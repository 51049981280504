import { AxiosResponse } from "axios"
import { axiosPrivateInstance } from "."
import {  FuelTypeBriefDto, GetFuelCostDetailsListDto, GetFuelCostDetailsDto, NewFuelTypesFormDto, VehicleNumberBriefDto, fuelBillDetailsDto } from "../utilities/models/fuel.model"

const postFuelRequest = (payload:NewFuelTypesFormDto):  Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.post('/api/Vehicle/AddFuelDetails', payload)
}

const getFuelTypes = ():  Promise<AxiosResponse<FuelTypeBriefDto[]>> => {
    return axiosPrivateInstance.get('/api/Vehicle/GetFuelTypes')
}

const getAllVehicleNumbers = (number: string): Promise<AxiosResponse<VehicleNumberBriefDto[]>> => {
    return axiosPrivateInstance.get(`/api/Vehicle/GetVehicleNumbers?number=${number}`)
}

const uploadFuelBill = (payload:any): Promise<AxiosResponse<string>> => {
    const payloadFormData = new FormData()
    payloadFormData.append('file', payload)
    return axiosPrivateInstance.post('/api/DocumentUpload/UploadFuelBillDocument', payloadFormData)
}

const getFuelPrice = (typeId:number):Promise<AxiosResponse<FuelTypeBriefDto>> => {
    return axiosPrivateInstance.get(`/api/Vehicle/GetFuelPrice?TypeId=${typeId}`)
}

const addFuelBillDetails = (payload:fuelBillDetailsDto):Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.post('/api/Vehicle/AddFuelBillDetails', payload)
}

const getFuelCostDetailsList = (param:GetFuelCostDetailsDto): Promise<AxiosResponse<GetFuelCostDetailsListDto[]>> => {
  
    return axiosPrivateInstance.get(`/api/Vehicle/GetFuelBillDetails?vehicleNumber=${param.vehicleNumber}&startDate=${param.startDate}&endDate=${param.endDate}`)
  };

  
export const fuelService = {
    postFuelRequest,
    getFuelTypes,
    getAllVehicleNumbers,
    uploadFuelBill,
    addFuelBillDetails,
    getFuelPrice,
    getFuelCostDetailsList
}