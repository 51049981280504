import React from 'react'
import style from './VehicleManagementGrid.module.scss'
import { VehicleDto } from '../../../utilities/models';
import { Box, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material';
import { AppSkeleton, CustomButton, CustomHeaderCell } from '../../Shared';
import { StyledTableCell } from '../../../assets/theme/theme';
import { APP_TABLE_CONFIGS, VEHICLE_PURPOSE_TYPES, VEHICLE_SCREEN_MODES } from '../../../utilities/constants';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { EditOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';

const VehicleManagementGrid: React.FC<{
  page: number,
  rowsPerPage: number,
  isFiltered: boolean,
  isLoading: boolean,
  filteredList: VehicleDto[],
  onHandleChangePage(event: unknown, newPage: number): void,
  onHandleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void,
  onSortHandle(col: string): void
  onSelectVehicleForRemove(id: number): void
  onFilterHandle(col: string, value: any): void;
  getFilterList: (col: string) => string[];
  navigateTo(mode: string, id: number): void;
  onClearFilter(): void;
}> = (props) => {
  return (
    <section className={style.gridContainer}>
      <div className={style.gridHeader}>
        <Typography
          noWrap
          component="div"
          className={style.gridTitle}
        >
          <h3>Vehicle Summary</h3>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <div className='layout-row'>
          {props.isFiltered &&
            <CustomButton text='Clear filter' textColor='black' bgColor='#bfbfbf' onClick={props.onClearFilter} />
          }
          <CustomButton icon={<AddIcon sx={{ fontSize: '20px' }} />} text='New Vehicle' onClick={() => props.navigateTo(VEHICLE_SCREEN_MODES.CREATE, -1)} />
        </div>
      </div>

      <TableContainer component={Paper} className={style.grid}>
        <Table className={style.table}>
          <TableHead>
            <TableRow>
              <CustomHeaderCell width={200} id='id' sortable onSort={props.onSortHandle} >Vehicle ID</CustomHeaderCell>
              <CustomHeaderCell width={300} id='vehicleNumber' sortable onSort={props.onSortHandle} >Vehicle License Plate Number </CustomHeaderCell>
              <CustomHeaderCell width={200} id='companyId' sortable onSort={props.onSortHandle}>Company ID</CustomHeaderCell>
              {/* <CustomHeaderCell width={300} id='companyName' sortable onSort={props.onSortHandle} filtered  filterStringList={["Express Trip","UrbanTruck Movers"]} getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Company Name</CustomHeaderCell> */}
              <CustomHeaderCell width={300} id='companyName' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Company Name</CustomHeaderCell>
              <CustomHeaderCell width={300} id='vehicleType' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Vehicle Type</CustomHeaderCell>
              <CustomHeaderCell width={300} id='seatCount' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Seating Capacity</CustomHeaderCell>
              <CustomHeaderCell width={300} id='cbm' sortable onSort={props.onSortHandle} >CBM Capacity</CustomHeaderCell>
              <CustomHeaderCell width={300} id='incident' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Recorded Incidents</CustomHeaderCell>
              <CustomHeaderCell width={300} id='purpose' sortable onSort={props.onSortHandle} > Purpose of the Vehicle</CustomHeaderCell>
              <CustomHeaderCell width={200} id='actions' >Actions</CustomHeaderCell>
            </TableRow>
          </TableHead>
          {props.isLoading && (
            <AppSkeleton numOfRows={props.rowsPerPage} numOfColumns={7} columnWidth={150} tag="table" />
          )}
          {!props.isLoading && props.filteredList.length > 0 &&
            <TableBody>
              {props.filteredList.map((item: VehicleDto) => (
                <TableRow key={item.id}>
                  <StyledTableCell >{item.id}</StyledTableCell>
                  <StyledTableCell >{item.vehicleNumber}</StyledTableCell>
                  <StyledTableCell >TC{item.companyId}</StyledTableCell>
                  <StyledTableCell >{item.companyName}</StyledTableCell>
                  <StyledTableCell >{item.vehicleType}</StyledTableCell>
                  <StyledTableCell >{item.seatCount}</StyledTableCell>
                  <StyledTableCell >{item.cbm}</StyledTableCell>
                  <StyledTableCell >{item.incident ? "Yes" : "No"}</StyledTableCell>
                  <StyledTableCell>{VEHICLE_PURPOSE_TYPES.find(type => type.value === item.purpose)?.label || item.purpose}</StyledTableCell>
                  <StyledTableCell style={{ backgroundColor: '#282828'}}>
                    <Box className='layout-row'>
                      <Box>
                        <IconButton size='small' onClick={() => { props.navigateTo(VEHICLE_SCREEN_MODES.VIEW, item.id) }}>
                          <Tooltip title="View">
                            <VisibilityOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton size='small' onClick={() => { props.navigateTo(VEHICLE_SCREEN_MODES.EDIT, item.id) }}>
                          <Tooltip title="Edit">
                            <EditOutlined sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton size='small' onClick={() => {props.onSelectVehicleForRemove(item.id)}}>
                          <Tooltip title="Delete">
                            <DeleteOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          }
          {!props.isLoading && props.filteredList.length === 0 &&
            <TableBody>
              <TableRow>
                <StyledTableCell align="left" colSpan={7}>No data to preview</StyledTableCell>
              </TableRow>
            </TableBody>
          }
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_OPTIONS}
        component="div"
        labelRowsPerPage={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: 'white',
            }}
          >
            Items per page
          </div>
        }
        count={-1}
        page={props.page}
        onPageChange={props.onHandleChangePage}
        onRowsPerPageChange={props.onHandleChangeRowsPerPage}
        rowsPerPage={props.rowsPerPage}
        nextIconButtonProps={
          { disabled: -1 < props.filteredList.length && props.filteredList.length < props.rowsPerPage }
        }
        sx={{ backgroundColor: "#282828", color: "white" }}
      />
    </section>
  )
}

export default VehicleManagementGrid
