import { Grid } from "@mui/material";
import { DriverDetailsFormDt, TransportCompaniesDto,} from "../../../utilities/models";
import { AppSkeleton, CustomAutocomplete, CustomDatePicker, CustomStepper,} from "../../Shared";

import React from "react";
import { StyledTextField } from "../../../assets/theme/theme";

const DriverDetails: React.FC<{
  helperText: boolean;
  isLoading: boolean;
  driverInformationForm: DriverDetailsFormDt;
  allCompanies: TransportCompaniesDto[];
  handleInputFocus(property: string, section: string): void;
  onInputHandleChangeDriverInformation(property: string, section: string): void;
}> = (props) => {
  const _driverName = props.driverInformationForm.driverName;
  const _companyName = props.driverInformationForm.companyName;
  // const _nic = props.driverInformationForm.nic;
  // const _licenceNumber = props.driverInformationForm.licenceNumber;
  const _contactNumber = props.driverInformationForm.contactNumber;
  const _plant = props.driverInformationForm.plant;

  return (
    <CustomStepper stepNumber={1} stepTitle="General Details">
      {!props.isLoading && (
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <StyledTextField
              fullWidth
              label="Driver Name"
              placeholder="Enter Driver Name"
              size="small"
              InputProps={{
                readOnly: _driverName.readonly,
              }}
              value={_driverName.value}
              error={!!_driverName.error}
              disabled={_driverName.disable}
              required={_driverName.isRequired}
              helperText={props.helperText && _driverName.error}
              onFocus={() =>
                props.handleInputFocus("driverName", "driver-information")
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.onInputHandleChangeDriverInformation(
                  "driverName",
                  event.target.value
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <CustomAutocomplete
              label="Transport Company"
              placeholder="Select Transport Company"
              options={props.allCompanies.map((c) => {
                return { label: c.name, value: c.id };
              })}
              value={_companyName.value}
              error={!!_companyName.error}
              disabled={_companyName.disable}
              required={_companyName.isRequired}
              readonly={_companyName.readonly}
              helperText={props.helperText && _companyName.error}
              onFocus={() =>
                props.handleInputFocus("companyName", "driver-information")
              }
              onChange={(event: any, value: any) =>
                props.onInputHandleChangeDriverInformation("companyName", value)
              }
            />
          </Grid>
          {/* <Grid item xs={6}>
            <StyledTextField
              fullWidth
              label="Driver NIC Number"
              placeholder="Enter Driver NIC Number"
              size="small"
              InputProps={{
                readOnly: _nic.readonly,
              }}
              value={_nic.value}
              error={!!_nic.error}
              disabled={_nic.disable}
              required={_nic.isRequired}
              helperText={props.helperText && _nic.error}
              onFocus={() =>
                props.handleInputFocus("nic", "driver-information")
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.onInputHandleChangeDriverInformation(
                  "nic",
                  event.target.value
                )
              }
            />
          </Grid> */}
          {/* <Grid item xs={6}>
            <StyledTextField
              fullWidth
              label="Driver License Number"
              placeholder="Enter Driver License Number"
              size="small"
              InputProps={{
                readOnly: _licenceNumber.readonly,
              }}
              value={_licenceNumber.value}
              error={!!_licenceNumber.error}
              disabled={_licenceNumber.disable}
              required={_licenceNumber.isRequired}
              helperText={props.helperText && _licenceNumber.error}
              onFocus={() =>
                props.handleInputFocus("licenceNumber", "driver-information")
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.onInputHandleChangeDriverInformation(
                  "licenceNumber",
                  event.target.value
                )
              }
            />
          </Grid> */}
          <Grid item xs={6}>
            <StyledTextField
              fullWidth
              label="Driver Contact Number"
              placeholder="Enter Driver Contact Number"
              size="small"
              InputProps={{
                readOnly: _contactNumber.readonly,
              }}
              value={_contactNumber.value}
              error={!!_contactNumber.error}
              disabled={_contactNumber.disable}
              required={_contactNumber.isRequired}
              helperText={props.helperText && _contactNumber.error}
              onFocus={() =>
                props.handleInputFocus("contactNumber", "driver-information")
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.onInputHandleChangeDriverInformation(
                  "contactNumber",
                  event.target.value
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              fullWidth
              label="Assigned Plant"
              placeholder="Enter Plant"
              size="small"
              InputProps={{
                readOnly: _plant.readonly,
              }}
              value={_plant.value.label || ''}
              error={!!_plant.error}
              disabled={_plant.disable}
              required={_plant.isRequired}
              helperText={props.helperText && _plant.error}
              onFocus={() =>
                props.handleInputFocus("plant", "driver-information")
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.onInputHandleChangeDriverInformation(
                  "plant",
                  event.target.value
                )
              }
            />
          </Grid>
        </Grid>
      )}
      {props.isLoading && (
        <AppSkeleton
          numOfRows={3}
          numOfColumns={2}
          columnWidth={50}
          height={30}
          tag="stepper"
        />
      )}
    </CustomStepper>
  );
};

export default DriverDetails;
