import React, { useEffect, useState } from "react";
import AppLayout from "../../templates/AppLayout/AppLayout";
import Typography from "@mui/material/Typography/Typography";
import style from "./ManageEntitlementScreen.module.scss";
import { AssignRolesforUsersParamDto, EditRolesforusersParamDto, UserByDeptDto, UserInformationFormDto, } from "../../utilities/models/permission.model";
import { OptionsDto } from "../../utilities/models/common.model";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationStateDto } from "../../utilities/models/state.model";
import { requestActions } from "../../redux/actions/request.action";
import ManageEntitlementForm from "../../components/ManageEntitlementScreen/ManageEntitlementScreen";
import { ALERT_ACTION_TYPES, ALL_ENTITLEMENTS, APP_ACTION_STATUS, APP_ROUTES, ApiRolesResponse, COMMON_ACTION_TYPES, GENERAL_SCREEN_MODES, USER_ROLES, } from "../../utilities/constants";
import { AlertDto } from "../../utilities/models";
import { alertActions, permissionActions } from "../../redux/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { validateFormData } from "../../utilities/helpers/FormValidator";
import CustomButton from "../../components/Shared/CustomButton/CustomButton";
import ConfirmationDialog from "../../components/Shared/ConfirmationDialog/ConfirmationDialog";

const ManageEntitlementScreen = () => {
  const USER_INFORMATION_FORM_INITIAL_STATE: UserInformationFormDto = {
    sbu: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    plant: { value: {} as OptionsDto, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    department: { value: {} as OptionsDto, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    roles: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
  };

  const [selectedOptions, setSelectedOptions] = useState<UserByDeptDto[]>([]);
  const [userInformationForm, setUserInformationForm] = useState(USER_INFORMATION_FORM_INITIAL_STATE);

  const [selectedRoleId, setSelectedRoleId] = useState(0);
  const [helperText, setHelperText] = useState(true);
  const [userId, setUserId] = useState(0);
  const [screenMode, setScreenMode] = useState("");
  const [isOpenCancel, setIsOpenCancel] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const allSbuList = useSelector((state: ApplicationStateDto) => state.request.getSbuList.data);
  const allPlantsBySbuIdResponse = useSelector((state: ApplicationStateDto) => state.request.getPlantListBySbuList);
  const allDepartmentByPlantIdResponse = useSelector((state: ApplicationStateDto) => state.request.getDepartmentListByPlantId);
  const allDeptUserResponse = useSelector((state: ApplicationStateDto) => state.permission.getUsersByDeptId);
  const allRolesPermissionsResponse = useSelector((state: ApplicationStateDto) => state.permission.getRolesPermissions);
  const selectedUserDetailsResponse = useSelector((state: ApplicationStateDto) => state.permission.getSelecteUserDetailsById);
  const authorizedUser = useSelector((state: ApplicationStateDto) => state.authUser.authorizedUser);
  const postRolesPermissionsResponse = useSelector((state:ApplicationStateDto) => state.permission.postRolesPermissions)
  const editRolesPermissionsResponse = useSelector((state:ApplicationStateDto) => state.permission.editRolesPermissions)

  useEffect(() => {
    getInitialData();

    return() => {
      dispatch(permissionActions.getRolesPermissions());
      dispatch(permissionActions.getSelecteUserDetailsByIdClear());
      dispatch(permissionActions.postAssignRolesUsersClear());
      dispatch(permissionActions.editAssignRolesUsersClear());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialData = async () => {
    const { mode, id } = location.state;
    setScreenMode(mode);
    setUserId(id);
    dispatch(requestActions.getSbuList());
    dispatch(permissionActions.getRolesPermissions());
    const _data = authorizedUser.data

    if (_data.roleId === USER_ROLES.SBU_MANAGER) {
      setUserInformationForm({
        ...userInformationForm,
        sbu: {
          ...userInformationForm.sbu,
          value: { label: _data.hierarchy[0].name, value: _data.hierarchy[0].id },
          disable: true,
        },
        plant: {
          ...userInformationForm.plant,
          value: {} as OptionsDto,
          disable: false,
        },
        department: {
          ...userInformationForm.department,
          value: {} as OptionsDto,
          disable: false,
        },
      })
      dispatch(requestActions.getPlantListBySbuId(_data.hierarchy[0].id));
    }

    if (_data.roleId === USER_ROLES.TRANSPORT_MANAGER) {
      setUserInformationForm({
        ...userInformationForm,
        sbu: {
          ...userInformationForm.sbu,
          value: { label: _data.hierarchy[0].name, value: _data.hierarchy[0].id },
          disable: true,
        },
        plant: {
          ...userInformationForm.plant,
          value: { label: _data.hierarchy[1].name, value: _data.hierarchy[1].id },
          disable: true,
        },
        department: {
          ...userInformationForm.department,
          value: {} as OptionsDto,
          disable: false,
        },
      })
      dispatch(requestActions.getDepartmentListByPlantId(_data.hierarchy[1].id));
    }
  };

  useEffect(() => {
    if (screenMode === GENERAL_SCREEN_MODES.EDIT) {
      dispatch(permissionActions.getSelecteUserDetailsById(userId));
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])
  

  useEffect(() => {
    if(postRolesPermissionsResponse.status === APP_ACTION_STATUS.SUCCESS){
      navigate(APP_ROUTES.ENTITLEMET_CREATION);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postRolesPermissionsResponse.status])
  
  useEffect(() => {
    if(editRolesPermissionsResponse.status === APP_ACTION_STATUS.SUCCESS){
      navigate(APP_ROUTES.ENTITLEMET_CREATION);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRolesPermissionsResponse.status])

  const setAsInitialState = () => {
    setUserInformationForm(USER_INFORMATION_FORM_INITIAL_STATE);
    setSelectedOptions([])
  }

  const handleRoleChange = () => {
    const roleId = userInformationForm.roles.value;
    setSelectedRoleId(Number(roleId.value));
  };

  const selectedRole = ApiRolesResponse.find(
    (role) => role.id === selectedRoleId
  );

  const selectedPermissions = selectedRole
    ? selectedRole.permissions.map((permission) => permission.type)
    : [];

  const ALL_ENTITLEMENTSWithChecked = ALL_ENTITLEMENTS.map((entitlement) => ({
    entitlement,
    checked: selectedPermissions.includes(entitlement),
  }));

  useEffect(() => {
    handleRoleChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInformationForm.roles.value]);

  useEffect(() => {
    if (selectedUserDetailsResponse.status === APP_ACTION_STATUS.SUCCESS) {
      const hierarchyNames = selectedUserDetailsResponse?.data?.hierachies;
      const editRoleId = selectedUserDetailsResponse?.data?.roleId;
      const editRoleName = selectedUserDetailsResponse?.data?.roleName;

      if (hierarchyNames && hierarchyNames.length > 0) {
        setUserInformationForm({
          sbu: {
            value: {
              label: hierarchyNames[0].name,
              value: hierarchyNames[0].id,
            },
            isRequired: true,
            disable: true,
            readonly: true,
            validator: "object",
            error: "",
          },
          plant: {
            value: {
              label: hierarchyNames[1].name,
              value: hierarchyNames[1].id,
            },
            isRequired: true,
            disable: true,
            readonly: false,
            validator: "object",
            error: "",
          },
          department: {
            value: {
              label: hierarchyNames[2].name,
              value: hierarchyNames[2].id,
            },
            isRequired: true,
            disable: true,
            readonly: false,
            validator: "object",
            error: "",
          },
          roles: {
            value: {
              label: editRoleName,
              value: editRoleId,
            },
            isRequired: true,
            disable: false,
            readonly: false,
            validator: "object",
            error: "",
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserDetailsResponse.status])


  const onInputHandleChangeUserInformation = (property: string, value: any) => {
    setHelperText(true);

    if (property === "sbu") {
      setUserInformationForm({
        ...userInformationForm,
        sbu: {
          ...userInformationForm.sbu,
          value: value ? value : ({} as OptionsDto),
        },
        plant: {
          ...userInformationForm.plant,
          value: {} as OptionsDto,
          disable: false,
        },
        department: {
          ...userInformationForm.department,
          value: {} as OptionsDto,
          disable: false,
        },
      });

      dispatch(requestActions.getPlantListBySbuId(value.value));
    }

    if (property === "plant") {
      setUserInformationForm({
        ...userInformationForm,
        plant: {
          ...userInformationForm.plant,
          value: value ? value : ({} as OptionsDto),
        },
        department: {
          ...userInformationForm.department,
          value: {} as OptionsDto,
          disable: false,
        },
      });

      dispatch(requestActions.getDepartmentListByPlantId(value.value));
    }
    if (property === "department") {
      setUserInformationForm({
        ...userInformationForm,
        department: {
          ...userInformationForm.department,
          value: value ? value : ({} as OptionsDto),
        },
      });
      dispatch(permissionActions.getUsersByDeptId(value.value));
    }
    if (property === "roles") {
      setUserInformationForm({
        ...userInformationForm,
        roles: {
          ...userInformationForm.roles,
          value: value ? value : ({} as OptionsDto),
        },
      });
    }
  };

  const handleAssignRoles = async () => {
    const idList = selectedOptions.map((option) => option.id);

    const _param: AssignRolesforUsersParamDto = {
      userId: idList,
      roleId: Number(userInformationForm.roles.value.value),
    };

    const [validateData, isValid] = await validateFormData(userInformationForm);
    setUserInformationForm(validateData);
    if (isValid) {
      if (selectedRoleId !== 0 && idList.length !== 0) {
        dispatch(permissionActions.postAssignRolesUsers(_param));
      } else {
        const setAlert: AlertDto = {
          message: "Select atleast 1 user",
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: "error",
          },
        };
        dispatch(alertActions.triggerAlert(setAlert));
      }
    } else {
      const setAlert: AlertDto = {
        message: "Please fill required field/s",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: "error",
        },
      };
      dispatch(alertActions.triggerAlert(setAlert));
    }
  };

  const editAssignRoles = () => {
    const id = userId;

    const _param: EditRolesforusersParamDto = {
      userId: id,
      roleId: Number(userInformationForm.roles.value.value),
    };

    if (id !== 0 && selectedRoleId !== 0) {
      dispatch(permissionActions.editAssignRolesUsers(_param));
    } else {
      const setAlert: AlertDto = {
        message: "Please fill required field/s",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: "error",
        },
      };
      dispatch(alertActions.triggerAlert(setAlert));
    }
  };

  const onEntitlementClose = (con: boolean) => {
    setIsOpenCancel(false)
    if (con) {
      navigate(APP_ROUTES.ENTITLEMET_CREATION)
      setAsInitialState()
    }
  }

  const handleInputFocus = (property: string, section: string) => {
    if (section === "UI")
      setUserInformationForm({
        ...userInformationForm,
        [property]: {
          ...userInformationForm[property as keyof typeof userInformationForm],
          error: null,
        },
      });
  };

  return (
    <React.Fragment>
      <AppLayout componentTitle="New Request Creation">
        <section className="page-root">
          <section className={style.sectionCard}>
            <section className={style.sectionCardHeader}>
              <Typography noWrap component="div" className={style.title}>
                <h3>Manage Entitlements</h3>
              </Typography>
            </section>
            <section className={style.sectionCardBody}>
              <ManageEntitlementForm
                userInformationForm={userInformationForm}
                onInputHandleChangeUserInformation={
                  onInputHandleChangeUserInformation
                }
                setSelectedOptions={setSelectedOptions}
                allSbuList={allSbuList || []}
                allPlantsBySbuIdResponse={allPlantsBySbuIdResponse}
                allDepartmentByPlantIdResponse={allDepartmentByPlantIdResponse}
                helperText={helperText}
                allDeptUserResponse={allDeptUserResponse || []}
                allRolesPermissionsResponse={allRolesPermissionsResponse || []}
                screenMode={screenMode}
                sbuReadOnlyValue={selectedUserDetailsResponse || null}
                isLoading={selectedUserDetailsResponse.isLoading}
                handleInputFocus={handleInputFocus}
                setSelectedRoleId={setSelectedRoleId}
                selectedRoleId={selectedRoleId}
                ALL_ENTITLEMENTSWithChecked={ALL_ENTITLEMENTSWithChecked || []}
              />
              <section className={style.sectionCardFooter}>
                <CustomButton
                  text="Cancel"
                  border="1px solid #6e6e6e"
                  bgColor="#282828"
                  onClick={() => setIsOpenCancel(true)}
                />
                {screenMode === GENERAL_SCREEN_MODES.EDIT ? (
                  <CustomButton text="Edit" 
                    disabled={editRolesPermissionsResponse.isLoading} 
                    isLoading={editRolesPermissionsResponse.isLoading} 
                    onClick={editAssignRoles} />
                ) : (
                  <CustomButton text="Save" 
                    disabled={postRolesPermissionsResponse.isLoading}
                    isLoading={postRolesPermissionsResponse.isLoading}  
                    onClick={handleAssignRoles} />
                )}
              </section>
            </section>
          </section>
          <ConfirmationDialog
          isOpenConfirmationDialog={isOpenCancel}
          onCallback={onEntitlementClose}
          title="Close Manage Entitlement"
          content="Do you want to close manage entitlement?"
          confirmButtonTitle="Yes"
          cancelButtonTitle="No"
        />
        </section>
      </AppLayout>
    </React.Fragment>
  );
};

export default ManageEntitlementScreen;
