import {Grid} from "@mui/material";
import React, { useEffect, useState } from "react";
import TmRequestSummaryGraphCard from "../../Shared/TmSummaryChart/TmRequestSummaryGraphCard";
import RequestSummaryChart from "../../Shared/RequestSummaryChart/RequestSummaryChart";

const TmDashboardSummaryChart = (props: any) => {
  const [summaryChartData, setSummaryChartData] = useState([]);
  const [tmDeptChartData, setTmDeptChartData] = useState([]);

  useEffect(() => {
    setSummaryChartData(
      props.getTmSummaryResponse?.data
        ?.tmDashboardChartsDTO_TMRequestSummaryDoughnutChartDTO
    );
    setTmDeptChartData(
      props.getTmSummaryResponse?.data
        ?.tmDashboardChartsDTO_TMRequestSummaryBarChartDTO
    );
  }, [props]);

  return (
    <Grid container spacing={4}>
      <Grid item md={6}>
        <RequestSummaryChart summaryChartData={summaryChartData} />
      </Grid>
      <Grid item md={6}>
        <TmRequestSummaryGraphCard tmDeptChartData={tmDeptChartData}/>
      </Grid>
    </Grid>
  );
};

export default TmDashboardSummaryChart;
