import {
  COMMON_ACTION_TYPES,
  REDIRECT_ACTION_TYPE,
  SPLIT_ACTION_TYPE,
} from "../../utilities/constants";

const getSplitTypes = () => {
  return {
    type: SPLIT_ACTION_TYPE.GET_SPLIT_TYPES + COMMON_ACTION_TYPES.REQUEST,
  };
};

const getSplitPassengersPackages = (id: number) => {
  return {
    type:
      SPLIT_ACTION_TYPE.GET_PASSENGERS_PACKAGES + COMMON_ACTION_TYPES.REQUEST,
    id: id,
  };
};

const createSplitRequest = (payload: any) => {
  return {
    type: SPLIT_ACTION_TYPE.POST_SPLIT_REQUEST + COMMON_ACTION_TYPES.REQUEST,
    payload: payload,
  };
};

const getTransportManager = (id: number) => {
  return {
    type:
      REDIRECT_ACTION_TYPE.GET_TRANSPORT_MANAGER + COMMON_ACTION_TYPES.REQUEST,
    id: id,
  };
};


const clearTransportManager = () => {
  return {
    type:
      REDIRECT_ACTION_TYPE.GET_TRANSPORT_MANAGER + COMMON_ACTION_TYPES.CLEAR,
  
  };
};


const createRedirectRequest = (payload: any) => {
  return {
    type:
      REDIRECT_ACTION_TYPE.POST_REDIRECT_REQUEST + COMMON_ACTION_TYPES.REQUEST,
    payload: payload,
  };
};
export const splitActions = {
  getSplitTypes,
  getSplitPassengersPackages,
  createSplitRequest,
  getTransportManager,
  clearTransportManager,
  createRedirectRequest,
};
