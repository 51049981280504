import { AxiosResponse } from "axios";
import { LocationBriefDto, NotificationDto, updateSeenNotificationParam } from "../utilities/models";
import { axiosPrivateInstance } from ".";

const getNotification = (): Promise<AxiosResponse<NotificationDto>> => {
    return axiosPrivateInstance.get('/api/Notification/GetNotification')
}
const updateSeenNotification = (payload:updateSeenNotificationParam): Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.put('/api/Notification/UpdateSeenNotification',payload)
}

export const NotificationService = {
    getNotification,
    updateSeenNotification
}