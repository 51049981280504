
import { ThemeProvider } from '@mui/material';
import AppRoutes from './routes';
import { PrimaryTheme, PrimaryTheme1 } from './assets/theme/theme';
import useNotifier from './utilities/helpers/UseNotifier';
import { useDispatch } from 'react-redux';
import { authActions, notificationAction } from './redux/actions';
import React from 'react';
import { Library } from '@googlemaps/js-api-loader';
import { ENV_LOCAL } from './.env/environment.local';
import { LoadScript } from '@react-google-maps/api';

const libraries: Library[] = ['places']; 
function App() {
  useNotifier()
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(authActions.fetchAuthorizedUser())
    dispatch(notificationAction.getNotification())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <ThemeProvider theme={PrimaryTheme1}>
       <LoadScript googleMapsApiKey={ENV_LOCAL.GOOGLE_MAPS_API_KEY} libraries={libraries}>
      <AppRoutes />
      </LoadScript>
    </ThemeProvider>
  );
}

export default App;
