import {
  APP_ACTION_STATUS,
  CHART_ACTION_TYPES,
  COMMON_ACTION_TYPES,
} from "../../utilities/constants";
import { ChartStateDto } from "../../utilities/models/state.model";

const INITIAL_STATE: ChartStateDto = {
  getLMSummary: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  getGUSummary: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getTMSummary: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  getLMBudgetSummary: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
};

const chartReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CHART_ACTION_TYPES.GET_LM_SUMMARY + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getLMSummary: {
          ...state.getLMSummary,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };

    case CHART_ACTION_TYPES.GET_LM_SUMMARY + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getLMSummary: {
          ...state.getLMSummary,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };

    case CHART_ACTION_TYPES.GET_LM_SUMMARY + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getLMSummary: {
          ...state.getLMSummary,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };

    case CHART_ACTION_TYPES.GET_LM_SUMMARY + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getLMSummary: {
          ...state.getLMSummary,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case CHART_ACTION_TYPES.GET_GU_SUMMARY + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getGUSummary: {
          ...state.getGUSummary,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case CHART_ACTION_TYPES.GET_GU_SUMMARY + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getGUSummary: {
          ...state.getGUSummary,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case CHART_ACTION_TYPES.GET_GU_SUMMARY + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getGUSummary: {
          ...state.getGUSummary,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case CHART_ACTION_TYPES.GET_GU_SUMMARY + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getGUSummary: {
          ...state.getGUSummary,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case CHART_ACTION_TYPES.GET_TM_SUMMARY + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getTMSummary: {
          ...state.getTMSummary,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };

    case CHART_ACTION_TYPES.GET_TM_SUMMARY + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getTMSummary: {
          ...state.getTMSummary,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };

    case CHART_ACTION_TYPES.GET_TM_SUMMARY + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getTMSummary: {
          ...state.getTMSummary,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };

    case CHART_ACTION_TYPES.GET_TM_SUMMARY + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getTMSummary: {
          ...state.getTMSummary,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case CHART_ACTION_TYPES.GET_LM_BUDGET_SUMMARY + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getLMBudgetSummary: {
          ...state.getLMBudgetSummary,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case CHART_ACTION_TYPES.GET_LM_BUDGET_SUMMARY + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getLMBudgetSummary: {
          ...state.getLMBudgetSummary,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case CHART_ACTION_TYPES.GET_LM_BUDGET_SUMMARY + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getLMBudgetSummary: {
          ...state.getLMBudgetSummary,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case CHART_ACTION_TYPES.GET_LM_BUDGET_SUMMARY + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getLMBudgetSummary: {
          ...state.getLMBudgetSummary,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    default:
      return state;
  }
};

export default chartReducer;
