import { APP_ACTION_STATUS, COMMON_ACTION_TYPES, SHIFT_ACTION_TYPES } from "../../utilities/constants";
import { ShiftStateDto } from "../../utilities/models/state.model";

const INITIAL_STATE: ShiftStateDto = {
  uploadFile: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  downloadShiftFile: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  deleteFile: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  getShiftTypes: {
    data: [],
    error: null,
    isLoading: false,
    status: null
  },
  getShiftGroups: {
    data: [],
    error: null,
    isLoading: false,
    status: null
  },
  getAvailableShiftVehicles: {
    data: [],
    error: null,
    isLoading: false,
    status: null
  },
  postShiftRequest: {
    data: '',
    error: null,
    isLoading: false,
    status: null,
  },
  getShiftRequestList: {
    data: [],
    error: null,
    isLoading: false,
    status: null
  },
  getShiftRequest: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  deleteShiftRequest: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  editShiftRequest: {
    data: '',
    error: null,
    isLoading: false,
    status: null,
  },
  getExecutionTemplates: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  executeShiftRequest: {
    data: '',
    error: null,
    isLoading: false,
    status: null,
  },
  getExecutedTemplateById: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  postShiftRequestDuplicate: {
    data: '',
    error: null,
    isLoading: false,
    status: null,
  }
}
const shiftReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SHIFT_ACTION_TYPES.SHIFT_UPLOAD_FILE + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.SHIFT_UPLOAD_FILE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case SHIFT_ACTION_TYPES.SHIFT_UPLOAD_FILE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.SHIFT_UPLOAD_FILE + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case SHIFT_ACTION_TYPES.SHIFT_DOWNLOAD_FILE + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        downloadShiftFile: {
          ...state.downloadShiftFile,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.SHIFT_DOWNLOAD_FILE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        downloadShiftFile: {
          ...state.downloadShiftFile,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case SHIFT_ACTION_TYPES.SHIFT_DOWNLOAD_FILE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        downloadShiftFile: {
          ...state.downloadShiftFile,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.SHIFT_DOWNLOAD_FILE + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        downloadShiftFile: {
          ...state.downloadShiftFile,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.SHIFT_DELETE_FILE + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        deleteFile: {
          ...state.deleteFile,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.SHIFT_DELETE_FILE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        deleteFile: {
          ...state.deleteFile,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case SHIFT_ACTION_TYPES.SHIFT_DELETE_FILE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        deleteFile: {
          ...state.deleteFile,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.SHIFT_DELETE_FILE + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        deleteFile: {
          ...state.deleteFile,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.GET_SHIFT_TYPES + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getShiftTypes: {
          ...state.getShiftTypes,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null
        }
      }
    case SHIFT_ACTION_TYPES.GET_SHIFT_TYPES + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getShiftTypes: {
          ...state.getShiftTypes,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data
        }
      }
    case SHIFT_ACTION_TYPES.GET_SHIFT_TYPES + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getShiftTypes: {
          ...state.getShiftTypes,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: []
        }
      }
    case SHIFT_ACTION_TYPES.GET_SHIFT_TYPES + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getShiftTypes: {
          ...state.getShiftTypes,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null
        }
      }

    case SHIFT_ACTION_TYPES.GET_SHIFT_GROUPS + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getShiftGroups: {
          ...state.getShiftGroups,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null
        }
      }
    case SHIFT_ACTION_TYPES.GET_SHIFT_GROUPS + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getShiftGroups: {
          ...state.getShiftGroups,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data
        }
      }
    case SHIFT_ACTION_TYPES.GET_SHIFT_GROUPS + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getShiftGroups: {
          ...state.getShiftGroups,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: []
        }
      }
    case SHIFT_ACTION_TYPES.GET_SHIFT_GROUPS + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getShiftGroups: {
          ...state.getShiftGroups,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null
        }
      }
    case SHIFT_ACTION_TYPES.GET_AVAILABLE_SHIFT_VEHICLES + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getAvailableShiftVehicles: {
          ...state.getAvailableShiftVehicles,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null
        }
      }
    case SHIFT_ACTION_TYPES.GET_AVAILABLE_SHIFT_VEHICLES + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getAvailableShiftVehicles: {
          ...state.getAvailableShiftVehicles,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data
        }
      }
    case SHIFT_ACTION_TYPES.GET_AVAILABLE_SHIFT_VEHICLES + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getAvailableShiftVehicles: {
          ...state.getAvailableShiftVehicles,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: []
        }
      }
    case SHIFT_ACTION_TYPES.GET_AVAILABLE_SHIFT_VEHICLES + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getAvailableShiftVehicles: {
          ...state.getAvailableShiftVehicles,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null
        }
      }
    case SHIFT_ACTION_TYPES.POST_SHIFT_REQUEST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        postShiftRequest: {
          ...state.postShiftRequest,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.POST_SHIFT_REQUEST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        postShiftRequest: {
          ...state.postShiftRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case SHIFT_ACTION_TYPES.POST_SHIFT_REQUEST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        postShiftRequest: {
          ...state.postShiftRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.POST_SHIFT_REQUEST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        postShiftRequest: {
          ...state.postShiftRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.GET_SHIFT_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getShiftRequestList: {
          ...state.getShiftRequestList,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null
        }
      }
    case SHIFT_ACTION_TYPES.GET_SHIFT_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getShiftRequestList: {
          ...state.getShiftRequestList,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data
        }
      }
    case SHIFT_ACTION_TYPES.GET_SHIFT_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getShiftRequestList: {
          ...state.getShiftRequestList,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: []
        }
      }
    case SHIFT_ACTION_TYPES.GET_SHIFT_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getShiftRequestList: {
          ...state.getShiftRequestList,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null
        }
      }
    case SHIFT_ACTION_TYPES.GET_SHIFT_REQUEST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getShiftRequest: {
          ...state.getShiftRequest,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.GET_SHIFT_REQUEST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getShiftRequest: {
          ...state.getShiftRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case SHIFT_ACTION_TYPES.GET_SHIFT_REQUEST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getShiftRequest: {
          ...state.getShiftRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.GET_SHIFT_REQUEST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getShiftRequest: {
          ...state.getShiftRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.DELETE_SHIFT_REQUEST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        deleteShiftRequest: {
          ...state.deleteShiftRequest,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.DELETE_SHIFT_REQUEST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        deleteShiftRequest: {
          ...state.deleteShiftRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case SHIFT_ACTION_TYPES.DELETE_SHIFT_REQUEST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        deleteShiftRequest: {
          ...state.deleteShiftRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.DELETE_SHIFT_REQUEST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        deleteShiftRequest: {
          ...state.deleteShiftRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.EDIT_SHIFT_REQUEST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        editShiftRequest: {
          ...state.editShiftRequest,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.EDIT_SHIFT_REQUEST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        editShiftRequest: {
          ...state.editShiftRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case SHIFT_ACTION_TYPES.EDIT_SHIFT_REQUEST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        editShiftRequest: {
          ...state.editShiftRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.EDIT_SHIFT_REQUEST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        editShiftRequest: {
          ...state.editShiftRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.EXECUTE_SHIFT_REQUEST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        executeShiftRequest: {
          ...state.executeShiftRequest,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.EXECUTE_SHIFT_REQUEST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        executeShiftRequest: {
          ...state.executeShiftRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case SHIFT_ACTION_TYPES.EXECUTE_SHIFT_REQUEST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        executeShiftRequest: {
          ...state.executeShiftRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.EXECUTE_SHIFT_REQUEST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        executeShiftRequest: {
          ...state.executeShiftRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case SHIFT_ACTION_TYPES.GET_EXECUTION_TEMPLATES + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getExecutionTemplates: {
          ...state.getExecutionTemplates,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case SHIFT_ACTION_TYPES.GET_EXECUTION_TEMPLATES + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getExecutionTemplates: {
          ...state.getExecutionTemplates,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case SHIFT_ACTION_TYPES.GET_EXECUTION_TEMPLATES + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getExecutionTemplates: {
          ...state.getExecutionTemplates,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case SHIFT_ACTION_TYPES.GET_EXECUTION_TEMPLATES + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getExecutionTemplates: {
          ...state.getExecutionTemplates,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: [],
        },
      };
    case SHIFT_ACTION_TYPES.GET_EXECUTED_TEMPLATE + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getExecutedTemplateById: {
          ...state.getExecutedTemplateById,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.GET_EXECUTED_TEMPLATE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getExecutedTemplateById: {
          ...state.getExecutedTemplateById,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case SHIFT_ACTION_TYPES.GET_EXECUTED_TEMPLATE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getExecutedTemplateById: {
          ...state.getExecutedTemplateById,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.GET_EXECUTED_TEMPLATE + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getExecutedTemplateById: {
          ...state.getExecutedTemplateById,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.POST_SHIFT_DUPLICATE + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        postShiftRequestDuplicate: {
          ...state.postShiftRequestDuplicate,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.POST_SHIFT_DUPLICATE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        postShiftRequestDuplicate: {
          ...state.postShiftRequestDuplicate,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case SHIFT_ACTION_TYPES.POST_SHIFT_DUPLICATE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        postShiftRequestDuplicate: {
          ...state.postShiftRequestDuplicate,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case SHIFT_ACTION_TYPES.POST_SHIFT_DUPLICATE + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        postShiftRequestDuplicate: {
          ...state.postShiftRequestDuplicate,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    default:
      return state;
  }
}

export default shiftReducer