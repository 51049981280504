import React, { useState } from "react";
import style from "./CompletedTripSummary.module.scss";
import { Typography, Box, Paper, TableContainer, TableHead, Table, TableRow, TableBody, IconButton, Tooltip, TablePagination, Grid,} from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import CancelIcon from "@mui/icons-material/Cancel";
import { EditOutlined } from "@mui/icons-material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import dayjs from "dayjs";
import { ApplicationStateDto, DateRangeDto, DateRangeParmDto, allocationHistoryDto, requestHistory,} from "../../../utilities/models";
import { AppSkeleton, CustomButton, CustomDatePicker, CustomHeaderCell,} from "../../Shared";
import { StickyTableCell, StyledCheckBox, StyledTableCell, StyledStatusApproved, StyledStatusRejected, StyledStatusPending, StyledStatusDraft, StyledSwitch,} from "../../../assets/theme/theme";
import { APP_TABLE_CONFIGS, REQUEST_STATUS, USER_ROLES,} from "../../../utilities/constants";
import { requestActions } from "../../../redux/actions";
import { validateFormData } from "../../../utilities/helpers";
import { useDispatch, useSelector } from "react-redux";

const CompletedTripSummary: React.FC<{
  isOpenAddPackagePopup: boolean;
  page: number;
  rowsPerPage: number;
  isFiltered: boolean;
  onHandleChangePage(event: unknown, newPage: number): void;
  onclickOnView(allocationId: string): void;
  onHandleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void;
  filteredList: requestHistory[];
  onSortHandle(col: string): void;
  onFilterHandle(col: string, value: any): void;
  getFilterList: (col: string) => string[];
  isLoading: boolean;
  onSelectRequestIds(id: string, value: boolean): void;
  onClearFilter(): void;
  handleViewClick(allocationId: string): void;
  onClickOnMap(fLat:string, fLon:string, toLat:string, toLon:string):void;
}> = (props) => {
  
  const DATE_RANGE_INITIAL_STATE: DateRangeDto = {
    fromDate: { value: null, isRequired: false, disable: false, readonly: false, validator: "date",error: "",},
    toDate: { value: null, isRequired: false, disable: false, readonly: false, validator: "date", error: "",},
    isAll: { value: false, isRequired: true, disable: false, readonly: false, validator: null, error: "",},
  };

  const authorizedUser = useSelector((state: ApplicationStateDto) => state.authUser.authorizedUser);
  const [DateRangeForm, setDateRangeForm] = useState<DateRangeDto>(DATE_RANGE_INITIAL_STATE);
  const [isFiltered, setIsFiltered] = useState(false)
  const [helperText, setHelperText] = useState(true);
  const [selectedFromDate, setSelectedFromDate] = useState(null);

  const dispatch = useDispatch();
 
  const handleInputFocus = (property: string, section: string) => {
    setDateRangeForm({
      ...DateRangeForm,
      [property]: {
        ...DateRangeForm[property as keyof typeof DateRangeForm],
        error: null,
      },
    });
  };

  const onInputHandleChange = (property: string, value: any) => {
    setHelperText(true);
    if (property === "fromDate") {
      setDateRangeForm({
        ...DateRangeForm,
        fromDate: {
          value: value,
          validator: null,
          isRequired: false,
          error: null,
          disable: false,
          readonly: false,
        },
      });
    }
    if (property === "toDate") {
      setDateRangeForm({
        ...DateRangeForm,
        toDate: {
          value: value,
          validator: null,
          isRequired: false,
          error: null,
          disable: false,
          readonly: false,
        },
      });
    }

    if (property === "isAll") {
      setDateRangeForm({
        ...DateRangeForm,
        isAll: {
          value: value,
          validator: null,
          isRequired: true,
          error: null,
          disable: false,
          readonly: false,
        },
      });
    }
  };

  const getDataWithingRange = async () => {
    const [validateData, isValid] = await validateFormData(DateRangeForm);
    setDateRangeForm(validateData);
    if (isValid) {
      const payload: DateRangeParmDto = {
        fromDate: DateRangeForm.fromDate.value?.toString() || "",
        toDate: DateRangeForm.toDate.value?.toString() || "",
        isAll: Boolean(DateRangeForm.isAll.value),
      };
      dispatch(requestActions.GetRequestHistory(payload));
      setIsFiltered(true)
    }
  };

  const handleSwitchChange = (newValue: any) => {
    setDateRangeForm((prevDateRangeForm) => ({
      ...prevDateRangeForm,
      isAll: {
        ...prevDateRangeForm.isAll,
        value: newValue,
      },
    }));
  };


  const onClearFilter = () => {
    setDateRangeForm(DATE_RANGE_INITIAL_STATE);
    const payload: DateRangeParmDto = {
      fromDate: "",
      toDate:"",
      isAll:  false,
    };
    dispatch(requestActions.GetRequestHistory(payload));
    setIsFiltered(false)
  };


  return (
    <section className={style.gridContainer}>
      <div className={style.gridHeader}>
        <Typography noWrap component="div" className={style.gridTitle}>
          <h3>Completed Trip Summary</h3>
        </Typography>

      </div>
          <br></br>
          <div className={style.gridContainer}>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <CustomDatePicker
                label="From Date"
                placeholder="Select Date"
                //multiple={_requestType.value.id === 2}
                value={DateRangeForm.fromDate.value}
                maxDate={new Date()}
                // error={DateRangeForm.fromDate.error}
                disabled={DateRangeForm.fromDate.disable}
                readOnly={DateRangeForm.fromDate.readonly}
                required={DateRangeForm.fromDate.isRequired}
                //helperText={props.helperText && _depatureDate.error}
                onFocus={() => handleInputFocus("fromDate", "GI")}
                onChange={(value: any) => {
                  const selectedDate = new Date(value);
                  console.log("From Date selected:", selectedDate);
                  onInputHandleChange("fromDate", value);
                }}
                helperText={undefined}
                error={false}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomDatePicker
                label="To Date"
                placeholder="Select Date"
                //multiple={_requestType.value.id === 2}
                value={DateRangeForm.toDate.value}
                maxDate={new Date()}
                minDate={DateRangeForm.toDate.value}
                // minDate={selectedFromDate}
                // error={DateRangeForm.toDate.error}
                disabled={
                  DateRangeForm.toDate.disable || !DateRangeForm.fromDate.value
                }
                readOnly={DateRangeForm.toDate.readonly}
                required={DateRangeForm.toDate.isRequired}
                //helperText={props.helperText && _depatureDate.error}
                onFocus={() => handleInputFocus("s", "GI")}
                onChange={(value: any) => onInputHandleChange("toDate", value)}
                helperText={undefined}
                error={false}
              />
            </Grid>

            <Grid item xs={3}>
              {authorizedUser.data.roleId !== USER_ROLES.REGULAR_USER && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography style={{ marginRight: "1rem" }}>
                    Created by {DateRangeForm.isAll.value ? "All" : "Me"}
                  </Typography>
                  <Box>
                    <StyledSwitch
                      checked={DateRangeForm.isAll.value}
                      disabled={DateRangeForm.isAll.disable}
                      onChange={(event) =>
                        handleSwitchChange(event.target.checked)
                      }
                    />
                  </Box>
                </div>
              )}
            </Grid>
            <Grid item xs={3}>
            <div className='layout-row'>
              {isFiltered && (
                  <CustomButton
                    text="Clear Filter"
                    textColor="black"
                    bgColor="#bfbfbf"
                    marginRight="5"
                    onClick={onClearFilter}
                  />
                )}
                <CustomButton
                  text="Get Data"
                  disabled={false}
                  isLoading={false}
                  onClick={getDataWithingRange}
                />
              </div>
            </Grid>           
          </Grid>
          </div>
        <Box sx={{ flexGrow: 1 }} />
        
      
      <TableContainer component={Paper} className={style.grid}>
        <Table className={style.table}>
          <TableHead>
            <TableRow>
              <CustomHeaderCell width={150} id="allocationId" sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}> Allocation ID </CustomHeaderCell>
              <CustomHeaderCell width={180} id="vehicleType" sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}> Vehicle Type </CustomHeaderCell>
              <CustomHeaderCell width={180} id="status" sortable onSort={props.onSortHandle}> Status </CustomHeaderCell>
              <CustomHeaderCell width={180} id="vehiclePlateNo" sortable onSort={props.onSortHandle}> Vehicle License Plate Number </CustomHeaderCell>
              <CustomHeaderCell width={180} id="driverName" sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}> Vehicle Driver Name</CustomHeaderCell>
              <CustomHeaderCell width={180} id="transportCompany" sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}> Transport Company Name </CustomHeaderCell>
              <CustomHeaderCell width={240} id="allocatedOn" sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}> Vehicle Allocated on </CustomHeaderCell>
              <CustomHeaderCell width={240} id="mileage" sortable onSort={props.onSortHandle}> Projected Mileage (Km) </CustomHeaderCell>
              <CustomHeaderCell width={240} id="mileage" sortable onSort={props.onSortHandle}> Actual Mileage (Km) </CustomHeaderCell>
              <CustomHeaderCell width={240} id="cost" sortable onSort={props.onSortHandle}> Projected Cost (LKR) </CustomHeaderCell>
              <CustomHeaderCell width={240} id="cost" sortable onSort={props.onSortHandle}> Actual Cost (LKR)</CustomHeaderCell>
              <CustomHeaderCell width={180} id="allocatedRequisitions">Allocated Requisitions</CustomHeaderCell>
              <CustomHeaderCell width={150} id="actions"> Actions </CustomHeaderCell>
            </TableRow>
          </TableHead>
          {props.isLoading && (
            <AppSkeleton
              numOfRows={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE}
              numOfColumns={10}
              columnWidth={180}
              height={35}
              stickyFirst
              tag="table"
            />
          )}
          {props.filteredList && props.filteredList.length > 0 && (
            <TableBody>
              {props.filteredList
                .slice(
                  props.page * props.rowsPerPage,
                  props.page * props.rowsPerPage + props.rowsPerPage
                )
                .map((p: requestHistory) => (
                  <TableRow key={p.allocationId}>
                    <StyledTableCell>A{p.allocationId}</StyledTableCell>
                    <StyledTableCell>{p.vehicleType}</StyledTableCell>
                    <StyledTableCell>
                      {p.status === REQUEST_STATUS.APPROVED_LM ||
                        p.status === REQUEST_STATUS.APPROVED_TM ||
                        p.status === REQUEST_STATUS.ALLOCATED_ME ||
                        p.status === REQUEST_STATUS.ALLOCATED_TM ||
                        p.status === REQUEST_STATUS.APPROVED_ME ? (
                        <StyledStatusApproved onClick={() => ""}>
                          {p.status}
                        </StyledStatusApproved>
                      ) : p.status === REQUEST_STATUS.ALLOCATION_CANCELLED_TM ||
                        p.status === REQUEST_STATUS.ALLOCATION_CANCELLED_ME ||
                        p.status === REQUEST_STATUS.REDIRECTION_REJECTED_TM ||
                        p.status === REQUEST_STATUS.REJECTED_LM ||
                        p.status === REQUEST_STATUS.REJECTED_TM ||
                        p.status === REQUEST_STATUS.REJECTED_ME ? (
                        <StyledStatusRejected onClick={() => ""}>
                          {p.status}
                        </StyledStatusRejected>
                      ) : p.status === REQUEST_STATUS.PENDING ||
                        p.status === REQUEST_STATUS.REDIRECTED_ME ||
                        p.status === REQUEST_STATUS.REDIRECTED_TM ||
                        p.status === REQUEST_STATUS.ALLOCATION_EDITED_TM ||
                        p.status === REQUEST_STATUS.ALLOCATION_EDITED_ME ? (
                        <StyledStatusPending onClick={() => ""}>
                          {p.status}
                        </StyledStatusPending>
                      ) : (
                        <StyledStatusDraft onClick={() => ""}>
                          {p.status}
                        </StyledStatusDraft>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>{p.vehiclePlateNo}</StyledTableCell>
                    <StyledTableCell>{p.driverName}</StyledTableCell>
                    <StyledTableCell>{p.transportCompany}</StyledTableCell>
                    <StyledTableCell>
                      {dayjs(p.allocatedOn)
                        .add(330, "minute")
                        .format("YYYY-MM-DD HH:mm")}
                    </StyledTableCell>
                    <StyledTableCell>{p.mileage}</StyledTableCell>
                    <StyledTableCell>{p.actualMileage}</StyledTableCell>
                    <StyledTableCell>{p.cost}</StyledTableCell>
                    <StyledTableCell>{p.actualCost}</StyledTableCell>
                    <StyledTableCell>
                      <Box className="layout-row">
                        <Box
                          sx={{
                            width: "100%",
                            position: "center",
                            marginInline: "4rem",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          <Typography
                            onClick={() => {
                              props.onclickOnView(p.allocationId);
                            }}
                          >
                            View
                          </Typography>
                        </Box>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box className="layout-row">
                        {/* <Box>
                          <IconButton size="small" onClick={() => {props.handleViewClick(p.allocationId)}}>
                            <Tooltip title="View">
                              <VisibilityOutlinedIcon sx={{fontSize: "20px", mr: "-1", color: "white"}}/>
                            </Tooltip>
                          </IconButton>
                        </Box> */}
                        <Box>
                          <IconButton size="small"onClick={() => {props.onClickOnMap(p.fromLocationLatitude, p.fromLocationLongitude, p.toLocationLatitude, p.toLocationLongitude)}}>
                            <Tooltip title="View On map">
                              <MapIcon sx={{ fontSize: "20px", mr: "-1", color: "white"}}
                              />
                            </Tooltip>
                          </IconButton>
                        </Box>
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
          {!props.isLoading && props.filteredList.length === 0 && (
            <TableBody>
              <TableRow>
                <StyledTableCell align="left" colSpan={15}>
                  No data to preview
                </StyledTableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_OPTIONS}
        component="div"
        labelRowsPerPage={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              color: "white",
            }}
          >
            Items per page
          </div>
        }
        count={props.filteredList.length}
        page={props.page}
        onPageChange={props.onHandleChangePage}
        onRowsPerPageChange={props.onHandleChangeRowsPerPage}
        rowsPerPage={props.rowsPerPage}
        showFirstButton={true}
        showLastButton={true}
        sx={{ backgroundColor: "#282828", color: "white" }}
      />
    </section>
  );
};

export default CompletedTripSummary;
