import {
  COMMON_ACTION_TYPES,
  TEMPLATE_ACTION_TYPES,
} from "../../utilities/constants";
import { GetMatchingTemplatesParamDto } from "../../utilities/models";

const getTemplate = () => {
  return {
    type: TEMPLATE_ACTION_TYPES.GET_TEMPLATE_LIST + COMMON_ACTION_TYPES.REQUEST,
  };
};

export const deleteTemplate = (id: number) => {
  return {
    type: TEMPLATE_ACTION_TYPES.DELETE_TEMPLATE + COMMON_ACTION_TYPES.REQUEST,
    id: id,
  };
};

export const getMatchingTemplateList = (param: GetMatchingTemplatesParamDto) => {
  return {
    type: TEMPLATE_ACTION_TYPES.GET_MATCHING_TEMPLATE_LIST + COMMON_ACTION_TYPES.REQUEST,
    param: param,
  };
};

export const getMatchingTemplateListClear = () => {
  return { type: TEMPLATE_ACTION_TYPES.GET_MATCHING_TEMPLATE_LIST + COMMON_ACTION_TYPES.CLEAR };
}

export const templateActions = {
  getTemplate,
  deleteTemplate,
  getMatchingTemplateList,
  getMatchingTemplateListClear,
};
