import React from 'react'
import style from './TMAllocationSummaryDataGrid.module.scss'
import { Typography, Box, Paper, TableContainer, TableHead, Table, TableRow, TableBody, IconButton, Tooltip, TablePagination } from '@mui/material'
import { AppSkeleton, CustomButton, CustomHeaderCell } from '../Shared'
import { StickyTableCell, StyledCheckBox, StyledStatusApproved, StyledStatusDraft, StyledStatusPending, StyledStatusRejected, StyledTableCell } from '../../assets/theme/theme'
import { APP_TABLE_CONFIGS, REQUEST_STATUS } from '../../utilities/constants'
import MapIcon from '@mui/icons-material/Map';
import CancelIcon from '@mui/icons-material/Cancel';
import { EditOutlined } from '@mui/icons-material'
import { allocationHistoryDto } from '../../utilities/models'
import dayjs from 'dayjs'

const TMAllocationSummaryDataGrid: React.FC<{
  isOpenAddPackagePopup: boolean,
  page: number,
  rowsPerPage: number,
  isFiltered: boolean,
  onHandleChangePage(event: unknown, newPage: number): void,
  onclickOnView(allocationId: string): void,
  onHandleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void,
  filteredList: allocationHistoryDto[],
  onSortHandle(col: string): void
  onFilterHandle(col: string, value: any): void;
  getFilterList: (col: string) => string[];
  onSeeAllocationHistory(): void;
  isLoading: boolean;
  setIsOpenAddPackagePopup(value: boolean): void
  onclickOnCancelAllocation(allocationId: string): void;
  onClickOnMap(fLat:string, fLon:string, toLat:string, toLon:string):void;
  onSelectRequestIds(id: string, value: boolean): void;
  onClearFilter(): void;
  handleEditClick(allocationId: string): void;
  onNavigateBack():void;
}> = (props) => {
  return (
    <section className={style.gridContainer}>
      <div className={style.gridHeader}>
        <Typography
          noWrap
          component="div"
          className={style.gridTitle}
        >
          <h3>Allocation Summary</h3>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <div className='layout-row'>
        <CustomButton text='Back' border='0px solid #6e6e6e' bgColor='transparent' isShadow='none' textDecoration='underline' onClick={() => { props.onNavigateBack() }} />
          {props.isFiltered &&
            <CustomButton text='Clear filter' textColor='black' bgColor='#bfbfbf' onClick={props.onClearFilter} />
          }
        </div>
      </div>
      <TableContainer component={Paper} className={style.grid}>
        <Table className={style.table}>
          <TableHead>
            <TableRow>
              {/* <StickyTableCell width={80} ><StyledCheckBox size="small" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onSelectRequestIds('ALL', event.target.checked)} /></StickyTableCell> */}
              <CustomHeaderCell width={150} id='allocationId' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Allocation ID</CustomHeaderCell>
              <CustomHeaderCell width={180} id='vehicleType' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Vehicle Type</CustomHeaderCell>
              <CustomHeaderCell width={180} id='status' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Status</CustomHeaderCell>
              <CustomHeaderCell width={180} id='vehiclePlateNo' sortable onSort={props.onSortHandle}  >Vehicle License Plate Number</CustomHeaderCell>
              <CustomHeaderCell width={180} id='driverName' sortable onSort={props.onSortHandle}  filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>	Vehicle Driver Name</CustomHeaderCell>
              <CustomHeaderCell width={180} id='transportCompany' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Transport Company Name</CustomHeaderCell>
              <CustomHeaderCell width={240} id='allocatedOn' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Vehicle Allocated on</CustomHeaderCell>
              <CustomHeaderCell width={240} id='mileage' sortable onSort={props.onSortHandle}>Projected mileage</CustomHeaderCell>
              <CustomHeaderCell width={240} id='cost' sortable onSort={props.onSortHandle}>Projected cost</CustomHeaderCell>
              <CustomHeaderCell width={180} id='allocatedRequisitions' >Allocated Requisitions</CustomHeaderCell>
              <CustomHeaderCell width={150} id='actions' >Actions</CustomHeaderCell>
            </TableRow>
          </TableHead>
          {props.isLoading && (
            <AppSkeleton numOfRows={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE} numOfColumns={10} columnWidth={180} height={35} stickyFirst tag="table" />
          )}
          {props.filteredList && props.filteredList.length > 0 &&
            <TableBody>
              {props.filteredList.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage).map((p: allocationHistoryDto) => (
                <TableRow key={p.allocationId}>
                  {/* <StickyTableCell ><StyledCheckBox size="small" checked={p.isSelect} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { props.onSelectRequestIds(p.allocationId, event.target.checked) }} /></StickyTableCell> */}
                  <StyledTableCell >A{p.allocationId}</StyledTableCell>
                  <StyledTableCell >{p.vehicleType}</StyledTableCell>
                   <StyledTableCell>
                    {p.status === REQUEST_STATUS.APPROVED_LM ||
                      p.status === REQUEST_STATUS.APPROVED_TM ||
                      p.status === REQUEST_STATUS.ALLOCATED_ME ||
                      p.status === REQUEST_STATUS.ALLOCATED_TM ||
                      p.status === REQUEST_STATUS.APPROVED_ME
                      ? (
                        <StyledStatusApproved onClick={() =>"" }>
                          {p.status}
                        </StyledStatusApproved>
                      ) : p.status === REQUEST_STATUS.ALLOCATION_CANCELLED_TM ||
                        p.status === REQUEST_STATUS.ALLOCATION_CANCELLED_ME ||
                        p.status === REQUEST_STATUS.REDIRECTION_REJECTED_TM ||
                        p.status === REQUEST_STATUS.REJECTED_LM ||
                        p.status === REQUEST_STATUS.REJECTED_TM ||
                        p.status === REQUEST_STATUS.REJECTED_ME
                        ? (
                          <StyledStatusRejected onClick={() =>"" }>
                            {p.status}
                          </StyledStatusRejected>
                        ) : p.status === REQUEST_STATUS.PENDING ||
                          p.status === REQUEST_STATUS.REDIRECTED_ME ||
                          p.status === REQUEST_STATUS.REDIRECTED_TM ||
                          p.status === REQUEST_STATUS.ALLOCATION_EDITED_TM ||
                          p.status === REQUEST_STATUS.ALLOCATION_EDITED_ME
                          ? (
                            <StyledStatusPending onClick={() =>"" }>
                              {p.status}
                            </StyledStatusPending>
                          ) : (
                            <StyledStatusDraft onClick={() => ""}>
                              {p.status}
                            </StyledStatusDraft>
                          )}
                  </StyledTableCell>
                  <StyledTableCell >{p.vehiclePlateNo}</StyledTableCell>
                  <StyledTableCell >{p.driverName}</StyledTableCell>
                  <StyledTableCell >{p.transportCompany}</StyledTableCell>
                  <StyledTableCell >{dayjs(p.allocatedOn).add(330, "minute").format("YYYY-MM-DD HH:mm")}</StyledTableCell>
                  <StyledTableCell >{p.mileage}</StyledTableCell>
                  <StyledTableCell >{p.cost}</StyledTableCell>
                  <StyledTableCell   >
                    <Box className='layout-row'>
                      <Box sx={{
                        width: "100%", position: 'center', marginInline: "4rem", cursor: "pointer", textDecoration: 'underline'
                      }}>
                        <Typography onClick={() => { props.onclickOnView(p.allocationId) }} >View</Typography>
                      </Box>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell >
                    <Box className='layout-row'>
                      <Box>
                        <IconButton size='small' onClick={() => { props.handleEditClick(p.allocationId) }}>
                          <Tooltip title="Edit">
                            <EditOutlined sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton size='small' onClick={() => {props.onClickOnMap(p.fromLocationLatitude, p.fromLocationLongitude, p.toLocationLatitude, p.toLocationLongitude)}}>
                          <Tooltip title="View On map">
                            <MapIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton size='small' onClick={() => { props.onclickOnCancelAllocation(p.allocationId) }}>
                          <Tooltip title="Cancel Allocation">
                            <CancelIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          }
          {!props.isLoading && props.filteredList.length === 0 &&
            <TableBody>
              <TableRow>
                <StyledTableCell align="left" colSpan={15}>No data to preview</StyledTableCell>
              </TableRow>
            </TableBody>
          }
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_OPTIONS}
        component="div"
        labelRowsPerPage={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: 'white',
            }}
          >
            Items per page
          </div>
        }
        count={props.filteredList.length}
        page={props.page}
        onPageChange={props.onHandleChangePage}
        onRowsPerPageChange={props.onHandleChangeRowsPerPage}
        rowsPerPage={props.rowsPerPage}
        showFirstButton={true}
        showLastButton={true}
        sx={{ backgroundColor: "#282828", color: "white" }}
      />
    </section>
  )
}

export default TMAllocationSummaryDataGrid