import {ENV_VARIABLES} from "./index";

export let ENV_PROD: ENV_VARIABLES = {
    APP_ENV: "prod",
    API_BASE: "https://mas-tms-dev-api-eastus.azurewebsites.net",
    AUTHORITY: "https://login.microsoftonline.com/f1db2653-6cf0-44c7-ac82-2ff963d4b481",
    CLIENT_ID: "e379a702-061b-4eef-b6ea-f2397cfb34d5",
    REDIRECT_URL: "https://tms.acentura.com",
    APP_SCOPES: ["openid"],
    GOOGLE_MAPS_API_KEY:"AIzaSyDOzShj91B7Gaz7Vm3Dotk2oZ27W-6HzPk"
}
