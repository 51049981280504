import { COMMON_ACTION_TYPES, PERMISSION_ACTION_TYPES,} from "../../utilities/constants";
import { AssignRolesforUsersParamDto, EditRolesforusersParamDto, GetAllUsersWithEntitlementParam, emailId,} from "../../utilities/models/permission.model";

const getUsersByDeptId = (deptId: number) => {
  return {
    type:PERMISSION_ACTION_TYPES.GET_USERS_LIST_BY_DEPT_ID + COMMON_ACTION_TYPES.REQUEST,
    deptId: deptId,
  };
};

const getRolesPermissions = () => {
  return {
    type:PERMISSION_ACTION_TYPES.GET_ROLES_PERMISSIONS + COMMON_ACTION_TYPES.REQUEST,
  };
};

const postAssignRolesUsers = (payload: AssignRolesforUsersParamDto) => {
  return {
    type: PERMISSION_ACTION_TYPES.POST_ASSIGN_ROLES_FOR_USERS + COMMON_ACTION_TYPES.REQUEST,
    payload: payload,
  };
};

const postAssignRolesUsersClear = () => {
  return {
    type: PERMISSION_ACTION_TYPES.POST_ASSIGN_ROLES_FOR_USERS + COMMON_ACTION_TYPES.CLEAR,
  };
};

const editAssignRolesUsers = (payload: EditRolesforusersParamDto) => {
  return {
    type:PERMISSION_ACTION_TYPES.EDIT_ASSIGN_ROLES_FOR_USERS + COMMON_ACTION_TYPES.REQUEST,
    payload: payload,
  };
};

const editAssignRolesUsersClear = () => {
  return {
    type:PERMISSION_ACTION_TYPES.EDIT_ASSIGN_ROLES_FOR_USERS + COMMON_ACTION_TYPES.CLEAR,
  };
};

const getAllUserWithEntitlements = (payload: GetAllUsersWithEntitlementParam) => {
  return {
    type: PERMISSION_ACTION_TYPES.GET_ALL_USER_WITH_ENTITLEMENT + COMMON_ACTION_TYPES.REQUEST,
    payload: payload,
  };
};

const getSelecteUserDetailsById = (id: number) => {
  return {
    type:PERMISSION_ACTION_TYPES.GET_SELECTED_USER_DETAILS + COMMON_ACTION_TYPES.REQUEST,
    id: id,
  };
};

const getSelecteUserDetailsByIdClear = () => {
  return{
    type:PERMISSION_ACTION_TYPES.GET_SELECTED_USER_DETAILS + COMMON_ACTION_TYPES.CLEAR,
  }
}

const getRolesAndEntitlementByUser = (payload:emailId ) => {
  return {
    type: PERMISSION_ACTION_TYPES.GET_ROLES_AND_ENTITLEMENT_BY_USER + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }
}

export const permissionActions = {
  getUsersByDeptId,
  getRolesPermissions,
  postAssignRolesUsers,
  editAssignRolesUsers,
  getAllUserWithEntitlements,
  getSelecteUserDetailsById,
  getRolesAndEntitlementByUser,
  getSelecteUserDetailsByIdClear,
  postAssignRolesUsersClear,
  editAssignRolesUsersClear
};