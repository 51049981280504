import { AxiosResponse } from "axios";
import { ApproverBriefDto, DefaultApproverDto } from "../utilities/models";
import { axiosPrivateInstance } from ".";

const getApprovers = (param: string): Promise<AxiosResponse<ApproverBriefDto[]>> => {
    return axiosPrivateInstance.get(`/api/Approver?approverName=${param}`)
}

const getDefaultApprover = (depId: number): Promise<AxiosResponse<DefaultApproverDto>> => {
    return axiosPrivateInstance.get(`/api/Approver/DefaultApprover?departmentId=${depId}`)
}

export const approverService = {
    getApprovers,
    getDefaultApprover
}
