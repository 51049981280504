import { APP_ACTION_STATUS, COMMON_ACTION_TYPES, MAP_ACTION_TYPE } from "../../utilities/constants";
import { MapStateDto } from "../../utilities/models";

const INITIAL_STATE: MapStateDto = {
  AddStartMapPoint: {
    data: {
      lat: 0,
      lng: 0
    },
    error: null,
    isLoading: false,
    status: null
  },
  AddEndMapPoint: {
    data: {
      lat: 0,
      lng: 0
    },
    error: null,
    isLoading: false,
    status: null

  },
  ADDPickUpPoints: {
    data: [],
    error: null,
    isLoading: false,
    status: null

  },
  addDropOffPoints: {
    data: [],
    error: null,
    isLoading: false,
    status: null

  },
  setTotalDistance: {
    data: "",
    error: null,
    isLoading: false,
    status: null
  },
  setTotalDuration: {
    data: 0,
    error: null,
    isLoading: false,
    status: null
  }
}

const mapReducer = (state = INITIAL_STATE, action: any) => {

  switch ((action.type)) {
    case MAP_ACTION_TYPE.ADD_MAP_START_POINT + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        AddStartMapPoint: {
          ...state.AddStartMapPoint,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null
        }
      }
    case MAP_ACTION_TYPE.ADD_MAP_START_POINT + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        AddStartMapPoint: {
          ...state.AddStartMapPoint,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.payload
        }
      }
    case MAP_ACTION_TYPE.ADD_MAP_START_POINT + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        AddStartMapPoint: {
          ...state.AddStartMapPoint,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null
        }
      }
    case MAP_ACTION_TYPE.ADD_MAP_START_POINT + COMMON_ACTION_TYPES.CLEAR:

      return {
        ...state,
        AddStartMapPoint: {
          ...state.AddStartMapPoint,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null
        }
      }
    case MAP_ACTION_TYPE.ADD_MAP_END_POINT + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        AddEndMapPoint: {
          ...state.AddEndMapPoint,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null
        }
      }
    case MAP_ACTION_TYPE.ADD_MAP_END_POINT + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        AddEndMapPoint: {
          ...state.AddEndMapPoint,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.payload
        }
      }
    case MAP_ACTION_TYPE.ADD_MAP_END_POINT + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        AddEndMapPoint: {
          ...state.AddEndMapPoint,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null
        }
      }
    case MAP_ACTION_TYPE.ADD_MAP_END_POINT + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        AddEndMapPoint: {
          ...state.AddEndMapPoint,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null
        }
      }
    case MAP_ACTION_TYPE.ADD_MAP_PICKUP_POINT + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        ADDPickUpPoints: {
          ...state.ADDPickUpPoints,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: []
        }
      }
    case MAP_ACTION_TYPE.ADD_MAP_PICKUP_POINT + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        ADDPickUpPoints: {
          ...state.ADDPickUpPoints,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.payload
        }
      }
    case MAP_ACTION_TYPE.ADD_MAP_PICKUP_POINT + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        ADDPickUpPoints: {
          ...state.ADDPickUpPoints,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: []
        }
      }
    case MAP_ACTION_TYPE.ADD_MAP_PICKUP_POINT + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        ADDPickUpPoints: {
          ...state.ADDPickUpPoints,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: []
        }
      }
    case MAP_ACTION_TYPE.ADD_MAP_DROPOFF_POINT + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        addDropOffPoints: {
          ...state.addDropOffPoints,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: []
        }
      }
    case MAP_ACTION_TYPE.ADD_MAP_DROPOFF_POINT + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        addDropOffPoints: {
          ...state.addDropOffPoints,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.payload
        }
      }
    case MAP_ACTION_TYPE.ADD_MAP_DROPOFF_POINT + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        addDropOffPoints: {
          ...state.addDropOffPoints,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: []
        }
      }
    case MAP_ACTION_TYPE.ADD_MAP_DROPOFF_POINT + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        addDropOffPoints: {
          ...state.addDropOffPoints,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: []
        }
      }
    case MAP_ACTION_TYPE.SET_TOTAL_DISTANCE + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        setTotalDistance: {
          ...state.setTotalDistance,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null
        }
      }
    case MAP_ACTION_TYPE.SET_TOTAL_DISTANCE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        setTotalDistance: {
          ...state.setTotalDistance,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.payload
        }
      }
    case MAP_ACTION_TYPE.SET_TOTAL_DISTANCE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        setTotalDistance: {
          ...state.setTotalDistance,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null
        }
      }
    case MAP_ACTION_TYPE.SET_TOTAL_DISTANCE + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        setTotalDistance: {
          ...state.setTotalDistance,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null
        }
      }
      case MAP_ACTION_TYPE.SET_TOTAL_DURATION + COMMON_ACTION_TYPES.REQUEST:
        return {
          ...state,
          setTotalDuration: {
            ...state.setTotalDuration,
            isLoading: true,
            status: APP_ACTION_STATUS.LOADING,
            error: null,
            data: null
          }
        }
      case MAP_ACTION_TYPE.SET_TOTAL_DURATION + COMMON_ACTION_TYPES.SUCCESS:
        return {
          ...state,
          setTotalDuration: {
            ...state.setTotalDuration,
            isLoading: false,
            status: APP_ACTION_STATUS.SUCCESS,
            error: null,
            data: action.payload
          }
        }
      case MAP_ACTION_TYPE.SET_TOTAL_DURATION + COMMON_ACTION_TYPES.ERROR:
        return {
          ...state,
          setTotalDuration: {
            ...state.setTotalDuration,
            isLoading: false,
            status: APP_ACTION_STATUS.ERROR,
            error: action.error,
            data: null
          }
        }
      case MAP_ACTION_TYPE.SET_TOTAL_DURATION + COMMON_ACTION_TYPES.CLEAR:
        return {
          ...state,
          setTotalDuration: {
            ...state.setTotalDuration,
            isLoading: false,
            status: APP_ACTION_STATUS.INITIAL,
            error: null,
            data: null
          }
        }  
    default:
      return state
  }
}
export default mapReducer