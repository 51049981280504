import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IpassengerDetails } from '../types/TransportCompanyDto';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalInstance } from '..'; // Make sure to import msalInstance from the appropriate location
import { APP_CONFIGS } from '../utilities/constants'


  
  export const getTransportCompanies = createAsyncThunk('get/transportcompanies', async () => {
    const response = await axios.get(`https://mas-tms-dev-api-eastus.azurewebsites.net/api/Transport/GetAllTransportComapanies`)
    const companylist:any[]=response.data;
    return  response.data;
  })


 

  export const getSBUs = createAsyncThunk('get/SBUs', async (userId: number) => {
    const msalRequest = {
      scopes: APP_CONFIGS.APP_SCOPES,
    };
  
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(msalRequest);
      const headers = {
        Authorization: `Bearer ${tokenResponse?.accessToken}`,
      };
  
      const response = await axios.get(
        `https://mas-tms-dev-api-eastus.azurewebsites.net/api/User/PassengerDetails?userId=${0}`,
        { headers }
      );
  
      const passengerDetails: IpassengerDetails = response.data;
      return passengerDetails;


    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Fallback to interaction when silent call fails
        await msalInstance.acquireTokenRedirect(msalRequest);
      }
  
      throw error;
    }
  });


  export const getBank = createAsyncThunk('get/Bank', async () => {
    const msalRequest = {
      scopes: APP_CONFIGS.APP_SCOPES,
    };
  
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(msalRequest);
      const headers = {
        Authorization: `Bearer ${tokenResponse?.accessToken}`,
      };
  
      const response = await axios.get(
        'https://mas-tms-dev-api-eastus.azurewebsites.net/api/Transport/GetBankList',
        { headers }
      );
  
      const bankList: any[] = response.data;
      return bankList;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Fallback to interaction when silent call fails
        await msalInstance.acquireTokenRedirect(msalRequest);
      }
  
      throw error;
    }
  });



  export const getBranch = createAsyncThunk('get/Branch', async () => {
    const msalRequest = {
      scopes: APP_CONFIGS.APP_SCOPES,
    };
  
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(msalRequest);
      const headers = {
        Authorization: `Bearer ${tokenResponse?.accessToken}`,
      };
  
      const response = await axios.get(
        'https://mas-tms-dev-api-eastus.azurewebsites.net/api/Transport/GetBranchList',
        { headers }
      );
  
      const branchList: any[] = response.data;
      return branchList;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Fallback to interaction when silent call fails
        await msalInstance.acquireTokenRedirect(msalRequest);
      }
  
      throw error;
    }
  });



  export const getCompanyViewList = createAsyncThunk('get/CompanyView', async () => {
    const msalRequest = {
      scopes: APP_CONFIGS.APP_SCOPES,
    };
  
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(msalRequest);
      const headers = {
        Authorization: `Bearer ${tokenResponse?.accessToken}`,
      };
  
      const response = await axios.get('/api/Transport/GetCompanyDetails', { headers });
  
      const companyViewList: any[] = response.data;
      return companyViewList;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Fallback to interaction when silent call fails
        await msalInstance.acquireTokenRedirect(msalRequest);
      }
  
      throw error;
    }
  });


  export const insertCompany = createAsyncThunk('posts/createCompany', async (transportcompany: any) => {
    const msalRequest = {
      scopes: APP_CONFIGS.APP_SCOPES,
    };
  
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(msalRequest);
      const headers = {
        Authorization: `Bearer ${tokenResponse?.accessToken}`,
      };
  
      const response = await axios.post('api/Transport/AddTransportCompany', transportcompany, { headers });
  
      return response.data;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Fallback to interaction when silent call fails
        await msalInstance.acquireTokenRedirect(msalRequest);
      }
  
      throw error;
    }
  });
  

 

