import CircularProgress from "@mui/material/CircularProgress/CircularProgress";

const CustomCircularProgress: React.FC<{
    size?:number,
    color?:string,
}> = (props) => {
    return(
        <CircularProgress size={24} color="primary" />
    )
}

export default CustomCircularProgress;