import { AxiosResponse } from "axios"
import { PassengerBriefDto, PassengerDetailsByIdDto } from "../utilities/models"
import { axiosPrivateInstance } from "."

const getPassengerById = (userId: number): Promise<AxiosResponse<PassengerDetailsByIdDto>> => {
    return axiosPrivateInstance.get(`/api/User/PassengerDetails?userId=${userId}`)
}

const getAllPassengers = (name: string): Promise<AxiosResponse<PassengerBriefDto[]>> => {
    return axiosPrivateInstance.get(`/api/User/GetAllPassengers?name=${name}`)
}

const getAllPassengersByDepartment = (id: number): Promise<AxiosResponse<PassengerBriefDto[]>> => {
    return axiosPrivateInstance.get(`/api/User/GetAllPassengersByDepartment?departmentId=${id}`)
}

const getAllRecipients = (name: string): Promise<AxiosResponse<PassengerBriefDto[]>> => {
    return axiosPrivateInstance.get(`/api/User/GetAllRecipient?name=${name}`)
}

export const userService = {
    getAllPassengers,
    getPassengerById,
    getAllPassengersByDepartment,
    getAllRecipients,
}