import { Grid, Box, Typography } from "@mui/material";
import React from "react";
import { StyledTextField, StyledSwitch } from "../../../assets/theme/theme";
import style from "./MyProfile.module.scss";
import { GetRolesAndEntitlementDto, PermissionDto, hierarchiesDTO } from "../../../utilities/models/permission.model";
import RadioButtonCheckedTwoToneIcon from "@mui/icons-material/RadioButtonCheckedTwoTone";
import { AuthorizedUserDto, HierarchyDto } from "../../../utilities/models";

const MyProfile :React.FC<{
  RolesAndEntitlement?:AuthorizedUserDto;
  hierarchy:HierarchyDto[];
  permissions:PermissionDto[];
  
}> = (props) => {

  const Sbu = props.hierarchy.find((item:HierarchyDto) => item.type === 'SBU')?.name ?? '';
  const plant = props.hierarchy.find((item:HierarchyDto) => item.type === 'Plant')?.name ?? '';
  const department = props.hierarchy.find((item:HierarchyDto) => item.type === 'Department')?.name ?? ''
  
  return (
    <section>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box className={style.label}>
            <label>Personal</label>
          </Box>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12} sm={12}>
            <Box className={style.label2}>
               
            </Box>
              <StyledTextField
                value={props.RolesAndEntitlement?.firstName||''}
                label="First Name"
                placeholder="First Name"
                fullWidth
                size="small"
              
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <Box className={style.label2}>
               
              </Box>
              <StyledTextField
                    label="Last Name"
              fullWidth
                placeholder="Last Name"
                size="small"
                value={props.RolesAndEntitlement?.lastName||""}
                inputProps={{ readOnly: true }}
              />
            </Grid>


          </Grid>
          <Grid item md={12} >
          <Box className={style.label2}>
               
               </Box>
            <StyledTextField
             label="SBU"
            fullWidth
            placeholder="SBU"
            size="small"
            value={Sbu}
            inputProps={{ readOnly: true }}
          />
          
            </Grid>
            <Grid item md={12} >
            <Box className={style.label2}>
          
          </Box>
            <StyledTextField
                label="Plant"
            fullWidth
            placeholder="Plant"
            size="small"
            value={plant}
            inputProps={{ readOnly: true }}
          />
          
            </Grid>
            <Grid item md={12} >
            <Box className={style.label2}>
       
          </Box>
            <StyledTextField
                 label="Department"
            fullWidth
            placeholder="Department"
            size="small"
            value={department}
            inputProps={{ readOnly: true }}
          />
          
            </Grid>
         
              <Box className={style.label2}>
               
              </Box>
              <StyledTextField
                  label="Role Name"
              fullWidth
                placeholder="Role Name"
                size="small"
                value={props.RolesAndEntitlement?.roleName||''}
                inputProps={{ readOnly: true }}
              />
         
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className={style.label}>
            <label>Contact</label>
          </Box>
          <Grid container spacing={2}>
            <Grid item md={6} xs={10} sm={12}>
              <Box className={style.label2}>
           
              </Box>
              <StyledTextField
                 label="Contact-Mobile"
              fullWidth
                placeholder="Contact-Mobile"
                size="small"
                value={props.RolesAndEntitlement?.contactNumber||""}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={6} xs={2} sm={12}>
              <Box className={style.label2}>
                {" "}
             
              </Box>
              <StyledTextField
                    label="Contact-Home"
              fullWidth
                placeholder="Contact-Home"
                size="small"
                value={props.RolesAndEntitlement?.homeContactNumber||""}
                inputProps={{ readOnly: true }}
              />
            </Grid>

          </Grid>
          <Grid item md={12} >
            <Box className={style.label2}>
         
          </Box>
            <StyledTextField
               label="Email"
            fullWidth
            placeholder="Email"
            size="small"
            value={props.RolesAndEntitlement?.emailId||""}
            inputProps={{ readOnly: true }}
          />
          
            </Grid>
            <Grid item md={12} >
            <Box className={style.label2}>
         
          </Box>
            <StyledTextField
              label="Address"
            fullWidth
            placeholder="Address"
            size="small"
            value={props.RolesAndEntitlement?.address||""}
            inputProps={{ readOnly: true }}
          />
          
            </Grid>
         
         
        </Grid>
        <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
        <Box className={style.lable3}>
            <label>Entitlement List</label>
        </Box>
     
           </Grid>
      
          </Grid>
          <Grid container spacing={1}>
          {props.permissions.map((permission,index) => (   
           <Grid item xs={12} md={6} key={index} >
          <Box sx={{marginLeft:"2rem",marginBottom:"1rem"}}key={index}>  
          <RadioButtonCheckedTwoToneIcon sx={{ fontSize: "20px", color: "white" ,marginInline:"1rem"}} />
          {permission.type}
          </Box>
           </Grid>
          ))}
               </Grid>
      </Grid>
    </section>
  );
};

export default MyProfile;
