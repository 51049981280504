import { AxiosResponse } from "axios"
import { shiftService } from "../../services/shift.service"
import { ALERT_ACTION_TYPES, COMMON_ACTION_TYPES, SHIFT_ACTION_TYPES } from "../../utilities/constants/action.constant"
import { AlertDto } from "../../utilities/models/alert.model"
import { call, put, take, takeEvery } from "redux-saga/effects"
import { DownloadFileResponseDto, GetExecutionTemplateDto, duplicateShiftRequestDto, getExecuteTemplateByIdDto, getPassengerRoutesByIdDto, newShiftRequestDto, shiftGroupBriefDto, shiftRequestByIdDto, shiftRequestSummaryListDto, shiftRequestSummaryParam, shiftTypeBriefDto, shiftVehicleDetailsParamDto } from "../../utilities/models/shift.model"
import { VehicleTypesDto } from "../../utilities/models/vehicle.model"
import { fileUploadDto } from "../../utilities/models/common.model"


function* uploadFile(action: { type: string, payload: fileUploadDto }) {
  try {
    const uploadFileResponse: AxiosResponse<string> = yield call(shiftService.uploadFile, action.payload)
    const setAlert: AlertDto = {
      message: "File Uploaded",
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: SHIFT_ACTION_TYPES.SHIFT_UPLOAD_FILE + COMMON_ACTION_TYPES.SUCCESS,
      data: uploadFileResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: SHIFT_ACTION_TYPES.SHIFT_UPLOAD_FILE + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* downloadShiftFile(action: { type: string, payload: string }) {
  try {
    const fileDownloadResponse: AxiosResponse<DownloadFileResponseDto> = yield call(shiftService.downloadShiftFile, action.payload)

    const base64Data = fileDownloadResponse.data.base64
    const binaryString = atob(base64Data);
    const byteArray = new Uint8Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([byteArray], { type: fileDownloadResponse.data.contentType });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'Document'; // Replace with desired file name and extension
    link.click();

    URL.revokeObjectURL(url); // Clean up the URL when done

    yield put({
      type: SHIFT_ACTION_TYPES.SHIFT_DOWNLOAD_FILE + COMMON_ACTION_TYPES.SUCCESS,
      data: fileDownloadResponse.data,
    })
    const setAlert: AlertDto = {
      message: "File Downloaded",
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: SHIFT_ACTION_TYPES.SHIFT_DOWNLOAD_FILE + COMMON_ACTION_TYPES.ERROR,
      error: error
      ,
    })
  }
}

function* deleteFile(action: { type: string, payload: string }) {
  try {
    const deleteFileResponse: AxiosResponse<string> = yield call(shiftService.deleteFile, action.payload)
    const setAlert: AlertDto = {
      message: "File deleted successfully",
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: SHIFT_ACTION_TYPES.SHIFT_DELETE_FILE + COMMON_ACTION_TYPES.SUCCESS,
      data: deleteFileResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: SHIFT_ACTION_TYPES.SHIFT_DELETE_FILE + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getShiftTypes(action: { type: string }) {
  try {
    const shiftTypes: AxiosResponse<shiftTypeBriefDto[]> = yield call(shiftService.getShiftTypes)
    yield put({
      type: SHIFT_ACTION_TYPES.GET_SHIFT_TYPES + COMMON_ACTION_TYPES.SUCCESS,
      data: shiftTypes.data
    })
  } catch (error) {
    yield put({
      type: SHIFT_ACTION_TYPES.GET_SHIFT_TYPES + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getShiftGroups(action: { type: string }) {
  try {
    const shiftGroup: AxiosResponse<shiftGroupBriefDto[]> = yield call(shiftService.getShiftGroups)
    yield put({
      type: SHIFT_ACTION_TYPES.GET_SHIFT_GROUPS + COMMON_ACTION_TYPES.SUCCESS,
      data: shiftGroup.data
    })
  } catch (error) {
    yield put({
      type: SHIFT_ACTION_TYPES.GET_SHIFT_GROUPS + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getAvailableShiftVehicles(action: { type: string, payload: shiftVehicleDetailsParamDto }) {
  try {
    const VehicleList: AxiosResponse<VehicleTypesDto> = yield call(shiftService.getAvailableShiftVehicles, action.payload)
    yield put({
      type: SHIFT_ACTION_TYPES.GET_AVAILABLE_SHIFT_VEHICLES + COMMON_ACTION_TYPES.SUCCESS,
      data: VehicleList,
    })
  } catch (error) {
    yield put({
      type: SHIFT_ACTION_TYPES.GET_AVAILABLE_SHIFT_VEHICLES + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* postShiftRequest(action: { type: string, payload: newShiftRequestDto }) {
  try {
    const createShiftRequestRes: AxiosResponse<string> = yield call(shiftService.postShiftRequest, action.payload)
    yield put({
      type: SHIFT_ACTION_TYPES.POST_SHIFT_REQUEST + COMMON_ACTION_TYPES.SUCCESS,
      data: createShiftRequestRes.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: SHIFT_ACTION_TYPES.POST_SHIFT_REQUEST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* postShiftRequestDuplicate(action: { type: string, payload: duplicateShiftRequestDto }) {
  try {
    const createShiftRequestRes: AxiosResponse<string> = yield call(shiftService.postShiftRequestDuplicate, action.payload)
    yield put({
      type: SHIFT_ACTION_TYPES.POST_SHIFT_DUPLICATE + COMMON_ACTION_TYPES.SUCCESS,
      data: createShiftRequestRes.data
    })
  } catch (error) {
    yield put({
      type: SHIFT_ACTION_TYPES.POST_SHIFT_DUPLICATE + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getShiftRequestList(action: { type: string, payload: shiftRequestSummaryParam }) {
  try {
    const requestList: AxiosResponse<shiftRequestSummaryListDto[]> = yield call(shiftService.getShiftRequestSummaryList, action.payload)
    yield put({
      type: SHIFT_ACTION_TYPES.GET_SHIFT_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: requestList.data
    })
  } catch (error) {
    yield put({
      type: SHIFT_ACTION_TYPES.GET_SHIFT_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getShiftRequestById(action: { type: string, requestId: string }) {
  try {
    const request: AxiosResponse<shiftRequestByIdDto> = yield call(shiftService.getShiftRequestById, action.requestId)
    yield put({
      type: SHIFT_ACTION_TYPES.GET_SHIFT_REQUEST + COMMON_ACTION_TYPES.SUCCESS,
      data: request.data
    })
  } catch (error) {
    yield put({
      type: SHIFT_ACTION_TYPES.GET_SHIFT_REQUEST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* deleteShiftRequest(action: { type: string, id: number }) {
  try {
    const deleteRequestResponse: AxiosResponse<string> = yield call(shiftService.deleteShiftRequest, action.id)
    const setAlert: AlertDto = {
      message: "Request deleted successfully",
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: SHIFT_ACTION_TYPES.DELETE_SHIFT_REQUEST + COMMON_ACTION_TYPES.SUCCESS,
      data: deleteRequestResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: SHIFT_ACTION_TYPES.DELETE_SHIFT_REQUEST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* editShiftRequest(action: { type: string, payload: any }) {
  try {
    const editRequestResponse: AxiosResponse<string> = yield call(shiftService.editShiftRequest, action.payload)
    yield put({
      type: SHIFT_ACTION_TYPES.EDIT_SHIFT_REQUEST + COMMON_ACTION_TYPES.SUCCESS,
      data: editRequestResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: SHIFT_ACTION_TYPES.EDIT_SHIFT_REQUEST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* GetExecutionTemplates(action: { type: string }) {
  try {
    const GetExecutionTemplatesResponse: AxiosResponse<GetExecutionTemplateDto[]> = yield call(shiftService.getExecutionTemplates)
    yield put({
      type: SHIFT_ACTION_TYPES.GET_EXECUTION_TEMPLATES + COMMON_ACTION_TYPES.SUCCESS,
      data: GetExecutionTemplatesResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: SHIFT_ACTION_TYPES.GET_EXECUTION_TEMPLATES + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* executeShiftRequest(action: { type: string, payload: any }) {
  try {
    const executeRequestResponse: AxiosResponse<string> = yield call(shiftService.executeShiftRequest, action.payload)
    yield put({
      type: SHIFT_ACTION_TYPES.EXECUTE_SHIFT_REQUEST + COMMON_ACTION_TYPES.SUCCESS,
      data: executeRequestResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: SHIFT_ACTION_TYPES.EXECUTE_SHIFT_REQUEST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getExecutedTemplateById(action: { type: string, dailyShiftHistoryId:string }) {
  try {
    const request: AxiosResponse<getExecuteTemplateByIdDto> = yield call(shiftService.getExecutedTemplateById, action.dailyShiftHistoryId)
    yield put({
      type: SHIFT_ACTION_TYPES.GET_EXECUTED_TEMPLATE + COMMON_ACTION_TYPES.SUCCESS,
      data: request.data
    })
  } catch (error) {
    yield put({
      type: SHIFT_ACTION_TYPES.GET_EXECUTED_TEMPLATE + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* shiftSaga() {
  yield takeEvery(SHIFT_ACTION_TYPES.SHIFT_UPLOAD_FILE + COMMON_ACTION_TYPES.REQUEST, uploadFile)
  yield takeEvery(SHIFT_ACTION_TYPES.SHIFT_DOWNLOAD_FILE + COMMON_ACTION_TYPES.REQUEST, downloadShiftFile)
  yield takeEvery(SHIFT_ACTION_TYPES.SHIFT_DELETE_FILE + COMMON_ACTION_TYPES.REQUEST, deleteFile)
  yield takeEvery(SHIFT_ACTION_TYPES.GET_SHIFT_TYPES + COMMON_ACTION_TYPES.REQUEST, getShiftTypes)
  yield takeEvery(SHIFT_ACTION_TYPES.GET_SHIFT_GROUPS + COMMON_ACTION_TYPES.REQUEST, getShiftGroups)
  yield takeEvery(SHIFT_ACTION_TYPES.GET_AVAILABLE_SHIFT_VEHICLES + COMMON_ACTION_TYPES.REQUEST, getAvailableShiftVehicles)
  yield takeEvery(SHIFT_ACTION_TYPES.POST_SHIFT_REQUEST + COMMON_ACTION_TYPES.REQUEST, postShiftRequest)
  yield takeEvery(SHIFT_ACTION_TYPES.POST_SHIFT_DUPLICATE + COMMON_ACTION_TYPES.REQUEST, postShiftRequestDuplicate)
  yield takeEvery(SHIFT_ACTION_TYPES.GET_SHIFT_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.REQUEST, getShiftRequestList)
  yield takeEvery(SHIFT_ACTION_TYPES.GET_SHIFT_REQUEST + COMMON_ACTION_TYPES.REQUEST, getShiftRequestById)
  yield takeEvery(SHIFT_ACTION_TYPES.DELETE_SHIFT_REQUEST + COMMON_ACTION_TYPES.REQUEST, deleteShiftRequest)
  yield takeEvery(SHIFT_ACTION_TYPES.EDIT_SHIFT_REQUEST + COMMON_ACTION_TYPES.REQUEST, editShiftRequest)
  yield takeEvery(SHIFT_ACTION_TYPES.GET_EXECUTION_TEMPLATES + COMMON_ACTION_TYPES.REQUEST, GetExecutionTemplates)
  yield takeEvery(SHIFT_ACTION_TYPES.EXECUTE_SHIFT_REQUEST + COMMON_ACTION_TYPES.REQUEST, executeShiftRequest)
  yield takeEvery(SHIFT_ACTION_TYPES.GET_EXECUTED_TEMPLATE + COMMON_ACTION_TYPES.REQUEST, getExecutedTemplateById)
}

export default shiftSaga