import { AxiosResponse } from "axios"
import { axiosPrivateInstance } from "."
import { GetTripStatusDto, RealTripPayloadDto } from "../utilities/models/gps.model"

const postRealTripDetails = (payload: RealTripPayloadDto):  Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.post(`/api/GPS/InsertActualTripGPSDetails?existingTripId=${payload.existingTripId}`, payload.tripReqBody);
}

const getActualTripDetails = (existingTripId:string): Promise<AxiosResponse<GetTripStatusDto>> => {
    return axiosPrivateInstance.get(`/api/GPS/GetTripCurrentSituation?existingTripId=${existingTripId}`);
}

const resetActualTripDetails = (tripId: string): Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.put(`/api/GPS/RemoveActualTripGPSDetails?tripId=${tripId}`)
}

export const gpsService = {
    postRealTripDetails,
    getActualTripDetails,
    resetActualTripDetails
}