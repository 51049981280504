import React, { useState } from "react";
import {
  BootstrapDialog,
  BootstrapDialogActions,
  BootstrapDialogContent,
  BootstrapDialogTitle,
  StyledTextField,
} from "../../../assets/theme/theme";
import styles from "./TmRequestRejectPopupView.module.scss";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { Grid, TextField } from "@mui/material";
import { BorderAll } from "@mui/icons-material";

const TmRequestRejectPopupView: React.FC<{
  isOpenConfirmationDialog: boolean;
  onCallback(con: boolean, reason: string): void;
  title: string;
  content: string;
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
}> = (props) => {
  const [reason, setReason] = useState("");
  return (
    <BootstrapDialog
      className={styles.dialogCard}
      aria-labelledby="customized-dialog-title"
      open={props.isOpenConfirmationDialog}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => props.onCallback(false, reason)}
      >
        {props.title}
      </BootstrapDialogTitle>
      <BootstrapDialogContent>
        <Grid container spacing={3}>
          <Grid item md={12}>
            {props.content}
          </Grid>
          <Grid item md={12}>
            <StyledTextField
              type="text"
              label="Reason?"
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              style={{
                minWidth: "100%",
                color: "rgb(235, 235, 235)",
              }}
              placeholder="Optional"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Grid>
        </Grid>
      </BootstrapDialogContent>
      <BootstrapDialogActions>
        <CustomButton
          text={props.cancelButtonTitle ? props.cancelButtonTitle : "Cancel"}
          border="1px solid #6e6e6e"
          bgColor="#282828"
          onClick={() => props.onCallback(false, reason)}
        />
        <CustomButton
          text={props.confirmButtonTitle ? props.confirmButtonTitle : "Confirm"}
          onClick={() => props.onCallback(true, reason)}
        />
      </BootstrapDialogActions>
    </BootstrapDialog>
  );
};

export default TmRequestRejectPopupView;
