
import React from 'react'
import Stepper from '../../Shared/Stepper/Stepper'
import { Box, Grid, Typography } from '@mui/material'
import SuggestionCard from '../SuggestionCard/SuggestionCard'
import { AppSkeleton, CustomButton } from '../../Shared'
import { GetMatchingTemplateListdto, StateObjectDto } from '../../../utilities/models'
import { StyledTextField } from '../../../assets/theme/theme'
import GoogleMap from '../../Shared/GoogleMap/GoogleMap'
import { REQUEST_SCREEN_MODES } from '../../../utilities/constants'

const Suggestions: React.FC<{
  clickOnce: boolean
  getMatchingTemplates(): void
  selectTemplate(id: number): void
  matchingTemplatesResponse: StateObjectDto<GetMatchingTemplateListdto[]>
}> = (props) => {
  return (
    <Stepper stepNumber={4} stepTitle={"Suggestions"}>

      {sessionStorage.getItem("Mode") ===
        REQUEST_SCREEN_MODES.CREATE && (
          <>

            <section style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
              <Typography>Suggested trips</Typography><br />
              <CustomButton isLoading={props.matchingTemplatesResponse.isLoading} text='Load Matching Templates' border='1px solid #6e6e6e' bgColor='#282828' onClick={props.getMatchingTemplates} />
            </section>
            {!props.matchingTemplatesResponse.isLoading && props.matchingTemplatesResponse.data.length > 0 &&
              <section>
                <Grid container spacing={4}>
                  {props.matchingTemplatesResponse.data.map((t) => (
                    <Grid item xs={12} md={2}>
                      <SuggestionCard data={t} onClick={() => props.selectTemplate(t.id)} />
                    </Grid>
                  ))}
                </Grid>
              </section>
            }
            {!props.matchingTemplatesResponse.isLoading && props.matchingTemplatesResponse.data.length === 0 &&
              <section>
                <StyledTextField
                  fullWidth
                  label="No Suggetion trips available"
                  placeholder='No Suggesion trips available'
                  size='small'
                  InputProps={{
                    readOnly: true
                  }}
                  value={null}
                  error={true}
                  disabled={false}
                  required={false}
                  helperText={false}
                  onFocus={() => { }}
                  onChange={() => { }}
                />
              </section>
            }
            {props.matchingTemplatesResponse.isLoading &&
              <AppSkeleton numOfRows={1} numOfColumns={2} columnWidth={50} height={40} tag="stepper" />
            }
          </>
        )}
      <Box sx={{ marginTop: "2rem" }}>
        <GoogleMap />
      </Box>

    </Stepper>
  )
}

export default Suggestions
