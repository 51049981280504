import Grid from "@mui/material/Grid/Grid";
import {UserByDeptDto, UserInformationFormDto, UserRolesEntitlementsDto, SbuBriefDto, StateObjectDto, PlantBriefDto,DepartmentBriefDto, GetSelectedUserDetailsByIdDto, entitlementscheckDto,} from "../../utilities/models";
import { Autocomplete, Chip, MenuItem, Select } from "@mui/material";
import { StyledCheckBox, StyledSelectebleTextField, } from "../../assets/theme/theme";
import React from "react";
import style from "./ManageEntitlementScreen.module.scss";
import { ALL_ENTITLEMENTS, REQUEST_SCREEN_MODES, } from "../../utilities/constants/app.constants";
import { AppSkeleton, CustomAutocomplete } from "../Shared";
import { BorderColor } from "@mui/icons-material";

const ManageEntitlementForm: React.FC<{
  userInformationForm: UserInformationFormDto;
  allSbuList: SbuBriefDto[];
  helperText: boolean;
  allPlantsBySbuIdResponse: StateObjectDto<PlantBriefDto[] | []>;
  allDepartmentByPlantIdResponse: StateObjectDto<DepartmentBriefDto[] | []>;
  onInputHandleChangeUserInformation(property: string, value: any): void;
  allDeptUserResponse: StateObjectDto<UserByDeptDto[] | []>;
  allRolesPermissionsResponse: StateObjectDto<UserRolesEntitlementsDto[] | []>;
  handleInputFocus(property: string, section: string): void;
  setSelectedOptions(value: any): void;
  selectedRoleId: number;
  ALL_ENTITLEMENTSWithChecked: entitlementscheckDto[];
  sbuReadOnlyValue: StateObjectDto<GetSelectedUserDetailsByIdDto | null>;
  screenMode: string;
  isLoading:boolean;
  setSelectedRoleId(value: number): void;
}> = (props) => {

  const _sbu = props.userInformationForm.sbu;
  const _plant = props.userInformationForm.plant;

  const _allPlants =
    props.allPlantsBySbuIdResponse !== undefined &&
      props.allPlantsBySbuIdResponse?.data?.length > 0
      ? props.allPlantsBySbuIdResponse.data
      : [];
  const _department = props.userInformationForm.department;
  const _allDepartment =
    props.allDepartmentByPlantIdResponse !== undefined &&
      props.allDepartmentByPlantIdResponse?.data?.length > 0
      ? props.allDepartmentByPlantIdResponse.data
      : [];
  const _deptUsers = props.allDeptUserResponse.data || [];
  const _allRolesPermissions =
    props.allRolesPermissionsResponse !== undefined &&
      props.allRolesPermissionsResponse?.data?.length > 0
      ? props.allRolesPermissionsResponse.data
      : [];
  const _rolesPermission = props.userInformationForm.roles;
  const itemsPerColumn = Math.ceil(ALL_ENTITLEMENTS.length / 4);

  const columns = [];
  for (let i = 0; i < ALL_ENTITLEMENTS.length; i += itemsPerColumn) {
    const column = ALL_ENTITLEMENTS.slice(i, i + itemsPerColumn);
    columns.push(column);
  }

  const readOnlyValue = props.sbuReadOnlyValue.data;
  const userObj = {
    username: readOnlyValue?.username,
  };

  return (
    <section className={style.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          {props.isLoading ? (
            <AppSkeleton numOfRows={1} numOfColumns={0} tag="formField" />
          ) : (
          <CustomAutocomplete
            label="SBU"
            placeholder="Select sbu"
            onFocus={() => props.handleInputFocus("sbu", "UI")}
            options={props.allSbuList.map((t) => {
              return { label: t.name, value: t.id };
            })}
            value={_sbu.value}
            error={!!_sbu.error}
            disabled={_sbu.disable}
            readonly={_sbu.readonly}
            required={_sbu.isRequired}
            helperText={props.helperText && _sbu.error}
            onChange={(event: any, value: any) =>
              props.onInputHandleChangeUserInformation("sbu", value)
            }
          />
          )}
          
        </Grid>
        <Grid item xs={12} md={2}>
        {props.isLoading ? (
            <AppSkeleton numOfRows={1} numOfColumns={0} tag="formField" />
          ) : (
          <CustomAutocomplete
            label="Plant"
            placeholder="Select Plant"
            onFocus={() => props.handleInputFocus("plant", "UI")}
            options={_allPlants.map((p) => {
              return { label: p.plantName, value: p.id };
            })}
            value={_plant.value}
            error={!!_plant.error}
            disabled={_plant.disable}
            readonly={_plant.readonly}
            required={_plant.isRequired}
            helperText={props.helperText && _plant.error}
            onChange={(event: any, value: any) =>
              props.onInputHandleChangeUserInformation("plant", value)
            }
          />
          )}
        </Grid>
        <Grid item xs={12} md={2}>
        {props.isLoading ? (
            <AppSkeleton numOfRows={1} numOfColumns={0} tag="formField" />
          ) : (
          <CustomAutocomplete
            label="Department"
            placeholder="Select department"
            onFocus={() => props.handleInputFocus("department", "UI")}
            options={_allDepartment.map((t) => {
              return { label: t.plantName, value: t.id };
            })}
            value={_department.value}
            error={!!_department.error}
            disabled={_department.disable}
            readonly={_department.readonly}
            required={_department.isRequired}
            helperText={props.helperText && _department.error}
            onChange={(event: any, value: any) =>
              props.onInputHandleChangeUserInformation("department", value)
            }
          />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {!props.isLoading && props.screenMode === REQUEST_SCREEN_MODES.EDIT ? (
            <Select
              required
              size="small"
              value="default"
              readOnly
              disabled
              sx={{ fieldset: { borderColor: "rgb(235, 235, 235)" , borderRadius:3} }}
              style={{
                minWidth: "100%",
                color: "rgb(235, 235, 235)",
                borderWidth: 2,
              }}
            >
              <MenuItem value="default">{userObj.username}</MenuItem>
            </Select>
          ) : (
            <Autocomplete
              multiple
              id="size-small-filled-multi"
              size="small"
              options={_deptUsers}
              sx={{ fieldset:{ borderColor: "rgb(235, 235, 235)", borderRadius:5}}}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => props.setSelectedOptions(value)}
              renderTags={(value, getTagProps) =>
                value.map((option: any, index) => (
                  <Chip
                    variant="outlined"
                    label={option.name}
                    size="small"
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <StyledSelectebleTextField
                  {...params}
                  required
                  variant="outlined"
                  label="User"
                  placeholder="Users"
                  sx={{backgroundColor:"#09072a", color:"#FFFFFF"}}
                  InputLabelProps={{
                    style: { color: "white" },
                  }}
                />
              )}
            />
          )}
          <Grid item xs={12} md={6}></Grid>
        </Grid>
        <Grid item xs={12} md={6}>
        {props.isLoading ? (
            <AppSkeleton numOfRows={1} numOfColumns={0} tag="formField" />
          ) : (
          <CustomAutocomplete
            label="Roles"
            placeholder="Select Role"
            onFocus={() => props.handleInputFocus("roles", "UI")}
            options={_allRolesPermissions.map((p) => {
              return { label: p.name, value: p.id };
            })}
            value={_rolesPermission.value}
            error={!!_rolesPermission.error}
            disabled={_rolesPermission.disable}
            readonly={_rolesPermission.readonly}
            required={_rolesPermission.isRequired}
            helperText={props.helperText && _rolesPermission.error}
            onChange={(event: any, value: any) =>
              props.onInputHandleChangeUserInformation("roles", value)
            }
          />
          )}
        </Grid>

        <Grid item xs={12} sx={{ borderBottom: "0.1rem solid white" }}></Grid>

        <div className={style.dataset}>
          {props.ALL_ENTITLEMENTSWithChecked.map(
            ({ entitlement, checked }, index: any) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <StyledCheckBox size="small" checked={checked} readOnly />
                <div style={{ flexWrap: "wrap", marginLeft: "8px" }}>
                  <label style={{ whiteSpace: "normal" }}>{entitlement}</label>
                </div>
              </div>
            )
          )}
        </div>
      </Grid>
    </section>
  );
};

export default ManageEntitlementForm;
