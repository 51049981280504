import Button from "@mui/material/Button/Button";
import styles from "./DriverTripScreen.module.scss";
import { logoImage } from "../../assets/images"
import { useEffect, useState } from "react";
import { Base64 } from 'js-base64';
import { useSelector } from "react-redux/es/hooks/useSelector";
import { ApplicationStateDto } from "../../utilities/models/state.model";
import { RealTripPayloadDto, StartParamDto, TripBodyPayloadDto } from "../../utilities/models/gps.model";
import moment from 'moment';
import { APP_TABLE_CONFIGS } from "../../utilities/constants/app.constants";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { alertActions, gpsActions } from "../../redux/actions";
import dayjs from "dayjs";
import { ALERT_ACTION_TYPES, APP_ACTION_STATUS, COMMON_ACTION_TYPES } from "../../utilities/constants";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { AlertDto } from "../../utilities/models/alert.model";

const DriverTripScreen = () => {
    const postTripResponse = useSelector((state: ApplicationStateDto) => state.gps.postRealTripDetails);
    const realTripResponse = useSelector((state: ApplicationStateDto) => state.gps.getActualTripDetails);

    const dispatch = useDispatch();
    const [isRunning, setIsRunning] = useState(false);
    const [time, setTime] = useState(0);
    const [tripId, setTripId] = useState(0)
    const [driverId, setDriverId] = useState(0)
    const [tripStartDateTime, setTripStartDateTime] = useState<null | string>(null);
    const [tripEndDateTime, setTripEndDateTime] = useState<null | string>(null);
    const [timeChecking, setTimeChecking] = useState<boolean>(false);
    
    useEffect(() => {
        if (postTripResponse.status === APP_ACTION_STATUS.SUCCESS) {
            if (postTripResponse.data.startsWith("Trip cannot commence")) {
                const setAlert: AlertDto = {
                    message: postTripResponse.data,
                    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        varient: "error",
                    },
                };
                setTimeChecking(true)
                dispatch(alertActions.triggerAlert(setAlert))
            } else {
                const setSuccessAlert: AlertDto = {
                    message: postTripResponse.data,
                    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        varient: "success",
                    },
                };
                dispatch(alertActions.triggerAlert(setSuccessAlert))
            }
        }
    }, [postTripResponse.status])

    useEffect(() => {
        if(timeChecking === false){
            let interval: any;

            if (isRunning) {
                interval = setInterval(() => {
                    setTime((prevTime) => prevTime + 1);
                }, 1000); // Update time every 1 second
            } else {
                clearInterval(interval);
            }
    
            return () => {
                clearInterval(interval);
            };
        }
    }, [isRunning, timeChecking]);

    useEffect(() => {
        const windowUrl = window.location.search;
        const params: any = new URLSearchParams(windowUrl);

        const baseValue = (Base64.decode(params.get('trip')));
        const splitedBaseValue = baseValue.split('&');

        setTripId(Number(splitedBaseValue[0].match(/\d+/)))
        setDriverId(Number(splitedBaseValue[1].match(/\d+/)))
    }, [])

    useEffect(() => {
        if (tripId !== 0) {
            dispatch(gpsActions.getActualTripDetails(tripId.toString()))
        }
    }, [tripId])

    // useEffect(() => {
    //     if (realTripResponse.status === APP_ACTION_STATUS.SUCCESS) {
    //         if (tripId !== 0) {
    //             const startDateTime = realTripResponse.data.tripStartDateTime as string | null;
    //             console.log("CHECKING", startDateTime)
    //             setTripStartDateTime(startDateTime);
    //             const endDateTime = realTripResponse.data?.tripEndDateTime as string | null;
    //             console.log("CHECKING", endDateTime)
    //             setTripEndDateTime(endDateTime)
    //         }

    //         if(tripStartDateTime !== null){
    //             const dateObject = new Date(tripStartDateTime);
    //             const seconds = dateObject.getTime() / 1000;

    //             const currentDateTime = moment().format(APP_TABLE_CONFIGS.DATE_FORMAT);
    //             const dateObject1 = new Date(currentDateTime);
    //             const seconds1 = dateObject1.getTime() / 1000;
    //             const diff = seconds1 - seconds;
    //             setTime(diff);
    //             setIsRunning(!isRunning);
    //         }
    //     }
    // }, [realTripResponse.status])
    useEffect(() => {
        if (realTripResponse.status === APP_ACTION_STATUS.SUCCESS) {
            if (tripId !== 0) {
                const startDateTime = realTripResponse.data.tripStartDateTime as string | null;
                console.log("CHECKING", startDateTime);
                setTripStartDateTime(startDateTime);
                const endDateTime = realTripResponse.data?.tripEndDateTime as string | null;
                console.log("CHECKING", endDateTime);
                setTripEndDateTime(endDateTime);

                if (startDateTime !== null && endDateTime === null) {
                    const dateObject = new Date(startDateTime);
                    const seconds = dateObject.getTime() / 1000;

                    console.log("XXX", startDateTime, dateObject, seconds)

                    const currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
                    const dateObject1 = new Date(currentDateTime);
                    const seconds1 = dateObject1.getTime() / 1000;
                    const diff = seconds1 - seconds;
                    setTime(diff);
                    setIsRunning(true); // Start the stopwatch

                    console.log("XXX", diff, startDateTime, currentDateTime)
                }
            }
        }
    }, [realTripResponse.status]);

    const startStop = () => {

        setIsRunning(!isRunning);

        const currentDateTime = moment().format(APP_TABLE_CONFIGS.DATE_FORMAT);

        const tripParamDto: StartParamDto = {
            existingTripId: tripId,
        };

        const tripBodyDto: TripBodyPayloadDto = {
            startTripDateTime: dayjs(currentDateTime).format('YYYY-MM-DDTHH:mm:ss'),
            endTripDateTime: null
        }

        const postTripDto: RealTripPayloadDto = {
            existingTripId: tripParamDto.existingTripId,
            tripReqBody: tripBodyDto
        }

        dispatch(gpsActions.postRealTripDetails(postTripDto))
    };

    const onStop = () => {
        setTime(0);
        setIsRunning(false);

        const currentDateTime = moment().format(APP_TABLE_CONFIGS.DATE_FORMAT);

        const tripParamDto: StartParamDto = {
            existingTripId: tripId,
        };

        const tripBodyDto: TripBodyPayloadDto = {
            startTripDateTime: null,
            endTripDateTime: dayjs(currentDateTime).format('YYYY-MM-DDTHH:mm:ss'),
        }

        const postTripDto: RealTripPayloadDto = {
            existingTripId: tripParamDto.existingTripId,
            tripReqBody: tripBodyDto
        }

        dispatch(gpsActions.postRealTripDetails(postTripDto))
        // setTimeout(() => { window.close() }, 3000);
    };

    const onReset = () => {
        dispatch(gpsActions.resetActualTripDetails(tripId.toString()));
        setTime(0);
        setIsRunning(false);
        setTripStartDateTime(null)
    }

    const formatTime = (seconds: any) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const pad = (value: any) => (value < 10 ? `0${value}` : value);

        return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
    }

    console.log("CHECKING VALUES", tripStartDateTime, tripEndDateTime);

    return (
        <section className={`${styles.container} content-padding container layout-row layout-wrap layout-align-center center`}>
            <section className={`${styles.login} layout-row`}>
                <aside className={styles.logincard}>
                    <aside className={styles.loginActions}>
                        <aside>
                            <img className={styles.logo} src={logoImage} alt="Logo of TMR" />
                            <h1>Welcome to TMR - Track My Ride</h1>
                        </aside>
                        <aside>
                            <h1> {formatTime(time)} </h1>
                        </aside>
                        <aside className={"layout-row"}>
                            <Button className={`${styles.primaryBtn} `} disabled={(isRunning && timeChecking === false) || tripStartDateTime !== null }
                                variant="contained" onClick={startStop}>
                                Start Time
                            </Button>
                            <Button
                                className={`${styles.primaryBtn} `} disabled={tripEndDateTime !== null}
                                variant="contained" onClick={onStop} >
                                End Time
                            </Button>
                            {/* <Button
                                className={`${styles.secondaryBtn} `} 
                                variant="contained" onClick={onReset} >
                                <RotateLeftIcon sx={{ fontSize: '20px' }} />
                            </Button> */}
                        </aside>
                        <div className={styles.loginFooter}>
                            <p>
                                Do not share this link with anyone!
                            </p>
                        </div>
                    </aside>
                </aside>
            </section>

        </section>
    )

}

export default DriverTripScreen