import { AxiosResponse } from "axios";
import { call, put, takeEvery } from 'redux-saga/effects'
import { LocationBriefDto, NotificationDto, updateSeenNotificationParam } from "../../utilities/models";
import { locationService } from "../../services/location.service";
import { COMMON_ACTION_TYPES, NOTIFICATION_ACTION_TYPES } from "../../utilities/constants";
import { NotificationService } from "../../services/notification.service";

function * getNotification (action: {type: string}) {
    try {
        const responseData: AxiosResponse<NotificationDto> = yield call(NotificationService.getNotification)
        yield put({
            type: NOTIFICATION_ACTION_TYPES.GET_NOTIFICATION + COMMON_ACTION_TYPES.SUCCESS,
            data: responseData.data
        })
    } catch (error) {
        
        yield put({
            type: NOTIFICATION_ACTION_TYPES.GET_NOTIFICATION + COMMON_ACTION_TYPES.ERROR,
            error: error
        })
    }
}
function * updateSeenNotification (action: {type: string,payload:updateSeenNotificationParam}) {
    try {
        const responseData: AxiosResponse<string> = yield call(NotificationService.updateSeenNotification,action.payload)
        yield put({
            type: NOTIFICATION_ACTION_TYPES.UPDATE_SEEN_NOTIFICATION + COMMON_ACTION_TYPES.SUCCESS,
            data: responseData.data
        })
    } catch (error) {
        
        yield put({
            type: NOTIFICATION_ACTION_TYPES.UPDATE_SEEN_NOTIFICATION + COMMON_ACTION_TYPES.ERROR,
            error: error
        })
    }
}
function* notificationSaga() {
    yield takeEvery(NOTIFICATION_ACTION_TYPES.GET_NOTIFICATION + COMMON_ACTION_TYPES.REQUEST, getNotification)
    yield takeEvery(NOTIFICATION_ACTION_TYPES.UPDATE_SEEN_NOTIFICATION + COMMON_ACTION_TYPES.REQUEST, updateSeenNotification)
}

export default notificationSaga