import React, { useEffect, useState } from "react";
import AppLayout from "../../templates/AppLayout/AppLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  ApplicationStateDto,
  AuthorizedUserDto,
  HierarchyDto,
  NotificationDto,
  notificationDetailsDto,
} from "../../utilities/models";
import {
  notificationAction,
  permissionActions,
  requestActions,
} from "../../redux/actions";

import {
  Avatar,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Tab,
  Typography,
} from "@mui/material";
import style from "./UserProfile.module.scss";
import { StyledTextField } from "../../assets/theme/theme";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MyProfile from "../../components/UserProfile/MyProfile/MyProfile";
import Notification from "../../components/UserProfile/Notification/Notification";
import PasswordAndSecurity from "../../components/UserProfile/PasswordAndSecurity/PasswordAndSecurity";
import { useLocation } from "react-router-dom";
import { APP_ACTION_STATUS } from "../../utilities/constants";

import {
  GetRolesAndEntitlementDto,
  PermissionDto,
  emailId,
  hierarchiesDTO,
} from "../../utilities/models/permission.model";
const UserProfile = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(1);
  const location = useLocation();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const RolesAndEntitlementResponse = useSelector((state: ApplicationStateDto) =>state.permission.getRolesAndEntitlementByUser);
  const GetNotificationResponse = useSelector((state: ApplicationStateDto) => state.notification.getNotifications);
  const seenStatusUpdateResponse = useSelector((state: ApplicationStateDto) => state.notification.updateSeenNotification);
  const authResponseEmail = useSelector((state: ApplicationStateDto) => state.authUser.authorizedUser.data.emailId);
  const fetchAuthorizedUserResponse = useSelector((state: ApplicationStateDto) => state.authUser.authorizedUser);
  

  const [RolesAndEntitlement, setRolesAndEntitlement] =useState<AuthorizedUserDto>();
  const [NotificationDetails, setNotificationDetails] =useState<NotificationDto>();
  const [IdList, setIDList] = useState<string[]>();
  const [hierarchy, sethierarchy] = useState<HierarchyDto[]>([]);
  const [permissions, setPermissions] = useState<PermissionDto[]>([]);

  useEffect(() => {
    const { TabValue } = location.state || 1;
    setValue(Number(TabValue));
  }, [location.state]);

  useEffect(() => {
    getNotification();
  }, []);

  useEffect(() => {
    if (seenStatusUpdateResponse.status === APP_ACTION_STATUS.SUCCESS) {
      localStorage.setItem("notificationCount", "0");
    }
  }, [seenStatusUpdateResponse.status]);

  // useEffect(() => {
  //   if (RolesAndEntitlementResponse.status === APP_ACTION_STATUS.SUCCESS) {
  //     const data: GetRolesAndEntitlementDto = RolesAndEntitlementResponse?.data;

  //     if (data) {
  //       setRolesAndEntitlement(data);
  //     }
  //   }
  // }, [RolesAndEntitlementResponse?.data, RolesAndEntitlementResponse.status]);

useEffect(() => {
 
  if(fetchAuthorizedUserResponse.status===APP_ACTION_STATUS.SUCCESS){
    console.log("fetchAuthorizedUserResponse",fetchAuthorizedUserResponse.data);
     setRolesAndEntitlement(fetchAuthorizedUserResponse.data)
    setPermissions(fetchAuthorizedUserResponse.data.permissions)
    sethierarchy(fetchAuthorizedUserResponse.data.hierarchy)
  }
}, [fetchAuthorizedUserResponse.status])



  useEffect(() => {
    if (GetNotificationResponse.status === APP_ACTION_STATUS.SUCCESS) {
      const data: NotificationDto = GetNotificationResponse?.data;

      if (data) {
        setNotificationDetails(data);
        const idList = data.notificationDetails.map(
          (item: notificationDetailsDto) => item.id
        );
        const unseenIds = idList.filter((id) => {
          const item = data.notificationDetails.find((item) => item.id === id);
          return item?.isSeen === false;
        });

        setIDList(unseenIds);
      }
      localStorage.setItem("notificationCount", data.count.toString());
    }
  }, [GetNotificationResponse?.data, GetNotificationResponse.status]);



  const getNotification = () => {
    dispatch(notificationAction.getNotification());
  };

  return (
    <React.Fragment>
      <AppLayout componentTitle="UserProfile">
        <section className="page-root">
          <section className={style.gridContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2.5} className={style.Profile}>
                <Box
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    alt="profile"
                    src="/broken-image.jpg"
                    className={style.profileimg}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8} className={style.Profile}>
                <Box className={style.textBox}>
                  <Typography className={style.name}>
                    {RolesAndEntitlement?.firstName +
                      "  " +
                      RolesAndEntitlement?.lastName}
                  </Typography>
                  <Typography className={style.designation}>
                    {RolesAndEntitlement?.roleName}
                  </Typography>
                  <Typography className={style.Description}>
                    Your account is ready, you can apply for the trip
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <TabContext value={value.toString()}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2.4} className={style.list}>
                  <Box sx={{ borderRight: 3, borderColor: "white" }}>
                    <TabList
                      sx={{
                        "& .MuiTabs-indicator": {
                          display: "none",
                        },
                      }}
                      onChange={handleChange}
                      orientation="vertical"
                    >
                      <Tab
                        sx={{
                          marginBlock: "0.5rem",
                          "&.Mui-selected": {
                            marginInline: "1rem",
                            backgroundColor: "rgba(255, 0, 0, 0.1)",
                            color: "white",
                          },
                          borderRadius: "0.8rem",
                          color: "white",
                        }}
                        label="My Profile"
                        value="1"
                      />
                      <Tab
                        sx={{
                          marginBlock: "0.5rem",
                          "&.Mui-selected": {
                            marginInline: "1rem",
                            backgroundColor: "rgba(255, 0, 0, 0.1)",
                            color: "white",
                          },
                          borderRadius: "0.8rem",
                          color: "white",
                        }}
                        label="Notifications  "
                        value="2"
                      />
                    </TabList>
                  </Box>
                </Grid>

                <Grid item xs={12} md={9} className={style.data}>
                  <TabPanel value="1">
                    <MyProfile
                      RolesAndEntitlement={RolesAndEntitlement}
                      hierarchy={hierarchy}
                      permissions={permissions}
                    />
                  </TabPanel>
                  <TabPanel value="2">
                    <Notification
                      NotificationDetails={NotificationDetails}
                      isLoading={GetNotificationResponse.isLoading}
                      IdList={IdList || []}
                    />
                  </TabPanel>
                  {/* <TabPanel value="3"><PasswordAndSecurity/></TabPanel>
                  <TabPanel value="4">Event History</TabPanel> */}
                </Grid>
              </Grid>
            </TabContext>
            {/* <NewUser /> */}
          </section>
        </section>
      </AppLayout>
    </React.Fragment>
  );
};

export default UserProfile;
