import { COMMON_ACTION_TYPES, NOTIFICATION_ACTION_TYPES,  } from "../../utilities/constants"
import { updateSeenNotificationParam } from "../../utilities/models"

const getNotification = () => {
    return { type: NOTIFICATION_ACTION_TYPES.GET_NOTIFICATION + COMMON_ACTION_TYPES.REQUEST }
}
const UpdateSeenNotification=(payload:updateSeenNotificationParam)=>{
    return { type: NOTIFICATION_ACTION_TYPES.UPDATE_SEEN_NOTIFICATION + COMMON_ACTION_TYPES.REQUEST,
             payload:payload
 }
}
export const notificationAction = {
    getNotification,
    UpdateSeenNotification
}