import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./LmDashboardSummaryChart.module.scss";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ReactECharts from "echarts-for-react";
import { CustomButton } from "../../Shared";
import { LmDashboard } from "../../../pages";
import RequestBudgetGraph from "../../Shared/RequestBudgetGraph/RequestBudgetGraph";
import RequestSummaryChart from "../../Shared/RequestSummaryChart/RequestSummaryChart";

const LmDashboardSummaryChart = (props: any) => {
  const [lmBudgetChartData, setLmBudgetChartData] = useState([]);
  const [summaryChartData, setSummaryChartData] = useState([]);

  useEffect(() => {
    setLmBudgetChartData(
      props.getLmSummaryResponse?.data?.lmDashboardChartsDTO_LMBudgetChartDTO
    );
    setSummaryChartData(
      props.getLmSummaryResponse?.data
        ?.lmDashboardChartsDTO_LMRequestSummaryChartDTO
    );
  }, [props]);

  return (
    <Grid container spacing={4}>
      <Grid item md={6}>
        <RequestBudgetGraph lmBudgetChartData={lmBudgetChartData} />
      </Grid>
      <Grid item md={6}>
        {/* <RequestSummaryChart lmSummaryChartData={lmSummaryChartData} /> */}
        <RequestSummaryChart summaryChartData={summaryChartData} />
      </Grid>
    </Grid>
  );
};

export default LmDashboardSummaryChart;
