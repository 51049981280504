import { Typography, Grid, Box } from '@mui/material'
import React from 'react'
import { BootstrapDialog, BootstrapDialogTitle, BootstrapDialogContent, StyledTextField, BootstrapDialogActions } from '../../../assets/theme/theme'
import { CustomButton } from '../../Shared'
import styles from './RequestCreationPopup.module.scss'
const RequestCreationPopup:React.FC<{
    OnPopUPClose(): void;
    isOpenCancelAllocationPopup: boolean;
    navigateTo(mode: string): void;
}> = (props) => {
    
  return (
    <BootstrapDialog
    width='480px'
      className={styles.dialogCard}
      aria-labelledby="customized-dialog-title"
      open={props.isOpenCancelAllocationPopup}
    >
      <BootstrapDialogTitle id="customized-dialog-title"
        onClose={() => { props.OnPopUPClose() }}>
        Create Request
      </BootstrapDialogTitle>
      <BootstrapDialogContent>

      <Grid container spacing={1} sx={{justifyContent:"space-between"}}>
      <Grid item md={5.5}>
         <CustomButton
           border="1px solid #6e6e6e"
           bgColor="#282828"
          text={"Express Request"}
          onClick={() => { props.navigateTo("ExpressRequest") }}
        /> 
          </Grid>
      <Grid item md={5.6}>
        <CustomButton
          text={"Ordinary Request"}
          onClick={() => { props.navigateTo("Ordinary Request") }}
        />
          </Grid>
       
        </Grid>

      </BootstrapDialogContent>
      <BootstrapDialogActions>
        <CustomButton
          text={"Cancel"}
          border="1px solid #6e6e6e"
          bgColor="#282828"
          onClick={() => { props.OnPopUPClose()}}
        />

      </BootstrapDialogActions>
    </BootstrapDialog>
  )
}

export default RequestCreationPopup