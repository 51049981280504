import { APP_ACTION_STATUS, COMMON_ACTION_TYPES, NOTIFICATION_ACTION_TYPES } from "../../utilities/constants";
import {  NotificationStateDto } from "../../utilities/models";

const INITIAL_STATE: NotificationStateDto = {
    getNotifications: {
        data: {
            count: 0,
            notificationDetails: []
        },
        error: null,
        isLoading: false,
        status: null
    },
    updateSeenNotification: {
      data:null,
      error: null,
      isLoading: false,
      status: null
  }
}

const notificationReducer = (state = INITIAL_STATE, action: any) => {
    switch((action.type)) {
        case NOTIFICATION_ACTION_TYPES.GET_NOTIFICATION + COMMON_ACTION_TYPES.REQUEST:
          return {
            ...state,
            getNotifications: {
              ...state.getNotifications,
              isLoading: true,
              status: APP_ACTION_STATUS.LOADING,
              error: null,
              data: null
            }
          }
        case NOTIFICATION_ACTION_TYPES.GET_NOTIFICATION + COMMON_ACTION_TYPES.SUCCESS:
          return {
            ...state,
            getNotifications: {
              ...state.getNotifications,
              isLoading: false,
              status: APP_ACTION_STATUS.SUCCESS,
              error: null,
              data: action.data
            }
          }
        case NOTIFICATION_ACTION_TYPES.GET_NOTIFICATION + COMMON_ACTION_TYPES.ERROR:
          return {
            ...state,
            getNotifications: {
              ...state.getNotifications,
              isLoading: false,
              status: APP_ACTION_STATUS.ERROR,
              error: action.error,
              data: null
            }
          }
        case NOTIFICATION_ACTION_TYPES.GET_NOTIFICATION + COMMON_ACTION_TYPES.CLEAR:
          return {
            ...state,
            getNotifications: {
              ...state.getNotifications,
              isLoading: false,
              status: APP_ACTION_STATUS.INITIAL,
              error: null,
              data: null
            }
          }
          case NOTIFICATION_ACTION_TYPES.UPDATE_SEEN_NOTIFICATION + COMMON_ACTION_TYPES.REQUEST:
            return {
              ...state,
              updateSeenNotification: {
                ...state.updateSeenNotification,
                isLoading: true,
                status: APP_ACTION_STATUS.LOADING,
                error: null,
                data: null
              }
            }
          case NOTIFICATION_ACTION_TYPES.UPDATE_SEEN_NOTIFICATION + COMMON_ACTION_TYPES.SUCCESS:
            return {
              ...state,
              updateSeenNotification: {
                ...state.updateSeenNotification,
                isLoading: false,
                status: APP_ACTION_STATUS.SUCCESS,
                error: null,
                data: action.data
              }
            }
          case NOTIFICATION_ACTION_TYPES.UPDATE_SEEN_NOTIFICATION + COMMON_ACTION_TYPES.ERROR:
            return {
              ...state,
              updateSeenNotification: {
                ...state.updateSeenNotification,
                isLoading: false,
                status: APP_ACTION_STATUS.ERROR,
                error: action.error,
                data: null
              }
            }
          case NOTIFICATION_ACTION_TYPES.UPDATE_SEEN_NOTIFICATION + COMMON_ACTION_TYPES.CLEAR:
            return {
              ...state,
              updateSeenNotification: {
                ...state.updateSeenNotification,
                isLoading: false,
                status: APP_ACTION_STATUS.INITIAL,
                error: null,
                data: null
              }
            }
        default:
          return state
        
    }
}

export default notificationReducer