import { Box, Button, CircularProgress, Grid, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { AppSkeleton, CustomButton, CustomDatePicker, CustomHeaderCell, CustomStepper, } from "../../Shared";
import { StyledTableCell, StyledTextField } from "../../../assets/theme/theme";
import style from "./DriverDocuments.module.scss";
import { DEFAULT_VEHICLE_DATA_LIST } from "../../../utilities/constants/data.constant";
import DownloadIcon from "@mui/icons-material/Download";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { DriverDocumentListDto, DriverDocumentListDto1, DriverLicenseDto, DriverNICDto, DriverOtherDto } from "../../../utilities/models/driver.model";
import { DateObject } from "react-multi-date-picker";
import CloudDownloadIcon from '@mui/icons-material/CloudDownloadRounded';
import { DOCUMENT_TYPE, DRIVERDOCUMENT, DRIVER_SCREEN_MODES } from "../../../utilities/constants/app.constants";
import LoadingButton from '@mui/lab/LoadingButton';
import { StateObjectDto } from "../../../utilities/models";
import CustomCircularProgress from "../../Shared/CustomCircularProgress/CustomCircularProgress";

const DriverDocuments: React.FC<{
  isLoading: boolean;
  onInputHandleChange(property: string, value: any): void;
  handleInputFocus(property: string, section: string): void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>, id: number) => void;
  licenseForm: DriverLicenseDto;
  nicForm: DriverNICDto;
  otherForm: DriverOtherDto;
  onSelectDocument(id: number, action: string): void
  handleDownload(mainId: number, docId: number): void;
  handleDeleteFile(mainId: number, docId: number): void;
  helperText: boolean;
  onAddDocuments(documentId: number): void;
  screenMode: string
  documentList: DriverDocumentListDto1[];
  licenseDocumentResponse: StateObjectDto<any>;
  nicDocumentResponse: StateObjectDto<any>;
  otherDocResponse: StateObjectDto<any>;
}> = (props) => {

  const _driverLicenseNo = props.licenseForm.driverLicenseNo;
  const _licenseFileName = props.licenseForm.licenseFileName;
  const _licenseIssue = props.licenseForm.driverLicenseIssue;
  const _licenseExpiry = props.licenseForm.driverLicenseExpiry;

  const _driverNICNo = props.nicForm.driverNICNo;
  const _nicFileName = props.nicForm.NICFileName;
  const _nicIssue = props.nicForm.driverNICIssue;
  const _nicExpiry = props.nicForm.driverNICExpiry;

  const _driverOtherDoc = props.otherForm.driverOtherDoc;
  const _otherFileName = props.otherForm.otherFileName;
  const _otherIssue = props.otherForm.driverOtherIssue;
  const _otherExpiry = props.otherForm.driverOtherExpiry;
  
  return (
    <CustomStepper stepNumber={2} stepTitle="Document Details">
      {!props.isLoading && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              Driver License*
            </Grid>
            <Grid item xs={4} md={2}>
              <StyledTextField
                fullWidth
                label="Driver's License Number"
                placeholder="Enter driver license number"
                size="small"
                value={_driverLicenseNo.value}
                InputProps={{
                  readOnly: _driverLicenseNo.readonly || props.screenMode === DRIVER_SCREEN_MODES.VIEW,
                }}
                error={!!_driverLicenseNo.error}
                disabled={_driverLicenseNo.disable}
                required={_driverLicenseNo.isRequired}
                helperText={props.helperText && _driverLicenseNo.error}
                onFocus={() => props.handleInputFocus("driverLicenseNo", "license")}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  props.onInputHandleChange("driverLicenseNo", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <CustomDatePicker
                label="Issued Date"
                placeholder="Select issued date"
                multiple={false}
                value={_licenseIssue.value}
                maxDate={new Date()}
                error={!!_licenseIssue.error}
                disabled={_licenseIssue.disable || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                readOnly={_licenseIssue.readonly || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                required={_licenseIssue.isRequired}
                helperText={props.helperText && _licenseIssue.error}
                onFocus={() => props.handleInputFocus("licenseIssue", "license")}
                onChange={(value: DateObject) =>
                  props.onInputHandleChange("licenseIssue", value)
                }
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <CustomDatePicker
                label="Expiry Date"
                placeholder="Select expiry date"
                multiple={false}
                value={_licenseExpiry.value}
                minDate={new Date()}
                error={!!_licenseExpiry.error}
                disabled={_licenseExpiry.disable || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                readOnly={_licenseExpiry.readonly || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                required={_licenseExpiry.isRequired}
                helperText={props.helperText && _licenseExpiry.error}
                onFocus={() => props.handleInputFocus("licenseExpiry", "license")}
                onChange={(value: DateObject) =>
                  props.onInputHandleChange("licenseExpiry", value)
                }
              />
            </Grid>

            {props.screenMode !== DRIVER_SCREEN_MODES.VIEW && (
              <Grid item xs={1} sx={{ display: "none" }}>
                <input
                  type="file"
                  required
                  accept=".pdf"
                  id="DriverLicense"
                  style={{ display: 'none' }}
                  onChange={(event) => props.handleFileChange(event, DRIVERDOCUMENT.LICENSE_DOCUMENT)}
                  onClick={(event: any) => { 
                    event.target.value = null
                  }}
                />
              </Grid>
            )}

            {props.screenMode !== DRIVER_SCREEN_MODES.VIEW && (
              <Grid item xs={3} md={6}>
                <Box sx={{ display: "flex" }}>
                  <Grid item xs={12} md={4}>
                    <label htmlFor="DriverLicense">
                      <LoadingButton style={{ color: "white" }}  loading={props.licenseDocumentResponse.isLoading} loadingIndicator={<CustomCircularProgress/>} component="span">
                        Select File
                      </LoadingButton>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {_licenseFileName.value !== '' && <Box sx={{ display: "flex" }}>
                      <StyledTextField
                        fullWidth
                        label="File Attached"
                        placeholder="Enter driver NIC"
                        size="small"
                        InputProps={{
                          readOnly: _licenseFileName.readonly,
                        }}
                        value={_licenseFileName.value}
                        error={!!_licenseFileName.error}
                        disabled={false}
                        required={_licenseFileName.isRequired}
                        helperText={props.helperText && _licenseFileName.error}
                        onFocus={() => props.handleInputFocus("licenseFileName", "license")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          props.onInputHandleChange("licenseFileName", event.target.value)
                        }
                      />
                    </Box>}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className='layout-row'>
                      <CustomButton text='Clear' textColor='black' bgColor='#bfbfbf' onClick={() => { props.handleDeleteFile(DOCUMENT_TYPE.DTIVER_DOCUMENTS, DRIVERDOCUMENT.LICENSE_DOCUMENT) }} />
                      {/* <Button style={{ color: "white" }} startIcon={<DeleteForeverRoundedIcon />} onClick={() => { props.handleDeleteFile(DOCUMENT_TYPE.DTIVER_DOCUMENTS, DRIVERDOCUMENT.OTHER_DOCUMENT) }} /> */}
                      <CustomButton text='Submit' textColor='black' bgColor='#bfbfbf' onClick={() => { props.onAddDocuments(DRIVERDOCUMENT.LICENSE_DOCUMENT) }} />
                    </div>
                  </Grid>
                </Box>
              </Grid>
            )}

            <Grid item xs={12} md={12}>
              Driver's NIC*
            </Grid>
            <Grid item xs={4} md={2}>
              <StyledTextField
                fullWidth
                label="Driver's NIC Number"
                placeholder="Enter driver NIC"
                size="small"
                inputProps={{
                  readOnly: props.screenMode === DRIVER_SCREEN_MODES.VIEW
                }}
                value={_driverNICNo.value}
                error={!!_driverNICNo.error}
                disabled={_driverNICNo.disable}
                required={_driverNICNo.isRequired}
                helperText={props.helperText && _driverNICNo.error}
                onFocus={() => props.handleInputFocus("driverNICNo", "nic")}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  props.onInputHandleChange("driverNICNo", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <CustomDatePicker
                label="Issued Date"
                placeholder="Select issued date"
                multiple={false}
                value={_nicIssue.value}
                maxDate={new Date()}
                error={!!_nicIssue.error}
                disabled={_nicIssue.disable || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                readOnly={_nicIssue.readonly || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                required={_nicIssue.isRequired}
                helperText={props.helperText && _nicIssue.error}
                onFocus={() => props.handleInputFocus("nicIssue", "nic")}
                onChange={(value: DateObject) =>
                  props.onInputHandleChange("nicIssue", value)
                }
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <CustomDatePicker
                label="Expiry Date"
                placeholder="Select expiry date"
                multiple={false}
                value={_nicExpiry.value}
                minDate={new Date()}
                error={!!_nicExpiry.error}
                disabled={_nicExpiry.disable || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                readOnly={_nicExpiry.readonly || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                required={_nicExpiry.isRequired}
                helperText={props.helperText && _nicExpiry.error}
                onFocus={() => { }}
                onChange={(value: any) => { }}
              />
            </Grid>

            {props.screenMode !== DRIVER_SCREEN_MODES.VIEW && (
              <Grid item xs={1} sx={{ display: "none" }}>
                <input
                  itemID="1"
                  type="file"
                  required
                  accept=".pdf"
                  id="NICNumber"
                  style={{ display: 'none' }}
                  onChange={(event) => props.handleFileChange(event, DRIVERDOCUMENT.NIC_DOCUMENT)}
                  onClick={(event: any) => { 
                    event.target.value = null
                  }}
                />
              </Grid>
            )}

            {props.screenMode !== DRIVER_SCREEN_MODES.VIEW && (
              <Grid item xs={3} md={6}>
                <Box sx={{ display: "flex" }}>
                  <Grid item xs={12} md={4}>
                    <label htmlFor="NICNumber">
                      <LoadingButton style={{ color: "white" }} loading={props.nicDocumentResponse.isLoading} loadingIndicator={<CustomCircularProgress />} component="span">
                        Select File
                      </LoadingButton>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {_nicFileName.value !== '' && <Box sx={{ display: "flex" }}>
                      <StyledTextField
                        fullWidth
                        label="File Attached"
                        placeholder="Enter driver NIC"
                        size="small"
                        InputProps={{
                          readOnly: _nicFileName.readonly,
                        }}
                        value={_nicFileName.value}
                        error={!!_nicFileName.error}
                        disabled={false}
                        required={_nicFileName.isRequired}
                        helperText={props.helperText && _nicFileName.error}
                        onFocus={() => props.handleInputFocus("nicFileName", "nic")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          props.onInputHandleChange("nicFileName", event.target.value)
                        }
                      />
                    </Box>}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {props.screenMode !== DRIVER_SCREEN_MODES.VIEW && (
                      <div className='layout-row'>
                        <CustomButton text='Clear' textColor='black' bgColor='#bfbfbf' onClick={() => { props.handleDeleteFile(DOCUMENT_TYPE.DTIVER_DOCUMENTS, DRIVERDOCUMENT.NIC_DOCUMENT) }} />
                        {/* <Button style={{ color: "white" }} startIcon={<DeleteForeverRoundedIcon />} onClick={() => { props.handleDeleteFile(DOCUMENT_TYPE.DTIVER_DOCUMENTS, DRIVERDOCUMENT.OTHER_DOCUMENT) }} /> */}
                        <CustomButton text='Submit' textColor='black' bgColor='#bfbfbf' onClick={() => { props.onAddDocuments(DRIVERDOCUMENT.NIC_DOCUMENT) }} />
                      </div>
                    )}
                  </Grid>
                </Box>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              Other Document
            </Grid>
            <Grid item xs={12} md={2}>
              <StyledTextField
                fullWidth
                label="Document Name"
                placeholder="Enter Document Name"
                size="small"
                inputProps={{
                  readOnly: props.screenMode === DRIVER_SCREEN_MODES.VIEW
                }}
                value={_driverOtherDoc.value}
                error={!!_driverOtherDoc.error}
                disabled={_driverOtherDoc.disable}
                required={_driverOtherDoc.isRequired}
                helperText={props.helperText && _driverOtherDoc.error}
                onFocus={() => props.handleInputFocus("driverOtherDoc", "other")}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  props.onInputHandleChange("driverOtherDoc", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <CustomDatePicker
                label="Issued Date"
                placeholder="Select issued date"
                multiple={false}
                value={_otherIssue.value}
                maxDate={new Date()}
                error={!!_otherIssue.error}
                disabled={_otherIssue.disable || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                readOnly={_otherIssue.readonly || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                required={_otherIssue.isRequired}
                helperText={props.helperText && _otherIssue.error}
                onFocus={() => props.handleInputFocus("otherIssue", "other")}
                onChange={(value: DateObject) =>
                  props.onInputHandleChange("otherIssue", value)
                }
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <CustomDatePicker
                label="Expiry Date"
                placeholder="Select expiry date"
                multiple={false}
                value={_otherExpiry.value}
                minDate={new Date()}
                error={!!_otherExpiry.error}
                disabled={_otherExpiry.disable || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                readOnly={_otherExpiry.readonly || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                required={_otherExpiry.isRequired}
                helperText={props.helperText && _otherExpiry.error}
                onFocus={() => props.handleInputFocus("otherExpiry", "other")}
                onChange={(value: DateObject) =>
                  props.onInputHandleChange("otherExpiry", value)
                }
              />
            </Grid>
            {props.screenMode !== DRIVER_SCREEN_MODES.VIEW && (
              <Grid item xs={1} sx={{ display: "none" }}>
                <input
                  type="file"
                  required
                  accept=".pdf"
                  id="OtherFile"
                  style={{ display: 'none' }}
                  onChange={(event) => props.handleFileChange(event, DRIVERDOCUMENT.OTHER_DOCUMENT)}
                  onClick={(event: any) => { 
                    event.target.value = null
                  }}
                />
              </Grid>
            )}

            {props.screenMode !== DRIVER_SCREEN_MODES.VIEW && (
              <Grid item xs={3} md={6}>
                <Box sx={{ display: "flex" }}>
                  <Grid item xs={12} md={4}>
                    <label htmlFor="OtherFile">
                      <LoadingButton style={{ color: "white" }} loading={props.otherDocResponse.isLoading} loadingIndicator={<CustomCircularProgress />} component="span">
                        Select File
                      </LoadingButton>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {_otherFileName.value !== '' && <Box sx={{ display: "flex" }}>
                      <StyledTextField
                        fullWidth
                        label="File Attached"
                        placeholder="Enter driver NIC"
                        size="small"
                        InputProps={{
                          readOnly: _otherFileName.readonly,
                        }}
                        value={_otherFileName.value}
                        error={!!_otherFileName.error}
                        disabled={false}
                        required={_otherFileName.isRequired}
                        helperText={props.helperText && _otherFileName.error}
                        onFocus={() => props.handleInputFocus("otherFileName", "other")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          props.onInputHandleChange("otherFileName", event.target.value)
                        }
                      />
                    </Box>}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {props.screenMode !== DRIVER_SCREEN_MODES.VIEW && (
                      <div className='layout-row'>
                        <CustomButton text='Clear' textColor='black' bgColor='#bfbfbf' onClick={() => { props.handleDeleteFile(DOCUMENT_TYPE.DTIVER_DOCUMENTS, DRIVERDOCUMENT.OTHER_DOCUMENT) }} />
                        {/* <Button style={{ color: "white" }} startIcon={<DeleteForeverRoundedIcon />} onClick={() => { props.handleDeleteFile(DOCUMENT_TYPE.DTIVER_DOCUMENTS, DRIVERDOCUMENT.OTHER_DOCUMENT) }} /> */}
                        <CustomButton text='Submit' textColor='black' bgColor='#bfbfbf' onClick={() => { props.onAddDocuments(DRIVERDOCUMENT.OTHER_DOCUMENT) }} />
                        {/* <Button  variant={"text"} style={{ color: "white" }} startIcon={<PublishIcon />} onClick={() => { props.onAddDocuments(DRIVERDOCUMENT.OTHER_DOCUMENT) }}> Add </Button> */}
                      </div>
                    )}
                  </Grid>
                </Box>
              </Grid>
            )}
          </Grid>
          <section className={style.actions}>
          </section>
        </>
      )}
      {props.isLoading && (
        <section style={{ marginBottom: "20px" }}>
          <AppSkeleton
            numOfRows={2}
            numOfColumns={2}
            columnWidth={50}
            height={40}
            tag="stepper"
          />
        </section>
      )}
      <section>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <CustomHeaderCell id='id'>#</CustomHeaderCell>
                <CustomHeaderCell id="incident" width={200}> Document Category </CustomHeaderCell>
                <CustomHeaderCell id="incident" width={100}> Document Version </CustomHeaderCell>
                <CustomHeaderCell id="date" width={120}> Issued Date </CustomHeaderCell>
                <CustomHeaderCell id="outcome" width={120}> Expiry Date </CustomHeaderCell>
                <CustomHeaderCell id="incident"> File Name </CustomHeaderCell>
                <CustomHeaderCell id="outcome"> Actions </CustomHeaderCell>
              </TableRow>
            </TableHead>
            {props.isLoading && (
              <AppSkeleton
                numOfRows={3}
                numOfColumns={7}
                columnWidth={100}
                tag="table"
              />
            )}

            <TableBody>
              {props.documentList.map((doc, index) => (
                <TableRow key={index}>
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell>{doc.category}</StyledTableCell>
                  <StyledTableCell>{doc.version ? doc.version : 'N/A'}</StyledTableCell>
                  <StyledTableCell>{doc.issuedDate ? doc.issuedDate : 'N/A'}</StyledTableCell>
                  <StyledTableCell>{doc.expiryDate ? doc.expiryDate : 'N/A'}</StyledTableCell>
                  <StyledTableCell>{doc.documentName ? doc.documentName : 'N/A'}</StyledTableCell>
                  <StyledTableCell>
                    <Box className="layout-row">
                      <Box>
                        <IconButton size='small' onClick={() => props.onSelectDocument(doc.id, "view")}>
                          <Tooltip title="View">
                            <RemoveRedEyeOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                      <Box>
                        {(props.screenMode === DRIVER_SCREEN_MODES.CREATE || props.screenMode === DRIVER_SCREEN_MODES.EDIT) && (
                          <IconButton size='small' onClick={() => props.onSelectDocument(doc.id, "download")}>
                            <Tooltip title="Download">
                              <CloudDownloadIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                            </Tooltip>
                          </IconButton>
                        )}
                      </Box>
                      <Box>
                        {(props.screenMode === DRIVER_SCREEN_MODES.CREATE || props.screenMode === DRIVER_SCREEN_MODES.EDIT) && (
                          <IconButton size='small' onClick={() => props.onSelectDocument(doc.id, "delete")}>
                            <Tooltip title="Delete">
                              <DeleteOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                            </Tooltip>
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
            {props.documentList.length === 0 &&
              <TableBody>
                <TableRow>
                  <StyledTableCell align="left" colSpan={7}>No data to preview</StyledTableCell>
                </TableRow>
              </TableBody>
            }
          </Table>
        </TableContainer>
      </section>
    </CustomStepper>
  );
};

export default DriverDocuments;
