import React from 'react'
import { BootstrapDialog, BootstrapDialogActions, BootstrapDialogContent, BootstrapDialogTitle } from '../../../assets/theme/theme'
import styles from './ShowStatusPopup.module.scss'
import { RequestStatusProcessDto, StateObjectDto } from '../../../utilities/models'
import { CustomButton, AppSkeleton } from '..'
import moment from 'moment'


const ShowStatusPopup: React.FC<{
  getRequestStatusProcessResponse: StateObjectDto<RequestStatusProcessDto[]>
  selectedRequestStatusId: string
  isStatusDialogOpen: boolean
  showStatusPopup(con: boolean): void
}> = (props) => {
  return (
    <BootstrapDialog
      width={"1200px"}
      className={styles.dialogCard}
      aria-labelledby="customized-dialog-title"
      open={props.isStatusDialogOpen}
    >
      <BootstrapDialogTitle id="customized-dialog-title"
        onClose={() => props.showStatusPopup(false)}
      >
        Request ID - {props.selectedRequestStatusId}
      </BootstrapDialogTitle>
      <BootstrapDialogContent>
        <section className={styles.bodyContent}>
          <div className={styles.stepperRow}>
            {!props.getRequestStatusProcessResponse.isLoading && props.getRequestStatusProcessResponse.data && props.getRequestStatusProcessResponse.data.length > 0 &&
              props.getRequestStatusProcessResponse.data.map((s, index) => (
                <>
                  <div className={styles.status}>
                    <div className={styles.stepCircle}>
                      <div className={styles.stepInnerCircle}></div>
                    </div>
                    <div className={styles.stepContent}>
                      <div className={styles.statusName}>{s.statuses}</div>
                      <div className={styles.date}>{moment(s.statusDate).add(330, 'minute').format('YYYY/MM/DD HH:mm')}</div>
                    </div>
                  </div>
                  {index !== props.getRequestStatusProcessResponse.data.length - 1 && <div className={styles.horizontalLine} />}
                </>
              ))
            }
          </div>
          {props.getRequestStatusProcessResponse.isLoading &&
            <AppSkeleton numOfRows={1} numOfColumns={2} columnWidth={120} height={30} tag="stepper" />
          }
        </section>
      </BootstrapDialogContent>
      <BootstrapDialogActions>
        <CustomButton text='Cancel' border='1px solid #6e6e6e' bgColor='#282828' onClick={() => props.showStatusPopup(false)} />
      </BootstrapDialogActions>
    </BootstrapDialog>
  )
}

export default ShowStatusPopup
