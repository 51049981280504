import { AxiosResponse } from "axios"
import { axiosPrivateInstance } from "."
import { PackageTypeBriefDto } from "../utilities/models"

const getPackageType = (): Promise<AxiosResponse<PackageTypeBriefDto[]>> => {
    return axiosPrivateInstance.get('/api/PackageType/GetPackageTypes')
}

export const packageService = {
    getPackageType
}