// import React, { useEffect } from "react";
import React, { useEffect, useState } from "react";
import { AppLayout } from "../../templates";
import style from "./ManageTemplates.module.scss";
import TemplateCard from "../../components/Shared/Template/TemplateCard";
import { Box } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { ApplicationStateDto, TemplatesDto } from "../../utilities/models";
import { templateActions } from "../../redux/actions";
import { APP_ACTION_STATUS } from "../../utilities/constants";

const ManageTemplates = () => {
  const [templateData, settemplateData] = useState<TemplatesDto[]>();
  const dispatch = useDispatch();

  const templatesresponse = useSelector((state: ApplicationStateDto) => state.template.getTemplate);
  const deleteTemplateresponse = useSelector((state: ApplicationStateDto) => state.template.deleteTemplate);

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (templatesresponse?.status === APP_ACTION_STATUS.SUCCESS) {
      settemplateData(templatesresponse.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templatesresponse?.status]);

  useEffect(() => {
    if (deleteTemplateresponse.status === APP_ACTION_STATUS.SUCCESS) {
      getInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTemplateresponse?.status]);

  const getInitialData = async () => {
    dispatch(templateActions.getTemplate());
  };

  const handleDelete = (id: number) => {
    dispatch(templateActions.deleteTemplate(id));
  };

  return (
    <React.Fragment>
      <AppLayout componentTitle="Manage Templates">
        <section className="page-root">
          <section className={style.sectionCard}>
            <h1 className={style.sectionCardHeader}>Template Details</h1>
            <Box className={style.sectionCardBody}>
              <div className={style.templates}>
                {templateData === null ? (
                  <>
                    <h1>NO DATA FOUND</h1>
                  </>
                ) : (
                  templateData?.map((card: any) => (
                    <TemplateCard
                      key={card.id}
                      id={card.id}
                      title={card.title}
                      purpose={card.purpose}
                      requestType={card.requestType}
                      toLocation={card.toLocation}
                      fromLocation={card.fromLocation}
                      passengerCount={card.passengerCount}
                      packageCount={card.packageCount}
                      passengersList={card.passengersList}
                      onDelete={() => handleDelete(card.id)}
                    />
                  ))
                )}
              </div>
            </Box>
          </section>
        </section>
      </AppLayout>
    </React.Fragment>
  );
};

export default ManageTemplates;
