import { APP_ACTION_STATUS, COMMON_ACTION_TYPES, GPS_ACTION_TYPES } from "../../utilities/constants";
import { GPSStateDto } from "../../utilities/models/state.model";

const INITIAL_STATE: GPSStateDto = {
    postRealTripDetails: {
        data: "",
        error: null,
        isLoading: false,
        status: null,
    },
    getActualTripDetails: {
        data: {
            tripEndDateTime: null,
            tripStartDateTime:null
        },
        error: null,
        isLoading: false,
        status: null,
    },
    resetActualTripDetails:{
        data: "",
        error: null,
        isLoading: false,
        status: null,
    }
}

const gpsReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case GPS_ACTION_TYPES.POST_REAL_TRIP + COMMON_ACTION_TYPES.REQUEST:
            return {
                ...state,
                postRealTripDetails: {
                    ...state.postRealTripDetails,
                    isLoading: true,
                    status: APP_ACTION_STATUS.LOADING,
                    error: null,
                    data: "",
                },
            };
        case GPS_ACTION_TYPES.POST_REAL_TRIP + COMMON_ACTION_TYPES.SUCCESS:
            return {
                ...state,
                postRealTripDetails: {
                    ...state.postRealTripDetails,
                    isLoading: false,
                    status: APP_ACTION_STATUS.SUCCESS,
                    error: null,
                    data: action.data,
                },
            };
        case GPS_ACTION_TYPES.POST_REAL_TRIP + COMMON_ACTION_TYPES.ERROR:
            return {
                ...state,
                postRealTripDetails: {
                    ...state.postRealTripDetails,
                    isLoading: false,
                    status: APP_ACTION_STATUS.ERROR,
                    error: action.error,
                    data: "",
                },
            };
        case GPS_ACTION_TYPES.POST_REAL_TRIP + COMMON_ACTION_TYPES.CLEAR:
            return {
                ...state,
                postRealTripDetails: {
                    ...state.postRealTripDetails,
                    isLoading: false,
                    status: APP_ACTION_STATUS.INITIAL,
                    error: null,
                    data: "",
                },
            };
        case GPS_ACTION_TYPES.GET_CURRENT_TRIP_SITUATION + COMMON_ACTION_TYPES.REQUEST:
            return {
                ...state,
                getActualTripDetails: {
                    ...state.getActualTripDetails,
                    isLoading: true,
                    status: APP_ACTION_STATUS.LOADING,
                    error: null,
                    data: null,
                },
            };
        case GPS_ACTION_TYPES.GET_CURRENT_TRIP_SITUATION + COMMON_ACTION_TYPES.SUCCESS:
            return {
                ...state,
                getActualTripDetails: {
                    ...state.getActualTripDetails,
                    isLoading: false,
                    status: APP_ACTION_STATUS.SUCCESS,
                    error: null,
                    data: action.data,
                },
            };
        case GPS_ACTION_TYPES.GET_CURRENT_TRIP_SITUATION + COMMON_ACTION_TYPES.ERROR:
            return {
                ...state,
                getActualTripDetails: {
                    ...state.getActualTripDetails,
                    isLoading: false,
                    status: APP_ACTION_STATUS.ERROR,
                    error: action.error,
                    data: null,
                },
            };
        case GPS_ACTION_TYPES.GET_CURRENT_TRIP_SITUATION + COMMON_ACTION_TYPES.CLEAR:
            return {
                ...state,
                getActualTripDetails: {
                    ...state.getActualTripDetails,
                    isLoading: false,
                    status: APP_ACTION_STATUS.INITIAL,
                    error: null,
                    data: null,
                },
            };
            case GPS_ACTION_TYPES.RESET_REAL_TRIP + COMMON_ACTION_TYPES.REQUEST:
                return {
                    ...state,
                    resetActualTripDetails: {
                        ...state.resetActualTripDetails,
                        isLoading: true,
                        status: APP_ACTION_STATUS.LOADING,
                        error: null,
                        data: "",
                    },
                };
            case GPS_ACTION_TYPES.RESET_REAL_TRIP + COMMON_ACTION_TYPES.SUCCESS:
                return {
                    ...state,
                    resetActualTripDetails: {
                        ...state.resetActualTripDetails,
                        isLoading: false,
                        status: APP_ACTION_STATUS.SUCCESS,
                        error: null,
                        data: action.data,
                    },
                };
            case GPS_ACTION_TYPES.RESET_REAL_TRIP + COMMON_ACTION_TYPES.ERROR:
                return {
                    ...state,
                    resetActualTripDetails: {
                        ...state.resetActualTripDetails,
                        isLoading: false,
                        status: APP_ACTION_STATUS.ERROR,
                        error: action.error,
                        data: "",
                    },
                };
            case GPS_ACTION_TYPES.RESET_REAL_TRIP + COMMON_ACTION_TYPES.CLEAR:
                return {
                    ...state,
                    resetActualTripDetails: {
                        ...state.resetActualTripDetails,
                        isLoading: false,
                        status: APP_ACTION_STATUS.INITIAL,
                        error: null,
                        data: "",
                    },
                };    
        default:
            return state;
    }
}

export default gpsReducer;