import { Box, Grid, IconButton, Typography } from "@mui/material";
import { GoogleMap, Marker, DirectionsRenderer, } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import usePlacesAutocomplete, {
  GeoArgs,
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import PlaceAutoComplete from "../PlaceAutoComplete/PlaceAutoComplete";
import { StyledTextField } from "../../../../assets/theme/theme";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useDispatch, useSelector } from "react-redux";
import { ApplicationStateDto } from "../../../../utilities/models";
import { APP_ACTION_STATUS } from "../../../../utilities/constants";
import CustomMarker from "../CustomeMarkerIcon/CustomMarkerIcon";
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import { mapActions } from "../../../../redux/actions";


const MapV2 = () => {
  const containerStyle = {
    width: "100%",
    minWidth: "300px",
    minHeight: "400px",
    height: "100%",
  };

  const center = {
    lat: 7.2905715,
    lng: 80.6337262,
  };

  interface selectedType {
    lat: number;
    lng: number;
  }

  const startPointesponse = useSelector((state: ApplicationStateDto) => state.map.AddStartMapPoint);
  const EndPointesponse = useSelector((state: ApplicationStateDto) => state.map.AddEndMapPoint);
  const ADDPickUpPoints = useSelector((state: ApplicationStateDto) => state.map.ADDPickUpPoints);
  const addDropOffPoints = useSelector((state: ApplicationStateDto) => state.map.addDropOffPoints);

  const [DerectionResponse, setDerectionResponse] = useState<google.maps.DirectionsResult>()
  const [DerectionResponseBack, setDerectionResponseBack] = useState<google.maps.DirectionsResult>()

  const [duration, setduration] = useState<string>()
  const [duration2, setduration2] = useState<string>()
  const [totalDistance, setTotalDistance] = useState<number | null>(null);
  const [Distance2, setDistance2] = useState<string>();
  const [Distance, setDistance] = useState<string>();
  const [startLocation, setStartLocation] = useState<selectedType>();
  const [endLocation, setEndLocation] = useState<selectedType>();

  const [PickUpPoints, setPickUpPointss] = useState<selectedType[]>([]);
  const [DropOffPoints, setDropOffPoints] = useState<selectedType[]>([]);

  const { ready, value, setValue, suggestions: { status, data }, clearSuggestions } = usePlacesAutocomplete();
  // const [pointToPointDistances, setPointToPointDistances] = useState<string[]>([]);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const dispatch = useDispatch();





  useEffect(() => {
    if (totalDistance) {
      dispatch(mapActions.SetTotalDistance(totalDistance.toString()));
    }
  }, [totalDistance])

  useEffect(() => {
    CalculateRoute()
  }, [startLocation, endLocation, DropOffPoints, PickUpPoints.length])

  useEffect(() => {

    if (ADDPickUpPoints.status === APP_ACTION_STATUS.SUCCESS) {
      const updatedPickUpPoints = ADDPickUpPoints.data.filter(coord => coord.lat !== startLocation?.lat && coord.lng != startLocation?.lng && coord.lat !== 0 && coord.lng !== 0);
      setPickUpPointss(updatedPickUpPoints)
      CalculateRoute()
    }

    if (addDropOffPoints.status === APP_ACTION_STATUS.SUCCESS) {
      const updatedDropOffPoints = addDropOffPoints.data.filter(coord => coord.lat !== endLocation?.lat && coord.lng != endLocation?.lng && coord.lat !== 0 && coord.lng !== 0);
      setDropOffPoints(updatedDropOffPoints)
      CalculateRoute()
    }
    if (addDropOffPoints.status === APP_ACTION_STATUS.INITIAL) {
      setDropOffPoints([])
    }
    if (ADDPickUpPoints.status === APP_ACTION_STATUS.INITIAL) {
      setPickUpPointss([])
    }

  }, [ADDPickUpPoints.status, addDropOffPoints.status])


  useEffect(() => {
    // removeDuplicateData()
    CalculateRoute()
  }, [ADDPickUpPoints.status, addDropOffPoints.status,])

  useEffect(() => {

    if (startPointesponse.status === APP_ACTION_STATUS.SUCCESS) {
      setStartLocation(startPointesponse.data)
      console.log("startPointesponse.data", startPointesponse.data);
    }

  }, [startPointesponse.data])


  useEffect(() => {
    if (EndPointesponse.status === APP_ACTION_STATUS.SUCCESS) {
      setEndLocation(EndPointesponse.data)
      //  console.log("EndPointesponse.data",EndPointesponse.data);
    }

  }, [EndPointesponse.data])


  // const removeDuplicateData = () => {

  //   if (startLocation !== null ) {
  //     const updatedPickUpPoints = PickUpPoints.filter(coord => coord.lat !== startLocation?.lat && coord.lng!=startLocation?.lng  &&coord.lat !== 0 && coord.lng !== 0);
  //     setFilterdPickUpPoints(updatedPickUpPoints);
  //     CalculateRoute()
  //   }
  //   if (endLocation !== null) {

  //     console.log("DropOffPointsbeforFiltering",DropOffPoints)
  //     const updatedDropOffPoints = DropOffPoints.filter(coord => coord.lat !== endLocation?.lat && coord.lng!=endLocation?.lng  &&coord.lat !== 0 && coord.lng !== 0);
  //      console.log("updatedDropOffPoints",updatedDropOffPoints);
  //      setFilterdDropOffPoints(updatedDropOffPoints);
  //     CalculateRoute()
  //   }
  // };
  const CalculateRoute = async () => {
    if (!startLocation || !endLocation) {
      // Handle the case where startLocation or endLocation is undefined
      setDerectionResponseBack(undefined);
      setDerectionResponse(undefined);
      setduration("");
      setduration2("");
      return;
    }


    const direCtionService = new google.maps.DirectionsService()
    const direCtionService2 = new google.maps.DirectionsService()
    const waypoints: google.maps.DirectionsWaypoint[] = PickUpPoints.map((location) => ({
      location: {
        lat: location.lat, // Latitude of the pickup point
        lng: location.lng, // Longitude of the pickup point
      },
      stopover: true,
      // Set to true if you want to stop at the waypoint, false otherwise.
    }));



    const wayOutPoints: google.maps.DirectionsWaypoint[] = DropOffPoints.map((location) => ({
      location: {
        lat: location.lat, // Latitude of the pickup point
        lng: location.lng, // Longitude of the pickup point
      },
      stopover: true,
      // Set to true if you want to stop at the waypoint, false otherwise.
    }));




    try {
      const results = await direCtionService.route({
        origin: startLocation,
        destination: endLocation,
        waypoints: waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
        optimizeWaypoints: true,
        provideRouteAlternatives: true,
      })
      const results2 = await direCtionService2.route({
        origin: endLocation,
        destination: startLocation,
        waypoints: wayOutPoints,
        travelMode: google.maps.TravelMode.DRIVING,
        optimizeWaypoints: true,
        provideRouteAlternatives: true
      })

      setDerectionResponse(results)
      setDerectionResponseBack(results2)
      const routeLegs = results.routes[0].legs;
      const totalDistanceInMeters = routeLegs.reduce((total, leg) => total + (leg.distance?.value || 0), 0);
      const totalDuration = routeLegs.reduce((total, leg) => total + (leg.duration?.value || 0), 0);
      const routeLegs2 = results2.routes[0].legs;
      const totalDistanceInMeters2 = routeLegs2.reduce((total, leg) => total + (leg.distance?.value || 0), 0);
      const totalDuration2 = routeLegs2.reduce((total, leg) => total + (leg.duration?.value || 0), 0);


      // Convert total distances to kilometers
      const totalDistanceInKilometers = (totalDistanceInMeters / 1000).toFixed(2);
      const totalDistanceInKilometers2 = (totalDistanceInMeters2 / 1000).toFixed(2);
      const totalDeparturetime = formatDuration(totalDuration)
      const totalReturntime = formatDuration(totalDuration2)


      setDistance(totalDistanceInKilometers)
      setDistance2(totalDistanceInKilometers2)
      setduration(totalDeparturetime)
      setduration2(totalReturntime)

      setTotalDistance((Number(totalDistanceInKilometers) + Number(totalDistanceInKilometers2)));
      if (totalDistance) {
        dispatch(mapActions.SetTotalDistance(totalDistance.toString()));
      }

    } catch (error) {
      if (error !== null && typeof error === 'object' && 'message' in error) {
        console.error("Directions API Error:", (error as { message: string }).message);
      } else {
        console.error("Error calculating route:", error);
      }
      setDerectionResponseBack(undefined);
      setDerectionResponse(undefined);
      setduration("");
      setduration2("");
    }
  }

  const customPolylineOptions = {
    strokeColor: '#1712CB', // Set the desired color for the route polyline
    strokeOpacity: 0.8,
    strokeWeight: 5,
    geodesic: true
  };
  const customPolylineOptions2 = {
    strokeColor: '#25a5be', // Set the desired color for the route polyline
    strokeOpacity: 0.8,
    strokeWeight: 5,
    geodesic: true


  };

  // Helper function to format duration in seconds to a readable format

  const formatDuration = (durationInSeconds: number) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    if (hours === 0) {
      return `${minutes} mins`
    }
    return `${hours} hrs ${minutes} mins`;
  }

  return (
    <Box>
      <Grid container spacing={4}>

        {/* <Grid item xs={12} md={6}>
          <StyledTextField
             label="Projected Mileage(km)"
            fullWidth
            placeholder="Projected Mileage(km)"
            size="small"
            value={totalDistance !== null ? `${totalDistance.toFixed(2)} ` : ""}
            inputProps={{ readOnly: true }}
          />
        </Grid> */}

        <Grid item xs={12} md={12}>
          <GoogleMap
            zoom={10}
            center={center}
            mapContainerStyle={containerStyle}
            options={{
              zoomControl: true,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,

            }} >


            {startLocation && startLocation.lat !== 0 && startLocation.lng !== 0 && !DerectionResponse && <Marker position={startLocation} />}
            {endLocation && endLocation.lat !== 0 && endLocation.lng !== 0 && !DerectionResponse && <Marker position={endLocation} />}



            {DerectionResponseBack && <DirectionsRenderer directions={DerectionResponseBack}
              options={{ polylineOptions: customPolylineOptions2, }} />}

            {DerectionResponse && <DirectionsRenderer directions={DerectionResponse}
              options={{ polylineOptions: customPolylineOptions, }} />}


            {/* 
{duration && (
    <><div 
                style={{
                  justifyContent:"space-between",
                  position: 'inherit',
                 width:"26rem",
                  top: '1rem',
                  left: '1rem',
                  right: '1rem',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  padding: '0.5rem',
                  borderRadius: '5px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                }}
              ><Box sx={{ display: "flex", alignItems: "center",color:"black",justifyContent:"start",}}>
                <Box sx={{display:"flex",alignItems: "center"}}>
              <Typography sx={{ marginRight: "10px",fontWeight:"600"  }}>Departure Trip</Typography>
              <div
                style={{
                  backgroundColor: "#1712CB",
                  width: "15px",
                  height: "15px",
                  marginRight: "10px",
                }}
              />
              </Box>
              <Box sx={{display:"flex",alignItems: "center"}}>
              <Typography sx={{ marginRight: "10px" ,fontWeight:"600" }}> Return Trip</Typography>
              <div
                style={{
                  backgroundColor: "#25a5be",
                  width: "15px",
                  height: "15px",
                  marginLeft: "10px",
                }}
              />
              </Box>
            </Box>
         <Box sx={{display:"flex", justifyContent:"space-between",}}>
                <Typography sx={{fontSize:"0.8rem" , color: "#1712CB" ,fontWeight:"500"}}>
                  Estimated Duration: {duration}
                </Typography>
                <Typography sx={{ fontSize:"0.8rem" , color: "#1712CB" ,fontWeight:"500"}}>
                  Estimated Distance: {Distance+`km`}
                </Typography>
                </Box>
                <Box sx={{display:"flex", justifyContent:"space-between",}}>
                <Typography sx={{ fontSize:"0.8rem" , color: "#25a5be",fontWeight:"500" }}>
                  Estimated Duration: {duration2}
                </Typography>
                <Typography sx={{ fontSize:"0.8rem" , color: "#25a5be" ,fontWeight:"500"}}>
                  Estimated Distance: {Distance2+`km`}
                </Typography>
                </Box>
              </div>
             </>
    
  )} */}

          </GoogleMap>
        </Grid>

      </Grid>
    </Box>
  );
};

export default MapV2;
