import { templateService } from "../../services/template.service";
import { call, put, takeEvery } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  TEMPLATE_ACTION_TYPES,
  COMMON_ACTION_TYPES,
  ALERT_ACTION_TYPES,
} from "../../utilities/constants";
import {
  GetMatchingTemplateListdto,
  GetMatchingTemplatesParamDto,
  TemplatesDto,
} from "../../utilities/models/template.model";
import { AlertDto } from "../../utilities/models/alert.model";

function* getTemplate(action: { type: string }) {
  try {
    const templates: AxiosResponse<TemplatesDto[]> = yield call(
      templateService.getTemplates
    );
    yield put({
      type:
        TEMPLATE_ACTION_TYPES.GET_TEMPLATE_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: templates.data,
    });
  } catch (error) {
    yield put({
      type: TEMPLATE_ACTION_TYPES.GET_TEMPLATE_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* deleteTemplate(action: { type: string; id: number }) {
  try {
    yield call(templateService.deleteTemplate, action.id);
    const setAlert: AlertDto = {
      message: "Template deleted successfully",
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert);
    yield put({
      type: TEMPLATE_ACTION_TYPES.DELETE_TEMPLATE + COMMON_ACTION_TYPES.SUCCESS,
    });
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: TEMPLATE_ACTION_TYPES.DELETE_TEMPLATE + COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* getMatchingTemplateList(action: {
  type: string;
  param: GetMatchingTemplatesParamDto;
}) {
  try {
    const templateList: AxiosResponse<GetMatchingTemplateListdto[]> =
      yield call(templateService.getMatchingTemplateList, action.param);
    yield put({
      type:
        TEMPLATE_ACTION_TYPES.GET_MATCHING_TEMPLATE_LIST +
        COMMON_ACTION_TYPES.SUCCESS,
      data: templateList.data,
    });
  } catch (error) {
    yield put({
      type:
        TEMPLATE_ACTION_TYPES.GET_MATCHING_TEMPLATE_LIST +
        COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* templateSaga() {
  yield takeEvery(
    TEMPLATE_ACTION_TYPES.GET_TEMPLATE_LIST + COMMON_ACTION_TYPES.REQUEST,
    getTemplate
  );

  yield takeEvery(
    TEMPLATE_ACTION_TYPES.DELETE_TEMPLATE + COMMON_ACTION_TYPES.REQUEST,
    deleteTemplate
  );
  yield takeEvery(
    TEMPLATE_ACTION_TYPES.GET_MATCHING_TEMPLATE_LIST +
      COMMON_ACTION_TYPES.REQUEST,
    getMatchingTemplateList
  );
}

export default templateSaga;
