import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/theme/base.css";
import "./assets/theme/main.scss";
import { Provider } from "react-redux";
import store from "./redux/store";
import { SnackbarProvider } from "notistack";
import { msalConfig } from "./core/authConfig";
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from "@azure/msal-browser";

export const msalInstance = new PublicClientApplication(msalConfig)
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <SnackbarProvider maxSnack={3} style={{ fontFamily: 'Ubuntu' }}>
        <App />
      </SnackbarProvider>
    </MsalProvider>
  </Provider>
);

reportWebVitals();
