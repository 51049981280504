import { axiosPrivateInstance } from ".";
import { AxiosResponse } from "axios";
import { VehicleTypesDto, getVehicleListParamDto, rejectAllocationParam, tripAllocationDto, updateTripDetailsParam, vehicleDetailsListDto, vehicleDetailsParamDto } from "../utilities/models";


const getTripDetailsByRequestIds = (payload: any): Promise<AxiosResponse<any>> => {
  return axiosPrivateInstance.post(`api/Trip/ManageTripDetails`, payload)
}

const GetVehicleLitsBYPassengerCount = (payload: getVehicleListParamDto): Promise<AxiosResponse<VehicleTypesDto>> => {
  return axiosPrivateInstance.get(`/api/Trip/GetVehicleTypesbyPessangerCount?PassengerCount=${payload.NOSeats}&purpose=${payload.purpose}`,)
}

const GetVehicleDetailsByVehicleType = (payload: vehicleDetailsParamDto): Promise<AxiosResponse<vehicleDetailsListDto>> => {
  return axiosPrivateInstance.get(`/api/Vehicle/GetVehicleAllocationDetails?VehicleTypeId=${payload.VehicleTypeId}&PassengerCount=${payload.PassengerCount}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`)
}

const postTripAllocationDetails = (payload: tripAllocationDto): Promise<AxiosResponse<string>> => {
  return axiosPrivateInstance.post(`/api/Trip/TripAllocation`, payload)
}
const updateTripAllocationDetails=(payload:updateTripDetailsParam):Promise<AxiosResponse<string>> => {
  return axiosPrivateInstance.put(`/api/Trip/UpdateAllocatedTrip?id=${payload.param}`, payload.requestBody)
}

const rejectTripAllocation=(payload:rejectAllocationParam):Promise<AxiosResponse<string>> => {
  return axiosPrivateInstance.post(`api/Trip/AllocationRejection`, payload)
}

export const tripService = {
  getTripDetailsByRequestIds,
  GetVehicleLitsBYPassengerCount,
  GetVehicleDetailsByVehicleType,
  postTripAllocationDetails,
  updateTripAllocationDetails,
  rejectTripAllocation,
}