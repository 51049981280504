import { SHIFT_ACTION_TYPES, COMMON_ACTION_TYPES  } from "../../utilities/constants"
import { duplicateShiftRequestDto, newShiftRequestDto, shiftRequestSummaryParam, shiftVehicleDetailsParamDto } from "../../utilities/models/shift.model"

const uploadFile = (payload:any) => {
    return { 
        type: SHIFT_ACTION_TYPES.SHIFT_UPLOAD_FILE+ COMMON_ACTION_TYPES.REQUEST ,
        payload:payload
    }
}

const uploadFileClear = () => {
    return { 
        type: SHIFT_ACTION_TYPES.SHIFT_UPLOAD_FILE+ COMMON_ACTION_TYPES.CLEAR ,
    }
}

const downloadShiftFile=(payload:string)=>{
    return { 
        type: SHIFT_ACTION_TYPES.SHIFT_DOWNLOAD_FILE + COMMON_ACTION_TYPES.REQUEST ,
        payload:payload
    }
}


const deleteFile = (payload:any) => {
    return {
        type:SHIFT_ACTION_TYPES.SHIFT_DELETE_FILE + COMMON_ACTION_TYPES.REQUEST,
        payload:payload
    }
}

const getShiftTypes = () => {
    return { type: SHIFT_ACTION_TYPES.GET_SHIFT_TYPES + COMMON_ACTION_TYPES.REQUEST }
}

const getShiftGroups = () => {
    return { type: SHIFT_ACTION_TYPES.GET_SHIFT_GROUPS + COMMON_ACTION_TYPES.REQUEST }
}

const getAvailableShiftVehicles = (payload: shiftVehicleDetailsParamDto) => {
    return {
        type: SHIFT_ACTION_TYPES.GET_AVAILABLE_SHIFT_VEHICLES + COMMON_ACTION_TYPES.REQUEST,
        payload: payload
    }
}

const clearAvailableShiftVehicles = () => {
    return{
        type:SHIFT_ACTION_TYPES.GET_AVAILABLE_SHIFT_VEHICLES + COMMON_ACTION_TYPES.CLEAR,
    }
}

const postShiftRequest = (payload: newShiftRequestDto) => {
    return {
        type: SHIFT_ACTION_TYPES.POST_SHIFT_REQUEST + COMMON_ACTION_TYPES.REQUEST,
        payload: payload
    }
}


const postShiftRequestDuplicate = (payload: duplicateShiftRequestDto) => {
    return {
        type: SHIFT_ACTION_TYPES.POST_SHIFT_DUPLICATE + COMMON_ACTION_TYPES.REQUEST,
        payload: payload
    }
}
const postShiftRequestClear = () => {
    return{
        type:SHIFT_ACTION_TYPES.POST_SHIFT_REQUEST + COMMON_ACTION_TYPES.CLEAR,
    }
}

const postShiftRequestDuplicateClear = () => {
    return{
        type:SHIFT_ACTION_TYPES.POST_SHIFT_DUPLICATE + COMMON_ACTION_TYPES.CLEAR,
    }
}

const getShiftRequestList = (payload:shiftRequestSummaryParam) => {
    return{ type:SHIFT_ACTION_TYPES.GET_SHIFT_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.REQUEST,
            payload:payload
    }
}

const getShiftRequestById = (requestId: string) => {
    return {
        type: SHIFT_ACTION_TYPES.GET_SHIFT_REQUEST + COMMON_ACTION_TYPES.REQUEST,
        requestId: requestId
    }
}

const getShiftRequestByIdClear = () => {
    return {
        type: SHIFT_ACTION_TYPES.GET_SHIFT_REQUEST + COMMON_ACTION_TYPES.CLEAR,
    }
}

const deleteShiftRequest = (id: number) => {
    return {
        type: SHIFT_ACTION_TYPES.DELETE_SHIFT_REQUEST + COMMON_ACTION_TYPES.REQUEST,
        id: id
    }
}

const GetExecutionTemplates=()=>{
    return {
        type: SHIFT_ACTION_TYPES.GET_EXECUTION_TEMPLATES + COMMON_ACTION_TYPES.REQUEST,
    }
 }

const editShiftRequest = (payload: any) => {
    return {
        type: SHIFT_ACTION_TYPES.EDIT_SHIFT_REQUEST + COMMON_ACTION_TYPES.REQUEST,
        payload: payload
    }
}

const editShiftRequestClear = () => {
    return {
        type: SHIFT_ACTION_TYPES.EDIT_SHIFT_REQUEST + COMMON_ACTION_TYPES.CLEAR,
    }
}

const deleteShiftRequestClear = () => {
    return {
        type: SHIFT_ACTION_TYPES.DELETE_SHIFT_REQUEST + COMMON_ACTION_TYPES.CLEAR
    }
}

const executeShiftRequest = (payload: any) => {
    return {
        type: SHIFT_ACTION_TYPES.EXECUTE_SHIFT_REQUEST + COMMON_ACTION_TYPES.REQUEST,
        payload: payload
    }
}

const executeShiftRequestClear = () => {
    return {
        type: SHIFT_ACTION_TYPES.EXECUTE_SHIFT_REQUEST + COMMON_ACTION_TYPES.CLEAR,
    }
}

const getExecutedTemplateById = (dailyShiftHistoryId: string) => {
    return {
        type: SHIFT_ACTION_TYPES.GET_EXECUTED_TEMPLATE + COMMON_ACTION_TYPES.REQUEST,
        dailyShiftHistoryId: dailyShiftHistoryId
    }
}

export const shiftActions = {
    uploadFile,
    deleteFile,
    getShiftTypes,
    getShiftGroups,
    getAvailableShiftVehicles,
    postShiftRequest,
    postShiftRequestClear,
    getShiftRequestList,
    getShiftRequestById,
    deleteShiftRequest,
    editShiftRequest,
    GetExecutionTemplates,
    clearAvailableShiftVehicles,
    executeShiftRequest,
    deleteShiftRequestClear,
    editShiftRequestClear,
    downloadShiftFile,
    uploadFileClear,
    getShiftRequestByIdClear,
    executeShiftRequestClear,
    getExecutedTemplateById,
    postShiftRequestDuplicate,
    postShiftRequestDuplicateClear
}