import { APP_ACTION_STATUS, COMMON_ACTION_TYPES, DRIVER_ACTION_TYPE } from "../../utilities/constants";
import { DriverStateDto } from "../../utilities/models";

const INITIAL_STATE: DriverStateDto = {
  viewDriver: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  addDriver: {
    data: "",
    error: null,
    isLoading: false,
    status: null,
  },
  editDriver: {
    data: "",
    error: null,
    isLoading: false,
    status: null,
  },
  editDriverVehicle: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  getDrivers: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  deleteDriver: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  getPlants: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getVehicleTypesByPlant: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getVehicleNumbersByType: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getVehicleNumbersByDriver: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  }
}

const driverReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DRIVER_ACTION_TYPE.GET_DRIVER + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        viewDriver: {
          ...state.viewDriver,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case DRIVER_ACTION_TYPE.GET_DRIVER + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        viewDriver: {
          ...state.viewDriver,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case DRIVER_ACTION_TYPE.GET_DRIVER + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        viewDriver: {
          ...state.viewDriver,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case DRIVER_ACTION_TYPE.GET_DRIVER + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        viewDriver: {
          ...state.viewDriver,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case DRIVER_ACTION_TYPE.POST_DRIVER + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        addDriver: {
          ...state.addDriver,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: "",
        },
      };
    case DRIVER_ACTION_TYPE.POST_DRIVER + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        addDriver: {
          ...state.addDriver,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case DRIVER_ACTION_TYPE.POST_DRIVER + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        addDriver: {
          ...state.addDriver,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: "",
        },
      };
    case DRIVER_ACTION_TYPE.POST_DRIVER + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        addDriver: {
          ...state.addDriver,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: "",
        },
      };

    case DRIVER_ACTION_TYPE.EDIT_DRIVER + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        editDriver: {
          ...state.editDriver,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case DRIVER_ACTION_TYPE.EDIT_DRIVER + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        editDriver: {
          ...state.editDriver,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case DRIVER_ACTION_TYPE.EDIT_DRIVER + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        editDriver: {
          ...state.editDriver,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: "",
        },
      };
    case DRIVER_ACTION_TYPE.EDIT_DRIVER + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        editDriver: {
          ...state.editDriver,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: "",
        },
      };

    case DRIVER_ACTION_TYPE.MODIFY_DRIVER_VEHICLE + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        editDriverVehicle: {
          ...state.editDriverVehicle,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case DRIVER_ACTION_TYPE.MODIFY_DRIVER_VEHICLE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        editDriverVehicle: {
          ...state.editDriverVehicle,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case DRIVER_ACTION_TYPE.MODIFY_DRIVER_VEHICLE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        editDriverVehicle: {
          ...state.editDriverVehicle,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case DRIVER_ACTION_TYPE.MODIFY_DRIVER_VEHICLE + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        editDriverVehicle: {
          ...state.editDriverVehicle,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case DRIVER_ACTION_TYPE.GET_DRIVER_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getDrivers: {
          ...state.getDrivers,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case DRIVER_ACTION_TYPE.GET_DRIVER_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getDrivers: {
          ...state.getDrivers,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case DRIVER_ACTION_TYPE.GET_DRIVER_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getDrivers: {
          ...state.getDrivers,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case DRIVER_ACTION_TYPE.GET_DRIVER_LIST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getDrivers: {
          ...state.getDrivers,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case DRIVER_ACTION_TYPE.DELETE_DRIVER + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        deleteDriver: {
          ...state.deleteDriver,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case DRIVER_ACTION_TYPE.DELETE_DRIVER + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        deleteDriver: {
          ...state.deleteDriver,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case DRIVER_ACTION_TYPE.DELETE_DRIVER + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        deleteDriver: {
          ...state.deleteDriver,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case DRIVER_ACTION_TYPE.DELETE_DRIVER + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        deleteDriver: {
          ...state.deleteDriver,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case DRIVER_ACTION_TYPE.GET_PLANT_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getPlants: {
          ...state.getPlants,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case DRIVER_ACTION_TYPE.GET_PLANT_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getPlants: {
          ...state.getPlants,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case DRIVER_ACTION_TYPE.GET_PLANT_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getPlants: {
          ...state.getPlants,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case DRIVER_ACTION_TYPE.GET_PLANT_LIST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getPlants: {
          ...state.getPlants,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case DRIVER_ACTION_TYPE.GET_VEHICLE_TYPES_BY_PLANT + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getVehicleTypesByPlant: {
          ...state.getVehicleTypesByPlant,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case DRIVER_ACTION_TYPE.GET_VEHICLE_TYPES_BY_PLANT + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getVehicleTypesByPlant: {
          ...state.getVehicleTypesByPlant,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case DRIVER_ACTION_TYPE.GET_VEHICLE_TYPES_BY_PLANT + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getVehicleTypesByPlant: {
          ...state.getVehicleTypesByPlant,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case DRIVER_ACTION_TYPE.GET_VEHICLE_TYPES_BY_PLANT + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getVehicleTypesByPlant: {
          ...state.getVehicleTypesByPlant,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case DRIVER_ACTION_TYPE.GET_VEHICLE_NUMBERS_BY_TYPE + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getVehicleNumbersByType: {
          ...state.getVehicleNumbersByType,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case DRIVER_ACTION_TYPE.GET_VEHICLE_NUMBERS_BY_TYPE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getVehicleNumbersByType: {
          ...state.getVehicleNumbersByType,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case DRIVER_ACTION_TYPE.GET_VEHICLE_NUMBERS_BY_TYPE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getVehicleNumbersByType: {
          ...state.getVehicleNumbersByType,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case DRIVER_ACTION_TYPE.GET_VEHICLE_NUMBERS_BY_TYPE + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getVehicleNumbersByType: {
          ...state.getVehicleNumbersByType,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case DRIVER_ACTION_TYPE.GET_VEHICLE_NUMBERS_BY_DRIVER + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getVehicleNumbersByDriver: {
          ...state.getVehicleNumbersByDriver,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case DRIVER_ACTION_TYPE.GET_VEHICLE_NUMBERS_BY_DRIVER + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getVehicleNumbersByDriver: {
          ...state.getVehicleNumbersByDriver,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case DRIVER_ACTION_TYPE.GET_VEHICLE_NUMBERS_BY_DRIVER + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getVehicleNumbersByDriver: {
          ...state.getVehicleNumbersByDriver,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case DRIVER_ACTION_TYPE.GET_VEHICLE_NUMBERS_BY_DRIVER + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getVehicleNumbersByDriver: {
          ...state.getVehicleNumbersByDriver,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
        
    default:
      return state;
  }
}

export default driverReducer;