import { AxiosResponse } from "axios"
import { linkService } from "../../services/link.service"
import { DownloadParamLinkDto, FileResponseLinkDto, FileUploadLinkDto } from "../../utilities/models/link.model"
import { AlertDto } from "../../utilities/models/alert.model"
import { call, put, takeEvery } from "redux-saga/effects"
import { ALERT_ACTION_TYPES, COMMON_ACTION_TYPES, LINK_ACTION_TYPES } from "../../utilities/constants/action.constant"

  function* uploadMainFile(action: { type: string, payload:FileUploadLinkDto }) {
    try {
      const uploadFileResponse: AxiosResponse<string> = yield call(linkService.uploadMainFile, action.payload)
      const setAlert: AlertDto = {
        message: "File Uploaded",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'success'
        }
      }
      yield put(setAlert)
      yield put({
        type: LINK_ACTION_TYPES.UPLOAD_MAIN_FILE + COMMON_ACTION_TYPES.SUCCESS,
        data: uploadFileResponse.data
      })
    } catch (error) {
      const setAlert: AlertDto = {
        message: error as string,
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'error'
        }
      }
      yield put(setAlert)
      yield put({
        type: LINK_ACTION_TYPES.UPLOAD_MAIN_FILE + COMMON_ACTION_TYPES.ERROR,
        error: error
      })
    }
  }
  
  function* uploadSecondaryFile(action: { type: string, payload:FileUploadLinkDto }) {
    try {
    const uploadFileResponse: AxiosResponse<string> = yield call(linkService.uploadSecondaryFile, action.payload)
      const setAlert: AlertDto = {
        message: "File Uploaded",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'success'
        }
      }
      yield put(setAlert)
      yield put({
        type: LINK_ACTION_TYPES.UPLOAD_SECONDARY_FILE + COMMON_ACTION_TYPES.SUCCESS,
        data: uploadFileResponse.data
      })
    } catch (error) {
      const setAlert: AlertDto = {
        message: error as string,
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'error'
        }
      }
      yield put(setAlert)
      yield put({
        type: LINK_ACTION_TYPES.UPLOAD_SECONDARY_FILE + COMMON_ACTION_TYPES.ERROR,
        error: error
      })
    }
  }
  
  function* uploadOtherFile(action: { type: string, payload:FileUploadLinkDto }) {
    try {
    const uploadFileResponse: AxiosResponse<string> = yield call(linkService.uploadOtherFile, action.payload)
      const setAlert: AlertDto = {
        message: "File Uploaded",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'success'
        }
      }
      yield put(setAlert)
      yield put({
        type: LINK_ACTION_TYPES.UPLOAD_OTHER_FILE + COMMON_ACTION_TYPES.SUCCESS,
        data: uploadFileResponse.data
      })
    } catch (error) {
      const setAlert: AlertDto = {
        message: error as string,
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'error'
        }
      }
      yield put(setAlert)
      yield put({
        type: LINK_ACTION_TYPES.UPLOAD_OTHER_FILE + COMMON_ACTION_TYPES.ERROR,
        error: error
      })
    }
  }
  
  function* downloadRequiredFile(action: { type: string, payload: DownloadParamLinkDto}) {
    try {
      const fileDownloadResponse: AxiosResponse<FileResponseLinkDto> = yield call(linkService.downloadRequiredFile, action.payload)
        //Get the content type from the response headers or data
        
        const base64Data =fileDownloadResponse.data.base64
        const binaryString = atob(base64Data);
        const byteArray = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          byteArray[i] = binaryString.charCodeAt(i);
        }
  
        const blob = new Blob([byteArray], { type: fileDownloadResponse.data.contentType});
        const url = URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Document'; // Replace with desired file name and extension
        link.click();
    
        URL.revokeObjectURL(url); // Clean up the URL when done
      
      yield put({
        type: LINK_ACTION_TYPES.DOWNLOAD_REQUIRED_FILE + COMMON_ACTION_TYPES.SUCCESS,
        data: fileDownloadResponse.data,
      })
      const setAlert: AlertDto = {
        message: "File Downloaded",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'success'
        }
      }
      yield put(setAlert)
    } catch (error) {
      const setAlert: AlertDto = {
        message: error as string,
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'error'
        }
      }
      yield put(setAlert)
      yield put({
        type: LINK_ACTION_TYPES.DOWNLOAD_REQUIRED_FILE + COMMON_ACTION_TYPES.ERROR,
        error: error
       ,
      })
    }
  }
  
  function* downloadOpenFile(action: { type: string, payload: DownloadParamLinkDto}){
    try {
      const fileDownloadResponse: AxiosResponse<FileResponseLinkDto> = yield call(linkService.downloadRequiredFile, action.payload)
        
        const base64Data =fileDownloadResponse.data.base64
        const binaryString = atob(base64Data);
        const byteArray = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          byteArray[i] = binaryString.charCodeAt(i);
        }
  
        const blob = new Blob([byteArray], { type: fileDownloadResponse.data.contentType});
        const url = URL.createObjectURL(blob);
        // Set the target attribute to '_blank' to open in a new tab
        window.open(url, '_blank');
        URL.revokeObjectURL(url); 
      
      yield put({
        type: LINK_ACTION_TYPES.DOWNLOAD_OPEN_FILE + COMMON_ACTION_TYPES.SUCCESS,
        data: fileDownloadResponse.data,
      })
      const setAlert: AlertDto = {
        message: "File Opened",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'info'
        }
      }
      yield put(setAlert)
    } catch (error) {
      const setAlert: AlertDto = {
        message: error as string,
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'error'
        }
      }
      yield put(setAlert)
      yield put({
        type: LINK_ACTION_TYPES.DOWNLOAD_REQUIRED_FILE + COMMON_ACTION_TYPES.ERROR,
        error: error
       ,
      })
    }
  }

  function* removeUploadedFile(action: { type: string, payload: DownloadParamLinkDto}) {
    try {
    const removeResponse: AxiosResponse<string> = yield call(linkService.removeUploadedFile, action.payload)
      const setAlert: AlertDto = {
        message: "File Removed",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'success'
        }
      }
      yield put(setAlert)
      yield put({
        type: LINK_ACTION_TYPES.REMOVE_UPLOADED_FILE + COMMON_ACTION_TYPES.SUCCESS,
       data: removeResponse.data
      })
    } catch (error) {
      const setAlert: AlertDto = {
        message: error as string,
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'error'
        }
      }
      yield put(setAlert)
      yield put({
        type: LINK_ACTION_TYPES.REMOVE_UPLOADED_FILE + COMMON_ACTION_TYPES.ERROR,
        error: error
      })
    }
  }

  function* linkSaga() {
    yield takeEvery(LINK_ACTION_TYPES.UPLOAD_MAIN_FILE + COMMON_ACTION_TYPES.REQUEST, uploadMainFile)
    yield takeEvery(LINK_ACTION_TYPES.UPLOAD_SECONDARY_FILE + COMMON_ACTION_TYPES.REQUEST, uploadSecondaryFile)
    yield takeEvery(LINK_ACTION_TYPES.UPLOAD_OTHER_FILE + COMMON_ACTION_TYPES.REQUEST, uploadOtherFile)
    yield takeEvery(LINK_ACTION_TYPES.DOWNLOAD_REQUIRED_FILE + COMMON_ACTION_TYPES.REQUEST, downloadRequiredFile)
    yield takeEvery(LINK_ACTION_TYPES.DOWNLOAD_OPEN_FILE + COMMON_ACTION_TYPES.REQUEST, downloadOpenFile)
    yield takeEvery(LINK_ACTION_TYPES.REMOVE_UPLOADED_FILE + COMMON_ACTION_TYPES.REQUEST, removeUploadedFile)
  }
  
  export default linkSaga