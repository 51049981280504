import { COMMON_ACTION_TYPES, GPS_ACTION_TYPES } from "../../utilities/constants/action.constant"
import { RealTripPayloadDto } from "../../utilities/models/gps.model"

const postRealTripDetails = (payload: RealTripPayloadDto) => {
    return {
        type: GPS_ACTION_TYPES.POST_REAL_TRIP + COMMON_ACTION_TYPES.REQUEST,
        payload: payload
    }
}

const getActualTripDetails = (existingTripId:string) => {
    return {
        type: GPS_ACTION_TYPES.GET_CURRENT_TRIP_SITUATION + COMMON_ACTION_TYPES.REQUEST,
        existingTripId:existingTripId
    }
}

const resetActualTripDetails = (tripId:string) => {
    return{
        type: GPS_ACTION_TYPES.RESET_REAL_TRIP + COMMON_ACTION_TYPES.REQUEST,
        tripId: tripId
    }
}
export const gpsActions = {
    postRealTripDetails,
    getActualTripDetails,
    resetActualTripDetails
}