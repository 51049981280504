import {
  APP_ACTION_STATUS,
  COMMON_ACTION_TYPES,
  REQUEST_ACTION_TYPE,
} from "../../utilities/constants";
import { RequestStateDto } from "../../utilities/models";

const INITIAL_STATE: RequestStateDto = {
  addRequest: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  addReccurrentRequest: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  editRequest: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  deleteRequest: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  getRequest: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  getSelfRequestList: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getLMRequestList: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getLMRequestSummaryFilters:{
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getTMRequestList: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getTMRequestSummaryFilters:{
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getSbuList: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getPlantListBySbuList: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getDepartmentListByPlantId: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getRequestStatusProcess: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  lmApproval: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  tmApproval: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  getRequestHistory: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
};

const requestReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case REQUEST_ACTION_TYPE.POST_REQUEST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        addRequest: {
          ...state.addRequest,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.POST_REQUEST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        addRequest: {
          ...state.addRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case REQUEST_ACTION_TYPE.POST_REQUEST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        addRequest: {
          ...state.addRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.POST_REQUEST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        addRequest: {
          ...state.addRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.POST_RECCURRENT_REQUEST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        addReccurrentRequest: {
          ...state.addReccurrentRequest,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.POST_RECCURRENT_REQUEST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        addReccurrentRequest: {
          ...state.addReccurrentRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case REQUEST_ACTION_TYPE.POST_RECCURRENT_REQUEST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        addReccurrentRequest: {
          ...state.addReccurrentRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.POST_RECCURRENT_REQUEST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        addReccurrentRequest: {
          ...state.addReccurrentRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.EDIT_REQUEST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        editRequest: {
          ...state.editRequest,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.EDIT_REQUEST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        editRequest: {
          ...state.editRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case REQUEST_ACTION_TYPE.EDIT_REQUEST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        editRequest: {
          ...state.editRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.EDIT_REQUEST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        editRequest: {
          ...state.editRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.DELETE_REQUEST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        deleteRequest: {
          ...state.deleteRequest,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.DELETE_REQUEST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        deleteRequest: {
          ...state.deleteRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case REQUEST_ACTION_TYPE.DELETE_REQUEST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        deleteRequest: {
          ...state.deleteRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.DELETE_REQUEST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        deleteRequest: {
          ...state.deleteRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.GET_REQUEST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getRequest: {
          ...state.getRequest,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.GET_REQUEST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getRequest: {
          ...state.getRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case REQUEST_ACTION_TYPE.GET_REQUEST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getRequest: {
          ...state.getRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.GET_REQUEST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getRequest: {
          ...state.getRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.GET_SELF_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getSelfRequestList: {
          ...state.getSelfRequestList,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.GET_SELF_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getSelfRequestList: {
          ...state.getSelfRequestList,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case REQUEST_ACTION_TYPE.GET_SELF_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getSelfRequestList: {
          ...state.getSelfRequestList,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.GET_SELF_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getSelfRequestList: {
          ...state.getSelfRequestList,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.GET_LM_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getLMRequestList: {
          ...state.getLMRequestList,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case REQUEST_ACTION_TYPE.GET_LM_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getLMRequestList: {
          ...state.getLMRequestList,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case REQUEST_ACTION_TYPE.GET_LM_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getLMRequestList: {
          ...state.getLMRequestList,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case REQUEST_ACTION_TYPE.GET_LM_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getLMRequestList: {
          ...state.getLMRequestList,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.GET_LM_REQUEST_SUMMARY_FILTERS+ COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getLMRequestSummaryFilters: {
          ...state.getLMRequestSummaryFilters,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case REQUEST_ACTION_TYPE.GET_LM_REQUEST_SUMMARY_FILTERS + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getLMRequestSummaryFilters: {
          ...state.getLMRequestSummaryFilters,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case REQUEST_ACTION_TYPE.GET_LM_REQUEST_SUMMARY_FILTERS + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getLMRequestSummaryFilters: {
          ...state.getLMRequestSummaryFilters,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case REQUEST_ACTION_TYPE.GET_LM_REQUEST_SUMMARY_FILTERS + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getLMRequestSummaryFilters: {
          ...state.getLMRequestSummaryFilters,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };  
    case REQUEST_ACTION_TYPE.GET_TM_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getTMRequestList: {
          ...state.getTMRequestList,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case REQUEST_ACTION_TYPE.GET_TM_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getTMRequestList: {
          ...state.getTMRequestList,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case REQUEST_ACTION_TYPE.GET_TM_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getTMRequestList: {
          ...state.getTMRequestList,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case REQUEST_ACTION_TYPE.GET_TM_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getTMRequestList: {
          ...state.getTMRequestList,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.GET_TM_REQUEST_SUMMARY_FILTERS+ COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getTMRequestSummaryFilters: {
          ...state.getTMRequestSummaryFilters,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case REQUEST_ACTION_TYPE.GET_TM_REQUEST_SUMMARY_FILTERS + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getTMRequestSummaryFilters: {
          ...state.getTMRequestSummaryFilters,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case REQUEST_ACTION_TYPE.GET_TM_REQUEST_SUMMARY_FILTERS + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getTMRequestSummaryFilters: {
          ...state.getTMRequestSummaryFilters,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case REQUEST_ACTION_TYPE.GET_TM_REQUEST_SUMMARY_FILTERS + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getTMRequestSummaryFilters: {
          ...state.getTMRequestSummaryFilters,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };    
    case REQUEST_ACTION_TYPE.GET_SBU_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getSbuList: {
          ...state.getSbuList,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case REQUEST_ACTION_TYPE.GET_SBU_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getSbuList: {
          ...state.getSbuList,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case REQUEST_ACTION_TYPE.GET_SBU_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getSbuList: {
          ...state.getSbuList,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case REQUEST_ACTION_TYPE.GET_SBU_LIST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getSbuList: {
          ...state.getSbuList,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.GET_PLANT_LIST_BY_SBU_ID + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getPlantListBySbuList: {
          ...state.getPlantListBySbuList,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case REQUEST_ACTION_TYPE.GET_PLANT_LIST_BY_SBU_ID + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getPlantListBySbuList: {
          ...state.getPlantListBySbuList,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case REQUEST_ACTION_TYPE.GET_PLANT_LIST_BY_SBU_ID + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getPlantListBySbuList: {
          ...state.getPlantListBySbuList,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case REQUEST_ACTION_TYPE.GET_PLANT_LIST_BY_SBU_ID + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getPlantListBySbuList: {
          ...state.getPlantListBySbuList,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.GET_DEPARTMENT_LIST_BY_PLANT_ID + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getDepartmentListByPlantId: {
          ...state.getDepartmentListByPlantId,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case REQUEST_ACTION_TYPE.GET_DEPARTMENT_LIST_BY_PLANT_ID + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getDepartmentListByPlantId: {
          ...state.getDepartmentListByPlantId,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case REQUEST_ACTION_TYPE.GET_DEPARTMENT_LIST_BY_PLANT_ID + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getDepartmentListByPlantId: {
          ...state.getDepartmentListByPlantId,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case REQUEST_ACTION_TYPE.GET_DEPARTMENT_LIST_BY_PLANT_ID + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getDepartmentListByPlantId: {
          ...state.getDepartmentListByPlantId,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.GET_REQUEST_STATUS_PROCESS + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getRequestStatusProcess: {
          ...state.getRequestStatusProcess,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case REQUEST_ACTION_TYPE.GET_REQUEST_STATUS_PROCESS + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getRequestStatusProcess: {
          ...state.getRequestStatusProcess,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case REQUEST_ACTION_TYPE.GET_REQUEST_STATUS_PROCESS + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getRequestStatusProcess: {
          ...state.getRequestStatusProcess,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case REQUEST_ACTION_TYPE.GET_REQUEST_STATUS_PROCESS + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getRequestStatusProcess: {
          ...state.getRequestStatusProcess,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.LM_APPROVAL + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        lmApproval: {
          ...state.lmApproval,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.LM_APPROVAL + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        lmApproval: {
          ...state.lmApproval,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case REQUEST_ACTION_TYPE.LM_APPROVAL + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        lmApproval: {
          ...state.lmApproval,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.LM_APPROVAL + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        lmApproval: {
          ...state.lmApproval,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.TM_APPROVAL + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        tmApproval: {
          ...state.tmApproval,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.TM_APPROVAL + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        tmApproval: {
          ...state.tmApproval,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case REQUEST_ACTION_TYPE.TM_APPROVAL + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        tmApproval: {
          ...state.tmApproval,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case REQUEST_ACTION_TYPE.TM_APPROVAL + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        tmApproval: {
          ...state.tmApproval,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
      case REQUEST_ACTION_TYPE.GET_REQUEST_HISTORY + COMMON_ACTION_TYPES.REQUEST:
        return {
          ...state,
          getRequestHistory: {
            ...state.getRequestHistory,
            isLoading: true,
            status: APP_ACTION_STATUS.LOADING,
            error: null,
            data: null,
          },
        };
      case REQUEST_ACTION_TYPE.GET_REQUEST_HISTORY + COMMON_ACTION_TYPES.SUCCESS:
        return {
          ...state,
          getRequestHistory: {
            ...state.getRequestHistory,
            isLoading: false,
            status: APP_ACTION_STATUS.SUCCESS,
            error: null,
            data: action.data,
          },
        };
      case REQUEST_ACTION_TYPE.GET_REQUEST_HISTORY + COMMON_ACTION_TYPES.ERROR:
        return {
          ...state,
          getRequestHistory: {
            ...state.getRequestHistory,
            isLoading: false,
            status: APP_ACTION_STATUS.ERROR,
            error: action.error,
            data: null,
          },
        };
      case REQUEST_ACTION_TYPE.GET_REQUEST_HISTORY + COMMON_ACTION_TYPES.CLEAR:
        return {
          ...state,
          getRequestHistory: {
            ...state.getRequestHistory,
            isLoading: false,
            status: APP_ACTION_STATUS.INITIAL,
            error: null,
            data: null,
          },
        };
    default:
      return state;
  }
};

export default requestReducer;
