import React from "react";
import style from "./TmRequestApprovalGrid.module.scss";
import { ApplicationStateDto, SortMetaDto, TMRequestSummaryListDto, } from "../../../utilities/models";
import { Box, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography, } from "@mui/material";
import { StickyTableCell, StyledCheckBox, StyledStatusApproved, StyledStatusDraft, StyledStatusPending, StyledStatusRejected, StyledTableCell, } from "../../../assets/theme/theme";
import { APP_ROUTES, APP_TABLE_CONFIGS, NEW_REQUEST_TYPES, REQUEST_SCREEN_EXPRESS_MODES, REQUEST_SCREEN_MODES, REQUEST_STATUS, TM_APPROVAL_ACTION, USER_ROLES, } from "../../../utilities/constants";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import moment from "moment";
import { AppSkeleton, CustomButton, CustomHeaderCell } from "../../Shared";
import CommuteIcon from '@mui/icons-material/Commute';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const TmRequestApprovalGrid: React.FC<{
  page: number;
  rowsPerPage: number;
  isFiltered: boolean;
  onHandleChangePage(event: unknown, newPage: number): void;
  onHandleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void;
  requestDataIsLoading: boolean;
  filteredList: TMRequestSummaryListDto[];
  sortMeta: SortMetaDto;
  onActionButtonClick(action: string): void;
  onSortHandle(col: string): void;
  onFilterHandle(col: string, value: any): void;
  onSelectRequestIds(id: string, value: boolean): void;
  getFilterList: (col: string) => string[];
  navigateTo(mode: string, id: string): void;
  onClearFilter(): void;
  showSplitViewPopup(con: boolean): void;
  showRedirectViewPopup(con: boolean): void;
  viewAvailability(value: number, dateValue: string): void;
  filters:any[];
  reqType: string;
}> = (props) => {
  const authorizedUser = useSelector((state: ApplicationStateDto) => state.authUser.authorizedUser);
  const navigate = useNavigate();

  const _recurrentParentId = props.filters[0]?.recurrentParentId  || [];
  const _parentRequestId = props.filters[0]?.parentRequestId || [];
  const _reqFilters = props.filters[0]?.requestType || [];
  const _createdBy = props.filters[0]?.createdBy || [];
  const _deptName = props.filters[0]?.departmentName || [];
  const _fromLocation = props.filters[0]?.fromLocation || [];
  const _package = ["Yes", "No"] || [];
  const _passengerCount = props.filters[0]?.passengerCount || [];
  const _performedBy = props.filters[0]?.performedBy || [];
  const _plantName = props.filters[0]?.plantName || [];
  const _prefVehicle = props.filters[0]?.preferredVehicle || [];
  const _reqOnBehalfof = props.filters[0]?.requestOnBehalfOf || [];
  const _sbuName = props.filters[0]?.sbuName || [];
  const _status = props.filters[0]?.status || [];
  const _toLocation = props.filters[0]?.toLocation || [];
  const _travelMode = props.filters[0]?.travelMode || [];
  const _userName = props.filters[0]?.userName || [];

  return (
    <section className={style.gridContainer}>
      <div className={style.gridHeader}>
        <Typography noWrap component="div" className={style.gridTitle}>
          <h3>{authorizedUser.data.roleId === USER_ROLES.TRANSPORT_MANAGER ? "PTM " :
            authorizedUser.data.roleId === USER_ROLES.SBU_MANAGER ? "SBU " :
              authorizedUser.data.roleId === USER_ROLES.SUPER_ADMIN ? "OSA " :
                authorizedUser.data.roleId === USER_ROLES.DIVISION_USER ? "TDU " : ""
          }Request Approval</h3>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <div className="layout-row">
          {props.isFiltered && (
            <CustomButton
              text="Clear filter"
              textColor="black"
              bgColor="#bfbfbf"
              onClick={props.onClearFilter}
            />
          )}
          <CustomButton
            text="Reject"
            border="1px solid #6e6e6e"
            bgColor="#282828"
            onClick={() => props.onActionButtonClick(TM_APPROVAL_ACTION.REJECT)}
          />
          <CustomButton
            text="Split"
            border="1px solid #6e6e6e"
            bgColor="#282828"
            disabled={props.reqType === NEW_REQUEST_TYPES[3].name}
            onClick={() => props.onActionButtonClick(TM_APPROVAL_ACTION.SPLIT)}
          />
          <CustomButton
            text="Redirect"
            border="1px solid #6e6e6e"
            bgColor="#282828"
            onClick={() => {
              props.onActionButtonClick(TM_APPROVAL_ACTION.REDIRECT);
            }}
          />
          <CustomButton
            text="Approve"
            onClick={() => props.onActionButtonClick(TM_APPROVAL_ACTION.APPROVE)}
          />
          <CustomButton text="Approve & Allocate" onClick={() => props.onActionButtonClick(TM_APPROVAL_ACTION.APPROVE_ALLOCATE)}
            disabled={props.filteredList.filter((r) => r.isSelect).length > 2} />
        </div>
      </div>

      <TableContainer component={Paper} className={style.grid}>
        <Table className={style.table}>
          <TableHead>
            <TableRow>
              <StickyTableCell width={60}>
                <StyledCheckBox
                  size="small"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.onSelectRequestIds("ALL", event.target.checked)
                  }
                />
              </StickyTableCell>
              <CustomHeaderCell width={180} id='recurrentParentId' sortable onSort={props.onSortHandle} filtered filterStringList={_recurrentParentId} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Parent ID</CustomHeaderCell>
              <CustomHeaderCell width={180} id='requestId' sortable onSort={props.onSortHandle} >Request ID</CustomHeaderCell>
              <CustomHeaderCell width={180} id='requestType' sortable onSort={props.onSortHandle} filtered filterStringList={_reqFilters} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Request Type</CustomHeaderCell>
              <CustomHeaderCell width={180} id='redirected' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Redirected</CustomHeaderCell>
              <CustomHeaderCell width={300} id='userName' sortable onSort={props.onSortHandle} filtered filterStringList={_userName} getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >User Name</CustomHeaderCell>
              <CustomHeaderCell width={220} id='sbuName' sortable onSort={props.onSortHandle} filtered filterStringList={_sbuName} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>SBU</CustomHeaderCell>
              <CustomHeaderCell width={220} id='plantName' sortable onSort={props.onSortHandle} filtered filterStringList={_plantName} getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Plant</CustomHeaderCell>
              <CustomHeaderCell width={250} id='departmentName' sortable onSort={props.onSortHandle} filtered filterStringList={_deptName} getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Department</CustomHeaderCell>
              <CustomHeaderCell width={150} id='travelMode' sortable onSort={props.onSortHandle} filtered filterStringList={_travelMode} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Travel Mode</CustomHeaderCell>
              <CustomHeaderCell width={200} id='fromLocation' sortable onSort={props.onSortHandle} filtered filterStringList={_fromLocation} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Travel From</CustomHeaderCell>
              <CustomHeaderCell width={300} id='toLocation' sortable onSort={props.onSortHandle} filtered filterStringList={_toLocation} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Travel To (Main Destination)</CustomHeaderCell>
              <CustomHeaderCell width={270} id='departureDateandTime' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Departure date & time</CustomHeaderCell>
              <CustomHeaderCell width={250} id='returnDateandTime' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Return date & time</CustomHeaderCell>
              <CustomHeaderCell width={200} id='projectedCost' sortable onSort={props.onSortHandle} >Projected Cost (LKR)</CustomHeaderCell>
              <CustomHeaderCell width={180} id='passengerCount' filtered filterStringList={_passengerCount} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Passenger count</CustomHeaderCell>
              <CustomHeaderCell width={240} id='preferredVehicle' sortable onSort={props.onSortHandle} filtered filterStringList={_prefVehicle} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Preferred Vehicle</CustomHeaderCell>
              <CustomHeaderCell width={150} id='packageX' sortable onSort={props.onSortHandle} filtered filterStringList={_package} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Package</CustomHeaderCell>
              <CustomHeaderCell width={200} id='cbm' sortable onSort={props.onSortHandle} >Total CBM of package</CustomHeaderCell>
              <CustomHeaderCell width={300} id='purpose' >Purpose</CustomHeaderCell>
              <CustomHeaderCell width={180} id='status' sortable onSort={props.onSortHandle} filtered filterStringList={_status} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Status</CustomHeaderCell>
              <CustomHeaderCell width={240} id='createdDateandTime' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Create date and time</CustomHeaderCell>
              <CustomHeaderCell width={180} id='createdBy' sortable onSort={props.onSortHandle} filtered filterStringList={_createdBy} getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Created by</CustomHeaderCell>
              <CustomHeaderCell width={100} id='actions' >Actions</CustomHeaderCell>
            </TableRow>
          </TableHead>
          {props.requestDataIsLoading && (
            <AppSkeleton
              numOfRows={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE}
              numOfColumns={20}
              columnWidth={80}
              height={35}
              stickyFirst
              tag="table"
            />
          )}
          {!props.requestDataIsLoading && props.filteredList.length > 0 && (
            <TableBody>
              {props.filteredList.map((req: TMRequestSummaryListDto) => (
                <TableRow key={req.requestId}>
                  <StickyTableCell>
                    <StyledCheckBox
                      size="small"
                      checked={req.isSelect}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        props.onSelectRequestIds(
                          req.requestId,
                          event.target.checked
                        )
                      }
                    />
                  </StickyTableCell>
                  <StyledTableCell >{!req.recurrentParentId ? 'N/A' : 'R' + req.recurrentParentId}</StyledTableCell>
                  <StyledTableCell> {req.requestType === "Express AdHoc"
                    ? "E" + req.requestId.toString()
                    : "R" + req.requestId.toString()
                  }</StyledTableCell>
                  <StyledTableCell>{req.requestType}</StyledTableCell>
                  <StyledTableCell>{req.redirected}</StyledTableCell>
                  <StyledTableCell>{req.userName}</StyledTableCell>
                  <StyledTableCell>{req.sbuName ?? "-"}</StyledTableCell>
                  <StyledTableCell>{req.plantName ?? "-"}</StyledTableCell>
                  <StyledTableCell>{req.departmentName ?? "-"}</StyledTableCell>
                  <StyledTableCell>{req.travelMode}</StyledTableCell>
                  <StyledTableCell>{req.from}</StyledTableCell>
                  <StyledTableCell>{req.to}</StyledTableCell>
                  <StyledTableCell>{moment(req.departureDateandTime).format(APP_TABLE_CONFIGS.DATE_FORMAT)}</StyledTableCell>
                  <StyledTableCell>{moment(req.returnDateandTime).format(APP_TABLE_CONFIGS.DATE_FORMAT)}</StyledTableCell>
                  <StyledTableCell>{req.projectedCost}</StyledTableCell>
                  <StyledTableCell>{req.passengerCount}</StyledTableCell>
                  <StyledTableCell>{req.preferredVehicle}</StyledTableCell>
                  <StyledTableCell>{req.package ? "Yes" : "No"}</StyledTableCell>
                  <StyledTableCell>{req.cbm}</StyledTableCell>
                  <StyledTableCell>{req.purpose}</StyledTableCell>
                  <StyledTableCell>
                    {req.status === REQUEST_STATUS.APPROVED_LM ||
                      req.status === REQUEST_STATUS.APPROVED_TM ||
                      req.status === REQUEST_STATUS.ALLOCATED_ME ||
                      req.status === REQUEST_STATUS.ALLOCATED_TM ||
                      req.status === REQUEST_STATUS.APPROVED_ME ? (
                      <StyledStatusApproved onClick={() => { }}>
                        {req.status}
                      </StyledStatusApproved>
                    ) : req.status === REQUEST_STATUS.ALLOCATION_CANCELLED_TM ||
                      req.status === REQUEST_STATUS.ALLOCATION_CANCELLED_ME ||
                      req.status === REQUEST_STATUS.REDIRECTION_REJECTED_TM ||
                      req.status === REQUEST_STATUS.REJECTED_LM ||
                      req.status === REQUEST_STATUS.REJECTED_TM ||
                      req.status === REQUEST_STATUS.REJECTED_ME ? (
                      <StyledStatusRejected onClick={() => { }}>
                        {req.status}
                      </StyledStatusRejected>
                    ) : req.status === REQUEST_STATUS.PENDING ||
                      req.status === REQUEST_STATUS.REDIRECTED_ME ||
                      req.status === REQUEST_STATUS.REDIRECTED_TM ||
                      req.status === REQUEST_STATUS.ALLOCATION_EDITED_TM ||
                      req.status === REQUEST_STATUS.ALLOCATION_EDITED_ME ? (
                      <StyledStatusPending>{req.status}</StyledStatusPending>
                    ) : (
                      <StyledStatusDraft>{req.status}</StyledStatusDraft>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{moment(req.createdDateandTime).add(330, 'minute').format(APP_TABLE_CONFIGS.DATE_FORMAT)}</StyledTableCell>
                  <StyledTableCell>{req.createdBy}</StyledTableCell>
                  <StyledTableCell style={{ backgroundColor: "#282828" }}>
                    <Box className="layout-row">
                      <Box>
                        <IconButton
                          size="small"
                          onClick={() => {

                            if (req.requestType === "Express AdHoc") {
                              sessionStorage.setItem("Mode", REQUEST_SCREEN_EXPRESS_MODES.TM_APPROVAL_VIEW);
                              sessionStorage.setItem("id", req.requestId.toString());
                              navigate(APP_ROUTES.MANAGE_REQUEST_EXPRESS);
                            } else {
                              props.navigateTo(REQUEST_SCREEN_MODES.TM_APPROVAL_VIEW, req.requestId)
                            }
                          }
                          }
                        >
                          <Tooltip title="View">
                            <RemoveRedEyeOutlinedIcon
                              sx={{
                                fontSize: "20px",
                                mr: "-1",
                                color: "white",
                              }}
                            />


                          </Tooltip>
                        </IconButton>
                      </Box>
                      <IconButton
                        size="small"
                        onClick={() => { props.viewAvailability(req.passengerCount, req.departureDateandTime) }}
                      >
                        <Tooltip title="View Availability">
                          <CommuteIcon
                            sx={{
                              fontSize: "20px",
                              mr: "-1",
                              color: "white",
                            }}
                          />
                        </Tooltip>
                      </IconButton>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
          {!props.requestDataIsLoading && props.filteredList.length === 0 && (
            <TableBody>
              <TableRow>
                <StyledTableCell align="left" colSpan={19}>
                  No data to preview
                </StyledTableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_OPTIONS}
        component="div"
        labelRowsPerPage={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              color: "white",
            }}
          >
            Items per page
          </div>
        }
        count={-1}
        page={props.page}
        onPageChange={props.onHandleChangePage}
        onRowsPerPageChange={props.onHandleChangeRowsPerPage}
        rowsPerPage={props.rowsPerPage}
        nextIconButtonProps={{
          disabled:
            -1 < props.filteredList.length &&
            props.filteredList.length < props.rowsPerPage,
        }}
        sx={{ backgroundColor: "#282828", color: "white" }}
      />
    </section>
  );
};

export default TmRequestApprovalGrid;
