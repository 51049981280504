import React, { useEffect, useState } from 'react'
import { AppLayout } from '../../templates'
import { ApplicationStateDto, CompanyAddressFormDto, CompanyDetailsFormDto, GetBankListDto, GetBranchListDto, GetPaymentMethodsDto, OnBehalfOfSomeoneFormDto, OptionsDto, NewPaymentDetailsDTo, PaymentDetailsFormDTo, CompanyRegistrationFormDto, ConfirmationOfAccountInformationFormDto, OtherDocumentFormDto, AlertDto, newDocumentDetailsDto, DownloadParamLinkDto, FileUploadLinkDto, RegisterTransportCompanyDto, paymentDetailsDto, companyDocuments, GetCompanyDetailsById, UpdateTransportCompany, vehicleCostFormDto, GetVehiclePriceListDto, VehicleTypesDto, PostVehiclePriceListDto } from '../../utilities/models';
import CompanyDetails from '../../components/ManageTransportCompanyScreen/CompanyDetails/CompanyDetails';
import style from './ManageTransportCompanyScreen.module.scss'
import { Grid, Typography } from '@mui/material';
import Address from '../../components/ManageTransportCompanyScreen/Address/Address';
import PaymentDetails from '../../components/ManageTransportCompanyScreen/PaymentDetails/PaymentDetails';
import DocumentInformation from '../../components/ManageTransportCompanyScreen/DocumentInformation/DocumentInformation';
import { ALERT_ACTION_TYPES, APP_ACTION_STATUS, APP_ROUTES, COMMON_ACTION_TYPES, CurrencyTypes, DOCUMENT_TYPE, TRANSPORT_COMPANY_DOCUMENT, TRANSPORT_COMPANY_DOCUMENT_NAME, TRANSPORT_COMPANY_MODE, VEHICLEDOCUMENT, sriLankanDistricts } from '../../utilities/constants';
import { useDispatch, useSelector } from 'react-redux';
import { transportActions } from '../../redux/actions/transport.action';
import { useNavigate } from 'react-router-dom';
import { validateFormData } from '../../utilities/helpers';
import { alertActions, linkActions, vehicleActions } from '../../redux/actions';
import dayjs, { Dayjs } from 'dayjs';
import { ConfirmationDialog, CustomButton } from '../../components';
import { max } from 'moment';
import VehicleCostDetails from '../../components/ManageTransportCompanyScreen/VehicleCostDetails/vehicleCostDetails';
import { version } from 'os';

const ManageTransportCompanyScreen = () => {

  const COMPANY_DETAILS_FORM_INITIAL_STATE: CompanyDetailsFormDto = {
    companyName: { value: "", isRequired: true, disable: false, readonly: false, validator: "text", error: "", max: 30 },
    attachedSbu: { value: "", isRequired: true, disable: false, readonly: true, validator: "text", error: "", },
    attachedSbuID: { value: "", isRequired: false, disable: false, readonly: true, validator: "text", error: "", },
    email: { value: "", isRequired: true, disable: false, readonly: false, validator: "email", error: "", max: 30 },
    phoneNumber: { value: "", isRequired: true, disable: false, readonly: false, validator: "number", error: "", charLength: [10], },
    CompanyId: { value: "", isRequired: false, disable: false, readonly: false, validator: null, error: "", charLength: [10], },
    rnVersion:{ value: 0, isRequired: false, disable: false, readonly: false, validator: 'number', error: '' },
  };

  const COMPANY_ADDRESS_FORM_INITIAL_STATE: CompanyAddressFormDto = {
    AddressLine1: { value: "", isRequired: true, disable: false, readonly: false, validator: "text", error: "", },
    AddressLine2: { value: "", isRequired: true, disable: false, readonly: false, validator: "text", error: "", },
    city: { value: "", isRequired: true, disable: false, readonly: false, validator: "text", error: "", },
    district: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    province: { value: "", isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    postalCode: { value: "", isRequired: true, disable: false, readonly: false, validator: "text", error: "", },
  };
  
  const COMPANY_PAYMENT_DETAILS_FORM_INITIAL_STATE: PaymentDetailsFormDTo = {
    currencyType: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    paymentMethod: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    bank: { value: {} as OptionsDto, isRequired: false, disable: true, readonly: false, validator: "object", error: "", },
    bankCode: { value: "", isRequired: false, disable: true, readonly: false, validator: 'text', error: "", },
    Branch: { value: {} as OptionsDto, isRequired: false, disable: true, readonly: false, validator: "object", error: "", },
    branchCode: { value: "", isRequired: false, disable: true, readonly: false, validator: 'text', error: "", },
    beneficiaryName: { value: "", isRequired: true, disable: false, readonly: false, validator: 'text', error: "", max: 30 },
    AccountNumber: { value: "", isRequired: false, disable: true, readonly: false, validator: 'text', error: "", max: 30 },
  };

  const COMPANY_REGISTRATION_FORM_INITIAL_STATE: CompanyRegistrationFormDto = {
    companyRegisterNumber: { value: "", isRequired: true, disable: false, readonly: false, validator: 'text', error: "", max: 15 },
    registeredDate: { value: null, isRequired: true, disable: false, readonly: false, validator: "date", error: "", },
    companyRegisterFile: { value: null, isRequired: false, disable: true, readonly: false, validator: null, error: "", },
    companyRegisteredFileUploadUrl: { value: "", isRequired: false, disable: true, readonly: false, validator: "text", error: "", },
    rnVersion:{ value: 0, isRequired: false, disable: false, readonly: false, validator: 'number', error: '' },
  }

  const COA_INFORMATION_FORM_INITIAL_STATE: ConfirmationOfAccountInformationFormDto = {
    AccountNumber: { value: "", isRequired: true, disable: false, readonly: false, validator: 'text', error: "", max: 16 },
    issuedDate: { value: null, isRequired: true, disable: false, readonly: false, validator: "date", error: "", },
    ConfirmationAccountFile: { value: null, isRequired: false, disable: false, readonly: false, validator: null, error: "", },
    COAInformationFileUploadUrl: { value: "", isRequired: false, disable: true, readonly: false, validator: "text", error: "", },
    bdVersion : { value: 0, isRequired: false, disable: false, readonly: false, validator: 'number', error: '' },
  }

  const OTHER_DOCUMENT_FORM_INITIAL_STATE: OtherDocumentFormDto = {
    documentCategory: { value: "", isRequired: true, disable: false, readonly: false, validator: 'text', error: "", max: 16 },
    OtherDocumentFileUploadUrl: { value: "", isRequired: false, disable: false, readonly: false, validator: 'text', error: "", },
    issuedDateOtherDoc: { value: null, isRequired: false, disable: false, readonly: false, validator: "date", error: "", },
    expiryDateOtherDoc: { value: null, isRequired: false, disable: false, readonly: false, validator: "date", error: "", },
    OtherDocumentFile: { value: null, isRequired: true, disable: false, readonly: false, validator: null, error: "", },
    version:{ value: 0, isRequired: false, disable: false, readonly: false, validator: 'number', error: '' },
  }

  const VEHICLE_COST_INISTIAL_STATE: vehicleCostFormDto = {
    vehicleType: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: 'object', error: '' },
    priceKm: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '', },
  }

  const GetPaymenMethodsResponse = useSelector((state: ApplicationStateDto) => state.transport.GetPaymentMethods)
  const GetBankListResponse = useSelector((state: ApplicationStateDto) => state.transport.getBankList)
  const GetBranchResponse = useSelector((state: ApplicationStateDto) => state.transport.getBranchList)
  const RegisterTransportCompany = useSelector((state: ApplicationStateDto) => state.transport.RegisterTransportCompany)
  const RegistrationFIleResponse = useSelector((state: ApplicationStateDto) => state.link.uploadMainFile)
  const BankFilefileRespose = useSelector((state: ApplicationStateDto) => state.link.uploadSecondaryFile)
  const OtherDockResponse = useSelector((state: ApplicationStateDto) => state.link.uploadOtherFile)
  const authuser = useSelector((state: ApplicationStateDto) => state.authUser.authorizedUser);
  const getTransportCompanyByIDResponse = useSelector((state: ApplicationStateDto) => state.transport.getTransportCompanyByID)
  const UpdateByIDResponse = useSelector((state: ApplicationStateDto) => state.transport.UpdateTransportCompanyById)
  const vehicleTypesResponse = useSelector((state: ApplicationStateDto) => state.vehicle.getVehicleTypes);
  
  const [companyDetailsForm, setCompanyDetailsForm] = useState<CompanyDetailsFormDto>(COMPANY_DETAILS_FORM_INITIAL_STATE)
  const [addressInformationForm, setaddressInformationForm] = useState<CompanyAddressFormDto>(COMPANY_ADDRESS_FORM_INITIAL_STATE)
  const [paymentDetailsForm, setpaymentDetailsForm] = useState<PaymentDetailsFormDTo>(COMPANY_PAYMENT_DETAILS_FORM_INITIAL_STATE)
  const [CompanyRegistrationForm, setCompanyRegistrationForm] = useState<CompanyRegistrationFormDto>(COMPANY_REGISTRATION_FORM_INITIAL_STATE)
  const [COAIformationForm, setCOAIformationForm] = useState<ConfirmationOfAccountInformationFormDto>(COA_INFORMATION_FORM_INITIAL_STATE)
  const [otherdocumentForm, setotherdocumentForm] = useState<OtherDocumentFormDto>(OTHER_DOCUMENT_FORM_INITIAL_STATE)
  const [vehicleCostForm, setVehcicleCostForm] = useState<vehicleCostFormDto>(VEHICLE_COST_INISTIAL_STATE)
 
  const [screenMode, setScreenMode] = useState("");
  const [paymentmethods, setpaymentmethods] = useState<GetPaymentMethodsDto[]>([])
  const [paymentDetailsList, setpaymentDetailsList] = useState<NewPaymentDetailsDTo[]>([])
  const [DocumentDetails, setDocumentDetails] = useState<newDocumentDetailsDto[]>([])
  const [isOpenDocumentRemovePopup, setIsOpenDocumentRemovePopup] = useState(false)
  const [isOpenpaymetDetailsRemovePopup, setIsOpenPaymentDetailsRemovePopup] = useState(false)
  const [isOpenRemoveVehicle, setIsOpenRemoveVehicle] = useState(false)
  const [bankList, setbankList] = useState<GetBankListDto[]>([])
  const [initialbankList, setinitialbankList] = useState<GetBankListDto[]>([])
  const [branchList, setbranchList] = useState<GetBranchListDto[]>([])
  const [InitialbranchList, setInitialbranchList] = useState<GetBranchListDto[]>([])
  const [vehiclePriceList, setVehiclePriceList] = useState<GetVehiclePriceListDto[]>([])

  const [isEditVehicle, setIsEditVehicle] = useState(false);
  
  const [selectedDoc, setSelectedDoc] = useState(-1)
  const [selectedPaymet, setselectedPaymet] = useState(-1)
  const [selectedVehicle, setSelectedVehicle] = useState(-1)
  const [helperText, setHelperText] = useState(true)
  const [inputValue, setInputValue] = useState("");
  const [initialBankValues, setinitialBankValues] = useState(true);
  const [initialBranchValues, setinitialBranchValues] = useState(true);
  const [fileUplordTypeNumber, setfileUplordTypeNumber] = useState(0)
  const [ispaymetDetailsEdite, setispaymetDetailsEdite] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const _mode = sessionStorage.getItem("Mode");
    const _id = sessionStorage.getItem("id");
    getInitialData()
    if (_mode !== null) setScreenMode(_mode);
    if ((_mode === TRANSPORT_COMPANY_MODE.VIEW || _mode === TRANSPORT_COMPANY_MODE.EDIT) && _id !== null) {
      dispatch(transportActions.getTransportCompanyById(_id))
    }

    return () => {
      dispatch(linkActions.uploadMainFileClear())
      dispatch(linkActions.uploadOtherFileClear()) 
      dispatch(linkActions.uploadSecondaryFileClear())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setGeneralAlert: AlertDto = {
    message: "Mandatory fields not filled!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const setPaymentAlert:AlertDto = {
    message: "Atleast one payment method should be added!!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const setPaymentActiveAlert:AlertDto = {
    message: "Atleast one payment method should be activated!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const setFileAlert: AlertDto = {
    message: "Document is mandatory for Other Documents!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const alreadyExistsAlert: AlertDto = {
    message: "Document already exists!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: 'error'
    }
  }

  useEffect(() => {
    const _mode = sessionStorage.getItem("Mode");
    if (_mode === TRANSPORT_COMPANY_MODE.VIEW || _mode === TRANSPORT_COMPANY_MODE.EDIT) {
      if (UpdateByIDResponse.status === APP_ACTION_STATUS.SUCCESS) {
        if (UpdateByIDResponse.data === "Already existing") {
          const setAlert: AlertDto = {
            message: UpdateByIDResponse.data,
            type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
            options: {
              key: new Date().getTime() + Math.random(),
              varient: 'error'
            }
          }
          dispatch(alertActions.triggerAlert(setAlert));
        } else {
          const setAlert: AlertDto = {
            message: UpdateByIDResponse.data,
            type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
            options: {
              key: new Date().getTime() + Math.random(),
              varient: 'success'
            }
          }
          dispatch(alertActions.triggerAlert(setAlert))
          sessionStorage.setItem("Mode", "")
          navigate(APP_ROUTES.MANAGE_TRANSPORT_COMPANY)
        }
      }
    }
  }, [UpdateByIDResponse.status])

  useEffect(() => {
    const _mode = sessionStorage.getItem("Mode");
    if (_mode !== TRANSPORT_COMPANY_MODE.VIEW && _mode !== TRANSPORT_COMPANY_MODE.EDIT) {
      setCompanyDetailsForm({
        ...companyDetailsForm,
        attachedSbu: {
          ...companyDetailsForm.attachedSbu,
          value: authuser.data.hierarchy[0].name
        },
        attachedSbuID: {
          ...companyDetailsForm.attachedSbuID,
          value: authuser.data.hierarchy[0].id.toString()
        }

      })
    }
  }, [])


  useEffect(() => {
    const _mode = sessionStorage.getItem("Mode");
    if (getTransportCompanyByIDResponse.status === APP_ACTION_STATUS.SUCCESS && GetBankListResponse.status === APP_ACTION_STATUS.SUCCESS && GetPaymenMethodsResponse.status === APP_ACTION_STATUS.SUCCESS && GetPaymenMethodsResponse.data.length > 0 && getTransportCompanyByIDResponse.data && GetBranchResponse.status === APP_ACTION_STATUS.SUCCESS && GetBankListResponse.data.length > 0 && GetBranchResponse.data.length > 0 && _mode !== TRANSPORT_COMPANY_MODE.CREATE) {
      const _isDisable = _mode === TRANSPORT_COMPANY_MODE.VIEW;

      const _data: GetCompanyDetailsById = getTransportCompanyByIDResponse.data;
      setCompanyDetailsForm({
        ...companyDetailsForm,
        attachedSbu: {
          ...companyDetailsForm.attachedSbu,
          value: _data.sbu,
          // readonly:_isDisable,
        },
        companyName: {
          ...companyDetailsForm.companyName,
          value: _data.transCompany,
          readonly: _isDisable,
        },
        attachedSbuID: {
          ...companyDetailsForm.attachedSbuID,
          value: _data.sbuId.toString(),
          readonly: _isDisable,

        },
        email: {
          ...companyDetailsForm.email,
          value: _data.email,
          readonly: _isDisable,
        },
        phoneNumber: {
          ...companyDetailsForm.phoneNumber,
          value: _data.telephoneNumber,
          readonly: _isDisable,
        },
        CompanyId: {
          ...companyDetailsForm.CompanyId,
          value: _data.companyId.toString(),
          readonly: _isDisable,
          isRequired: true,
        },
        rnVersion:{
          ...companyDetailsForm.rnVersion,
          value:_data.rnVersion,
        }

      })
      const district = sriLankanDistricts.filter(item => item.name === _data.district)
      setaddressInformationForm({
        ...addressInformationForm,
        AddressLine1: {
          ...addressInformationForm.AddressLine1,
          value: _data.addressLine1,
          readonly: _isDisable,
        },
        AddressLine2: {
          ...addressInformationForm.AddressLine2,
          value: _data.addressLine2,
          readonly: _isDisable,
        },
        city: {
          ...addressInformationForm.city,
          value: _data.city,
          readonly: _isDisable,
        },
        district: {
          ...addressInformationForm.district,
          value: { label: district[0]?.name, value: district[0]?.id } as OptionsDto,
          readonly: _isDisable,
        },
        postalCode: {
          ...addressInformationForm.postalCode,
          value: _data.postalCode,
          readonly: _isDisable,
        },
        province: {
          ...addressInformationForm.province,
          value: _data.province,
          readonly: _isDisable,
        },

      })
      setCompanyRegistrationForm({
        ...COMPANY_REGISTRATION_FORM_INITIAL_STATE,
        registeredDate: {
          ...COMPANY_REGISTRATION_FORM_INITIAL_STATE.registeredDate,
          readonly: _isDisable,
          disable: _isDisable,

        },
        companyRegisteredFileUploadUrl: {
          ...COMPANY_REGISTRATION_FORM_INITIAL_STATE.companyRegisteredFileUploadUrl,
          readonly: _isDisable,
          disable: _isDisable,

        },
        companyRegisterFile: {
          ...COMPANY_REGISTRATION_FORM_INITIAL_STATE.companyRegisterFile,
          readonly: _isDisable,
          disable: _isDisable,

        },
        companyRegisterNumber: {
          ...COMPANY_REGISTRATION_FORM_INITIAL_STATE.companyRegisterNumber,
          readonly: _isDisable,
          disable: _isDisable,

        },
        rnVersion:{
          ...COMPANY_REGISTRATION_FORM_INITIAL_STATE.rnVersion,
        }
      })
      setCOAIformationForm({
        ...COA_INFORMATION_FORM_INITIAL_STATE,
        AccountNumber: {
          ...COA_INFORMATION_FORM_INITIAL_STATE.AccountNumber,
          readonly: _isDisable,
          disable: _isDisable,
        },
        COAInformationFileUploadUrl: {
          ...COA_INFORMATION_FORM_INITIAL_STATE.COAInformationFileUploadUrl,
          readonly: _isDisable,
          disable: _isDisable,
        },
        ConfirmationAccountFile: {
          ...COA_INFORMATION_FORM_INITIAL_STATE.ConfirmationAccountFile,
          readonly: _isDisable,
          disable: _isDisable,
        },
        issuedDate: {
          ...COA_INFORMATION_FORM_INITIAL_STATE.issuedDate,
          readonly: _isDisable,
          disable: _isDisable,
        },
        bdVersion:{
          ...COA_INFORMATION_FORM_INITIAL_STATE.bdVersion,
          value:COA_INFORMATION_FORM_INITIAL_STATE.bdVersion.value
        }
      })
      setotherdocumentForm({
        ...OTHER_DOCUMENT_FORM_INITIAL_STATE,
        documentCategory: {
          ...OTHER_DOCUMENT_FORM_INITIAL_STATE.documentCategory,
          readonly: _isDisable,
          disable: _isDisable,

        },
        expiryDateOtherDoc: {
          ...OTHER_DOCUMENT_FORM_INITIAL_STATE.expiryDateOtherDoc,
          readonly: _isDisable,
          disable: _isDisable,

        },
        issuedDateOtherDoc: {
          ...OTHER_DOCUMENT_FORM_INITIAL_STATE.issuedDateOtherDoc,
          readonly: _isDisable,
          disable: _isDisable,

        },
        OtherDocumentFile: {
          ...OTHER_DOCUMENT_FORM_INITIAL_STATE.OtherDocumentFile,
          readonly: _isDisable,
          disable: _isDisable,

        },
        OtherDocumentFileUploadUrl: {
          ...OTHER_DOCUMENT_FORM_INITIAL_STATE.OtherDocumentFileUploadUrl,
          readonly: _isDisable,
          disable: _isDisable,

        },
      })

      setpaymentDetailsForm({
        ...paymentDetailsForm,
        currencyType: {
          ...paymentDetailsForm.currencyType,
          readonly: _isDisable,
          disable: _isDisable,
        },
        paymentMethod: {
          ...paymentDetailsForm.paymentMethod,
          readonly: _isDisable,
          disable: _isDisable,
        },
        beneficiaryName: {
          ...paymentDetailsForm.beneficiaryName,
          readonly: _isDisable,
          disable: _isDisable,
        }
      })

      setVehcicleCostForm({
        ...vehicleCostForm,
        vehicleType: {
          ...vehicleCostForm.vehicleType,
          readonly: _isDisable,
        },
        priceKm: {
          ...vehicleCostForm.priceKm,
          readonly: _isDisable,
        },
      })

      const paymentList = _data.paymentMethodDetails.map((item) => {

        let paymentMethod = paymentmethods.filter(p => p.id === item.paymentMethod)
        let currency = CurrencyTypes.filter(p => p.name === item.currency)
        let bankLists = initialbankList.filter(p => p.id === item.bank)
        let barnch = InitialbranchList.filter(p => p.id === item.branch)

        const data: NewPaymentDetailsDTo = {
          paymentMethod: { label: paymentMethod[0]?.method, value: paymentMethod[0]?.id } as OptionsDto,
          currencyType: { label: currency[0]?.name, value: currency[0]?.id } as OptionsDto,
          bank: { value: bankLists[0]?.id, label: bankLists[0]?.bankName } as OptionsDto,
          bankCode: bankLists[0]?.code,
          Branch: { value: barnch[0]?.id, label: barnch[0]?.name } as OptionsDto,
          branchCode: barnch[0]?.code,
          beneficiaryName: item.beneficiaryName,
          AccountNumber: item.accountNumber,
          isActive: item.isActive,
          bdVersion:item.bdVersion
        }

        return data
      }
      )
      setpaymentDetailsList(paymentList)

      setVehiclePriceList(_data.compVehicleTypes.map((v, index) => {
        const _vehicle: GetVehiclePriceListDto = {
          id: index + 1,
          vehicleType: v.type,
          vehicleTypeId: v.vehicleTypeId,
          cost: v.cost.toString()
        }

        return _vehicle
      }))

      const otherDocument: newDocumentDetailsDto[] = _data.companyDocuments.map((item) => {
        const data: newDocumentDetailsDto = {
          companyRegisterNumber: '',
          documentCategory: (item.documentName.match(/\/([^/]+)\/\d+/) || [])[1] || "",
          fileName: item.documentName,
          version: item.version,
          DocumentNumber: TRANSPORT_COMPANY_DOCUMENT.OTHER_DOCUMENTS.toString(),
          IssuedDate: item.issuedDate,
          expireDate: item.expiryDate
        }

        return data

      })

      const regdata: newDocumentDetailsDto = {

        companyRegisterNumber: _data.registeredNumber,
        documentCategory: TRANSPORT_COMPANY_DOCUMENT_NAME.REGISTRATION_DOCUMENT_NAME,
        fileName: _data.rnDocument,
        version: _data.rnVersion,
        DocumentNumber: TRANSPORT_COMPANY_DOCUMENT.REGISTRATION_DOCUMENT.toString(),
        IssuedDate: _data.registeredDate,
        expireDate: "",
      }

      const bankData: newDocumentDetailsDto = {

        companyRegisterNumber: "",
        documentCategory: TRANSPORT_COMPANY_DOCUMENT_NAME.BANK_DOCUMENT_NAME,
        fileName: _data?.paymentMethodDetails[0]?.bankDocument,
        version: _data?.paymentMethodDetails[0]?.bdVersion,
        DocumentNumber: TRANSPORT_COMPANY_DOCUMENT.BANK_DOCUMENT.toString(),
        IssuedDate: _data?.paymentMethodDetails[0]?.issuedDate,
        expireDate: "",
      }
      const docdetailslist = [...otherDocument, regdata, bankData];
      setDocumentDetails(docdetailslist)
    }
  }, [getTransportCompanyByIDResponse.status, GetPaymenMethodsResponse.status, getTransportCompanyByIDResponse.data, GetPaymenMethodsResponse.data, InitialbranchList, initialbankList, GetBankListResponse.status, GetBranchResponse.status])


  useEffect(() => {
    if (fileUplordTypeNumber !== 0) {
      handleUploadFile(fileUplordTypeNumber)
    }
  }, [fileUplordTypeNumber])

  useEffect(() => {
    if (GetPaymenMethodsResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setpaymentmethods(GetPaymenMethodsResponse.data)
    }
  }, [GetPaymenMethodsResponse.status])

  useEffect(() => {
    if (GetBankListResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setbankList(GetBankListResponse.data)
      if (initialBankValues) {
        setinitialbankList(GetBankListResponse.data)
      }
      setinitialBankValues(false)
    }
  }, [GetBankListResponse.status])

  useEffect(() => {
    if (GetBranchResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setbranchList(GetBranchResponse.data)
      if (initialBranchValues) {
        setInitialbranchList(GetBranchResponse.data)
      }
      setinitialBranchValues(false)

    }
  }, [GetBranchResponse.status])

  useEffect(() => {
    if (RegistrationFIleResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setCompanyRegistrationForm({
        ...CompanyRegistrationForm,
        companyRegisteredFileUploadUrl: {
          ...CompanyRegistrationForm.companyRegisteredFileUploadUrl,
          value: RegistrationFIleResponse.data[0],
          error: "",
        },
        rnVersion:{
          ...CompanyRegistrationForm.rnVersion,
          value:CompanyRegistrationForm.rnVersion.value + 1,
        }
      })

      setCompanyDetailsForm({
        ...companyDetailsForm,
        rnVersion:{
          ...companyDetailsForm.rnVersion,
          value:companyDetailsForm.rnVersion.value + 1
        }
      })
    }
  }, [RegistrationFIleResponse.status])

  useEffect(() => {
    if (BankFilefileRespose.status === APP_ACTION_STATUS.SUCCESS) {
      setCOAIformationForm({
        ...COAIformationForm,
        COAInformationFileUploadUrl: {
          ...COAIformationForm.COAInformationFileUploadUrl,
          value: BankFilefileRespose.data[0],
          error: "",
        },
        bdVersion:{
          ...COAIformationForm.bdVersion,
          value:COAIformationForm.bdVersion.value+1,
        }
      })
    }
  }, [BankFilefileRespose.status])

  useEffect(() => {
    if (OtherDockResponse.status === APP_ACTION_STATUS.SUCCESS) {
      console.log("OtherDockResponse", OtherDockResponse)
      setotherdocumentForm({
        ...otherdocumentForm,
        OtherDocumentFileUploadUrl: {
          ...otherdocumentForm.OtherDocumentFileUploadUrl,
          value: OtherDockResponse.data[0],
          error: "",
        },
        version:{
          ...otherdocumentForm.version,
          value:otherdocumentForm.version.value + 1
        }

      })
    }
  }, [OtherDockResponse.status])


  const getInitialData = () => {
    const _mode = sessionStorage.getItem("Mode");
    if (_mode === TRANSPORT_COMPANY_MODE.VIEW || _mode === TRANSPORT_COMPANY_MODE.EDIT) {
      dispatch(transportActions.GetBranchList(""))
    }
    dispatch(transportActions.GetPaymentMethods())
    dispatch(transportActions.GetBankList())
    dispatch(vehicleActions.getVehicleTypes());
    
    setCompanyRegistrationForm(COMPANY_REGISTRATION_FORM_INITIAL_STATE)
    setCOAIformationForm(COA_INFORMATION_FORM_INITIAL_STATE)
    setotherdocumentForm(OTHER_DOCUMENT_FORM_INITIAL_STATE)
  }

  const onInputHandleChange = (property: string, value: any) => {
    setHelperText(true);
    if (value) {

      if (property === "CompanyName") {
        setCompanyDetailsForm({
          ...companyDetailsForm,
          companyName: {
            ...companyDetailsForm.companyName,
            value: value
          }
        })
      }
      if (property === "AssignedSBUs") {
        setCompanyDetailsForm({
          ...companyDetailsForm,
          attachedSbu: {
            ...companyDetailsForm.attachedSbu,
            value: value
          }
        })
      }
      if (property === "phoneNumber") {
        setCompanyDetailsForm({
          ...companyDetailsForm,
          phoneNumber: {
            ...companyDetailsForm.phoneNumber,
            value: value
          }
        })
      }
      if (property === "email") {
        setCompanyDetailsForm({
          ...companyDetailsForm,
          email: {
            ...companyDetailsForm.email,
            value: value
          }
        })
      }
      ///addressInformationForm//
      if (property === "district") {
        let province = sriLankanDistricts.find((item) => item.id === value.value);
        setaddressInformationForm({
          ...addressInformationForm,
          district: {
            ...addressInformationForm.district,
            value: value
          },
          province: {
            ...addressInformationForm.district,
            value: province?.province || ''
          }
        })
      }
      if (property === "AddressLine1") {
        setaddressInformationForm({
          ...addressInformationForm,
          AddressLine1: {
            ...addressInformationForm.AddressLine1,
            value: value
          }
        })
      }
      if (property === "AddressLine2") {
        setaddressInformationForm({
          ...addressInformationForm,
          AddressLine2: {
            ...addressInformationForm.AddressLine2,
            value: value
          }
        })
      }
      if (property === "city") {
        setaddressInformationForm({
          ...addressInformationForm,
          city: {
            ...addressInformationForm.city,
            value: value
          }
        })
      }
      if (property === "postalCode") {
        setaddressInformationForm({
          ...addressInformationForm,
          postalCode: {
            ...addressInformationForm.postalCode,
            value: value
          }
        })
      }
      /////////////paymentForm////////////////////

      if (property === "currencyType") {
        setpaymentDetailsForm({
          ...paymentDetailsForm,
          currencyType: {
            ...paymentDetailsForm.currencyType,
            value: value
          }
        })
      }

      if (property === "paymentMethod") {


        if (value.value === 1) {
          setpaymentDetailsForm({
            ...paymentDetailsForm,
            paymentMethod: {
              ...paymentDetailsForm.paymentMethod,
              value: value
            },
            AccountNumber: {
              ...paymentDetailsForm.AccountNumber,
              disable: false,
              isRequired: true
            },
            bank: {
              ...paymentDetailsForm.bank,
              disable: false,
              isRequired: true
            },
            bankCode: {
              ...paymentDetailsForm.bankCode,
              disable: false,
              isRequired: true
            },
            Branch: {
              ...paymentDetailsForm.Branch,
              disable: false,
              isRequired: true
            },
            branchCode: {
              ...paymentDetailsForm.branchCode,
              disable: false,
              isRequired: true
            },
          })

        } else {
          setpaymentDetailsForm({
            ...paymentDetailsForm,
            paymentMethod: {
              ...paymentDetailsForm.paymentMethod,
              value: value
            },
            AccountNumber: {
              ...paymentDetailsForm.AccountNumber,
              value: "",
              disable: true,
              isRequired: false
            },
            bank: {
              ...paymentDetailsForm.bank,
              value: {} as OptionsDto,
              disable: true,
              isRequired: false
            },
            bankCode: {
              ...paymentDetailsForm.bankCode,
              value: "",
              disable: true,
              isRequired: false
            },
            Branch: {
              ...paymentDetailsForm.Branch,
              value: {} as OptionsDto,
              disable: true,
              isRequired: false
            },
            branchCode: {
              ...paymentDetailsForm.branchCode,
              value: "",
              disable: true,
              isRequired: false
            },


          })

        }
      }
      if (property === "bank") {
        console.log("bank", value);
        let code = bankList.find((item) => item.id === value.value);

        setpaymentDetailsForm({
          ...paymentDetailsForm,
          bank: {
            ...paymentDetailsForm.bank,
            value: value
          },
          bankCode: {
            ...paymentDetailsForm.bankCode,
            value: code?.code || '',
            error: null
          }
        })
      }
      if (property === "Branch") {
        let code = branchList.find((item) => item.id === value.value);

        setpaymentDetailsForm({
          ...paymentDetailsForm,
          Branch: {
            ...paymentDetailsForm.Branch,
            value: value
          },
          branchCode: {
            ...paymentDetailsForm.branchCode,
            value: code?.code || '',
            error: null
          }
        })
      }
      if (property === "_AccountNumber") {

        setpaymentDetailsForm({
          ...paymentDetailsForm,
          AccountNumber: {
            ...paymentDetailsForm.AccountNumber,
            value: value
          }
        })
      }
      if (property === "beneficiaryName") {

        setpaymentDetailsForm({
          ...paymentDetailsForm,
          beneficiaryName: {
            ...paymentDetailsForm.beneficiaryName,
            value: value
          }
        })
      }



      ////////////////////document/////////
      if (property === "RegistrationFile") {
        if (value.type !== 'application/pdf') {
          const setAlert: AlertDto = {
            message: "Please select a PDF file",
            type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
            options: {
              key: new Date().getTime() + Math.random(),
              varient: "error",
            },
          };
          dispatch(alertActions.triggerAlert(setAlert));

        } else {
          setCompanyRegistrationForm({
            ...CompanyRegistrationForm,
            companyRegisterFile: {
              ...CompanyRegistrationForm.companyRegisterFile,
              value: value
            }
          })
          setfileUplordTypeNumber(TRANSPORT_COMPANY_DOCUMENT.REGISTRATION_DOCUMENT)
        }

      }
      if (property === "registeredDate") {
        setCompanyRegistrationForm({
          ...CompanyRegistrationForm,
          registeredDate: {
            ...CompanyRegistrationForm.registeredDate,
            value: value,
            error:null
          }
        })
      }

      if (property === "companyRegisterNumber") {
        setCompanyRegistrationForm({
          ...CompanyRegistrationForm,
          companyRegisterNumber: {
            ...CompanyRegistrationForm.companyRegisterNumber,
            value: value
          }
        })
      }

      if (property === "AccountNumber") {
        setCOAIformationForm({
          ...COAIformationForm,
          AccountNumber: {
            ...COAIformationForm.AccountNumber,
            value: value
          }
        })
      }
      if (property === "issuedDate") {
        setCOAIformationForm({
          ...COAIformationForm,
          issuedDate: {
            ...COAIformationForm.issuedDate,
            value: value,
            error:null
          }
        })
      }
      if (property === "COAInformationFile") {
        if (value.type !== 'application/pdf') {
          const setAlert: AlertDto = {
            message: "Please select a PDF file",
            type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
            options: {
              key: new Date().getTime() + Math.random(),
              varient: "error",
            },
          };
          dispatch(alertActions.triggerAlert(setAlert));

        } else {
          setCOAIformationForm({
            ...COAIformationForm,
            ConfirmationAccountFile: {
              ...COAIformationForm.ConfirmationAccountFile,
              value: value
            }
          })
          setfileUplordTypeNumber(TRANSPORT_COMPANY_DOCUMENT.BANK_DOCUMENT)

        }

      }

      if (property === "documentCategory") {
        setotherdocumentForm({
          ...otherdocumentForm,
          documentCategory: {
            ...otherdocumentForm.documentCategory,
            value: value
          }
        })
      }
      if (property === "issuedDateOtherDoc") {
        setotherdocumentForm({
          ...otherdocumentForm,
          issuedDateOtherDoc: {
            ...otherdocumentForm.issuedDateOtherDoc,
            value: value
          }
        })
      }
      if (property === "expiryDateOtherDoc") {
        setotherdocumentForm({
          ...otherdocumentForm,
          expiryDateOtherDoc: {
            ...otherdocumentForm.expiryDateOtherDoc,
            value: value
          }
        })
      }
      if (property === "OtherDocumentFile") {
        if (value.type !== 'application/pdf') {
          const setAlert: AlertDto = {
            message: "Please select a PDF file",
            type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
            options: {
              key: new Date().getTime() + Math.random(),
              varient: "error",
            },
          };
          dispatch(alertActions.triggerAlert(setAlert));

        } else {
          setotherdocumentForm({
            ...otherdocumentForm,
            OtherDocumentFile: {
              ...otherdocumentForm.OtherDocumentFile,
              value: value
            }
          });
          setfileUplordTypeNumber(TRANSPORT_COMPANY_DOCUMENT.OTHER_DOCUMENTS)

        }

      }
    }
    if (!value) {
      if (property === "CompanyName") {
        setCompanyDetailsForm({
          ...companyDetailsForm,
          companyName: {
            ...companyDetailsForm.companyName,
            value: value
          }
        })
      }
      if (property === "AssignedSBUs") {
        setCompanyDetailsForm({
          ...companyDetailsForm,
          attachedSbu: {
            ...companyDetailsForm.attachedSbu,
            value: value
          }
        })
      }
      if (property === "AccountNumber") {
        setCOAIformationForm({
          ...COAIformationForm,
          AccountNumber: {
            ...COAIformationForm.AccountNumber,
            value: value
          }
        })
      }
      if (property === "beneficiaryName") {

        setpaymentDetailsForm({
          ...paymentDetailsForm,
          beneficiaryName: {
            ...paymentDetailsForm.beneficiaryName,
            value: value
          }
        })
      }
      if (property === "phoneNumber") {
        setCompanyDetailsForm({
          ...companyDetailsForm,
          phoneNumber: {
            ...companyDetailsForm.phoneNumber,
            value: value
          }
        })
      }
      if (property === "email") {
        setCompanyDetailsForm({
          ...companyDetailsForm,
          email: {
            ...companyDetailsForm.email,
            value: value
          }
        })
      }
      if (property === "district") {
        // let province = sriLankanDistricts.find((item) => item.id === value.value);
        setaddressInformationForm({
          ...addressInformationForm,
          district: {
            ...addressInformationForm.district,
            value: {} as OptionsDto
          },
          province: {
            ...addressInformationForm.district,
            value: ""
          }
        })
      }
      if (property === "AddressLine1") {
        setaddressInformationForm({
          ...addressInformationForm,
          AddressLine1: {
            ...addressInformationForm.AddressLine1,
            value: value
          }
        })
      }
      if (property === "AddressLine2") {
        setaddressInformationForm({
          ...addressInformationForm,
          AddressLine2: {
            ...addressInformationForm.AddressLine2,
            value: value
          }
        })
      }
      if (property === "city") {
        setaddressInformationForm({
          ...addressInformationForm,
          city: {
            ...addressInformationForm.city,
            value: value
          }
        })
      }
      if (property === "postalCode") {
        setaddressInformationForm({
          ...addressInformationForm,
          postalCode: {
            ...addressInformationForm.postalCode,
            value: value
          }
        })
      }
      if (property === "paymentMethod") {
        setpaymentDetailsForm({
          ...paymentDetailsForm,
          paymentMethod: {
            ...paymentDetailsForm.paymentMethod,
            value: {} as OptionsDto
          },
          AccountNumber: {
            ...paymentDetailsForm.AccountNumber,
            value: "",
            disable: true,
            isRequired: false,

          },
          bank: {
            ...paymentDetailsForm.bank,
            value: {} as OptionsDto,
            disable: true,
            isRequired: false,

          },
          bankCode: {
            ...paymentDetailsForm.bankCode,
            value: "",
            disable: true,
            isRequired: false,

          },
          Branch: {
            ...paymentDetailsForm.Branch,
            value: {} as OptionsDto,
            disable: true,
            isRequired: false,

          },
          branchCode: {
            ...paymentDetailsForm.branchCode,
            value: "",
            disable: true,
            isRequired: false,

          },


        })
      }
      if (property === "Branch") {
        //    let code = branchList.find((item) => item.id === value.value);

        setpaymentDetailsForm({
          ...paymentDetailsForm,
          Branch: {
            ...paymentDetailsForm.Branch,
            value: {} as OptionsDto
          },
          branchCode: {
            ...paymentDetailsForm.branchCode,
            value: '',
            error: null
          }
        })
      }
      if (property === "currencyType") {
        setpaymentDetailsForm({
          ...paymentDetailsForm,
          currencyType: {
            ...paymentDetailsForm.currencyType,
            value: {} as OptionsDto
          }
        })
      }
      if (property === "_AccountNumber") {

        setpaymentDetailsForm({
          ...paymentDetailsForm,
          AccountNumber: {
            ...paymentDetailsForm.AccountNumber,
            value: value
          }
        })
      }
      if (property === "companyRegisterNumber") {
        setCompanyRegistrationForm({
          ...CompanyRegistrationForm,
          companyRegisterNumber: {
            ...CompanyRegistrationForm.companyRegisterNumber,
            value: value
          }
        })
      }
      if (property === "documentCategory") {
        setotherdocumentForm({
          ...otherdocumentForm,
          documentCategory: {
            ...otherdocumentForm.documentCategory,
            value: value
          }
        })
      }
      if (property === "bank") {
        console.log("bank", value);
        //  let code = bankList.find((item) => item.id === value.value);

        setpaymentDetailsForm({
          ...paymentDetailsForm,
          bank: {
            ...paymentDetailsForm.bank,
            value: {} as OptionsDto
          },
          bankCode: {
            ...paymentDetailsForm.bankCode,
            value: '',
            error: null
          }
        })

      }
    }


  }
  const onSearchValueChange = (property: string, value: any) => {
    console.log("inputValue", inputValue, "value", value);
    if (property === "Branch") {
      if (value && value !== inputValue) {

        dispatch(transportActions.GetBranchList(value))

      }

    }
    setInputValue(value);

  }

  const onInputHandleChangeVehicleCost = (property: string, value: any) => {
    setHelperText(true)
    if (property === 'vehicleType') {
      setVehcicleCostForm(() => ({
        ...vehicleCostForm,
        vehicleType: {
          ...vehicleCostForm.vehicleType,
          value: value,
        }
      }))
    }
    if (property === 'price') {
      setVehcicleCostForm(() => ({
        ...vehicleCostForm,
        priceKm: {
          ...vehicleCostForm.priceKm,
          value: value
        }
      }))
    }
  }

  const handleInputFocus = (property: string, section: string) => {
    
    if (section === "CD") {
      setCompanyDetailsForm({
        ...companyDetailsForm,
        [property]: {
          ...companyDetailsForm[property as keyof typeof companyDetailsForm],
          error: null,
        },
      });
      setCompanyRegistrationForm({
        ...CompanyRegistrationForm,
        [property]: {
          ...CompanyRegistrationForm[property as keyof typeof CompanyRegistrationForm],
          error: null,
        },
      })
      setCOAIformationForm({
        ...COAIformationForm,
        [property]: {
          ...COAIformationForm[property as keyof typeof COAIformationForm],
          error: null,
        },
      })
      setotherdocumentForm({
        ...otherdocumentForm,
        [property]: {
          ...otherdocumentForm[property as keyof typeof otherdocumentForm],
          error: null,
        },
      })
      setVehcicleCostForm({
        ...vehicleCostForm,
        [property]: {
          ...vehicleCostForm[property as keyof typeof vehicleCostForm],
          error:null,
        }
      })
    }

    if (section === "PM")
      setpaymentDetailsForm({
        ...paymentDetailsForm,
        [property]: {
          ...paymentDetailsForm[property as keyof typeof paymentDetailsForm],
          error: null,
        },
      });

    if(section === "VCI")  {
      setVehcicleCostForm({
        ...vehicleCostForm,
        [property]:{
          ...vehicleCostForm[property as keyof typeof vehicleCostForm],
          error:null
        }
      })
    }

    if(section === "AD"){
      setaddressInformationForm({
        ...addressInformationForm,
        [property]:{
          ...addressInformationForm[property as keyof typeof addressInformationForm],
          error:null
        }
      })
    }

  }

  const onPushPaymentDetails = async (property: string) => {
    const [validateData, isValid] = await validateFormData(
      paymentDetailsForm
    );
    setpaymentDetailsForm(validateData)

    if (isValid) {
      const newPayementDetails: NewPaymentDetailsDTo = {
        currencyType: paymentDetailsForm.currencyType.value,
        paymentMethod: paymentDetailsForm.paymentMethod.value,
        bank: paymentDetailsForm.bank.value,
        bankCode: paymentDetailsForm.bankCode.value,
        Branch: paymentDetailsForm.Branch.value,
        branchCode: paymentDetailsForm.branchCode.value,
        beneficiaryName: paymentDetailsForm.beneficiaryName.value,
        AccountNumber: paymentDetailsForm.AccountNumber.value,
        isActive: false,
      }
      let _list = [...paymentDetailsList, newPayementDetails];
      if (property === "edit") {
        _list = [...paymentDetailsList.filter((d, index) => index !== selectedPaymet), newPayementDetails];
      }

      setpaymentDetailsList(_list)
      setpaymentDetailsForm(COMPANY_PAYMENT_DETAILS_FORM_INITIAL_STATE)
      setispaymetDetailsEdite(false)

    }
  }


  const setActiveStates = (index: number) => {
    const updatedPaymentDetailsList = paymentDetailsList.map((item, i) => ({
      ...item,
      isActive: i === index ? !item.isActive : false, // Set isActive to true only for the specified index
    }));
    setpaymentDetailsList(updatedPaymentDetailsList)
  }
  const PaymentDetailsFormClear = () => {
    setpaymentDetailsForm(COMPANY_PAYMENT_DETAILS_FORM_INITIAL_STATE)
  }

  const onAddDocuments = async (documentId: number) => {
    if (documentId === TRANSPORT_COMPANY_DOCUMENT.REGISTRATION_DOCUMENT) {
      const [validateData, isValid] = await validateFormData(CompanyRegistrationForm)
      setCompanyRegistrationForm(validateData)

      const _isTHere = DocumentDetails.find((v) => v.documentCategory === TRANSPORT_COMPANY_DOCUMENT_NAME.REGISTRATION_DOCUMENT_NAME);
      if (isValid && _isTHere === undefined) {
        const _document: newDocumentDetailsDto = {

          documentCategory: TRANSPORT_COMPANY_DOCUMENT_NAME.REGISTRATION_DOCUMENT_NAME,
          DocumentNumber: documentId.toString(),
          fileName: CompanyRegistrationForm.companyRegisteredFileUploadUrl.value,
          IssuedDate: dayjs(CompanyRegistrationForm.registeredDate.value as Dayjs).format('YYYY-MM-DD'),
          version: CompanyRegistrationForm.rnVersion.value,
          expireDate: "",
          companyRegisterNumber: CompanyRegistrationForm.companyRegisterNumber.value,
        }
        const _list = [...DocumentDetails, _document]
        setDocumentDetails(_list)
        setCompanyRegistrationForm({
          ...COMPANY_REGISTRATION_FORM_INITIAL_STATE,
          companyRegisteredFileUploadUrl: {
            ...COMPANY_REGISTRATION_FORM_INITIAL_STATE.companyRegisteredFileUploadUrl,
            value: ""
          }
        })
      }

      
      if(_isTHere !== undefined){
        dispatch(alertActions.triggerAlert(alreadyExistsAlert));
      }
    }

    if (documentId === TRANSPORT_COMPANY_DOCUMENT.BANK_DOCUMENT) {
      const [validateData, isValid] = await validateFormData(COAIformationForm)
      setCOAIformationForm(validateData)

      const _isTHere = DocumentDetails.find((v) => v.documentCategory === TRANSPORT_COMPANY_DOCUMENT_NAME.BANK_DOCUMENT_NAME);
      if (isValid && _isTHere === undefined) {
        const _document: newDocumentDetailsDto = {

          documentCategory: TRANSPORT_COMPANY_DOCUMENT_NAME.BANK_DOCUMENT_NAME,
          DocumentNumber: documentId.toString(),
          fileName: COAIformationForm.COAInformationFileUploadUrl.value,
          IssuedDate: dayjs(COAIformationForm.issuedDate.value as Dayjs).format('YYYY-MM-DD'),
          version: COAIformationForm.bdVersion.value,
          expireDate: "",
          companyRegisterNumber: ""
        }

        const _list = [...DocumentDetails, _document]
        setDocumentDetails(_list)
        setCOAIformationForm({
          ...COA_INFORMATION_FORM_INITIAL_STATE,
          COAInformationFileUploadUrl: {
            ...COA_INFORMATION_FORM_INITIAL_STATE.COAInformationFileUploadUrl,
            value: ""
          }
        })
      }

      
      if(_isTHere !== undefined){
        dispatch(alertActions.triggerAlert(alreadyExistsAlert));
      }
    }

    if (documentId === TRANSPORT_COMPANY_DOCUMENT.OTHER_DOCUMENTS) {
      const [validateData, isValid] = await validateFormData(otherdocumentForm)
      setotherdocumentForm(validateData)

      const _isTHere = DocumentDetails.find((v) => v.documentCategory === otherdocumentForm.documentCategory.value); 
      if (isValid && _isTHere === undefined) {
        if (otherdocumentForm.OtherDocumentFileUploadUrl.value === '') {
          dispatch(alertActions.triggerAlert(setFileAlert))
        }else{
          const _document: newDocumentDetailsDto = {

            documentCategory: otherdocumentForm.documentCategory.value,
            DocumentNumber: documentId.toString(),
            fileName: otherdocumentForm.OtherDocumentFileUploadUrl.value,
            IssuedDate: dayjs(otherdocumentForm.issuedDateOtherDoc.value as Dayjs).format('YYYY-MM-DD'),
            version: otherdocumentForm.version.value,
            expireDate: dayjs(otherdocumentForm.expiryDateOtherDoc.value as Dayjs).format('YYYY-MM-DD'),
            companyRegisterNumber: ""
          }
          const _list = [...DocumentDetails, _document]
          setDocumentDetails(_list)
          setotherdocumentForm({
            ...OTHER_DOCUMENT_FORM_INITIAL_STATE,
            OtherDocumentFileUploadUrl: {
              ...OTHER_DOCUMENT_FORM_INITIAL_STATE.OtherDocumentFileUploadUrl,
              value: ""
            }
          })
        }  
      } else {
        if (otherdocumentForm.OtherDocumentFileUploadUrl.value === "") {
          const setAlert: AlertDto = {
            message: "Please select a PDF file",
            type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
            options: {
              key: new Date().getTime() + Math.random(),
              varient: "error",
            },
          };
          dispatch(alertActions.triggerAlert(setAlert));
        }

      }

      if(_isTHere !== undefined){
        dispatch(alertActions.triggerAlert(alreadyExistsAlert));
      }
    }
  }

  const handnleFileChangeFromActions = (property: string, value: number) => {
    if (property === "download") {
      if (value === TRANSPORT_COMPANY_DOCUMENT.REGISTRATION_DOCUMENT) {
        const _downloadPayload: DownloadParamLinkDto = {
          fileName: CompanyRegistrationForm.companyRegisteredFileUploadUrl.value,
          mainDocumentType: DOCUMENT_TYPE.TRANSPORT_COMPANY_DOCUMENTS,
        }
        dispatch(linkActions.downloadRequiredFile(_downloadPayload))
      }
      if (value === TRANSPORT_COMPANY_DOCUMENT.BANK_DOCUMENT) {
        const _downloadPayload: DownloadParamLinkDto = {
          fileName: COAIformationForm.COAInformationFileUploadUrl.value,
          mainDocumentType: DOCUMENT_TYPE.TRANSPORT_COMPANY_DOCUMENTS,
        }
        dispatch(linkActions.downloadRequiredFile(_downloadPayload))
      }

      if (value === TRANSPORT_COMPANY_DOCUMENT.OTHER_DOCUMENTS) {
        const _downloadPayload: DownloadParamLinkDto = {
          fileName: otherdocumentForm.OtherDocumentFileUploadUrl.value,
          mainDocumentType: DOCUMENT_TYPE.TRANSPORT_COMPANY_DOCUMENTS,
        }
        dispatch(linkActions.downloadRequiredFile(_downloadPayload))
      }

    }
    if (property === "delete") {
      if (value === TRANSPORT_COMPANY_DOCUMENT.REGISTRATION_DOCUMENT) {
        if(CompanyRegistrationForm.companyRegisteredFileUploadUrl.value !== ""){
          const _downloadPayload: DownloadParamLinkDto = {
            fileName: CompanyRegistrationForm.companyRegisteredFileUploadUrl.value,
            mainDocumentType: DOCUMENT_TYPE.TRANSPORT_COMPANY_DOCUMENTS,
          }
          dispatch(linkActions.removeUploadedFile(_downloadPayload))
        }
        setCompanyRegistrationForm(COMPANY_REGISTRATION_FORM_INITIAL_STATE)
      }
      if (value === TRANSPORT_COMPANY_DOCUMENT.BANK_DOCUMENT) {
        if(COAIformationForm.COAInformationFileUploadUrl.value !== ""){
          const _downloadPayload: DownloadParamLinkDto = {
            fileName: COAIformationForm.COAInformationFileUploadUrl.value,
            mainDocumentType: DOCUMENT_TYPE.TRANSPORT_COMPANY_DOCUMENTS,
          }
          dispatch(linkActions.removeUploadedFile(_downloadPayload))
        }
        setCOAIformationForm(COA_INFORMATION_FORM_INITIAL_STATE)
      }

      if (value === TRANSPORT_COMPANY_DOCUMENT.OTHER_DOCUMENTS) {
        if(otherdocumentForm.OtherDocumentFileUploadUrl.value !== ""){
          const _downloadPayload: DownloadParamLinkDto = {
            fileName: otherdocumentForm.OtherDocumentFileUploadUrl.value,
            mainDocumentType: DOCUMENT_TYPE.TRANSPORT_COMPANY_DOCUMENTS,
          }
          dispatch(linkActions.removeUploadedFile(_downloadPayload))
        }
        setotherdocumentForm(OTHER_DOCUMENT_FORM_INITIAL_STATE)
      }
    }

  }

  const onSelectDocument = (id: number, action: string) => {
    setSelectedDoc(id)

    if (action === 'delete') {
      setIsOpenDocumentRemovePopup(true)
    }
    if (action === "download") {
      const foundItem = DocumentDetails.find((item, index) => index === id);

      if (foundItem) {
        const _downloadPayload: DownloadParamLinkDto = {
          fileName: foundItem.fileName,
          mainDocumentType: DOCUMENT_TYPE.TRANSPORT_COMPANY_DOCUMENTS,
        }
        dispatch(linkActions.downloadRequiredFile(_downloadPayload))
      }
    }
    if (action === 'view') {
      const foundItem = DocumentDetails.find((item, index) => index === id);
      if (foundItem) {
        const _downloadPayload: DownloadParamLinkDto = {
          fileName: foundItem.fileName,
          mainDocumentType: DOCUMENT_TYPE.TRANSPORT_COMPANY_DOCUMENTS,
        }
        dispatch(linkActions.downloadOpenFile(_downloadPayload))
      }
    }
  }
  const onRemoveVehicleDocument = (con: boolean) => {
    if (con) {
      setDocumentDetails(DocumentDetails.filter((d, index) => index !== selectedDoc))
      setSelectedDoc(-1)
    }
    setIsOpenDocumentRemovePopup(false)
  }
  const handleUploadFile = (DocumentType: number) => {

    if (DocumentType === TRANSPORT_COMPANY_DOCUMENT.REGISTRATION_DOCUMENT) {
      console.log("DocumentType", DocumentType);
      if (CompanyRegistrationForm.companyRegisterFile.value) {
        const fileUploadPyload: FileUploadLinkDto = {
          attachment: CompanyRegistrationForm.companyRegisterFile.value,
          mainDocumentType: DOCUMENT_TYPE.TRANSPORT_COMPANY_DOCUMENTS,
          documentType: TRANSPORT_COMPANY_DOCUMENT.REGISTRATION_DOCUMENT,
          folderName: "",
        };
        dispatch(linkActions.uploadMainFile(fileUploadPyload));
      }
    }
    if (DocumentType === TRANSPORT_COMPANY_DOCUMENT.OTHER_DOCUMENTS) {

      if (otherdocumentForm.OtherDocumentFile.value) {
        const fileUploadPyload: FileUploadLinkDto = {
          attachment: otherdocumentForm.OtherDocumentFile.value,
          mainDocumentType: DOCUMENT_TYPE.TRANSPORT_COMPANY_DOCUMENTS,
          documentType: TRANSPORT_COMPANY_DOCUMENT.OTHER_DOCUMENTS,
          folderName: otherdocumentForm.documentCategory.value,
        };
        dispatch(linkActions.uploadOtherFile(fileUploadPyload));
      }
    }
    if (DocumentType === TRANSPORT_COMPANY_DOCUMENT.BANK_DOCUMENT) {
      console.log("DocumentType", DocumentType);
      if (COAIformationForm.ConfirmationAccountFile.value) {
        const fileUploadPayload: FileUploadLinkDto = {
          attachment: COAIformationForm.ConfirmationAccountFile.value,
          mainDocumentType: DOCUMENT_TYPE.TRANSPORT_COMPANY_DOCUMENTS,
          documentType: TRANSPORT_COMPANY_DOCUMENT.BANK_DOCUMENT,
          folderName: ""
        };
        dispatch(linkActions.uploadSecondaryFile(fileUploadPayload));
      }
    }
    setfileUplordTypeNumber(0)

  }

  const setAsInitialState = () => {
    console.log("calling set state");
    setCompanyDetailsForm({
      ...COMPANY_DETAILS_FORM_INITIAL_STATE,
      attachedSbu: {
        ...companyDetailsForm.attachedSbu,
      }
    })
    setaddressInformationForm(COMPANY_ADDRESS_FORM_INITIAL_STATE)
    setpaymentDetailsForm(COMPANY_PAYMENT_DETAILS_FORM_INITIAL_STATE)
    setCompanyRegistrationForm({
      ...COMPANY_REGISTRATION_FORM_INITIAL_STATE,
      companyRegisteredFileUploadUrl: {
        ...COMPANY_REGISTRATION_FORM_INITIAL_STATE.companyRegisteredFileUploadUrl,
        value: ""
      }
    })
    setCOAIformationForm(COA_INFORMATION_FORM_INITIAL_STATE)
    setotherdocumentForm(OTHER_DOCUMENT_FORM_INITIAL_STATE)
    setDocumentDetails([])
    dispatch(linkActions.uploadMainFileClear())
    dispatch(linkActions.uploadOtherFileClear())
    dispatch(linkActions.uploadSecondaryFileClear())
  }

  const onClose = () => {

    setAsInitialState()
    navigate(APP_ROUTES.MANAGE_TRANSPORT_COMPANY);
  }
  const createNewSaveRequest = async (type: string) => {
    const [validateData, isValid] = await validateFormData(companyDetailsForm);
    const [validateData2, isValid2] = await validateFormData(addressInformationForm);
    // const [validateData3, isValid3] = await validateFormData(paymentDetailsForm);

    setCompanyDetailsForm(validateData)
    setaddressInformationForm(validateData2)


    if (isValid && isValid2) {

      const BankDoc = DocumentDetails.find(item => item.documentCategory === TRANSPORT_COMPANY_DOCUMENT_NAME.BANK_DOCUMENT_NAME)
      const registerDocument = DocumentDetails.find(item => item.documentCategory === TRANSPORT_COMPANY_DOCUMENT_NAME.REGISTRATION_DOCUMENT_NAME)
      const filteredOtherDocs = DocumentDetails.filter(item => item.documentCategory !== TRANSPORT_COMPANY_DOCUMENT_NAME.BANK_DOCUMENT_NAME && item.documentCategory !== TRANSPORT_COMPANY_DOCUMENT_NAME.REGISTRATION_DOCUMENT_NAME);
      const extractedDocumentValues: companyDocuments[] = filteredOtherDocs.map(item => ({

        documentId: TRANSPORT_COMPANY_DOCUMENT.OTHER_DOCUMENTS,
        documentName: item.fileName,
        issuedDate: item.IssuedDate,
        expiryDate: item.expireDate,
        version:item?.version,
      }));


      const paymentDetails = paymentDetailsList.map((item) => {
        const data: paymentDetailsDto = {
          paymentMethod: Number(item.paymentMethod.value),
          bank: Number(item.bank.value),
          beneficiaryName: item.beneficiaryName,
          accountNumber: item.AccountNumber,
          currency: item.currencyType.label,
          bankDocument: BankDoc?.fileName || '',
          issuedDate: BankDoc?.IssuedDate || '',
          branch: Number(item.Branch.value),
          isActive: item.isActive,
          bdVersion:BankDoc?.version
        }

        return data
      })

      const vehiclePriceDetails = vehiclePriceList.map((item) => {
        const data: PostVehiclePriceListDto = {
          vehicleTypeId:item.vehicleTypeId,
          cost:Number(item.cost)
        }

        return data
      })

      const totPaymentDetails = paymentDetails.length; 
      const isPaymentActive = paymentDetails.find((item) => item.isActive === true)

      const _payload: RegisterTransportCompanyDto = {
        transCompany: companyDetailsForm.companyName.value,
        registeredNumber: registerDocument?.companyRegisterNumber || '',
        rnDocument: registerDocument?.fileName || '',
        rnVersion: companyDetailsForm.rnVersion.value,
        registeredDate: registerDocument?.IssuedDate || '',
        attachedSBU: Number(companyDetailsForm.attachedSbuID.value),
        telephoneNumber: companyDetailsForm.phoneNumber.value,
        email: companyDetailsForm.email.value,
        addressLine1: addressInformationForm.AddressLine1.value,
        addressLine2: addressInformationForm.AddressLine2.value,
        city: addressInformationForm.city.value,
        district: addressInformationForm.district.value.label,
        province: addressInformationForm.province.value,
        postalCode: addressInformationForm.postalCode.value,
        paymentMethodDetails: paymentDetails,
        companyDocuments: extractedDocumentValues,
        companyVehicleType:vehiclePriceDetails
      }

      if (type === "Create") {
        if(totPaymentDetails === 0){
          dispatch(alertActions.triggerAlert(setPaymentAlert))
        }else{
          if(isPaymentActive === undefined){
            if(BankDoc !== undefined){
              dispatch(alertActions.triggerAlert(setPaymentActiveAlert))
            }else{
              dispatch(alertActions.triggerAlert(setGeneralAlert));
            }
          }else{
            dispatch(transportActions.RegisterTransportCompany(_payload))
          }
        }
      } else {
        const editpayloAd: UpdateTransportCompany = {
          payload: _payload,
          companyId: Number(companyDetailsForm.CompanyId.value)
        }
        if(totPaymentDetails === 0){
          dispatch(alertActions.triggerAlert(setPaymentAlert))
        }else{
          if(isPaymentActive === undefined){
            if(BankDoc !== undefined){
              dispatch(alertActions.triggerAlert(setPaymentActiveAlert))
            }else{
              dispatch(alertActions.triggerAlert(setGeneralAlert));
            }
          }else{
            dispatch(transportActions.UpdateTransportCompanyByID(editpayloAd))
          }
        }
      }


    }
    else {
      dispatch(alertActions.triggerAlert(setGeneralAlert));
    }
  }

  const handleTableAction = (id: number, action: string) => {
    setselectedPaymet(id)
    if (action === "delete") {
      setIsOpenPaymentDetailsRemovePopup(true)
    }
    if (action === "edit") {
      setispaymetDetailsEdite(true)

      const data: NewPaymentDetailsDTo[] = (paymentDetailsList.filter((d, index) => index === id).map((v) => {
        let item: NewPaymentDetailsDTo = {
          currencyType: v.currencyType,
          paymentMethod: v.paymentMethod,
          bank: v.bank,
          bankCode: v.bankCode,
          Branch: v.Branch,
          branchCode: v.branchCode,
          beneficiaryName: v.beneficiaryName,
          AccountNumber: v.AccountNumber,
          isActive: v.isActive
        }
        return item
      }))

      if (data[0].paymentMethod.value === 1) {
        setpaymentDetailsForm({
          ...paymentDetailsForm,
          AccountNumber: {
            ...paymentDetailsForm.AccountNumber,
            value: data[0].AccountNumber,
            disable: false
          },
          bank: {
            ...paymentDetailsForm.bank,
            value: data[0].bank,
            disable: false
          },
          bankCode: {
            ...paymentDetailsForm.bankCode,
            value: data[0].bankCode,
            disable: false
          },
          beneficiaryName: {
            ...paymentDetailsForm.beneficiaryName,
            value: data[0].beneficiaryName,
            disable: false
          },
          Branch: {
            ...paymentDetailsForm.Branch,
            value: data[0].Branch,
            disable: false
          },
          branchCode: {
            ...paymentDetailsForm.branchCode,
            value: data[0].branchCode,
            disable: false
          },
          currencyType: {
            ...paymentDetailsForm.currencyType,
            value: data[0].currencyType,
            disable: false
          },
          paymentMethod: {
            ...paymentDetailsForm.paymentMethod,
            value: data[0].paymentMethod,
            disable: false
          }

        })
        setInputValue(data[0].Branch.label);
      } else {
        setpaymentDetailsForm({
          ...paymentDetailsForm,
          AccountNumber: {
            ...paymentDetailsForm.AccountNumber,
            value: data[0].AccountNumber
          },
          bank: {
            ...paymentDetailsForm.bank,
            value: data[0].bank
          },
          bankCode: {
            ...paymentDetailsForm.bankCode,
            value: data[0].bankCode
          },
          beneficiaryName: {
            ...paymentDetailsForm.beneficiaryName,
            value: data[0].beneficiaryName
          },
          Branch: {
            ...paymentDetailsForm.Branch,
            value: data[0].Branch
          },
          branchCode: {
            ...paymentDetailsForm.branchCode,
            value: data[0].branchCode
          },
          currencyType: {
            ...paymentDetailsForm.currencyType,
            value: data[0].currencyType
          },
          paymentMethod: {
            ...paymentDetailsForm.paymentMethod,
            value: data[0].paymentMethod
          }
        })
      }
    }
  }

  const onRemovePaymetDetails = (con: boolean) => {
    if (con) {
      setpaymentDetailsList(paymentDetailsList.filter((d, index) => index !== selectedPaymet))
      setselectedPaymet(-1)
    }
    setIsOpenPaymentDetailsRemovePopup(false)
  }

  const onRemoveVehicleDetails = (con: boolean) => {
    if (con) {
      setVehiclePriceList(vehiclePriceList.filter((d) => d.id !== selectedVehicle))
      setSelectedVehicle(-1)
    }
    setIsOpenRemoveVehicle(false)
  }

  const onSelectVehicle = (id: number, action: string) => {
    setSelectedVehicle(id)
    if (action === 'edit') {
      setIsEditVehicle(true);
      onSelectVehicleForEdit(id)
    }
    if (action === 'delete') {
      setIsOpenRemoveVehicle(true)
    }
  }

  const onSelectVehicleForEdit = (id: number) => {
    const _vehicle = vehiclePriceList.find((v) => v.id === id)
    if (_vehicle)
      setVehcicleCostForm({
        ...vehicleCostForm,
        vehicleType: {
          ...vehicleCostForm.vehicleType,
          value: { label: _vehicle.vehicleType, value: _vehicle.vehicleTypeId}
        },
        priceKm: {
          ...vehicleCostForm.priceKm,
          value: _vehicle.cost
        },
      })
  }

  useEffect(() => {
    if (RegisterTransportCompany.status === APP_ACTION_STATUS.SUCCESS) {
      console.log("RegisterTransportCompany.data", RegisterTransportCompany.data)
      if (RegisterTransportCompany.data === "Company already exists!") {
        const setAlert: AlertDto = {
          message: RegisterTransportCompany.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
        
      } else {
        const setAlert: AlertDto = {
          message: RegisterTransportCompany.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'success'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert))
        navigate(APP_ROUTES.MANAGE_TRANSPORT_COMPANY);
      }


    }
  }, [RegisterTransportCompany.status])

  const onAddPrice = async () => {
    const [validateData, isValid] = await validateFormData(vehicleCostForm)
    setVehcicleCostForm(validateData)

    if (isValid) {
      const _id = vehiclePriceList.length === 0 ? 1 : vehiclePriceList[vehiclePriceList.length - 1].id + 1

      const _vehiclePrice: GetVehiclePriceListDto = {
        id: _id,
        vehicleType:vehicleCostForm.vehicleType.value.label,
        vehicleTypeId: Number(vehicleCostForm.vehicleType.value.value),
        cost:vehicleCostForm.priceKm.value,
      }

      const _list = [...vehiclePriceList, _vehiclePrice]
      setVehiclePriceList(_list)
      setVehcicleCostForm(VEHICLE_COST_INISTIAL_STATE)
    }else {
      const setAlert: AlertDto = {
        message: "select mandatory fields.!",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'error'
        }
      }
      dispatch(alertActions.triggerAlert(setAlert))
    }
  }

  const onEditPrice = async () => {
    const [validateData, isValid] = await validateFormData(vehicleCostForm);
    setVehcicleCostForm(validateData);
    
    if (isValid) {
      vehiclePriceList.forEach((p) => {
        if (p.id === selectedVehicle) {
          p.id = selectedVehicle;
          p.vehicleType = vehicleCostForm.vehicleType.value.label;
          p.vehicleTypeId = Number(vehicleCostForm.vehicleType.value.value);
          p.cost = vehicleCostForm.priceKm.value;
        }
      });
      setIsEditVehicle(false);
      setVehcicleCostForm(VEHICLE_COST_INISTIAL_STATE);
    }
  };

  const onClearVehicleCostForm = () =>{
    setVehcicleCostForm(VEHICLE_COST_INISTIAL_STATE)
  }

  return (
    <React.Fragment>
      <AppLayout componentTitle="Transport manager dashboard">
        <section className="page-root">

          <section className={style.sectionCard}>
            <section className={style.sectionCardHeader}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={10}>
                  <Typography noWrap component="div" className={style.title}>
                    <h3>Transport Company Management</h3>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>

                </Grid>
              </Grid>
            </section>
            <section className={style.sectionCardBody}>
              <section className={style.stepperRoot}>
                <CompanyDetails
                  onInputHandleChange={onInputHandleChange}
                  companyDetailsForm={companyDetailsForm}
                  handleInputFocus={handleInputFocus}
                  helperText={helperText}
                  getTransportCompanyByIDResponse={getTransportCompanyByIDResponse}
                />
                <Address
                  onInputHandleChange={onInputHandleChange}
                  addressInformationForm={addressInformationForm}
                  handleInputFocus={handleInputFocus}
                  helperText={helperText}
                  getTransportCompanyByIDResponse={getTransportCompanyByIDResponse}
                />
                <PaymentDetails
                  onSearchValueChange={onSearchValueChange}
                  paymentmethods={paymentmethods}
                  bankList={bankList}
                  branchList={branchList}
                  onInputHandleChange={onInputHandleChange}
                  paymentDetailsForm={paymentDetailsForm}
                  handleInputFocus={handleInputFocus}
                  helperText={helperText}
                  onPushPaymentDetails={onPushPaymentDetails}
                  paymentDetailsList={paymentDetailsList}
                  setActiveStates={setActiveStates}
                  PaymentDetailsFormClear={PaymentDetailsFormClear}
                  handleTableAction={handleTableAction}
                  ispaymetDetailsEdite={ispaymetDetailsEdite}
                  screenMode={screenMode}
                  getTransportCompanyByIDResponse={getTransportCompanyByIDResponse}
                  GetBankListResponse={GetBankListResponse}
                />
                <DocumentInformation
                  onInputHandleChange={onInputHandleChange}
                  handleInputFocus={handleInputFocus}
                  helperText={helperText}
                  CompanyRegistrationForm={CompanyRegistrationForm}
                  COAIformationForm={COAIformationForm}
                  otherdocumentForm={otherdocumentForm}
                  DocumentDetails={DocumentDetails}
                  onAddDocuments={onAddDocuments}
                  onSelectDocument={onSelectDocument}
                  screenMode={screenMode}
                  handnleFileChangeFromActions={handnleFileChangeFromActions}
                  getTransportCompanyByIDResponse={getTransportCompanyByIDResponse}
                  RegistrationFIleResponse={RegistrationFIleResponse}
                  BankFilefileRespose={BankFilefileRespose} 
                  OtherDockResponse={ OtherDockResponse}
                />

                <VehicleCostDetails 
                  onInputHandleChangeVehicleCost={onInputHandleChangeVehicleCost}
                  handleInputFocus={handleInputFocus}
                  helperText={helperText}
                  vehicleTypesResponse={vehicleTypesResponse}
                  screenMode={screenMode}
                  isEditVehicle={isEditVehicle}
                  onAddPrice={onAddPrice}
                  onEditPrice={onEditPrice}
                  vehicleCostForm={vehicleCostForm}
                  vehiclePriceList={vehiclePriceList}
                  onClearVehicleCostForm={onClearVehicleCostForm}
                  onSelectVehicle={onSelectVehicle}
                />

              </section>
              <section className={style.sectionCardFooter}>
                {(screenMode !== TRANSPORT_COMPANY_MODE.EDIT && screenMode !== TRANSPORT_COMPANY_MODE.VIEW) && <CustomButton
                  text="Clear"
                  border="1px solid #6e6e6e"
                  bgColor="#282828"
                  onClick={setAsInitialState}
                />}
                <CustomButton
                  text="Cancel"
                  textColor="black"
                  bgColor="#bfbfbf"
                  onClick={() => { onClose(); }}
                />
                {(screenMode !== TRANSPORT_COMPANY_MODE.EDIT && screenMode !== TRANSPORT_COMPANY_MODE.VIEW) && <CustomButton
                  text="Save"
                  disabled={RegisterTransportCompany.isLoading}
                  isLoading={RegisterTransportCompany.isLoading}
                  onClick={() => createNewSaveRequest("Create")}
                />}
                {(screenMode === TRANSPORT_COMPANY_MODE.EDIT) && <CustomButton
                  text="Edit"
                  disabled={UpdateByIDResponse.isLoading}
                  isLoading={UpdateByIDResponse.isLoading}
                  onClick={() => createNewSaveRequest("Edit")}
                />}

              </section>
            </section>
          </section>
          <ConfirmationDialog
            isOpenConfirmationDialog={isOpenDocumentRemovePopup}
            onCallback={onRemoveVehicleDocument}
            title="Remove Vehicle Document Information"
            content="Do you want to remove this document?"
          />
          <ConfirmationDialog
            isOpenConfirmationDialog={isOpenpaymetDetailsRemovePopup}
            onCallback={onRemovePaymetDetails}
            title="Remove Vehicle Document Information"
            content="Do you want to remove this document?"
          />
          <ConfirmationDialog
            isOpenConfirmationDialog={isOpenRemoveVehicle}
            onCallback={onRemoveVehicleDetails}
            title="Remove Vehicle Document Information"
            content="Do you want to remove this vehicle?"
          />

        </section>

      </AppLayout>
    </React.Fragment>
  )
}

export default ManageTransportCompanyScreen