import { AxiosResponse } from "axios";
import { GetMatchingTemplateListdto, GetMatchingTemplatesParamDto, TemplatesDto } from "../utilities/models/template.model";
import { axiosPrivateInstance } from ".";

const getTemplates = (): Promise<AxiosResponse<TemplatesDto[]>> => {
  return axiosPrivateInstance.get("/api/Template/GetTemplate");
};

const deleteTemplate = (id: number): Promise<AxiosResponse<void>> => {
  return axiosPrivateInstance.put(`/api/Template/RemoveTemplate?RequestId=${id}`);
};

const getMatchingTemplateList = (param: GetMatchingTemplatesParamDto): Promise<AxiosResponse<GetMatchingTemplateListdto[]>> => {
  
 
  return axiosPrivateInstance.get(`/api/Template/GetMatchingTemplates?fromDate=${param.fromDate}&toDate=${param.toDate}&fromLocation=${param.fromLocation}&toLocation=${param.toLocation}&VehicleTypeId=${param.VehicleTypeId}&PassengerCount=${param.PassengerCount}`);
};

export const templateService = {
  getTemplates,
  deleteTemplate,
  getMatchingTemplateList,
};
