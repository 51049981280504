export const GU_EMAIL = "join@gmail.com";
export const GU_ID = 12;

export const TM_EMAIL = "Nirmali@gmail.com";
export const TM_ID = 16;

export const LM_EMAIL = "supun@gmail.com"; //not work
export const LM_ID = 16;

export const REQUEST_DATA_LIST = [
  {
    requestId: "ABC001",
    requestType: "Type 1",
    createdFor: "User 1",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Rejected by LM",
    VIPTrip: false,
    from: "Location 1",
    to: "Destination 1",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [
      "https://www.gravatar.com/avatar/HASH",
      "https://www.gravatar.com/avatar/HASH",
      "https://www.gravatar.com/avatar/HASH",
      "https://www.gravatar.com/avatar/HASH",
      "https://www.gravatar.com/avatar/HASH",
      "https://www.gravatar.com/avatar/HASH",
    ],
    preferredVehicle: "Car",
    package: false,
    cbm: 0.5,
    purpose: "Purpose 1",
    approver: "Approver 1",
  },
  {
    requestId: 2,
    requestType: "Type 2",
    createdFor: "User 2",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Approved by TM",
    VIPTrip: true,
    from: "Location 2",
    to: "Destination 2",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [],
    preferredVehicle: "Car",
    package: false,
    cbm: 1,
    purpose: "Purpose 2",
    approver: "Approver 2",
  },
  {
    requestId: 3,
    requestType: "Type 3",
    createdFor: "User 3",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Pending",
    VIPTrip: false,
    from: "Location 3",
    to: "Destination 3",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [
      "https://example.com/avatar1.jpg",
      "https://example.com/avatar2.jpg",
      "https://example.com/avatar3.jpg",
      "https://example.com/avatar4.jpg",
    ],
    preferredVehicle: "Car",
    package: true,
    cbm: 1.5,
    purpose: "Purpose 3",
    approver: "Approver 3",
  },
  {
    requestId: 4,
    requestType: "Type 4",
    createdFor: "User 4",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Rejected by LM",
    VIPTrip: true,
    from: "Location 4",
    to: "Destination 4",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [
      "https://example.com/avatar6.jpg",
      "https://example.com/avatar7.jpg",
    ],
    preferredVehicle: "Car",
    package: false,
    cbm: 2,
    purpose: "Purpose 4",
    approver: "Approver 4",
  },
  {
    requestId: 5,
    requestType: "Type 5",
    createdFor: "User 5",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Approved by TM",
    VIPTrip: false,
    from: "Location 5",
    to: "Destination 5",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [],
    preferredVehicle: "Car",
    package: false,
    cbm: 2.5,
    purpose: "Purpose 5",
    approver: "Approver 5",
  },
  {
    requestId: 6,
    requestType: "Type 6",
    createdFor: "User 6",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Pending",
    VIPTrip: true,
    from: "Location 6",
    to: "Destination 6",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [],
    preferredVehicle: "Car",
    package: true,
    cbm: 3,
    purpose: "Purpose 6",
    approver: "Approver 6",
  },
  {
    requestId: 7,
    requestType: "Type 7",
    createdFor: "User 7",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Rejected by LM",
    VIPTrip: false,
    from: "Location 7",
    to: "Destination 7",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [],
    preferredVehicle: "Car",
    package: false,
    cbm: 3.5,
    purpose: "Purpose 7",
    approver: "Approver 7",
  },
  {
    requestId: 8,
    requestType: "Type 8",
    createdFor: "User 8",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Approved by TM",
    VIPTrip: true,
    from: "Location 8",
    to: "Destination 8",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [],
    preferredVehicle: "Car",
    package: false,
    cbm: 4,
    purpose: "Purpose 8",
    approver: "Approver 8",
  },
  {
    requestId: 9,
    requestType: "Type 9",
    createdFor: "User 9",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Pending",
    VIPTrip: false,
    from: "Location 9",
    to: "Destination 9",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [],
    preferredVehicle: "Car",
    package: true,
    cbm: 4.5,
    purpose: "Purpose 9",
    approver: "Approver 9",
  },
  {
    requestId: 10,
    requestType: "Type 10",
    createdFor: "User 10",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Rejected by LM",
    VIPTrip: true,
    from: "Location 10",
    to: "Destination 10",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [],
    preferredVehicle: "Car",
    package: false,
    cbm: 5,
    purpose: "Purpose 10",
    approver: "Approver 10",
  },
  {
    requestId: 11,
    requestType: "Type 11",
    createdFor: "User 11",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Approved by TM",
    VIPTrip: false,
    from: "Location 11",
    to: "Destination 11",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [],
    preferredVehicle: "Car",
    package: false,
    cbm: 5.5,
    purpose: "Purpose 11",
    approver: "Approver 11",
  },
  {
    requestId: 12,
    requestType: "Type 12",
    createdFor: "User 12",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Pending",
    VIPTrip: true,
    from: "Location 12",
    to: "Destination 12",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [],
    preferredVehicle: "Car",
    package: true,
    cbm: 6,
    purpose: "Purpose 12",
    approver: "Approver 12",
  },
  {
    requestId: 13,
    requestType: "Type 13",
    createdFor: "User 13",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Rejected by LM",
    VIPTrip: false,
    from: "Location 13",
    to: "Destination 13",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [],
    preferredVehicle: "Car",
    package: false,
    cbm: 6.5,
    purpose: "Purpose 13",
    approver: "Approver 13",
  },
  {
    requestId: 14,
    requestType: "Type 14",
    createdFor: "User 14",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Approved by TM",
    VIPTrip: true,
    from: "Location 14",
    to: "Destination 14",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [],
    preferredVehicle: "Car",
    package: false,
    cbm: 7,
    purpose: "Purpose 14",
    approver: "Approver 14",
  },
  {
    requestId: 15,
    requestType: "Type 15",
    createdFor: "User 15",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Pending",
    VIPTrip: false,
    from: "Location 15",
    to: "Destination 15",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [],
    preferredVehicle: "Car",
    package: true,
    cbm: 7.5,
    purpose: "Purpose 15",
    approver: "Approver 15",
  },
  {
    requestId: 16,
    requestType: "Type 16",
    createdFor: "User 16",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Rejected by LM",
    VIPTrip: true,
    from: "Location 16",
    to: "Destination 16",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [],
    preferredVehicle: "Car",
    package: false,
    cbm: 8,
    purpose: "Purpose 16",
    approver: "Approver 16",
  },
  {
    requestId: 17,
    requestType: "Type 17",
    createdFor: "User 17",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Approved by TM",
    VIPTrip: false,
    from: "Location 17",
    to: "Destination 17",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [],
    preferredVehicle: "Car",
    package: false,
    cbm: 8.5,
    purpose: "Purpose 17",
    approver: "Approver 17",
  },
  {
    requestId: 18,
    requestType: "Type 18",
    createdFor: "User 18",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Pending",
    VIPTrip: true,
    from: "Location 18",
    to: "Destination 18",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [],
    preferredVehicle: "Car",
    package: true,
    cbm: 9,
    purpose: "Purpose 18",
    approver: "Approver 18",
  },
  {
    requestId: 19,
    requestType: "Type 19",
    createdFor: "User 19",
    createdDatetime: "2023-05-01T09:00:00",
    status: "Rejected by LM",
    VIPTrip: false,
    from: "Location 19",
    to: "Destination 19",
    departureDateTime: "2023-05-02T10:00:00",
    returnDateTime: "2023-05-03T15:00:00",
    passengers: [],
    preferredVehicle: "Car",
    package: false,
    cbm: 10,
    purpose: "Purpose 20",
    approver: "Approver 20",
  },
];

export const APPROVAL_REQUEST_DATA_LIST = [
  {
    requestId: "REQ1",
    requestType: "Request Type 1",
    createdBy: "Created By 1",
    createdUser: "Created User 1",
    status: "Rejected by TM",
    projectedCost: 794,
    createdDatetime: "2023-05-31T16:56:03.860Z",
    sbu: "SBU 1",
    plant: "Plant 1",
    department: "Department 1",
    from: "From Location 1",
    to: "To Location 1",
    departureDateTime: "2023-05-31T16:56:03.860Z",
    returnDateTime: "2023-05-31T16:56:03.860Z",
    passengerCount: 8,
    preferredVehicle: "Bus",
    package: false,
    cubicMeterage: 1.57,
    instruction: "Instruction 1",
    purpose: "Purpose 1",
    approver: "Approver 1",
  },
  {
    requestId: "REQ2",
    requestType: "Request Type 2",
    createdBy: "Created By 2",
    createdUser: "Created User 2",
    status: "Approved by TM",
    projectedCost: 99,
    createdDatetime: "2023-05-31T16:56:03.860Z",
    sbu: "SBU 2",
    plant: "Plant 2",
    department: "Department 2",
    from: "From Location 2",
    to: "To Location 2",
    departureDateTime: "2023-05-31T16:56:03.860Z",
    returnDateTime: "2023-05-31T16:56:03.860Z",
    passengerCount: 0,
    preferredVehicle: "Van",
    package: true,
    cubicMeterage: 0.09,
    instruction: "Instruction 2",
    purpose: "Purpose 2",
    approver: "Approver 2",
  },
  {
    requestId: "REQ3",
    requestType: "Request Type 3",
    createdBy: "Created By 3",
    createdUser: "Created User 3",
    status: "Pending",
    projectedCost: 291,
    createdDatetime: "2023-05-31T16:56:03.860Z",
    sbu: "SBU 3",
    plant: "Plant 3",
    department: "Department 3",
    from: "From Location 3",
    to: "To Location 3",
    departureDateTime: "2023-05-31T16:56:03.860Z",
    returnDateTime: "2023-05-31T16:56:03.860Z",
    passengerCount: 2,
    preferredVehicle: "Car",
    package: false,
    cubicMeterage: 0.99,
    instruction: "Instruction 3",
    purpose: "Purpose 3",
    approver: "Approver 3",
  },
  {
    requestId: "REQ4",
    requestType: "Request Type 4",
    createdBy: "Created By 4",
    createdUser: "Created User 4",
    status: "Rejected by TM",
    projectedCost: 362,
    createdDatetime: "2023-05-31T16:56:03.860Z",
    sbu: "SBU 4",
    plant: "Plant 4",
    department: "Department 4",
    from: "From Location 4",
    to: "To Location 4",
    departureDateTime: "2023-05-31T16:56:03.860Z",
    returnDateTime: "2023-05-31T16:56:03.860Z",
    passengerCount: 2,
    preferredVehicle: "Bus",
    package: true,
    cubicMeterage: 4.87,
    instruction: "Instruction 4",
    purpose: "Purpose 4",
    approver: "Approver 4",
  },
  {
    requestId: "REQ5",
    requestType: "Request Type 5",
    createdBy: "Created By 5",
    createdUser: "Created User 5",
    status: "Approved by TM",
    projectedCost: 588,
    createdDatetime: "2023-05-31T16:56:03.860Z",
    sbu: "SBU 5",
    plant: "Plant 5",
    department: "Department 5",
    from: "From Location 5",
    to: "To Location 5",
    departureDateTime: "2023-05-31T16:56:03.860Z",
    returnDateTime: "2023-05-31T16:56:03.860Z",
    passengerCount: 7,
    preferredVehicle: "Van",
    package: false,
    cubicMeterage: 8.66,
    instruction: "Instruction 5",
    purpose: "Purpose 5",
    approver: "Approver 5",
  },
  {
    requestId: "REQ6",
    requestType: "Request Type 6",
    createdBy: "Created By 6",
    createdUser: "Created User 6",
    status: "Pending",
    projectedCost: 44,
    createdDatetime: "2023-05-31T16:56:03.860Z",
    sbu: "SBU 6",
    plant: "Plant 6",
    department: "Department 6",
    from: "From Location 6",
    to: "To Location 6",
    departureDateTime: "2023-05-31T16:56:03.860Z",
    returnDateTime: "2023-05-31T16:56:03.860Z",
    passengerCount: 3,
    preferredVehicle: "Car",
    package: true,
    cubicMeterage: 0.3,
    instruction: "Instruction 6",
    purpose: "Purpose 6",
    approver: "Approver 6",
  },
  {
    requestId: "REQ7",
    requestType: "Request Type 7",
    createdBy: "Created By 7",
    createdUser: "Created User 7",
    status: "Rejected by TM",
    projectedCost: 705,
    createdDatetime: "2023-05-31T16:56:03.860Z",
    sbu: "SBU 7",
    plant: "Plant 7",
    department: "Department 7",
    from: "From Location 7",
    to: "To Location 7",
    departureDateTime: "2023-05-31T16:56:03.860Z",
    returnDateTime: "2023-05-31T16:56:03.860Z",
    passengerCount: 7,
    preferredVehicle: "Bus",
    package: false,
    cubicMeterage: 8.16,
    instruction: "Instruction 7",
    purpose: "Purpose 7",
    approver: "Approver 7",
  },
  {
    requestId: "REQ8",
    requestType: "Request Type 8",
    createdBy: "Created By 8",
    createdUser: "Created User 8",
    status: "Approved by TM",
    projectedCost: 488,
    createdDatetime: "2023-05-31T16:56:03.860Z",
    sbu: "SBU 8",
    plant: "Plant 8",
    department: "Department 8",
    from: "From Location 8",
    to: "To Location 8",
    departureDateTime: "2023-05-31T16:56:03.860Z",
    returnDateTime: "2023-05-31T16:56:03.860Z",
    passengerCount: 6,
    preferredVehicle: "Van",
    package: true,
    cubicMeterage: 8.57,
    instruction: "Instruction 8",
    purpose: "Purpose 8",
    approver: "Approver 8",
  },
  {
    requestId: "REQ9",
    requestType: "Request Type 9",
    createdBy: "Created By 9",
    createdUser: "Created User 9",
    status: "Pending",
    projectedCost: 85,
    createdDatetime: "2023-05-31T16:56:03.860Z",
    sbu: "SBU 9",
    plant: "Plant 9",
    department: "Department 9",
    from: "From Location 9",
    to: "To Location 9",
    departureDateTime: "2023-05-31T16:56:03.860Z",
    returnDateTime: "2023-05-31T16:56:03.860Z",
    passengerCount: 4,
    preferredVehicle: "Car",
    package: false,
    cubicMeterage: 6.77,
    instruction: "Instruction 9",
    purpose: "Purpose 9",
    approver: "Approver 9",
  },
  {
    requestId: "REQ10",
    requestType: "Request Type 10",
    createdBy: "Created By 10",
    createdUser: "Created User 10",
    status: "Rejected by TM",
    projectedCost: 448,
    createdDatetime: "2023-05-31T16:56:03.860Z",
    sbu: "SBU 10",
    plant: "Plant 10",
    department: "Department 10",
    from: "From Location 10",
    to: "To Location 10",
    departureDateTime: "2023-05-31T16:56:03.860Z",
    returnDateTime: "2023-05-31T16:56:03.860Z",
    passengerCount: 1,
    preferredVehicle: "Bus",
    package: true,
    cubicMeterage: 7.23,
    instruction: "Instruction 10",
    purpose: "Purpose 10",
    approver: "Approver 10",
  },
  {
    requestId: "REQ11",
    requestType: "Request Type 11",
    createdBy: "Created By 11",
    createdUser: "Created User 11",
    status: "Approved by TM",
    projectedCost: 496,
    createdDatetime: "2023-05-31T16:56:03.861Z",
    sbu: "SBU 11",
    plant: "Plant 11",
    department: "Department 11",
    from: "From Location 11",
    to: "To Location 11",
    departureDateTime: "2023-05-31T16:56:03.861Z",
    returnDateTime: "2023-05-31T16:56:03.861Z",
    passengerCount: 6,
    preferredVehicle: "Van",
    package: false,
    cubicMeterage: 2.67,
    instruction: "Instruction 11",
    purpose: "Purpose 11",
    approver: "Approver 11",
  },
  {
    requestId: "REQ12",
    requestType: "Request Type 12",
    createdBy: "Created By 12",
    createdUser: "Created User 12",
    status: "Pending",
    projectedCost: 568,
    createdDatetime: "2023-05-31T16:56:03.861Z",
    sbu: "SBU 12",
    plant: "Plant 12",
    department: "Department 12",
    from: "From Location 12",
    to: "To Location 12",
    departureDateTime: "2023-05-31T16:56:03.861Z",
    returnDateTime: "2023-05-31T16:56:03.861Z",
    passengerCount: 7,
    preferredVehicle: "Car",
    package: true,
    cubicMeterage: 4.58,
    instruction: "Instruction 12",
    purpose: "Purpose 12",
    approver: "Approver 12",
  },
  {
    requestId: "REQ13",
    requestType: "Request Type 13",
    createdBy: "Created By 13",
    createdUser: "Created User 13",
    status: "Rejected by TM",
    projectedCost: 987,
    createdDatetime: "2023-05-31T16:56:03.861Z",
    sbu: "SBU 13",
    plant: "Plant 13",
    department: "Department 13",
    from: "From Location 13",
    to: "To Location 13",
    departureDateTime: "2023-05-31T16:56:03.861Z",
    returnDateTime: "2023-05-31T16:56:03.861Z",
    passengerCount: 5,
    preferredVehicle: "Bus",
    package: false,
    cubicMeterage: 7.64,
    instruction: "Instruction 13",
    purpose: "Purpose 13",
    approver: "Approver 13",
  },
  {
    requestId: "REQ14",
    requestType: "Request Type 14",
    createdBy: "Created By 14",
    createdUser: "Created User 14",
    status: "Approved by TM",
    projectedCost: 646,
    createdDatetime: "2023-05-31T16:56:03.861Z",
    sbu: "SBU 14",
    plant: "Plant 14",
    department: "Department 14",
    from: "From Location 14",
    to: "To Location 14",
    departureDateTime: "2023-05-31T16:56:03.861Z",
    returnDateTime: "2023-05-31T16:56:03.861Z",
    passengerCount: 6,
    preferredVehicle: "Van",
    package: true,
    cubicMeterage: 3.78,
    instruction: "Instruction 14",
    purpose: "Purpose 14",
    approver: "Approver 14",
  },
  {
    requestId: "REQ15",
    requestType: "Request Type 15",
    createdBy: "Created By 15",
    createdUser: "Created User 15",
    status: "Pending",
    projectedCost: 17,
    createdDatetime: "2023-05-31T16:56:03.861Z",
    sbu: "SBU 15",
    plant: "Plant 15",
    department: "Department 15",
    from: "From Location 15",
    to: "To Location 15",
    departureDateTime: "2023-05-31T16:56:03.861Z",
    returnDateTime: "2023-05-31T16:56:03.861Z",
    passengerCount: 7,
    preferredVehicle: "Car",
    package: false,
    cubicMeterage: 6.44,
    instruction: "Instruction 15",
    purpose: "Purpose 15",
    approver: "Approver 15",
  },
  {
    requestId: "REQ16",
    requestType: "Request Type 16",
    createdBy: "Created By 16",
    createdUser: "Created User 16",
    status: "Rejected by TM",
    projectedCost: 603,
    createdDatetime: "2023-05-31T16:56:03.861Z",
    sbu: "SBU 16",
    plant: "Plant 16",
    department: "Department 16",
    from: "From Location 16",
    to: "To Location 16",
    departureDateTime: "2023-05-31T16:56:03.861Z",
    returnDateTime: "2023-05-31T16:56:03.861Z",
    passengerCount: 3,
    preferredVehicle: "Bus",
    package: true,
    cubicMeterage: 8.38,
    instruction: "Instruction 16",
    purpose: "Purpose 16",
    approver: "Approver 16",
  },
  {
    requestId: "REQ17",
    requestType: "Request Type 17",
    createdBy: "Created By 17",
    createdUser: "Created User 17",
    status: "Approved by TM",
    projectedCost: 587,
    createdDatetime: "2023-05-31T16:56:03.861Z",
    sbu: "SBU 17",
    plant: "Plant 17",
    department: "Department 17",
    from: "From Location 17",
    to: "To Location 17",
    departureDateTime: "2023-05-31T16:56:03.861Z",
    returnDateTime: "2023-05-31T16:56:03.861Z",
    passengerCount: 0,
    preferredVehicle: "Van",
    package: false,
    cubicMeterage: 9.28,
    instruction: "Instruction 17",
    purpose: "Purpose 17",
    approver: "Approver 17",
  },
  {
    requestId: "REQ18",
    requestType: "Request Type 18",
    createdBy: "Created By 18",
    createdUser: "Created User 18",
    status: "Pending",
    projectedCost: 756,
    createdDatetime: "2023-05-31T16:56:03.861Z",
    sbu: "SBU 18",
    plant: "Plant 18",
    department: "Department 18",
    from: "From Location 18",
    to: "To Location 18",
    departureDateTime: "2023-05-31T16:56:03.861Z",
    returnDateTime: "2023-05-31T16:56:03.861Z",
    passengerCount: 3,
    preferredVehicle: "Car",
    package: true,
    cubicMeterage: 4.24,
    instruction: "Instruction 18",
    purpose: "Purpose 18",
    approver: "Approver 18",
  },
  {
    requestId: "REQ19",
    requestType: "Request Type 19",
    createdBy: "Created By 19",
    createdUser: "Created User 19",
    status: "Rejected by TM",
    projectedCost: 813,
    createdDatetime: "2023-05-31T16:56:03.861Z",
    sbu: "SBU 19",
    plant: "Plant 19",
    department: "Department 19",
    from: "From Location 19",
    to: "To Location 19",
    departureDateTime: "2023-05-31T16:56:03.861Z",
    returnDateTime: "2023-05-31T16:56:03.861Z",
    passengerCount: 5,
    preferredVehicle: "Bus",
    package: false,
    cubicMeterage: 2.86,
    instruction: "Instruction 19",
    purpose: "Purpose 19",
    approver: "Approver 19",
  },
  {
    requestId: "REQ20",
    requestType: "Request Type 20",
    createdBy: "Created By 20",
    createdUser: "Created User 20",
    status: "Approved by TM",
    projectedCost: 987,
    createdDatetime: "2023-05-31T16:56:03.861Z",
    sbu: "SBU 20",
    plant: "Plant 20",
    department: "Department 20",
    from: "From Location 20",
    to: "To Location 20",
    departureDateTime: "2023-05-31T16:56:03.861Z",
    returnDateTime: "2023-05-31T16:56:03.861Z",
    passengerCount: 5,
    preferredVehicle: "Van",
    package: true,
    cubicMeterage: 6.65,
    instruction: "Instruction 20",
    purpose: "Purpose 20",
    approver: "Approver 20",
  },
];

export const PACKAGE_TYPES = [
  {
    id: 1,
    description: "Greige fabric",
  },
  {
    id: 2,
    description: "Dyed/Finished fabric",
  },
  {
    id: 3,
    description: "Finished Accessories",
  },
  {
    id: 4,
    description: "Semi-finished accessories",
  },
  {
    id: 5,
    description: "Finished garments",
  },
  {
    id: 6,
    description: " Semi-finished garments",
  },
  {
    id: 7,
    description: "Documents",
  },
  {
    id: 8,
    description: "others",
  },
];

export const DEFAULT_VEHICLE_DATA_LIST = [
  {
    documentName: "LicenseDoc Prakash.pdf",
    issuedDate: "2021-05-31",
    expiryDate:"2024-05-31"
  },
  {
    documentName: "Insurance Document.pdf",
    issuedDate: "2021-05-31",
    expiryDate:"2024-05-31"
  }
]

export const DEFAULT_DS_TRANSPORTATION_LIST = [
  {
    ds_template_id:"1",
    sbu:"MAS Corporate",
    plant:"Production Department",
    shiftGroup:"Night",
    passengerCount:5,
    startLocation:"Biyagama",
    mainLocation:"Colombo",
    vehicleType:"Van",
    licensePlateNumber:"45232",
    assignedDriverName:"Tom",
    transportCompany:"XYZ",
    allocatedDateTime:"2023-05-31",
    projectedMileage:"100",
    projectedCost:"10000"
  },
  {
  ds_template_id:"2",
    sbu:"HR Department",
    plant:"Headquarters",
    shiftGroup:"Day",
    passengerCount:3,
    startLocation:"Colombo",
    mainLocation:"Galle",
    vehicleType:"Car",
    licensePlateNumber:"78456",
    assignedDriverName:"Jane",
    transportCompany:"ABC",
    allocatedDateTime:"2023-06-15",
    projectedMileage:"150",
    projectedCost:"8000"
  }
]

export const sriLankanDistricts = [
  { id: 1, name: "Colombo", province: "Western Province" },
  { id: 2, name: "Gampaha", province: "Western Province" },
  { id: 3, name: "Kalutara", province: "Western Province" },
  { id: 4, name: "Kandy", province: "Central Province" },
  { id: 5, name: "Matale", province: "Central Province" },
  { id: 6, name: "Nuwara Eliya", province: "Central Province" },
  { id: 7, name: "Galle", province: "Southern Province" },
  { id: 8, name: "Matara", province: "Southern Province" },
  { id: 9, name: "Hambantota", province: "Southern Province" },
  { id: 10, name: "Jaffna", province: "Northern Province" },
  { id: 11, name: "Kilinochchi", province: "Northern Province" },
  { id: 12, name: "Mannar", province: "Northern Province" },
  { id: 13, name: "Mullaitivu", province: "Northern Province" },
  { id: 14, name: "Vavuniya", province: "Northern Province" },
  { id: 15, name: "Trincomalee", province: "Eastern Province" },
  { id: 16, name: "Batticaloa", province: "Eastern Province" },
  { id: 17, name: "Ampara", province: "Eastern Province" },
  { id: 18, name: "Badulla", province: "Uva Province" },
  { id: 19, name: "Monaragala", province: "Uva Province" },
  { id: 20, name: "Ratnapura", province: "Sabaragamuwa Province" },
  { id: 21, name: "Kegalle", province: "Sabaragamuwa Province" },
  { id: 22, name: "Kurunegala", province: "North Western Province" },
  { id: 23, name: "Puttalam", province: "North Western Province" },
  { id: 24, name: "Anuradhapura", province: "North Central Province" },
  { id: 25, name: "Polonnaruwa", province: "North Central Province" }
];

export const CurrencyTypes=[
  { id: 1, name: "LKR",},
  { id: 2, name: "USD",},
]

export const ownerTypes=[
  { id: 0, name: "By Me",},
  { id: 1, name: "By All",},
]












// export const sriLankanDistrict = [
//   {
//       provinceId: 1,
//       province: "Western Province",
//       districts: [
//           { id: 1, name: "Colombo" },
//           { id: 2, name: "Gampaha" },
//           { id: 3, name: "Kalutara" }
//       ]
//   },
//   {
//       provinceId: 2,
//       province: "Central Province",
//       districts: [
//           { id: 4, name: "Kandy" },
//           { id: 5, name: "Matale" },
//           { id: 6, name: "Nuwara Eliya" }
//       ]
//   },
//   {
//       provinceId: 3,
//       province: "Southern Province",
//       districts: [
//           { id: 7, name: "Galle" },
//           { id: 8, name: "Matara" },
//           { id: 9, name: "Hambantota" }
//       ]
//   },
//   {
//       provinceId: 4,
//       province: "Northern Province",
//       districts: [
//           { id: 10, name: "Jaffna" },
//           { id: 11, name: "Kilinochchi" },
//           { id: 12, name: "Mannar" },
//           { id: 13, name: "Mullaitivu" },
//           { id: 14, name: "Vavuniya" }
//       ]
//   },
//   {
//       provinceId: 5,
//       province: "Eastern Province",
//       districts: [
//           { id: 15, name: "Trincomalee" },
//           { id: 16, name: "Batticaloa" },
//           { id: 17, name: "Ampara" }
//       ]
//   },
//   {
//       provinceId: 6,
//       province: "Uva Province",
//       districts: [
//           { id: 18, name: "Badulla" },
//           { id: 19, name: "Monaragala" }
//       ]
//   },
//   {
//       provinceId: 7,
//       province: "Sabaragamuwa Province",
//       districts: [
//           { id: 20, name: "Ratnapura" },
//           { id: 21, name: "Kegalle" }
//       ]
//   },
//   {
//       provinceId: 8,
//       province: "North Western Province",
//       districts: [
//           { id: 22, name: "Kurunegala" },
//           { id: 23, name: "Puttalam" }
//       ]
//   },
//   {
//       provinceId: 9,
//       province: "North Central Province",
//       districts: [
//           { id: 24, name: "Anuradhapura" },
//           { id: 25, name: "Polonnaruwa" }
//       ]
//   }

// ];