import { AxiosResponse } from "axios"
import { axiosPrivateInstance } from "."
import { AllPlantBriefDto, DriverListDto, EditDriverDto, GetVehicleNumberParamDto, ModifyDriverDto, NewDriverPayloadDto, VehicleNumberDto,
     VehicleTypesDto, ViewDriverDetailsDto } from "../utilities/models"
import { FileDriverResponseDto, FileUploadDrivertDto, GetDriverDocumentParmDto, getDriversSummaryParam } from "../utilities/models/driver.model"


const viewDriver = (id: number):  Promise<AxiosResponse<ViewDriverDetailsDto>> => {
    return axiosPrivateInstance.get(`/api/Driver/ViewDriverDetails?driverId=${id}`)
}

const createDriver = (payload: NewDriverPayloadDto):  Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.post('/api/Driver/AddDriverDetails', payload)
}

const editDriver = (payload: EditDriverDto):  Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.put(`/api/Driver/UpdateDriverDetails?driverId=${payload.EditDriverDtoParm.driverId}`, payload.EditDriverDtoBody)
}

const modifyDriverVehicle = (payload: ModifyDriverDto):  Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.post(`/api/Driver/ModifyDriverVehicles`, payload)
}

const getDrivers = (payload:getDriversSummaryParam): Promise<AxiosResponse<DriverListDto[]>> => {
    return axiosPrivateInstance.get(`/api/Driver/GetDriverDetails?pageNumber=${payload.pageNumber}&pagination=${payload.pagination}`)
}

const deleteDriver = (id: number):  Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.put(`/api/Driver/DeleteDriverDetails?driverId=${id}`)
}

const getPlantList = (): Promise<AxiosResponse<AllPlantBriefDto[]>> => {
    return axiosPrivateInstance.get(`/api/Driver/GetAllPlantList`)
}

const getVehicleTypesByPlant = (id: number): Promise<AxiosResponse<VehicleTypesDto[]>> => {
    return axiosPrivateInstance.get(`/api/Driver/GetPlantVehicleTypes?plantId=${id}`)
}

const getVehicleNumbersByType = (p: GetVehicleNumberParamDto): Promise<AxiosResponse<VehicleNumberDto[]>> => {
    return axiosPrivateInstance.get(`/api/Driver/GetPlantVehicleNumber?vehicleTypeId=${p.vehicleTypeId}&plantId=${p.plantId}`)
}

const getVehicleNumbersByDriver = (nic: string): Promise<AxiosResponse<VehicleNumberDto[]>> => {
    return axiosPrivateInstance.get(`/api/Driver/GetDriverVehicleNumbers?NIC=${nic}`)
}


export const driverService = {
    viewDriver,
    editDriver,
    modifyDriverVehicle,
    createDriver,
    getDrivers,
    deleteDriver,
    getPlantList,
    getVehicleTypesByPlant,
    getVehicleNumbersByType,
    getVehicleNumbersByDriver,
}