import React, { useEffect, useState } from 'react'
import { AppLayout } from '../../templates'
import { DriverManagementGrid } from '../../components/DriverManagement'
import { ApplicationStateDto, DriverListDto, SortMetaDto, getDriversSummaryParam } from '../../utilities/models'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { driverActions } from '../../redux/actions/driver.action'
import { APP_ACTION_STATUS, APP_ROUTES, APP_TABLE_CONFIGS, DRIVER_SCREEN_MODES } from '../../utilities/constants'
import { ConfirmationDialog } from '../../components'

const DriverManagement = () => {
  const INITIAL_SORT_META: SortMetaDto = {
    field: "",
    asc: false,
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
  const [sortMeta, setSortMeta] = useState<SortMetaDto>(INITIAL_SORT_META);
  const [filteredList, setFilteredList] = useState<DriverListDto[]>([])
  const [isFiltered, setIsFiltered] = useState(false)
  const [selectedDriverId, setSelectedDriverId] = useState(-1)
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false)

  const getDriversListResponse = useSelector((state: ApplicationStateDto) => state.driver.getDrivers)
  const deleteDriverResponse = useSelector((state: ApplicationStateDto) => state.driver.deleteDriver)

  useEffect(() => {
    if (getDriversListResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (getDriversListResponse.data.length > 0)
        setFilteredList(getDriversListResponse.data)
      else setFilteredList([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDriversListResponse.status])

  useEffect(() => {
    dispatch(driverActions.getDriversClear())
    getDriversList(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE, 1)
  },[])

  useEffect(() => {
    if (deleteDriverResponse.status === APP_ACTION_STATUS.SUCCESS) {
      getDriversList(rowsPerPage, 1)
      dispatch(driverActions.deleteDriverClear())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDriverResponse.status])

  const getDriversList = async (r: number, p: number) => {
    const _param: getDriversSummaryParam = { pagination: r, pageNumber: p, };
    dispatch(driverActions.getDrivers(_param))
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    getDriversList(rowsPerPage, newPage + 1)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    getDriversList(+event.target.value, 1)
    setPage(0)
  }

  const onClearFilter = () => {
    setIsFiltered(false)
    setFilteredList(getDriversListResponse.data)
  }

  const onSelectDriverForRemove = (id: number) => {
    setSelectedDriverId(id)
    setIsOpenConfirmation(true)
  }

  const onRemoveDriver = (con: boolean) => {
    setIsOpenConfirmation(false)
    if (con) {
      dispatch(driverActions.deleteDriver(selectedDriverId))
    }
  }

  const onNavigate = (mode: string, id: number) => {
    if (mode === DRIVER_SCREEN_MODES.CREATE)
      navigate(APP_ROUTES.CREATE_DRIVER, { state: { mode: mode } })
    if (mode === DRIVER_SCREEN_MODES.EDIT)
      navigate(APP_ROUTES.EDIT_DRIVER, { state: { mode: mode, id: id } })
    if (mode === DRIVER_SCREEN_MODES.VIEW)
      navigate(APP_ROUTES.VIEW_DRIVER, { state: { mode: mode, id: id } })
  }

  const onSortHandle = (col: string) => {
    const sorted = filteredList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setFilteredList(sorted);
  };

  const onFilterHandle = (col: string, value: string) => {
    setIsFiltered(true)
    setPage(0)
    const _list = getDriversListResponse.data
    const filtered = _list.filter((item) => {
      const _value = (item as any)[col];

      if (typeof _value === "boolean") {
        return _value ? value === "Yes" : value === "No";
      }
      return _value === value;
    });

    setFilteredList(filtered);
  };

  const getFilterList = (col: string): string[] => {
    if (!getDriversListResponse.isLoading) {
      const _list = getDriversListResponse.data
      return _list
        .map((item) => {
          const value = (item as any)[col];
          if (typeof value === "boolean") {
            return value ? "Yes" : "No";
          }
          return value;
        })
        .filter((value, index, array) => array.indexOf(value) === index);
    }
    else return []
  };

  return (
    <React.Fragment>
      <AppLayout componentTitle="Driver Management">
        <section className="page-root">
          <DriverManagementGrid
            page={page}
            rowsPerPage={rowsPerPage}
            onHandleChangePage={handleChangePage}
            onHandleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={getDriversListResponse.isLoading}
            filteredList={filteredList || []}
            onSortHandle={onSortHandle}
            onFilterHandle={onFilterHandle}
            getFilterList={getFilterList}
            onClearFilter={onClearFilter}
            isFiltered={isFiltered}
            navigateTo={onNavigate}
            onSelectDriverForRemove={onSelectDriverForRemove}
          />
          <ConfirmationDialog
            isOpenConfirmationDialog={isOpenConfirmation}
            onCallback={onRemoveDriver}
            title="Delete Driver"
            content="Do you want to delete this driver ?"
            confirmButtonTitle="Yes"
            cancelButtonTitle="No"
          />
        </section>
      </AppLayout>
    </React.Fragment>
  )
}

export default DriverManagement
