import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { FuelDetailsFormDto, StateObjectDto, VehicleNumberBriefDto,} from "../../../utilities/models";
import { AppSkeleton, CustomAutocomplete, CustomButton, CustomDatePicker, CustomStepper,} from "../../Shared";
import { StyledTextField } from "../../../assets/theme/theme";
import { FUEL_CATEGORY } from "../../../utilities/constants/app.constants";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import styles from './AddBillingDetails.module.scss'

const BillingDetails: React.FC<{
  helperText: boolean;
  isLoading: boolean;
  billInformationForm: FuelDetailsFormDto;
  handleInputFocus(property: string, section: string): void;
  onInputHandleChangeBillInformation(property: string, section: string): void;
  vehicleNumberRes:StateObjectDto<VehicleNumberBriefDto[]>;
  onSearchValueChange(property: string, value: any): void;
  billUploadResponse: StateObjectDto<any>;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fileString: string;
  onUploadFile(): void;
  handleRemove():void;
  selectedFile: File | any;
}> = (props) => {

  const _vehicleNo = props.billInformationForm.vehicleNo;
  const _fuelType= props.billInformationForm.fuelType;
  const _fuelPrice = props.billInformationForm.fuelPrice;
  const _billedAmount = props.billInformationForm.billedAmount;
  const _billedDate = props.billInformationForm.billedDate;
  const _literCount = props.billInformationForm.literCount;
  const _kmCount = props.billInformationForm.kmCount; 
  const _excelDocument = props.billInformationForm.billFile;
  const _vehicleNumbers = props.vehicleNumberRes !== undefined && props.vehicleNumberRes?.data?.length > 0 ? props.vehicleNumberRes.data : []

  return (
    <CustomStepper stepNumber={1} stepTitle="Billing Details">
      {!props.isLoading && (
        <Grid container spacing={4}>
            <Grid item xs={6}>
            <CustomAutocomplete
              freeSolo={true}
              label="Vehicle Number"
              placeholder="Select Vehicle Number"
              options={_vehicleNumbers.map((c:VehicleNumberBriefDto) => {
                return { label: c.vehicleNumber, value: c.id };
              })}
              value={_vehicleNo.value}
              error={!!_vehicleNo.error}
              disabled={_vehicleNo.disable}
              required={_vehicleNo.isRequired}
              readonly={_vehicleNo.readonly}
              helperText={props.helperText && _vehicleNo.error}
              onFocus={() => props.handleInputFocus("vehicleNo", "bill-information")}
              onChange={(event: any, value: any) => props.onInputHandleChangeBillInformation("vehicleNo", value)}
              onInputChange={(event: any, value: any) => props.onSearchValueChange('vehicleNo', value)}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomAutocomplete
              label="Fuel Type"
              placeholder="Select Fuel Type"
              options={FUEL_CATEGORY.map((t) => { return { label: t.label, value: t.value } })}   
              value={_fuelType.value}
              error={!!_fuelType.error}
              disabled={_fuelType.disable}
              required={_fuelType.isRequired}
              readonly={_fuelType.readonly}
              helperText={props.helperText && _fuelType.error}
              onFocus={() => props.handleInputFocus("fuelType", "bill-information")}
              onChange={(event: any, value: any) => props.onInputHandleChangeBillInformation("fuelType", value)}
            />
          </Grid>
        
          <Grid item xs={6}>
            <StyledTextField
              fullWidth
              label="Fule Price (LKR)"
              placeholder="Fule Price"
              size="small"
              InputProps={{
                readOnly: _fuelPrice.readonly,
              }}
              value={_fuelPrice.value}
              error={!!_fuelPrice.error}
              disabled={_fuelPrice.disable}
              required={_fuelPrice.isRequired}
              helperText={props.helperText && _fuelPrice.error}
              onFocus={() => props.handleInputFocus("fuelPrice", "bill-information")}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChangeBillInformation("fuelPrice", event.target.value)}
            />
          </Grid>
            <Grid item xs={6}>
            <StyledTextField
              fullWidth
              className={styles.StyledTextField}
              label="Bill Amount (LKR)"
              placeholder="Enter Bill Amount"
              size="small"
              InputProps={{
                readOnly: _billedAmount.readonly,
                type:'number'
              }}
              value={_billedAmount.value}
              error={!!_billedAmount.error}
              disabled={_billedAmount.disable}
              required={_billedAmount.isRequired}
              helperText={props.helperText && _billedAmount.error}
              onFocus={() =>props.handleInputFocus("billedAmount", "bill-information")}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChangeBillInformation("billedAmount", event.target.value)}
            />
          </Grid>
         
          <Grid item xs={6}>
              <CustomDatePicker
                label="Billing Date"
                placeholder="Select Billing date"
                multiple={false}
                value={_billedDate.value}
                maxDate={new Date()}
                error={!!_billedDate.error}
                disabled={_billedDate.disable}// || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                readOnly={_billedDate.readonly}// || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                required={_billedDate.isRequired}
                helperText={props.helperText && _billedDate.error}
                onFocus={() => props.handleInputFocus("billedDate", "bill-information")}
                onChange={(value: any) => props.onInputHandleChangeBillInformation("billedDate",value)}
              />
            </Grid>
          <Grid item xs={6}>
            <StyledTextField
              fullWidth
              label="Liter Count (L)"
              placeholder="Fule Price"
              size="small"
              InputProps={{
                readOnly: _literCount.readonly,
              }}
              value={_literCount.value}
              error={!!_literCount.error}
              disabled={_literCount.disable}
              required={_literCount.isRequired}
              helperText={props.helperText && _literCount.error}
              onFocus={() => props.handleInputFocus("literCount", "bill-information")}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.onInputHandleChangeBillInformation("literCount", event.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              fullWidth
              label="Number Of KM"
              placeholder="Fule Price"
              size="small"
              InputProps={{
                readOnly: _kmCount.readonly,
                type:'number'
              }}
              value={_kmCount.value}
              error={!!_kmCount.error}
              disabled={_kmCount.disable}
              required={_kmCount.isRequired}
              helperText={props.helperText && _kmCount.error}
              onFocus={() => props.handleInputFocus("kmCount", "bill-information")}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.onInputHandleChangeBillInformation("kmCount", event.target.value)}
            />
          </Grid>
            <Grid item xs={0} sx={{ display: "none" }}>
              <input
                type="file"
                required
                accept="image/png, image/jpeg, image/jpg"
                id="outlined-required"
                style={{ display: 'none', border:'red' }}
                onChange={props.handleFileChange}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Box sx={{ display: "flex" }}>
                <Grid item xs={12} md={4}>
                  <label htmlFor="outlined-required">
                    <Button style={{ color: "white", border:"white 1px solid", fontSize:"0.8rem" }} component="span">
                      Bill Upload
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={12} md={8}>
                    {(props.selectedFile !== null) ? (
                      <Box sx={{ display: "flex" }}>
                        <Typography>Selected File: {props.selectedFile?.name}</Typography>
                      </Box>
                    ) : (_excelDocument.value !== '') ? (
                      <Box sx={{ display: "flex" }}>
                        <Typography>Uploaded File: {_excelDocument.value}</Typography>
                      </Box>
                    ) : (
                      <Box sx={{ display: "flex" }}>
                        <Typography></Typography>
                      </Box>
                    )}
                  </Grid>
              </Box>
            </Grid>

            <Grid item xs={2} md={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CustomButton isLoading={props.billUploadResponse.isLoading} onClick={props.onUploadFile} text="Upload" bgColor="#bfbfbf" />
                {/* <Button style={{ color: "white" }} startIcon={<CloudDownloadIcon style={{ color: "white" }} />} onClick={props.handleDownload} /> */}
                {/* <Button style={{ color: "white"}} startIcon={<DeleteForeverRoundedIcon />} onClick={props.handleRemove} /> */}
              </div>
            </Grid>
        </Grid>
      )}
      {props.isLoading && (
        <AppSkeleton
          numOfRows={3}
          numOfColumns={2}
          columnWidth={50}
          height={30}
          tag="stepper"
        />
      )}
    </CustomStepper>
  );
};

export default BillingDetails;
