import React from 'react'
import { AppSkeleton, CustomAutocomplete, CustomButton, CustomHeaderCell, CustomStepper } from '../../Shared'
import { AllPlantBriefDto, AssignVehicleFormDto, AssignVehicleListDto, GetVehiclePriceListDto, StateObjectDto, VehicleNumberDto, VehicleTypesDto, vehicleCostFormDto } from '../../../utilities/models'
import { Box, Grid, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material'
import { StyledTableCell, StyledTextField } from '../../../assets/theme/theme'
import { EditOutlined } from '@mui/icons-material'
import style from './vehicleCostDetails.module.scss'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import NoCrashOutlinedIcon from '@mui/icons-material/NoCrashOutlined';
import { APP_TABLE_CONFIGS, DRIVER_SCREEN_MODES, TRANSPORT_COMPANY_MODE } from '../../../utilities/constants'
import { number } from 'echarts'

const VehicleCostDetails: React.FC<{
    screenMode: string
    vehicleCostForm: vehicleCostFormDto
    vehiclePriceList: GetVehiclePriceListDto[]
    handleInputFocus(property: string, section: string): void
    onInputHandleChangeVehicleCost(property: string, section: string): void
    helperText: boolean
    onSelectVehicle(id: number, action: string): void;
    vehicleTypesResponse: StateObjectDto<VehicleTypesDto[]>
    isEditVehicle: boolean
    onAddPrice(): void
    onEditPrice(): void
    onClearVehicleCostForm(): void
}> = (props) => {

    const _vehicleType = props.vehicleCostForm.vehicleType
    const _vehiclePrice = props.vehicleCostForm.priceKm
    const _allVehicleTypes = props.vehicleTypesResponse !== undefined && props.vehicleTypesResponse?.data?.length > 0 ? props.vehicleTypesResponse.data : []

    return (
        <CustomStepper stepNumber={5} stepTitle="Vehicle Type">
            <>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <CustomAutocomplete
                            label="Preferred vehicle type"
                            placeholder='Select preferred vehicle type'
                            onFocus={() => props.handleInputFocus('vehicleType', 'VCI')}
                            options={_allVehicleTypes.map((v: VehicleTypesDto) => { return { label: v.description, value: v.id } })}
                            value={_vehicleType.value}
                            error={!!_vehicleType.error}
                            disabled={_vehicleType.disable}
                            required={_vehicleType.isRequired}
                            readonly={_vehicleType.readonly}
                            helperText={props.helperText && _vehicleType.error}
                            onChange={(event: any, value: any) => props.onInputHandleChangeVehicleCost('vehicleType', value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <StyledTextField
                            className={style.StyledTextField}
                            fullWidth
                            label="Price per km (LKR)"
                            placeholder="Enter price"
                            size="small"
                            InputProps={{
                                readOnly: _vehiclePrice.readonly,
                                type:'number',
                            }}
                            value={_vehiclePrice.value}
                            error={!!_vehiclePrice.error}
                            disabled={_vehiclePrice.disable}
                            required={_vehiclePrice.isRequired}
                            helperText={props.helperText && _vehiclePrice.error}
                            onFocus={() => props.handleInputFocus("price", "VCI")}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                               props.onInputHandleChangeVehicleCost("price", event.target.value)
                            }
                        />
                    </Grid>
                </Grid>
                <section className={style.actions}>
                 
                    <CustomButton text='Clear' textColor='black' disabled={props.screenMode === TRANSPORT_COMPANY_MODE.VIEW} bgColor='#bfbfbf' onClick={() => props.onClearVehicleCostForm()} />
                    <CustomButton text={props.isEditVehicle ? 'Edit' : 'Add'} disabled={props.screenMode === TRANSPORT_COMPANY_MODE.VIEW} border='1px solid #6e6e6e' bgColor='#282828' onClick={() => props.isEditVehicle ? props.onEditPrice() : props.onAddPrice()} />
                </section>
            </>

            <section>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <CustomHeaderCell id='id' >#</CustomHeaderCell>
                                <CustomHeaderCell id='vehicleType'>Vehicle Type</CustomHeaderCell>
                                <CustomHeaderCell id='vehicleCost'>Vehicle Price</CustomHeaderCell>
                                <CustomHeaderCell width={150} id='actions' >Actions</CustomHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.vehiclePriceList.map((p) => (
                                <TableRow key={p.id}>
                                    <StyledTableCell >{p.id}</StyledTableCell>
                                    <StyledTableCell >{p.vehicleType} </StyledTableCell>
                                    <StyledTableCell>{parseFloat(p.cost).toLocaleString('en-US')}.00 LKR</StyledTableCell>
                                    <StyledTableCell>
                                    {props.screenMode !== TRANSPORT_COMPANY_MODE.VIEW &&
                                        <Box className='layout-row'>
                                            <Box>
                                                <IconButton size='small' onClick={() => props.onSelectVehicle(p.id, "edit")}>
                                                    <Tooltip title="Edit">
                                                        <EditOutlined sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                                    </Tooltip>
                                                </IconButton>
                                            </Box>
                                            <Box>
                                                <IconButton size='small' onClick={() => props.onSelectVehicle(p.id, "delete")}>
                                                    <Tooltip title="Delete">
                                                        <DeleteOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                                    </Tooltip>
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    }
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        {props.vehiclePriceList.length === 0 &&
                            <TableBody>
                                <TableRow>
                                    <StyledTableCell align="center" colSpan={4}>No data to preview</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        }

                    </Table>
                </TableContainer>
            </section>
        </CustomStepper>
    )
}

export default VehicleCostDetails
