import { APPROVER_ACTION_TYPE, APP_ACTION_STATUS, COMMON_ACTION_TYPES } from "../../utilities/constants";
import { ApproverStateDto } from "../../utilities/models";

const INITIAL_STATE: ApproverStateDto = {
    getApprovers: {
        data: [],
        error: null,
        isLoading: false,
        status: null
    },
    getDefaultApprover: {
        data: null,
        error: null,
        isLoading: false,
        status: null
    }
}


const approverReducer = (state = INITIAL_STATE, action: any) => {
    switch ((action.type)) {
      case APPROVER_ACTION_TYPE.GET_ALL_APPROVERS_LIST + COMMON_ACTION_TYPES.REQUEST:
        return {
          ...state,
          getApprovers: {
            ...state.getApprovers,
            isLoading: true,
            status: APP_ACTION_STATUS.LOADING,
            error: null,
            data: null
          }
        }
      case APPROVER_ACTION_TYPE.GET_ALL_APPROVERS_LIST + COMMON_ACTION_TYPES.SUCCESS:
        return {
          ...state,
          getApprovers: {
            ...state.getApprovers,
            isLoading: false,
            status: APP_ACTION_STATUS.SUCCESS,
            error: null,
            data: action.data
          }
        }
      case APPROVER_ACTION_TYPE.GET_ALL_APPROVERS_LIST + COMMON_ACTION_TYPES.ERROR:
        return {
          ...state,
          getApprovers: {
            ...state.getApprovers,
            isLoading: false,
            status: APP_ACTION_STATUS.ERROR,
            error: action.error,
            data: null
          }
        }
      case APPROVER_ACTION_TYPE.GET_ALL_APPROVERS_LIST + COMMON_ACTION_TYPES.CLEAR:
        return {
          ...state,
          getApprovers: {
            ...state.getApprovers,
            isLoading: false,
            status: APP_ACTION_STATUS.INITIAL,
            error: null,
            data: null
          }
        }
      case APPROVER_ACTION_TYPE.GET_DEFAULT_APPROVER + COMMON_ACTION_TYPES.REQUEST:
        return {
          ...state,
          getDefaultApprover: {
            ...state.getDefaultApprover,
            isLoading: true,
            status: APP_ACTION_STATUS.LOADING,
            error: null,
            data: null
          }
        }
      case APPROVER_ACTION_TYPE.GET_DEFAULT_APPROVER + COMMON_ACTION_TYPES.SUCCESS:
        return {
          ...state,
          getDefaultApprover: {
            ...state.getDefaultApprover,
            isLoading: false,
            status: APP_ACTION_STATUS.SUCCESS,
            error: null,
            data: action.data
          }
        }
      case APPROVER_ACTION_TYPE.GET_DEFAULT_APPROVER + COMMON_ACTION_TYPES.ERROR:
        return {
          ...state,
          getDefaultApprover: {
            ...state.getDefaultApprover,
            isLoading: false,
            status: APP_ACTION_STATUS.ERROR,
            error: action.error,
            data: null
          }
        }
      case APPROVER_ACTION_TYPE.GET_DEFAULT_APPROVER + COMMON_ACTION_TYPES.CLEAR:
        return {
          ...state,
          getDefaultApprover: {
            ...state.getDefaultApprover,
            isLoading: false,
            status: APP_ACTION_STATUS.INITIAL,
            error: null,
            data: null
          }
        }
      default:
        return state
    }
  }
  
  export default approverReducer