import { AxiosResponse } from "axios";
import {
  PassengerBriefDto,
  PassengerDetailsByIdDto,
} from "../../utilities/models";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  COMMON_ACTION_TYPES,
  USER_ACTION_TYPE,
} from "../../utilities/constants";
import { userService } from "../../services/user.service";

function* getAllPassengers(action: { type: string; name: string }) {
  try {
    const _passengers: AxiosResponse<PassengerBriefDto[]> = yield call(
      userService.getAllPassengers,
      action.name
    );
    yield put({
      type:
        USER_ACTION_TYPE.GET_ALL_PASSENGERS_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: _passengers.data,
    });
  } catch (error) {
    yield put({
      type:
        USER_ACTION_TYPE.GET_ALL_PASSENGERS_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

  
function * getAllPassengersByDepartment (action: {type: string, id: number}) {
    try {
        const _passengers: AxiosResponse<PassengerBriefDto[]> = yield call(userService.getAllPassengersByDepartment, action.id)
        yield put({
            type: USER_ACTION_TYPE.GET_ALL_USERS_BY_DEPARTMENT + COMMON_ACTION_TYPES.SUCCESS,
            data: _passengers.data
        })
    } catch (error) {
        yield put({
            type: USER_ACTION_TYPE.GET_ALL_USERS_BY_DEPARTMENT + COMMON_ACTION_TYPES.ERROR,
            error: error
        })
    }
}

function* getPassengerById(action: { type: string, userId: number }) {
  try {
    const passenger: AxiosResponse<PassengerDetailsByIdDto> = yield call(
      userService.getPassengerById,
      action.userId
    );
    yield put({
      type:
        USER_ACTION_TYPE.GET_PASSENGER_DETAILS + COMMON_ACTION_TYPES.SUCCESS,
      data: passenger.data,
    });
  } catch (error) {
    yield put({
      type: USER_ACTION_TYPE.GET_PASSENGER_DETAILS + COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* getAllRecipients(action: { type: string; name: string }) {
  try {
    const _recipients: AxiosResponse<PassengerBriefDto[]> = yield call(
      userService.getAllRecipients,
      action.name
    );
    yield put({
      type:
        USER_ACTION_TYPE.GET_ALL_RECIPIENTS + COMMON_ACTION_TYPES.SUCCESS,
      data: _recipients.data,
    });
  } catch (error) {
    yield put({
      type:
        USER_ACTION_TYPE.GET_ALL_RECIPIENTS + COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* userSaga() {
    yield takeEvery(USER_ACTION_TYPE.GET_ALL_PASSENGERS_LIST + COMMON_ACTION_TYPES.REQUEST, getAllPassengers)
    yield takeEvery(USER_ACTION_TYPE.GET_ALL_USERS_BY_DEPARTMENT + COMMON_ACTION_TYPES.REQUEST, getAllPassengersByDepartment)
    yield takeEvery(USER_ACTION_TYPE.GET_PASSENGER_DETAILS + COMMON_ACTION_TYPES.REQUEST, getPassengerById)
    yield takeEvery(USER_ACTION_TYPE.GET_ALL_RECIPIENTS + COMMON_ACTION_TYPES.REQUEST, getAllRecipients)
}

export default userSaga;
