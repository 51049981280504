import { Box, Grid, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CustomAutocomplete, CustomButton, CustomHeaderCell, Stepper } from '../..'
import { StyledTableCell, StyledTextField } from '../../../assets/theme/theme';
import style from './passengerDetails.module.scss'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { LocationBriefDto, NewRequestPassengerDetailsDto, PassengerBriefDto, PassengerDetailsByIdDto, PassengerDetailsFormDto, StateObjectDto } from '../../../utilities/models';
import { AppSkeleton } from '../../Shared';
import { REQUEST_SCREEN_MODES } from '../../../utilities/constants';
import { EditOutlined } from '@mui/icons-material';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import PlaceAutoComplete from '../../Shared/GoogleMap/PlaceAutoComplete/PlaceAutoComplete';
const PassengerDetails: React.FC<{
  helperText: boolean
  isEdit: boolean
  screenMode: string
  passengerDetailsForm: PassengerDetailsFormDto
  onInputHandleChangePassengerDetails(property: string, value: any): void;
  onSearchValueChange(property: string, value: any): void;
  handleInputFocus(property: string, section: string): void;
  onSortHandle(col: string): void
  showAddMultipleLocationPopup(con: boolean): void
  onPushPassenger(): void
  onEditPassenger(): void
  onClearPassengerDetails(): void
  setSelectedPassengerItem(id: number): void
  onSelectPassengerForEdit(id: number): void
  allPassengerResponse: StateObjectDto<PassengerBriefDto[] | []>
  frequentLocations: LocationBriefDto[]
  passengerDetailsList: NewRequestPassengerDetailsDto[]
  passengerByIdResponse: StateObjectDto<PassengerDetailsByIdDto | null>
  onInputHandleChangeGoogleInput(property: string, value: any,section:string): void;
  switchValue:boolean;
  switchValue2:boolean;
}> = (props) => {
  const _allPassengers = props.allPassengerResponse !== undefined && props.allPassengerResponse?.data?.length > 0 ? props.allPassengerResponse.data : []

  const {ready,value,setValue,suggestions: { status, data },clearSuggestions} = usePlacesAutocomplete();

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  
  const [switchValue, setswitchValue] = useState(false)
  const [switchValue2, setswitchValue2] = useState(false)
  const [switchValue3, setswitchValue3] = useState(false)
  const [switchValue4, setswitchValue4] = useState(false)

  
  useEffect(() => {
    if(props.switchValue === false){
      setswitchValue(true)
    }
    if(props.switchValue2 === false){
      setswitchValue3(true)
    }
  }, [props.switchValue, props.switchValue2])

  useEffect(() => {
    if(props.screenMode === REQUEST_SCREEN_MODES.EDIT || props.screenMode === REQUEST_SCREEN_MODES.VIEW ||
      props.screenMode === REQUEST_SCREEN_MODES.PENDING_VEHICLE_VIEW || REQUEST_SCREEN_MODES.USE_TEMPLATE){
      if(switchValue === true){
        handleSelect(props.passengerDetailsForm.startLocation.value.location, 1)
      }
      if(switchValue3 === true){
        handleSelect(props.passengerDetailsForm.toLocation.value.location,2)
      }
    }
  },[switchValue, switchValue3])


  const handleSelect = async (address: string, typeID: number) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);

    if (typeID === 1) {
      setswitchValue(false)
      let data = { address: address, latAndLang: { lat: lat, lng: lng } }
      props.onInputHandleChangeGoogleInput("fromLocation", data, "PI")
    } else if (typeID === 2) {
      let data = { address: address, latAndLang: { lat: lat, lng: lng } }
      setswitchValue3(false)
      props.onInputHandleChangeGoogleInput("toLocation", data, "PI")
    } else if (typeID === 3) {
      let data = { address: address, latAndLang: { lat: lat, lng: lng } }
      setswitchValue2(false)
      props.onInputHandleChangeGoogleInput("intermediateLocation", data, "PI")
    }
    else if (typeID === 4) {
      setswitchValue4(false)
      let data = { address: address, latAndLang: { lat: lat, lng: lng } }
      props.onInputHandleChangeGoogleInput("dropLocation", data, "PI")
    }
  };

  const handleAddLocationClick=(value:string)=>{
    if(value==="stLocatoon"){
      if(switchValue){
        setswitchValue(false)
      }else{
        setswitchValue(true)
        
      }
    }else if(value==="intermediateLocation"){
      if(switchValue2){
        setswitchValue2(false)
      }else{
        setswitchValue2(true)
        
      }
    }else if(value==="MainDestination"){
      if(switchValue3){
        setswitchValue3(false)
      }else{
        setswitchValue3(true)
        
      }
    }
    else if(value==="dropLocation"){
      if(switchValue4){
        setswitchValue4(false)
      }else{
        setswitchValue4(true)
        
      }
    }
  }

  return (
    <Stepper stepNumber={2} stepTitle={"Passenger Details"}>
      {props.screenMode !== REQUEST_SCREEN_MODES.VIEW && (
        <>
          <section className={style.instructionSection}>
            <Typography className={style.instruction}><b>Instruction :-</b> The user must enter passengers in the order of pick-up locations as desired since the projected route shall be decided by the system through the Google Map integration as per this order.</Typography>
          </section>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <CustomAutocomplete
               freeSolo={true}
                label="Passenger name"
                placeholder='Select passenger'
                onFocus={() => props.handleInputFocus('passenger', 'PD')}
                options={_allPassengers.map((p: PassengerBriefDto) => { return { label: p.name, value: p.id } })}
                value={props.passengerDetailsForm.passenger.value}
                error={!!props.passengerDetailsForm.passenger.error}
                disabled={props.passengerDetailsForm.passenger.disable}
                readonly={props.passengerDetailsForm.passenger.readonly}
                required={props.passengerDetailsForm.passenger.isRequired}
                helperText={props.helperText && props.passengerDetailsForm.passenger.error}
                onChange={(event: any, value: any) => props.onInputHandleChangePassengerDetails('passenger', value)}
                onInputChange={(event: any, value: any) => props.onSearchValueChange('passenger', value)}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  {props.passengerByIdResponse.isLoading ?
                    <AppSkeleton numOfRows={1} numOfColumns={1} columnWidth={50} height={37} tag="formField" /> :
                    <StyledTextField
                      fullWidth
                      label="SBU name"
                      placeholder='Select SBU'
                      size='small'
                      value={props.passengerDetailsForm.sbu.value}
                      error={!!props.passengerDetailsForm.sbu.error}
                      disabled={props.passengerDetailsForm.sbu.disable}
                      required={props.passengerDetailsForm.sbu.isRequired}
                      helperText={props.helperText && props.passengerDetailsForm.sbu.error}
                      onFocus={() => props.handleInputFocus('sbu', 'PD')}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChangePassengerDetails('sbu', event.target.value)}
                    />
                  }
                </Grid>
                <Grid item xs={12} md={4}>
                  {props.passengerByIdResponse.isLoading ?
                    <AppSkeleton numOfRows={1} numOfColumns={1} columnWidth={50} height={37} tag="formField" /> :
                    <StyledTextField
                      fullWidth
                      label="Plant name"
                      placeholder='Enter Plant'
                      size='small'
                      value={props.passengerDetailsForm.plant.value}
                      error={!!props.passengerDetailsForm.plant.error}
                      disabled={props.passengerDetailsForm.plant.disable}
                      required={props.passengerDetailsForm.plant.isRequired}
                      helperText={props.helperText && props.passengerDetailsForm.plant.error}
                      onFocus={() => props.handleInputFocus('plant', 'PD')}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChangePassengerDetails('plant', event.target.value)}
                    />
                  }
                </Grid>
                <Grid item xs={12} md={4}>
                  {props.passengerByIdResponse.isLoading ?
                    <AppSkeleton numOfRows={1} numOfColumns={1} columnWidth={50} height={37} tag="formField" /> :
                    <StyledTextField
                      fullWidth
                      label="Dept name"
                      placeholder='Enter Department'
                      size='small'
                      value={props.passengerDetailsForm.department.value}
                      error={!!props.passengerDetailsForm.department.error}
                      disabled={props.passengerDetailsForm.department.disable}
                      required={props.passengerDetailsForm.department.isRequired}
                      helperText={props.helperText && props.passengerDetailsForm.department.error}
                      onFocus={() => props.handleInputFocus('department', 'PD')}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChangePassengerDetails('department', event.target.value)}
                    />
                  }
                </Grid>
              </Grid>
              </Grid>
           { !switchValue&&<Grid item xs={12} md={6}>
            <Grid container spacing={2}>
             <Grid item xs={10} md={11} >
              <CustomAutocomplete
                freeSolo={true}
                label="Start Location"
                placeholder='Select location'
                onFocus={() => props.handleInputFocus('startLocation', 'PD')}
                options={props.frequentLocations && props.frequentLocations.map((l: LocationBriefDto) => { return { label: l.location, value: l.id } })}
                value={{ label: props.passengerDetailsForm.startLocation.value.location, value: props.passengerDetailsForm.startLocation.value.id }}
                error={!!props.passengerDetailsForm.startLocation.error}
                disabled={props.passengerDetailsForm.startLocation.disable}
                readonly={props.passengerDetailsForm.startLocation.readonly}
                required={props.passengerDetailsForm.startLocation.isRequired}
                helperText={props.helperText && props.passengerDetailsForm.startLocation.error}
                onChange={(event: any, value: any) => props.onInputHandleChangePassengerDetails('startLocation', value)}
              />
            </Grid>
           
             <Grid item xs={2} md={1}>
               <IconButton aria-label="delete" size="medium"  onClick={ ()=>{handleAddLocationClick( "stLocatoon")} }>
               <AddLocationAltIcon sx={{color:"#ffffff"}} fontSize="medium"  />
              </IconButton>
             </Grid>
             </Grid>
              </Grid>}

              {switchValue&& <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
            <Grid item xs={10} md={11} >
          <PlaceAutoComplete 
            value={value}
            ready={ready}
            status={status}
            data={data}
            setValue={setValue}
            required={false}
            clearSuggestions={clearSuggestions}
            handleInput={handleInput}
            handleSelect={handleSelect}
            placeHolder={"Start Location"}
            type={1}
          />
           </Grid>
            <Grid item xs={2} md={1}>
               <IconButton aria-label="delete" size="medium"  onClick={ ()=>{handleAddLocationClick( "stLocatoon")} }>
               <AddLocationAltIcon sx={{color:"#dd2126"}} fontSize="medium" />
             </IconButton>
             </Grid>
             </Grid>
        </Grid>}

          { !switchValue2&& <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
             <Grid item xs={10} md={11} >
              <CustomAutocomplete
                 freeSolo={true}
                label="Intermediate Pickup Location"
                placeholder='Select Pickup Location'
                onFocus={() => props.handleInputFocus('intermediateLocation', 'PD')}
                options={props.frequentLocations && props.frequentLocations.map((l: LocationBriefDto) => { return { label: l.location, value: l.id } })}
                value={{ label: props.passengerDetailsForm.intermediateLocation.value.location, value: props.passengerDetailsForm.intermediateLocation.value.id }}
                error={!!props.passengerDetailsForm.intermediateLocation.error}
                disabled={props.passengerDetailsForm.intermediateLocation.disable}
                readonly={props.passengerDetailsForm.intermediateLocation.readonly}
                required={props.passengerDetailsForm.intermediateLocation.isRequired}
                helperText={props.helperText && props.passengerDetailsForm.intermediateLocation.error}
                onChange={(event: any, value: any) => props.onInputHandleChangePassengerDetails('intermediateLocation', value)}
              />
            </Grid>
            <Grid item xs={2} md={1}>
               <IconButton aria-label="delete" size="medium"  onClick={ ()=>{handleAddLocationClick( "intermediateLocation")} }>
               <AddLocationAltIcon sx={{color:"#ffffff"}} fontSize="medium"  />
              </IconButton>
             </Grid>
             </Grid>
              </Grid>}
              {switchValue2&& <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
            <Grid item xs={10} md={11} >
          <PlaceAutoComplete 
            value={value}
            ready={ready}
            status={status}
            data={data}
            setValue={setValue}
            required={false}
            clearSuggestions={clearSuggestions}
            handleInput={handleInput}
            handleSelect={handleSelect}
            placeHolder={"Intermediate Pickup Location"}
            type={3}
          />
           </Grid>
            <Grid item xs={2} md={1}>
               <IconButton aria-label="delete" size="medium"  onClick={ ()=>{handleAddLocationClick( "intermediateLocation")} }>
               <AddLocationAltIcon sx={{color:"#dd2126"}} fontSize="medium" />
             </IconButton>
             </Grid>
             </Grid>
        </Grid>}

          {!switchValue3 &&  <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
             <Grid item xs={10} md={11} >
              <CustomAutocomplete
                       freeSolo={true}
                label="Main Destination"
                placeholder='Select location'
                onFocus={() => props.handleInputFocus('toLocation', 'PD')}
                options={props.frequentLocations && props.frequentLocations.map((l: LocationBriefDto) => { return { label: l.location, value: l.id } })}
                value={{ label: props.passengerDetailsForm.toLocation.value.location, value: props.passengerDetailsForm.toLocation.value.id }}
                error={!!props.passengerDetailsForm.toLocation.error}
                disabled={props.passengerDetailsForm.toLocation.disable}
                readonly={props.passengerDetailsForm.toLocation.readonly}
                required={props.passengerDetailsForm.toLocation.isRequired}
                helperText={props.helperText && props.passengerDetailsForm.toLocation.error}
                onChange={(event: any, value: any) => props.onInputHandleChangePassengerDetails('toLocation', value)}
              />
            </Grid>
            <Grid item xs={2} md={1}>
               <IconButton aria-label="delete" size="medium"  onClick={ ()=>{handleAddLocationClick( "MainDestination")} }>
               <AddLocationAltIcon sx={{color:"#ffffff"}} fontSize="medium"  />
              </IconButton>
             </Grid>
             </Grid>
             </Grid>}
             {switchValue3&& <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
            <Grid item xs={10} md={11} >
          <PlaceAutoComplete 
            value={value}
            ready={ready}
            status={status}
            data={data}
            setValue={setValue}
            required={false}
            clearSuggestions={clearSuggestions}
            handleInput={handleInput}
            handleSelect={handleSelect}
            placeHolder={"Main Destination"}
            type={2}
          />
           </Grid>
            <Grid item xs={2} md={1}>
               <IconButton aria-label="delete" size="medium"  onClick={ ()=>{handleAddLocationClick( "MainDestination")} }>
               <AddLocationAltIcon sx={{color:"#dd2126"}} fontSize="medium" />
             </IconButton>
             </Grid>
             </Grid>
        </Grid>}




         {!switchValue4 &&   <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
             <Grid item xs={10} md={11} >
              <CustomAutocomplete
                freeSolo={true}
                label="Drop Off"
                placeholder='Select location'
                onFocus={() => props.handleInputFocus('dropLocation', 'PD')}
                options={props.frequentLocations && props.frequentLocations.map((l: LocationBriefDto) => { return { label: l.location, value: l.id } })}
                value={{ label: props.passengerDetailsForm.dropLocation.value.location, value: props.passengerDetailsForm.dropLocation.value.id }}
                error={!!props.passengerDetailsForm.dropLocation.error}
                disabled={props.passengerDetailsForm.dropLocation.disable}
                readonly={props.passengerDetailsForm.dropLocation.readonly}
                required={props.passengerDetailsForm.dropLocation.isRequired}
                helperText={props.helperText && props.passengerDetailsForm.dropLocation.error}
                onChange={(event: any, value: any) => props.onInputHandleChangePassengerDetails('dropLocation', value)}
              />
            </Grid>
            <Grid item xs={2} md={1}>
               <IconButton aria-label="delete" size="medium"  onClick={ ()=>{handleAddLocationClick( "dropLocation")} }>
               <AddLocationAltIcon sx={{color:"#ffffff"}} fontSize="medium"  />
              </IconButton>
             </Grid>
             </Grid>
             </Grid>
             }
              {switchValue4&& <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
            <Grid item xs={10} md={11} >
          <PlaceAutoComplete 
            value={value}
            ready={ready}
            status={status}
            data={data}
            setValue={setValue}
            required={false}
            clearSuggestions={clearSuggestions}
            handleInput={handleInput}
            handleSelect={handleSelect}
            placeHolder={"Drop Off"}
            type={4}
          />
           </Grid>
            <Grid item xs={2} md={1}>
               <IconButton aria-label="delete" size="medium"  onClick={ ()=>{handleAddLocationClick( "dropLocation")} }>
               <AddLocationAltIcon sx={{color:"#dd2126"}} fontSize="medium" />
             </IconButton>
             </Grid>
             </Grid>
        </Grid>}
          
          </Grid>

          <section className={style.actions}>
            {/* <CustomButton text={!props.isEdit ? 'Add Multiple Locations' : 'Edit Multiple Locations'} border='0px solid #6e6e6e' bgColor='transparent' isShadow='none' textDecoration='underline' onClick={() => props.showAddMultipleLocationPopup(true)} /> */}
            <CustomButton text='Clear' textColor='black' bgColor='#bfbfbf' onClick={props.onClearPassengerDetails} />
            <CustomButton text={!props.isEdit ? 'Add Passenger' : 'Edit Passenger'} border='1px solid #6e6e6e' bgColor='#282828' onClick={!props.isEdit ? props.onPushPassenger : props.onEditPassenger} />
          </section>
        </>
      )}

      <section className={style.gridSection}>
        <TableContainer component={Paper} className={style.grid}>
          <Table className={style.table}>
            <TableHead>
              <TableRow>
                <CustomHeaderCell id='passenger' sortable onSort={props.onSortHandle} >Passenger Name</CustomHeaderCell>
                <CustomHeaderCell id='department' sortable onSort={props.onSortHandle} >Department/SBU</CustomHeaderCell>
                <CustomHeaderCell id='startLocation' >Start From</CustomHeaderCell>
                <CustomHeaderCell id='intermediateLocation' >Intermediate Location</CustomHeaderCell>
                <CustomHeaderCell id='toLocation' >Main Destination</CustomHeaderCell>
                <CustomHeaderCell id='dropLocation' sortable onSort={props.onSortHandle} >Drop off at</CustomHeaderCell>
                {props.screenMode !== REQUEST_SCREEN_MODES.VIEW &&
                  <CustomHeaderCell width={100} id='actions' >Actions</CustomHeaderCell>
                }
              </TableRow>
            </TableHead>
            {props.passengerDetailsList && props.passengerDetailsList.length > 0 &&
              <TableBody>
                {props.passengerDetailsList.map((p) => (
                  <TableRow key={p.id}>
                    <StyledTableCell >{p.passenger.name}</StyledTableCell>
                    <StyledTableCell >{p.department}</StyledTableCell>
                    <StyledTableCell >{p.startLocation.location}</StyledTableCell>
                    <StyledTableCell >{p.intermediateLocation?.location ?? "-"}</StyledTableCell>
                    <StyledTableCell >{p.toLocation?.location ?? "-"}</StyledTableCell>
                    <StyledTableCell >{p.dropLocation?.location ?? '-'}</StyledTableCell>
                    {props.screenMode !== REQUEST_SCREEN_MODES.VIEW &&
                      <StyledTableCell style={{ backgroundColor: '#282828' }}>
                        <Box className='layout-row'>
                          <Box>
                            <IconButton size='small' onClick={() => props.setSelectedPassengerItem(p.id)}>
                              <Tooltip title="Delete">
                                <DeleteOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                              </Tooltip>
                            </IconButton>
                          </Box>
                          <Box>
                            <IconButton size='small' onClick={() => props.onSelectPassengerForEdit(p.id)}>
                              <Tooltip title="Edit">
                                <EditOutlined sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                              </Tooltip>
                            </IconButton>
                          </Box>
                        </Box>
                      </StyledTableCell>
                    }
                  </TableRow>
                ))}
              </TableBody>
            }
            {props.passengerDetailsList.length === 0 &&
              <TableBody>
                <TableRow>
                  <StyledTableCell align="left" colSpan={7}>No data to preview</StyledTableCell>
                </TableRow>
              </TableBody>
            }
          </Table>
        </TableContainer>
      </section>
    </Stepper>

  )
}

export default PassengerDetails
