import {  LoadScript, } from '@react-google-maps/api'
import React from 'react'
import { ENV_LOCAL } from '../../../.env/environment.local'
import Map from './Map/Map'
import { Library } from '@googlemaps/js-api-loader'

const libraries: Library[] = ['places']; 
const GoogleMap = () => {
  return (
   
     <Map/>

  )
}

export default GoogleMap