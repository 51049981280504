import { AxiosResponse } from "axios"
import { axiosPrivateInstance } from "."
import { DownloadParamLinkDto, FileResponseLinkDto, FileUploadLinkDto } from "../utilities/models/link.model"

const uploadMainFile = (payload: FileUploadLinkDto): Promise<AxiosResponse<string>> => {
    const payloadFormData = new FormData()
    payloadFormData.append('file', payload.attachment)
    return axiosPrivateInstance.post(`/api/DocumentUpload/UploadDocuments?mainDocumentType=${payload.mainDocumentType}&documentType=${payload.documentType}&folderName=${payload.folderName}`,payloadFormData)
}

const uploadSecondaryFile = (payload: FileUploadLinkDto): Promise<AxiosResponse<string>> => {
    const payloadFormData = new FormData()
    payloadFormData.append('file', payload.attachment)
    return axiosPrivateInstance.post(`/api/DocumentUpload/UploadDocuments?mainDocumentType=${payload.mainDocumentType}&documentType=${payload.documentType}&folderName=${payload.folderName}`,payloadFormData)
}

const uploadOtherFile = (payload: FileUploadLinkDto): Promise<AxiosResponse<string>> => {
    const payloadFormData = new FormData()
    payloadFormData.append('file', payload.attachment)
    return axiosPrivateInstance.post(`/api/DocumentUpload/UploadDocuments?mainDocumentType=${payload.mainDocumentType}&documentType=${payload.documentType}&folderName=${payload.folderName}`,payloadFormData)
}

const downloadRequiredFile = (payload:DownloadParamLinkDto):Promise<AxiosResponse<FileResponseLinkDto>>=>{
    return axiosPrivateInstance.get(`api/DocumentUpload/GetRequiredDocuments?mainDocumentType=${payload.mainDocumentType}&fileName=${payload.fileName}`)
}

const removeUploadedFile = (payload:DownloadParamLinkDto):Promise<AxiosResponse<string>>=>{
    return axiosPrivateInstance.delete(`/api/DocumentUpload/RemoveDocument?mainDocumentType=${payload.mainDocumentType}&fileName=${payload.fileName}`)
}

export const linkService = {
    uploadMainFile,
    uploadSecondaryFile,
    uploadOtherFile,
    downloadRequiredFile,
    removeUploadedFile
}