import { APPROVER_ACTION_TYPE, COMMON_ACTION_TYPES } from "../../utilities/constants"

const getApprovers = (param: string) => {
    return { 
        type: APPROVER_ACTION_TYPE.GET_ALL_APPROVERS_LIST + COMMON_ACTION_TYPES.REQUEST,
        param: param
    }
}

const getDefaultApprover = (depId: number) => {
    return { 
        type: APPROVER_ACTION_TYPE.GET_DEFAULT_APPROVER + COMMON_ACTION_TYPES.REQUEST,
        depId: depId
    }
}

const getDefaultApproverClear = () => {
    return {type: APPROVER_ACTION_TYPE.GET_DEFAULT_APPROVER + COMMON_ACTION_TYPES.CLEAR}
}

export const approverActions = {
    getApprovers,
    getDefaultApprover,
    getDefaultApproverClear,
}