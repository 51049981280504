import { type } from "os";
import { COMMON_ACTION_TYPES, USER_ACTION_TYPE, } from "../../utilities/constants";

const getAllPassengers = (name: string) => {
  return { 
    type: USER_ACTION_TYPE.GET_ALL_PASSENGERS_LIST + COMMON_ACTION_TYPES.REQUEST, 
    name: name, 
  };
};

const getAllPassengersByDepartment = (id: number) => {
  return { 
    type: USER_ACTION_TYPE.GET_ALL_USERS_BY_DEPARTMENT + COMMON_ACTION_TYPES.REQUEST,
    id: id 
  }
}

const getPassengerById = (userId: number) => {
  return { 
    type: USER_ACTION_TYPE.GET_PASSENGER_DETAILS + COMMON_ACTION_TYPES.REQUEST, 
    userId: userId, 
  };
};

const getPassengerByIdClear = () => {
  return { type: USER_ACTION_TYPE.GET_PASSENGER_DETAILS + COMMON_ACTION_TYPES.CLEAR }
}

const GetAllRecipients = (name: string) => {
  return { 
    type: USER_ACTION_TYPE.GET_ALL_RECIPIENTS + COMMON_ACTION_TYPES.REQUEST,
    name: name, 
  }
}

export const userActions = {
  getAllPassengers,
  getPassengerById,
  getAllPassengersByDepartment,
  getPassengerByIdClear,
  GetAllRecipients,
}
