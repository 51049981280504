import React, { useEffect, useState } from "react";
import style from "./RequestScreen.module.scss";
import { AppLayout } from "../../templates";
import { Grid, Typography } from "@mui/material";
import { ConfirmationDialog, CustomAutocomplete, CustomButton, } from "../../components/Shared";
import { GeneralInformation, PackageDetails, PessengerDetails, ShowAddPackagePopup, Suggestions, ShowAddMultipleLocationPopup, OnBehalfOfSomeone, } from "../../components";
import { StyledSwitch, StyledTextField } from "../../assets/theme/theme";
import { useDispatch, useSelector } from "react-redux";
import { AlertDto, ApplicationStateDto, GeneralInformationFormDto, GetMatchingTemplatesParamDto, LocationBriefDto,NewRequestPackageDetailsItemsDto,
  NewRequestPackagedetailsDto,NewRequestPassengerDetailsDto,OnBehalfOfSomeoneFormDto,OptionsDto,PackageDetailsFormDto,PackageItemsFormDto,
  PackageTypeBriefDto,PassengerBriefDto,PassengerDetailsFormDto,RecipientBriefDto,RequestByIdDto,RequestTypeBriefDto,SortMetaDto,
  TemplatesDto,VehicleTypesDto,selectedType,} from "../../utilities/models";
import { alertActions,approverActions, locationActions, mapActions, packageActions,permissionActions,requestActions, templateActions, userActions,vehicleActions,} from "../../redux/actions";
import { validateFormData } from "../../utilities/helpers";
import dayjs, { Dayjs } from "dayjs";
import { ALERT_ACTION_TYPES, APP_ACTION_STATUS, APP_ROUTES, COMMON_ACTION_TYPES, ORGANIZATION_UNIT_TYPES, REQUEST_SCREEN_MODES,REQUEST_TYPES,USER_ROLES,} from "../../utilities/constants";
import { useNavigate } from "react-router-dom";

const NewRequest = () => {
  const ON_BEHALF_OF_SOMEONE_FORM_INITIAL_STATE: OnBehalfOfSomeoneFormDto = {
    isForSomeone: { value: false, isRequired: true, disable: false, readonly: false, validator: null, error: "", },
    sbu: { value: {} as OptionsDto, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    plant: { value: {} as OptionsDto, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    department: { value: {} as OptionsDto, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    someone: { value: {} as OptionsDto, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
  };

  const GENERAL_INFORMATION_FORM_INITIAL_STATE: GeneralInformationFormDto = {
    requestType: { value: {} as RequestTypeBriefDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    depatureDate: { value: null, isRequired: true, disable: true, readonly: false, validator: "date", error: "", },
    departureTime: { value: null, isRequired: true, disable: false, readonly: false, validator: "text", error: "", },
    fromLocation: { value: {} as LocationBriefDto, isRequired: true, readonly: false, disable: false, validator: "object", error: "", },
    toLocation: { value: {} as LocationBriefDto, isRequired: false, readonly: false, disable: false, validator: "object", error: "", },
    returnDate: { value: null, isRequired: true, disable: true, readonly: false, validator: "date", error: "", },
    returnTime: { value: null, isRequired: true, disable: false, readonly: false, validator: "text", error: "", },
    isSameDay: { value: false, isRequired: true, disable: false, readonly: false, validator: null, error: "", },
    isVIP: { value: false, isRequired: true, disable: false, readonly: false, validator: null, error: "", },
    approverDep: { value: {} as { value: string; label: string }, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    approver: { value: {} as OptionsDto, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    vehicleType: { value: {} as VehicleTypesDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    purpose: { value: "", isRequired: false, disable: false, readonly: false, validator: "text", error: "", max: 100 },
    isTemplate: { value: false, isRequired: true, disable: false, readonly: false, validator: null, error: "", },
    sbu: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    plant: { value: {} as OptionsDto, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    department: { value: {} as OptionsDto, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    templateTitle: { value: "", isRequired: false, disable: true, readonly: false, validator: "text", error: "", max: 15 },
    startLocationLatAndLng: { value: {} as selectedType, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    toLocationLatAndLng: { value: {} as selectedType, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    totalPassengerCount: { value: '', isRequired: false, disable: false, readonly: false, validator: "number", error: "", },
    totalPackageCount: { value: '', isRequired: false, disable: true, readonly: false, validator: "number", error: "", },
    totalPackageWeight: { value: '', isRequired: false, disable: true, readonly: false, validator: "number", error: "", },
    totalPackageCBM: { value: '', isRequired: false, disable: true, readonly: false, validator: "number", error: "", },
  };

  const PASSENGER_DETAILS_FORM_INITIAL_STATE: PassengerDetailsFormDto = {
    passenger: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    sbu: { value: "", isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    plant: { value: "", isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    department: { value: "", isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    startLocation: { value: {} as LocationBriefDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    toLocation: { value: {} as LocationBriefDto, isRequired: true, disable: false, readonly: true, validator: "object", error: "", },
    intermediateLocation: { value: {} as LocationBriefDto, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    dropLocation: { value: {} as LocationBriefDto, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    startLocationLatAndLng: { value: {} as selectedType, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    toLocationLatAndLng: { value: {} as selectedType, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    dropLocationLatAndLng: { value: {} as selectedType, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    intermediateLocationLatAndLng: { value: {} as selectedType, isRequired: false, disable: false, readonly: false, validator: "object", error: "", }
  };

  const PACKAGE_DETAILS_FORM_INITIAL_STATE: PackageDetailsFormDto = {
    packageType: { value: {} as PackageTypeBriefDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    gatePassNo: { value: "", isRequired: true, disable: false, readonly: false, validator: "text", error: "", max: 15 },
    pickup: { value: {} as LocationBriefDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    dropOff: { value: {} as LocationBriefDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    recipient: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    contactNumber: { value: "", isRequired: true, disable: false, readonly: false, validator: "number", error: "", charLength: [10], },
    instructions: { value: "", isRequired: false, disable: false, readonly: false, validator: "text", error: "", max: 100 },
    pickupLatAndLng: { value: {} as selectedType, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    dropOffLatAndLng: { value: {} as selectedType, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
  };

  const PACKAGE_DETAILS_ITEMS_FORM_INITIAL_STATE: PackageItemsFormDto = {
    serialNumber: { value: "", isRequired: false, disable: false, readonly: false, validator: "text", error: "", max: 15 },
    description: { value: "", isRequired: false, disable: false, readonly: false, validator: "text", error: "", max: 100 },
    cbm: { value: "", isRequired: false, disable: false, readonly: false, validator: "number", error: "", max: 5 },
    weight: { value: "", isRequired: false, disable: false, readonly: false, validator: "number", error: "", max: 5 },
  };

  const INITIAL_SORT_META: SortMetaDto = {
    field: "",
    asc: false,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [screenMode, setScreenMode] = useState("");
  const [isOpenAddPackagePopup, setIsOpenAddPackagePopup] = useState(false);
  const [isOpenAddMultipleLocationPopup, setIsOpenAddMultipleLocationPopup] = useState(false);
  const [sortMeta, setSortMeta] = useState<SortMetaDto>(INITIAL_SORT_META);
  const [onBehalfOfSomeoneForm, setOnBehlafOfSomeoneForm] = useState(ON_BEHALF_OF_SOMEONE_FORM_INITIAL_STATE);
  const [genaralInformationForm, setGenaralInformationForm] = useState(GENERAL_INFORMATION_FORM_INITIAL_STATE);
  const [passengerDetailsForm, setPassengerDetailsForm] = useState(PASSENGER_DETAILS_FORM_INITIAL_STATE);
  const [packageDetailsForm, setPackageDetailsForm] = useState(PACKAGE_DETAILS_FORM_INITIAL_STATE);
  const [packageItemsForm, setPackageItemsForm] = useState(PACKAGE_DETAILS_ITEMS_FORM_INITIAL_STATE);
  const [passengerDetailsList, setPassengerDetailsList] = useState<NewRequestPassengerDetailsDto[]>([]);
  const [packageDetailsList, setPackageDetailsList] = useState<NewRequestPackagedetailsDto[]>([]);
  const [packageDetailItemsList, setPackageDetailItemsList] = useState<NewRequestPackageDetailsItemsDto[]>([]);
  const [helperText, setHelperText] = useState(true);
  const [selectedMatchingTemplate, setSelectedMatchingTemplate] = useState(-1);
  const [clickOnce, setClickOnce] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [allTemplates, setAllTemplates] = useState([]);
  const [selectedTemplateVal, setSelectedTemplateVal] = useState<OptionsDto>({} as OptionsDto);
  const [isOpenUseTemplatePopup, setIsOpenUseTemplatePopup] = useState(false);
  const [selectedTemplateFreez, setSelectedTemplateFreez] = useState(false);

  const [isOpenPassengerConfirmationPopup, setIsOpenPassengerConfirmationPopup] = useState(false);
  const [selectedPassenger, setSelectedPassenger] = useState(-1);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditItem, setIsEditItem] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [totalDistance, settotalDistance] = useState(0)

  const [isOpenPackageConfirmationPopup, setIsOpenPackageConfirmationPopup] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(-1);

  const [isOpenPackageItemConfirmationPopup, setIsOpenPackageItemConfirmationPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(-1);

  const [isDraftButtonClick, setIsDraftButtonClick] = useState(false);

  const authorizedUser = useSelector((state: ApplicationStateDto) => state.authUser.authorizedUser);
  const vehicleTypesResponse = useSelector((state: ApplicationStateDto) => state.vehicle.getVehicleTypes);
  const frequentLocationsResponse = useSelector((state: ApplicationStateDto) => state.location.getFrequentLocations);
  const allPassengerResponse = useSelector((state: ApplicationStateDto) => state.user.getAllPassengers);
  const allRecipientResponse = useSelector((state: ApplicationStateDto) => state.user.getAllRecipients);
  const allApproverResponse = useSelector((state: ApplicationStateDto) => state.approver.getApprovers);
  const defaultApproverResponse = useSelector((state: ApplicationStateDto) => state.approver.getDefaultApprover);
  const packageTypes = useSelector((state: ApplicationStateDto) => state.package.getPackageTypes.data);
  const passengerByIdResponse = useSelector((state: ApplicationStateDto) => state.user.getPassenger);
  const requestByIdResponse = useSelector((state: ApplicationStateDto) => state.request.getRequest);
  const createNewRequestResponse = useSelector((state: ApplicationStateDto) => state.request.addRequest);
  const createNewReccurrentRequestResponse = useSelector((state: ApplicationStateDto) => state.request.addReccurrentRequest);
  const editRequestResponse = useSelector((state: ApplicationStateDto) => state.request.editRequest);
  const matchingTemplatesResponse = useSelector((state: ApplicationStateDto) => state.template.getMatchingTemplateList);
  const allSbuList = useSelector((state: ApplicationStateDto) => state.request.getSbuList.data);
  const allPlantsBySbuIdResponse = useSelector((state: ApplicationStateDto) => state.request.getPlantListBySbuList);
  const allDepartmentByPlantIdResponse = useSelector((state: ApplicationStateDto) => state.request.getDepartmentListByPlantId);
  const allTemplatesResponse = useSelector((state: ApplicationStateDto) => state.template.getTemplate);
  const userByIdResponse = useSelector((state: ApplicationStateDto) => state.user.getPassenger);
  const getUsersByDepartmentResponse = useSelector((state: ApplicationStateDto) => state.user.getAllPassengersByDepartment);
  const GetTotalDistanceResponse = useSelector((state: ApplicationStateDto) => state.map.setTotalDistance);

  const [switchValue, setswitchValue] = useState(true)
  const [switchValue2, setswitchValue2] = useState(true)

  useEffect(() => {
    getInitialData();

    return () => {
      dispatch(requestActions.CreateNewRequestClear());
      dispatch(requestActions.createNewReccurentRequestClear());
      dispatch(requestActions.editRequestClear());
      dispatch(requestActions.getRequestByIdClear());
      dispatch(templateActions.getMatchingTemplateListClear());
      dispatch(userActions.getPassengerByIdClear());
      dispatch(approverActions.getDefaultApproverClear());
      setSelectedTemplateFreez(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (GetTotalDistanceResponse.status === APP_ACTION_STATUS.SUCCESS) {
      settotalDistance(Number(GetTotalDistanceResponse.data))
    }
  }, [GetTotalDistanceResponse.status])

  const getInitialData = async () => {
    const _mode = sessionStorage.getItem("Mode");
    if (_mode !== null) setScreenMode(_mode);

    dispatch(vehicleActions.getVehicleTypes());
    dispatch(locationActions.getFrequentLocationList());
    dispatch(packageActions.getPackageTypes());
    dispatch(requestActions.getSbuList());
    dispatch(permissionActions.getRolesPermissions());

    if (_mode === REQUEST_SCREEN_MODES.CREATE || _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE) {
      dispatch(templateActions.getTemplate());
    }

    if (_mode === REQUEST_SCREEN_MODES.CREATE) {
      fetchInitialPassengerDetailsForm();
    }
  };

  useEffect(() => {
    if (passengerByIdResponse.status === APP_ACTION_STATUS.SUCCESS &&
      screenMode !== REQUEST_SCREEN_MODES.VIEW) {
      setPassengerDetailsForm({
        ...passengerDetailsForm,
        sbu: {
          ...passengerDetailsForm.sbu,
          value: passengerByIdResponse.data?.data.find((d) => d.type === ORGANIZATION_UNIT_TYPES.SBU)?.name ?? "",
          readonly: true,
        },
        plant: {
          ...passengerDetailsForm.plant,
          value: passengerByIdResponse.data?.data.find((d) => d.type === ORGANIZATION_UNIT_TYPES.PLANT)?.name ?? "",
          readonly: true,
        },
        department: {
          ...passengerDetailsForm.department,
          value: passengerByIdResponse.data?.data.find((d) => d.type === ORGANIZATION_UNIT_TYPES.DEPARTMENT)?.name ?? "",
          readonly: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passengerByIdResponse.status]);

  useEffect(() => {
    const _mode = sessionStorage.getItem("Mode");
    if (_mode === REQUEST_SCREEN_MODES.USE_TEMPLATE) {
      setGenaralInformationForm({
        ...genaralInformationForm,
        isTemplate: {
          value: false,
          validator: null,
          isRequired: false,
          error: null,
          disable: false,
          readonly: false
        }
      }
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (vehicleTypesResponse.status === APP_ACTION_STATUS.SUCCESS &&
      frequentLocationsResponse.status === APP_ACTION_STATUS.SUCCESS) {

      const _mode = sessionStorage.getItem("Mode");
      const _id = sessionStorage.getItem("id");

      if (_mode === REQUEST_SCREEN_MODES.VIEW || _mode === REQUEST_SCREEN_MODES.EDIT ||
        _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE || _mode === REQUEST_SCREEN_MODES.PENDING_VEHICLE_VIEW ||
        _mode === REQUEST_SCREEN_MODES.LM_APPROVAL_VIEW || _mode === REQUEST_SCREEN_MODES.TM_APPROVAL_VIEW) {

        if (_id) dispatch(requestActions.getRequestById(_id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleTypesResponse.status, frequentLocationsResponse.status]);

  useEffect(() => {
    const _mode = sessionStorage.getItem("Mode");

    if (defaultApproverResponse.status === APP_ACTION_STATUS.SUCCESS && defaultApproverResponse.data &&
      (_mode !== REQUEST_SCREEN_MODES.CREATE)) {

      const _isDefaultApprover = defaultApproverResponse.data?.defaultApproverId === genaralInformationForm.approver.value.value;
      const _isDisable = _mode === REQUEST_SCREEN_MODES.VIEW;

      setGenaralInformationForm({
        ...genaralInformationForm,
        approverDep: {
          ...genaralInformationForm.approverDep,
          value: { label: _isDefaultApprover ? "same department" : "all department", value: _isDefaultApprover ? "same department" : "all department", },
          disable: false,
          readonly: _isDisable,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultApproverResponse.status]);

  // useEffect(() => {
  //   const _mode = sessionStorage.getItem("Mode");
  //   if (defaultApproverResponse.status === APP_ACTION_STATUS.SUCCESS && defaultApproverResponse.data &&
  //     (_mode === REQUEST_SCREEN_MODES.VIEW || _mode === REQUEST_SCREEN_MODES.EDIT)) {

  //     setGenaralInformationForm({
  //       ...genaralInformationForm,
  //       approverDep: {
  //         ...genaralInformationForm.approverDep,
  //         value: { label: "same department", value: "same department" },
  //         error: null,
  //       },
  //       approver: {
  //         ...genaralInformationForm.approver,
  //         value: { label: defaultApproverResponse.data.defaultApproverName, value: defaultApproverResponse.data.defaultApproverId, },
  //         disable: false,
  //         readonly: true,
  //         error: null,
  //       },
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [defaultApproverResponse.status]);

  useEffect(() => {
    const _mode = sessionStorage.getItem("Mode");
    if (requestByIdResponse.status === APP_ACTION_STATUS.SUCCESS && requestByIdResponse.data && _mode !== REQUEST_SCREEN_MODES.CREATE) {

      if (_mode === REQUEST_SCREEN_MODES.USE_TEMPLATE) setSelectedTemplateFreez(true);

      const _data: RequestByIdDto = requestByIdResponse.data;
      setIsDraft(_data.isDraft);
      const _isDisable = _mode === REQUEST_SCREEN_MODES.VIEW;
      dispatch(approverActions.getApprovers(_data.approver));
      dispatch(approverActions.getDefaultApprover(_data.billableDepartments.departmentId));
      setInputValue(_data.approver);

      if (_data && frequentLocationsResponse.data && vehicleTypesResponse.data) {
        const _requestType = REQUEST_TYPES.find((r) => r.id === _data.requestTypeId);
        const _fromLocation = frequentLocationsResponse.data.find((l) => l.location === _data.fromLocation);
        const _toLocation = frequentLocationsResponse.data.find((l) => l.location === _data.toLocation);
        const _vehicle = vehicleTypesResponse.data.find((v) => v.id === _data.vehicleTypeId);

        const specificItemStart:boolean = frequentLocationsResponse.data.some((item) => item.location === (_fromLocation?.location));
        const specificItemEnd:boolean = frequentLocationsResponse.data.some((item) => item.location === (_toLocation?.location));

        setswitchValue(specificItemStart)
        setswitchValue2(specificItemEnd)

        const viewOnlyModes = (_mode === REQUEST_SCREEN_MODES.VIEW) ? _mode  : (_mode === REQUEST_SCREEN_MODES.LM_APPROVAL_VIEW) ? _mode 
                  : (_mode === REQUEST_SCREEN_MODES.TM_APPROVAL_VIEW) ? _mode : REQUEST_SCREEN_MODES.PENDING_VEHICLE_VIEW;  

        // Rest of your code using _requestType, _fromLocation, _toLocation, and _vehicle
        if (_mode === viewOnlyModes || _mode === REQUEST_SCREEN_MODES.EDIT)
        console.log("CHECK CONDITION", viewOnlyModes, _mode)
          setOnBehlafOfSomeoneForm({
            ...onBehalfOfSomeoneForm,
            isForSomeone: {
              ...onBehalfOfSomeoneForm.isForSomeone,
              value: _data.isOnbehalfOfSomeone,
              disable: (_mode === viewOnlyModes || _mode === REQUEST_SCREEN_MODES.EDIT) ? true : false
            },
            someone: {
              ...onBehalfOfSomeoneForm.someone,
              value: { label: _data.createForName, value: _data.createFor },
              readonly: (_mode === viewOnlyModes || _mode === REQUEST_SCREEN_MODES.EDIT) ? true : false
            }
          })

        setGenaralInformationForm({
          ...genaralInformationForm,
          requestType: {
            ...genaralInformationForm.requestType,
            value: { name: _requestType?.name, id: _requestType?.id, } as RequestTypeBriefDto,
            readonly: _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE ? false : true,
          },
          depatureDate: {
            ...genaralInformationForm.depatureDate,
            value: _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE ? null : dayjs(_data?.fromDateTime).format("YYYY-MM-DD") as unknown as Dayjs,
            readonly: _isDisable ? true
              : _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE
                ? false
                : _requestType?.id === 2
                  ? true
                  : false,
            disable: _isDisable
              ? true
              : _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE
                ? false
                : _requestType?.id === 2
                  ? true
                  : false,
            validator: _requestType?.id === 2 ? "dates" : "date"
          },
          departureTime: {
            ...genaralInformationForm.departureTime,
            value: dayjs(_data?.fromDateTime),
            readonly: _isDisable,
          },
          returnDate: {
            ...genaralInformationForm.returnDate,
            value: _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE ? null : dayjs(_data?.toDateTime).format("YYYY-MM-DD") as unknown as Dayjs,
            readonly: _isDisable
              ? true
              : _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE
                ? false
                : _requestType?.id === 2
                  ? true
                  : false,
            disable: _isDisable
              ? true
              : _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE
                ? false
                : _requestType?.id === 2
                  ? true
                  : false,
            validator: _requestType?.id === 2 ? "dates" : "date"

          },
          returnTime: {
            ...genaralInformationForm.returnTime,
            value: dayjs(_data?.toDateTime),
            readonly: _isDisable,
          },
          fromLocation: {
            ...genaralInformationForm.fromLocation,
            value: specificItemStart ? { location: _fromLocation?.location, id: _fromLocation?.id, } as LocationBriefDto : 
                                  { location: _data.fromLocation, id: "0", } as LocationBriefDto,
            readonly: _isDisable,
          },
          startLocationLatAndLng: {
            value: {
              lat: Number(requestByIdResponse.data.fromLocationLatitude),
              lng: Number(requestByIdResponse.data.fromLocationLongitude)
            } as selectedType,
            validator: null,
            isRequired: false,
            error: null,
            disable: false,
            readonly: (_mode === viewOnlyModes || _mode === REQUEST_SCREEN_MODES.EDIT) ? true : false
          },
          toLocationLatAndLng: {
            value: {
              lat: Number(requestByIdResponse.data.toLocationLatitude),
              lng: Number(requestByIdResponse.data.toLocationLongitude),
            } as selectedType,
            validator: null,
            isRequired: false,
            error: null,
            disable: false,
            readonly: (_mode === viewOnlyModes || _mode === REQUEST_SCREEN_MODES.EDIT) ? true : false
          },
          toLocation: {
            ...genaralInformationForm.toLocation,
            // value: { location: _toLocation?.location, id: _toLocation?.id, } as LocationBriefDto,
            value: specificItemEnd ? { location: _toLocation?.location, id: _toLocation?.id, } as LocationBriefDto : 
                                  { location: _data.toLocation, id: "0", } as LocationBriefDto,
            readonly: _isDisable,
          },
          isSameDay: {
            ...genaralInformationForm.isSameDay,
            // value: _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE ? false : _data.return,
            value:  _requestType?.id === REQUEST_TYPES[0].id ? _data.return : false,
            disable: _isDisable || _requestType?.id === REQUEST_TYPES[1].id,
          },
          isVIP: {
            ...genaralInformationForm.isVIP,
            value: _data.vip,
            disable: _isDisable,
          },
          purpose: {
            ...genaralInformationForm.purpose,
            value: _data.purpose,
            readonly: _isDisable,
          },
          isTemplate: {
            ...genaralInformationForm.isTemplate,
            value: (_mode === REQUEST_SCREEN_MODES.USE_TEMPLATE) ? false : _data.isTemplate,
            disable: true,
          },
          templateTitle: {
            ...genaralInformationForm.templateTitle,
            value: _data.templateTitle,
            disable: !_data.isTemplate,
            isRequired: _data.isTemplate
          },
          approver: {
            ...genaralInformationForm.approver,
            value: { label: _data.approver, value: _data?.approverId, } as OptionsDto,
            readonly: _isDisable,
            disable: !_isDisable,
          },
          vehicleType: {
            ...genaralInformationForm.vehicleType,
            value: { description: _vehicle?.description, id: _vehicle?.id, } as VehicleTypesDto,
            readonly: _isDisable,
          },
          sbu: {
            ...genaralInformationForm.sbu,
            value: { label: _data.billableDepartments.sbuName, value: _data?.billableDepartments.sbuId, } as OptionsDto,
            readonly: _isDisable,
          },
          plant: {
            ...genaralInformationForm.plant,
            value: { label: _data.billableDepartments.plantName, value: _data?.billableDepartments.plantId, } as OptionsDto,
            readonly: _isDisable,
            disable: !_isDisable,
          },
          department: {
            ...genaralInformationForm.department,
            value: { label: _data.billableDepartments.departmentName, value: _data?.billableDepartments.departmentId, } as OptionsDto,
            readonly: _isDisable,
            disable: !_isDisable,
          },
        });

        setPassengerDetailsForm({
          ...passengerDetailsForm,
          startLocation: {
            ...passengerDetailsForm.startLocation,
            value:  specificItemStart ? { location: _fromLocation?.location, id: _fromLocation?.id, } as LocationBriefDto : 
                                    { location: _data.fromLocation, id: "0", } as LocationBriefDto,
          },
          startLocationLatAndLng: {
            value: { lat: Number(requestByIdResponse.data.fromLocationLatitude), lng: Number(requestByIdResponse.data.fromLocationLatitude), } as selectedType,
            validator: null,
            isRequired: false,
            error: null,
            disable: false,
            readonly: (_mode === viewOnlyModes || _mode === REQUEST_SCREEN_MODES.EDIT) ? true : false
          },
          toLocation: {
            ...passengerDetailsForm.toLocation,
            value: specificItemEnd ? { location: _toLocation?.location, id: _toLocation?.id, } as LocationBriefDto : 
                                    { location: _data.toLocation, id: "0", } as LocationBriefDto,
          },
          toLocationLatAndLng: {
            value: { lat: Number(requestByIdResponse.data.toLocationLatitude), lng: Number(requestByIdResponse.data.toLocationLongitude) },
            validator: null,
            isRequired: false,
            error: null,
            disable: false,
            readonly: (_mode === viewOnlyModes || _mode === REQUEST_SCREEN_MODES.EDIT) ? true : false
          }
        });
        setPackageDetailsForm({
          ...packageDetailsForm,
          pickup: {
            ...packageDetailsForm.pickup,
            value: specificItemStart ? { location: _fromLocation?.location, id: _fromLocation?.id, } as LocationBriefDto : 
                                        { location: _data.fromLocation, id: "0", } as LocationBriefDto,
          },

          dropOff: {
            ...packageDetailsForm.dropOff,
            value: specificItemEnd ? { location: _toLocation?.location, id: _toLocation?.id, } as LocationBriefDto : 
                                      { location: _data.toLocation, id: "0", } as LocationBriefDto,
          },

        });


        const _passengerList = _data.requestPassengers.map((p, index) => {
          const _listOfLocations = p.requestPassengerLocations;
          const _sbu = p.hierarchy.find((h) => h.typeId === ORGANIZATION_UNIT_TYPES.SBU);
          const _plant = p.hierarchy.find((h) => h.typeId === ORGANIZATION_UNIT_TYPES.PLANT);
          const _dep = p.hierarchy.find((h) => h.typeId === ORGANIZATION_UNIT_TYPES.DEPARTMENT);

          // const _startLocation = frequentLocationsResponse.data.find((l) => l.location === _listOfLocations[0].startLocation)
          const _startLocation: LocationBriefDto = frequentLocationsResponse.data.find((l) => l.location === _listOfLocations[0].startLocation) || {
            id: Math.floor(Math.random() * 10000).toString(),
            location: _listOfLocations[0].startLocation,
            latitude: parseFloat(_listOfLocations[0].startLocationLatitude),
            longitude: parseFloat(_listOfLocations[0].startLocationLongitude),
          };
          
          const _toLocation: LocationBriefDto = frequentLocationsResponse.data.find((l) => l.location === _listOfLocations[0].toLocation) || {
            id: Math.floor(Math.random() * 10000).toString(),
            location: _listOfLocations[0].toLocation,
            latitude: parseFloat(_listOfLocations[0].toLocationLatitude),
            longitude: parseFloat(_listOfLocations[0].toLocationLongitude),
          }

          const _intermediateLocation: LocationBriefDto = frequentLocationsResponse.data.find((l) => l.location === _listOfLocations[0].intermediateLocation) || {
            id: Math.floor(Math.random() * 10000).toString(),
            location: _listOfLocations[0].intermediateLocation,
            latitude: parseFloat(_listOfLocations[0].intermediateLocationLatitude),
            longitude: parseFloat(_listOfLocations[0].intermediateLocationLongitude),
          }
          const _dropLocation: LocationBriefDto = frequentLocationsResponse.data.find((l) => l.location === _listOfLocations[0].dropLocation) || {
            id: Math.floor(Math.random() * 10000).toString(),
            location: _listOfLocations[0].dropLocation,
            latitude: parseFloat(_listOfLocations[0].dropLocationLatitude),
            longitude: parseFloat(_listOfLocations[0].dropLocationLongitude),
          }
          
          const _passenger: NewRequestPassengerDetailsDto = {
            id: index,
            passenger: { id: p.passengerId, name: p.passenger },
            sbu: _sbu?.name ?? "",
            plant: _plant?.name ?? "",
            department: _dep?.name ?? "",
            startLocation: _startLocation ?? ({} as LocationBriefDto),
            toLocation: _toLocation ?? ({} as LocationBriefDto),
            intermediateLocation: _intermediateLocation ?? ({} as LocationBriefDto),
            dropLocation: _dropLocation ?? ({} as LocationBriefDto),
            requestPassengerLocations: _listOfLocations
          };
          return _passenger;
        });

        const _packageList = _data.packages.map((p, index) => {
          const _items = p.items.map((i, index) => {
            const item: NewRequestPackageDetailsItemsDto = {
              id: index,
              serialNumber: i.serialNo,
              description: i.description,
              cbm: i.cbm,
              weight: i.weight,
            };

            return item;
          });

          const _pickup = frequentLocationsResponse.data.find((l) => l.location === p.packageLocations[0].startLocation) || {
            id: Math.floor(Math.random() * 10000).toString(),
            location: p.packageLocations[0].startLocation,
            latitude: parseFloat(p.packageLocations[0].startLocationLatitude),
            longitude: parseFloat(p.packageLocations[0].startLocationLongitude),
          }
          const _dropOff = frequentLocationsResponse.data.find((l) => l.location === p.packageLocations[0].toLocation) || {
            id: Math.floor(Math.random() * 10000).toString(),
            location: p.packageLocations[0].toLocation,
            latitude: parseFloat(p.packageLocations[0].toLocationLatitude),
            longitude: parseFloat(p.packageLocations[0].toLocationLongitude),
          }

          // const pickupLat = frequentLocationsResponse.data.find((l) => l.latitude === p.packageLocations[0].pickupLatAndLng?.lat);
          // const pickupLang = frequentLocationsResponse.data.find((l) => l.longitude === p.packageLocations[0].pickupLatAndLng?.lng);
          // const dropOffLat = frequentLocationsResponse.data.find((l) => l.latitude === p.packageLocations[0].dropOffLatAndLng?.lat);
          // const dropOffLng = frequentLocationsResponse.data.find((l) => l.longitude === p.packageLocations[0].dropOffLatAndLng?.lng);

          const pickupLatAndLngs: selectedType = {
            lat: _pickup?.latitude ?? 0,
            lng: _pickup?.longitude ?? 0,
          }

          const dropOffLatAndLngs: selectedType = {
            lat: _dropOff?.latitude ?? 0,
            lng: _dropOff?.longitude ?? 0,
          }

          const _package: NewRequestPackagedetailsDto = {
            // TODO: CHANGE ID TO P.serialNo 
            id: index,
            packageType: { id: p.packageTypeId, description: p.packageType },
            gatePassNo: p.gatePassNo,
            pickup: _pickup ?? ({} as LocationBriefDto),
            dropOff: _dropOff ?? ({} as LocationBriefDto),
            recipient: { id: p.recipientId, name: p.reciepient },
            contactNumber: p.recipientContact,
            instructions: p.instructions,
            items: _items,
            pickupLatAndLng: pickupLatAndLngs ?? ({} as selectedType),
            dropOffLatAndLng: dropOffLatAndLngs ?? ({} as selectedType),
          };

          return _package;
        });

        if (screenMode === viewOnlyModes || screenMode === REQUEST_SCREEN_MODES.EDIT) {
          setPassengerDetailsList(_passengerList);
          setPackageDetailsList(_packageList);
        }

        if (screenMode === REQUEST_SCREEN_MODES.USE_TEMPLATE) setPassengerDetailsList(_passengerList);
      }

    }
    else {
      // Handle the case when any of the data sources (_data, frequentLocationsResponse, vehicleTypesResponse) is null or undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestByIdResponse.status]);

  useEffect(() => {
    if (createNewRequestResponse.status === APP_ACTION_STATUS.SUCCESS || createNewReccurrentRequestResponse.status === APP_ACTION_STATUS.SUCCESS) {
      navigate(APP_ROUTES.REQUEST_CREATION);
      setAsInitialState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNewRequestResponse.status, createNewReccurrentRequestResponse.status]);

  useEffect(() => {
    if (editRequestResponse.status === APP_ACTION_STATUS.SUCCESS) {
      navigate(APP_ROUTES.REQUEST_CREATION);
      setAsInitialState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRequestResponse.status]);

  useEffect(() => {
    if (matchingTemplatesResponse?.status === APP_ACTION_STATUS.SUCCESS) {
      matchingTemplatesResponse?.data?.forEach((t) => (t.isActive = false));
      setSelectedMatchingTemplate(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchingTemplatesResponse?.status]);

  useEffect(() => {
    if (allTemplatesResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setAllTemplates(
        allTemplatesResponse.data ? allTemplatesResponse.data : []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTemplatesResponse.status]);

  const fetchInitialPassengerDetailsForm = () => {
    const _empName = authorizedUser.data.firstName + " " + authorizedUser.data.lastName
    setInputValue(_empName);
    dispatch(userActions.getPassengerById(authorizedUser.data.userId));
    setPassengerDetailsForm({
      ...passengerDetailsForm,
      passenger: {
        ...passengerDetailsForm.passenger,
        value: { label: _empName, value: authorizedUser.data.userId, } as OptionsDto,
        readonly: true,
      },
    });
  };

  const onInputHandleChangeRequestForSomeone = (property: string, value: any) => {
    setHelperText(true);
    if (value !== null) {
      if (property === "isForSomeone") {
        if (value) {
          setInputValue("");
          setPassengerDetailsForm({
            ...passengerDetailsForm,
            passenger: {
              ...passengerDetailsForm.passenger,
              value: {} as OptionsDto,
              readonly: false,
            },
            sbu: {
              ...passengerDetailsForm.sbu,
              value: "",
              readonly: true,
            },
            plant: {
              ...passengerDetailsForm.plant,
              value: "",
              readonly: true,
            },
            department: {
              ...passengerDetailsForm.department,
              value: "",
              readonly: true,
            },
          });

          // CHECK USER ROLE BUG 406, 408
          if (authorizedUser.data && authorizedUser.data !== undefined) {
            const _dataSbu = authorizedUser.data.hierarchy.find((d) => d.typeId === ORGANIZATION_UNIT_TYPES.SBU)
            const _dataPlant = authorizedUser.data.hierarchy.find((d) => d.typeId === ORGANIZATION_UNIT_TYPES.PLANT)
            const _dataDept = authorizedUser.data.hierarchy.find((d) => d.typeId === ORGANIZATION_UNIT_TYPES.DEPARTMENT)

            if (_dataPlant !== undefined && _dataSbu !== undefined && _dataDept !== undefined){
              if (authorizedUser.data.roleId === USER_ROLES.SUPER_ADMIN)
                setOnBehlafOfSomeoneForm({
                  ...onBehalfOfSomeoneForm,
                  isForSomeone: {
                    ...onBehalfOfSomeoneForm.isForSomeone,
                    value: value,
                  },
                  sbu: {
                    ...onBehalfOfSomeoneForm.sbu,
                    disable: false,
                  },
                });
              }

              if(authorizedUser.data.roleId === USER_ROLES.SBU_MANAGER){
                setOnBehlafOfSomeoneForm({
                  ...onBehalfOfSomeoneForm,
                  isForSomeone: {
                    ...onBehalfOfSomeoneForm.isForSomeone,
                    value: value,
                  },
                  sbu: {
                    ...onBehalfOfSomeoneForm.sbu,
                    value: { label: authorizedUser.data.hierarchy[0].name, value: authorizedUser.data.hierarchy[0].id },
                    disable: true,
                  },
                  plant: {
                    ...onBehalfOfSomeoneForm.plant,
                    disable: false,
                  },
                });
                dispatch(requestActions.getPlantListBySbuId(authorizedUser.data.hierarchy[0].id));
              }

              if(authorizedUser.data.roleId === USER_ROLES.TRANSPORT_MANAGER || authorizedUser.data.roleId === USER_ROLES.DIVISION_USER){
                setOnBehlafOfSomeoneForm({
                  ...onBehalfOfSomeoneForm,
                  isForSomeone: {
                    ...onBehalfOfSomeoneForm.isForSomeone,
                    value: value,
                  },
                  sbu: {
                    ...onBehalfOfSomeoneForm.sbu,
                    value: { label: authorizedUser.data.hierarchy[0].name, value: authorizedUser.data.hierarchy[0].id },
                    disable: true,
                  },
                  plant: {
                    ...onBehalfOfSomeoneForm.plant,
                    value: { label:  authorizedUser.data.hierarchy[1].name, value:  authorizedUser.data.hierarchy[1].id },
                    disable: true,
                  },
                  department: {
                    ...onBehalfOfSomeoneForm.department,
                    disable: false,
                  },
                });
                dispatch(requestActions.getDepartmentListByPlantId(authorizedUser.data.hierarchy[1].id));
              }
            } 
        } else {
          fetchInitialPassengerDetailsForm();
          setOnBehlafOfSomeoneForm({
            ...onBehalfOfSomeoneForm,
            isForSomeone: {
              ...onBehalfOfSomeoneForm.isForSomeone,
              value: value,
            },
            sbu: {
              ...onBehalfOfSomeoneForm.sbu,
              value: {} as OptionsDto,
              disable: true,
            },
            plant: {
              ...onBehalfOfSomeoneForm.plant,
              value: {} as OptionsDto,
              disable: true,
            },
            department: {
              ...onBehalfOfSomeoneForm.department,
              value: {} as OptionsDto,
              disable: true,
            },
            someone: {
              ...onBehalfOfSomeoneForm.someone,
              value: {} as OptionsDto,
              disable: true,
            },
          });
        }
      }

      if (property === "sbu") {
        dispatch(requestActions.getPlantListBySbuId(value.value));
        setOnBehlafOfSomeoneForm({
          ...onBehalfOfSomeoneForm,
          sbu: {
            ...onBehalfOfSomeoneForm.sbu,
            value: value,
          },
          plant: {
            ...onBehalfOfSomeoneForm.plant,
            disable: false,
          },
        });
      }

      if (property === "plant") {
        dispatch(requestActions.getDepartmentListByPlantId(value.value));
        setOnBehlafOfSomeoneForm({
          ...onBehalfOfSomeoneForm,
          plant: {
            ...onBehalfOfSomeoneForm.plant,
            value: value,
          },
          department: {
            ...onBehalfOfSomeoneForm.department,
            disable: false,
          },
        });
      }

      if (property === "department") {
        dispatch(userActions.getAllPassengersByDepartment(value.value));
        setOnBehlafOfSomeoneForm({
          ...onBehalfOfSomeoneForm,
          department: {
            ...onBehalfOfSomeoneForm.department,
            value: value,
          },
          someone: {
            ...onBehalfOfSomeoneForm.someone,
            disable: false,
          },
        });
      }

      if (property === "someone") {
        setOnBehlafOfSomeoneForm({
          ...onBehalfOfSomeoneForm,
          someone: {
            ...onBehalfOfSomeoneForm.someone,
            value: value,
          },
        });

        setInputValue(value.label);
        dispatch(userActions.getPassengerById(value.value));
        setPassengerDetailsForm({
          ...passengerDetailsForm,
          passenger: {
            ...passengerDetailsForm.passenger,
            value: { label: value.label, value: value.value } as OptionsDto,
            readonly: true,
          },
        });
      }
    }
  };

  const onInputHandleChangeSelectTemplate = (value: any) => {
    setSelectedTemplateVal(value);
    setIsOpenUseTemplatePopup(true);
  };

  const onUseTemplate = (con: boolean) => {
    if (con) {
      sessionStorage.setItem("Mode", REQUEST_SCREEN_MODES.USE_TEMPLATE);
      dispatch(
        requestActions.getRequestById(selectedTemplateVal.value.toString())
      );
    }
    setIsOpenUseTemplatePopup(false);
  };

  const handleInputFocus = (property: string, section: string) => {
    if (section === "GI")
      setGenaralInformationForm({
        ...genaralInformationForm,
        [property]: {
          ...genaralInformationForm[property as keyof typeof genaralInformationForm],
          error: null,
        },
      });
    if (section === "PD")
      setPassengerDetailsForm({
        ...passengerDetailsForm,
        [property]: {
          ...passengerDetailsForm[property as keyof typeof passengerDetailsForm],
          error: null,
        },
      });
    if (section === "PACKAGE_DETAILS_FORM")
      setPackageDetailsForm({
        ...packageDetailsForm,
        [property]: {
          ...packageDetailsForm[property as keyof typeof packageDetailsForm],
          error: null,
        },
      });
    if (section === "PACKAGE_DETAIL_ITEMS_FORM")
      setPackageItemsForm({
        ...packageItemsForm,
        [property]: {
          ...packageItemsForm[property as keyof typeof packageItemsForm],
          error: null,
        },
      });
    if (section === "request-for")
      setOnBehlafOfSomeoneForm({
        ...onBehalfOfSomeoneForm,
        [property]: {
          ...onBehalfOfSomeoneForm[property as keyof typeof onBehalfOfSomeoneForm],
          error: null,
        },
      });
  };

  const onInputHandleChangeGenaralInformation = (property: string, value: any) => {
    setHelperText(true);
    if (value !== null) {
      // REQUEST TYPE IS RECURRENT
      if (property === "requestType" && value.value === 2) {
        setGenaralInformationForm({
          ...genaralInformationForm,
          requestType: {
            ...genaralInformationForm.requestType,
            value: { name: value.label, id: value.value, } as RequestTypeBriefDto,
          },
          returnDate: {
            ...genaralInformationForm.returnDate,
            value: [],
            disable: true,
            validator: "dates",
          },
          isSameDay: {
            ...genaralInformationForm.isSameDay,
            value: true,
            disable: true,
          },
          depatureDate: {
            ...genaralInformationForm.depatureDate,
            value: [],
            disable: false,
            validator: "dates",
          },
        });
      }
      if (property === "requestType" && value.value === 1) {
        setGenaralInformationForm({
          ...genaralInformationForm,
          requestType: {
            ...genaralInformationForm.requestType,
            value: { name: value.label, id: value.value, } as RequestTypeBriefDto,
          },
          returnDate: {
            ...genaralInformationForm.returnDate,
            value: null,
            disable: false,
            validator: "date",
          },
          isSameDay: {
            ...genaralInformationForm.isSameDay,
            value: false,
            disable: false,
          },
          depatureDate: {
            ...genaralInformationForm.depatureDate,
            value: null,
            disable: false,
            validator: "date",
          },
        });
      }
      if (property === "fromLocation") {
        let _isError = false
        if (genaralInformationForm.toLocation.value.id !== undefined) {
          if (genaralInformationForm.toLocation.value.id === value.value) {
            _isError = true
            const setAlert: AlertDto = {
              message: "Please select different locations.!",
              type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
              options: {
                key: new Date().getTime() + Math.random(),
                varient: "error",
              },
            };
            dispatch(alertActions.triggerAlert(setAlert));
          }
        }
        if (!_isError) {
          const specificItem = frequentLocationsResponse.data.find((item) => item.id === value.value);
          if (specificItem) {
            setStartLocation({ lat: Number(specificItem.latitude), lng: Number(specificItem.longitude) })
          } else {

          }
          setGenaralInformationForm({
            ...genaralInformationForm,
            fromLocation: {
              ...genaralInformationForm.fromLocation,
              value: { location: value.label, id: value.value, } as LocationBriefDto,
            },
            startLocationLatAndLng: {
              ...passengerDetailsForm.startLocationLatAndLng,
              value: { lat: Number(specificItem?.latitude) || null, lng: Number(specificItem?.longitude) || null } as selectedType,
            }
          });
          setPassengerDetailsForm({
            ...passengerDetailsForm,
            startLocation: {
              ...passengerDetailsForm.startLocation,
              value: { location: value.label, id: value.value, } as LocationBriefDto,
            },
            startLocationLatAndLng: {
              ...passengerDetailsForm.startLocationLatAndLng,
              value: { lat: Number(specificItem?.latitude) || null, lng: Number(specificItem?.longitude) || null } as selectedType,
            }
          });
          setPackageDetailsForm({
            ...packageDetailsForm,
            pickup: {
              ...packageDetailsForm.pickup,
              value: { location: value.label, id: value.value, } as LocationBriefDto,
            },
            pickupLatAndLng:{
              ...packageDetailsForm.pickupLatAndLng,
              value: { lat: Number(specificItem?.latitude) || null, lng: Number(specificItem?.longitude) || null } as selectedType,
            }
          });
        }
      }
      if (property === "toLocation") {
        let _isError = false
        if (genaralInformationForm.fromLocation.value.id !== undefined) {
          if (genaralInformationForm.fromLocation.value.id === value.value) {
            _isError = true
            const setAlert: AlertDto = {
              message: "Please select different locations.!",
              type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
              options: {
                key: new Date().getTime() + Math.random(),
                varient: "error",
              },
            };
            dispatch(alertActions.triggerAlert(setAlert));
          }
        }
        if (!_isError) {
          const specificItem = frequentLocationsResponse.data.find((item) => item.id === value.value);
          if (specificItem) {
            setEndLocation({ lat: Number(specificItem.latitude), lng: Number(specificItem.longitude) })
          }
          setGenaralInformationForm({
            ...genaralInformationForm,
            toLocation: {
              ...genaralInformationForm.toLocation,
              value: { location: value.label, id: value.value, } as LocationBriefDto,
            },
            toLocationLatAndLng: {
              ...genaralInformationForm.toLocationLatAndLng,
              value: { lat: Number(specificItem?.latitude) || null, lng: Number(specificItem?.longitude) || null } as selectedType,
            }
          });
          setPassengerDetailsForm({
            ...passengerDetailsForm,
            toLocation: {
              ...passengerDetailsForm.toLocation,
              value: { location: value.label, id: value.value, } as LocationBriefDto,
            },
            toLocationLatAndLng: {
              ...genaralInformationForm.toLocationLatAndLng,
              value: { lat: Number(specificItem?.latitude) || null, lng: Number(specificItem?.longitude) || null } as selectedType,
            }
          });
          setPackageDetailsForm({
            ...packageDetailsForm,
            dropOff: {
              ...packageDetailsForm.dropOff,
              value: { location: value.label, id: value.value, } as LocationBriefDto,
            },
            dropOffLatAndLng:{
              ...packageDetailsForm.dropOffLatAndLng,
              value: { lat: Number(specificItem?.latitude) || null, lng: Number(specificItem?.longitude) || null } as selectedType,
            },
          });
        }
      }
      if (property === "depatureDate" && genaralInformationForm.requestType.value.id === 2) {
        setGenaralInformationForm({
          ...genaralInformationForm,
          depatureDate: {
            ...genaralInformationForm.depatureDate,
            value: value,
            error: null,
          },
          returnDate: {
            ...genaralInformationForm.returnDate,
            value: value,
            error: null,
          },
        });
      }
      if (property === "depatureDate" && genaralInformationForm.requestType.value.id === 1) {
        setGenaralInformationForm({
          ...genaralInformationForm,
          depatureDate: {
            ...genaralInformationForm.depatureDate,
            value: value,
            error: null,
          },
        });
      }
      if (property === "isSameDay" && value) {
        setGenaralInformationForm({
          ...genaralInformationForm,
          isSameDay: {
            ...genaralInformationForm.isSameDay,
            value: value,
          },
          returnDate: {
            ...genaralInformationForm.returnDate,
            value: genaralInformationForm.depatureDate.value,
            disable: true,
            error: null,
          },
        });
      }
      if (property === "isSameDay" && !value) {
        setGenaralInformationForm({
          ...genaralInformationForm,
          isSameDay: {
            ...genaralInformationForm.isSameDay,
            value: value,
          },
          returnDate: {
            ...genaralInformationForm.returnDate,
            value: null,
            disable: false,
          },
        });
      }
      if (property === "vehicleType") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          vehicleType: {
            ...genaralInformationForm.vehicleType,
            value: { description: value.label, id: value.value, } as VehicleTypesDto,
          },
        });
      }
      if (property === "returnDate") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          returnDate: {
            ...genaralInformationForm.returnDate,
            value: value,
            error: null,
          },
        });
      }
      if (property === "returnTime") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          returnTime: {
            ...genaralInformationForm.returnTime,
            value: value,
          },
        });
      }
      if (property === "departureTime") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          departureTime: {
            ...genaralInformationForm.departureTime,
            value: value,
          },
        });
      }
      if (property === "purpose" || property === "isVIP") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          [property]: {
            ...genaralInformationForm[property as keyof typeof genaralInformationForm],
            value: value,
          },
        });
      }
      if (property === "sbu") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          sbu: {
            ...genaralInformationForm.sbu,
            value: value ? value : ({} as OptionsDto),
          },
          plant: {
            ...genaralInformationForm.plant,
            value: {} as OptionsDto,
            disable: false,
          },
          department: {
            ...genaralInformationForm.department,
            value: {} as OptionsDto,
          },
        });

        dispatch(requestActions.getPlantListBySbuId(value.value));
      }
      if (property === "plant") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          plant: {
            ...genaralInformationForm.plant,
            value: value ? value : ({} as OptionsDto),
          },
          department: {
            ...genaralInformationForm.department,
            value: {} as OptionsDto,
            disable: false,
          },
        });

        dispatch(requestActions.getDepartmentListByPlantId(value.value));
      }
      if (property === "department") {
        dispatch(approverActions.getDefaultApprover(Number(value.value)));
        setGenaralInformationForm({
          ...genaralInformationForm,
          department: {
            ...genaralInformationForm.department,
            value: value ? value : ({} as OptionsDto),
          },
          approverDep: {
            ...genaralInformationForm.approverDep,
            disable: false,
          },
        });
      }
      if (property === "approverDep" && value.value === "same department") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          approverDep: {
            ...genaralInformationForm.approverDep,
            value: value,
          },
          approver: {
            ...genaralInformationForm.approver,
            value: defaultApproverResponse.data ? {
              label: defaultApproverResponse.data?.defaultApproverName,
              value: defaultApproverResponse.data?.defaultApproverId,
            } : ({} as OptionsDto),
            disable: false,
            readonly: true,
          },
        });
      }
      if (property === "approverDep" && value.value === "all department") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          approver: {
            ...genaralInformationForm.approver,
            value: {} as OptionsDto,
            disable: false,
            readonly: false,
          },
          approverDep: {
            ...genaralInformationForm.approverDep,
            value: value,
          },
        });
      }
      if (property === "approver") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          approver: {
            ...genaralInformationForm.approver,
            value: value
              ? ({ label: value.label, value: value.value } as OptionsDto)
              : ({} as OptionsDto),
          },
        });
      }
      if (property === "isTemplate") {
        if (value)
          setGenaralInformationForm({
            ...genaralInformationForm,
            isTemplate: {
              ...genaralInformationForm.isTemplate,
              value: value,
            },
            templateTitle: {
              ...genaralInformationForm.templateTitle,
              isRequired: true,
              disable: false,
            },
          });
        else
          setGenaralInformationForm({
            ...genaralInformationForm,
            isTemplate: {
              ...genaralInformationForm.isTemplate,
              value: value,
            },
            templateTitle: {
              ...genaralInformationForm.templateTitle,
              value: "",
              isRequired: false,
              disable: true,
            },
          });
      }
      if (property === "templateTitle") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          templateTitle: {
            ...genaralInformationForm.templateTitle,
            value: value,
          },
        });
      }
    }
  };

  const onSearchValueChange = (property: string, value: any) => {
    if (screenMode === REQUEST_SCREEN_MODES.CREATE || screenMode === REQUEST_SCREEN_MODES.EDIT ||
      screenMode === REQUEST_SCREEN_MODES.USE_TEMPLATE) {

      if (property === "approver" && genaralInformationForm.approverDep.value.value === "all department") {
        if (value && value !== inputValue) {
          dispatch(approverActions.getApprovers(value));
        }
      }
      if (property === "passenger") {
        if (value && value !== inputValue) {
          dispatch(userActions.getAllPassengers(value));
        }
      }
      if (property === "recipient") {
        if (value && value !== inputValue) {
          dispatch(userActions.GetAllRecipients(value));
        }
      }
      if (value) setInputValue(value);
    }
  };

  const onSelectPassengerForEdit = (id: number) => {
    setIsEdit(true);
    setSelectedPassenger(id);
    const _passenger = passengerDetailsList.find((p) => p.id === id);
    if (_passenger !== undefined) setInputValue(_passenger.passenger.name);

    setPassengerDetailsForm({
      ...passengerDetailsForm,
      passenger: {
        ...passengerDetailsForm.passenger,
        value: { label: _passenger?.passenger.name, value: _passenger?.passenger.id, } as OptionsDto,
        readonly: true,
      },
      sbu: {
        ...passengerDetailsForm.sbu,
        value: _passenger?.sbu ?? "",
        readonly: true,
      },
      plant: {
        ...passengerDetailsForm.plant,
        value: _passenger?.plant ?? "",
        readonly: true,
      },
      department: {
        ...passengerDetailsForm.department,
        value: _passenger?.department ?? "",
        readonly: true,
      },
      startLocation: {
        ...passengerDetailsForm.startLocation,
        value: _passenger?.startLocation as LocationBriefDto,
      },
      toLocation: {
        ...passengerDetailsForm.toLocation,
        value: _passenger?.toLocation as LocationBriefDto,
      },
      intermediateLocation: {
        ...passengerDetailsForm.intermediateLocation,
        value: _passenger?.intermediateLocation as LocationBriefDto,
      },
      dropLocation: {
        ...passengerDetailsForm.dropLocation,
        value: _passenger?.dropLocation as LocationBriefDto,
      },
    });
  };

  const onSelectPackageForEdit = (id: number) => {
    setIsEdit(true);
    setSelectedPackage(id);
    const _package = packageDetailsList.find((p) => p.id === id);
    if (_package !== undefined) setInputValue(_package.recipient.name);

    setPackageDetailsForm({
      ...packageDetailsForm,
      packageType: {
        ...packageDetailsForm.packageType,
        value: _package?.packageType as PackageTypeBriefDto,
        isRequired: true,
        disable: true,
      },
      gatePassNo: {
        ...packageDetailsForm.gatePassNo,
        value: _package?.gatePassNo ?? "",
        isRequired: true,
      },
      pickup: {
        ...packageDetailsForm.pickup,
        value: _package?.pickup as LocationBriefDto,
        isRequired: true,
      },
      dropOff: {
        ...packageDetailsForm.dropOff,
        value: _package?.dropOff as LocationBriefDto,
        isRequired: true,
      },
      recipient: {
        ...packageDetailsForm.recipient,
        value: { label: _package?.recipient.name, value: _package?.recipient.id, } as OptionsDto,
        isRequired: true,
      },
      contactNumber: {
        ...packageDetailsForm.contactNumber,
        value: _package?.contactNumber ?? "",
        isRequired: true,
      },
      instructions: {
        ...packageDetailsForm.instructions,
        value: _package?.instructions ?? "",
      },
    });
    if (_package?.items && _package?.items.length > 0)
      setPackageDetailItemsList(_package?.items);
    setIsOpenAddPackagePopup(true);
  };

  const onSelectPackageForView = (id: number) => {
    setIsEdit(true);
    setSelectedPackage(id);
    const _package = packageDetailsList.find((p) => p.id === id);
    if (_package !== undefined) setInputValue(_package.recipient.name);

    setPackageDetailsForm({
      ...packageDetailsForm,
      packageType: {
        ...packageDetailsForm.packageType,
        value: _package?.packageType as PackageTypeBriefDto,
        isRequired: true,
        readonly: true,
      },
      gatePassNo: {
        ...packageDetailsForm.gatePassNo,
        value: _package?.gatePassNo ?? "",
        isRequired: true,
        readonly: true,
      },
      pickup: {
        ...packageDetailsForm.pickup,
        value: _package?.pickup as LocationBriefDto,
        isRequired: true,
        readonly: true,
      },
      dropOff: {
        ...packageDetailsForm.dropOff,
        value: _package?.dropOff as LocationBriefDto,
        isRequired: true,
        readonly: true,
      },
      recipient: {
        ...packageDetailsForm.recipient,
        value: { label: _package?.recipient.name, value: _package?.recipient.id, } as OptionsDto,
        isRequired: true,
        readonly: true,
      },
      contactNumber: {
        ...packageDetailsForm.contactNumber,
        value: _package?.contactNumber ?? "",
        isRequired: true,
        readonly: true,
      },
      instructions: {
        ...packageDetailsForm.instructions,
        value: _package?.instructions ?? "",
        isRequired: true,
        readonly: true,
      },
    });
    if (_package?.items && _package?.items.length > 0)
      setPackageDetailItemsList(_package?.items);
    setIsOpenAddPackagePopup(true);
  };

  const onSelectPackageItemForEdit = (id: number) => {
    setIsEditItem(true);
    setSelectedItem(id);
    const _item = packageDetailItemsList.find((i) => i.id === id);

    setPackageItemsForm({
      ...packageItemsForm,
      serialNumber: {
        ...packageItemsForm.serialNumber,
        value: _item?.serialNumber ?? "",
        isRequired: true,
      },
      description: {
        ...packageItemsForm.description,
        value: _item?.description ?? "",
        isRequired: true,
      },
      cbm: {
        ...packageItemsForm.cbm,
        value: _item?.cbm.toString() ?? "",
        isRequired: true,
      },
      weight: {
        ...packageItemsForm.weight,
        value: _item?.weight.toString() ?? "",
        isRequired: true,
      },
    });
  };

  const getMatchingTemplates = () => {
    const requestType = genaralInformationForm.requestType.value.id;
    const fromLocation = genaralInformationForm.fromLocation.value;
    const toLocation = genaralInformationForm.toLocation.value;
    const departureTime = genaralInformationForm.departureTime.value;
    const departureDate = genaralInformationForm.depatureDate.value;
    const returnTime = genaralInformationForm.returnTime.value;
    const returnDate = genaralInformationForm.returnDate.value;
    const vehicleType = genaralInformationForm.vehicleType.value;
    const passengerCount = passengerDetailsList.length;
    const isSetFromLocation = !!fromLocation && Object.keys(fromLocation).length > 0;
    const isSetToLocation = !!toLocation && Object.keys(toLocation).length > 0;
    const isSetDepatureTime = !!departureTime;
    const isSetReturnTime = !!returnTime;

    const isSetDepatureDate = (requestType === 1 && !!departureDate) || (requestType === 2 && departureDate && (departureDate as Dayjs[]).length > 0);
    const isSetReturnDate = (requestType === 1 && !!returnDate) || (requestType === 2 && departureDate && (departureDate as Dayjs[]).length > 0);

    if (isSetFromLocation && isSetToLocation && isSetDepatureTime &&
      isSetReturnTime && isSetDepatureDate && isSetReturnDate) {

      const _depatureDate = requestType === 1
        ? dayjs(departureDate as Dayjs).format("YYYY-MM-DD")
        : dayjs((departureDate as Dayjs[])[0]).format("YYYY-MM-DD");

      const _returnDate = requestType === 1
        ? dayjs(returnDate as Dayjs).format("YYYY-MM-DD")
        : dayjs((departureDate as Dayjs[])[0]).format("YYYY-MM-DD");

      const _depatureAt = dayjs(departureTime).format("HH:mm");
      const _returnAt = dayjs(returnTime).format("HH:mm");
      const _from = _depatureDate + " " + _depatureAt;
      const _to = _returnDate + " " + _returnAt;

      // const _fromDate = dayjs(_from).add(330, 'minute').format("YYYY-MM-DD HH:mm")
      // const _toDate = dayjs(_to).add(330, 'minute').format("YYYY-MM-DD HH:mm")

      const param: GetMatchingTemplatesParamDto = {
        fromDate: _from,
        toDate: _to,
        fromLocation: fromLocation.location,
        toLocation: toLocation.location,
        VehicleTypeId: vehicleType.id.toString(),
        PassengerCount: passengerCount.toString()
      };

      dispatch(templateActions.getMatchingTemplateList(param));
      setClickOnce(true);
    } else {
      let errorMessage = "Please set the following fields: ";
      if (!isSetFromLocation) {
        errorMessage += "From Location, ";
      }
      if (!isSetToLocation) {
        errorMessage += "To Location, ";
      }
      if (!isSetDepatureTime) {
        errorMessage += "Departure Time, ";
      }
      if (!isSetDepatureDate) {
        errorMessage += "Departure Date, ";
      }
      if (!isSetReturnTime) {
        errorMessage += "Return Time, ";
      }
      if (!isSetReturnDate) {
        errorMessage += "Return Date, ";
      }
      errorMessage = errorMessage.slice(0, -2);

      const setAlert: AlertDto = {
        message: errorMessage,
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: "error",
        },
      };
      dispatch(alertActions.triggerAlert(setAlert));
    }
  };

  const selectTemplate = (id: number) => {
    let isSelected = false;
    matchingTemplatesResponse.data = matchingTemplatesResponse.data.map((t) => {
      if (t.id === id) {
        t.isActive = !t.isActive; // Toggle isActive if the id matches
        isSelected = t.isActive; // Check if it's now selected
      } else if (t.isActive) {
        t.isActive = false; // Set isActive to false for other items
      }
      return t;
    });

    if (isSelected) {
      setSelectedMatchingTemplate(id);
      console.log("setSelectedMatchingTemplate", id)
    } else {
      setSelectedMatchingTemplate(-1);
    }


  };

  const onInputHandleChangePassengerDetails = ( property: string, value: any) => {
    setHelperText(true);
    if (value) {
      if (property === "passenger") {
        if (
          onBehalfOfSomeoneForm.isForSomeone.value &&
          authorizedUser.data.userId === value.value
        ) {
          const setAlert: AlertDto = {
            message: "Please select someone else for this request",
            type:
              ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
            options: {
              key: new Date().getTime() + Math.random(),
              varient: "error",
            },
          };
          dispatch(alertActions.triggerAlert(setAlert));
        } else {
          dispatch(userActions.getPassengerById(value.value));
          setPassengerDetailsForm({
            ...passengerDetailsForm,
            passenger: {
              ...passengerDetailsForm.passenger,
              value: value
                ? ({ label: value.label, value: value.value } as OptionsDto)
                : ({} as OptionsDto),
            },
          });
        }
      }
      const specificItem = frequentLocationsResponse.data.find((item) => item.id === value.value);
      if (property === "startLocation") {
        let _isError = false
        if (passengerDetailsForm.toLocation.value.id !== undefined) {
          if (passengerDetailsForm.toLocation.value.id === value.value) {
            _isError = true
          }
        }
        if (passengerDetailsForm.intermediateLocation.value.id !== undefined) {
          if (passengerDetailsForm.intermediateLocation.value.id === value.value) {
            _isError = true
          }
        }
        if (!_isError) {
          setPassengerDetailsForm({
            ...passengerDetailsForm,
            startLocation: {
              ...passengerDetailsForm.startLocation,
              value: { location: value.label, id: value.value } as LocationBriefDto,
            },
            startLocationLatAndLng: {
              ...passengerDetailsForm.startLocationLatAndLng,
              value: { lat: Number(specificItem?.latitude), lng: Number(specificItem?.longitude) }
            }

          });
        } else {
          const setAlert: AlertDto = {
            message: "Please select different locations.!",
            type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
            options: {
              key: new Date().getTime() + Math.random(),
              varient: "error",
            },
          };
          dispatch(alertActions.triggerAlert(setAlert));
        }
      }

      if (property === "toLocation") {
        let _isError = false
        if (passengerDetailsForm.startLocation.value.id !== undefined) {
          if (passengerDetailsForm.startLocation.value.id === value.value) {
            _isError = true
          }
        }
        if (passengerDetailsForm.intermediateLocation.value.id !== undefined) {
          if (passengerDetailsForm.intermediateLocation.value.id === value.value) {
            _isError = true
          }
        }
        if (!_isError) {
          setPassengerDetailsForm({
            ...passengerDetailsForm,
            toLocation: {
              ...passengerDetailsForm.toLocation,
              value: { location: value.label, id: value.value } as LocationBriefDto,
            },
            toLocationLatAndLng: {
              ...passengerDetailsForm.toLocationLatAndLng,
              value: { lat: Number(specificItem?.latitude), lng: Number(specificItem?.longitude) }
            }
          });

        } else {
          const setAlert: AlertDto = {
            message: "Please select different locations.!",
            type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
            options: {
              key: new Date().getTime() + Math.random(),
              varient: "error",
            },
          };
          dispatch(alertActions.triggerAlert(setAlert));
        }
      }

      if (property === "intermediateLocation") {
        let _isError = false
        if (passengerDetailsForm.startLocation.value.id !== undefined) {
          if (passengerDetailsForm.startLocation.value.id === value.value) {
            _isError = true
          }
        }
        if (passengerDetailsForm.toLocation.value.id !== undefined) {
          if (passengerDetailsForm.toLocation.value.id === value.value) {
            _isError = true
          }
        }
        if (!_isError) {
          setPassengerDetailsForm({
            ...passengerDetailsForm,
            intermediateLocation: {
              ...passengerDetailsForm.intermediateLocation,
              value: { location: value.label, id: value.value } as LocationBriefDto,
            }, intermediateLocationLatAndLng: {
              ...passengerDetailsForm.intermediateLocationLatAndLng,
              value: { lat: Number(specificItem?.latitude), lng: Number(specificItem?.longitude) }
            }
          });
        } else {
          const setAlert: AlertDto = {
            message: "Please select different locations.!",
            type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
            options: {
              key: new Date().getTime() + Math.random(),
              varient: "error",
            },
          };
          dispatch(alertActions.triggerAlert(setAlert));
        }
      }

      if (property === "dropLocation") {
        setPassengerDetailsForm({
          ...passengerDetailsForm,
          dropLocation: {
            ...passengerDetailsForm.dropLocation,
            value: { location: value.label, id: value.value } as LocationBriefDto,
          },
          dropLocationLatAndLng: {
            ...passengerDetailsForm.dropLocationLatAndLng,
            value: { lat: Number(specificItem?.latitude), lng: Number(specificItem?.longitude) }
          }
        });
      }
    } else {
      if (property === "startLocation") {
        setPassengerDetailsForm({
          ...passengerDetailsForm,
          startLocation: {
            ...passengerDetailsForm.startLocation,
            value: { location: "", id: "" } as LocationBriefDto,
          },
          startLocationLatAndLng: {
            ...passengerDetailsForm.startLocationLatAndLng,
            value: { lat: 0, lng: 0 }
          }
        });
      }

      if (property === "toLocation") {
        setPassengerDetailsForm({
          ...passengerDetailsForm,
          toLocation: {
            ...passengerDetailsForm.toLocation,
            value: { location: "", id: "" } as LocationBriefDto,
          },
          toLocationLatAndLng: {
            ...passengerDetailsForm.toLocationLatAndLng,
            value: { lat: 0, lng: 0 }
          }
        });
      }
      if (property === "intermediateLocation") {
        setPassengerDetailsForm({
          ...passengerDetailsForm,
          intermediateLocation: {
            ...passengerDetailsForm.intermediateLocation,
            value: { location: "", id: "" } as LocationBriefDto,
          },
          intermediateLocationLatAndLng: {
            ...passengerDetailsForm.intermediateLocationLatAndLng,
            value: { lat: 0, lng: 0 }
          }
        });
      }
      if (property === "dropLocation") {
        setPassengerDetailsForm({
          ...passengerDetailsForm,
          dropLocation: {
            ...passengerDetailsForm.dropLocation,
            value: { location: "", id: "" } as LocationBriefDto,
          },
          dropLocationLatAndLng: {
            ...passengerDetailsForm.dropLocationLatAndLng,
            value: { lat: 0, lng: 0 }
          }
        });
      }
    }
  };

  const onInputHandleChangePackageDetails = (property: string, value: any) => {
    setHelperText(true);
    if (property === "contactNumber") {
      setPackageDetailsForm({
        ...packageDetailsForm,
        contactNumber: {
          ...packageDetailsForm.contactNumber,
          value: value,
        },
      });
    }
    if (property === "gatePassNo") {
      setPackageDetailsForm({
        ...packageDetailsForm,
        gatePassNo: {
          ...packageDetailsForm.gatePassNo,
          value: value,
        },
      });
    }
    if (property === "instructions") {
      setPackageDetailsForm({
        ...packageDetailsForm,
        instructions: {
          ...packageDetailsForm.instructions,
          value: value,
        },
      });
    }
    if (value) {
      if (property === "packageType") {
        setPackageDetailsForm({
          ...packageDetailsForm,
          packageType: {
            ...packageDetailsForm.packageType,
            value: {
              description: value.label,
              id: value.value,
            } as PackageTypeBriefDto,
          },
        });
      }
      if (property === "recipient") {
        setPackageDetailsForm({
          ...packageDetailsForm,
          recipient: {
            ...packageDetailsForm.recipient,
            value: value ? value : ({} as OptionsDto),
          },
        });
      }
      const specificItem = frequentLocationsResponse.data.find((item) => item.id === value.value);
      if (property === "pickup") {
        setPackageDetailsForm({
          ...packageDetailsForm,
          pickupLatAndLng: {
            ...packageDetailsForm.pickupLatAndLng,
            value: { lat: Number(specificItem?.latitude), lng: Number(specificItem?.longitude) }
          }
        });
      }

      if (property === "dropOff") {
        setPackageDetailsForm({
          ...packageDetailsForm,
          dropOffLatAndLng: {
            ...packageDetailsForm.dropOffLatAndLng,
            value: { lat: Number(specificItem?.latitude), lng: Number(specificItem?.longitude) }
          }
        });
      }
      if (property === "pickup" || property === "dropOff") {
        setPackageDetailsForm({
          ...packageDetailsForm,
          [property]: {
            ...packageDetailsForm[property as keyof typeof packageDetailsForm],
            value: {
              location: value.label,
              id: value.value,
            } as LocationBriefDto,
          },
        });
      }
    }
  };

  const onInputHandleChangePackageDetailItems = (property: string, value: any) => {
    setHelperText(true);
    if (property === "serialNumber") {
      setPackageItemsForm({
        ...packageItemsForm,
        serialNumber: {
          ...packageItemsForm.serialNumber,
          value: value,
          isRequired: true,
        },
        description: {
          ...packageItemsForm.description,
          isRequired: true,
        },
        cbm: {
          ...packageItemsForm.cbm,
          isRequired: true,
        },
        weight: {
          ...packageItemsForm.weight,
          isRequired: true,
        },
      });
    }
    if (
      property === "description" ||
      property === "cbm" ||
      property === "weight"
    )
      setPackageItemsForm({
        ...packageItemsForm,
        [property]: {
          ...packageItemsForm[property as keyof typeof packageItemsForm],
          value: value,
        },
      });
  };

  const onAddPackage = async () => {
    const [validateData, isValid] = await validateFormData(packageDetailsForm);
    setPackageDetailsForm(validateData);
    if (isValid) {
      const _id =
        packageDetailsList.length === 0
          ? 1
          : packageDetailsList[packageDetailsList.length - 1].id + 1;
      const _package: NewRequestPackagedetailsDto = {
        id: _id,
        packageType: packageDetailsForm.packageType.value,
        gatePassNo: packageDetailsForm.gatePassNo.value,
        pickup: packageDetailsForm.pickup.value,
        dropOff: packageDetailsForm.dropOff.value,
        recipient: {
          id: packageDetailsForm.recipient.value.value,
          name: packageDetailsForm.recipient.value.label,
        } as RecipientBriefDto,
        contactNumber: packageDetailsForm.contactNumber.value,
        instructions: packageDetailsForm.instructions.value,
        items: packageDetailItemsList,
        pickupLatAndLng: packageDetailsForm.pickupLatAndLng.value,
        dropOffLatAndLng: packageDetailsForm.dropOffLatAndLng.value

      };
      const _list = [...packageDetailsList, _package];
      setPackageDetailsList(_list);
      setPackageDetailsForm({
        ...PACKAGE_DETAILS_FORM_INITIAL_STATE,
        pickup: {
          ...PACKAGE_DETAILS_FORM_INITIAL_STATE.pickup,
          value: genaralInformationForm.fromLocation.value,
        },
        dropOff: {
          ...PACKAGE_DETAILS_FORM_INITIAL_STATE.dropOff,
          value: genaralInformationForm.toLocation.value,
        }
      });
      setPackageDetailItemsList([]);
      setIsOpenAddPackagePopup(false);
    }
  };

  const onEditPackage = async () => {
    const [validateData, isValid] = await validateFormData(packageDetailsForm);
    setPackageDetailsForm(validateData);
    if (isValid) {
      packageDetailsList.forEach((p) => {
        if (p.id === selectedPackage) {
          p.gatePassNo = packageDetailsForm.gatePassNo.value;
          p.pickup = packageDetailsForm.pickup.value;
          p.dropOff = packageDetailsForm.dropOff.value;
          p.recipient = { id: packageDetailsForm.recipient.value.value, name: packageDetailsForm.recipient.value.label, } as RecipientBriefDto;
          p.contactNumber = packageDetailsForm.contactNumber.value;
          p.instructions = packageDetailsForm.instructions.value;
          p.items = packageDetailItemsList;
          p.pickupLatAndLng = packageDetailsForm.pickupLatAndLng.value;
          p.dropOffLatAndLng = packageDetailsForm.dropOffLatAndLng.value;

        }
      });
      setPackageDetailsForm({
        ...PACKAGE_DETAILS_FORM_INITIAL_STATE,
        pickup: {
          ...PACKAGE_DETAILS_FORM_INITIAL_STATE.pickup,
          value: genaralInformationForm.fromLocation.value,
        },
        dropOff: {
          ...PACKAGE_DETAILS_FORM_INITIAL_STATE.dropOff,
          value: genaralInformationForm.toLocation.value,
        }
      });
      setPackageDetailItemsList([]);
      setIsOpenAddPackagePopup(false);
      setIsEdit(false);
    }
  };

  const onAddPackageItem = async () => {
    const [validateData, isValid] = await validateFormData(packageItemsForm);
    setPackageItemsForm(validateData);
    if (isValid) {
      const _id =
        packageDetailItemsList.length === 0
          ? 1
          : packageDetailItemsList[packageDetailItemsList.length - 1].id + 1;
      const _item: NewRequestPackageDetailsItemsDto = {
        id: _id,
        serialNumber: packageItemsForm.serialNumber.value,
        description: packageItemsForm.description.value,
        cbm: Number(packageItemsForm.cbm.value),
        weight: Number(packageItemsForm.weight.value),
      };
      const _list = [...packageDetailItemsList, _item];
      setPackageDetailItemsList(_list);
      setPackageItemsForm(PACKAGE_DETAILS_ITEMS_FORM_INITIAL_STATE);
    }
  };

  const onEditPackageItem = async () => {
    const [validateData, isValid] = await validateFormData(packageItemsForm);
    setPackageItemsForm(validateData);
    if (isValid) {
      packageDetailItemsList.forEach((i) => {
        if (i.id === selectedItem) {
          i.serialNumber = packageItemsForm.serialNumber.value;
          i.description = packageItemsForm.description.value;
          i.cbm = Number(packageItemsForm.cbm.value);
          i.weight = Number(packageItemsForm.weight.value);
        }
      });
      setPackageItemsForm(PACKAGE_DETAILS_ITEMS_FORM_INITIAL_STATE);
      setIsEditItem(false);
    }
  };

  const setSelectedPackageFromList = (id: number) => {
    setSelectedPackage(id);
    setIsOpenPackageConfirmationPopup(true);
  };

  const onPopPackage = (con: boolean) => {
    if (con) {
      setPackageDetailsList(
        packageDetailsList.filter((p) => p.id !== selectedPackage)
      );
      setSelectedPackage(-1);
    }
    setIsOpenPackageConfirmationPopup(false);
  };

  const onPopPackageItem = (con: boolean) => {
    if (con) {
      setPackageDetailItemsList(
        packageDetailItemsList.filter((p) => p.id !== selectedItem)
      );
      setSelectedItem(-1);
    }
    setIsOpenPackageItemConfirmationPopup(false);
  };

  const onClearPassengerDetails = () => {
    //  setPassengerDetailsForm(PASSENGER_DETAILS_FORM_INITIAL_STATE);
    setPassengerDetailsForm({
      ...PASSENGER_DETAILS_FORM_INITIAL_STATE,
      toLocation: {
        value: genaralInformationForm.toLocation.value,
        isRequired: true, disable: false, readonly: true, validator: "object", error: "",
      },
      toLocationLatAndLng: {
        value: genaralInformationForm.toLocationLatAndLng.value,
        validator: null,
        isRequired: true,
        error: null,
        disable: false,
        readonly: false
      }
    })
    setIsEdit(false);
  };
  const onClearPackageItems = () => {
    setPackageItemsForm(PACKAGE_DETAILS_ITEMS_FORM_INITIAL_STATE);
    setIsEditItem(false);
  };

  const onPushPassenger = async () => {
    const [validateData, isValid] = await validateFormData(passengerDetailsForm);
    setPassengerDetailsForm(validateData);

    if (isValid) {
      if (passengerDetailsList.some((p) => p.passenger.id === passengerDetailsForm.passenger.value.value)) {
        const setAlert: AlertDto = {
          message: "Passenger already added.!",
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: "error",
          },
        };
        dispatch(alertActions.triggerAlert(setAlert));
      } else {
        const _id =
          passengerDetailsList.length === 0
            ? 1
            : passengerDetailsList[passengerDetailsList.length - 1].id + 1;
        const _passenger: NewRequestPassengerDetailsDto = {
          id: _id,
          passenger: { id: passengerDetailsForm.passenger.value.value, name: passengerDetailsForm.passenger.value.label, } as PassengerBriefDto,
          sbu: passengerDetailsForm.sbu.value,
          plant: passengerDetailsForm.plant.value,
          department: passengerDetailsForm.department.value,
          startLocation: passengerDetailsForm.startLocation.value,
          toLocation: passengerDetailsForm.toLocation.value,
          intermediateLocation: passengerDetailsForm.intermediateLocation.value,
          dropLocation: passengerDetailsForm.dropLocation.value,
          requestPassengerLocations: [{
            passengerLocationsId: Number(passengerDetailsForm.passenger.value.value),
            startLocation: passengerDetailsForm.startLocation.value.location,
            startLocationLatitude: passengerDetailsForm.startLocationLatAndLng.value.lat.toString() || '',
            startLocationLongitude: passengerDetailsForm.startLocationLatAndLng.value.lng.toString() || '',
            toLocation: passengerDetailsForm.toLocation.value.location,
            toLocationLatitude: passengerDetailsForm.toLocationLatAndLng.value.lat.toString() || '',
            toLocationLongitude: passengerDetailsForm.toLocationLatAndLng.value.lng.toString() || '',
            dropLocation: passengerDetailsForm.dropLocation.value.location,
            dropLocationLatitude: passengerDetailsForm.dropLocationLatAndLng?.value?.lat?.toString() || '',
            dropLocationLongitude: passengerDetailsForm.dropLocationLatAndLng?.value?.lng?.toString() || '',
            intermediateLocation: passengerDetailsForm.intermediateLocation.value.location,
            intermediateLocationLongitude: passengerDetailsForm.intermediateLocationLatAndLng?.value?.lng?.toString() || '',
            intermediateLocationLatitude: passengerDetailsForm.intermediateLocationLatAndLng?.value?.lat?.toString() || '',
          }]
        };

        if (passengerDetailsList.length === 0)
          setOnBehlafOfSomeoneForm({
            ...onBehalfOfSomeoneForm,
            isForSomeone: {
              ...onBehalfOfSomeoneForm.isForSomeone,
              disable: true,
            },
          });

        const _list = [...passengerDetailsList, _passenger];

        setPassengerDetailsList(_list);
        setPassengerDetailsForm({
          ...passengerDetailsForm,
          passenger: {
            ...passengerDetailsForm.startLocation,
            value: {} as OptionsDto
          },
          plant: {
            ...passengerDetailsForm.plant,
            value: ''
          },
          sbu: {
            ...passengerDetailsForm.sbu,
            value: ''
          },
          department: {
            ...passengerDetailsForm.department,
            value: ''
          },
          startLocation: {
            ...passengerDetailsForm.startLocation,
            value: genaralInformationForm.fromLocation.value,
          },
          toLocation: {
            ...passengerDetailsForm.toLocation,
            value: genaralInformationForm.toLocation.value,
          },
          intermediateLocation: {
            ...passengerDetailsForm.intermediateLocation,
            value: {} as LocationBriefDto,
          },
          dropLocation: {
            ...passengerDetailsForm.dropLocation,
            value: {} as LocationBriefDto,
          },
        });
      }
    }
  };
  const onEditPassenger = async () => {
    const [validateData, isValid] = await validateFormData(
      passengerDetailsForm
    );
    setPassengerDetailsForm(validateData);
    if (isValid) {
      passengerDetailsList.forEach((p) => {
        if (p.id === selectedPassenger) {
          p.startLocation = passengerDetailsForm.startLocation.value;
          p.toLocation = passengerDetailsForm.toLocation.value;
          p.intermediateLocation = passengerDetailsForm.intermediateLocation.value;
          p.dropLocation = passengerDetailsForm.dropLocation.value;
          p.requestPassengerLocations = [{
            passengerLocationsId: Number(passengerDetailsForm.passenger.value.value),
            startLocation: passengerDetailsForm.startLocation.value.location,
            startLocationLatitude: passengerDetailsForm.startLocationLatAndLng.value.lat.toString() || '',
            startLocationLongitude: passengerDetailsForm.startLocationLatAndLng.value.lng.toString() || '',
            toLocation: passengerDetailsForm.toLocation.value.location,
            toLocationLatitude: passengerDetailsForm.toLocationLatAndLng.value.lat.toString() || '',
            toLocationLongitude: passengerDetailsForm.toLocationLatAndLng.value.lng.toString() || '',
            dropLocation: passengerDetailsForm.dropLocation.value.location,
            dropLocationLatitude: passengerDetailsForm.dropLocationLatAndLng?.value?.lat?.toString() || '',
            dropLocationLongitude: passengerDetailsForm.dropLocationLatAndLng?.value?.lng?.toString() || '',
            intermediateLocation: passengerDetailsForm.intermediateLocation.value.location,
            intermediateLocationLongitude: passengerDetailsForm.intermediateLocationLatAndLng?.value?.lng?.toString() || '',
            intermediateLocationLatitude: passengerDetailsForm.intermediateLocationLatAndLng?.value?.lat?.toString() || '',
          }]
        }
      });
      setIsEdit(false);
      setPassengerDetailsForm({
        ...passengerDetailsForm,
        passenger: {
          ...passengerDetailsForm.startLocation,
          value: {} as OptionsDto
        },
        plant: {
          ...passengerDetailsForm.plant,
          value: ''
        },
        sbu: {
          ...passengerDetailsForm.sbu,
          value: ''
        },
        department: {
          ...passengerDetailsForm.department,
          value: ''
        },
        startLocation: {
          ...passengerDetailsForm.startLocation,
          value: genaralInformationForm.fromLocation.value,
        },
        toLocation: {
          ...passengerDetailsForm.toLocation,
          value: genaralInformationForm.toLocation.value,
        },
        intermediateLocation: {
          ...passengerDetailsForm.intermediateLocation,
          value: {} as LocationBriefDto,
        },
        dropLocation: {
          ...passengerDetailsForm.dropLocation,
          value: {} as LocationBriefDto,
        },
      });
    }
  };

  const setSelectedPassengerItem = (id: number) => {
    setSelectedPassenger(id);
    setIsOpenPassengerConfirmationPopup(true);
  };

  const setSelectedPackageItem = (id: number) => {
    setSelectedItem(id);
    setIsOpenPackageItemConfirmationPopup(true);
  };

  const onPopPassenger = (con: boolean) => {
    if (con) {
      setPassengerDetailsList(
        passengerDetailsList.filter((p) => p.id !== selectedPassenger)
      );
      if (passengerDetailsList.length === 1)
        setOnBehlafOfSomeoneForm({
          ...onBehalfOfSomeoneForm,
          isForSomeone: {
            ...onBehalfOfSomeoneForm.isForSomeone,
            disable: false,
          },
        });
      setSelectedPassenger(-1);
    }
    setIsOpenPassengerConfirmationPopup(false);
  };

  const createNewRequest = async (isDraft: boolean) => {
    if (isDraft) setIsDraftButtonClick(isDraft);
    const [validateData, isValid] = await validateFormData(genaralInformationForm);

    setGenaralInformationForm(validateData);

    console.log("validateData", validateData)
    if (isValid) {
      let _isError = false;
      const _date = dayjs(new Date()).format("YYYY-MM-DDT");
      const _depatureDate = dayjs(genaralInformationForm.depatureDate.value as Dayjs).format("YYYY-MM-DDT");
      const _depatureAt = dayjs(genaralInformationForm.departureTime.value).format("HH:mmZ");
      const _returnDate = dayjs(genaralInformationForm.returnDate.value as Dayjs).format("YYYY-MM-DDT");
      const _returnAt = dayjs(genaralInformationForm.returnTime.value).format("HH:mmZ");

      const _fromDate = _date + _depatureAt;
      const _toDate = _date + _returnAt;

      console.log("dayjs(_depatureDate).isSame(_returnDate)", dayjs(_depatureDate).isSame(_returnDate))
      console.log("dayjs(_fromDate).isAfter(_toDate)", dayjs(_fromDate).isAfter(_toDate))

      if (dayjs(_fromDate).isAfter(_toDate) && dayjs(_depatureDate).isSame(_returnDate)) {
        _isError = true
        const setAlert: AlertDto = {
          message: "Return time should be greater than depature time.!",
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: "error",
          },
        };
        dispatch(alertActions.triggerAlert(setAlert));
      }
      if (passengerDetailsList.length === 0 && packageDetailsList.length === 0) {
        _isError = true;
        const setAlert: AlertDto = {
          message: "Please select passenger/package for create a request.!",
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: "error",
          },
        };
        dispatch(alertActions.triggerAlert(setAlert));
      }
      if (packageDetailsList.length === 0) {
        if (onBehalfOfSomeoneForm.isForSomeone.value) {
          if (!passengerDetailsList.some((p) => p.passenger.id === onBehalfOfSomeoneForm.someone.value.value)) {
            _isError = true;
            const setAlert: AlertDto = {
              message:
                "Invalid request! Request pointer person must be in the passenger list.",
              type:
                ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
              options: {
                key: new Date().getTime() + Math.random(),
                varient: "error",
              },
            };
            dispatch(alertActions.triggerAlert(setAlert));
          }
        } else {
          if (!passengerDetailsList.some((p) => p.passenger.id === authorizedUser.data.userId)) {
            _isError = true;
            const setAlert: AlertDto = {
              message:
                "Invalid request! Request creator must be in the passenger list.",
              type:
                ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
              options: {
                key: new Date().getTime() + Math.random(),
                varient: "error",
              },
            };
            dispatch(alertActions.triggerAlert(setAlert));
          }
        }
      }
      if (genaralInformationForm.requestType.value.id === 2) {
        const _dateRange = genaralInformationForm.depatureDate.value as Array<Dayjs>;
        if (_dateRange.length === 1) {
          _isError = true;
          const setAlert: AlertDto = {
            message: "Please select more than one day to create reccurent request.!",
            type:
              ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
            options: {
              key: new Date().getTime() + Math.random(),
              varient: "error",
            },
          };
          dispatch(alertActions.triggerAlert(setAlert));
        }

        const _date = dayjs(new Date()).format("YYYY-MM-DDT");
        const _depatureDate = _dateRange[0];
        const _depatureAt = dayjs(genaralInformationForm.departureTime.value).format("HH:mmZ");
        const _returnDate = _dateRange[0];
        const _returnAt = dayjs(genaralInformationForm.returnTime.value).format("HH:mmZ");

        const _fromDate = _date + _depatureAt;
        const _toDate = _date + _returnAt;

        console.log("dayjs(_depatureDate).isSame(_returnDate)", dayjs(_depatureDate).isSame(_returnDate))
        console.log("dayjs(_fromDate).isAfter(_toDate)", dayjs(_fromDate).isAfter(_toDate))

        if (dayjs(_fromDate).isAfter(_toDate) && dayjs(_depatureDate).isSame(_returnDate)) {
          _isError = true
          const setAlert: AlertDto = {
            message: "Return time should be greater than depature time.!",
            type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
            options: {
              key: new Date().getTime() + Math.random(),
              varient: "error",
            },
          };
          dispatch(alertActions.triggerAlert(setAlert));
        }
      }
      if (!_isError)
        if (genaralInformationForm.requestType.value.id === 1) {
          const _depatureDate = dayjs(genaralInformationForm.depatureDate.value as Dayjs).format("YYYY-MM-DDT");
          const _depatureAt = dayjs(genaralInformationForm.departureTime.value).format("HH:mmZ");
          const _returnDate = dayjs(genaralInformationForm.returnDate.value as Dayjs).format("YYYY-MM-DDT");
          const _returnAt = dayjs(genaralInformationForm.returnTime.value).format("HH:mmZ");

          const _tempFromDate = _depatureDate + _depatureAt;
          const _tempToDate = _returnDate + _returnAt;

          const _fromDate = dayjs(_tempFromDate).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ")
          const _toDate = dayjs(_tempToDate).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ")

          // ======================== CREATE PAYLOAD FOR adhoc REQUEST ==============================
          const _packages = packageDetailsList.map(
            (p: NewRequestPackagedetailsDto) => ({
              packageTypeId: p.packageType.id,
              gatePassNo: p.gatePassNo,
              recipientContact: p.contactNumber,
              instructions: p.instructions,
              serialNo: p.id.toString(),
              isReturn: genaralInformationForm.isSameDay.value,
              fromDate: _fromDate,
              toDate: _toDate,
              reciepientId: p.recipient.id,
              packageLocations: [
                {
                  startLocation: p.pickup.location,
                  startLocationLatitude: p.pickupLatAndLng?.lat?.toLocaleString() || "",
                  startLocationLongitude: p.pickupLatAndLng?.lng?.toLocaleString() || "",
                  toLocation: p.dropOff.location,
                  toLocationLatitude: p.pickupLatAndLng?.lat?.toLocaleString() || "",
                  toLocationLongitude: p.pickupLatAndLng?.lng?.toLocaleString() || "",
                },
              ],
              items: p.items.map((i: NewRequestPackageDetailsItemsDto) => ({
                serialNo: i.serialNumber,
                description: i.description,
                cbm: Number(i.cbm),
                weight: Number(i.weight),
              })),
            })
          );

          const _passengers = passengerDetailsList.map(
            (p: NewRequestPassengerDetailsDto) => {
              const _obj = {
                userId: p.passenger.id,
                isReturn: genaralInformationForm.isSameDay.value,
                fromDate: _fromDate,
                toDate: _toDate,
                requestPassengerLocations: p.requestPassengerLocations
              };

              return _obj;
            }
          );

          const _sbu = genaralInformationForm.sbu.value.value;
          const _plant = genaralInformationForm.plant.value.value;
          const _department = genaralInformationForm.department.value.value;

          let _requestFor = -1;

          if (
            authorizedUser.data.roleId === USER_ROLES.SUPER_ADMIN ||
            authorizedUser.data.roleId === USER_ROLES.DIVISION_USER ||
            authorizedUser.data.roleId === USER_ROLES.SBU_MANAGER ||
            authorizedUser.data.roleId === USER_ROLES.TRANSPORT_MANAGER
          )
            _requestFor = Number(onBehalfOfSomeoneForm.someone.value.value);

          const _payload = {
            requestTypeId: genaralInformationForm.requestType.value.id,
            vehicleTypeId: genaralInformationForm.vehicleType.value.id,
            fromDateTime: _fromDate,
            toDateTime: _toDate,
            return: genaralInformationForm.isSameDay.value,
            fromLocation: genaralInformationForm.fromLocation.value.location,
            fromLocationLatitude: genaralInformationForm.startLocationLatAndLng.value.lat.toLocaleString(),
            fromLocationLongitude: genaralInformationForm.startLocationLatAndLng.value.lng.toLocaleString(),
            toLocation: genaralInformationForm.toLocation.value.location,
            toLocationLatitude: genaralInformationForm.toLocationLatAndLng.value.lat.toLocaleString(),
            toLocationLongitude: genaralInformationForm.toLocationLatAndLng.value.lng.toLocaleString(),
            vip: genaralInformationForm.isVIP.value,
            purpose: genaralInformationForm.purpose.value,
            isDraft: isDraft,
            approver: genaralInformationForm.approver.value.value,
            sbu: _sbu,
            plant: _plant,
            department: _department,
            distance: totalDistance,
            selectedTripId:
              selectedMatchingTemplate === -1 ? 0 : selectedMatchingTemplate,
            packages: _packages,
            requestPassengers: _passengers,
            templates: genaralInformationForm.isTemplate.value,
            templateTitle: genaralInformationForm.templateTitle.value,
            createFor: _requestFor,
          };
          dispatch(requestActions.createNewRequest(_payload));

        } else {
          const _dateRange = genaralInformationForm.depatureDate.value as Array<Dayjs>;
          const _depatureAt = dayjs(genaralInformationForm.departureTime.value).format("HH:mmZ");
          const _returnAt = dayjs(genaralInformationForm.returnTime.value).format("HH:mmZ");
          const _dates: string[] = []
          _dateRange.forEach((date) => {
            const _fromDate = dayjs(date).format("YYYY-MM-DDT") + _depatureAt;
            _dates.push(dayjs(_fromDate).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ"))
          });

          // ======================== CREATE PAYLOAD FOR RECCURRENT REQUEST ==============================
          const _packages = packageDetailsList.map(
            (p: NewRequestPackagedetailsDto) => ({
              packageTypeId: p.packageType.id,
              gatePassNo: p.gatePassNo,
              recipientContact: p.contactNumber,
              instructions: p.instructions,
              serialNo: p.id.toString(),
              isReturn: genaralInformationForm.isSameDay.value,
              reciepientId: p.recipient.id,
              packageLocations: [
                {
                  startLocation: p.pickup.location,
                  startLocationLatitude: p.pickupLatAndLng?.lat?.toLocaleString() || "",
                  startLocationLongitude: p.pickupLatAndLng?.lng?.toLocaleString() || "",
                  toLocation: p.dropOff.location,
                  toLocationLatitude: p.pickupLatAndLng?.lat?.toLocaleString() || "",
                  toLocationLongitude: p.pickupLatAndLng?.lng?.toLocaleString() || "",
                },
              ],
              items: p.items.map((i: NewRequestPackageDetailsItemsDto) => ({
                serialNo: i.serialNumber,
                description: i.description,
                cbm: Number(i.cbm),
                weight: Number(i.weight),
              })),
            })
          );

          const _passengers = passengerDetailsList.map(
            (p: NewRequestPassengerDetailsDto) => {
              const _obj = {
                userId: p.passenger.id,
                isReturn: genaralInformationForm.isSameDay.value,
                requestPassengerLocations: p.requestPassengerLocations
              };

              return _obj;
            }
          );

          const _sbu = genaralInformationForm.sbu.value.value;
          const _plant = genaralInformationForm.plant.value.value;
          const _department = genaralInformationForm.department.value.value;

          let _requestFor = null;

          if (
            authorizedUser.data.roleId === USER_ROLES.SUPER_ADMIN ||
            authorizedUser.data.roleId === USER_ROLES.DIVISION_USER ||
            authorizedUser.data.roleId === USER_ROLES.SBU_MANAGER ||
            authorizedUser.data.roleId === USER_ROLES.TRANSPORT_MANAGER
          )
            _requestFor = Number(onBehalfOfSomeoneForm.someone.value.value);

          const _payload = {
            requestTypeId: genaralInformationForm.requestType.value.id,
            vehicleTypeId: genaralInformationForm.vehicleType.value.id,
            dateRange: _dates,
            returnTime: dayjs(_dateRange[0]).add(330, 'minute').format("YYYY-MM-DDT") + _returnAt,
            return: genaralInformationForm.isSameDay.value,
            fromLocation: genaralInformationForm.fromLocation.value.location,
            fromLocationLatitude: genaralInformationForm.startLocationLatAndLng.value.lat.toLocaleString(),
            fromLocationLongitude: genaralInformationForm.startLocationLatAndLng.value.lng.toLocaleString(),
            toLocation: genaralInformationForm.toLocation.value.location,
            toLocationLatitude: genaralInformationForm.toLocationLatAndLng.value.lat.toLocaleString(),
            toLocationLongitude: genaralInformationForm.toLocationLatAndLng.value.lng.toLocaleString(),
            vip: genaralInformationForm.isVIP.value,
            purpose: genaralInformationForm.purpose.value,
            isDraft: isDraft,
            approver: genaralInformationForm.approver.value.value,
            sbu: _sbu,
            plant: _plant,
            department: _department,
            distance: totalDistance,
            selectedTripId:
              selectedMatchingTemplate === -1 ? 0 : selectedMatchingTemplate,
            packages: _packages,
            requestPassengers: _passengers,
            templates: genaralInformationForm.isTemplate.value,
            templateTitle: genaralInformationForm.templateTitle.value,
            createFor: _requestFor,
          };

          dispatch(requestActions.createNewReccurentRequest(_payload));
        }
    } else {

      const setAlert: AlertDto = {
        message: "Please fill required field/s",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: "error",
        },
      };
      dispatch(alertActions.triggerAlert(setAlert));
    }
  };

  const editRequest = async () => {
    const [validateData, isValid] = await validateFormData(
      genaralInformationForm
    );
    console.log("validateData", validateData)
    setGenaralInformationForm(validateData);
    if (isValid) {
      let _isError = false;
      const _date = dayjs(new Date()).format("YYYY-MM-DDT");
      const _depatureDate = dayjs(genaralInformationForm.depatureDate.value as Dayjs).format("YYYY-MM-DDT");
      const _depatureAt = dayjs(genaralInformationForm.departureTime.value).format("HH:mmZ");
      const _returnDate = dayjs(genaralInformationForm.returnDate.value as Dayjs).format("YYYY-MM-DDT");
      const _returnAt = dayjs(genaralInformationForm.returnTime.value).format("HH:mmZ");

      const _fromDate = _date + _depatureAt;
      const _toDate = _date + _returnAt;

      console.log("dayjs(_depatureDate).isSame(_returnDate)", dayjs(_depatureDate).isSame(_returnDate))
      console.log("dayjs(_fromDate).isAfter(_toDate)", dayjs(_fromDate).isAfter(_toDate))

      if (dayjs(_fromDate).isAfter(_toDate) && dayjs(_depatureDate).isSame(_returnDate)) {
        _isError = true
        const setAlert: AlertDto = {
          message: "Return time should be greater than depature time.!",
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: "error",
          },
        };
        dispatch(alertActions.triggerAlert(setAlert));
      }
      if (passengerDetailsList.length === 0 && packageDetailsList.length === 0) {
        _isError = true;
        const setAlert: AlertDto = {
          message: "Please select passenger/package for update a request.!",
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: "error",
          },
        };
        dispatch(alertActions.triggerAlert(setAlert));
      }
      if (packageDetailsList.length === 0) {
        if (onBehalfOfSomeoneForm.isForSomeone.value) {
          if (!passengerDetailsList.some((p) => p.passenger.id === onBehalfOfSomeoneForm.someone.value.value)) {
            _isError = true;
            const setAlert: AlertDto = {
              message:
                "Invalid request! Request pointer person must be in the passenger list.",
              type:
                ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
              options: {
                key: new Date().getTime() + Math.random(),
                varient: "error",
              },
            };
            dispatch(alertActions.triggerAlert(setAlert));
          }
        } else {
          if (!passengerDetailsList.some((p) => p.passenger.id === authorizedUser.data.userId)) {
            _isError = true;
            const setAlert: AlertDto = {
              message:
                "Invalid request! Request creator must be in the passenger list.",
              type:
                ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
              options: {
                key: new Date().getTime() + Math.random(),
                varient: "error",
              },
            };
            dispatch(alertActions.triggerAlert(setAlert));
          }
        }
      }

      if (!_isError) {
        const _depatureDate = dayjs(genaralInformationForm.depatureDate.value as Dayjs).format("YYYY-MM-DDT");
        const _depatureAt = dayjs(genaralInformationForm.departureTime.value).format("HH:mmZ");
        const _returnDate = dayjs(genaralInformationForm.returnDate.value as Dayjs).format("YYYY-MM-DDT");
        const _returnAt = dayjs(genaralInformationForm.returnTime.value).format("HH:mmZ");

        const _tempFromDate = _depatureDate + _depatureAt;
        const _tempToDate = _returnDate + _returnAt;

        const _fromDate = dayjs(_tempFromDate).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ")
        const _toDate = dayjs(_tempToDate).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ")

        const _payload = getEditRequestPayload(_fromDate, _toDate, isDraft);
        dispatch(requestActions.editRequest(_payload));
      }

    } else {
      const setAlert: AlertDto = {
        message: "Please fill required field/s",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: "error",
        },
      };
      dispatch(alertActions.triggerAlert(setAlert));
    }
  };

  const getEditRequestPayload = (
    fromDate: string,
    toDate: string,
    isDraft: boolean
  ) => {
    const _packages = packageDetailsList.map(
      (p: NewRequestPackagedetailsDto) => ({
        packageTypeId: p.packageType.id,
        gatePassNo: p.gatePassNo,
        recipientContact: p.contactNumber,
        instructions: p.instructions,
        serialNo: p.id.toString(),
        isReturn: genaralInformationForm.isSameDay.value,
        fromDate: fromDate,
        toDate: toDate,
        reciepientId: p.recipient.id,
        packageLocations: [
          {
            startLocation: p.pickup.location,
            startLocationLatitude: p.pickupLatAndLng?.lat?.toLocaleString() || "",
            startLocationLongitude: p.pickupLatAndLng?.lng?.toLocaleString() || "",
            toLocation: p.dropOff.location,
            toLocationLatitude: p.pickupLatAndLng?.lat?.toLocaleString() || "",
            toLocationLongitude: p.pickupLatAndLng?.lng?.toLocaleString() || "",
          },
        ],
        items: p.items.map((i: NewRequestPackageDetailsItemsDto) => ({
          serialNo: i.serialNumber,
          description: i.description,
          cbm: Number(i.cbm),
          weight: Number(i.weight),
        })),
      })
    );

    const _passengers = passengerDetailsList.map(
      (p: NewRequestPassengerDetailsDto) => {
        const _obj = {
          userId: p.passenger.id,
          isReturn: genaralInformationForm.isSameDay.value,
          fromDate: fromDate,
          toDate: toDate,
          requestPassengerLocations: p.requestPassengerLocations
        };

        return _obj;
      }
    );

    const _payload = {
      id: sessionStorage.getItem("id"),
      requestTypeId: genaralInformationForm.requestType.value.id,
      vehicleTypeId: genaralInformationForm.vehicleType.value.id,
      fromDateTime: fromDate,
      toDateTime: toDate,
      return: genaralInformationForm.isSameDay.value,
      fromLocation: genaralInformationForm.fromLocation.value.location,
      fromLocationLatitude: genaralInformationForm.startLocationLatAndLng.value.lat.toLocaleString(),
      fromLocationLongitude: genaralInformationForm.startLocationLatAndLng.value.lng.toLocaleString(),
      toLocation: genaralInformationForm.toLocation.value.location,
      vip: genaralInformationForm.isVIP.value,
      purpose: genaralInformationForm.purpose.value,
      isDraft: isDraft,
      approver: genaralInformationForm.approver.value.value,
      distance: totalDistance,
      selectedTripId:
        selectedMatchingTemplate === -1 ? 0 : selectedMatchingTemplate,
      packages: _packages,
      requestPassengers: _passengers,
      template: genaralInformationForm.isTemplate.value,
      templateTitle: genaralInformationForm.templateTitle.value,
      deprtmentId: genaralInformationForm.department.value.value,
      plantId: genaralInformationForm.plant.value.value,
      sbuId: genaralInformationForm.sbu.value.value,
    };

    return _payload;
  };

  const onClose = () => {
    if(screenMode === REQUEST_SCREEN_MODES.PENDING_VEHICLE_VIEW){
      navigate(APP_ROUTES.TM_VEHICLE_ALLOCATION);
    }else if(screenMode === REQUEST_SCREEN_MODES.LM_APPROVAL_VIEW){
      navigate(APP_ROUTES.LM_REQUEST_APPROVAL)
    }else if(screenMode === REQUEST_SCREEN_MODES.TM_APPROVAL_VIEW){
      navigate(APP_ROUTES.TM_REQUEST_APPROVAL)
    }else{
      navigate(APP_ROUTES.REQUEST_CREATION);
    }
    
    setAsInitialState();
  };

  const setAsInitialState = () => {
    setGenaralInformationForm(GENERAL_INFORMATION_FORM_INITIAL_STATE);
    setPassengerDetailsForm(PASSENGER_DETAILS_FORM_INITIAL_STATE);
    setPackageDetailsForm(PACKAGE_DETAILS_FORM_INITIAL_STATE);
    setPackageItemsForm(PACKAGE_DETAILS_ITEMS_FORM_INITIAL_STATE);
    setPassengerDetailsList([]);
    setPackageDetailsList([]);
    setPackageDetailItemsList([]);
    setIsEdit(false);
    setIsDraft(false);
    setIsEditItem(false);
    setInputValue("");
    setSelectedMatchingTemplate(-1);
    setSelectedTemplateFreez(false);
    dispatch(mapActions.ADDPickUpPointsClear())
    dispatch(mapActions.AddEndMapPointClear())
    dispatch(mapActions.addDropOffPointsClear())
    dispatch(mapActions.AddStartMapPointClear())
    dispatch(mapActions.AddStartMapPointClear());
    dispatch(mapActions.ADDPickUpPointsClear());
    dispatch(mapActions.AddEndMapPointClear());
    dispatch(mapActions.addDropOffPointsClear());
  };

  const showPopup = (con: boolean) => {
    setIsOpenAddPackagePopup(con);
    if (!con) {
      setPackageDetailsForm({
        ...PACKAGE_DETAILS_FORM_INITIAL_STATE,
        pickup: {
          ...PACKAGE_DETAILS_FORM_INITIAL_STATE.pickup,
          value: genaralInformationForm.fromLocation.value,
        },
        dropOff: {
          ...PACKAGE_DETAILS_FORM_INITIAL_STATE.dropOff,
          value: genaralInformationForm.toLocation.value,
        }
      });
      setPackageItemsForm(PACKAGE_DETAILS_ITEMS_FORM_INITIAL_STATE);
      setPackageDetailItemsList([]);
      setIsEdit(false);
    }
  };

  const showAddMultipleLocationPopup = (con: boolean) => {
    setIsOpenAddMultipleLocationPopup(con);
  };

  const onPassengerDetailsSortHandle = (col: string) => {
    const sorted = passengerDetailsList.sort((_prev: any, _next: any) => {
      let _prevItem, _nextItem;
      if (col === "passenger") {
        _prevItem = _prev[col].name;
        _nextItem = _next[col].name;
      }
      if (col === "department") {
        _prevItem = _prev[col];
        _nextItem = _next[col];
      }
      if (col === "dropOff") {
        _prevItem = _prev[col].location;
        _nextItem = _next[col].location;
      }

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setPassengerDetailsList(sorted);
  };

  const onPackageDetailsSortHandle = (col: string) => {
    const sorted = packageDetailsList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setPackageDetailsList(sorted);
  };

  const onPackageDetailItemsSortHandle = (col: string) => {
    const sorted = packageDetailItemsList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setPackageDetailItemsList(sorted);
  };
  const SelectedType_Initial_State: selectedType = {
    lat: 0,
    lng: 0
  }

  const [startLocation, setStartLocation] = useState<selectedType>(SelectedType_Initial_State);
  const [endLocation, setEndLocation] = useState<selectedType>(SelectedType_Initial_State);

  const [PickUpPoints, setPickUpPoints] = useState<selectedType[]>([]);
  const [DropOffPoints, setDropOffPoints] = useState<selectedType[]>([]);

  const [PassengerdropPoints, setPassengerDropOffPoints] = useState<selectedType[]>([]);
  const [PackageDropOffPoints, setPackageDropOffPoints] = useState<selectedType[]>([]);



  const [PassengerPickPoints, setPassengerPickPoints] = useState<selectedType[]>([]);
  const [PackagePickPoints, setPackagePickPoints] = useState<selectedType[]>([]);


  useEffect(() => {

    if (requestByIdResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setStartLocation({ lat: Number(requestByIdResponse.data?.fromLocationLatitude), lng: Number(requestByIdResponse.data?.fromLocationLongitude) })
      setEndLocation({ lat: Number(requestByIdResponse.data?.toLocationLatitude), lng: Number(requestByIdResponse.data?.toLocationLongitude) })
    }

  }, [requestByIdResponse.status])


  useEffect(() => {


    const startLocationLatAndLngArray: selectedType[] = passengerDetailsList.map((passengerDetail) => ({
      lat: Number(passengerDetail.requestPassengerLocations.map((p) => p.startLocationLatitude)),
      lng: Number(passengerDetail.requestPassengerLocations.map((p) => p.startLocationLongitude))
    }));

    const intermediateLocationLatAndLngArray: selectedType[] = passengerDetailsList.map((passengerDetail) => ({

      lat: Number(passengerDetail.requestPassengerLocations.map((p) => p.intermediateLocationLatitude)),
      lng: Number(passengerDetail.requestPassengerLocations.map((p) => p.intermediateLocationLongitude)),
    }));

    console.log("startLocationLatAndLngArray", startLocationLatAndLngArray);
    console.log("intermediateLocationLatAndLngArray", intermediateLocationLatAndLngArray);
    console.log("passengerDetailsList", passengerDetailsList);


    if (intermediateLocationLatAndLngArray.length > 0) {
      const combinedLocationLatAndLnArray: selectedType[] = startLocationLatAndLngArray.concat(intermediateLocationLatAndLngArray);
      const uniqueLocationLatAndLngArray: selectedType[] = Array.from(new Set(combinedLocationLatAndLnArray));
      setPassengerPickPoints(uniqueLocationLatAndLngArray)
      console.log("uniqueLocationLatAndLngArray,", uniqueLocationLatAndLngArray);
    }

    const toLocationLatAndLng: selectedType[] = passengerDetailsList.map((passengerDetail) => ({
      lat: Number(passengerDetail.requestPassengerLocations.map((p) => p.toLocationLatitude)),
      lng: Number(passengerDetail.requestPassengerLocations.map((p) => p.toLocationLongitude)),
    }));

    const dropLocationLatAndLng: selectedType[] = passengerDetailsList.map((passengerDetail) => ({
      lat: Number(passengerDetail.requestPassengerLocations.map((p) => p.dropLocationLatitude)),
      lng: Number(passengerDetail.requestPassengerLocations.map((p) => p.dropLocationLongitude)),
    }));

    if (dropLocationLatAndLng.length > 0) {
      const combinedLocationLatAndLngArray: selectedType[] = toLocationLatAndLng.concat(dropLocationLatAndLng);
      const uniqueLocationLatAndLngArray: selectedType[] = Array.from(new Set(combinedLocationLatAndLngArray));
      setPassengerDropOffPoints(uniqueLocationLatAndLngArray)
      console.log("uniqueLocationLatAndLngArray2,", uniqueLocationLatAndLngArray);
    }

  }, [passengerDetailsList.length])


  useEffect(() => {
    const pickupLatAndLngArry: selectedType[] = packageDetailsList.map((packages) => packages.pickupLatAndLng).filter((obj) => Object.keys(obj).length !== 0);
    const dropOffLatAndLngArry: selectedType[] = packageDetailsList.map((packages) => packages.dropOffLatAndLng).filter((obj) => Object.keys(obj).length !== 0);
    setPackagePickPoints(pickupLatAndLngArry)
    setPackageDropOffPoints(dropOffLatAndLngArry)

  }, [packageDetailsList, packageDetailsList.length])


  useEffect(() => {
    if (PassengerPickPoints.length > 0) {
      const combinedPickUpPoints = PassengerPickPoints.concat(PackagePickPoints);
      const uniqueLocationLatAndLngArray: selectedType[] = Array.from(new Set(combinedPickUpPoints));
      console.log("combinedPickUpPoints:", uniqueLocationLatAndLngArray);
      setPickUpPoints(uniqueLocationLatAndLngArray);
    }
    if (PassengerdropPoints.length > 0) {
      const combinedDropOffPoints = PassengerdropPoints.concat(PackageDropOffPoints);
      const uniqueLocationLatAndLngArray: selectedType[] = Array.from(new Set(combinedDropOffPoints));
      console.log("combinedDropOffPoints:", uniqueLocationLatAndLngArray);
      setDropOffPoints(uniqueLocationLatAndLngArray)
    }
  }, [PackageDropOffPoints, PackageDropOffPoints.length, PackagePickPoints, PackagePickPoints.length, PassengerPickPoints, PassengerPickPoints.length, PassengerdropPoints, PassengerdropPoints.length])


  useEffect(() => {
    dispatch(mapActions.AddStartMapPoint(startLocation));
    dispatch(mapActions.AddEndMapPoint(endLocation));

  }, [startLocation, endLocation])




  useEffect(() => {
    if (DropOffPoints.length) {
      dispatch(mapActions.addDropOffPointsClear());
      dispatch(mapActions.addDropOffPoints(DropOffPoints));
    }
  }, [DropOffPoints, DropOffPoints.length])

  useEffect(() => {
    if (PickUpPoints.length) {
      dispatch(mapActions.ADDPickUpPointsClear());
      dispatch(mapActions.ADDPickUpPoints(PickUpPoints));
    }
  }, [PickUpPoints, PickUpPoints.length])


  const onInputHandleChangeGoogleInput = (property: string, value: any, section: string) => {
    console.log("property", property, "value", value);
    if (section === "GI") {
      if (property === "fromLocation") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          fromLocation: {
            ...genaralInformationForm.fromLocation,
            value: { location: value.address, id: value.value, } as LocationBriefDto,
          },
          startLocationLatAndLng: {
            ...genaralInformationForm.startLocationLatAndLng,
            value: value.latAndLang as selectedType
          }
        });
        setPassengerDetailsForm({
          ...passengerDetailsForm,
          startLocation: {
            ...passengerDetailsForm.startLocation,
            value: { location: value.address, id: value.value, } as LocationBriefDto,
          },
          startLocationLatAndLng: {
            ...passengerDetailsForm.startLocationLatAndLng,
            value: value.latAndLang as selectedType
          }
        });
      }
      if (property === "toLocation") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          toLocation: {
            ...genaralInformationForm.toLocation,
            value: { location: value.address, id: value.value, } as LocationBriefDto,
          },
          toLocationLatAndLng: {
            ...genaralInformationForm.toLocationLatAndLng,
            value: value.latAndLang as selectedType
          }
        });
        setPassengerDetailsForm({
          ...passengerDetailsForm,
          toLocation: {
            ...passengerDetailsForm.toLocation,
            value: { location: value.address, id: value.value, } as LocationBriefDto,
          },
          toLocationLatAndLng: {
            ...passengerDetailsForm.toLocationLatAndLng,
            value: value.latAndLang as selectedType
          }
        });
      }
    }
    if (section === "PI") {
      if (property === "fromLocation") {
        setPassengerDetailsForm({
          ...passengerDetailsForm,
          startLocation: {
            ...passengerDetailsForm.startLocation,
            value: { location: value.address, id: value.value, } as LocationBriefDto,
          },
          startLocationLatAndLng: {
            ...passengerDetailsForm.startLocationLatAndLng,
            value: value.latAndLang as selectedType
          }
        });
      }

      if (property === "toLocation") {
        setPassengerDetailsForm({
          ...passengerDetailsForm,
          toLocation: {
            ...passengerDetailsForm.toLocation,
            value: { location: value.address, id: value.value, } as LocationBriefDto,
          },
          toLocationLatAndLng: {
            ...passengerDetailsForm.toLocationLatAndLng,
            value: value.latAndLang as selectedType
          }
        });
      }
   
      if (property === "intermediateLocation") {

        console.log("intermediateLocationPropert");
        setPassengerDetailsForm({
          ...passengerDetailsForm,
          intermediateLocation: {
            ...passengerDetailsForm.intermediateLocation,
            value: { location: value.address, id: value.value, } as LocationBriefDto,
          },
          intermediateLocationLatAndLng: {
            ...passengerDetailsForm.intermediateLocationLatAndLng,
            value: value.latAndLang as selectedType
          }
        });
      }
      if (property === "dropLocation") {
        setPassengerDetailsForm({
          ...passengerDetailsForm,
          dropLocation: {
            ...passengerDetailsForm.dropLocation,
            value: { location: value.address, id: value.value, } as LocationBriefDto,
          },
          dropLocationLatAndLng: {
            ...passengerDetailsForm.dropLocationLatAndLng,
            value: value.latAndLang as selectedType
          }
        });
      }

    }
    if (section === "PK") {
      if (property === "pickLocation") {
        setPackageDetailsForm({
          ...packageDetailsForm,
          pickup: {
            ...packageDetailsForm.pickup,
            value: { location: value.address, id: value.value, } as LocationBriefDto,
          },
          pickupLatAndLng: {
            ...packageDetailsForm.pickupLatAndLng,
            value: value.latAndLang as selectedType
          }
        });
      }
      if (property === "dropOffLocation") {
        setPackageDetailsForm({
          ...packageDetailsForm,
          dropOff: {
            ...packageDetailsForm.dropOff,
            value: { location: value.address, id: value.value, } as LocationBriefDto,
          },
          dropOffLatAndLng: {
            ...packageDetailsForm.dropOffLatAndLng,
            value: value.latAndLang as selectedType
          }
        });
      }


    }
  }
  return (
    <React.Fragment>
      <AppLayout componentTitle="New Request Creation">
        <section className="page-root">
          <section className={style.sectionCard}>
            <section className={style.sectionCardHeader}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                  <Typography noWrap component="div" className={style.title}>
                    <h3>Manage Requests</h3>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {(screenMode === REQUEST_SCREEN_MODES.CREATE ||
                    screenMode === REQUEST_SCREEN_MODES.USE_TEMPLATE) && (
                      <CustomAutocomplete
                        label="Select saved templates"
                        placeholder="Select saved templates"
                        onFocus={() => { }}
                        options={allTemplates.map((t: TemplatesDto) => {
                          return {
                            label: t.title + " ",
                            value: t.id,
                          };
                        })}
                        value={selectedTemplateVal}
                        error={false}
                        disabled={false}
                        readonly={selectedTemplateFreez}
                        required={false}
                        helperText={""}
                        onChange={(event: any, value: any) =>
                          onInputHandleChangeSelectTemplate(value)
                        }
                      />
                    )}
                </Grid>
              </Grid>
            </section>

            <section className={style.sectionCardBody}>
              <section className={style.stepperRoot}>
                {(authorizedUser.data.roleId === USER_ROLES.SBU_MANAGER ||
                  authorizedUser.data.roleId === USER_ROLES.DIVISION_USER ||
                  authorizedUser.data.roleId === USER_ROLES.SUPER_ADMIN ||
                  authorizedUser.data.roleId === USER_ROLES.TRANSPORT_MANAGER
                  || (screenMode === REQUEST_SCREEN_MODES.VIEW && authorizedUser.data.roleId === USER_ROLES.LINE_MANAGER)) && (
                    <OnBehalfOfSomeone
                      helperText={helperText}
                      isLoading={requestByIdResponse.isLoading}
                      screenMode={screenMode}
                      onBehalfOfSomeoneForm={onBehalfOfSomeoneForm}
                      handleInputFocus={handleInputFocus}
                      onInputHandleChangeRequestForSomeone={
                        onInputHandleChangeRequestForSomeone
                      }
                      allSbuList={allSbuList || []}
                      allPlantsBySbuIdResponse={allPlantsBySbuIdResponse}
                      allDepartmentByPlantIdResponse={
                        allDepartmentByPlantIdResponse
                      }
                      getUsersByDepartmentResponse={
                        getUsersByDepartmentResponse
                      }
                    />
                  )}
                <GeneralInformation
                  vehicleTypesResponse={vehicleTypesResponse}
                  frequentLocations={frequentLocationsResponse.data || []}
                  allSbuList={allSbuList || []}
                  allApproverResponse={allApproverResponse}
                  defaultApproverResponse={defaultApproverResponse}
                  helperText={helperText}
                  handleInputFocus={handleInputFocus}
                  genaralInformationForm={genaralInformationForm}
                  onInputHandleChangeGenaralInformation={
                    onInputHandleChangeGenaralInformation
                  }
                  onInputHandleChangeGoogleInput={onInputHandleChangeGoogleInput}
                  requestByIdResponse={requestByIdResponse}
                  allPlantsBySbuIdResponse={allPlantsBySbuIdResponse}
                  allDepartmentByPlantIdResponse={
                    allDepartmentByPlantIdResponse
                  }
                  onSearchValueChange={onSearchValueChange}
                  screenMode={screenMode}
                  setStartLocation={setStartLocation}
                  setEndLocation={setEndLocation}
                  switchValue={switchValue}
                  switchValue2={switchValue2}
                />
                <PessengerDetails
                  onSortHandle={onPassengerDetailsSortHandle}
                  allPassengerResponse={allPassengerResponse}
                  frequentLocations={frequentLocationsResponse.data || []}
                  passengerDetailsList={passengerDetailsList || []}
                  showAddMultipleLocationPopup={showAddMultipleLocationPopup}
                  handleInputFocus={handleInputFocus}
                  helperText={helperText}
                  passengerDetailsForm={passengerDetailsForm}
                  onInputHandleChangePassengerDetails={
                    onInputHandleChangePassengerDetails
                  }
                  onInputHandleChangeGoogleInput={onInputHandleChangeGoogleInput}
                  onPushPassenger={onPushPassenger}
                  onClearPassengerDetails={onClearPassengerDetails}
                  passengerByIdResponse={passengerByIdResponse}
                  screenMode={screenMode}
                  setSelectedPassengerItem={setSelectedPassengerItem}
                  onSearchValueChange={onSearchValueChange}
                  onSelectPassengerForEdit={onSelectPassengerForEdit}
                  onEditPassenger={onEditPassenger}
                  isEdit={isEdit}
                  switchValue={switchValue}
                  switchValue2={switchValue2}
                />
                <PackageDetails
                  onSortHandle={onPackageDetailsSortHandle}
                  showAddPackagePopup={showPopup}
                  packageDetailsList={packageDetailsList}
                  screenMode={screenMode}
                  setSelectedPackageFromList={setSelectedPackageFromList}
                  onSelectPackageForEdit={onSelectPackageForEdit}
                  onSelectPackageForView={onSelectPackageForView}
                />

                <Suggestions
                  getMatchingTemplates={getMatchingTemplates}
                  matchingTemplatesResponse={matchingTemplatesResponse}
                  selectTemplate={selectTemplate}
                  clickOnce={clickOnce}
                />

              </section>

              {(sessionStorage.getItem("Mode") === REQUEST_SCREEN_MODES.CREATE ||
                sessionStorage.getItem("Mode") === REQUEST_SCREEN_MODES.EDIT) && (
                  <section className={style.saveAs}>
                    <div className={style.switchField}>
                      <Typography className={style.label}>
                        Save as template
                      </Typography>
                      <StyledSwitch
                        checked={genaralInformationForm.isTemplate.value}
                        disabled={genaralInformationForm.isTemplate.disable}
                        onChange={() =>
                          onInputHandleChangeGenaralInformation(
                            "isTemplate",
                            !genaralInformationForm.isTemplate.value
                          )
                        }
                      />
                    </div>
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <StyledTextField
                          fullWidth
                          label="Template title"
                          placeholder='Enter title'
                          size='small'
                          InputProps={{
                            readOnly: genaralInformationForm.templateTitle.readonly
                          }}
                          value={genaralInformationForm.templateTitle.value}
                          error={!!genaralInformationForm.templateTitle.error}
                          disabled={genaralInformationForm.templateTitle.disable}
                          required={genaralInformationForm.templateTitle.isRequired}
                          helperText={helperText && genaralInformationForm.templateTitle.error}
                          onFocus={() => handleInputFocus('templateTitle', 'GI')}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onInputHandleChangeGenaralInformation('templateTitle', event.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </section>
                )}

              <section className={style.sectionCardFooter}>
                <CustomButton
                  text="Close"
                  textColor="black"
                  bgColor="#bfbfbf"
                  onClick={onClose}
                />
                {screenMode !== REQUEST_SCREEN_MODES.VIEW && (
                  <>
                    {(sessionStorage.getItem("Mode") ===
                      REQUEST_SCREEN_MODES.CREATE ||
                      sessionStorage.getItem("Mode") ===
                      REQUEST_SCREEN_MODES.USE_TEMPLATE) && (
                        <>
                          <CustomButton
                            text="Clear"
                            border="1px solid #6e6e6e"
                            bgColor="#282828"
                            onClick={setAsInitialState}
                          />
                          <CustomButton
                            text="Save draft"
                            border="1px solid #6e6e6e"
                            bgColor="#282828"
                            disabled={createNewRequestResponse.isLoading}
                            isLoading={
                              isDraftButtonClick &&
                              createNewRequestResponse.isLoading
                            }
                            onClick={() => createNewRequest(true)}
                          />
                          <CustomButton
                            text="Send Request"
                            disabled={createNewRequestResponse.isLoading}
                            isLoading={
                              !isDraftButtonClick &&
                              createNewRequestResponse.isLoading
                            }
                            onClick={() => createNewRequest(false)}
                          />
                        </>
                      )}
                    {sessionStorage.getItem("Mode") ===
                      REQUEST_SCREEN_MODES.EDIT && (
                        <CustomButton
                          text="Edit Request"
                          disabled={editRequestResponse.isLoading}
                          isLoading={editRequestResponse.isLoading}
                          onClick={editRequest}
                        />
                      )}
                  </>
                )}
              </section>
            </section>
          </section>

          <ShowAddPackagePopup
            isOpenAddPackagePopup={isOpenAddPackagePopup}
            showAddPackagePopup={showPopup}
            onSortHandle={onPackageDetailItemsSortHandle}
            helperText={helperText}
            packageDetailsForm={packageDetailsForm}
            packageItemsForm={packageItemsForm}
            onInputHandleChangePackageDetails={
              onInputHandleChangePackageDetails
            }
            onInputHandleChangePackageDetailItems={
              onInputHandleChangePackageDetailItems
            }
            onInputHandleChangeGoogleInput={onInputHandleChangeGoogleInput}
            handleInputFocus={handleInputFocus}
            onAddPackage={onAddPackage}
            onAddPackageItem={onAddPackageItem}
            onClearPackageItems={onClearPackageItems}
            frequentLocations={frequentLocationsResponse.data || []}
            packageDetailItemsList={packageDetailItemsList || []}
            packageTypes={packageTypes || []}
            allRecipientResponse={allRecipientResponse}
            onSearchValueChange={onSearchValueChange}
            isEdit={isEdit}
            onEditPackage={onEditPackage}
            screenMode={screenMode}
            setSelectedPackageItem={setSelectedPackageItem}
            onSelectPackageItemForEdit={onSelectPackageItemForEdit}
            isEditItem={isEditItem}
            onEditPackageItem={onEditPackageItem}
            switchValue={switchValue}
            switchValue2={switchValue2}
          />
          <ShowAddMultipleLocationPopup
            isOpenAddMultipleLocationPopup={isOpenAddMultipleLocationPopup}
            showAddMultipleLocationPopup={showAddMultipleLocationPopup}
            frequentLocations={frequentLocationsResponse.data || []}
            helperText={helperText}
            passengerDetailsForm={passengerDetailsForm}
            onInputHandleChangePassengerDetails={
              onInputHandleChangePassengerDetails
            }
            handleInputFocus={handleInputFocus}
          />
          <ConfirmationDialog
            isOpenConfirmationDialog={isOpenPassengerConfirmationPopup}
            onCallback={onPopPassenger}
            title="Remove Passenger"
            content="Do you want to remove this passenger ?"
          />
          <ConfirmationDialog
            isOpenConfirmationDialog={isOpenPackageConfirmationPopup}
            onCallback={onPopPackage}
            title="Remove Package"
            content="Do you want to remove this package ?"
          />
          <ConfirmationDialog
            isOpenConfirmationDialog={isOpenPackageItemConfirmationPopup}
            onCallback={onPopPackageItem}
            title="Remove Item"
            content="Do you want to remove this item ?"
          />
          <ConfirmationDialog
            isOpenConfirmationDialog={isOpenUseTemplatePopup}
            onCallback={onUseTemplate}
            title="Use Template"
            content="Do you want to use this template ?"
          />
        </section>
      </AppLayout>
    </React.Fragment>
  );
};

export default NewRequest;
