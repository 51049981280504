import { AxiosResponse } from "axios";
import { permissionService } from "../../services/permission.service";
import { call, put, takeEvery } from "redux-saga/effects";
import { ALERT_ACTION_TYPES, COMMON_ACTION_TYPES, PERMISSION_ACTION_TYPES,} from "../../utilities/constants";
import { AssignRolesforUsersParamDto, EditRolesforusersParamDto, GetAllUsersWithEntitlementsDTO, GetRolesAndEntitlementDto,
  GetSelectedUserDetailsByIdDto, UserByDeptDto, UserRolesEntitlementsDto, emailId,} from "../../utilities/models/permission.model";
import { AlertDto } from "../../utilities/models";

function* getUserByDept(action: { type: string; deptId: number }) {
  try {
    const user: AxiosResponse<UserByDeptDto[]> = yield call(
      permissionService.getUserByDept,
      action.deptId
    );
    yield put({
      type:
        PERMISSION_ACTION_TYPES.GET_USERS_LIST_BY_DEPT_ID +
        COMMON_ACTION_TYPES.SUCCESS,
      data: user.data,
    });
  } catch (error) {
    yield put({
      type:
        PERMISSION_ACTION_TYPES.GET_USERS_LIST_BY_DEPT_ID +
        COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* getRolesPermissions(action: { type: string }) {
  try {
    const RolesPermissions: AxiosResponse<UserRolesEntitlementsDto[]> =
      yield call(permissionService.getRolesEntitlements);
    yield put({
      type:
        PERMISSION_ACTION_TYPES.GET_ROLES_PERMISSIONS +
        COMMON_ACTION_TYPES.SUCCESS,
      data: RolesPermissions.data,
    });
  } catch (error) {
    yield put({
      type:
        PERMISSION_ACTION_TYPES.GET_ROLES_PERMISSIONS +
        COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* postAssignRolesUsers(action: { type: string; payload: AssignRolesforUsersParamDto;}) {
  try {
    const RolesPermissions: AxiosResponse<string> = yield call(
      permissionService.postAssignRolesUsers,
      action.payload
    );

    const setAlert: AlertDto = {
      message: RolesPermissions.data,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: "success",
      },
    };
    yield put(setAlert);
    yield put({
      type:
        PERMISSION_ACTION_TYPES.POST_ASSIGN_ROLES_FOR_USERS +
        COMMON_ACTION_TYPES.SUCCESS,
      data: RolesPermissions.data,
    });
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: "error",
      },
    };
    yield put(setAlert);
    yield put({
      type:
        PERMISSION_ACTION_TYPES.POST_ASSIGN_ROLES_FOR_USERS +
        COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* editRolesPermissions(action: { type: string; payload: EditRolesforusersParamDto;}) {
  try {
    const RolesPermissions: AxiosResponse<string> = yield call(
      permissionService.editAssignRolesUsers,
      action.payload
    );

    const setAlert: AlertDto = {
      message: "Permission updated successfully",
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: "success",
      },
    };
    yield put(setAlert);
    yield put({
      type:
        PERMISSION_ACTION_TYPES.EDIT_ASSIGN_ROLES_FOR_USERS +
        COMMON_ACTION_TYPES.SUCCESS,
      data: RolesPermissions.data,
    });
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: "error",
      },
    };
    yield put(setAlert);
    yield put({
      type:
        PERMISSION_ACTION_TYPES.EDIT_ASSIGN_ROLES_FOR_USERS +
        COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* GetVehicleLitsBYPassengerCount(action: { type: string; payload: any;}) {
  try {
    const DataResponse: AxiosResponse<GetAllUsersWithEntitlementsDTO> =
      yield call(permissionService.getAllUsersWithEntitlements, action.payload);
    yield put({
      type:
        PERMISSION_ACTION_TYPES.GET_ALL_USER_WITH_ENTITLEMENT +
        COMMON_ACTION_TYPES.SUCCESS,
      data: DataResponse.data,
    });
  } catch (error) {
    yield put({
      type:
        PERMISSION_ACTION_TYPES.GET_ALL_USER_WITH_ENTITLEMENT +
        COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* getSelecteUserDetailsById(action: { type: string; id: number }) {
  try {
    const DataResponse: AxiosResponse<GetSelectedUserDetailsByIdDto> =
      yield call(permissionService.getSelecteUserDetailsById, action.id);
    yield put({
      type:
        PERMISSION_ACTION_TYPES.GET_SELECTED_USER_DETAILS +
        COMMON_ACTION_TYPES.SUCCESS,
      data: DataResponse.data,
    });
  } catch (error) {
    yield put({
      type:
        PERMISSION_ACTION_TYPES.GET_SELECTED_USER_DETAILS +
        COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* getRolesAndEntitlementByUser(action: { type: string, payload: emailId }){
  try {
    const DataResponse: AxiosResponse<GetRolesAndEntitlementDto> = yield call(permissionService.getRolesAndEntitlementByUser, action.payload)
    yield put({
       type: PERMISSION_ACTION_TYPES.GET_ROLES_AND_ENTITLEMENT_BY_USER + COMMON_ACTION_TYPES.SUCCESS,
       data: DataResponse.data,
    })
  } catch (error) {
    yield put({
       type: PERMISSION_ACTION_TYPES.GET_ROLES_AND_ENTITLEMENT_BY_USER + COMMON_ACTION_TYPES.ERROR,
       error: error
    })
  }
 }


function* permissionSaga() {
  yield takeEvery( PERMISSION_ACTION_TYPES.GET_USERS_LIST_BY_DEPT_ID + COMMON_ACTION_TYPES.REQUEST, getUserByDept);
  yield takeEvery( PERMISSION_ACTION_TYPES.GET_ROLES_PERMISSIONS + COMMON_ACTION_TYPES.REQUEST, getRolesPermissions);
  yield takeEvery( PERMISSION_ACTION_TYPES.POST_ASSIGN_ROLES_FOR_USERS + COMMON_ACTION_TYPES.REQUEST, postAssignRolesUsers);
  yield takeEvery( PERMISSION_ACTION_TYPES.EDIT_ASSIGN_ROLES_FOR_USERS +  COMMON_ACTION_TYPES.REQUEST, editRolesPermissions);
  yield takeEvery( PERMISSION_ACTION_TYPES.GET_ALL_USER_WITH_ENTITLEMENT + COMMON_ACTION_TYPES.REQUEST, GetVehicleLitsBYPassengerCount);
  yield takeEvery( PERMISSION_ACTION_TYPES.GET_SELECTED_USER_DETAILS + COMMON_ACTION_TYPES.REQUEST, getSelecteUserDetailsById);
  yield takeEvery( PERMISSION_ACTION_TYPES.GET_ROLES_AND_ENTITLEMENT_BY_USER + COMMON_ACTION_TYPES.REQUEST, getRolesAndEntitlementByUser)
}

export default permissionSaga;
