import React, { useEffect, useState } from 'react'
import { AppLayout } from '../../templates'
import { ApplicationStateDto, OptionsDto, AlertDto, FuelTypeFormDto } from '../../utilities/models';
import style from './AddFuelTypes.module.scss';
import { Grid, Typography } from '@mui/material';
import { ALERT_ACTION_TYPES, APP_ACTION_STATUS, APP_ROUTES, COMMON_ACTION_TYPES, FUEL_TYPE_MODE, FUEL_TYPE_NAME } from '../../utilities/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { validateFormData } from '../../utilities/helpers';
import { alertActions, fuelActions } from '../../redux/actions';
import { ConfirmationDialog, CustomButton } from '../../components';
import FuelCostDetails from '../../components/FuelManagement/AddFuelTypes/AddFuelTypes';
import { AddNewFuelTypeDTO, FuelTypesFormDto, GetFuelListDto, GetFuelTypeDTO, NewFuelTypesFormDto } from '../../utilities/models/fuel.model';
import { number } from 'echarts';

const ManageFuelTypesScreen = () => {

  const FUEL_TYPE_INISTIAL_STATE: FuelTypeFormDto = {
    fuelType: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: 'object', error: '' },
    priceL: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '', },
  }
  const fuelTypesResponse = useSelector((state: ApplicationStateDto) => state.fuel.postFuelTypes);
  const GetFuelTypesResponse = useSelector((state: ApplicationStateDto) => state.fuel.getFuelTypesList)
  const [fuelTypeForm, setFuelTypeForm] = useState<FuelTypeFormDto>(FUEL_TYPE_INISTIAL_STATE)
  const [fuelTypeList, setFuelTypeList] = useState<GetFuelListDto[]>([])
  const [fuelList, setfuelList] = useState<GetFuelTypeDTO[]>([])
  //const AddFuelTypes = useSelector((state: ApplicationStateDto) => state.fuel.postFuelTypes)
  const [selectedFuel, setSelectedFuel] = useState(-1)
  const [isEditFuel, setIsEditFuel] = useState(false);
  const [helperText, setHelperText] = useState(true)
  const [isOpenRemoveFuel, setIsOpenRemoveFuel] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const setGeneralAlert: AlertDto = {
    message: "Mandatory fields not filled!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };
  const alreadyExistsAlert: AlertDto = {
    message: "Fuel type already added!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: 'error'
    }
  }

  useEffect(() => {
    if (fuelTypesResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setFuelTypeForm(FUEL_TYPE_INISTIAL_STATE);
      setFuelTypeList([])
      dispatch(fuelActions.postFuelRequestClear())
      navigate(APP_ROUTES.FUEL_REPORT)
    }
  }, [fuelTypesResponse.status])

  useEffect(() => {
    setFuelTypeForm({
      ...fuelTypeForm,
      fuelType: {
        ...fuelTypeForm.fuelType
      },
      priceL: {
        ...fuelTypeForm.priceL
      },
    })
  }, []);

  const onInputHandleChangeVehicleCost = (property: string, value: any) => {
    setHelperText(true)
    if (property === 'fuelType') {
      setFuelTypeForm(() => ({
        ...fuelTypeForm,
        fuelType: {
          ...fuelTypeForm.fuelType,
          value: value,
        }
      }))
    }
    if (property === 'priceL') {
      setFuelTypeForm(() => ({
        ...fuelTypeForm,
        priceL: {
          ...fuelTypeForm.priceL,
          value: value
        }
      }))
    }
  }

  const handleInputFocus = (property: string, section: string) => {

    if (section === "CD") {

      setFuelTypeForm({
        ...fuelTypeForm,
        [property]: {
          ...fuelTypeForm[property as keyof typeof fuelTypeForm],
          error: null,
        }
      })
    }

    if (section === "VCI") {
      setFuelTypeForm({
        ...fuelTypeForm,
        [property]: {
          ...fuelTypeForm[property as keyof typeof fuelTypeForm],
          error: null
        }
      })
    }

  }

  const onRemoveVehicleDetails = (con: boolean) => {
    if (con) {
      setFuelTypeList(fuelTypeList.filter((d) => d.id !== selectedFuel))
      setSelectedFuel(-1)
    }
    setIsOpenRemoveFuel(false)
  }

  const onSelectVehicleForEdit = (id: number) => {
    const _vehicle = fuelTypeList.find((v) => v.id === id)
    if (_vehicle)
      setFuelTypeForm({
        ...fuelTypeForm,
        fuelType: {
          ...fuelTypeForm.fuelType,
          value: { label: _vehicle.fuelType, value: _vehicle.fuelTypeId }
        },
        priceL: {
          ...fuelTypeForm.priceL,
          value: _vehicle.price
        },
      })
  }

  const onAddPrice = async () => {
    const [validateData, isValid] = await validateFormData(fuelTypeForm)
    setFuelTypeForm(validateData)

    // const _isTHere = fuelTypeList.find((v) => v.fuelType === FUEL_TYPE_NAME.PETROL_92);
    //  || FUEL_TYPE_NAME.PETROL_95||FUEL_TYPE_NAME.DIESEL_AUTO||FUEL_TYPE_NAME.DIESEL_SUPER||FUEL_TYPE_NAME.ELECTRIC);
    // console.log("check Oil type",_isTHere)

    // if(_isTHere !== undefined){
    //   console.log("addvalidation",_isTHere)
    //   dispatch(alertActions.triggerAlert(alreadyExistsAlert));
    //   setFuelTypeForm(FUEL_TYPEA_INISTIAL_STATE)

    // }else{

    if (isValid) {

      const isAlreadyExists = fuelTypeList.some(
        (item) => item.fuelType === fuelTypeForm.fuelType.value.label
      );

      if (isAlreadyExists) {
        // If the fuel type already exists, show an alert and reset the form
        const setAlert: AlertDto = {
          message: "Fuel type already exists!",
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        };
        dispatch(alertActions.triggerAlert(setAlert));
        setFuelTypeForm(FUEL_TYPE_INISTIAL_STATE);

      } else {
        const _id = fuelTypeList.length === 0 ? 1 : fuelTypeList[fuelTypeList.length - 1].id + 1

        const _vehiclePrice: GetFuelListDto = {
          id: _id,
          fuelType: fuelTypeForm.fuelType.value.label,
          fuelTypeId: Number(fuelTypeForm.fuelType.value.value),
          price: fuelTypeForm.priceL.value,
        }

        const _list = [...fuelTypeList, _vehiclePrice]
        setFuelTypeList(_list)
        setFuelTypeForm(FUEL_TYPE_INISTIAL_STATE)
      }
    } else {
      const setAlert: AlertDto = {
        message: "select mandatory fields.!",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'error'
        }
      }
      dispatch(alertActions.triggerAlert(setAlert))
    }
    // }
  }

  const onSelectVehicle = (id: number, action: string) => {
    setSelectedFuel(id)
    if (action === 'edit') {
      setIsEditFuel(true);
      onSelectVehicleForEdit(id)
    }
    if (action === 'delete') {
      setIsOpenRemoveFuel(true)
    }
  }

  const onEditPrice = async () => {
    const [validateData, isValid] = await validateFormData(fuelTypeForm);
    setFuelTypeForm(validateData);

    if (isValid) {
      fuelTypeList.forEach((p) => {
        if (p.id === selectedFuel) {
          p.id = selectedFuel;
          p.fuelType = fuelTypeForm.fuelType.value.label;
          p.fuelTypeId = Number(fuelTypeForm.fuelType.value.value);
          p.price = fuelTypeForm.priceL.value;
        }
      });
      setIsEditFuel(false);
      setFuelTypeForm(FUEL_TYPE_INISTIAL_STATE);
    }
  };

  const createNewSaveRequest = async (type: string) => {

    const vehiclePriceDetails = fuelTypeList.map((item) => {
      const data: AddNewFuelTypeDTO = {
        fuelType: item.fuelTypeId,
        price: Number(item.price)
      }

      return data
    })

    const _payload: NewFuelTypesFormDto = {
      addTypes: vehiclePriceDetails
    }
    dispatch(fuelActions.postFuelRequest(_payload))
  }

  const onClear = () => {
    setFuelTypeForm(FUEL_TYPE_INISTIAL_STATE);
  }

  const onNavigateClose = () => {
    navigate(APP_ROUTES.FUEL_REPORT)
  }


  return (

    <React.Fragment>
      <AppLayout componentTitle="Fuel manager dashboard">
        <section className="page-root">

          <section className={style.sectionCard}>
            <section className={style.sectionCardHeader}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={10}>
                  <Typography noWrap component="div" className={style.title}>
                    <h3>Fuel Cost Management</h3>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>

                </Grid>
              </Grid>
            </section>
            <section className={style.sectionCardBody}>
              <section className={style.stepperRoot}>

                <FuelCostDetails
                  onInputHandleChangeVehicleCost={onInputHandleChangeVehicleCost}
                  handleInputFocus={handleInputFocus}
                  helperText={helperText}
                  isEditFuel={isEditFuel}
                  onAddPrice={onAddPrice}
                  onEditPrice={onEditPrice}
                  fuelCostForm={fuelTypeForm}
                  fuelPriceList={fuelTypeList}
                  onSelectVehicle={onSelectVehicle}
                  onClear={onClear}
                //fuelList={fuelList}

                />


              </section>
              <section className={style.sectionCardFooter}>
                <CustomButton text='Close' textColor='black' bgColor='#bfbfbf' onClick={onNavigateClose} />
                {<CustomButton
                  text="Save"
                  disabled={fuelTypesResponse.isLoading}
                  isLoading={fuelTypesResponse.isLoading}
                  onClick={() => createNewSaveRequest("Create")}
                />}


              </section>
              <section className={style.sectionCardFooter}>

              </section>
            </section>
          </section>

          <ConfirmationDialog
            isOpenConfirmationDialog={isOpenRemoveFuel}
            onCallback={onRemoveVehicleDetails}
            title="Remove Vehicle Document Information"
            content="Do you want to remove this vehicle?"
          />

        </section>

      </AppLayout>
    </React.Fragment>
  );
};

export default ManageFuelTypesScreen;
