import React from 'react'
import style from './ManageTransportCompanyGrid.module.scss'
import { Typography, Box, TableContainer, Paper, Table, TableHead, TableRow, TableBody, IconButton, TablePagination, Tooltip } from '@mui/material';
import moment from 'moment';
import { StyledTableCell, StyledStatusApproved, StyledStatusRejected, StyledStatusPending, StyledStatusDraft } from '../../../assets/theme/theme';
import { APP_ROUTES, APP_TABLE_CONFIGS, REQUEST_STATUS, REQUEST_SCREEN_EXPRESS_MODES, REQUEST_SCREEN_MODES, TRANSPORT_COMPANY_MODE } from '../../../utilities/constants';
import { LMRequestSummaryListDto, SortMetaDto, getCompanyDetailsDTO } from '../../../utilities/models';
import { CustomButton, CustomHeaderCell, AppSkeleton } from '../../Shared';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { EditOutlined } from '@mui/icons-material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const ManageTransportCompanyGrid: React.FC<{
  page: number,
  rowsPerPage: number,
  isFiltered: boolean,
  onHandleChangePage(event: unknown, newPage: number): void,
  onHandleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void,
  requestDataIsLoading: boolean,
  filteredList: getCompanyDetailsDTO[],
  sortMeta: SortMetaDto,
  onSortHandle(col: string): void
  // onShowStatus(id: string): void
  onFilterHandle(col: string, value: any): void;
  getFilterList: (col: string) => string[];
  navigateTo(mode: string, id: string): void;
  onClearFilter(): void;
}> = (props) => {

  const navigate = useNavigate()

  return (

    <section className={style.gridContainer}>
      <div className={style.gridHeader}>
        <Typography
          noWrap
          component="div"
          className={style.gridTitle}
        >

          <h3>Transport Company Summary Table</h3>

        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <div className='layout-row'>
          {props.isFiltered &&
            <CustomButton text='Clear filter' textColor='black' bgColor='#bfbfbf' onClick={props.onClearFilter} />
          }
          <CustomButton icon={<AddIcon sx={{ fontSize: "20px" }} />} text="New Company" onClick={() => props.navigateTo(TRANSPORT_COMPANY_MODE.CREATE, "")}
          />
          {/* <CustomButton text='Pending Request' border='1px solid #6e6e6e' bgColor='#282828' onClick={() => navigate(APP_ROUTES.LM_REQUEST_APPROVAL)} /> */}
        </div>
      </div>

      <TableContainer component={Paper} className={style.grid}>
        <Table className={style.table}>
          <TableHead>
            <TableRow>
              <CustomHeaderCell width={180} id='companyId' sortable onSort={props.onSortHandle}>Company ID</CustomHeaderCell>
              <CustomHeaderCell width={220} id='transCompany' sortable onSort={props.onSortHandle}>Company Name</CustomHeaderCell>
              <CustomHeaderCell width={180} id='registeredNumber' sortable onSort={props.onSortHandle} > Company Registration Number</CustomHeaderCell>
              <CustomHeaderCell width={180} id='sbu' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Attached SBUs</CustomHeaderCell>
              <CustomHeaderCell width={180} id='status' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Status</CustomHeaderCell>
              <CustomHeaderCell width={180} id='paymentMethod' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Payment Method</CustomHeaderCell>
              <CustomHeaderCell width={220} id='address' sortable onSort={props.onSortHandle}>Address</CustomHeaderCell>
              <CustomHeaderCell width={220} id='telephoneNumber' sortable onSort={props.onSortHandle}>Phone Number</CustomHeaderCell>
              <CustomHeaderCell width={220} id='email' sortable onSort={props.onSortHandle}>Email</CustomHeaderCell>
              <CustomHeaderCell id="actions"> Actions</CustomHeaderCell>
            </TableRow>

          </TableHead>

          {props.requestDataIsLoading && (
            <AppSkeleton numOfRows={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE} numOfColumns={10} columnWidth={120} tag="table" />
          )}

          {!props.requestDataIsLoading && props.filteredList.length > 0 &&
            <TableBody>
              {props.filteredList.map((req: getCompanyDetailsDTO) => (
                <TableRow key={req.companyId}>
                  <StyledTableCell>{req.companyId} </StyledTableCell>
                  <StyledTableCell>{req.transCompany} </StyledTableCell>
                  <StyledTableCell>{req.registeredNumber} </StyledTableCell>
                  <StyledTableCell>{req.sbu}</StyledTableCell>
                  <StyledTableCell style={{ color: req.status ? "green" : "yellow" }}> {req.status ? "Active" : "Inactive"} </StyledTableCell>
                  <StyledTableCell>{req.paymentMethod === null ? 'Cash' : req.paymentMethod} </StyledTableCell>
                  <StyledTableCell>{req.address} </StyledTableCell>
                  <StyledTableCell>{req.telephoneNumber} </StyledTableCell>
                  <StyledTableCell>{req.email} </StyledTableCell>
                  <StyledTableCell >
                    <Box className='layout-row'>
                      <Box>
                        <IconButton size='small' onClick={() => { props.navigateTo(TRANSPORT_COMPANY_MODE.VIEW, req.companyId.toString()) }}>
                          <Tooltip title="View">
                            <RemoveRedEyeOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>

                      <Box>
                        <IconButton size='small' onClick={() => { props.navigateTo(TRANSPORT_COMPANY_MODE.EDIT, req.companyId.toString()) }}>
                          <Tooltip title="Edit">
                            <EditOutlined sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>

                      <Box>
                        <IconButton size='small' onClick={() => { props.navigateTo("delete", req.companyId.toString()) }}>
                          <Tooltip title="Delete">
                            <DeleteOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          }
          {!props.requestDataIsLoading && props.filteredList.length === 0 &&
            <TableBody>
              <TableRow>
                <StyledTableCell align="left" colSpan={10}>No data to preview</StyledTableCell>
              </TableRow>
            </TableBody>
          }
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_OPTIONS}
        component="div"
        labelRowsPerPage={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: 'white',
            }}
          >
            Items per page
          </div>
        }
        count={-1}
        page={props.page}
        onPageChange={props.onHandleChangePage}
        onRowsPerPageChange={props.onHandleChangeRowsPerPage}
        rowsPerPage={props.rowsPerPage}
        nextIconButtonProps={
          { disabled: -1 < props.filteredList.length && props.filteredList.length < props.rowsPerPage }
        }
        sx={{ backgroundColor: "#282828", color: "white" }}
      />
    </section>
  )
}

export default ManageTransportCompanyGrid