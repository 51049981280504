import React from 'react'
import Stepper from '../../Shared/Stepper/Stepper'
import style from './Address.module.scss'
import { CompanyAddressFormDto, GetCompanyDetailsById, StateObjectDto } from '../../../utilities/models';
import { StyledTextField } from '../../../assets/theme/theme';
import { Grid } from '@mui/material';
import { AppSkeleton, CustomAutocomplete } from '../../Shared';
import { sriLankanDistricts } from '../../../utilities/constants';
const Address: React.FC<{
  addressInformationForm: CompanyAddressFormDto;
  handleInputFocus(property: string, section: string): void;
  onInputHandleChange(property: string, value: any): void;
  helperText: boolean
  getTransportCompanyByIDResponse: StateObjectDto<GetCompanyDetailsById | null>
}> = (props) => {

  const _AddressLine1 = props.addressInformationForm.AddressLine1
  const _AddressLine2 = props.addressInformationForm.AddressLine2
  const _city = props.addressInformationForm.city
  const _district = props.addressInformationForm.district
  const _postalCode = props.addressInformationForm.postalCode
  const _province = props.addressInformationForm.province


  return (
    <Stepper stepNumber={2} stepTitle={"Address Information"}>
      {props.getTransportCompanyByIDResponse && !props.getTransportCompanyByIDResponse.isLoading && (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                label="Address Line 1"
                placeholder='Enter Address Line 1'
                size='small'
                InputProps={{
                  readOnly: _AddressLine1.readonly
                }}
                value={_AddressLine1.value}
                error={!!_AddressLine1.error}
                disabled={_AddressLine1.disable}
                required={_AddressLine1.isRequired}
                helperText={props.helperText && _AddressLine1.error}
                onFocus={() => props.handleInputFocus('AddressLine1', 'AD')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('AddressLine1', event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                label="Address Line 2"
                placeholder='Enter Address Line 2'
                size='small'
                InputProps={{
                  readOnly: _AddressLine2.readonly
                }}
                value={_AddressLine2.value}
                error={!!_AddressLine2.error}
                disabled={_AddressLine2.disable}
                required={_AddressLine2.isRequired}
                helperText={props.helperText && _AddressLine2.error}
                onFocus={() => props.handleInputFocus('AddressLine2', 'AD')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('AddressLine2', event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                label="City  "
                placeholder='Enter City  '
                size='small'
                InputProps={{
                  readOnly: _city.readonly
                }}
                value={_city.value}
                error={!!_city.error}
                disabled={_city.disable}
                required={_city.isRequired}
                helperText={props.helperText && _city.error}
                onFocus={() => props.handleInputFocus('city', 'AD')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('city', event.target.value)}
              />
            </Grid>



            <Grid item xs={12} md={6}>
              <CustomAutocomplete
                label="District"
                placeholder='Select District'
                onFocus={() => props.handleInputFocus('district', 'AD')}
                options={sriLankanDistricts.map((district) => ({
                  label: district.name,
                  value: district.id,
                }))}
                value={{ label: _district.value.label, value: _district.value.value }}
                error={!!_district.error}
                disabled={_district.disable}
                readonly={_district.readonly}
                required={_district.isRequired}
                helperText={props.helperText && _district.error}
                onChange={(event: any, value: any) => props.onInputHandleChange('district', value)}
              /></Grid>

            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                label=" Province "
                placeholder='Enter Province '
                size='small'
                InputProps={{
                  readOnly: _province.readonly
                }}
                value={_province.value}
                error={!!_province.error}
                disabled={_province.disable}
                required={_province.isRequired}
                helperText={props.helperText && _province.error}
                onFocus={() => props.handleInputFocus('province', 'AD')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('Province', event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                label=" Postal Code "
                placeholder='Enter Postal Code '
                size='small'
                InputProps={{
                  readOnly: _postalCode.readonly
                }}
                value={_postalCode.value}
                error={!!_postalCode.error}
                disabled={_postalCode.disable}
                required={_postalCode.isRequired}
                helperText={props.helperText && _postalCode.error}
                onFocus={() => props.handleInputFocus('postalCode', 'AD')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('postalCode', event.target.value)}
              />
            </Grid>
          </Grid>
        </>)}
      {props.getTransportCompanyByIDResponse.isLoading &&
        <AppSkeleton numOfRows={3} numOfColumns={2} columnWidth={50} height={40} tag="stepper" />
      }

    </Stepper>
  )
}

export default Address