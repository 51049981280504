import { AxiosResponse } from "axios"
import {  FuelTypeBriefDto, GetFuelCostDetailsDto, GetFuelCostDetailsListDto, NewFuelTypesFormDto, VehicleNumberBriefDto, fuelBillDetailsDto } from "../../utilities/models/fuel.model"
import { ALERT_ACTION_TYPES, COMMON_ACTION_TYPES, FUEL_ACTION_TYPES } from "../../utilities/constants"
import { AlertDto, fileUploadDto } from "../../utilities/models"
import { call, put, takeEvery } from "redux-saga/effects"
import { fuelService } from "../../services/fuel.service"

function* postFuelRequest(action: { type: string, payload: NewFuelTypesFormDto }) {
  try {
    const createFuelRequestRes: AxiosResponse<string> = yield call(fuelService.postFuelRequest, action.payload)
    yield put({
      type: FUEL_ACTION_TYPES.POST_FUEL_TYPES + COMMON_ACTION_TYPES.SUCCESS,
      data: createFuelRequestRes.data
    })

    const setAlert: AlertDto = {
      message: createFuelRequestRes.data,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: FUEL_ACTION_TYPES.POST_FUEL_TYPES + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getFuelTypeList(action: { type: string }) {
  try {
    const GetBankList: AxiosResponse<FuelTypeBriefDto[]> = yield call(fuelService.getFuelTypes)
    yield put({
      type: FUEL_ACTION_TYPES.GET_FUEL_TYPES + COMMON_ACTION_TYPES.SUCCESS,
      data: GetBankList.data
    })
  } catch (error) {
    yield put({
      type: FUEL_ACTION_TYPES.GET_FUEL_TYPES + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getVehicleNumbers(action: { type: string, number: string }) {
  try {
    const getFuelTypesRes: AxiosResponse<VehicleNumberBriefDto[]> = yield call(fuelService.getAllVehicleNumbers, action.number)
    yield put({
      type: FUEL_ACTION_TYPES.GET_VEHICLE_NUMBER + COMMON_ACTION_TYPES.SUCCESS,
      data: getFuelTypesRes.data
    })
  } catch (error) {
    yield put({
      type: FUEL_ACTION_TYPES.GET_VEHICLE_NUMBER + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}


function* uploadFuelBill(action: { type: string, payload: fileUploadDto }) {
  try {
    const uploadFileResponse: AxiosResponse<string> = yield call(fuelService.uploadFuelBill, action.payload)
    const setAlert: AlertDto = {
      message: "File Uploaded",
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: FUEL_ACTION_TYPES.UPLOAD_FUEL_BILL + COMMON_ACTION_TYPES.SUCCESS,
      data: uploadFileResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: FUEL_ACTION_TYPES.UPLOAD_FUEL_BILL + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getFuelPrice(action: { type: string, typeId: number }) {
  try {
    const driverResponse: AxiosResponse<FuelTypeBriefDto> = yield call(fuelService.getFuelPrice, action.typeId)
    yield put({
      type: FUEL_ACTION_TYPES.GET_FUEL_PRICE + COMMON_ACTION_TYPES.SUCCESS,
      data: driverResponse.data
    })
  } catch (error) {
    yield put({
      type: FUEL_ACTION_TYPES.GET_FUEL_PRICE + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* addFuelBillDetails(action: { type: string, payload: fuelBillDetailsDto }) {
  try {
    const createFuelDetailsRes: AxiosResponse<string> = yield call(fuelService.addFuelBillDetails, action.payload)
    yield put({
      type: FUEL_ACTION_TYPES.ADD_FUEL_DETAILS + COMMON_ACTION_TYPES.SUCCESS,
      data: createFuelDetailsRes.data
    })

    const setAlert: AlertDto = {
      message: createFuelDetailsRes.data,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: FUEL_ACTION_TYPES.ADD_FUEL_DETAILS + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getFuelBillDetailsList(action: {
  type: string;
  param: GetFuelCostDetailsDto;
}) {
  try {
    const fuelCostBillList: AxiosResponse<GetFuelCostDetailsListDto[]> =
      yield call(fuelService.getFuelCostDetailsList, action.param);
    yield put({
      type:
      FUEL_ACTION_TYPES.GET_FUEL_COST_DETAILS +
        COMMON_ACTION_TYPES.SUCCESS,
      data: fuelCostBillList.data,
    });
  } catch (error) {
    yield put({
      type:
      FUEL_ACTION_TYPES.GET_FUEL_COST_DETAILS +
        COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}


function* fuelSaga() {
  yield takeEvery(FUEL_ACTION_TYPES.POST_FUEL_TYPES + COMMON_ACTION_TYPES.REQUEST, postFuelRequest)
  yield takeEvery(FUEL_ACTION_TYPES.GET_FUEL_TYPES + COMMON_ACTION_TYPES.REQUEST, getFuelTypeList)
  yield takeEvery(FUEL_ACTION_TYPES.GET_VEHICLE_NUMBER + COMMON_ACTION_TYPES.REQUEST, getVehicleNumbers)
  yield takeEvery(FUEL_ACTION_TYPES.UPLOAD_FUEL_BILL + COMMON_ACTION_TYPES.REQUEST, uploadFuelBill);
  yield takeEvery(FUEL_ACTION_TYPES.GET_FUEL_PRICE + COMMON_ACTION_TYPES.REQUEST, getFuelPrice);
  yield takeEvery(FUEL_ACTION_TYPES.ADD_FUEL_DETAILS + COMMON_ACTION_TYPES.REQUEST, addFuelBillDetails);
  yield takeEvery(FUEL_ACTION_TYPES.GET_FUEL_COST_DETAILS + COMMON_ACTION_TYPES.REQUEST, getFuelBillDetailsList);
}

export default fuelSaga;