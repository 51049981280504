import { Box, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@mui/material";
import style from "./DailyShiftGrid.module.scss";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import CustomHeaderCell from "../../Shared/CustomHeaderCell/CustomHeaderCell";
import { StyledTableCell } from "../../../assets/theme/theme";
import { EditOutlined } from "@mui/icons-material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { shiftDateRangesDto, shiftRequestSummaryListDto } from "../../../utilities/models/shift.model";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { APP_TABLE_CONFIGS, DAILY_TRANSPORT_MODES } from "../../../utilities/constants/app.constants";
import AppSkeleton from "../../Shared/AppSkeleton/AppSkeleton";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import moment from 'moment';
import dayjs, { Dayjs } from "dayjs";
import AddIcon from '@mui/icons-material/Add';

const DailyShiftGrid: React.FC<{
  page: number,
  rowsPerPage: number,
  isFiltered: boolean,
  onHandleChangePage(event: unknown, newPage: number): void,
  onHandleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void,
  filteredList: shiftRequestSummaryListDto[];
  navigateTo(mode: string, id: number): void;
  isLoading: boolean,
  onSortHandle(col: string): void
  getFilterList: (col: string) => string[];
  onFilterHandle(col: string, value: string): void;
  onClearFilter(): void;
  onSelectTemplateForDelete(id: number): void;
}> = (props) => {

  return (
    <section className={style.gridContainer}>
      <div className={style.gridHeader}>
        <Typography noWrap component="div" className={style.gridTitle}>
          <h3>Daily Transport</h3>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <div className="layout-row">
          {props.isFiltered && (
            <CustomButton text="Clear filter" textColor="black" bgColor="#bfbfbf" onClick={props.onClearFilter} />
          )}
          <CustomButton icon={<AddIcon sx={{ fontSize: '20px' }} />}  text="New Template" onClick={() => props.navigateTo(DAILY_TRANSPORT_MODES.CREATE, -1)} />
        </div>
      </div>
      <TableContainer component={Paper} className={style.grid}>
        <Table className={style.table}>
          <TableHead>
            <TableRow>
              <CustomHeaderCell width={150} id="id" sortable onSort={props.onSortHandle}>Template ID</CustomHeaderCell>
              <CustomHeaderCell width={150} id="name" sortable onSort={props.onSortHandle}>Template Name </CustomHeaderCell>
              <CustomHeaderCell width={120} id="sbu" filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >SBU</CustomHeaderCell>
              <CustomHeaderCell width={120} id="plant" filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Plant</CustomHeaderCell>
              <CustomHeaderCell width={140} id="empcategory" filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Employee Category</CustomHeaderCell>
              <CustomHeaderCell width={100} id="shiftGroup" filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Shift Group</CustomHeaderCell>
              <CustomHeaderCell width={100} id="shiftType" filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Shift</CustomHeaderCell>
              <CustomHeaderCell width={200} id="applicableDateRange" getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Applicable Date Range</CustomHeaderCell>
              <CustomHeaderCell width={150} id="plantLocation" filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Plant Location</CustomHeaderCell>
              <CustomHeaderCell width={150} id="status" filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Status</CustomHeaderCell>
              <CustomHeaderCell width={150} id="noOfRoutes" filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Routes Count</CustomHeaderCell>
              <CustomHeaderCell width={150} id='createDateTime' filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Template created date/time</CustomHeaderCell>
              <CustomHeaderCell getFilterList={props.getFilterList} onFilter={props.onFilterHandle} id="actions">Actions</CustomHeaderCell>
            </TableRow>
          </TableHead>
          {props.isLoading && (
            <AppSkeleton numOfRows={props.rowsPerPage} numOfColumns={12} columnWidth={150} tag="table" />
          )}
          {!props.isLoading && props.filteredList.length > 0 &&
            <TableBody>
              {props.filteredList.map((item: shiftRequestSummaryListDto) => (
                <TableRow key={item.templateId}>
                  <StyledTableCell >D{item.templateId}</StyledTableCell>
                  <StyledTableCell >{item.templateName}</StyledTableCell>
                  <StyledTableCell >{item.sbu}</StyledTableCell>
                  <StyledTableCell >{item.plant}</StyledTableCell>
                  <StyledTableCell >{item.employeeCategory}</StyledTableCell>
                  <StyledTableCell >{item.shiftGroup ? item.shiftGroup : 'N/A'}</StyledTableCell>
                  <StyledTableCell >{item.shiftType ? item.shiftType : 'N/A'}</StyledTableCell>
                  <StyledTableCell>
                    {item.dateRanges.map((dateRange: any, index: any) => (
                      <div key={index}>
                        {dateRange.dateRangeFrom.split('T')[0]} to {dateRange.dateRangeTo.split('T')[0]}
                        <br />
                      </div>
                    ))}
                  </StyledTableCell>
                  <StyledTableCell >{item.mainDestination}</StyledTableCell>
                  <StyledTableCell >{item.status}</StyledTableCell>
                  <StyledTableCell >{item.routePassengerGroups.length}</StyledTableCell>
                  <StyledTableCell >{moment(item.createDate).add(330, 'minute').format(APP_TABLE_CONFIGS.DATE_FORMAT)}</StyledTableCell>
                  <StyledTableCell style={{ backgroundColor: '#282828' }}>
                    <Box className='layout-row'>
                      <Box>
                        <IconButton size='small' onClick={() => props.navigateTo(DAILY_TRANSPORT_MODES.VIEW, item.templateId)}>
                          <Tooltip title="View">
                            <VisibilityOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton size='small' onClick={() => props.navigateTo(DAILY_TRANSPORT_MODES.EDIT, item.templateId)}>
                          <Tooltip title="Edit">
                            <EditOutlined sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton size='small' onClick={() => { props.onSelectTemplateForDelete(item.templateId) }}>
                          <Tooltip title="Delete">
                            <DeleteOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton size='small' onClick={() => props.navigateTo(DAILY_TRANSPORT_MODES.DUPLICATE, item.templateId)}>
                          <Tooltip title="Duplicate">
                            <ContentCopyIcon sx={{ fontSize: '15px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                      <Box>
                        {item.status !== 'Draft' && (
                        <IconButton size='small' onClick={() => props.navigateTo(DAILY_TRANSPORT_MODES.EXECUTE, item.templateId)}>
                          <Tooltip title="Execute">
                            <KeyboardDoubleArrowRightIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                        )}
                      </Box>
                    </Box>

                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          }
          {!props.isLoading && props.filteredList.length === 0 && (
            <TableBody>
              <TableRow>
                <StyledTableCell align="justify" colSpan={13}>
                  No data to preview
                </StyledTableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_OPTIONS}
        component="div"
        labelRowsPerPage={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: 'white',
            }}
          >
            Items per page
          </div>
        }
        count={-1}
        page={props.page}
        onPageChange={props.onHandleChangePage}
        onRowsPerPageChange={props.onHandleChangeRowsPerPage}
        rowsPerPage={props.rowsPerPage}
        nextIconButtonProps={
          { disabled: -1 < props.filteredList.length && props.filteredList.length < props.rowsPerPage }
        }
        sx={{ backgroundColor: "#282828", color: "white" }}
      />
    </section>
  );
};

export default DailyShiftGrid;