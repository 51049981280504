import { APP_ACTION_STATUS, COMMON_ACTION_TYPES, LOCATION_ACTION_TYPE } from "../../utilities/constants";
import { LocationStateDto } from "../../utilities/models";

const INITIAL_STATE: LocationStateDto = {
    getFrequentLocations: {
        data: [],
        error: null,
        isLoading: false,
        status: null
    }
}

const locationReducer = (state = INITIAL_STATE, action: any) => {
    switch((action.type)) {
        case LOCATION_ACTION_TYPE.GET_FREQUENT_LOCATION_LIST + COMMON_ACTION_TYPES.REQUEST:
          return {
            ...state,
            getFrequentLocations: {
              ...state.getFrequentLocations,
              isLoading: true,
              status: APP_ACTION_STATUS.LOADING,
              error: null,
              data: null
            }
          }
        case LOCATION_ACTION_TYPE.GET_FREQUENT_LOCATION_LIST + COMMON_ACTION_TYPES.SUCCESS:
          return {
            ...state,
            getFrequentLocations: {
              ...state.getFrequentLocations,
              isLoading: false,
              status: APP_ACTION_STATUS.SUCCESS,
              error: null,
              data: action.data
            }
          }
        case LOCATION_ACTION_TYPE.GET_FREQUENT_LOCATION_LIST + COMMON_ACTION_TYPES.ERROR:
          return {
            ...state,
            getFrequentLocations: {
              ...state.getFrequentLocations,
              isLoading: false,
              status: APP_ACTION_STATUS.ERROR,
              error: action.error,
              data: null
            }
          }
        case LOCATION_ACTION_TYPE.GET_FREQUENT_LOCATION_LIST + COMMON_ACTION_TYPES.CLEAR:
          return {
            ...state,
            getFrequentLocations: {
              ...state.getFrequentLocations,
              isLoading: false,
              status: APP_ACTION_STATUS.INITIAL,
              error: null,
              data: null
            }
          }
        default:
          return state
        
    }
}

export default locationReducer