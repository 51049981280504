import { useDispatch, useSelector } from "react-redux"
import { AlertDto, ApplicationStateDto, PendingAllocationBodyDto, PendingAllocationParamDto, PendingVehicleAllocationsDto, SortMetaDto, rejectAllocationParam } from "../../utilities/models"
import { useEffect, useState } from "react"
import { ALERT_ACTION_TYPES, APP_ACTION_STATUS, APP_ROUTES, APP_TABLE_CONFIGS, COMMON_ACTION_TYPES, REQUEST_TYPES } from "../../utilities/constants"
import { alertActions, tripActions, vehicleActions } from "../../redux/actions"
import { AppLayout } from "../../templates"
import React from "react"
import { PendingVehicleAllocationGrid } from "../../components"
import moment from "moment"
import { useNavigate } from "react-router"
import RejectPendingAllocationPopUp from "../../components/PendingVehicleAllocation/RejectPendingAllocationPopUp/RejectPendingAllocationPopUp"
import dayjs from "dayjs"

const PendingVehicleAllocation = () => {
 
  const INITIAL_SORT_META: SortMetaDto = {
    field: "",
    asc: false,
  }

  const INITIAL_FILTER_OPTIONS : PendingAllocationBodyDto = {
    parentRequestId: null,
    recurrentParentId: null,
    createFor: null,
    requestType: null,
    isVIP: null,
    redirected: null,
    createdBy: null,
    sbu: null,
    plant: null,
    department: null,
    travelMode: null,
    fromLocation: null,
    toLocation: null,
    passengerCount: null,
    preferredVehicle: null,
    status: null,
    createBy: null,
    isPackage: null,
    createFromDateandTime: null,
    createToDateandTime: null,
    departureFromDateandTime: null,
    departureToDateandTime: null,
    returnFromDateandTime: null,
    returnToDateandTime: null
  }

  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const navigate = useNavigate()
  const [rowsPerPage, setRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
  const [sortMeta, setSortMeta] = useState<SortMetaDto>(INITIAL_SORT_META);
  const [filteredList, setFilteredList] = useState<PendingVehicleAllocationsDto[]>([])
  const [isFiltered, setIsFiltered] = useState(false)
  const [reason, setReason] = useState("");
  const [isOpenCancelAllocationPopup, setIsOpenCancelAllocationPopup] = useState(false);
  const [filters, setFilters] = useState<any[]>([]);
  const [filterBody, setFilterBody] = useState<PendingAllocationBodyDto>(INITIAL_FILTER_OPTIONS);

  const getVehicleAllocationDataResponse = useSelector((state: ApplicationStateDto) => state.vehicle.getPendingAllocations)
  const rejectVehicleAllocationRes = useSelector((state:ApplicationStateDto) => state.trip.rejectTripAllocation);
  const getVehicleAllocationFilterRes = useSelector((state:ApplicationStateDto) => state.vehicle.getPendingAllocationsFilters)

  useEffect(() => {
    getRequestList(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE, 1)
    dispatch(vehicleActions.getPendingAllocationsFilters())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(getVehicleAllocationFilterRes.status === APP_ACTION_STATUS.SUCCESS){
      setFilters(getVehicleAllocationFilterRes.data)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[getVehicleAllocationFilterRes.status])

  useEffect(() => {
    if (getVehicleAllocationDataResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (getVehicleAllocationDataResponse.data.length > 0)
        setFilteredList(getVehicleAllocationDataResponse.data.map((item) => {
          return {
            ...item,
            isSelect: false
          }
        }))
      else setFilteredList([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getVehicleAllocationDataResponse.status])

  useEffect(() => {
    if(rejectVehicleAllocationRes.status === APP_ACTION_STATUS.SUCCESS){
      const _param: PendingAllocationParamDto = {
        pagination: APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE,
        pageNumber: 1,
        filter: filterBody
      }
      dispatch(vehicleActions.getPendingAllocations(_param))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectVehicleAllocationRes.status])

  const onSelectRequestIds = (id: string, value: boolean) => {
    if (id === 'ALL') {
      setFilteredList(filteredList.map((item) => {
        return {
          ...item,
          isSelect: value
        }
      }))
    } else {
      setFilteredList(filteredList.map((item) => {
        return {
          ...item,
          isSelect: Number(id) === item.requestId ? value : item.isSelect
        }
      }))
    }
  }

  const onSeeAllocationHistory = () => {
    navigate(APP_ROUTES.TM_ALLOCATION_SUMMARY)
  }

  const getRequestList = async (r: number, p: number) => {
    const _param: PendingAllocationParamDto = {
      pagination: r,
      pageNumber: p,
      filter: filterBody
    }
    dispatch(vehicleActions.getPendingAllocations(_param))
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    getRequestList(rowsPerPage, newPage + 1)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    getRequestList(+event.target.value, 1)
    setPage(0)
  }

  const navigteTORequestScreen = (mode: string, id: string) => {
    sessionStorage.setItem('Mode', mode)
    sessionStorage.setItem('id', id)
    navigate(APP_ROUTES.MANAGE_REQUEST)
  }

  const onAllocateVehicle = () => {
    let _list: Array<string> = []
    let _isVip: boolean = false
    let _isRecurrentTrip: boolean = false
    let _isSplitedRequest:boolean = false

    filteredList.forEach((req) => {
      if (req.isSelect) {
        if(req.isVIP) _isVip = true
        if(req.requestType === REQUEST_TYPES[1].name)  _isRecurrentTrip = true
        if(req.parentRequestId > 0) _isSplitedRequest = true

        _list = [..._list, req.requestId.toString()]
      }
    })

    if(_isVip && _list.length > 1) {
      const setAlert: AlertDto = {
        message: "Please select non VIP request.!",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: "error",
        },
      };
      dispatch(alertActions.triggerAlert(setAlert));
    }

    if(_isRecurrentTrip && _list.length > 1) {
      const setAlert: AlertDto = {
        message: "Selected requests cannot be merged into a single trip. Please check the recurrent status.!",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: "error",
        },
      };
      dispatch(alertActions.triggerAlert(setAlert));
    }

    if(_isSplitedRequest && _list.length > 1){
      const setAlert: AlertDto = {
        message: "Selected requests cannot be merged into a single trip. Please check the split status.!",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: "error",
        },
      };
      dispatch(alertActions.triggerAlert(setAlert));
    }

    if (_list.length === 1){
      navigate(APP_ROUTES.TM_ACCEPT_VEHICLE_ALLOCATION, { state: { reqList: _list } })
    }else if(_list.length > 1 && !_isVip && !_isRecurrentTrip && !_isSplitedRequest){
      navigate(APP_ROUTES.TM_ACCEPT_VEHICLE_ALLOCATION, { state: { reqList: _list } })
    }
  
  }

  const onClearFilter = () => {
    setIsFiltered(false)
    setPage(0)
    setFilterBody(INITIAL_FILTER_OPTIONS)
  }

  const onSortHandle = (col: string) => {
    const sorted = filteredList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setFilteredList(sorted);
  };

  const onFilterHandle = (col: string, value: any) => {
    setIsFiltered(true)
    if (filterBody.hasOwnProperty(col)) {
      setFilterBody(prevFilterBody => ({
        ...prevFilterBody,
        [col]: value,
      }));
    }else {
      if (col === "createDateTime") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DDTHH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DDTHH:mm");

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          createFromDateandTime: _selectedMin,
          createToDateandTime: _selectedMax
        }));
      } else if (col === "departureDateTime") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DDTHH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DDTHH:mm");

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          departureFromDateandTime: _selectedMin,
          departureToDateandTime: _selectedMax
        }));
      } else if (col === "returnDateandTime") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DDTHH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DDTHH:mm");

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          returnFromDateandTime: _selectedMin,
          returnToDateandTime: _selectedMax
        }));
      }else if(col === "Package"){
        const _valueCon = value === "Yes" ? true : false

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          isPackage:_valueCon
        }));
      }else if(col === "Redirected"){
        const _valueCon = value === "Yes" ? true : false

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          redirected:_valueCon
        }));
      }else if(col === "VIP"){
        const _valueCon = value === "Yes" ? true : false

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          _isVIP:_valueCon
        }));
      }

    }
  }

  useEffect(() => {
    getRequestList(rowsPerPage, 1)
    setPage(0)
  }, [isFiltered, filterBody])

  useEffect(() => {
    setFilteredList(getVehicleAllocationDataResponse.data);
  }, [filterBody])

  const getFilterList = (col: string): string[] => {
    if (!getVehicleAllocationDataResponse.isLoading) {
      const _list = getVehicleAllocationDataResponse.data || []
      return _list
        .map((item) => {
          const value = (item as any)[col];
          if (typeof value === "boolean") {
            return value ? "Yes" : "No";
          }
          return value ? value : 'N/A'
        })
        .filter((value, index, array) => array.indexOf(value) === index);
    }
    else return []
  };
  const OnCancelAllocationPopUPClose = () => {
    setIsOpenCancelAllocationPopup(false);
  };
  const onCallback = (value: boolean, reason: string) => {
    if (value) {
      let _list: Array<string> = []
      filteredList.forEach((req => {
        if (req.isSelect) {
          _list = [..._list, req.requestId.toString()]
        }
      }))
      setIsOpenCancelAllocationPopup(false);
      const _param: rejectAllocationParam = {
        requestId: _list,
        note: reason,
      }

      if (_list.length > 0) {
        dispatch(tripActions.postRejectTripAllocation(_param))
      }
    } else {
      setIsOpenCancelAllocationPopup(false);
    }

  };
  const onActionButtonClick = () => {
    let _list: Array<string> = []
    filteredList.forEach((req) => {
      if (req.isSelect) {
        _list = [..._list, req.requestId.toString()]
      }
    })
    if (_list.length > 0)
      setIsOpenCancelAllocationPopup(true);
  }


  return (
    <React.Fragment>
      <AppLayout componentTitle="Vehicle Allocation">
        <section className='page-root'>
          <PendingVehicleAllocationGrid
            page={page}
            rowsPerPage={rowsPerPage}
            onHandleChangePage={handleChangePage}
            onHandleChangeRowsPerPage={handleChangeRowsPerPage}
            approvalRequestDataIsLoading={getVehicleAllocationDataResponse.isLoading}
            filteredList={filteredList || []}
            onSortHandle={onSortHandle}
            onFilterHandle={onFilterHandle}
            getFilterList={getFilterList}
            onClearFilter={onClearFilter}
            isFiltered={isFiltered}
            navigateTo={navigteTORequestScreen}
            onSelectRequestIds={onSelectRequestIds}
            onAllocateVehicle={onAllocateVehicle}
            onActionButtonClick={onActionButtonClick}
            onSeeAllocationHistory={onSeeAllocationHistory}
            filters={filters}
          />
          <RejectPendingAllocationPopUp
            onCallback={onCallback}
            OnCancelAllocationPopUPClose={OnCancelAllocationPopUPClose}
            isOpenCancelAllocationPopup={isOpenCancelAllocationPopup}
            setReason={setReason}
            reason={reason}
          />

        </section>
      </AppLayout>
    </React.Fragment>
  )
}

export default PendingVehicleAllocation
