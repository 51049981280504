import { Box, Button, Grid, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, Typography, } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import style from "./VehicleManagementForm.module.scss";
import { AppSkeleton, CustomAutocomplete, CustomButton, CustomDatePicker, CustomHeaderCell, } from "../../Shared";
import {
  IncidentListDto, StateObjectDto, TransportCompaniesDto, VehicleDetailsFormDto, VehicleDocumentListDto, VehicleDocumentsDto, VehicleIncidentFormDto,
  VehicleIncidentListDto, VehicleInsuranceDto, VehicleLicenseDto, VehicleOtherDocDto, VehiclePurposeTypesDto, VehicleTypesDto,
} from "../../../utilities/models";
import { StyledTableCell, StyledTextField } from "../../../assets/theme/theme";
import { DateObject } from "react-multi-date-picker";
import { DOCUMENT_TYPE, DRIVERDOCUMENT, VEHICLEDOCUMENT, VEHICLE_PURPOSE_TYPES, VEHICLE_SCREEN_MODES, } from "../../../utilities/constants";

import DownloadIcon from "@mui/icons-material/Download";
import PublishIcon from '@mui/icons-material/Publish';
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CloudDownloadIcon from '@mui/icons-material/CloudDownloadRounded';
import CloudUploadIcon from '@mui/icons-material/CloudUploadRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import Stepper from "../../Shared/Stepper/Stepper";
import CustomCircularProgress from "../../Shared/CustomCircularProgress/CustomCircularProgress";
import LoadingButton from '@mui/lab/LoadingButton';

const VehicleManagementForm: React.FC<{
  helperText: boolean;
  screenMode: string;
  vehicleDetailsForm: VehicleDetailsFormDto;
  vehicleIncidentForm: VehicleIncidentFormDto;
  vehicleDocumentForm: VehicleDocumentsDto;
  incidentList: IncidentListDto[];
  documentList: VehicleDocumentListDto[] | [];
  getAllCompaniesResponse: StateObjectDto<TransportCompaniesDto[]>;
  getVehicleTypesResponse: StateObjectDto<VehicleTypesDto[]>;
  getIncidentsResponse: StateObjectDto<VehicleIncidentListDto>;
  createVehicleResponse: StateObjectDto<string>;
  editVehicleResponse: StateObjectDto<string>;
  onInputHandleChange(property: string, value: any): void;
  onInputHandleChangeIncident(property: string, value: any): void;
  onInputHandleChangeDocument(property: string, value: any): void;
  handleInputFocus(property: string, section: string): void;
  onSortHandle(col: string): void;
  onAddIncident(): void;
  onAddDocuments(documentId: number): void;
  onClearIncidentForm(): void;
  onCreateVehicle(): void;
  onEditVehicle(): void;
  onClearCreateVehicleForm(): void;
  onClearDocumentForm(): void
  onNavigateTo(): void;
  licenseForm: VehicleLicenseDto;
  insuranceForm: VehicleInsuranceDto;
  otherForm: VehicleOtherDocDto;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>, id: number) => void;
  handleDownload(mainId: number, docId: number): void;
  handleDeleteFile(mainId: number, docId: number): void;
  onSelectDocument(id: number, action: string): void;
  licenseDocumentResponse:StateObjectDto<any>;
  insuranceDocumentResponse:StateObjectDto<any>;
  otherDocResponse:StateObjectDto<any>;
}> = (props) => {

  const _companyName = props.vehicleDetailsForm.companyName;
  const _vehicleNumber = props.vehicleDetailsForm.vehicleNumber;
  const _vehicleType = props.vehicleDetailsForm.vehicleType;
  const _purpose = props.vehicleDetailsForm.purpose;
  const _ceatCount = props.vehicleDetailsForm.ceatCount;
  const _cbm = props.vehicleDetailsForm.cbm;
  const _plant = props.vehicleDetailsForm.plant;
  const _gps = props.vehicleDetailsForm.gpsDeviceNumber;

  const _incident = props.vehicleIncidentForm.incident;
  const _date = props.vehicleIncidentForm.date;
  const _outcome = props.vehicleIncidentForm.outcome;

  const _allCompanies =
    props.getAllCompaniesResponse &&
      !props.getAllCompaniesResponse.isLoading &&
      props.getAllCompaniesResponse.data.length > 0
      ? props.getAllCompaniesResponse.data
      : [];
  const _vehicleTypes =
    props.getVehicleTypesResponse.data !== null &&
      !props.getVehicleTypesResponse.isLoading &&
      props.getVehicleTypesResponse.data.length > 0
      ? props.getVehicleTypesResponse.data
      : [];

  const _vehicleLicenseNo = props.licenseForm.vehicleLicenseNo;
  const _licenseFileName = props.licenseForm.licenseFileName;
  const _vehicleLicenseIssue = props.licenseForm.vehicleLicenseIssue;
  const _vehicleLicenseExpiry = props.licenseForm.vehicleLicenseExpiry;

  const _insuranceNo = props.insuranceForm.insuranceNo;
  const _insuranceFileName = props.insuranceForm.insuranceFileName;
  const _insuranceIssue = props.insuranceForm.insuranceIssue;
  const _insuranceExpiry = props.insuranceForm.insuranceExpiry;

  const _vehcileOtherDoc = props.otherForm.vehcileOtherDoc;
  const _otherFileName = props.otherForm.otherFileName;
  const _otherIssue = props.otherForm.vehicleOtherIssue;
  const _otherExpiry = props.otherForm.vehicleOtherExpiry;


  return (
    <section className={style.sectionCard}>
      <section className={style.sectionCardHeader}>
        <Typography noWrap component="div" className={style.title}>
          <h3>Manage Vehicles</h3>
        </Typography>
      </section>

      <section className={style.sectionCardBody}>
        <Stepper stepNumber={1} stepTitle={"General Information"}>
          <section className={style.vehicleSection}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <CustomAutocomplete
                  label="Transport Company"
                  placeholder="Select transport company"
                  onFocus={() => props.handleInputFocus("companyName", "vehicle")}
                  options={_allCompanies.map((c) => {
                    return { label: c.name, value: c.id };
                  })}
                  value={_companyName.value}
                  error={!!_companyName.error}
                  disabled={_companyName.disable}
                  readonly={_companyName.readonly}
                  required={_companyName.isRequired}
                  helperText={props.helperText && _companyName.error}
                  onChange={(event: any, value: any) =>
                    props.onInputHandleChange("companyName", value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  fullWidth
                  label="Vehicle License Plate Number"
                  placeholder="Enter vehicle license plate number"
                  size="small"
                  InputProps={{
                    readOnly: _vehicleNumber.readonly,
                  }}
                  value={_vehicleNumber.value}
                  error={!!_vehicleNumber.error}
                  disabled={_vehicleNumber.disable}
                  required={_vehicleNumber.isRequired}
                  helperText={props.helperText && _vehicleNumber.error}
                  onFocus={() =>
                    props.handleInputFocus("vehicleNumber", "vehicle")
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.onInputHandleChange("vehicleNumber", event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomAutocomplete
                  label="Vehicle Type"
                  placeholder="Select vehicle type"
                  options={_vehicleTypes.map((v) => {
                    return { label: v.description, value: v.id };
                  })}
                  value={_vehicleType.value}
                  error={!!_vehicleType.error}
                  disabled={_vehicleType.disable}
                  readonly={_vehicleType.readonly}
                  required={_vehicleType.isRequired}
                  helperText={props.helperText && _vehicleType.error}
                  onFocus={() => props.handleInputFocus("vehicleType", "vehicle")}
                  onChange={(event: any, value: any) =>
                    props.onInputHandleChange("vehicleType", value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomAutocomplete
                  label="Vehicle Purpose"
                  placeholder="Select purpose type"
                  options={VEHICLE_PURPOSE_TYPES.map((v: VehiclePurposeTypesDto) => {
                    return { label: v.label, value: v.value };
                  })}
                  value={_purpose.value}
                  error={!!_purpose.error}
                  disabled={_purpose.disable}
                  readonly={_purpose.readonly}
                  required={_purpose.isRequired}
                  helperText={props.helperText && _purpose.error}
                  onFocus={() => props.handleInputFocus("purpose", "vehicle")}
                  onChange={(event: any, value: any) =>
                    props.onInputHandleChange("purpose", value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  fullWidth
                  label="Seating Capacity"
                  placeholder="Enter available seat count"
                  size="small"
                  InputProps={{
                    readOnly: _ceatCount.readonly,
                  }}
                  value={_ceatCount.value}
                  error={!!_ceatCount.error}
                  disabled={_ceatCount.disable}
                  required={_ceatCount.isRequired}
                  helperText={props.helperText && _ceatCount.error}
                  onFocus={() => props.handleInputFocus("ceatCount", "vehicle")}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const inputValue = event.target.value;
                    // Ensure only positive values are allowed
                    const newValue = Math.max(0, parseInt(inputValue, 10)) || ''

                    props.onInputHandleChange("ceatCount", newValue)
                  }
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  fullWidth
                  label="CBM Capacity"
                  placeholder="Enter cbm"
                  size="small"
                  InputProps={{
                    readOnly: _cbm.readonly,
                  }}
                  value={_cbm.value}
                  error={!!_cbm.error}
                  disabled={_cbm.disable}
                  required={_cbm.isRequired}
                  helperText={props.helperText && _cbm.error}
                  onFocus={() => props.handleInputFocus("cbm", "vehicle")}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.onInputHandleChange("cbm", event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  fullWidth
                  label="Assigned plant"
                  placeholder="Enter plant"
                  size="small"
                  InputProps={{
                    readOnly: _plant.readonly,
                  }}
                  value={_plant.value.label || ''}
                  error={!!_plant.error}
                  disabled={_plant.disable}
                  required={_plant.isRequired}
                  helperText={props.helperText && _plant.error}
                  onFocus={() => props.handleInputFocus("plant", "vehicle")}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.onInputHandleChange("plant", event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  fullWidth
                  label="GPS Device Number"
                  placeholder="Enter gps device number"
                  size="small"
                  InputProps={{
                    readOnly: _gps.readonly,
                  }}
                  value={_gps.value || ''}
                  error={!!_gps.error}
                  disabled={_gps.disable}
                  required={_gps.isRequired}
                  helperText={props.helperText && _gps.error}
                  onFocus={() => props.handleInputFocus("gpsDeviceNumber", "vehicle")}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.onInputHandleChange("gpsDeviceNumber", event.target.value)
                  }
                />
              </Grid>
            </Grid>
          </section>
        </Stepper>
        <Stepper stepNumber={2} stepTitle={"Document Information"}>
          <section className={style.incidentSection}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                Vehicle Revenue License*
              </Grid>
              <Grid item xs={4} md={2}>
                <StyledTextField
                  fullWidth
                  label="Vehicle Revenue License Number"
                  placeholder="Enter Vehicle Revenue License Number"
                  size="small"
                  value={_vehicleLicenseNo.value}
                  InputProps={{
                    readOnly: _vehicleLicenseNo.readonly || props.screenMode === VEHICLE_SCREEN_MODES.VIEW,
                  }}
                  error={!!_vehicleLicenseNo.error}
                  disabled={_vehicleLicenseNo.disable}
                  required={_vehicleLicenseNo.isRequired}
                  helperText={props.helperText && _vehicleLicenseNo.error}
                  onFocus={() => props.handleInputFocus("vehicleLicenseNo", "license")}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.onInputHandleChange("vehicleLicenseNo", event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4} md={2}>
                <CustomDatePicker
                  label="Issued Date"
                  placeholder="Select issued date"
                  multiple={false}
                  value={_vehicleLicenseIssue.value}
                  maxDate={new Date()}
                  error={!!_vehicleLicenseIssue.error}
                  disabled={_vehicleLicenseIssue.disable || props.screenMode === VEHICLE_SCREEN_MODES.VIEW}
                  readOnly={_vehicleLicenseIssue.readonly || props.screenMode === VEHICLE_SCREEN_MODES.VIEW}
                  required={_vehicleLicenseIssue.isRequired}
                  helperText={props.helperText && _vehicleLicenseIssue.error}
                  onFocus={() => props.handleInputFocus("vehicleLicenseIssue", "license")}
                  onChange={(value: DateObject) =>
                    props.onInputHandleChange("vehicleLicenseIssue", value)
                  }
                />
              </Grid>
              <Grid item xs={4} md={2}>
                <CustomDatePicker
                  label="Expiry Date"
                  placeholder="Select expiry date"
                  multiple={false}
                  value={_vehicleLicenseExpiry.value}
                  minDate={new Date()}
                  error={!!_vehicleLicenseExpiry.error}
                  disabled={_vehicleLicenseExpiry.disable || props.screenMode === VEHICLE_SCREEN_MODES.VIEW}
                  readOnly={_vehicleLicenseExpiry.readonly || props.screenMode === VEHICLE_SCREEN_MODES.VIEW}
                  required={_vehicleLicenseExpiry.isRequired}
                  helperText={props.helperText && _vehicleLicenseExpiry.error}
                  onFocus={() => props.handleInputFocus("vehicleLicenseExpiry", "license")}
                  onChange={(value: DateObject) =>
                    props.onInputHandleChange("vehicleLicenseExpiry", value)
                  }
                />
              </Grid>
              {props.screenMode !== VEHICLE_SCREEN_MODES.VIEW && (
                <>
                  <Grid item xs={1} sx={{ display: "none" }}>
                    <input
                      type="file"
                      required
                      accept=".pdf"
                      id="VehicleLicense"
                      style={{ display: 'none' }}
                      onChange={(event) => props.handleFileChange(event, VEHICLEDOCUMENT.REVENUE_DOCUMENT)}
                      onClick={(event: any) => { 
                        event.target.value = null
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} md={6}>
                    <Box sx={{ display: "flex" }}>
                      <Grid item xs={12} md={4}>
                        <label htmlFor="VehicleLicense">
                          <LoadingButton style={{ color: "white" }} loading={props.licenseDocumentResponse.isLoading} loadingIndicator={<CustomCircularProgress />} component="span">
                            Select File
                          </LoadingButton>
                        </label>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {_licenseFileName.value !== '' && <Box sx={{ display: "flex" }}>
                          <StyledTextField
                            fullWidth
                            label="File Attached"
                            placeholder="Enter driver NIC"
                            size="small"
                            InputProps={{
                              readOnly: _licenseFileName.readonly,
                            }}
                            value={_licenseFileName.value}
                            error={!!_licenseFileName.error}
                            disabled={false}
                            required={_licenseFileName.isRequired}
                            helperText={props.helperText && _licenseFileName.error}
                            onFocus={() => props.handleInputFocus("licenseFileName", "license")}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              props.onInputHandleChange("licenseFileName", event.target.value)
                            }
                          />
                        </Box>}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className='layout-row'>
                          <CustomButton text='Clear' textColor='black' bgColor='#bfbfbf' onClick={() => { props.handleDeleteFile(DOCUMENT_TYPE.VEHICLE_DOCUMENTS, VEHICLEDOCUMENT.REVENUE_DOCUMENT) }} />
                          <CustomButton text='Submit' textColor='black' bgColor='#bfbfbf' onClick={() => { props.onAddDocuments(VEHICLEDOCUMENT.REVENUE_DOCUMENT) }} />
                        </div>
                      </Grid>
                    </Box>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={12}></Grid>
              <Grid item xs={12} md={12}>
                Vehicle Insurance Policy*
              </Grid>
              <Grid item xs={4} md={2}>
                <StyledTextField
                  fullWidth
                  label="Insurance Policy Number"
                  placeholder="Enter insurance policy Number"
                  size="small"
                  InputProps={{
                    readOnly: _insuranceNo.readonly || props.screenMode === VEHICLE_SCREEN_MODES.VIEW,
                  }}
                  value={_insuranceNo.value}
                  error={!!_insuranceNo.error}
                  disabled={_insuranceNo.disable}
                  required={_insuranceNo.isRequired}
                  helperText={props.helperText && _insuranceNo.error}
                  onFocus={() => props.handleInputFocus("insuranceNo", "insurance")}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.onInputHandleChange("insuranceNo", event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4} md={2}>
                <CustomDatePicker
                  label="Issued Date"
                  placeholder="Select issued date"
                  multiple={false}
                  value={_insuranceIssue.value}
                  maxDate={new Date()}
                  error={!!_insuranceIssue.error}
                  disabled={_insuranceIssue.disable || props.screenMode === VEHICLE_SCREEN_MODES.VIEW}
                  readOnly={_insuranceIssue.readonly || props.screenMode === VEHICLE_SCREEN_MODES.VIEW}
                  required={_insuranceIssue.isRequired}
                  helperText={props.helperText && _insuranceIssue.error}
                  onFocus={() => props.handleInputFocus("insuranceIssue", "insurance")}
                  onChange={(value: DateObject) =>
                    props.onInputHandleChange("insuranceIssue", value)
                  }
                />
              </Grid>
              <Grid item xs={4} md={2}>
                <CustomDatePicker
                  label="Expiry Date"
                  placeholder="Select expiry date"
                  multiple={false}
                  value={_insuranceExpiry.value}
                  minDate={new Date()}
                  error={!!_insuranceExpiry.error}
                  disabled={_insuranceExpiry.disable || props.screenMode === VEHICLE_SCREEN_MODES.VIEW}
                  readOnly={_insuranceExpiry.readonly || props.screenMode === VEHICLE_SCREEN_MODES.VIEW}
                  required={_insuranceExpiry.isRequired}
                  helperText={props.helperText && _insuranceExpiry.error}
                  onFocus={() => props.handleInputFocus("insuranceExpiry", "insurance")}
                  onChange={(value: DateObject) =>
                    props.onInputHandleChange("insuranceExpiry", value)
                  }
                />
              </Grid>
              {props.screenMode !== VEHICLE_SCREEN_MODES.VIEW && (
                <>
                  <Grid item xs={1} sx={{ display: "none" }}>
                    <input
                      itemID="1"
                      type="file"
                      required
                      accept=".pdf"
                      id="PolicyNumber"
                      style={{ display: 'none' }}
                      onChange={(event) => props.handleFileChange(event, VEHICLEDOCUMENT.INSURANCE_DOCUMENT)}
                      onClick={(event: any) => { 
                        event.target.value = null
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} md={6}>
                    <Box sx={{ display: "flex" }}>
                      <Grid item xs={12} md={4}>
                        <label htmlFor="PolicyNumber">
                          <LoadingButton style={{ color: "white" }} loading={props.insuranceDocumentResponse.isLoading} loadingIndicator={<CustomCircularProgress />} component="span">
                            Select File
                          </LoadingButton>
                        </label>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {_insuranceFileName.value !== '' && <Box sx={{ display: "flex" }}>
                          <StyledTextField
                            fullWidth
                            label="File Attached"
                            placeholder="Enter driver NIC"
                            size="small"
                            InputProps={{
                              readOnly: _insuranceFileName.readonly,
                            }}
                            value={_insuranceFileName.value}
                            error={!!_insuranceFileName.error}
                            disabled={false}
                            required={_insuranceFileName.isRequired}
                            helperText={props.helperText && _insuranceFileName.error}
                            onFocus={() => props.handleInputFocus("insuranceFileName", "insurance")}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              props.onInputHandleChange("insuranceFileName", event.target.value)
                            }
                          />
                        </Box>}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className='layout-row'>
                          <CustomButton text='Clear' textColor='black' bgColor='#bfbfbf' onClick={() => { props.handleDeleteFile(DOCUMENT_TYPE.VEHICLE_DOCUMENTS, VEHICLEDOCUMENT.INSURANCE_DOCUMENT) }} />
                          <CustomButton text='Submit' textColor='black' bgColor='#bfbfbf' onClick={() => { props.onAddDocuments(VEHICLEDOCUMENT.INSURANCE_DOCUMENT) }} />
                        </div>
                      </Grid>
                    </Box>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={12}></Grid>
              <Grid item xs={12} md={12}>
                Other Documents{" "}
              </Grid>
              <Grid item xs={12} md={2}>
                <StyledTextField
                  fullWidth
                  label="Other Documents"
                  placeholder="Enter Document Category"
                  size="small"
                  InputProps={{
                    readOnly: _vehcileOtherDoc.readonly || props.screenMode === VEHICLE_SCREEN_MODES.VIEW,
                  }}
                  value={_vehcileOtherDoc.value}
                  error={!!_vehcileOtherDoc.error}
                  disabled={_vehcileOtherDoc.disable}
                  required={_vehcileOtherDoc.isRequired}
                  helperText={props.helperText && _vehcileOtherDoc.error}
                  onFocus={() => props.handleInputFocus("vehcileOtherDoc", "other")}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.onInputHandleChange("vehcileOtherDoc", event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4} md={2}>
                <CustomDatePicker
                  label="Issued Date"
                  placeholder="Select issued date"
                  multiple={false}
                  value={_otherIssue.value}
                  maxDate={new Date()}
                  error={!!_otherIssue.error}
                  disabled={_otherIssue.disable || props.screenMode === VEHICLE_SCREEN_MODES.VIEW}
                  readOnly={_otherIssue.readonly || props.screenMode === VEHICLE_SCREEN_MODES.VIEW}
                  required={_otherIssue.isRequired}
                  helperText={props.helperText && _otherIssue.error}
                  onFocus={() => props.handleInputFocus("otherIssue", "other")}
                  onChange={(value: DateObject) =>
                    props.onInputHandleChange("otherIssue", value)
                  }
                />
              </Grid>
              <Grid item xs={4} md={2}>
                <CustomDatePicker
                  label="Expiry Date"
                  placeholder="Select expiry date"
                  multiple={false}
                  value={_otherExpiry.value}
                  minDate={new Date()}
                  error={!!_otherExpiry.error}
                  disabled={_otherExpiry.disable || props.screenMode === VEHICLE_SCREEN_MODES.VIEW}
                  readOnly={_otherExpiry.readonly || props.screenMode === VEHICLE_SCREEN_MODES.VIEW}
                  required={_otherExpiry.isRequired}
                  helperText={props.helperText && _otherExpiry.error}
                  onFocus={() => props.handleInputFocus("otherExpiry", "other")}
                  onChange={(value: DateObject) =>
                    props.onInputHandleChange("otherExpiry", value)
                  }
                />
              </Grid>
              {props.screenMode !== VEHICLE_SCREEN_MODES.VIEW && (
                <>
                  <Grid item xs={1} sx={{ display: "none" }}>
                    <input
                      type="file"
                      required
                      accept=".pdf"
                      id="OtherFile"
                      style={{ display: 'none' }}
                      onChange={(event) => props.handleFileChange(event, VEHICLEDOCUMENT.OTHER_DOCUMENTS)}
                      onClick={(event: any) => { 
                        event.target.value = null
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} md={6}>
                    <Box sx={{ display: "flex" }}>
                      <Grid item xs={12} md={4}>
                        <label htmlFor="OtherFile">
                          <LoadingButton style={{ color: "white" }} loading={props.otherDocResponse.isLoading} loadingIndicator={<CustomCircularProgress />} component="span">
                            Select File
                          </LoadingButton>
                        </label>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {_otherFileName.value !== '' && <Box sx={{ display: "flex" }}>
                          <StyledTextField
                            fullWidth
                            label="File Attached"
                            placeholder="Enter driver NIC"
                            size="small"
                            InputProps={{
                              readOnly: _otherFileName.readonly,
                            }}
                            value={_otherFileName.value}
                            error={!!_otherFileName.error}
                            disabled={false}
                            required={_otherFileName.isRequired}
                            helperText={props.helperText && _otherFileName.error}
                            onFocus={() => props.handleInputFocus("otherFileName", "other")}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              props.onInputHandleChange("otherFileName", event.target.value)
                            }
                          />
                        </Box>}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className='layout-row'>
                          <CustomButton text='Clear' textColor='black' bgColor='#bfbfbf' onClick={() => { props.handleDeleteFile(DOCUMENT_TYPE.VEHICLE_DOCUMENTS, VEHICLEDOCUMENT.OTHER_DOCUMENTS) }} />
                          <CustomButton text='Submit' textColor='black' bgColor='#bfbfbf' onClick={() => { props.onAddDocuments(VEHICLEDOCUMENT.OTHER_DOCUMENTS) }} />
                        </div>
                      </Grid>
                    </Box>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={6}> </Grid>
              <Grid item xs={12} md={6}> </Grid>
              <Grid item xs={12} md={6}> </Grid>
            </Grid>

            <section>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <CustomHeaderCell id='id'>#</CustomHeaderCell>
                      <CustomHeaderCell id="incident" width={200}> Document Category </CustomHeaderCell>
                      <CustomHeaderCell id="incident" width={100}> Document Version </CustomHeaderCell>
                      <CustomHeaderCell id="date" width={120}> Issued Date </CustomHeaderCell>
                      <CustomHeaderCell id="outcome" width={120}> Expiry Date </CustomHeaderCell>
                      <CustomHeaderCell id="incident"> File Name </CustomHeaderCell>
                      <CustomHeaderCell id="outcome"> Actions </CustomHeaderCell>
                    </TableRow>
                  </TableHead>
                  {props.getIncidentsResponse.isLoading && (
                    <AppSkeleton
                      numOfRows={3}
                      numOfColumns={5}
                      columnWidth={100}
                      tag="table"
                    />
                  )}

                  <TableBody>
                    {props.documentList.map((doc, index) => (
                      <TableRow key={index}>
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell>{doc.category}</StyledTableCell>
                        <StyledTableCell>{doc.version ?  doc.version : 'N/A'}</StyledTableCell>
                        <StyledTableCell>{doc.issuedDate ? doc.issuedDate : 'N/A'}</StyledTableCell>
                        <StyledTableCell>{doc.expiryDate ? doc.expiryDate : 'N/A'}</StyledTableCell>
                        <StyledTableCell>{doc.documentName ? doc.documentName : 'N/A'}</StyledTableCell>
                        <StyledTableCell>
                          <Box className="layout-row">
                            <Box>
                              <IconButton size='small' onClick={() => props.onSelectDocument(doc.id, "view")}>
                                <Tooltip title="View">
                                  <RemoveRedEyeOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                </Tooltip>
                              </IconButton>
                            </Box>
                            <Box>
                              {((props.screenMode === VEHICLE_SCREEN_MODES.CREATE || props.screenMode === VEHICLE_SCREEN_MODES.EDIT) && (
                                <IconButton size='small' onClick={() => props.onSelectDocument(doc.id, "download")}>
                                  <Tooltip title="Download">
                                    <CloudDownloadIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                  </Tooltip>
                                </IconButton>
                              ))}
                            </Box>
                            <Box>
                              {((props.screenMode === VEHICLE_SCREEN_MODES.CREATE || props.screenMode === VEHICLE_SCREEN_MODES.EDIT) && (
                                <IconButton size='small' onClick={() => props.onSelectDocument(doc.id, "delete")}>
                                  <Tooltip title="Delete">
                                    <DeleteOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                  </Tooltip>
                                </IconButton>
                              ))}
                            </Box>
                          </Box>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {!Array.isArray(props.documentList) || props.documentList.length === 0 ? (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell align="center" colSpan={7}>
                          No document data to preview
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  ) : (<TableBody />)}
                </Table>
              </TableContainer>
            </section>
          </section>
        </Stepper>
        <Stepper stepNumber={3} stepTitle={"Incident Information"}>
          <section className={style.incidentSection}>
            {/* {props.screenMode !== VEHICLE_SCREEN_MODES.CREATE && ( */}
            <>
              <Grid container spacing={4}>
                <Grid item xs={12} md={12}>
                  <StyledTextField
                    fullWidth
                    label="Incident"
                    placeholder="Enter incident"
                    size="small"
                    InputProps={{
                      readOnly: _incident.readonly || props.screenMode === VEHICLE_SCREEN_MODES.VIEW,
                    }}
                    value={_incident.value}
                    error={!!_incident.error}
                    disabled={_incident.disable}
                    required={_incident.isRequired}
                    helperText={props.helperText && _incident.error}
                    onFocus={() =>
                      props.handleInputFocus("incident", "incident")
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      props.onInputHandleChangeIncident(
                        "incident",
                        event.target.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomDatePicker
                    label="Date of incident"
                    placeholder="Select Date"
                    multiple={false}
                    maxDate={new Date()}
                    value={_date.value}
                    error={!!_date.error}
                    disabled={_date.disable || props.screenMode === VEHICLE_SCREEN_MODES.VIEW}
                    readOnly={_date.readonly || props.screenMode === VEHICLE_SCREEN_MODES.VIEW}
                    required={_date.isRequired}
                    helperText={props.helperText && _date.error}
                    onFocus={() => props.handleInputFocus("date", "incident")}
                    onChange={(value: DateObject) =>
                      props.onInputHandleChangeIncident("date", value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    fullWidth
                    label="Outcome of the incident"
                    placeholder="Enter outcome"
                    size="small"
                    InputProps={{
                      readOnly: _outcome.readonly || props.screenMode === VEHICLE_SCREEN_MODES.VIEW,
                    }}
                    value={_outcome.value}
                    error={!!_outcome.error}
                    disabled={_outcome.disable}
                    required={_outcome.isRequired}
                    helperText={props.helperText && _outcome.error}
                    onFocus={() =>
                      props.handleInputFocus("outcome", "incident")
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      props.onInputHandleChangeIncident(
                        "outcome",
                        event.target.value
                      )
                    }
                  />
                </Grid>
              </Grid>

              <div className={style.itemsAction}>
                {props.screenMode !== VEHICLE_SCREEN_MODES.VIEW &&
                  <>
                    <CustomButton
                      text="Clear"
                      textColor="black"
                      bgColor="#bfbfbf"
                      onClick={props.onClearIncidentForm}
                    />
                    <CustomButton
                      border="1px solid #6e6e6e"
                      bgColor="#282828"
                      text="Add incident"
                      onClick={props.onAddIncident}
                    />
                  </>}
              </div>
            </>
            {/* )} */}

            <section>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* <CustomHeaderCell id='id' >#</CustomHeaderCell> */}
                      <CustomHeaderCell
                        id="incident"
                        sortable
                        onSort={props.onSortHandle}
                      >
                        Incident
                      </CustomHeaderCell>
                      <CustomHeaderCell
                        id="date"
                        sortable
                        onSort={props.onSortHandle}
                      >
                        Date
                      </CustomHeaderCell>
                      <CustomHeaderCell
                        id="outcome"
                        sortable
                        onSort={props.onSortHandle}
                      >
                        Outcome
                      </CustomHeaderCell>
                    </TableRow>
                  </TableHead>
                  {props.getIncidentsResponse.isLoading && (
                    <AppSkeleton
                      numOfRows={3}
                      numOfColumns={3}
                      columnWidth={100}
                      tag="table"
                    />
                  )}
                  {!props.getIncidentsResponse.isLoading &&
                    props.incidentList.length > 0 && (
                      <TableBody>
                        {props.incidentList.map((i: IncidentListDto) => (
                          <TableRow>
                            {/* <StyledTableCell >{i.id}</StyledTableCell> */}
                            <StyledTableCell>{i.incident}</StyledTableCell>
                            <StyledTableCell>{i.date}</StyledTableCell>
                            <StyledTableCell>{i.outCome}</StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  {!props.getIncidentsResponse.isLoading &&
                    props.incidentList.length === 0 && (
                      <TableBody>
                        <TableRow>
                          <StyledTableCell align="center" colSpan={5}>
                            No incident data to preview
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    )}
                </Table>
              </TableContainer>
            </section>
          </section>
        </Stepper>
        <section className={style.sectionCardFooter}>
          <CustomButton
            text="Close"
            border="1px solid #6e6e6e"
            bgColor="#282828"
            onClick={props.onNavigateTo}
          />
          {props.screenMode === VEHICLE_SCREEN_MODES.CREATE && (
            <CustomButton
              text="Clear"
              textColor="black"
              bgColor="#bfbfbf"
              onClick={props.onClearCreateVehicleForm}
            />
          )}
          {props.screenMode === VEHICLE_SCREEN_MODES.CREATE && (
            <CustomButton
              text="Add Vehicle"
              disabled={props.createVehicleResponse.isLoading}
              isLoading={props.createVehicleResponse.isLoading}
              onClick={props.onCreateVehicle}
            />
          )}
          {props.screenMode === VEHICLE_SCREEN_MODES.EDIT && (
            <CustomButton
              text="Edit Vehicle"
              disabled={props.editVehicleResponse.isLoading}
              isLoading={props.editVehicleResponse.isLoading}
              onClick={props.onEditVehicle}
            />
          )}
        </section>
      </section>
    </section>
  );
};

export default VehicleManagementForm;
