import React, { useEffect, useState } from "react";
import { AppLayout } from "../../templates";
import { AlertDto, ApplicationStateDto, OptionsDto, RedirectInformationFormDto, RequestSummaryParamDto, SortMetaDto, TMRequestApprovalParamDto, TMRequestRedirectParamDto,
  TMRequestSummaryListDto, TmRequestSummaryBodyDto, TmRequestSummaryParamDto, VehicleTypesDto, filterationParam, getVehicleListParamDto, selectedsbuDto, vehicleDetailsListDto, vehicleDetailsParamDto,} from "../../utilities/models";
import { useDispatch, useSelector } from "react-redux";
import { alertActions, requestActions, splitActions, tripActions, } from "../../redux/actions";
import { ALERT_ACTION_TYPES, APP_ACTION_STATUS, APP_ROUTES, APP_TABLE_CONFIGS, COMMON_ACTION_TYPES, NEW_REQUEST_TYPES, REQUEST_TYPES, TM_APPROVAL_ACTION, } from "../../utilities/constants";
import { ConfirmationDialog, TmRequestApprovalGrid } from "../../components";
import { useNavigate } from "react-router";
import moment from "moment";
import TmSplitRequestPopupView from "../../components/TmRequestApproval/TmSplitRequestPopupView/TmSplitRequestPopupView";
import TmRequestRejectPopupView from "../../components/TmRequestApproval/TmRequestRejectPopupView/TmRequestRejectPopupView";
import TmRequestRedirectPopupView from "../../components/TmRequestApproval/TmRequestRedirectPopupView/TmRequestRedirectPopupView";
import { validateFormData } from "../../utilities/helpers/FormValidator";
import dayjs from "dayjs";
import TmRequestApprovalViewAvaliBilityPopUp from "../../components/TmRequestApproval/TmRequestApprovalViewAvaliBilityPopUp/TmRequestApprovalViewAvaliBilityPopUp";

const TmRequestApproval = () => {
  const INITIAL_SORT_META: SortMetaDto = {
    field: "",
    asc: false,
  };

  const filterParam:filterationParam ={
    pending:true
  }

  const INITIAL_FILTER_OPTIONS : TmRequestSummaryBodyDto = {
    parentRequestId: null,
    recurrentParentId: null,
    requestType: null,
    userName: null,
    sbuName: null,
    plantName: null,
    departmentName: null,
    travelMode: null,
    fromLocation: null,
    toLocation: null,
    passengerCount: null,
    preferredVehicle: null,
    package: null,
    createdBy: null,
    status: null,

    requestOnBehalfOf: null,
    performedBy: null,
    createdFromDateandTime: null,
    createdToDateandTime: null,
    departureFromDateandTime: null,
    departureToDateandTime: null,
    returnFromDateandTime: null,
    returnToDateandTime: null
  }
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE);
  const [sortMeta, setSortMeta] = useState<SortMetaDto>(INITIAL_SORT_META);
  const [filteredList, setFilteredList] = useState<TMRequestSummaryListDto[]>([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [isOpenApproveAllocate, setIsOpenApproveAllocate] = useState(false);
  const [isOpenReject, setIsOpenReject] = useState(false);
  const [isOpenSplitViewPopup, setIsOpenSplitViewPopup] = useState(false);

  const [reqId, setReqId] = useState<number>(0);
  const [reqType, setReqType] = useState<string>("");
  const [reqStartDate, setReqStartDate] =  useState<string>("");
  const [reqEndDate, setReqEndDate] =  useState<string>("");
  const [isOpenRedirectViewPopup, setIsOpenRedirectViewPopup] = useState(false);
  const [helperText, setHelperText] = useState(true);

  const [VehicleTypeError, setVehicleTypeError] = useState(false);
  const [VehicleTypeList, setVehicleTypeList] = useState<VehicleTypesDto[]>();
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const [passengerCount, setPassengerCount] = useState(0);
  const [vehicleDetails, setVehicleDetails] = useState<vehicleDetailsListDto[]>();
  const [isOpenViewVehicleDetailsPopUp, setisOpenViewVehicleDetailsPopUp] = useState(false);
  const [dateTime, setdateTime] = useState("")
  const getRequestDataResponse = useSelector((state: ApplicationStateDto) => state.request.getTMRequestList);
  const TransportManageApprovalResponse = useSelector((state: ApplicationStateDto) => state.request.tmApproval);
  const vehicleTypeListResponse = useSelector((state: ApplicationStateDto) => state.trip.getVehicleList);
  const vehicleDetailsListResponse = useSelector((state: ApplicationStateDto) => state.trip.getVehicleDetailsList);
  const getTmSummaryFiltersRes = useSelector((state: ApplicationStateDto) => state.request.getTMRequestSummaryFilters);
  const [filterBody, setFilterBody] = useState<TmRequestSummaryBodyDto>(INITIAL_FILTER_OPTIONS);
  const [filters, setFilters] = useState<any[]>([]);

  const redirectAlert: AlertDto = {
    message: "Redirected requests cannot be redirected again!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const splitAlert:AlertDto ={
    message:"Redirected requests cannot be splitted!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    }
  }

  const rejectAlert:AlertDto ={
    message:"Redirected requests cannot be rejected!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    }
  }

  const approveAlert:AlertDto ={
    message:"Redirected requests cannot be approved!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    }
  }

  const approveAllocateAlert:AlertDto ={
    message:"Redirected requests cannot be approved & allocated!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    }
  }

  useEffect(() => {
    // getRequestList(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE, 1);
    dispatch(requestActions.getTMRequestSummaryFilters(filterParam));
    return () => {
      dispatch(requestActions.transportManagerApprovalClear())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getRequestDataResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (getRequestDataResponse.data.length > 0) {
        setFilteredList(
          getRequestDataResponse.data.map((item) => {
            return {
              ...item,
              isSelect: false,
            };
          })
        );
      } else setFilteredList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequestDataResponse.status]);

  useEffect(() => {
    if (TransportManageApprovalResponse.status === APP_ACTION_STATUS.SUCCESS) {
      navigate(APP_ROUTES.TM_DASHBOARD)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TransportManageApprovalResponse.status]);

  useEffect(() => {
    if (getTmSummaryFiltersRes.status === APP_ACTION_STATUS.SUCCESS) {
      setFilters(getTmSummaryFiltersRes.data)
    }
  }, [getTmSummaryFiltersRes.status])

  const getRequestList = async (r: number, p: number) => {
    const _param: TmRequestSummaryParamDto = {
      pagination: r,
      pageNumber: p,
      pending: true,
      filter: filterBody
    };
    dispatch(requestActions.getTMRequestSummaryList(_param));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    getRequestList(rowsPerPage, newPage + 1);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    getRequestList(+event.target.value, 1);
    setPage(0);
  };

  const onSelectRequestIds = (id: string, value: boolean) => {
    if (id === "ALL") {
      setFilteredList(
        filteredList.map((item) => {
          return {
            ...item,
            isSelect: value,
          };
        })
      );
    } else {
      const selectedItem = filteredList.find((item: any) => item.requestId === id);
      if (selectedItem) {
        setReqId(parseInt(selectedItem.requestId));
        const requestType = selectedItem.requestType;   
        const departureDate = selectedItem.departureDateandTime?.split(",").toString();
        const returnDate = selectedItem.returnDateandTime?.split(",").toString();
        setReqType(requestType);
        setReqStartDate(departureDate);
        setReqEndDate(returnDate);
      }
      setFilteredList(
        filteredList.map((item) => {
          return {
            ...item,
            isSelect: id === item.requestId ? value : item.isSelect,
          };
        })
      );
    }
  };

  const navigteTORequestScreen = (mode: string, id: string) => {
    sessionStorage.setItem("Mode", mode);
    sessionStorage.setItem("id", id);
    navigate(APP_ROUTES.MANAGE_REQUEST);
  };

  const onApproveRequest = (con: boolean) => {
    setIsOpenApprove(false);
    if (con) {
      let _list: Array<string> = [];
      filteredList.forEach((req) => {
        if (req.isSelect) {
          _list = [..._list, req.requestId];
        }
      });
      const param: TMRequestApprovalParamDto = {
        requestId: _list,
        approve: true,
        note: "",
      };
      dispatch(requestActions.transportManagerApproval(param));
    }
  };



  const onApproveAllocateRequest = async (con: boolean) => {
    if (con) {
      let _list: Array<string> = []
      let _isVip: boolean = false
      let _isRecurrentTrip: boolean = false

      filteredList.forEach((req) => {
        if (req.isSelect) {
          if (req.viPtrip) _isVip = true
          if (req.requestType === REQUEST_TYPES[1].name) _isRecurrentTrip = true
          _list = [..._list, req.requestId.toString()]
        }
      })

      if (_isVip && _list.length > 1) {
        const setAlert: AlertDto = {
          message: "Please select non VIP request.!",
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: "error",
          },
        };
        dispatch(alertActions.triggerAlert(setAlert));
      }

      if (_isRecurrentTrip && _list.length > 1) {
        const setAlert: AlertDto = {
          message: "Selected requests cannot be merged into a single trip. Please check the dates.!",
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: "error",
          },
        };
        dispatch(alertActions.triggerAlert(setAlert));
      }

      if (_list.length === 1) {
        const param: TMRequestApprovalParamDto = {
          requestId: _list,
          approve: true,
          note: "",
        };
        dispatch(requestActions.transportManagerApproval(param));
        navigate(APP_ROUTES.TM_ACCEPT_VEHICLE_ALLOCATION, { state: { reqList: _list } })
      } else if (_list.length > 1 && !_isVip && !_isRecurrentTrip) {
        const param: TMRequestApprovalParamDto = {
          requestId: _list,
          approve: true,
          note: "",
        };
        dispatch(requestActions.transportManagerApproval(param));
        navigate(APP_ROUTES.TM_ACCEPT_VEHICLE_ALLOCATION, { state: { reqList: _list } })
      }
    }

    setIsOpenApproveAllocate(false);
  }

  const onRejectRequest = (con: boolean, reason: string) => {
    setIsOpenReject(false);
    if (con) {
      let _list: Array<string> = [];
      filteredList.forEach((req) => {
        if (req.isSelect) {
          _list = [..._list, req.requestId];
        }
      });
      const param: TMRequestApprovalParamDto = {
        requestId: _list,
        approve: false,
        note: reason,
      };
      dispatch(requestActions.transportManagerApproval(param));
    }
  };

  const onActionButtonClick = (action: string) => {
    let count = 1;
    let _list: Array<string> = [];
    let _redirectedList: Array<string> = [];

    filteredList.forEach((req) => {
      if (req.isSelect) {
        _list = [..._list, req.requestId];
        if (req.status === "Redirected by Me") {
          _redirectedList = [..._redirectedList, req.requestId]
        }
      }
    });


    if (_list.length === count && action === TM_APPROVAL_ACTION.SPLIT) {
      if(_redirectedList.length <= 0){
        showSplitViewPopup(true);
      }else{
        dispatch(alertActions.triggerAlert(splitAlert))
      }
    } else if (_list.length > 0) {
      if (action === TM_APPROVAL_ACTION.APPROVE) {
        if(_redirectedList.length <= 0){
          setIsOpenApprove(true);
        }else{
          dispatch(alertActions.triggerAlert(approveAlert))
        }
      } else if (action === TM_APPROVAL_ACTION.REDIRECT) {
        if (_redirectedList.length > 0) {
          dispatch(alertActions.triggerAlert(redirectAlert));
        } else {
          setIsOpenRedirectViewPopup(true);
        }
      } else if (action === TM_APPROVAL_ACTION.REJECT) {
        if(_redirectedList.length <= 0){
          setIsOpenReject(true);
        }else{
          dispatch(alertActions.triggerAlert(rejectAlert))
        }
      } else if (action === TM_APPROVAL_ACTION.APPROVE_ALLOCATE) {
        if(_redirectedList.length <= 0){
          setIsOpenApproveAllocate(true);
        }else{
          dispatch(alertActions.triggerAlert(approveAllocateAlert))
        }
      }
    }
  };

  const onSortHandle = (col: string) => {
    const sorted = filteredList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setFilteredList(sorted);
  };

  const onClearFilter = () => {
    setIsFiltered(false)
    setFilterBody(INITIAL_FILTER_OPTIONS)
    setPage(0)
  };

  // const onFilterHandle = (col: string, value: any) => {
  //   setIsFiltered(true);
  //   const _list = getRequestDataResponse.data;
  //   const filtered = _list.filter((item) => {
  //     const _value = (item as any)[col];
  //     if (typeof _value === "boolean") {
  //       return _value ? value === "Yes" : value === "No";
  //     }
  //     if (col === "createdDateandTime") {
  //       const _selectedMin = dayjs(value[0]).format('YYYY-MM-DD HH:mm')
  //       const _selectedMax = dayjs(value[1]).format('YYYY-MM-DD HH:mm')
  //       const _targetDate = dayjs(_value).add(330, 'minute').format('YYYY-MM-DD HH:mm')

  //       return moment(_targetDate).isBetween(_selectedMin, _selectedMax)
  //     }
  //     if (col === "departureDateandTime" || col === "returnDateandTime") {
  //       const _selectedMin = dayjs(value[0]).format('YYYY-MM-DD HH:mm')
  //       const _selectedMax = dayjs(value[1]).format('YYYY-MM-DD HH:mm')
  //       const _targetDate = dayjs(_value).format('YYYY-MM-DD HH:mm')

  //       return moment(_targetDate).isBetween(_selectedMin, _selectedMax)
  //     }
  //     if (value === "N/A") return !_value;
  //     return _value === value;
  //   });

  //   setFilteredList(filtered);
  // };

  const onFilterHandle = (col: string, value: any) => {
    setIsFiltered(true)

    if (filterBody.hasOwnProperty(col)) {
      setFilterBody(prevFilterBody => ({
        ...prevFilterBody,
        [col]: value,
      }));
    } else {
      if (col === "createdDateandTime") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DDTHH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DDTHH:mm");

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          createdFromDateandTime: _selectedMin,
          createdToDateandTime: _selectedMax
        }));
      } else if (col === "departureDateandTime") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DDTHH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DDTHH:mm");

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          departureFromDateandTime: _selectedMin,
          departureToDateandTime: _selectedMax
        }));
      } else if (col === "returnDateandTime") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DDTHH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DDTHH:mm");

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          returnFromDateandTime: _selectedMin,
          returnToDateandTime: _selectedMax
        }));
      }else if(col === 'packageX'){
        const _valueCon = value === "Yes" ? true : false

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          package:_valueCon
        }));
      }
    }
  }

  useEffect(() => {
    getRequestList(rowsPerPage, 1)
    setPage(0)
  }, [isFiltered, filterBody])

  useEffect(() => {
    setFilteredList(getRequestDataResponse.data);
  }, [filterBody])

  const getFilterList = (col: string): string[] => {
    if (!getRequestDataResponse.isLoading) {
      return getRequestDataResponse.data
        .map((item) => {
          const value = (item as any)[col];
          if (typeof value === "boolean") {
            return value ? "Yes" : "No";
          }
          return value ? value : "N/A";
        })
        .filter((value, index, array) => array.indexOf(value) === index);
    } else return [];
  };

  const splitTypes = useSelector(
    (state: ApplicationStateDto) => state.split.getSplitTypes.data
  );
  const getPassengerPackages = useSelector(
    (state: ApplicationStateDto) => state.split.getPassengersPackages.data
  );

  const allSbuList = useSelector(
    (state: ApplicationStateDto) => state.request.getSbuList.data
  );

  const showSplitViewPopup = (con: boolean) => {
    // if(reqType !== NEW_REQUEST_TYPES[3].name){
      setIsOpenSplitViewPopup(con);
    // }
    dispatch(splitActions.getSplitPassengersPackages(reqId));
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialData = async () => {
    dispatch(splitActions.getSplitTypes());
    dispatch(requestActions.getSbuList());
  };

  const showRedirectViewPopup = (con: boolean) => {
    setIsOpenRedirectViewPopup(con);
  };

  const [selectedSbuManager, setSelectedSbuManager] = useState<selectedsbuDto>();
  const [selectedRedirector, setSelectedRedirector] = useState("");
  const redirectPostResponse = useSelector((state: ApplicationStateDto) => state.split.addRedirectRequest);
  const allRedirectedUserResponse = useSelector((state: ApplicationStateDto) => state.split.getTransportManager);
  const allPlantsBySbuIdResponse = useSelector((state: ApplicationStateDto) => state.request.getPlantListBySbuList);
  const createSplit = useSelector((state: ApplicationStateDto) => state.split.addSplitRequest);
  const REDIRECT_INFORMATION_FORM_INITIAL_STATE: RedirectInformationFormDto = {
    sbu: {
      value: {} as OptionsDto,
      isRequired: true,
      disable: false,
      readonly: false,
      validator: "object",
      error: "",
    },
    plant: {
      value: {} as OptionsDto,
      isRequired: true,
      disable: true,
      readonly: false,
      validator: "object",
      error: "",
    },
  };

  const [redirectInformationForm, setRedirectInformationForm] = useState(REDIRECT_INFORMATION_FORM_INITIAL_STATE);

  useEffect(() => {
    const selectedSbuObject = allSbuList.find(
      (sbu) => sbu.id === redirectInformationForm.sbu.value.value
    );

    const data: selectedsbuDto = {
      sbuManagerId: Number(selectedSbuObject?.sbuManagerId) || 0,
      sbuManagerName: selectedSbuObject?.sbuManagerName || "",
    };

    setSelectedSbuManager(data);
  }, [allSbuList, redirectInformationForm.sbu.value]);

  const onInputHandleChangeRedirectInformation = (property: string, value: any) => {
    setHelperText(true);
    if (property === "sbu") {
      setRedirectInformationForm({
        ...redirectInformationForm,
        sbu: {
          ...redirectInformationForm.sbu,
          value: value ? value : ({} as OptionsDto),
        },
        plant: {
          ...redirectInformationForm.plant,
          value: {} as OptionsDto,
          disable: false,
        },
      });
      dispatch(requestActions.getPlantListBySbuId(value.value));
      const selectedSbuObject = allSbuList.find(
        (sbu) => sbu.id === redirectInformationForm.sbu.value.value
      );
      setSelectedSbuManager({
        sbuManagerId: Number(selectedSbuObject?.sbuManagerId) || 0,
        sbuManagerName: selectedSbuObject?.sbuManagerName || "",
      });
    }
    if (property === "plant") {
      setRedirectInformationForm({
        ...redirectInformationForm,
        plant: {
          ...redirectInformationForm.plant,
          value: value ? value : ({} as OptionsDto),
        },
      });
      dispatch(splitActions.getTransportManager(value.value));
    }
  };

  const handleInputFocus = (property: string, section: string) => {
    if (section === "RI")
      setRedirectInformationForm({
        ...redirectInformationForm,
        [property]: {
          ...redirectInformationForm[
          property as keyof typeof redirectInformationForm
          ],
          error: null,
        },
      });
  };

  const onHandleInputChange = (property: string, value: string) => {
    if (property === "redirectTo") {
      setSelectedRedirector(value);
    }
    if (property === "vehicleType") {
      setSelectedVehicleType(value);
    }
  };

  useEffect(() => {
    if (redirectPostResponse.status === APP_ACTION_STATUS.SUCCESS) {
      getRequestList(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectPostResponse.status]);

  const onRedirectRequest = async (reason: string) => {
    let _list: Array<string> = [];
    filteredList.forEach((req) => {
      if (req.isSelect) {
        _list = [..._list, req.requestId];
      }
    });

    const param: TMRequestRedirectParamDto = {
      requestIds: _list,
      redirectedToId: selectedRedirector,
      note: reason,
    };

    const [validateData, isValid] = await validateFormData(
      redirectInformationForm
    );
    setRedirectInformationForm(validateData);

    if (isValid) {
      if (selectedRedirector !== "") {
        dispatch(splitActions.createRedirectRequest(param));
        showRedirectViewPopup(false);
      } else {
        const setAlert: AlertDto = {
          message: "Something went wrong!",
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: "error",
          },
        };
        dispatch(alertActions.triggerAlert(setAlert));
        showRedirectViewPopup(false);
      }
    } else {
      const setAlert: AlertDto = {
        message: "Please fill required field/s",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: "error",
        },
      };
      dispatch(alertActions.triggerAlert(setAlert));
    }
  };

  const onCancelRedirectPopup = () => {
    const data: selectedsbuDto = {
      sbuManagerId: 0,
      sbuManagerName: "",
    };
    dispatch(splitActions.clearTransportManager());
    setRedirectInformationForm(REDIRECT_INFORMATION_FORM_INITIAL_STATE);
    setSelectedSbuManager(data);
    setSelectedRedirector("");
    showRedirectViewPopup(false);
  };

  const viewAvailability = (value: number, dateValue: string) => {
    setPassengerCount(value);
    setisOpenViewVehicleDetailsPopUp(true);
    setdateTime(dateValue)

    const Count: getVehicleListParamDto = {
      NOSeats: passengerCount.toString(),
      purpose: 1
    };
    dispatch(tripActions.getVehicleLitsBYPassengerCount(Count));
  };



  const handleClosePopUp = () => {
    setisOpenViewVehicleDetailsPopUp(false);
    setVehicleTypeError(false);
    setVehicleTypeList([]);
    setVehicleDetails([]);
    setSelectedVehicleType("");
    dispatch(tripActions.getVehicleLitsBYPassengerCountClear());
    dispatch(tripActions.getVehicleDetailsByVehicleClear());
  };

  useEffect(() => {
    if (vehicleTypeListResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setVehicleTypeList(vehicleTypeListResponse.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleTypeListResponse.status]);

  const onShowVehicle = () => {
    if (selectedVehicleType) {
      setVehicleTypeError(false);
      const vehicleDetailsParm: vehicleDetailsParamDto = {
        VehicleTypeId: selectedVehicleType,
        PassengerCount: passengerCount,
        fromDate: moment(dateTime).format("YYYY-MM-DD"),
        toDate:moment(dateTime).format("YYYY-MM-DD")
      };

      dispatch(tripActions.getVehicleDetailsByVehicleType(vehicleDetailsParm));
    } else {
      setVehicleTypeError(true);
    }
  };
  useEffect(() => {
    if (vehicleDetailsListResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setVehicleDetails(vehicleDetailsListResponse.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleDetailsListResponse.status]);

  useEffect(() => {
    if (createSplit.status === APP_ACTION_STATUS.SUCCESS) {
      getRequestList(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSplit.status]);

  return (
    <React.Fragment>
      <AppLayout componentTitle="Request Approval">
        <section className="page-root">
          <TmRequestApprovalGrid
            page={page}
            rowsPerPage={rowsPerPage}
            onHandleChangePage={handleChangePage}
            onHandleChangeRowsPerPage={handleChangeRowsPerPage}
            requestDataIsLoading={getRequestDataResponse.isLoading}
            filteredList={filteredList || []}
            sortMeta={sortMeta}
            onSortHandle={onSortHandle}
            onFilterHandle={onFilterHandle}
            getFilterList={getFilterList}
            onActionButtonClick={onActionButtonClick}
            onSelectRequestIds={onSelectRequestIds}
            navigateTo={navigteTORequestScreen}
            onClearFilter={onClearFilter}
            isFiltered={isFiltered}
            showSplitViewPopup={showSplitViewPopup}
            showRedirectViewPopup={showRedirectViewPopup}
            viewAvailability={viewAvailability}
            filters={filters}
            reqType={reqType}
          />
          <ConfirmationDialog
            isOpenConfirmationDialog={isOpenApprove}
            onCallback={onApproveRequest}
            title="Are you sure ?"
            content="Are you sure you want to approve ?"
            confirmButtonTitle="Yes"
            cancelButtonTitle="No"
          />

          <ConfirmationDialog
            isOpenConfirmationDialog={isOpenApproveAllocate}
            onCallback={onApproveAllocateRequest}
            title="Are you sure ?"
            content="Are you sure you want to approve & allocate?"
            confirmButtonTitle="Yes"
            cancelButtonTitle="No"
          />

          <TmRequestRejectPopupView
            isOpenConfirmationDialog={isOpenReject}
            onCallback={(confirmed: boolean, reason: string) =>
              onRejectRequest(confirmed, reason)
            }
            title="Are you sure?"
            content="Are you sure you want to reject?"
            confirmButtonTitle="Yes"
            cancelButtonTitle="No"
          />
          <TmSplitRequestPopupView
            isOpenSplitViewPopup={isOpenSplitViewPopup}
            showSplitViewPopup={showSplitViewPopup}
            splitTypes={splitTypes || []}
            reqId={reqId}
            reqType={reqType}
            reqStartDate={reqStartDate}
            reqEndDate={reqEndDate}
            getPassengerPackages={getPassengerPackages || []}
          />
          <TmRequestRedirectPopupView
            isOpenRedirectViewPopup={isOpenRedirectViewPopup}
            showRedirectViewPopup={showRedirectViewPopup}
            allSbuList={allSbuList || []}
            selectedSbuManager={selectedSbuManager}
            redirectInformationForm={redirectInformationForm}
            handleInputFocus={handleInputFocus}
            onInputHandleChangeRedirectInformation={
              onInputHandleChangeRedirectInformation
            }
            helperText={helperText}
            onRedirectRequest={onRedirectRequest}
            selectedRedirector={selectedRedirector}
            onHandleInputChange={onHandleInputChange}
            allPlantsBySbuIdResponse={allPlantsBySbuIdResponse}
            allRedirectedUserResponse={allRedirectedUserResponse}
            onCancelRedirectPopup={onCancelRedirectPopup}
          />
          <TmRequestApprovalViewAvaliBilityPopUp
            passengerCount={passengerCount}
            isOpenViewVehicleDetailsPopUp={isOpenViewVehicleDetailsPopUp}
            handleClosePopUp={handleClosePopUp}
            VehicleTypeError={VehicleTypeError}
            onHandleInputChange={onHandleInputChange}
            VehicleTypeList={VehicleTypeList || []}
            selectedVehicleType={selectedVehicleType}
            onShowVehicle={onShowVehicle}
            vehicleDetails={vehicleDetails || []}
          />
        </section>
      </AppLayout>
    </React.Fragment>
  );
};

export default TmRequestApproval;
