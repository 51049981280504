
import { Box, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { EditOutlined } from '@mui/icons-material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import React from 'react'
import { StyledStatusApproved, StyledStatusDraft, StyledStatusPending, StyledStatusRejected, StyledTableCell } from '../../../assets/theme/theme';
import style from './ViewRequests.module.scss'
import { SelfRequestSummaryListDto, SortMetaDto } from '../../../utilities/models';
import { APP_TABLE_CONFIGS, REQUEST_SCREEN_MODES,REQUEST_SCREEN_EXPRESS_MODES, REQUEST_STATUS, APP_ROUTES } from '../../../utilities/constants';
import CustomButton from '../../Shared/CustomButton/CustomButton';
import moment from 'moment';
import { CustomHeaderCell, AppSkeleton } from '../../Shared';
import { useNavigate } from 'react-router-dom';

const ViewRequests: React.FC<{
  page: number,
  rowsPerPage: number,
  isFiltered: boolean,
  onHandleChangePage(event: unknown, newPage: number): void,
  onHandleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void,
  requestDataIsLoading: boolean,
  filteredList: SelfRequestSummaryListDto[],
  sortMeta: SortMetaDto,
  onSortHandle(col: string): void
  setSelectedStatus(id: string): void
  onFilterHandle(col: string, value: any): void;
  getFilterList: (col: string) => string[];
  navigateTo(mode: string, id: string): void;
  onClearFilter(): void;
  onSelectRequestForRemove(id: string): void;
  setisOpenRequestCreationPopup(value:boolean):void
}> = (props) => {
  const navigate = useNavigate();
  return (
    <section className={style.gridContainer}>
      <div className={style.gridHeader}>
        <Typography
          noWrap
          component="div"
          className={style.gridTitle}
        >
          <h3>Self-Request Summary</h3>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <div className='layout-row'>
          {props.isFiltered &&
            <CustomButton text='Clear filter' textColor='black' bgColor='#bfbfbf' onClick={props.onClearFilter} />
          }
          <CustomButton icon={<AddIcon sx={{ fontSize: '20px' }} />} text='New Request' onClick={() => {  props.setisOpenRequestCreationPopup(true) }} />
        </div>
      </div>

      <TableContainer component={Paper} className={style.grid}>
        <Table className={style.table}>
          <TableHead>
            <TableRow>
              <CustomHeaderCell width={180} id='recurrentParentId' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Parent ID</CustomHeaderCell>
              <CustomHeaderCell width={220} id='parentRequestId' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Pre-split Request ID</CustomHeaderCell>
              <CustomHeaderCell width={180} id='requestId' sortable onSort={props.onSortHandle} >Request ID</CustomHeaderCell>
              <CustomHeaderCell width={180} id='requestType' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Request Type</CustomHeaderCell>
              <CustomHeaderCell width={300} id='status' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Status</CustomHeaderCell>
              <CustomHeaderCell width={270} id='departureDateandTime' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Departure date and time</CustomHeaderCell>
              <CustomHeaderCell width={250} id='returnDateandTime' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Return date and time</CustomHeaderCell>
              <CustomHeaderCell width={180} id='from' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Travel From</CustomHeaderCell>
              <CustomHeaderCell width={300} id='to' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Travel To (Main Destination)</CustomHeaderCell>
              <CustomHeaderCell width={150} id='viPtrip' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>VIP trip</CustomHeaderCell>
              <CustomHeaderCell width={220} id='travelMode' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Travel Mode</CustomHeaderCell>
              <CustomHeaderCell width={180} id='passengerCount' >Passenger Count</CustomHeaderCell>
              <CustomHeaderCell width={240} id='preferredVehicle' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Preferred Vehicle</CustomHeaderCell>
              <CustomHeaderCell width={150} id='package' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Package</CustomHeaderCell>
              <CustomHeaderCell width={240} id='cbm' sortable onSort={props.onSortHandle} >Total CBM of Package</CustomHeaderCell>
              <CustomHeaderCell width={300} id='purpose' filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Purpose</CustomHeaderCell>
              <CustomHeaderCell width={250} id='sbuName' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>SBU (Billable)</CustomHeaderCell>
              <CustomHeaderCell width={250} id='plantName' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Plant (Billable)</CustomHeaderCell>
              <CustomHeaderCell width={300} id='departmentName' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Department (Billable)</CustomHeaderCell>
              <CustomHeaderCell width={200} id='approver' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Approver</CustomHeaderCell>
              <CustomHeaderCell width={200} id='createdFor' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Created For</CustomHeaderCell>
              <CustomHeaderCell width={240} id='createdDateandTime' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Create date and time</CustomHeaderCell>
              <CustomHeaderCell width={150} id='actions' >Actions</CustomHeaderCell>
            </TableRow>
          </TableHead>
          {props.requestDataIsLoading && (
            <AppSkeleton numOfRows={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE} numOfColumns={23} columnWidth={120} height={30} tag="table" />
          )}
          {!props.requestDataIsLoading && props.filteredList.length > 0 &&
            <TableBody>
              {props.filteredList.map((item: SelfRequestSummaryListDto) => (
                <TableRow key={item.requestId}>
                  <StyledTableCell >{!item.recurrentParentId ? 'N/A' : 'R' + item.recurrentParentId}</StyledTableCell>
                  <StyledTableCell >{!item.parentRequestId ? 'N/A' : 'R' + item.parentRequestId}</StyledTableCell>
                  <StyledTableCell>
                  {item.requestType === "Express AdHoc"
    ? "E" + item.requestId.toString()
    : "R" + item.requestId.toString()
  }
</StyledTableCell>
                  <StyledTableCell >{item.requestType}</StyledTableCell>
                  <StyledTableCell>
                    {item.status === REQUEST_STATUS.APPROVED_LM ||
                      item.status === REQUEST_STATUS.APPROVED_TM ||
                      item.status === REQUEST_STATUS.ALLOCATED_ME ||
                      item.status === REQUEST_STATUS.ALLOCATED_TM ||
                      item.status === REQUEST_STATUS.APPROVED_ME
                      ? (
                        <StyledStatusApproved onClick={() => props.setSelectedStatus(item.requestId.toString())}>
                          {item.status}
                        </StyledStatusApproved>
                      ) : item.status === REQUEST_STATUS.ALLOCATION_CANCELLED_TM ||
                        item.status === REQUEST_STATUS.ALLOCATION_CANCELLED_ME ||
                        item.status === REQUEST_STATUS.REDIRECTION_REJECTED_TM ||
                        item.status === REQUEST_STATUS.REJECTED_LM ||
                        item.status === REQUEST_STATUS.REJECTED_TM ||
                        item.status === REQUEST_STATUS.REJECTED_ME
                        ? (
                          <StyledStatusRejected onClick={() => props.setSelectedStatus(item.requestId.toString())}>
                            {item.status}
                          </StyledStatusRejected>
                        ) : item.status === REQUEST_STATUS.PENDING ||
                          item.status === REQUEST_STATUS.REDIRECTED_ME ||
                          item.status === REQUEST_STATUS.REDIRECTED_TM ||
                          item.status === REQUEST_STATUS.ALLOCATION_EDITED_TM ||
                          item.status === REQUEST_STATUS.ALLOCATION_EDITED_ME
                          ? (
                            <StyledStatusPending onClick={() => props.setSelectedStatus(item.requestId.toString())}>
                              {item.status}
                            </StyledStatusPending>
                          ) : (
                            <StyledStatusDraft onClick={() => props.setSelectedStatus(item.requestId.toString())}>
                              {item.status}
                            </StyledStatusDraft>
                          )}
                  </StyledTableCell>
                  <StyledTableCell >{moment(item.departureDateandTime).format(APP_TABLE_CONFIGS.DATE_FORMAT)}</StyledTableCell>
                  {/* <StyledTableCell >{item.departureDateandTime}</StyledTableCell> */}
                  <StyledTableCell >{moment(item.returnDateandTime).format(APP_TABLE_CONFIGS.DATE_FORMAT)}</StyledTableCell>
                  <StyledTableCell >{item.from}</StyledTableCell>
                  <StyledTableCell >{item.to}</StyledTableCell>
                  <StyledTableCell >{item.viPtrip ? "Yes" : "No"}</StyledTableCell>
                  <StyledTableCell >{item.travelMode}</StyledTableCell>
                  <StyledTableCell >{item.passengerCount ?? "-"}</StyledTableCell>
                  <StyledTableCell >{item.preferredVehicle}</StyledTableCell>
                  <StyledTableCell >{item.package ? "Yes" : "No"}</StyledTableCell>
                  <StyledTableCell >
                    {item.cbm !== null
                      ? <>{item.cbm}</>
                      : <>-</>
                    }
                  </StyledTableCell>
                  <StyledTableCell >{item.purpose !== "" ? item.purpose : '-'}</StyledTableCell>
                  <StyledTableCell >{item.sbuName ?? '-'}</StyledTableCell>
                  <StyledTableCell >{item.plantName ?? '-'}</StyledTableCell>
                  <StyledTableCell >{item.departmentName ?? '-'}</StyledTableCell>
                  <StyledTableCell >{item.approver}</StyledTableCell>
                  <StyledTableCell >{item.createdFor}</StyledTableCell>
                  <StyledTableCell >{moment(item.createdDateandTime).add(330, 'minute').format(APP_TABLE_CONFIGS.DATE_FORMAT)}</StyledTableCell>
                  <StyledTableCell style={{ backgroundColor: '#282828' }}>
                    <Box className='layout-row'>
                      <Box>
                        <IconButton size='small' onClick={() => { 
                             if(item.requestType==="Express AdHoc"){
                
                              sessionStorage.setItem("Mode",REQUEST_SCREEN_EXPRESS_MODES.VIEW);
                              sessionStorage.setItem("id", item.requestId.toString());
                              navigate(APP_ROUTES.MANAGE_REQUEST_EXPRESS);
                             }else{
                              props.navigateTo(REQUEST_SCREEN_MODES.VIEW, item.requestId.toString()) }
                             }
                          
                          }>
                          <Tooltip title="View">
                            <RemoveRedEyeOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                      {(item.status === REQUEST_STATUS.PENDING ||
                      item.status === REQUEST_STATUS.DRAFT ||
                        item.status === REQUEST_STATUS.REJECTED_LM
                      ) &&
                        <>
                          <Box>
                            <IconButton size='small' onClick={() => { 
                               if(item.requestType==="Express AdHoc"){
                
                                sessionStorage.setItem("Mode",REQUEST_SCREEN_EXPRESS_MODES.EDIT);
                                sessionStorage.setItem("id", item.requestId.toString());
                                navigate(APP_ROUTES.MANAGE_REQUEST_EXPRESS);
                               }else{
                                props.navigateTo(REQUEST_SCREEN_MODES.EDIT, item.requestId.toString())
                               }
                              }
                          
                          
                          }>
                              <Tooltip title="Edit">
                                <EditOutlined sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                              </Tooltip>
                            </IconButton>
                          </Box>
                          <Box>
                            <IconButton size='small' onClick={() => { props.onSelectRequestForRemove(item.requestId.toString()) }}>
                              <Tooltip title="Delete">
                                <DeleteOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                              </Tooltip>
                            </IconButton>
                          </Box>
                        </>
                      }
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          }

          {!props.requestDataIsLoading && props.filteredList.length === 0 &&
            <TableBody>
              <TableRow>
                <StyledTableCell align="left" colSpan={16}>No data to preview</StyledTableCell>
              </TableRow>
            </TableBody>
          }
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_OPTIONS}
        component="div"
        labelRowsPerPage={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: 'white',
            }}
          >
            Items per page
          </div>
        }
        count={-1}
        page={props.page}
        onPageChange={props.onHandleChangePage}
        onRowsPerPageChange={props.onHandleChangeRowsPerPage}
        rowsPerPage={props.rowsPerPage}
        sx={{ backgroundColor: "#282828", color: "white" }}
        nextIconButtonProps={
          { disabled: -1 < props.filteredList.length && props.filteredList.length < props.rowsPerPage }
        }
      />
    </section>
  )
}

export default ViewRequests
