import { AxiosResponse } from "axios";
import { axiosPrivateInstance } from ".";
import { LmChartDto, TmChartDto } from "../utilities/models/chart.model";

const getLMSummary = (): Promise<AxiosResponse<LmChartDto>> => {
  return axiosPrivateInstance.get(`/api/Dashboard/GetLMDashboardChartsDetails`);
};

const getGUSummary = (): Promise<AxiosResponse<string>> => {
  return axiosPrivateInstance.get(`/api/Dashboard/GetUserSummaryBoxDetails`);
};

const getTMSummary = (): Promise<AxiosResponse<TmChartDto>> => {
  return axiosPrivateInstance.get(`/api/Dashboard/GetTMDashboardChartsDetails`);
};

const getLMBudgetSummary = (): Promise<AxiosResponse<string>> => {
  return axiosPrivateInstance.get(`/api/Dashboard/GetLMSummaryBoxDetails`);
};

export const chartService = {
  getLMSummary,
  getGUSummary,
  getTMSummary,
  getLMBudgetSummary,
};
