import { AxiosResponse } from "axios"
import { axiosPrivateInstance } from "."
import { DownloadFileResponseDto, GetExecutionTemplateDto, duplicateShiftRequestDto, getExecuteTemplateByIdDto, newShiftRequestDto, shiftGroupBriefDto, shiftRequestByIdDto, shiftRequestSummaryListDto, 
    shiftRequestSummaryParam, shiftTypeBriefDto, shiftVehicleDetailsListDto, shiftVehicleDetailsParamDto } from "../utilities/models/shift.model"

const uploadFile = (payload: any):  Promise<AxiosResponse<string>> => {
    const payloadFormData = new FormData()
    payloadFormData.append('file', payload)
    return axiosPrivateInstance.post('/api/shift/UploadDailyShiftDocument', payloadFormData)
}

const downloadShiftFile = (fileName:string):Promise<AxiosResponse<DownloadFileResponseDto>>=>{
    return axiosPrivateInstance.get(`/api/Shift/GetDailyShiftDocument?fileName=${fileName}`);
}

const deleteFile = (fileName:string): Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.delete(`/api/Shift/RemoveDailyShiftDocument?fileName=${fileName}`)
}

const getShiftTypes = (): Promise<AxiosResponse<shiftTypeBriefDto[]>> => {
    return axiosPrivateInstance.get('/api/Shift/GetAllShiftTypes')
}

const getShiftGroups = (): Promise<AxiosResponse<shiftGroupBriefDto[]>> => {
    return axiosPrivateInstance.get('/api/Shift/GetAllShiftGroups')
}

const getAvailableShiftVehicles = (payload: shiftVehicleDetailsParamDto): Promise<AxiosResponse<shiftVehicleDetailsListDto>> => {
    return axiosPrivateInstance.post(`/api/Shift/GetAvailableShiftVehicles?passengerCount=${payload.passengerCount}&vehicleType=${payload.vehicleTypeId}`, payload.shiftDateRange)
}

const postShiftRequest = (payload:newShiftRequestDto):  Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.post('/api/Shift/CreateDailyShiftTemplate', payload)
}

const postShiftRequestDuplicate = (payload:duplicateShiftRequestDto):  Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.post(`/api/Shift/CreateDailyShiftTemplate?previousTemplateId=${payload.id}`, payload)
}

const getShiftRequestSummaryList = (payload:shiftRequestSummaryParam) : Promise<AxiosResponse<shiftRequestSummaryListDto[]>> => {
    return axiosPrivateInstance.get(`/api/Shift/GetShiftTemplateSummary?pageNumber=${payload.pageNumber}&pagination=${payload.pagination}`);
}

const getShiftRequestById = (requestId: string): Promise<AxiosResponse<shiftRequestByIdDto>> => {
    return axiosPrivateInstance.get(`/api/Shift/GetSelectedShiftTemplate?templateId=${requestId}`)
}

const deleteShiftRequest = (id: number): Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.put(`/api/Shift/DeleteShiftTemplate?templateId=${id}`)
}
const getExecutionTemplates = (): Promise<AxiosResponse<GetExecutionTemplateDto[]>> => {
    return axiosPrivateInstance.get(`/api/Shift/GetExecutionTemplate`)
}

const getExecutedTemplateById = (dailyShiftHistoryId:string): Promise<AxiosResponse<getExecuteTemplateByIdDto>> => {
    return axiosPrivateInstance.get(`/api/Shift/GetSelectedShiftExecutionTemplate?dailyShiftHistoryId=${dailyShiftHistoryId}`)
}

const editShiftRequest = (payload: any): Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.put(`api/Shift/UpdateDailyShiftTemplate?templateId=${payload.templateId}`, payload)
}

const executeShiftRequest = (payload: any): Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.put(`api/Shift/ExecuteShiftTemplate?templateId=${payload.templateId}`, payload)
}

export const shiftService = {
    uploadFile,
    deleteFile,
    getShiftTypes,
    getShiftGroups,
    getAvailableShiftVehicles,
    postShiftRequest,
    getShiftRequestSummaryList,
    getShiftRequestById,
    deleteShiftRequest,
    editShiftRequest,
    getExecutionTemplates,
    executeShiftRequest,
    downloadShiftFile,
    getExecutedTemplateById,
    postShiftRequestDuplicate
}