import { Grid, MenuItem, TextField } from "@mui/material";
import {
  BootstrapDialog,
  BootstrapDialogActions,
  BootstrapDialogContent,
  BootstrapDialogTitle,
  StyledTextField,
} from "../../../assets/theme/theme";
import { SbuBriefDto } from "../../../utilities/models/sbu.model";
import styles from "./TmRequestRedirectPopupView.module.scss";
import { useState } from "react";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { PlantBriefDto } from "../../../utilities/models/plant.model";
import { StateObjectDto } from "../../../utilities/models/common.model";
import CustomAutocomplete from "../../Shared/CustomAutocomplete/CustomAutocomplete";
import {
  RedirectInformationFormDto,
  TransportManagerDto,
} from "../../../utilities/models/split.model";

const TmRequestRedirectPopupView: React.FC<{
  isOpenRedirectViewPopup: boolean;
  showRedirectViewPopup(con: boolean): void;
  onCancelRedirectPopup():void;
  allSbuList: SbuBriefDto[];
  selectedSbuManager: any;
  onInputHandleChangeRedirectInformation(property: string, value: any): void;
  handleInputFocus(property: string, section: string): void;
  redirectInformationForm: RedirectInformationFormDto;
  helperText: boolean;
  allPlantsBySbuIdResponse: StateObjectDto<PlantBriefDto[] | []>;
  allRedirectedUserResponse: StateObjectDto<TransportManagerDto | any>;
  selectedRedirector: string;
  onHandleInputChange(property: string, value: string): void;
  onRedirectRequest(reason: string): void;
}> = (props) => {
  const _sbu = props.redirectInformationForm.sbu;
  const _plant = props.redirectInformationForm.plant;

  const _allPlants = props.allPlantsBySbuIdResponse.data;
  const _allRedirectedUser = props.allRedirectedUserResponse.data;

  const [reason, setReason] = useState("");

  return (
    <BootstrapDialog
      width={"40rem"}
      className={styles.dialogCard}
      aria-labelledby="customized-dialog-title"
      open={props.isOpenRedirectViewPopup}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => props.onCancelRedirectPopup()}
      >
        Redirect Request
      </BootstrapDialogTitle>
      <BootstrapDialogContent>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <CustomAutocomplete
              label="SBU"
              placeholder="Select sbu"
              onFocus={() => props.handleInputFocus("sbu", "RI")}
              options={props.allSbuList.map((t) => {
                return { label: t.name, value: t.id };
              })}
              value={_sbu.value}
              error={!!_sbu.error}
              disabled={_sbu.disable}
              readonly={_sbu.readonly}
              required={_sbu.isRequired}
              helperText={props.helperText && _sbu.error}
              onChange={(event: any, value: any) =>
                props.onInputHandleChangeRedirectInformation("sbu", value)
              }
            />
          </Grid>
          <Grid item md={12}>
            <CustomAutocomplete
              label="Plant"
              placeholder="Select Plant"
              onFocus={() => props.handleInputFocus("plant", "RI")}
              options={_allPlants.map((p) => {
                return { label: p.plantName, value: p.id };
              })}
              value={_plant.value}
              error={!!_plant.error}
              disabled={_plant.disable}
              readonly={_plant.readonly}
              required={_plant.isRequired}
              helperText={props.helperText && _plant.error}
              onChange={(event: any, value: any) =>
                props.onInputHandleChangeRedirectInformation("plant", value)
              }
            />
          </Grid>

          <Grid item md={12}>
            <StyledTextField
              placeholder="Redirect to"
              fullWidth
              size="medium"
              select
              defaultValue="EUR"
              label="Redirect to"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.onHandleInputChange("redirectTo", event.target.value)
              }
              value={props.selectedRedirector}
              required={true}
            >
              {props.selectedSbuManager?.sbuManagerId && (
                <MenuItem
                  key={props.selectedSbuManager?.sbuManagerId}
                  value={props.selectedSbuManager?.sbuManagerId}
                >
                  {props.selectedSbuManager?.sbuManagerName} - SBU
                </MenuItem>
              )}
              {_allRedirectedUser?.osaName && (
                <MenuItem
                  key={_allRedirectedUser.osaId}
                  value={_allRedirectedUser.osaId}
                >
                  {_allRedirectedUser.osaName} - Organizational Super Admin
                </MenuItem>
              )}

              {_allRedirectedUser?.tmName && (
                <MenuItem
                  key={_allRedirectedUser.tmId}
                  value={_allRedirectedUser.tmId}
                >
                  {_allRedirectedUser.tmName} -Transport Manager
                </MenuItem>
              )}

              {_allRedirectedUser?.tdu.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name} - Transport Division User
                </MenuItem>
              ))}
            </StyledTextField>
          </Grid>
          <Grid item md={12}>
            <Grid item md={12}>
              <StyledTextField
                fullWidth
                placeholder="Reason (Optional)"
                size="medium"
                onChange={(e) => setReason(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </BootstrapDialogContent>
      <BootstrapDialogActions>
        <CustomButton
          text="Cancel"
          border="1px solid #6e6e6e"
          bgColor="#282828"
          onClick={() => props.onCancelRedirectPopup()}
        />
        <CustomButton
          text="Send"
          onClick={() => props.onRedirectRequest(reason)}
        />
      </BootstrapDialogActions>
    </BootstrapDialog>
  );
};

export default TmRequestRedirectPopupView;
