import { AxiosResponse } from "axios"
import { axiosPrivateInstance } from "."
import { FileResponseDto, GetBankListDto, GetBranchListDto, GetCompanyDetailsById, GetPaymentMethodsDto, GetRequiredDocumentParmDto, RegisterTransportCompanyDto, TransportCompaniesDto, TransportCompaniesDtoViewTable, UpdateTransportCompany, fileUploadTransportDto, getCompanyDetailsDTO, updateCompanyDetailsParam } from "../utilities/models/transport.model"
import ITransportCompany from "../types/TransportCompanyDto"
import { DOCUMENT_TYPE, TRANSPORTCOMPNAYDOCUMENT } from "../utilities/constants/app.constants"
import { APP_CONFIGS } from "../utilities/constants"
import { getSummaryGridParam } from "../utilities/models"

const getAllTransportCompanies = (): Promise<AxiosResponse<TransportCompaniesDto[]>> => {
    return axiosPrivateInstance.get('/api/Transport/GetAllTransportComapanies')
}

// const ViewAllTransportCompanies = (): Promise<AxiosResponse<TransportCompaniesDtoViewTable[]>> => {
//     return axiosPrivateInstance.get('/api/Transport/GetCompanyDetails')
// }


const editCompanyById=(payload:updateCompanyDetailsParam):Promise<AxiosResponse<string>> => {
  
    return axiosPrivateInstance.put(`/api/Transport/UpdateTransportCompany?trasnportCompanyId=${payload.param}`, payload.requestBody)
  }
  

const addtarsportCompany = (payload:any): Promise<AxiosResponse<string>> => {
   
    return axiosPrivateInstance.post('/api/Transport/AddTransportCompany',payload)
}

const gettransportcompanyById = (companyId: number): Promise<AxiosResponse<ITransportCompany>> => {
    return axiosPrivateInstance.get(`/api/Transport/GetCompanyDetailsById?companyId=${companyId}`)
}

const gettransportcompanyPlant = (companyId: number): Promise<AxiosResponse<ITransportCompany>> => {
    return axiosPrivateInstance.get(`/api/Transport/GetAllPlants?companyId=${companyId}`)
}


const deltransportcompanyById = (companyIdDel: number): Promise<AxiosResponse<ITransportCompany>> => {
    return axiosPrivateInstance.put(`/api/Transport/RemoveTransportCompany?trasnportCompanyId=${companyIdDel}`)
}



const uploadFileTransport  = (payload: fileUploadTransportDto): Promise<AxiosResponse<string>> => {
    const payloadFormData = new FormData()
    payloadFormData.append('file', payload.attachment)
    return axiosPrivateInstance.post(`/api/DocumentUpload/UploadDocuments?mainDocumentType=${payload.mainDocumentType}&documentType=${payload.documentType}&folderName=${payload.folderName}`,payloadFormData)
}

const uploadFileTransportAccInfor  = (payload: fileUploadTransportDto): Promise<AxiosResponse<string>> => {
    const payloadFormData = new FormData()
    payloadFormData.append('file', payload.attachment)
    return axiosPrivateInstance.post(`/api/DocumentUpload/UploadDocuments?mainDocumentType=${payload.mainDocumentType}&documentType=${payload.documentType}&folderName=${payload.folderName}`,payloadFormData)
}

const uploadFileTransportOtherDoc  = (payload: fileUploadTransportDto): Promise<AxiosResponse<string>> => {
    const payloadFormData = new FormData()
    payloadFormData.append('file', payload.attachment)
    return axiosPrivateInstance.post(`/api/DocumentUpload/UploadDocuments?mainDocumentType=${payload.mainDocumentType}&documentType=${payload.documentType}&folderName=${payload.folderName}`,payloadFormData)
}

const GetRequiredDocument =(payload:GetRequiredDocumentParmDto):Promise<AxiosResponse<FileResponseDto>>=>{
    
    return axiosPrivateInstance.get(`api/DocumentUpload/GetRequiredDocuments?mainDocumentType=${payload.mainDocumentType}&fileName=${payload.fileName}`)
}
const RemoveUploadedDocument=(payload:GetRequiredDocumentParmDto):Promise<AxiosResponse<string>>=>{
    
    return axiosPrivateInstance.delete(`/api/DocumentUpload/RemoveDocument?mainDocumentType=${payload.mainDocumentType}&fileName=${payload.fileName}`)
}
/////////////////
const getAllCompanyDetails = (payload:getSummaryGridParam): Promise<AxiosResponse<getCompanyDetailsDTO[]>> => {
    return axiosPrivateInstance.get(`/api/Transport/GetCompanyDetails?pageNumber=${payload.pageNumber}&pagination=${payload.pagination}`)
}
const GetPaymentMethods = (): Promise<AxiosResponse<GetPaymentMethodsDto[]>> => {
    return axiosPrivateInstance.get('/api/Transport/GetPaymentMethods')
}
const getBankList = (): Promise<AxiosResponse<GetBankListDto[]>> => {
    return axiosPrivateInstance.get('/api/Transport/GetBankList')
}
const getBranchList = (payload:string): Promise<AxiosResponse<GetBranchListDto[]>> => {
    return axiosPrivateInstance.get(`/api/Transport/GetBranchList?name=${payload}`)
}
const RegisterTransportCompany = (payload:RegisterTransportCompanyDto): Promise<AxiosResponse<any>> => {
    return axiosPrivateInstance.post(`/api/Transport/AddTransportCompany`,payload)
}
const getTransportCompanyById = (payload:string): Promise<AxiosResponse<GetCompanyDetailsById>> => {
    return axiosPrivateInstance.get(`api/Transport/GetCompanyDetailsById?companyId=${payload}`)
}
const UpdateTransportCompanyByID = (payload:UpdateTransportCompany): Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.put(`/api/Transport/UpdateTransportCompany?trasnportCompanyId=${payload.companyId}`,payload.payload)
}


export const transportService = {
    getAllTransportCompanies,
    addtarsportCompany,
    gettransportcompanyById,
    deltransportcompanyById,
    // ViewAllTransportCompanies,
    editCompanyById,
    uploadFileTransport,
    uploadFileTransportAccInfor,
    uploadFileTransportOtherDoc,
    GetRequiredDocument,
    RemoveUploadedDocument,
    gettransportcompanyPlant,
    getAllCompanyDetails,
    GetPaymentMethods,
    getBankList,
    getBranchList,
    RegisterTransportCompany,
    getTransportCompanyById,
    UpdateTransportCompanyByID,

}