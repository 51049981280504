/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./LmRequestApprovalSummary.module.scss";
import { StyledStatusApproved, StyledStatusDraft, StyledStatusPending,} from "../../../assets/theme/theme";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CustomButton } from "../../Shared";
import { LmSummaryBudgetDto } from "../../../utilities/models/chart.model";
import { StateObjectDto } from "../../../utilities/models/common.model";
import { LMRequestSummaryListDto } from "../../../utilities/models/request.model";

const LmRequestApprovalSummary: React.FC<{
  lmSummaryCardResponse:| any | LmSummaryBudgetDto[]| StateObjectDto<LmSummaryBudgetDto[]>;
  filteredList: LMRequestSummaryListDto[],
  onSelectRequestIds(id: string, value: boolean): void;
}> = (props) => {
  const { lmSummaryCardResponse } = props;
  const [department, setDepartment] = useState("");
  const [allocatedAnnualBudget, setAllocatedAnnualBudget] = useState("");
  const [allocatedMonthlyBudget, setAllocatedMonthlyBudget] = useState("");
  const [remainingAnnualBudget, setRemainingAnnualBudget] = useState("");
  const [remainingMonthlyBudget, setRemainingMonthlyBudget] = useState("");
  const [cost, setCost] = useState("");

  const formatToThreeDigitsAndSpace = (inputString: string) => {
    let isNegative = false;
    if (inputString.startsWith("-")) {
      isNegative = true;
      inputString = inputString.slice(1);
    }
    const [integerPart, decimalPart] = inputString.split(".");

    const digitsOnly = integerPart.replace(/\D/g, "");
    const lastThreeDigits = digitsOnly.slice(-3).padStart(3, "0");
    const remainingDigits = digitsOnly.slice(0, -3);
    const formattedRemainingDigits = remainingDigits.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    let formattedDecimalPart = "";

    if (decimalPart) {
      formattedDecimalPart = `.${decimalPart.slice(0, 2)}`;
    }

    const formattedIntegerPart = formattedRemainingDigits + " " + lastThreeDigits;
    const formattedString = formattedDecimalPart
      ? formattedIntegerPart + formattedDecimalPart
      : formattedIntegerPart;

    return isNegative ? `-${formattedString}` : formattedString;
  };

  useEffect(() => {
    if ( lmSummaryCardResponse && lmSummaryCardResponse.data && lmSummaryCardResponse.data.length > 0) {
      const {
        department,
        allocatedAnnualBudget,
        allocatedMonthlyBudget,
        remainingAnnualBudget,
        remainingMonthlyBudget,
        cost,
      } = lmSummaryCardResponse.data[0];

      setDepartment(department);
      setAllocatedAnnualBudget(formatToThreeDigitsAndSpace(allocatedAnnualBudget));
      setAllocatedMonthlyBudget(formatToThreeDigitsAndSpace(allocatedMonthlyBudget));
      setRemainingAnnualBudget(formatToThreeDigitsAndSpace(remainingAnnualBudget));
      setRemainingMonthlyBudget(formatToThreeDigitsAndSpace(remainingMonthlyBudget));
      setCost(formatToThreeDigitsAndSpace(cost));
    }
  }, [lmSummaryCardResponse.status]);

  const [selectedOption, setSelectedOption] = useState('This Month');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState('This Month');
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [selectedTotal, setSelectedTotal] = useState(0)

  const toggleDropdown = () => {
    setSelectedOption((prevOption) =>  prevOption === "This Month" ? "This Year" : "This Month");
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleDropdown1 = () => {
    setSelectedOption1((prevOption) =>  prevOption === "This Month" ? "This Year" : "This Month");
    setIsDropdownOpen1(!isDropdownOpen1);
  };

  const totalChanges = () => {
    let _total: number = 0;
      props.filteredList.forEach((req) => {
        if (req.isSelect) {
          _total += Number(req.projectedCost);
        }
      });
      setSelectedTotal(_total)      
  }

  useEffect(() => {
    totalChanges();
  }, [props.onSelectRequestIds])

  return (
    <Grid container spacing={2}>
      <Grid item md={3}>
        <Card className={style.viewSummaryCard}>
          <CardContent>
            <Box className={style.viewSummaryCardDataRow}>
              <Box>
                <Typography className={style.data}>{department} Department</Typography>
                <StyledStatusDraft>Cost Center</StyledStatusDraft>
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={3}>
        <Card className={style.viewSummaryCard}>
          <CardContent>
            <Box className={style.viewSummaryCardDataRow}>
              <Box>
                <Typography className={style.data}>
                  {selectedOption === "This Month" ? allocatedMonthlyBudget : allocatedAnnualBudget} LKR
                </Typography>
                <StyledStatusPending>Allocated Budget</StyledStatusPending>
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <Box>
                <CustomButton
                  text={selectedOption}
                  fontSize="10px"
                  icon={
                    <KeyboardArrowDownOutlinedIcon sx={{ fontSize: "11px" }} />
                  }
                  bgColor="#1e1e1e"
                  onClick={toggleDropdown}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={3}>
        <Card className={style.viewSummaryCard}>
          <CardContent>
            <Box className={style.viewSummaryCardDataRow}>
              <Box>
                <Typography className={style.data}>
                  {selectedOption1 === "This Month" ? remainingMonthlyBudget : remainingAnnualBudget} LKR
                </Typography>
                <StyledStatusApproved>Remaining Budget</StyledStatusApproved>
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <Box>
                <CustomButton
                  text={selectedOption1}
                  fontSize="10px"
                  icon={
                    <KeyboardArrowDownOutlinedIcon sx={{ fontSize: "11px" }} />
                  }
                  bgColor="#1e1e1e"
                  onClick={toggleDropdown1}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={3}>
        <Card className={style.viewSummaryCard}>
          <CardContent>
            <Box className={style.viewSummaryCardDataRow}>
              <Box>
                <Typography className={style.data}>{selectedTotal === 0 ? cost: (selectedTotal).toLocaleString('en-US')} LKR</Typography>
                <StyledStatusDraft>Sum of Projected cost</StyledStatusDraft>
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <Box>
                <CustomButton
                  text={selectedTotal === 0 ? 'Total': 'Selected'}
                  fontSize="10px"
                  bgColor="#1e1e1e"
                  onClick={() => {}}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LmRequestApprovalSummary;
