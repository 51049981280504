import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { DriverManagement,ManageEntitlements, LmDashboard, Login, ManageDriverScreen, ManageRequest, ManageVehicleScreen, PendingVehicleAllocation, RequestApproval, RequestCreation, TmDashboard, TmRequestApproval, VehicalAllocationScreen, VehicleManagement, TMAllocationSummary } from '../pages'
import { APP_ROUTES } from "../utilities/constants";
import ManageTemplates from "../pages/ManageTemplates/ManageTemplates";
import ManageEntitlementScreen from '../pages/ManageEntitlementScreen/ManageEntitlementScreen';
import UserProfile from '../pages/UserProfile/UserProfile';
import DailyShiftTransportation from '../pages/DailyShiftTransportation/DailyShiftTransportation';
import ManageDailyShiftScreen from '../pages/ManageDailyShiftScreen/ManageDailyShiftScreen';
import RequestScreenExpress from '../pages/RequestScreenExpress/RequestScreenExpress';
import GuDashboard from '../pages/GuDashboard/GuDashboard';
import ManageTransportCompanies from '../pages/ManageTransportCompanies/ManageTransportCompanies';
import ManageTransportCompanyScreen from '../pages/ManageTransportCompanyScreen/ManageTransportCompanyScreen';
import DriverTripScreen from '../pages/DriverTripScreen/DriverTripScreen';
import ManageFuelTypesScreen from '../pages/ManageFuelScreen/AddFuelTypes';
import FuelReportManagement from '../pages/ManageFuelBillReport/ManageFuelBillReport';
import ManageFuelBillScreen from '../pages/ManageFuelBillingScreen/ManageFuleBillingScreen';


const AppRoutes = () => {

  return (
    <BrowserRouter>
      <Routes>

        <Route path={APP_ROUTES.ROOT} element={<Login />} />
        <Route path={APP_ROUTES.DRIVER} element={<DriverTripScreen />} />
        {/* <Route path="*" element={ <AppLayout> <NotFound /> </AppLayout>}/> */}

        <Route path={APP_ROUTES.GU_DASHBOARD} element={<GuDashboard />} />
        <Route path={APP_ROUTES.REQUEST_CREATION} element={<RequestCreation />} />
        <Route path={APP_ROUTES.MANAGE_REQUEST} element={<ManageRequest />} />
        <Route path={APP_ROUTES.MANAGE_REQUEST_EXPRESS} element={<RequestScreenExpress />} />
        {/* LINE MANAGER */}
        <Route path={APP_ROUTES.LM_DASHBOARD} element={<LmDashboard />} />
        <Route path={APP_ROUTES.LM_REQUEST_APPROVAL} element={<RequestApproval />} />

        {/* TRANSPORT MANAGER */}
        <Route path={APP_ROUTES.TM_DASHBOARD} element={<TmDashboard />} />
        <Route path={APP_ROUTES.TM_VEHICLE_ALLOCATION} element={<PendingVehicleAllocation />} />
        <Route path={APP_ROUTES.TM_ACCEPT_VEHICLE_ALLOCATION} element={<VehicalAllocationScreen />} />
        <Route path={APP_ROUTES.TM_REQUEST_APPROVAL} element={<TmRequestApproval />} />

        <Route path={APP_ROUTES.VEHICLE_MANAGEMENT} element={<VehicleManagement />} />
        <Route path={APP_ROUTES.CREATE_VEHICLE} element={<ManageVehicleScreen />} />
        <Route path={APP_ROUTES.EDIT_VEHICLE} element={<ManageVehicleScreen />} />
        <Route path={APP_ROUTES.VIEW_VEHICLE} element={<ManageVehicleScreen />} />
        <Route path={APP_ROUTES.TM_ALLOCATION_SUMMARY} element={<TMAllocationSummary />} />

        <Route path={APP_ROUTES.DRIVER_MANAGEMENT} element={<DriverManagement />} />
        <Route path={APP_ROUTES.CREATE_DRIVER} element={<ManageDriverScreen />} />
        <Route path={APP_ROUTES.VIEW_DRIVER} element={<ManageDriverScreen />} />
        <Route path={APP_ROUTES.EDIT_DRIVER} element={<ManageDriverScreen />} />

        <Route path={APP_ROUTES.PROFILE_VIEW} element={<UserProfile />} />
        <Route path={APP_ROUTES.GU_MANAGE_TEMPLATES} element={<ManageTemplates />}></Route>
        
        {/* SBU MANAGER */}
        <Route path={APP_ROUTES.ENTITLEMET_CREATION} element={<ManageEntitlements/>} /> 
        <Route path={APP_ROUTES.MANAGE_ENTITLEMENT} element={<ManageEntitlementScreen />} /> 
        <Route path={APP_ROUTES.EDIT_ENTITLEMENT} element={<ManageEntitlementScreen/>} /> 
        <Route path={APP_ROUTES.DS_TRANSPORTATION} element={<DailyShiftTransportation />} />
        <Route path={APP_ROUTES.DS_EXECUTION_HISTORY} element={<DailyShiftTransportation />} />
        <Route path={APP_ROUTES.CREATE_DS_TRANSPORTATION} element={<ManageDailyShiftScreen />} />
        <Route path={APP_ROUTES.VIEW_DS_TRANSPORTATION} element={<ManageDailyShiftScreen />} />
        <Route path={APP_ROUTES.EDIT_DS_TRANSPORTATION} element={<ManageDailyShiftScreen />} />
        <Route path={APP_ROUTES.EXECUTE_DS_TRANSPORTATION} element={<ManageDailyShiftScreen />} />
        <Route path={APP_ROUTES.DUPLICATE_DS_TRANSPORTATION} element={<ManageDailyShiftScreen />} />
  
        {/* new */}
        <Route path={APP_ROUTES.MANAGE_TRANSPORT_COMPANY}  element={<ManageTransportCompanies/>} />
        <Route path={APP_ROUTES.ADD_NEW_TRANSPORT_COMPANY}  element={<ManageTransportCompanyScreen/>} />
        
        {/* Fuel */}
        <Route path={APP_ROUTES.FUEL_REPORT}  element={<FuelReportManagement/>} />
        <Route path={APP_ROUTES.ADD_FUEL_PRICE}  element={<ManageFuelTypesScreen/>} />
        <Route path={APP_ROUTES.ADD_FUEL_PRICE_BILL}  element={<ManageFuelBillScreen/>} />
  


      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
