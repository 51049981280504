import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { FuelCostDetailsFormDto, FuelDetailsFormDto, StateObjectDto, VehicleNumberBriefDto,} from "../../../utilities/models";
import { AppSkeleton, CustomAutocomplete, CustomButton, CustomDatePicker, CustomStepper,} from "../../Shared";
import { StyledTextField } from "../../../assets/theme/theme";
import { FUEL_CATEGORY } from "../../../utilities/constants/app.constants";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import styles from './AddBillingDetails.module.scss'

const DownloadFuelCostBill: React.FC<{
  helperText: boolean;
  isLoading: boolean;
  billInformationForm: FuelCostDetailsFormDto;
  handleInputFocus(property: string, section: string): void;
  onInputHandleChangeFuelBillCostInformation(property: string, section: string): void;
  vehicleNumberRes:StateObjectDto<VehicleNumberBriefDto[]>;
  onSearchValueChange(property: string, value: any): void;
  handleRemove():void;
  selectedFile: File | any;
  excelDownload():void;
  FuelBillCostDetailResponse:StateObjectDto<any>;
}> = (props) => {

  const _vehicleNo = props.billInformationForm.vehicleNo;
  const _fromDate = props.billInformationForm.fromDate;
  const _toDate = props.billInformationForm.toDate;
  const _vehicleNumbers = props.vehicleNumberRes !== undefined && props.vehicleNumberRes?.data?.length > 0 ? props.vehicleNumberRes.data : []

  return (
    <div className={styles.background}>
      {!props.isLoading && (
        <Grid container spacing={4}>
            <Grid item xs={6}>
            <CustomAutocomplete
              freeSolo={true}
              label="Vehicle Number"
              placeholder="Select Vehicle Number"
              options={_vehicleNumbers.map((c:VehicleNumberBriefDto) => {
                return { label: c.vehicleNumber, value: c.id };
              })}
              value={_vehicleNo.value}
              error={!!_vehicleNo.error}
              disabled={_vehicleNo.disable}
              required={_vehicleNo.isRequired}
              readonly={_vehicleNo.readonly}
              helperText={props.helperText && _vehicleNo.error}
              onFocus={() => props.handleInputFocus("vehicleNo", "bill-information")}
              onChange={(event: any, value: any) => props.onInputHandleChangeFuelBillCostInformation("vehicleNo", value)}
              onInputChange={(event: any, value: any) => props.onSearchValueChange('vehicleNo', value)}
            />
          </Grid>
         
          <Grid item xs={6}>
              <CustomDatePicker
                label="From Date"
                placeholder="Select From Date"
                multiple={false}
                value={_fromDate.value}
                maxDate={new Date()}
                error={!!_fromDate.error}
                disabled={_fromDate.disable}// || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                readOnly={_fromDate.readonly}// || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                required={_fromDate.isRequired}
                helperText={props.helperText && _fromDate.error}
                onFocus={() => props.handleInputFocus("billedDate", "bill-information")}
                onChange={(value: any) => props.onInputHandleChangeFuelBillCostInformation("fromDate",value)}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                label="To Date"
                placeholder="Select To Date"
                multiple={false}
                value={_toDate.value}
                maxDate={new Date()}
                error={!!_toDate.error}
                disabled={_toDate.disable}// || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                readOnly={_toDate.readonly}// || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                required={_toDate.isRequired}
                helperText={props.helperText && _toDate.error}
                onFocus={() => props.handleInputFocus("billedDate", "bill-information")}
                onChange={(value: any) => props.onInputHandleChangeFuelBillCostInformation("endDate",value)}
              />
            </Grid>
            <Grid item xs={2} md={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CustomButton isLoading={props.FuelBillCostDetailResponse.isLoading} onClick={props.excelDownload} text="Download" bgColor="#bfbfbf" />
                {/* <Button style={{ color: "white" }} startIcon={<CloudDownloadIcon style={{ color: "white" }} />} onClick={props.handleDownload} /> */}
                {/* <Button style={{ color: "white"}} startIcon={<DeleteForeverRoundedIcon />} onClick={props.handleRemove} /> */}
              </div>
            </Grid>
        </Grid>
      )}
      {props.isLoading && (
        <AppSkeleton
          numOfRows={3}
          numOfColumns={2}
          columnWidth={50}
          height={30}
          tag="stepper"
        />
      )}
      </div>
  );
};

export default DownloadFuelCostBill;
