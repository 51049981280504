import {
  APP_ACTION_STATUS,
  COMMON_ACTION_TYPES,
  TEMPLATE_ACTION_TYPES,
} from "../../utilities/constants";
import { TemplateStateDto } from "../../utilities/models";

const INITIAL_STATE: TemplateStateDto = {
  getTemplate: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },

  deleteTemplate: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  getMatchingTemplateList: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
};

const TemplateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case TEMPLATE_ACTION_TYPES.GET_TEMPLATE_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getTemplate: {
          ...state.getTemplate,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };

    case TEMPLATE_ACTION_TYPES.GET_TEMPLATE_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getTemplate: {
          ...state.getTemplate,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };

    case TEMPLATE_ACTION_TYPES.GET_TEMPLATE_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getTemplate: {
          ...state.getTemplate,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };

    case TEMPLATE_ACTION_TYPES.GET_TEMPLATE_LIST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getTemplate: {
          ...state.getTemplate,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case TEMPLATE_ACTION_TYPES.DELETE_TEMPLATE + COMMON_ACTION_TYPES.REQUEST:
      return {
        deleteTemplate: {
          ...state.deleteTemplate,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case TEMPLATE_ACTION_TYPES.DELETE_TEMPLATE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        deleteTemplate: {
          ...state.deleteTemplate,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };

    case TEMPLATE_ACTION_TYPES.DELETE_TEMPLATE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        deleteTemplate: {
          ...state.deleteTemplate,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };

    case TEMPLATE_ACTION_TYPES.DELETE_TEMPLATE + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        deleteTemplate: {
          ...state.deleteTemplate,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case TEMPLATE_ACTION_TYPES.GET_MATCHING_TEMPLATE_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getMatchingTemplateList: {
          ...state.getMatchingTemplateList,
          isLoading: true,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: [],
        },
      };

    case TEMPLATE_ACTION_TYPES.GET_MATCHING_TEMPLATE_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getMatchingTemplateList: {
          ...state.getMatchingTemplateList,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };

    case TEMPLATE_ACTION_TYPES.GET_MATCHING_TEMPLATE_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getMatchingTemplateList: {
          ...state.getMatchingTemplateList,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };

    case TEMPLATE_ACTION_TYPES.GET_MATCHING_TEMPLATE_LIST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getMatchingTemplateList: {
          ...state.getMatchingTemplateList,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: [],
        },
      };

    default:
      return state;
  }
};

export default TemplateReducer;
