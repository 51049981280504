import React, { useState } from "react";
import Stepper from "../../Shared/Stepper/Stepper";
import { StyledSwitch, StyledTableCell, StyledTextField } from "../../../assets/theme/theme";
import { Box, Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import style from "./PassengerAndPackageDetails.module.scss";
import { CustomHeaderCell } from "../../Shared";
import { APP_TABLE_CONFIGS } from "../../../utilities/constants";
import { GeneralInformationDto, ItemDetailsDto, PassengerDetailsDto, TravelDetailsDto } from "../../../utilities/models/trip.model";
import moment from "moment";

const PassengerAndPackageDetails: React.FC<{
  GeneralInformation: GeneralInformationDto[],
  travelDetails: TravelDetailsDto[],
  packageDetails: ItemDetailsDto[],
  PassengerDetails: PassengerDetailsDto[],
  onSortHandle(col: string): void,
  page: number,
  rowsPerPage: number,
  onHandleChangePage(event: unknown, newPage: number): void,
  onHandleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void,
}> = (props) => {
  const passengerStartDate =moment(props.travelDetails[0]?.passengerStartDate).format('DD-MM-YYYY     h:mm A');
  const passengerEndDate = moment(props.travelDetails[0]?.passengerEndDate ).format('DD-MM-YYYY       h:mm A');
  const totalPassengerCount = props.travelDetails.reduce((acc, travelDetail) => {
    return acc + (Number(travelDetail?.passengerCount)|| 0);
  }, 0);
  
  const passengerMainDestination = props.travelDetails[0]?.passengerMainDestination;
  
  const isVip= props.travelDetails[0]?.isVip || false




  let totalCbm = 0;
  let totalWeight = 0;
  props.packageDetails.forEach((pkg: ItemDetailsDto) => {
    totalCbm += parseFloat(pkg.cbm);
    totalWeight += parseFloat(pkg.weight);
  });

  return (
    <Stepper stepNumber={2} stepTitle={"Passenger & Package Details"}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} className={style.formBox}>
          <Box className={style.label}>
            <label>Start Date</label>
          </Box>
          <StyledTextField
            placeholder="Start Date"
            size="small"
            value={passengerStartDate}
            inputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12} md={6} className={style.formBox}>
          <Box className={style.label}>
            <label>End Date</label>
          </Box>
          <StyledTextField
            placeholder="End Date"
            size="small"
            value={passengerEndDate}
            inputProps={{ readOnly: true }}
          />
        </Grid>

      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} className={style.formBox}>
          <Box className={style.label2}>
            <label>Passenger Count</label>
          </Box>
          <StyledTextField
            placeholder="Count"
            size="small"
            value={totalPassengerCount}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <div className={style.switchField}>
            <Typography className={style.vipLabel}>VIP Trip</Typography>
            <StyledSwitch checked={isVip}    disabled={true} />
          </div>
        </Grid>

      </Grid>
      {/* passenger Details  */}
      <section className={style.gridSection}>
        <TableContainer component={Paper} className={style.grid}>
          <Table className={style.table}>
            <TableHead>
              <TableRow>
                <CustomHeaderCell id='requestID' sortable onSort={props.onSortHandle}  >Request ID</CustomHeaderCell>
                <CustomHeaderCell id='passenger' onSort={props.onSortHandle} sortable  >Passenger Name</CustomHeaderCell>
                <CustomHeaderCell id='sbu' sortable onSort={props.onSortHandle}  >Billable SBU</CustomHeaderCell>
                <CustomHeaderCell id='plant' sortable onSort={props.onSortHandle}  >Plant</CustomHeaderCell>
                <CustomHeaderCell id='department' sortable onSort={props.onSortHandle}  >Department</CustomHeaderCell>
                <CustomHeaderCell id='pickup' sortable onSort={props.onSortHandle}  >Start from</CustomHeaderCell>
                <CustomHeaderCell id='Drop-off' sortable onSort={props.onSortHandle}  >Drop-off at</CustomHeaderCell>
                <CustomHeaderCell id='intermediateLocation' sortable onSort={props.onSortHandle}  >Intermediate at</CustomHeaderCell>
                <CustomHeaderCell id='Destination' sortable onSort={props.onSortHandle}  >Destination</CustomHeaderCell>
              </TableRow>
            </TableHead>
            {props.PassengerDetails && props.PassengerDetails.length > 0 &&
              <TableBody>
                {props.PassengerDetails.map((p) => (
                  <TableRow key={p.requestId}>
                    <StyledTableCell >R{p.requestId}</StyledTableCell>
                    <StyledTableCell >{p.passengerName}</StyledTableCell>
                    <StyledTableCell >{p.sbu}</StyledTableCell>
                    <StyledTableCell >{p.plant}</StyledTableCell>
                    <StyledTableCell >{p.department}</StyledTableCell>
                    <StyledTableCell >{p.startLocation}</StyledTableCell>
                    <StyledTableCell >{p.dropLocation}</StyledTableCell>
                    <StyledTableCell >{p.intermediateLocation}</StyledTableCell>
                    <StyledTableCell >{p.destination}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            }
            {props.PassengerDetails.length === 0 &&
              <TableBody>
                <TableRow>
                  <StyledTableCell align="center" colSpan={8}>No data to preview</StyledTableCell>
                </TableRow>
              </TableBody>
            }
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_OPTIONS}
          component="div"
          labelRowsPerPage={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                color: 'white',
              }}
            >
              Items per page
            </div>
          }
          count={props.PassengerDetails.length}
          page={props.page}
          onPageChange={props.onHandleChangePage}
          onRowsPerPageChange={props.onHandleChangeRowsPerPage}
          rowsPerPage={props.rowsPerPage}
          showFirstButton={true}
          showLastButton={true}
          sx={{ backgroundColor: "#282828", color: "white" }}
        />
      </section>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6} className={style.formBox}>
          <Box className={style.label}>
            <label>Total package weight(kg)</label>
          </Box>
          <StyledTextField
            placeholder="Total package weight(kg)"
            size="small"
            value={totalWeight}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} md={6} className={style.formBox}>
          <Box className={style.label}>
            <label>Total package (m3)</label>
          </Box>
          <StyledTextField
            placeholder="Total package (m3)"
            size="small"
            value={totalCbm}
            inputProps={{ readOnly: true }}
          />
        </Grid>
      </Grid>
 
      {/* package Details Table */}
      <section className={style.gridSection}>
        <TableContainer component={Paper} className={style.grid}>
          <Table className={style.table}>
            <TableHead>
              <TableRow>
                <CustomHeaderCell id='tripId' width={30}   >#</CustomHeaderCell>
                <CustomHeaderCell id='PackageType' onSort={props.onSortHandle} sortable  >Package Type</CustomHeaderCell>
                <CustomHeaderCell id='Recipient' onSort={props.onSortHandle} sortable  >Recipient</CustomHeaderCell>
                <CustomHeaderCell id='ItemID' onSort={props.onSortHandle} sortable  >Item ID</CustomHeaderCell>
                <CustomHeaderCell id='ItemDescription' onSort={props.onSortHandle} sortable  >Item Description</CustomHeaderCell>
                <CustomHeaderCell id='Weight' sortable onSort={props.onSortHandle}  >Weight(kg)</CustomHeaderCell>
                <CustomHeaderCell id='CBM' sortable onSort={props.onSortHandle}  >CBM(m³)</CustomHeaderCell>
                <CustomHeaderCell id='pickUpLocation' onSort={props.onSortHandle} sortable  >Pick up Location</CustomHeaderCell>
                <CustomHeaderCell id='Drop-off' sortable onSort={props.onSortHandle}  >Drop-off Location</CustomHeaderCell>
                <CustomHeaderCell id='GatePassNo' onSort={props.onSortHandle} sortable  >Gate pass No</CustomHeaderCell>
                <CustomHeaderCell id='GatePassNo' onSort={props.onSortHandle} sortable  >Instructions</CustomHeaderCell>
              </TableRow>
            </TableHead>
            {props.packageDetails && props.packageDetails.length > 0 &&
              <TableBody>
                {props.packageDetails.map((p, index) => (
                  <TableRow key={index}>
                    <StyledTableCell >{index + 1}</StyledTableCell>
                    <StyledTableCell >{p.packageType}</StyledTableCell>
                    <StyledTableCell >{p.recipient}</StyledTableCell>
                    <StyledTableCell >{p.itemId}</StyledTableCell>
                    <StyledTableCell >{p.itemDescription}</StyledTableCell>
                    <StyledTableCell >{p.weight}</StyledTableCell>
                    <StyledTableCell >{p.cbm}</StyledTableCell>
                    <StyledTableCell >{p.fromLocation}</StyledTableCell>
                    <StyledTableCell >{p.dropLocation}</StyledTableCell>
                    <StyledTableCell >{p.gatePassNo}</StyledTableCell>
                    <StyledTableCell >{p.instructions}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            }
            {props.packageDetails.length === 0 &&
              <TableBody>
                <TableRow>
                  <StyledTableCell align="center" colSpan={11}>No data to preview</StyledTableCell>
                </TableRow>
              </TableBody>
            }
          </Table>
        </TableContainer>
      </section>
    </Stepper>
  );
};

export default PassengerAndPackageDetails;
