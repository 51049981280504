import { UsrInfoDto } from './../../utilities/models/users.model'
import { authService } from './../../services/auth.service'
import { call, put, takeLeading } from 'redux-saga/effects'
import {
  COMMON_ACTION_TYPES,
  AUTH_ACTION_TYPE
} from '../../utilities/constants'
import { AuthorizedUserDto } from '../../utilities/models/users.model'
import { AxiosResponse } from 'axios'
import { permissionService } from '../../services/permission.service'

function* authorizeUser(action: { type: string, email: string }) {
  try {
    const userInfoResponse: AxiosResponse<UsrInfoDto> = yield call(permissionService.getUserInfo, action.email)
    const userInfo: UsrInfoDto = userInfoResponse.data
    if (userInfo.isActive) {
      const authorizedUserInfo: AuthorizedUserDto = {
        userId: userInfo.userId,
        emailId: userInfo.emailId,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        isActive: userInfo.isActive,
        roleId: userInfo.roleId,
        preferredTheme:userInfo.preferredTheme,
        roleName: userInfo.roleName,
        contactNumber: userInfo.contactNumber,
        nic: userInfo.nic,
        permissions: userInfo.permissions,
        hierarchy: userInfo.hierarchy,
        homeContactNumber: userInfo.homeContactNumber,
        plant: userInfo.plant,
        sbu: userInfo.sbu,
        department: userInfo.department,
        address:userInfo.address
      }

      yield call(authService.authorizeUser, authorizedUserInfo)

      yield put({
        type:
          AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER + COMMON_ACTION_TYPES.REQUEST
      })

      yield put({
        type: AUTH_ACTION_TYPE.AUTHORIZE_USER + COMMON_ACTION_TYPES.SUCCESS
      })
    } else {
      yield put({
        type: AUTH_ACTION_TYPE.AUTHORIZE_USER + COMMON_ACTION_TYPES.ERROR,
        error: 'Unauthorized User'
      })
    }
  } catch (error) {
    yield put({
      type: AUTH_ACTION_TYPE.AUTHORIZE_USER + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* fetchAuthorizedUser() {
  try {
    const user: AuthorizedUserDto = yield call(authService.getAuthorizedUser)

    yield put({
      type:
        AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER + COMMON_ACTION_TYPES.SUCCESS,
      data: user
    })
  } catch (error) {
    yield put({
      type: AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER + COMMON_ACTION_TYPES.ERROR,
      error: 'Failed to fetch user data'
    })
  }
}

function* authSaga() {
  yield takeLeading(
    AUTH_ACTION_TYPE.AUTHORIZE_USER + COMMON_ACTION_TYPES.REQUEST,
    authorizeUser
  )
  yield takeLeading(
    AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER + COMMON_ACTION_TYPES.REQUEST,
    fetchAuthorizedUser
  )
}
export default authSaga
