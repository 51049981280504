import { COMMON_ACTION_TYPES, REQUEST_ACTION_TYPE } from "../../utilities/constants"
import { DateRangeDto, DateRangeParmDto, LMRequestApprovalParamDto, RequestSummaryParamDto, TMRequestApprovalParamDto, TmRequestSummaryParamDto, filterationParam} from "../../utilities/models"

const createNewRequest = (payload: any) => {
    return {
        type: REQUEST_ACTION_TYPE.POST_REQUEST + COMMON_ACTION_TYPES.REQUEST,
        payload: payload
    }
}

const editRequest = (payload: any) => {
    return {
        type: REQUEST_ACTION_TYPE.EDIT_REQUEST + COMMON_ACTION_TYPES.REQUEST,
        payload: payload
    }
}

const CreateNewRequestClear = () => {
    return {
        type: REQUEST_ACTION_TYPE.POST_REQUEST + COMMON_ACTION_TYPES.CLEAR
    }
}

const createNewReccurentRequest = (payload: any) => {
    return {
        type: REQUEST_ACTION_TYPE.POST_RECCURRENT_REQUEST + COMMON_ACTION_TYPES.REQUEST,
        payload: payload
    }
}
const createNewReccurentRequestClear = () => {
    return {
        type: REQUEST_ACTION_TYPE.POST_RECCURRENT_REQUEST + COMMON_ACTION_TYPES.CLEAR
    }
}

const deleteRequest = (id: number) => {
    return {
        type: REQUEST_ACTION_TYPE.DELETE_REQUEST + COMMON_ACTION_TYPES.REQUEST,
        id: id
    }
}

const deleteRequestClear = () => {
    return {type: REQUEST_ACTION_TYPE.DELETE_REQUEST + COMMON_ACTION_TYPES.CLEAR}
}

const editRequestClear = () => {
    return {
        type: REQUEST_ACTION_TYPE.EDIT_REQUEST + COMMON_ACTION_TYPES.CLEAR
    }
}

const getRequestById = (requestId: string) => {
    return {
        type: REQUEST_ACTION_TYPE.GET_REQUEST + COMMON_ACTION_TYPES.REQUEST,
        requestId: requestId
    }
}

const getRequestByIdClear = () => {
    return {
        type: REQUEST_ACTION_TYPE.GET_REQUEST + COMMON_ACTION_TYPES.CLEAR
    }
}

const getSelfRequestSummaryList = (param: RequestSummaryParamDto) => {
    return { 
        type: REQUEST_ACTION_TYPE.GET_SELF_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.REQUEST,
        param: param
    }
}

const getLMRequestSummaryList = (param: RequestSummaryParamDto) => {
    return { 
        type: REQUEST_ACTION_TYPE.GET_LM_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.REQUEST,
        param: param
    }
}

const getLMRequestSummaryFilters = (param:filterationParam) => {
    return{
        type:REQUEST_ACTION_TYPE.GET_LM_REQUEST_SUMMARY_FILTERS + COMMON_ACTION_TYPES.REQUEST,
        param:param
    }
}

const getTMRequestSummaryList = (param: TmRequestSummaryParamDto) => {
    return { 
        type: REQUEST_ACTION_TYPE.GET_TM_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.REQUEST,
        param: param
    }
}

const getTMRequestSummaryFilters = (param:filterationParam) => {
    return{
        type:REQUEST_ACTION_TYPE.GET_TM_REQUEST_SUMMARY_FILTERS + COMMON_ACTION_TYPES.REQUEST,
        param:param
    }
}

const getSbuList = () => {
    return { type: REQUEST_ACTION_TYPE.GET_SBU_LIST + COMMON_ACTION_TYPES.REQUEST}
}

const getPlantListBySbuId = (id: number) => {
    return { type: REQUEST_ACTION_TYPE.GET_PLANT_LIST_BY_SBU_ID + COMMON_ACTION_TYPES.REQUEST, id: id}
}

const getDepartmentListByPlantId = (id: number) => {
    return { type: REQUEST_ACTION_TYPE.GET_DEPARTMENT_LIST_BY_PLANT_ID + COMMON_ACTION_TYPES.REQUEST, id: id}
}

const getRequestStatusProcess = (id: string) => {
    return { type: REQUEST_ACTION_TYPE.GET_REQUEST_STATUS_PROCESS + COMMON_ACTION_TYPES.REQUEST, id: id}
}

const getRequestStatusProcessClear = () => {
    return { type: REQUEST_ACTION_TYPE.GET_REQUEST_STATUS_PROCESS + COMMON_ACTION_TYPES.CLEAR }
}

const lineManagerApproval = (payload: LMRequestApprovalParamDto) => {
    return { type: REQUEST_ACTION_TYPE.LM_APPROVAL + COMMON_ACTION_TYPES.REQUEST, payload: payload }
}

const lineManagerApprovalClear = () => {
    return { type: REQUEST_ACTION_TYPE.LM_APPROVAL + COMMON_ACTION_TYPES.CLEAR }
}

const transportManagerApproval = (payload: TMRequestApprovalParamDto) => {
    return { type: REQUEST_ACTION_TYPE.TM_APPROVAL + COMMON_ACTION_TYPES.REQUEST, payload: payload }
}

const transportManagerApprovalClear = () => {
    return { type: REQUEST_ACTION_TYPE.TM_APPROVAL + COMMON_ACTION_TYPES.CLEAR }
}
const GetRequestHistory=(payload:DateRangeParmDto)=>{
    return { 
        type: REQUEST_ACTION_TYPE.GET_REQUEST_HISTORY+ COMMON_ACTION_TYPES.REQUEST,
        payload:payload
     }
}



export const requestActions = {
    createNewRequest,
    CreateNewRequestClear,
    createNewReccurentRequest,
    createNewReccurentRequestClear,
    editRequest,
    editRequestClear,
    deleteRequest,
    deleteRequestClear,
    getRequestById,
    getRequestByIdClear,
    getSelfRequestSummaryList,
    getLMRequestSummaryList,
    getLMRequestSummaryFilters,
    getTMRequestSummaryList,
    getTMRequestSummaryFilters,
    getSbuList,
    getPlantListBySbuId,
    getDepartmentListByPlantId,
    getRequestStatusProcess,
    getRequestStatusProcessClear,
    lineManagerApproval,
    lineManagerApprovalClear,
    transportManagerApproval,
    transportManagerApprovalClear,
    GetRequestHistory,
}