import { COMMON_ACTION_TYPES, VEHICLE_ACTION_TYPE } from "../../utilities/constants"
import { AddVehicleIncidentParamDto, EditVehicleParamDto, NewVehiclePayloadDto, PendingAllocationParamDto, cancelAllocationParm, getDetailsSummaryParam } from "../../utilities/models"

const createVehicle = (payload: NewVehiclePayloadDto) => {
    return {
        type: VEHICLE_ACTION_TYPE.POST_VEHICLE + COMMON_ACTION_TYPES.REQUEST,
        payload: payload
    }
}
const addVehicleIncident = (payload: AddVehicleIncidentParamDto) => {
    return {
        type: VEHICLE_ACTION_TYPE.POST_VEHICLE_INCIDENT + COMMON_ACTION_TYPES.REQUEST,
        payload: payload
    }
}

const editVehicle = (payload: EditVehicleParamDto) => {
    return {
        type: VEHICLE_ACTION_TYPE.EDIT_VEHICLE + COMMON_ACTION_TYPES.REQUEST,
        payload: payload
    }
}

const deleteVehicle = (id: number) => {
    return {
        type: VEHICLE_ACTION_TYPE.DELETE_VEHICLE + COMMON_ACTION_TYPES.REQUEST,
        id: id
    }
}

const deleteVehicleClear = () => {
    return{
        type:VEHICLE_ACTION_TYPE.DELETE_VEHICLE + COMMON_ACTION_TYPES.CLEAR,
    }
}

const createVehicleResponseClear = () => {
    return { type: VEHICLE_ACTION_TYPE.POST_VEHICLE + COMMON_ACTION_TYPES.CLEAR }
}

const createVehicleIncidentResponseClear = () => {
    return { type: VEHICLE_ACTION_TYPE.POST_VEHICLE_INCIDENT + COMMON_ACTION_TYPES.CLEAR }
}

const editVehicleResponseClear = () => {
    return { type: VEHICLE_ACTION_TYPE.EDIT_VEHICLE + COMMON_ACTION_TYPES.CLEAR }
}

const getVehicleTypes = () => {
    return { type: VEHICLE_ACTION_TYPE.GET_VEHICLE_TYPES + COMMON_ACTION_TYPES.REQUEST }
}

const getVehicles = (payload:getDetailsSummaryParam) => {
    return { type: VEHICLE_ACTION_TYPE.GET_VEHICLE_LIST + COMMON_ACTION_TYPES.REQUEST, payload:payload }
}

const getVehicleIncidents = (id: number) => {
    return { type: VEHICLE_ACTION_TYPE.GET_VEHICLE_INCIDENTS + COMMON_ACTION_TYPES.REQUEST, id: id }
}

const getVehicleIncidentsClear = () => {
    return { type: VEHICLE_ACTION_TYPE.GET_VEHICLE_INCIDENTS + COMMON_ACTION_TYPES.CLEAR }
}

const getPendingAllocationsFilters = () => {
    return { type: VEHICLE_ACTION_TYPE.GET_PENDING_VEHICLE_ALLOC_FILTERS + COMMON_ACTION_TYPES.REQUEST }
}
const getPendingAllocations = (param:PendingAllocationParamDto) => {
    return { 
        type: VEHICLE_ACTION_TYPE.GET_PENDING_VEHICLE_ALLOCATIONS + COMMON_ACTION_TYPES.REQUEST,
        param:param
    }
}
const getAllocationHistory = () => {
    return {
        type: VEHICLE_ACTION_TYPE.GET_VEHICLE_ALLOCATION_HISTORY + COMMON_ACTION_TYPES.REQUEST
    }
}
const getAllocationRequisitions = (payload: string) => {
    return {
        type: VEHICLE_ACTION_TYPE.GET_VEHICLE_ALLOCATION_REQUISITIONS + COMMON_ACTION_TYPES.REQUEST,
        payload: payload,
    }
}
const getAllocationRequisitionsClear = () => {
    return { type: VEHICLE_ACTION_TYPE.GET_VEHICLE_ALLOCATION_REQUISITIONS + COMMON_ACTION_TYPES.CLEAR }
}

const cancelAllocation = (payload: cancelAllocationParm,) => {
    return {
        type: VEHICLE_ACTION_TYPE.PUT_CANCEL_ALLOCATION + COMMON_ACTION_TYPES.REQUEST,
        payload: payload,
    }
}

export const vehicleActions = {
    createVehicle,
    editVehicle,
    addVehicleIncident,
    createVehicleIncidentResponseClear,
    deleteVehicle,
    getVehicleTypes,
    getVehicles,
    getVehicleIncidents,
    getPendingAllocations,
    getPendingAllocationsFilters,
    createVehicleResponseClear,
    editVehicleResponseClear,
    getVehicleIncidentsClear,
    getAllocationHistory,
    getAllocationRequisitions,
    getAllocationRequisitionsClear,
    cancelAllocation,
    deleteVehicleClear
}
