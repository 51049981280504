import axios, { AxiosResponse } from "axios";
import { axiosPrivateInstance } from ".";
import {
  SplitTypeBriefDto,
  SplitPassengerPackageDto,
  SplitPostDTO,
  RedirectRequestDto,
  TransportManagerDto,
} from "../utilities/models/split.model";

const getSplitTypes = (): Promise<AxiosResponse<SplitTypeBriefDto[]>> => {
  return axiosPrivateInstance.get("/api/Request/GetSplitTypes");
};

const getSplitPassengerPackages = (
  id: number
): Promise<AxiosResponse<SplitPassengerPackageDto[]>> => {
  return axiosPrivateInstance.get(
    `/api/User/GetPassengersByRequest?requestId=${id}`
  );
};

const createSplitRequest = (
  payload: any
): Promise<AxiosResponse<SplitPostDTO>> => {
  return axiosPrivateInstance.post(`/api/Request/SplitRequest`, payload);
};

const getTransportManagerByPlant = (
  id: number
): Promise<AxiosResponse<TransportManagerDto>> => {
  return axiosPrivateInstance.get(
    `/api/User/GetTransportManagerByPlant?plantId=${id}`
  );
};
const createRedirectRequest = (
  payload: any
): Promise<AxiosResponse<RedirectRequestDto>> => {
  return axiosPrivateInstance.post(`/api/Request/RequestRedirection`, payload);
};

export const splitService = {
  getSplitTypes,
  getSplitPassengerPackages,
  createSplitRequest,
  getTransportManagerByPlant,
  createRedirectRequest,
};
