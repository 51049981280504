export const REQUEST_STATUS = {
    // GREEN : SUCCESS
    APPROVED: "Approved",
    APPROVED_TM: "Approved by TM",
    APPROVED_ME: 'Approved by Me',
    ALLOCATED_ME: 'Allocated by me',
    ALLOCATED_TM: "Allocated by TM",
    APPROVED_LM: "Approved by LM",
    UPDATE_DETAILS_ALLOCATED_ME: "UPDATE DETAILS: Allocated by me",

    // ORANGE : WARNING
    PENDING: "Pending",
    REDIRECTED_ME: 'Redirected to Me',
    REDIRECTED_TM: "Redirected to TM",
    UPDATE_DETAILS_PENDING: "UPDATE DETAILS: Pending",
    ALLOCATION_EDITED_TM: "Allocation edited by TM",
    ALLOCATION_EDITED_ME: 'Allocation edited by me',
    
    // RED : DANGER
    ALLOCATION_CANCELLED_TM: "Allocation cancelled by TM",
    ALLOCATION_CANCELLED_ME: "Allocation cancelled by me",
    REDIRECTION_REJECTED_TM: "Redirection rejected by TM",
    REJECTED_LM: "Rejected by LM",
    REJECTED_TM: "Rejected by TM",
    REJECTED_ME: 'Rejected by Me',
    REMOVE: 'REMOVE',
    REMOVED: 'REMOVED',

    // WHITE
    DRAFT: "Draft",


}

