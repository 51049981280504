import {
  APP_ACTION_STATUS,
  COMMON_ACTION_TYPES,
  REDIRECT_ACTION_TYPE,
  SPLIT_ACTION_TYPE,
} from "../../utilities/constants/index";
import { SplitStateDto } from "../../utilities/models/state.model";

const INITIAL_STATE: SplitStateDto = {
  getSplitTypes: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getPassengersPackages: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  addSplitRequest: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  getTransportManager: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  addRedirectRequest: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },

};

const splitReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SPLIT_ACTION_TYPE.GET_SPLIT_TYPES + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getSplitTypes: {
          ...state.getSplitTypes,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };

    case SPLIT_ACTION_TYPE.GET_SPLIT_TYPES + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getSplitTypes: {
          ...state.getSplitTypes,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };

    case SPLIT_ACTION_TYPE.GET_SPLIT_TYPES + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getSplitTypes: {
          ...state.getSplitTypes,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };

    case SPLIT_ACTION_TYPE.GET_SPLIT_TYPES + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getSplitTypes: {
          ...state.getSplitTypes,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case SPLIT_ACTION_TYPE.GET_PASSENGERS_PACKAGES +
      COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getPassengersPackages: {
          ...state.getPassengersPackages,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };

    case SPLIT_ACTION_TYPE.GET_PASSENGERS_PACKAGES +
      COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getPassengersPackages: {
          ...state.getPassengersPackages,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };

    case SPLIT_ACTION_TYPE.GET_PASSENGERS_PACKAGES + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getPassengersPackages: {
          ...state.getPassengersPackages,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };

    case SPLIT_ACTION_TYPE.GET_PASSENGERS_PACKAGES + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getPassengersPackages: {
          ...state.getPassengersPackages,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case SPLIT_ACTION_TYPE.POST_SPLIT_REQUEST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        addSplitRequest: {
          ...state.addSplitRequest,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };

    case SPLIT_ACTION_TYPE.POST_SPLIT_REQUEST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        addSplitRequest: {
          ...state.addSplitRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };

    case SPLIT_ACTION_TYPE.POST_SPLIT_REQUEST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        addSplitRequest: {
          ...state.addSplitRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };

    case SPLIT_ACTION_TYPE.POST_SPLIT_REQUEST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        addSplitRequest: {
          ...state.addSplitRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case REDIRECT_ACTION_TYPE.GET_TRANSPORT_MANAGER +
      COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getTransportManager: {
          ...state.getTransportManager,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };

    case REDIRECT_ACTION_TYPE.GET_TRANSPORT_MANAGER +
      COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getTransportManager: {
          ...state.getTransportManager,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };

    case REDIRECT_ACTION_TYPE.GET_TRANSPORT_MANAGER + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getTransportManager: {
          ...state.getTransportManager,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };

    case REDIRECT_ACTION_TYPE.GET_TRANSPORT_MANAGER + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getTransportManager: {
          ...state.getTransportManager,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case REDIRECT_ACTION_TYPE.POST_REDIRECT_REQUEST +
      COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        addRedirectRequest: {
          ...state.addRedirectRequest,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };

    case REDIRECT_ACTION_TYPE.POST_REDIRECT_REQUEST +
      COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        addRedirectRequest: {
          ...state.addRedirectRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };

    case REDIRECT_ACTION_TYPE.POST_REDIRECT_REQUEST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        addRedirectRequest: {
          ...state.addRedirectRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };

    case REDIRECT_ACTION_TYPE.POST_REDIRECT_REQUEST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        addRedirectRequest: {
          ...state.addRedirectRequest,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    default:
      return state;
  }
};

export default splitReducer;
