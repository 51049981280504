import React, { useEffect, useState } from 'react'
import { AppLayout } from '../../templates'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationStateDto, DateRangeDto, DateRangeParmDto, LMRequestSummaryListDto, LmRequestSummaryBodyDto, LmRequestSummaryParamDto, RequestSummaryParamDto, SortMetaDto, allocationHistoryDto, allocationRequisitionsDto, filterationParam, requestHistory, selectedType, vehicleDetailsListDto } from '../../utilities/models'
import { chartActions, mapActions, notificationAction, requestActions, tripActions, vehicleActions } from '../../redux/actions'
import { APP_ACTION_STATUS, APP_ROUTES, APP_TABLE_CONFIGS } from '../../utilities/constants'
import LmDashboardGrid from '../../components/LmDashboard/LmDashboardGrid/LmDashboardGrid'
import LmDashboardSummaryChart from '../../components/LmDashboard/LmDashboardSummaryChart/LmDashboardSummaryChart'
import { useNavigate } from 'react-router'
import moment from 'moment'
import { AllocatedRequisitionsPopUp, CustomButton, CustomDatePicker, MapSummaryPopup, StatusPopup } from '../../components'
import dayjs from 'dayjs'
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab, Typography } from "@mui/material";
import CompletedTripSummary from "../../components/TmDashboard/CompltedTripSummary/CompletedTripSummary";
import { validateFormData } from '../../utilities/helpers'
import { StyledSwitch } from '../../assets/theme/theme'
import style from "../TMAllocationSummary/TMAllocationSummary.module.scss"

const LmDashboard = () => {
  const INITIAL_SORT_META: SortMetaDto = {
    field: "",
    asc: false,
  }

  const filterParam: filterationParam = {
    pending: false
  }

  const INITIAL_FILTER_OPTIONS: LmRequestSummaryBodyDto = {
    parentRequestId: null,
    recurrentParentId: null,
    requestType: null,
    userName: null,
    sbuName: null,
    plantName: null,
    departmentName: null,
    travelMode: null,
    fromLocation: null,
    toLocation: null,
    passengerCount: null,
    preferredVehicle: null,
    package: null,
    createdBy: null,
    status: null,
    createdFromDateandTime: null,
    createdToDateandTime: null,
    departureFromDateandTime: null,
    departureToDateandTime: null,
    returnFromDateandTime: null,
    returnToDateandTime: null
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
  const [sortMeta, setSortMeta] = useState<SortMetaDto>(INITIAL_SORT_META);
  const [filteredList, setFilteredList] = useState<LMRequestSummaryListDto[]>([])
  const [isFiltered, setIsFiltered] = useState(false)
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false)
  const [selectedRequestId, setSelectedRequestId] = useState("")
  const [filters, setFilters] = useState<any[]>([]);
  const [filterBody, setFilterBody] = useState<LmRequestSummaryBodyDto>(INITIAL_FILTER_OPTIONS);
  const [isOpenMapPopup, setIsOpenMapPopup] = useState(false);
  const [selectedStartePointLatANdLang, setselectedStartePointLatANdLang] = useState<selectedType>()
  const [selectedEndPointLatANdLang, setselectedEndPointtLatANdLang] = useState<selectedType>()

  const getRequestDataResponse = useSelector((state: ApplicationStateDto) => state.request.getLMRequestList)
  const getRequestStatusProcessResponse = useSelector((state: ApplicationStateDto) => state.request.getRequestStatusProcess)
  const getLmSummaryResponse = useSelector((state: ApplicationStateDto) => state.chart.getLMSummary);
  const getLmSummaryFiltersRes = useSelector((state: ApplicationStateDto) => state.request.getLMRequestSummaryFilters);


  useEffect(() => {
    dispatch(chartActions.getLMSummary());
    dispatch(requestActions.getLMRequestSummaryFilters(filterParam));
    dispatch(notificationAction.getNotification())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   getRequestList(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE, 1)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    if (getLmSummaryFiltersRes.status === APP_ACTION_STATUS.SUCCESS) {
      setFilters(getLmSummaryFiltersRes.data)
    }
  }, [getLmSummaryFiltersRes.status])

  useEffect(() => {
    if (getRequestDataResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (getRequestDataResponse.data.length > 0) setFilteredList(getRequestDataResponse.data)
      else setFilteredList([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequestDataResponse.status])

  useEffect(() => {
    if (getRequestStatusProcessResponse.status === APP_ACTION_STATUS.SUCCESS && getRequestStatusProcessResponse.data) {
      if (getRequestStatusProcessResponse.data.length > 0)
        setIsStatusDialogOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequestStatusProcessResponse.status])

  const getRequestList = async (r: number, p: number) => {
    const _param: LmRequestSummaryParamDto = {
      pagination: r,
      pageNumber: p,
      filter: filterBody
    }
    dispatch(requestActions.getLMRequestSummaryList(_param))
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    getRequestList(rowsPerPage, newPage + 1)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    getRequestList(+event.target.value, 1)
    setPage(0)
  }

  const navigteTORequestScreen = (mode: string, id: string) => {
    sessionStorage.setItem('Mode', mode)
    sessionStorage.setItem('id', id)
    navigate(APP_ROUTES.MANAGE_REQUEST)
  }
  const onClearFilter = () => {
    setIsFiltered(false)
    // setFilteredList(getRequestDataResponse.data)
    setFilterBody(INITIAL_FILTER_OPTIONS)
    // getRequestList(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE, 1)
    setPage(0)
  }

  const onShowStatus = (id: string) => {
    setSelectedRequestId(id)
    dispatch(requestActions.getRequestStatusProcess(id))
  }

  const showStatusPopup = (con: boolean) => {
    setIsStatusDialogOpen(con)
    if (!con) {
      setSelectedRequestId("")
      dispatch(requestActions.getRequestStatusProcessClear())
    }
  }

  const onSortHandle = (col: string) => {
    const sorted = filteredList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setFilteredList(sorted);
  };

  // const onFilterHandle = (col: string, value: any) => {
  //   console.log("CHECKING CALLED onFilterHandle", col, value)
  //     setIsFiltered(true)

  //     if (filterBody.hasOwnProperty(col)) {
  //       setFilterBody(prevFilterBody => ({
  //         ...prevFilterBody,
  //         [col]: value,
  //       }));
  //     }
  // };

  const onFilterHandle = (col: string, value: any) => {
    setIsFiltered(true)

    if (filterBody.hasOwnProperty(col)) {
      setFilterBody(prevFilterBody => ({
        ...prevFilterBody,
        [col]: value,
      }));
    } else {
      if (col === "createdDateandTime") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DDTHH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DDTHH:mm");

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          createdFromDateandTime: _selectedMin,
          createdToDateandTime: _selectedMax
        }));
      } else if (col === "departureDateandTime") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DDTHH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DDTHH:mm");

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          departureFromDateandTime: _selectedMin,
          departureToDateandTime: _selectedMax
        }));
      } else if (col === "returnDateandTime") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DDTHH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DDTHH:mm");

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          returnFromDateandTime: _selectedMin,
          returnToDateandTime: _selectedMax
        }));
      } else if (col === "packageX") {
        const _valueCon = value === "Yes" ? true : false

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          package: _valueCon
        }));
      }
    }
  }

  useEffect(() => {
    getRequestList(rowsPerPage, 1)
    setPage(0)
  }, [isFiltered, filterBody])

  useEffect(() => {
    setFilteredList(getRequestDataResponse.data);
  }, [filterBody])

  const getFilterList = (col: string): string[] => {
    if (!getRequestDataResponse?.isLoading)
      return getRequestDataResponse?.data
        ?.map((item) => {
          const value = (item as any)[col];
          if (typeof value === "boolean") {
            return value ? "Yes" : "No";
          }
          return value ? value : 'N/A';
        })
        ?.filter((value, index, array) => array.indexOf(value) === index);
    else return []
  };

  const DATE_RANGE_INITIAL_STATE: DateRangeDto = {
    fromDate: { value: null, isRequired: false, disable: false, readonly: false, validator: "date", error: "", },
    toDate: { value: null, isRequired: false, disable: false, readonly: false, validator: "date", error: "", },
    isAll: { value: false, isRequired: true, disable: false, readonly: false, validator: null, error: "", },
  };

  const allocatedRequisitionsResponse = useSelector((state: ApplicationStateDto) => state.vehicle.getAllocationsRequisitions);
  const allocatedHistoryResponse2 = useSelector((state: ApplicationStateDto) => state.vehicle.getAllocationsHistory);
  const allocatedHistoryResponse = useSelector((state: ApplicationStateDto) => state.request.getRequestHistory);


  const [value, setValue] = React.useState(1);
  const [rowsPerPage2, setRowsPerPage2] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE);
  const [rowsPerPage3, setRowsPerPage3] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE);
  const [DateRangeForm, setDateRangeForm] = useState<DateRangeDto>(DATE_RANGE_INITIAL_STATE)
  const [sortMeta2, setSortMeta2] = useState<SortMetaDto>(INITIAL_SORT_META);
  const [page2, setPage2] = useState(0);
  const [page3, setPage3] = useState(0);
  const [isFiltered2, setIsFiltered2] = useState(false);
  const [isFiltered3, setIsFiltered3] = useState(false);
  const [filteredList2, setFilteredList2] = useState<requestHistory[]>([]);
  const [isOpenAddPackagePopup, setIsOpenAddPackagePopup] = useState(false);
  const [allocatedRequisitions, setallocatedRequisitions] = useState<allocationRequisitionsDto[]>([]);
  const [NavigateView, setNavigateView] = useState(false);
  const [allocationID, setAllocationID] = useState("");
  const [helperText, setHelperText] = useState(true);

  useEffect(() => {
    // dispatch(vehicleActions.getAllocationHistory());
    dispatch(tripActions.updateTripAllocationDetailsClear());
    getRequestHistory()

  }, []);
  const getRequestHistory = () => {
    const payload: DateRangeParmDto = {
      fromDate: "",
      toDate: "",
      isAll: false,
    }
    dispatch(requestActions.GetRequestHistory(payload))
  }
  useEffect(() => {
    if (allocatedRequisitionsResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setallocatedRequisitions(allocatedRequisitionsResponse.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocatedRequisitionsResponse.status]);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onInputHandleChange = (property: string, value: any) => {
    setHelperText(true);
    if (property === "fromDate") {
      setDateRangeForm(
        {
          ...DateRangeForm,
          fromDate: {
            value: value,
            validator: null,
            isRequired: false,
            error: null,
            disable: false,
            readonly: false
          }
        })

    }
    if (property === "toDate") {
      setDateRangeForm(
        {
          ...DateRangeForm,
          toDate: {
            value: value,
            validator: null,
            isRequired: false,
            error: null,
            disable: false,
            readonly: false
          }
        })

    }

    if (property === "isAll") {
      setDateRangeForm(
        {
          ...DateRangeForm,
          isAll: {
            value: value,
            validator: null,
            isRequired: true,
            error: null,
            disable: false,
            readonly: false
          }
        })

    }

  }


  const handleSwitchChange = (newValue: any) => {

    setDateRangeForm((prevDateRangeForm) => ({
      ...prevDateRangeForm,
      isAll: {
        ...prevDateRangeForm.isAll,
        value: newValue,
      },
    }));
  };
  const handleInputFocus = (property: string, section: string) => {
    setDateRangeForm({
      ...DateRangeForm,
      [property]: {
        ...DateRangeForm[property as keyof typeof DateRangeForm],
        error: null,
      },
    });

  }

  const getDataWithingRange = async () => {
    const [validateData, isValid] = await validateFormData(DateRangeForm);
    setDateRangeForm(validateData);
    if (isValid) {
      const payload: DateRangeParmDto = {
        fromDate: DateRangeForm.fromDate.value?.toString() || '',
        toDate: DateRangeForm.toDate.value?.toString() || '',
        isAll: Boolean(DateRangeForm.isAll.value)
      }
      dispatch(requestActions.GetRequestHistory(payload))

    }


  }


  const onSortHandle3 = (col: string) => {
    const sorted = allocatedRequisitions.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta2.asc) {
      sorted.reverse();
    }

    setSortMeta2((_sort) => ({ field: col, asc: !_sort.asc }));
    setallocatedRequisitions(sorted);
  };



  const onSortHandle2 = (col: string) => {
    const sorted = filteredList2.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setFilteredList2(sorted);
  };

  const onFilterHandle2 = (col: string, value: any) => {
    setIsFiltered2(true);
    const filtered = allocatedHistoryResponse.data.filter((item) => {
      const _value = (item as any)[col];
      if (typeof _value === "boolean") {
        return _value ? value === "Yes" : value === "No";
      }
      if (col === "createdDateandTime") {
        const _selectedMin = dayjs(value[0]).format('YYYY-MM-DD HH:mm')
        const _selectedMax = dayjs(value[1]).format('YYYY-MM-DD HH:mm')
        const _targetDate = dayjs(_value).add(330, 'minute').format('YYYY-MM-DD HH:mm')

        return moment(_targetDate).isBetween(_selectedMin, _selectedMax)
      }
      if (col === "departureDateandTime" || col === "returnDateandTime") {
        const _selectedMin = dayjs(value[0]).format('YYYY-MM-DD HH:mm')
        const _selectedMax = dayjs(value[1]).format('YYYY-MM-DD HH:mm')
        const _targetDate = dayjs(_value).format('YYYY-MM-DD HH:mm')

        return moment(_targetDate).isBetween(_selectedMin, _selectedMax)
      }
      return _value === value;
    });

    setFilteredList2(filtered);
  };

  const getFilterList2 = (col: string): string[] => {
    if (!allocatedHistoryResponse.isLoading) {
      const _list = allocatedHistoryResponse.data || [];

      const keys = _list
        .map((item) => {
          const value = (item as any)[col];

          if (typeof value === "boolean") {
            return value ? "Yes" : "No";
          }
          return value;
        })
        .filter((value, index, array) => array.indexOf(value) === index);
      return keys;
    } else return [];
  };


  const handleChangePage2 = (event: unknown, newPage: number) => {
    setPage2(newPage);
  };
  useEffect(() => {
    if (allocatedHistoryResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (allocatedHistoryResponse.data.length > 0)
        setFilteredList2(
          allocatedHistoryResponse.data.map((item) => {
            return {
              ...item,
              isSelect: false,
            };
          })
        );
      else setFilteredList2([]);
    }
  }, [allocatedHistoryResponse.status]);

  const onSelectRequestIds = (id: string, value: boolean) => {
    if (id === "ALL") {
      setFilteredList2(
        filteredList2.map((item) => {
          return {
            ...item,
            isSelect: value,
          };
        })
      );
    } else {
      setFilteredList2(
        filteredList2.map((item) => {
          return {
            ...item,
            isSelect: id === item.allocationId ? value : item.isSelect,
          };
        })
      );
    }
  };

  const onclickOnView = (allocationId: string) => {
    setNavigateView(false);
    dispatch(vehicleActions.getAllocationRequisitions(allocationId));
    setIsOpenAddPackagePopup(true);
  };

  const onClearFilter2 = () => {
    setIsFiltered2(false);
    setFilteredList2(allocatedHistoryResponse.data);
  };
  const onClearFilter3 = () => {
    setIsFiltered3(false);
    setallocatedRequisitions(allocatedRequisitionsResponse.data);
  };
  const handleChangeRowsPerPage2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage2(+event.target.value);
    setPage2(0);
  };
  const OnclickPopupClose = () => {
    setIsOpenAddPackagePopup(false);
    dispatch(vehicleActions.getAllocationRequisitionsClear());
    setallocatedRequisitions(allocatedRequisitionsResponse.data);
  };

  const getFilterList3 = (col: string): string[] => {
    if (!allocatedRequisitionsResponse.isLoading) {
      const _list = allocatedRequisitionsResponse.data || [];

      const keys = _list
        .map((item) => {
          const value = (item as any)[col];

          if (typeof value === "boolean") {
            return value ? "Yes" : "No";
          }
          return value;
        })
        .filter((value, index, array) => array.indexOf(value) === index);
      return keys;
    } else return [];
  };

  const onFilterHandle3 = (col: string, value: string) => {
    setIsFiltered3(true);

    const _list = allocatedRequisitionsResponse.data || [];
    const filtered = _list.filter((item) => {
      const _value = (item as any)[col];

      if (typeof _value === "boolean") {
        return _value ? value === "Yes" : value === "No";
      }
      if (col === "createdDateandTime" || col === "allocatedOn") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DD HH:mm");

        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DD HH:mm");

        const _targetDate = dayjs(_value)
          .add(330, "minute")
          .format("YYYY-MM-DD HH:mm");

        return moment(_targetDate).isBetween(_selectedMin, _selectedMax);
      }

      if (col === "departureDateTime" || col === "returnDateTime") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DD HH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DD HH:mm");
        const _targetDate = dayjs(_value).format("YYYY-MM-DD HH:mm");


        return moment(_targetDate).isBetween(_selectedMin, _selectedMax);
      }
      return _value === value;
    });

    setallocatedRequisitions(filtered);
  };

  const handleChangeRowsPerPage3 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage3(+event.target.value);
    setPage3(0);
  };
  const handleChangePage3 = (event: unknown, newPage: number) => {
    setPage3(newPage);
  };

  const handleViewClick = (allocationId: string) => {
    dispatch(vehicleActions.getAllocationRequisitions(allocationId));
    setNavigateView(true);
    setAllocationID(allocationId);
  }

  useEffect(() => {
    if (allocatedRequisitionsResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setallocatedRequisitions(allocatedRequisitionsResponse.data);
    }

  }, [allocatedRequisitionsResponse.status,]);


  useEffect(() => {
    if (NavigateView && allocatedRequisitions.length > 0) {
      handleNavigate()
    }
  }, [NavigateView, allocatedRequisitions])

  const handleNavigate = () => {
    let _list: Array<string> = [];

    allocatedRequisitions.forEach((req) => {
      _list = [..._list, req.requestId];
    });

    const allocationData = filteredList2.find(
      (item) => item.allocationId === allocationID
    );

    const allocationDetails: vehicleDetailsListDto =
    {
      allocationId: allocationID,
      editIsTrue: false,
      selectedTripId: "",
      licensePlateNo: allocationData?.vehiclePlateNo || "",
      driverName: allocationData?.driverName || "",
      transportCompany: allocationData?.transportCompany || "",
      vehicleSeatCount: "",
      passengerCount: "",
      seatCount: allocationData?.seatCount?.toString() || "",
      capacity: allocationData?.cbm?.toString() || "",
      vehicleId: Number(allocationData?.vehicleId) || 0,
      isSelected: true,
      fromLocation: allocationData?.fromLocation || "",
      toLocation: allocationData?.toLocation || "",
      availableSeatCount: "",
      maxSeatCount: "",
      fromLocationLatitude: allocationData?.fromLocationLatitude || "",
      fromLocationLongitude: allocationData?.fromLocationLongitude || "",
      toLocationLatitude: allocationData?.toLocationLatitude || "",
      toLocationLongitude: allocationData?.toLocationLongitude || "",
      sbu: "",
      plant: "",
      actualMileage: "",
      actualCost: "",
      isView: true
    }

    if (_list.length > 0)
      navigate(APP_ROUTES.TM_ACCEPT_VEHICLE_ALLOCATION, {
        state: { reqList: _list, allocationDetails: allocationDetails },
      });
  }

  const onClickOnMap = (fLat: string, fLon: string, toLat: string, toLon: string) => {
    const selectedStartPoint = { lat: Number(fLat), lng: Number(fLon) };
    const selectedEndPoint = { lat: Number(toLat), lng: Number(toLon) };
    setselectedStartePointLatANdLang(selectedStartPoint);
    setselectedEndPointtLatANdLang(selectedEndPoint);
    navToMap(selectedStartPoint, selectedEndPoint);
  };

  const navToMap = (selectedStartePointLatANdLang: selectedType, selectedEndPointtLatANdLang: selectedType) => {
    if (selectedStartePointLatANdLang !== undefined && selectedEndPointtLatANdLang !== undefined) {
      dispatch(mapActions.AddStartMapPoint(selectedStartePointLatANdLang));
      dispatch(mapActions.AddEndMapPoint(selectedEndPointtLatANdLang));
    }
    setIsOpenMapPopup(true);
  };

  const onMapPopupClose = () => {
    dispatch(mapActions.AddStartMapPointClear());
    dispatch(mapActions.ADDPickUpPointsClear());
    dispatch(mapActions.AddEndMapPointClear());
    dispatch(mapActions.addDropOffPointsClear());
    setIsOpenMapPopup(false)
  }

  return (
    <React.Fragment>
      <AppLayout componentTitle="Request Creation">
        <section className='page-root'>
          <LmDashboardSummaryChart getLmSummaryResponse={getLmSummaryResponse} />


          <Box sx={{ marginTop: "3rem", background: "#282828" }}>

            <TabContext value={value.toString()}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8} >
                  <TabList
                    sx={{
                      "& .MuiTabs-indicator": {
                        display: "none",
                      },
                    }}
                    onChange={handleChange}
                    orientation="horizontal"
                  >
                    <Tab sx={{
                      marginBlock: "1rem",
                      //  marginTop:"2rem",
                      marginLeft: "1.5rem",
                      "&.Mui-selected": {
                        //   marginLeft: "1.5rem",
                        backgroundColor: "rgba(255, 0, 0, 0.1)",
                        color: "white",
                      },
                      borderRadius: "0.8rem",
                      color: "white",
                    }}
                      label="Request Summary"
                      value="1"
                    />
                    <Tab sx={{
                      marginBlock: "0.5rem",
                      // marginTop:"2rem",
                      marginLeft: "1.5rem",
                      "&.Mui-selected": {
                        marginInline: "1.5rem",
                        backgroundColor: "rgba(255, 0, 0, 0.1)",
                        color: "white",
                      },
                      borderRadius: "0.8rem",
                      color: "white",
                    }}
                      label="Completed Trip summary"
                      value="2"
                    />
                  </TabList>

                </Grid>

                <Grid item xs={12} md={12}>
                  <TabPanel value="1">
                    <LmDashboardGrid
                      page={page}
                      rowsPerPage={rowsPerPage}
                      onHandleChangePage={handleChangePage}
                      onHandleChangeRowsPerPage={handleChangeRowsPerPage}
                      requestDataIsLoading={getRequestDataResponse.isLoading}
                      filteredList={filteredList || []}
                      sortMeta={sortMeta}
                      onSortHandle={onSortHandle}
                      onFilterHandle={onFilterHandle}
                      navigateTo={navigteTORequestScreen}
                      onClearFilter={onClearFilter}
                      isFiltered={isFiltered}
                      onShowStatus={onShowStatus}
                      filters={filters}
                    />

                  </TabPanel>
                  <TabPanel value="2">
                    <Box sx={{ marginTop: "1px" }}>
                      <CompletedTripSummary
                        onSortHandle={onSortHandle2}
                        getFilterList={getFilterList2}
                        onFilterHandle={onFilterHandle2}
                        filteredList={filteredList2}
                        isFiltered={isFiltered2}
                        onHandleChangeRowsPerPage={handleChangeRowsPerPage2}
                        onHandleChangePage={handleChangePage2}
                        page={page2}
                        rowsPerPage={rowsPerPage2}
                        onclickOnView={onclickOnView}
                        isOpenAddPackagePopup={isOpenAddPackagePopup}
                        isLoading={allocatedHistoryResponse.isLoading}
                        onSelectRequestIds={onSelectRequestIds}
                        onClearFilter={onClearFilter2}
                        handleViewClick={handleViewClick}
                        onClickOnMap={onClickOnMap}
                      />
                    </Box>
                  </TabPanel>
                </Grid>
              </Grid>
            </TabContext>
          </Box>

          <AllocatedRequisitionsPopUp
            onClearFilter2={onClearFilter3}
            getFilterList={getFilterList3}
            onFilterHandle={onFilterHandle3}
            isOpenAddPackagePopup={isOpenAddPackagePopup}
            page={page3}
            isFiltered={isFiltered3}
            rowsPerPage={rowsPerPage3}
            onSortHandle={onSortHandle3}
            OnclickPopupClose={OnclickPopupClose}
            allocatedRequisitions={allocatedRequisitions}
            onHandleChangeRowsPerPage={handleChangeRowsPerPage3}
            onHandleChangePage={handleChangePage3}
            isLoading={allocatedRequisitionsResponse.isLoading}
          />
          <StatusPopup
            showStatusPopup={showStatusPopup}
            isStatusDialogOpen={isStatusDialogOpen}
            selectedRequestStatusId={selectedRequestId}
            getRequestStatusProcessResponse={getRequestStatusProcessResponse}
          />

          <MapSummaryPopup
            isOpenMapPopup={isOpenMapPopup}
            onMapPopupClose={onMapPopupClose}
          />
        </section>
      </AppLayout>
    </React.Fragment>
  )
}

export default LmDashboard
