import { AxiosResponse } from "axios"
import { call, put, takeEvery } from "redux-saga/effects"
import { ALERT_ACTION_TYPES, APP_CONFIGS, COMMON_ACTION_TYPES, TRANSPORT_ACTION_TYPE, TRANSPORT_ACTION_TYPES, TRANSPORT_ACTION_TYPES_BANKACC, TRANSPORT_ACTION_TYPES_OTHERDOC} from "../../utilities/constants"
import { transportService } from "../../services/transport.service"
import { FileResponseDto, GetBankListDto, GetBranchListDto, GetCompanyDetailsById, GetPaymentMethodsDto, GetRequiredDocumentParmDto, RegisterTransportCompanyDto, TransportCompaniesDto, UpdateTransportCompany, fileUploadTransportDto, getCompanyDetailsDTO, updateCompanyDetailsParam } from "../../utilities/models/transport.model"
import { AlertDto, getSummaryGridParam } from "../../utilities/models"
import ITransportCompany from "../../types/TransportCompanyDto"

function * getAllTransportCompanies (action: {type: string}) {
    try {
         const companies: AxiosResponse<TransportCompaniesDto[]> = yield call(transportService.getAllTransportCompanies)
         yield put({
             type: TRANSPORT_ACTION_TYPE.GET_ALL_TRANSPORT_COMPANIES + COMMON_ACTION_TYPES.SUCCESS,
             data: companies.data
         })
    } catch (error) {
        yield put({
            type: TRANSPORT_ACTION_TYPE.GET_ALL_TRANSPORT_COMPANIES + COMMON_ACTION_TYPES.ERROR,
            error: error
        })
    }
}


// function * ViewAllTransportCompanies (action: {type: string}) {
//     try {
//          const companies: AxiosResponse<TransportCompaniesDtoViewTable[]> = yield call(transportService.getAllTransportCompanies)
//          yield put({
//              type: TRANSPORT_ACTION_TYPE_COMPANYADD.GET_ALL_TRANSPORT_COMPANIES_COMPANYADD + COMMON_ACTION_TYPES.SUCCESS,
//              data: companies.data
//          })
//     } catch (error) {
//         yield put({
//             type: TRANSPORT_ACTION_TYPE_COMPANYADD.GET_ALL_TRANSPORT_COMPANIES_COMPANYADD + COMMON_ACTION_TYPES.ERROR,
//             error: error
//         })
//     }
// }

 //createcompany
function * AddTransportCompany (action: {type: string,payload:any}) {
    console.log("addtarsportCompany  saga0",action.payload) 
        try {
         const response: AxiosResponse<string> = yield call(transportService.addtarsportCompany,action.payload)
         const setAlert: AlertDto = {
          message: response.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'success'
          }
        }
        yield put(setAlert)
        yield put({
          type: TRANSPORT_ACTION_TYPE.ADD_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.SUCCESS,
          data: response.data
        })
    } catch (error) {
        const setAlert: AlertDto = {
            message: error as string,
      
            type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
      
            options: {
      
              key: new Date().getTime() + Math.random(),
      
              varient: 'error'
      
            }
      
          }
      
          yield put(setAlert)
        yield put({
            type: TRANSPORT_ACTION_TYPE.ADD_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.ERROR,
            error: error
        })
    }
}

  //ViewCompany

function* getRequestBYIdCompany(action: {type: string, companyId: number}) {
    try {
      const request: AxiosResponse<ITransportCompany> = yield call(transportService.gettransportcompanyById, action.companyId)
      yield put({
        type: TRANSPORT_ACTION_TYPE.VIEW_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.SUCCESS,
        data: request.data
      })
    } catch (error) {
      yield put({
        type: TRANSPORT_ACTION_TYPE.VIEW_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.ERROR,
        error: error
      })
    }
  }
  
  //deletecompany
  function* deletecompany(action: { type: string, companyIdDel: number }) {
    try {
      const deletecompanyresponse: AxiosResponse<string> = yield call(transportService.deltransportcompanyById, action.companyIdDel)
      const setAlert: AlertDto = {
        message: deletecompanyresponse.data,
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'success'
        }
      }
      yield put(setAlert)
      yield put({
        type: TRANSPORT_ACTION_TYPE.DELETE_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.SUCCESS,
        data: deletecompanyresponse.data
      })
    } catch (error) {
      const setAlert: AlertDto = {
        message: error as string,
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'error'
        }
      }
      yield put(setAlert)
      yield put({
        type: TRANSPORT_ACTION_TYPE.DELETE_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.ERROR,
        error: error
      })
    }
  }

  //editCompany

  function *  updateCompanyDetails(action:{type:string,payload:updateCompanyDetailsParam}){
    try {
      const updateResponse: AxiosResponse<string> = yield call(transportService.editCompanyById, action.payload)
      const setAlert: AlertDto = {
        message: updateResponse.data,
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'success'
        }
      }
      yield put(setAlert)
      yield put({
        type: TRANSPORT_ACTION_TYPE.EDIT_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.SUCCESS,
        data: updateResponse.data
      })
      
    } catch (error) {
      const setAlert: AlertDto = {
        message: error as string,
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'error'
        }
      }
      yield put(setAlert)
      yield put({
        type: TRANSPORT_ACTION_TYPE.EDIT_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.ERROR,
        error: error
      })
    
    }
  }

  //uploadFile
function* uploadFileTransport(action: { type: string, payload:fileUploadTransportDto }) {
  try {
    const uploadFileResponseTranport: AxiosResponse<string> = yield call(transportService.uploadFileTransport, action.payload)
    const setAlert: AlertDto = {
      message: "File Uploaded",
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: TRANSPORT_ACTION_TYPES.TRANSPORT_UPLOAD_FILE + COMMON_ACTION_TYPES.SUCCESS,
      data: uploadFileResponseTranport.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: TRANSPORT_ACTION_TYPES.TRANSPORT_UPLOAD_FILE + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}


function* uploadFileTransportBankAcc(action: { type: string, payload:fileUploadTransportDto }) {
  try {
  const uploadFileResponseTranportBankAcc: AxiosResponse<string> = yield call(transportService.uploadFileTransport, action.payload)
    const setAlert: AlertDto = {
      message: "File Uploaded",
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: TRANSPORT_ACTION_TYPES_BANKACC.TRANSPORT_BANKACC_UPLOAD_FILE + COMMON_ACTION_TYPES.SUCCESS,
     data: uploadFileResponseTranportBankAcc.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: TRANSPORT_ACTION_TYPES_BANKACC.TRANSPORT_BANKACC_UPLOAD_FILE + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* uploadFileTransportOtherDoc(action: { type: string, payload:fileUploadTransportDto }) {
  try {
   const uploadFileResponseTranportOtherDoc: AxiosResponse<string> = yield call(transportService.uploadFileTransport, action.payload)
    const setAlert: AlertDto = {
      message: "File Uploaded",
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: TRANSPORT_ACTION_TYPES_OTHERDOC.TRANSPORT_OTHERDOC_UPLOAD_FILE + COMMON_ACTION_TYPES.SUCCESS,
      data: uploadFileResponseTranportOtherDoc.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: TRANSPORT_ACTION_TYPES_OTHERDOC.TRANSPORT_OTHERDOC_UPLOAD_FILE + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

 function* GetRequiredDocument(action: { type: string, payload: GetRequiredDocumentParmDto }) {
  try {
    const fileDownloadResponse: AxiosResponse<FileResponseDto> = yield call(transportService.GetRequiredDocument, action.payload)
      //Get the content type from the response headers or data
      
      const base64Data =fileDownloadResponse.data.base64
      const binaryString = atob(base64Data);
      const byteArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }


      const blob = new Blob([byteArray], { type: fileDownloadResponse.data.contentType});
      const url = URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Document'; // Replace with desired file name and extension
      link.click();
  
      URL.revokeObjectURL(url); // Clean up the URL when done
    
    yield put({
      type: TRANSPORT_ACTION_TYPES.TRANSPORT_GET_REQUIRED_DOCUMENT + COMMON_ACTION_TYPES.SUCCESS,
      data: fileDownloadResponse.data,
    })
    const setAlert: AlertDto = {
      message: "File Downloaded",
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: TRANSPORT_ACTION_TYPES.TRANSPORT_GET_REQUIRED_DOCUMENT + COMMON_ACTION_TYPES.ERROR,
      error: error
     ,
    })
    
  }

}


function* RemoveUploadedDocument(action: { type: string, payload: GetRequiredDocumentParmDto  }) {
  try {
  const RemoveResponse: AxiosResponse<string> = yield call(transportService.RemoveUploadedDocument, action.payload)
    const setAlert: AlertDto = {
      message: "File Removed",
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: TRANSPORT_ACTION_TYPES.TRANSPORT_REMOVE_DOCUMENT + COMMON_ACTION_TYPES.SUCCESS,
     data: RemoveResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: TRANSPORT_ACTION_TYPES.TRANSPORT_REMOVE_DOCUMENT + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}


function * getAllCompanyDetails (action: {type: string, payload:getSummaryGridParam}) {
  try {
       const CompanyDetailsList: AxiosResponse<getCompanyDetailsDTO[]> = yield call(transportService.getAllCompanyDetails, action.payload)
       yield put({
           type: TRANSPORT_ACTION_TYPES.GET_ALL_TRANSPORT_COMPANY_DETAILS + COMMON_ACTION_TYPES.SUCCESS,
           data: CompanyDetailsList.data
       })
  } catch (error) {
      yield put({
          type: TRANSPORT_ACTION_TYPES.GET_ALL_TRANSPORT_COMPANY_DETAILS + COMMON_ACTION_TYPES.ERROR,
          error: error
      })
  }
}

function * GetPaymentMethods (action: {type: string}) {
  try {
       const paymentMethods: AxiosResponse<GetPaymentMethodsDto[]> = yield call(transportService.GetPaymentMethods)
       yield put({
           type: TRANSPORT_ACTION_TYPES.GET_PAYMENT_METHODS + COMMON_ACTION_TYPES.SUCCESS,
           data: paymentMethods.data
       })
  } catch (error) {
      yield put({
          type: TRANSPORT_ACTION_TYPES.GET_PAYMENT_METHODS + COMMON_ACTION_TYPES.ERROR,
          error: error
      })
  }
}

function * GetBankList (action: {type: string}) {
  try {
       const GetBankList: AxiosResponse<GetBankListDto[]> = yield call(transportService.getBankList)
       yield put({
           type: TRANSPORT_ACTION_TYPES.GTE_BANK_LIST + COMMON_ACTION_TYPES.SUCCESS,
           data: GetBankList.data
       })
  } catch (error) {
      yield put({
          type: TRANSPORT_ACTION_TYPES.GTE_BANK_LIST + COMMON_ACTION_TYPES.ERROR,
          error: error
      })
  }
}
function * GetBranchList (action: {type: string,payload:string}) {
  try {
       const BranchList: AxiosResponse<GetBranchListDto[]> = yield call(transportService.getBranchList,action.payload)
       yield put({
           type: TRANSPORT_ACTION_TYPES.GTE_BRANCH_LIST + COMMON_ACTION_TYPES.SUCCESS,
           data: BranchList.data
       })
  } catch (error) {
      yield put({
          type: TRANSPORT_ACTION_TYPES.GTE_BRANCH_LIST + COMMON_ACTION_TYPES.ERROR,
          error: error
      })
  }
}
function * RegisterTransportCompany (action: {type: string,payload:RegisterTransportCompanyDto}) {
  try {
       const response: AxiosResponse<any> = yield call(transportService.RegisterTransportCompany,action.payload)

      //  if(response.data==="Already existing"){
      //   const setAlert: AlertDto = {
      //     message: response.data,
      //     type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
      //     options: {
      //       key: new Date().getTime() + Math.random(),
      //       varient: 'error'
      //     }
      //   }
      //   yield put(setAlert)
      //  }else{
      //   const setAlert: AlertDto = {
      //     message: response.data,
      //     type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      //     options: {
      //       key: new Date().getTime() + Math.random(),
      //       varient: 'success'
      //     }
      //   }
      //   yield put(setAlert)
      //  }
   
       yield put({
           type: TRANSPORT_ACTION_TYPES.ADD_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.SUCCESS,
           data: response.data
       })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
      yield put({
          type: TRANSPORT_ACTION_TYPES.ADD_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.ERROR,
          error: error
      })
  }
}
function * getTransportCompanyById (action: {type: string,payload:string}) {
  try {
       const response: AxiosResponse<GetCompanyDetailsById> = yield call(transportService.getTransportCompanyById,action.payload)
      //  const setAlert: AlertDto = {
      //   message: response.data,
      //   type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      //   options: {
      //     key: new Date().getTime() + Math.random(),
      //     varient: 'success'
      //   }
      // }
      // yield put(setAlert)
        yield put({
           type: TRANSPORT_ACTION_TYPES.Get_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.SUCCESS,
           data: response.data
       })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
      yield put({
          type: TRANSPORT_ACTION_TYPES.Get_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.ERROR,
          error: error
      })
  }
}

function * UpdateTransportCompanyById (action: {type: string,payload:UpdateTransportCompany}) {
  try {
       const response: AxiosResponse<any> = yield call(transportService.UpdateTransportCompanyByID,action.payload)
     
       yield put({
           type: TRANSPORT_ACTION_TYPES.UPDATE_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.SUCCESS,
           data: response.data
       })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
      yield put({
          type: TRANSPORT_ACTION_TYPES.UPDATE_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.ERROR,
          error: error
      })
  }
}


function* transportSaga() {
    yield takeEvery(TRANSPORT_ACTION_TYPE.GET_ALL_TRANSPORT_COMPANIES + COMMON_ACTION_TYPES.REQUEST, getAllTransportCompanies)
    // yield takeEvery(TRANSPORT_ACTION_TYPE.GET_ALL_TRANSPORT_COMPANIES + COMMON_ACTION_TYPES.REQUEST, ViewAllTransportCompanies)
    // yield takeEvery(TRANSPORT_ACTION_TYPE.ADD_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.REQUEST, AddTransportCompany)
    yield takeEvery(TRANSPORT_ACTION_TYPE.VIEW_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.REQUEST, getRequestBYIdCompany)
    yield takeEvery(TRANSPORT_ACTION_TYPE.DELETE_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.REQUEST, deletecompany)
    yield takeEvery(TRANSPORT_ACTION_TYPE.EDIT_TRANSPORT_COMPANY+ COMMON_ACTION_TYPES.REQUEST, updateCompanyDetails)
    yield takeEvery(TRANSPORT_ACTION_TYPES.TRANSPORT_UPLOAD_FILE + COMMON_ACTION_TYPES.REQUEST, uploadFileTransport)
    yield takeEvery(TRANSPORT_ACTION_TYPES_BANKACC.TRANSPORT_BANKACC_UPLOAD_FILE + COMMON_ACTION_TYPES.REQUEST, uploadFileTransportBankAcc)
    yield takeEvery(TRANSPORT_ACTION_TYPES_OTHERDOC.TRANSPORT_OTHERDOC_UPLOAD_FILE + COMMON_ACTION_TYPES.REQUEST, uploadFileTransportOtherDoc)
    yield takeEvery(TRANSPORT_ACTION_TYPES.TRANSPORT_GET_REQUIRED_DOCUMENT + COMMON_ACTION_TYPES.REQUEST, GetRequiredDocument)
    yield takeEvery(TRANSPORT_ACTION_TYPES.TRANSPORT_REMOVE_DOCUMENT + COMMON_ACTION_TYPES.REQUEST, RemoveUploadedDocument)
    yield takeEvery(TRANSPORT_ACTION_TYPES.GET_ALL_TRANSPORT_COMPANY_DETAILS + COMMON_ACTION_TYPES.REQUEST, getAllCompanyDetails)
    yield takeEvery(TRANSPORT_ACTION_TYPES.GET_PAYMENT_METHODS + COMMON_ACTION_TYPES.REQUEST, GetPaymentMethods)
    yield takeEvery(TRANSPORT_ACTION_TYPES.GTE_BANK_LIST + COMMON_ACTION_TYPES.REQUEST, GetBankList)
    yield takeEvery(TRANSPORT_ACTION_TYPES.GTE_BRANCH_LIST + COMMON_ACTION_TYPES.REQUEST, GetBranchList)
    yield takeEvery(TRANSPORT_ACTION_TYPES.ADD_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.REQUEST, RegisterTransportCompany)
    yield takeEvery(TRANSPORT_ACTION_TYPES.Get_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.REQUEST, getTransportCompanyById)
    yield takeEvery(TRANSPORT_ACTION_TYPES.UPDATE_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.REQUEST, UpdateTransportCompanyById)
  }

export default transportSaga

