import React from 'react'
import { AppSkeleton, CustomAutocomplete, CustomButton, CustomHeaderCell, CustomStepper } from '../../Shared'
import { AllPlantBriefDto, AssignVehicleFormDto, AssignVehicleListDto, FuelTypeFormDto, GetVehiclePriceListDto, StateObjectDto, VehicleNumberDto, VehicleTypesDto, shiftEmployeeCategoriesDto, vehicleCostFormDto } from '../../../utilities/models'
import { Box, Grid, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material'
import { StyledTableCell, StyledTextField } from '../../../assets/theme/theme'
import { EditOutlined } from '@mui/icons-material'
import style from './AddFuelTypes.module.scss'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import NoCrashOutlinedIcon from '@mui/icons-material/NoCrashOutlined';
import { APP_TABLE_CONFIGS, DRIVER_SCREEN_MODES, EMPLOYEE_CATEGORY, FUEL_ACTION_TYPES, FUEL_CATEGORY, REQUEST_TYPES, TRANSPORT_COMPANY_MODE } from '../../../utilities/constants'
import { GetFuelListDto, GetFuelTypeDTO } from '../../../utilities/models/fuel.model'

const FuelCostDetails: React.FC<{
    fuelCostForm: FuelTypeFormDto
    fuelPriceList: GetFuelListDto[]
    handleInputFocus(property: string, section: string): void
    onInputHandleChangeVehicleCost(property: string, section: string): void
    helperText: boolean
    onSelectVehicle(id: number, action: string): void;
    isEditFuel: boolean
    onAddPrice(): void
    onEditPrice(): void
    onClear():void
   // fuelList:GetFuelTypeDTO
}> = (props) => {

    const _fuelType = props.fuelCostForm.fuelType
    const _priceL = props.fuelCostForm.priceL

    return (
        <CustomStepper stepNumber={1} stepTitle="Fuel Types">
            <>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <CustomAutocomplete
                            label="Select Fuel types"
                            placeholder='Select Fuel type'
                            onFocus={() => props.handleInputFocus('fuelType', 'VCI')}
                            options={FUEL_CATEGORY.map((t) => { return { label: t.label, value: t.value } })}                          
                            value={_fuelType.value}
                            error={!!_fuelType.error}
                            disabled={_fuelType.disable}
                            required={_fuelType.isRequired}
                            readonly={_fuelType.readonly}
                            helperText={props.helperText && _fuelType.error}
                            onChange={(event: any, value: any) => props.onInputHandleChangeVehicleCost('fuelType', value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <StyledTextField
                            fullWidth
                            label="Price per 1L"
                            placeholder="Enter price"
                            size="small"
                            InputProps={{
                                readOnly: _priceL.readonly,
                                type:"number"
                            }}
                            value={_priceL.value}
                            error={!!_priceL.error}
                            disabled={_priceL.disable}
                            required={_priceL.isRequired}
                            helperText={props.helperText && _priceL.error}
                            onFocus={() => props.handleInputFocus("priceL", "VCI")}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                               props.onInputHandleChangeVehicleCost("priceL", event.target.value)
                            }
                        />
                    </Grid>
                </Grid>
                <section className={style.actions}>
                 
                    <CustomButton text='Clear' textColor='black'  bgColor='#bfbfbf' onClick={() => props.onClear()} />
                    <CustomButton text={props.isEditFuel ? 'Edit' : 'Add'}  border='1px solid #6e6e6e' bgColor='#282828' onClick={() => props.isEditFuel ? props.onEditPrice() : props.onAddPrice()} />
                </section>
            </>

            <section>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <CustomHeaderCell id='id' >#</CustomHeaderCell>
                                <CustomHeaderCell id='fuelType'>Fuel Type</CustomHeaderCell>
                                <CustomHeaderCell id='price'>Fuel Price</CustomHeaderCell>
                                <CustomHeaderCell width={150} id='actions' >Actions</CustomHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.fuelPriceList.map((p) => (
                                <TableRow key={p.id}>
                                    <StyledTableCell >{p.id}</StyledTableCell>
                                    <StyledTableCell >{p.fuelType} </StyledTableCell>
                                    <StyledTableCell>{parseFloat(p.price).toLocaleString('en-US')} LKR</StyledTableCell>
                                    <StyledTableCell>
                                        <Box className='layout-row'>
                                            <Box>
                                                <IconButton size='small' onClick={() => props.onSelectVehicle(p.id, "edit")}>
                                                    <Tooltip title="Edit">
                                                        <EditOutlined sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                                    </Tooltip>
                                                </IconButton>
                                            </Box>
                                            <Box>
                                                <IconButton size='small' onClick={() => props.onSelectVehicle(p.id, "delete")}>
                                                    <Tooltip title="Delete">
                                                        <DeleteOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                                    </Tooltip>
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        {props.fuelPriceList.length === 0 &&
                            <TableBody>
                                <TableRow>
                                    <StyledTableCell align="center" colSpan={4}>No data to preview</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        }

                    </Table>
                </TableContainer>
            </section>
        </CustomStepper>
    )
}

export default FuelCostDetails
