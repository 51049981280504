import { AxiosResponse } from "axios"
import { call, put, takeEvery } from "redux-saga/effects"
import { COMMON_ACTION_TYPES, PACKAGE_ACTION_TYPE } from "../../utilities/constants"
import { PackageTypeBriefDto } from "../../utilities/models"
import { packageService } from "../../services/package.service"

function * getPackageTypes (action: {type: string}) {
    try {
        const packageTypes: AxiosResponse<PackageTypeBriefDto[]> = yield call(packageService.getPackageType)
        yield put({
            type: PACKAGE_ACTION_TYPE.GET_PACKAGE_TYPES + COMMON_ACTION_TYPES.SUCCESS,
            data: packageTypes.data
        })
   } catch (error) {
       yield put({
           type: PACKAGE_ACTION_TYPE.GET_PACKAGE_TYPES + COMMON_ACTION_TYPES.ERROR,
           error: error
       })
   }
}
function* packageSaga() {
    yield takeEvery(PACKAGE_ACTION_TYPE.GET_PACKAGE_TYPES + COMMON_ACTION_TYPES.REQUEST, getPackageTypes)
}

export default packageSaga