import React, { useEffect, useState } from 'react'
import styles from './AddPackagePopup.module.scss'
import { BootstrapDialog, BootstrapDialogActions, BootstrapDialogContent, BootstrapDialogTitle, StyledTableCell, StyledTextField } from '../../../assets/theme/theme'
import { Box, Grid, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import { CustomAutocomplete, CustomButton, CustomHeaderCell } from '../../Shared'
import { LocationBriefDto, PackageDetailsFormDto, PackageItemsFormDto, PackageTypeBriefDto, PassengerBriefDto, NewRequestPackageDetailsItemsDto, StateObjectDto } from '../../../utilities/models'
import { REQUEST_SCREEN_MODES } from '../../../utilities/constants'
import { EditOutlined } from '@mui/icons-material'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import PlaceAutoComplete from '../../Shared/GoogleMap/PlaceAutoComplete/PlaceAutoComplete'
const AddPackagePopup: React.FC<{
  helperText: boolean
  isEdit: boolean
  isEditItem: boolean
  screenMode: string
  packageDetailsForm: PackageDetailsFormDto
  packageItemsForm: PackageItemsFormDto
  onInputHandleChangePackageDetails(property: string, value: any): void;
  onInputHandleChangePackageDetailItems(property: string, value: any): void;
  onSearchValueChange(property: string, value: any): void;
  handleInputFocus(property: string, section: string): void;
  isOpenAddPackagePopup: boolean
  showAddPackagePopup(con: boolean): void
  onSortHandle(col: string): void
  setSelectedPackageItem(id: number): void
  onSelectPackageItemForEdit(id: number): void
  onAddPackage(): void
  onEditPackage(): void
  onAddPackageItem(): void
  onEditPackageItem(): void
  onClearPackageItems(): void
  frequentLocations: LocationBriefDto[]
  allRecipientResponse: StateObjectDto<PassengerBriefDto[] | []>
  packageDetailItemsList: NewRequestPackageDetailsItemsDto[]
  packageTypes: PackageTypeBriefDto[]
  onInputHandleChangeGoogleInput(property: string, value: any, section: string): void;
  switchValue: boolean;
  switchValue2: boolean;
}> = (props) => {
  const _packageType = props.packageDetailsForm.packageType
  const _gatePassNo = props.packageDetailsForm.gatePassNo
  const _pickup = props.packageDetailsForm.pickup
  const _dropOff = props.packageDetailsForm.dropOff
  const _recipient = props.packageDetailsForm.recipient
  const _contact = props.packageDetailsForm.contactNumber
  const _instructions = props.packageDetailsForm.instructions

  const _serialNumber = props.packageItemsForm.serialNumber
  const _description = props.packageItemsForm.description
  const _cbm = props.packageItemsForm.cbm
  const _weight = props.packageItemsForm.weight

  const _allPassengers = props.allRecipientResponse !== undefined && props.allRecipientResponse?.data?.length > 0 ? props.allRecipientResponse.data : []
  const { ready, value, setValue, suggestions: { status, data }, clearSuggestions } = usePlacesAutocomplete();
  const [switchValue, setswitchValue] = useState(false)
  const [switchValue2, setswitchValue2] = useState(false)

  useEffect(() => {
    if (props.switchValue === false) {
      setswitchValue(true)
    }
    if (props.switchValue2 === false) {
      setswitchValue2(true)
    }
  }, [props.switchValue, props.switchValue2])

  useEffect(() => {
    if (props.screenMode === REQUEST_SCREEN_MODES.EDIT || props.screenMode === REQUEST_SCREEN_MODES.VIEW ||
      props.screenMode === REQUEST_SCREEN_MODES.PENDING_VEHICLE_VIEW || REQUEST_SCREEN_MODES.USE_TEMPLATE) {
        
      if (switchValue === true) {
        handleSelect(props.packageDetailsForm.pickup.value.location, 1)
      }
      if (switchValue2 === true) {
        handleSelect(props.packageDetailsForm.dropOff.value.location, 2)
      }
    }
  }, [switchValue, switchValue2])

  const handleAddLocationClick = (value: string) => {
    if (value === "pickUp") {
      if (switchValue) {
        setswitchValue(false)
      } else {
        setswitchValue(true)

      }
    } else if (value === "dropLocation") {
      if (switchValue2) {
        setswitchValue2(false)
      } else {
        setswitchValue2(true)

      }
    }
  }

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSelect = async (address: string, typeID: number) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    if (typeID === 1) {
      setswitchValue(false)
      let data = { address: address, latAndLang: { lat: lat, lng: lng } }
      props.onInputHandleChangeGoogleInput("pickLocation", data, "PK")

    } else if (typeID === 2) {
      setswitchValue2(false)
      let data = { address: address, latAndLang: { lat: lat, lng: lng } }
      props.onInputHandleChangeGoogleInput("dropOffLocation", data, "PK")
    } else if (typeID === 3) {
      // setMiddleLocations([...middleLocations, { lat, lng }]);
    }
  };

  return (
    <BootstrapDialog
      className={styles.dialogCard}
      aria-labelledby="customized-dialog-title"
      open={props.isOpenAddPackagePopup}
    >
      <BootstrapDialogTitle id="customized-dialog-title"
        onClose={() => props.showAddPackagePopup(false)}
      >
        Package Details
      </BootstrapDialogTitle>
      <BootstrapDialogContent>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <CustomAutocomplete
              label="Package Type"
              placeholder='Select package type'
              onFocus={() => props.handleInputFocus('packageType', 'PACKAGE_DETAILS_FORM')}
              options={props.packageTypes.map((p: PackageTypeBriefDto) => { return { label: p.description, value: p.id } })}
              value={{ label: _packageType.value.description, value: _packageType.value.id }}
              error={!!_packageType.error}
              disabled={_packageType.disable}
              readonly={_packageType.readonly}
              required={_packageType.isRequired}
              helperText={props.helperText && _packageType.error}
              onChange={(event: any, value: any) => props.onInputHandleChangePackageDetails('packageType', value)}
            />
          </Grid>
          <Grid item md={6}>
            <StyledTextField
              fullWidth
              label="Gate pass No"
              placeholder='Enter gate pass No'
              size='small'
              InputProps={{
                readOnly: _gatePassNo.readonly
              }}
              value={_gatePassNo.value}
              error={!!_gatePassNo.error}
              disabled={_gatePassNo.disable}
              required={_gatePassNo.isRequired}
              helperText={props.helperText && _gatePassNo.error}
              onFocus={() => props.handleInputFocus('gatePassNo', 'PACKAGE_DETAILS_FORM')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChangePackageDetails('gatePassNo', event.target.value)}
            />
          </Grid>
          {!switchValue && <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid item xs={10} md={11} >
                <CustomAutocomplete
                  freeSolo={true}
                  label="Pickup Location"
                  placeholder='Select location'
                  onFocus={() => props.handleInputFocus('pickup', 'PACKAGE_DETAILS_FORM')}
                  options={props.frequentLocations && props.frequentLocations.map((l: LocationBriefDto) => { return { label: l.location, value: l.id } })}
                  value={{ label: _pickup.value.location, value: _pickup.value.id }}
                  error={!!_pickup.error}
                  disabled={_pickup.disable}
                  readonly={_pickup.readonly}
                  required={_pickup.isRequired}
                  helperText={props.helperText && _pickup.error}
                  onChange={(event: any, value: any) => props.onInputHandleChangePackageDetails('pickup', value)}
                />

              </Grid>
              <Grid item xs={2} md={1}>
                <IconButton aria-label="delete" size="medium" onClick={() => { handleAddLocationClick("pickUp") }}>
                  <AddLocationAltIcon sx={{ color: "#ffffff" }} fontSize="medium" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>}
          {switchValue && <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid item xs={10} md={11} >
                <PlaceAutoComplete
                  value={value}
                  ready={ready}
                  status={status}
                  data={data}
                  setValue={setValue}
                  required={_pickup.isRequired}
                  clearSuggestions={clearSuggestions}
                  handleInput={handleInput}
                  handleSelect={handleSelect}
                  placeHolder={"Pickup Location"}
                  type={1}
                />

              </Grid>
              <Grid item xs={2} md={1}>
                <IconButton aria-label="delete" size="medium" onClick={() => { handleAddLocationClick("pickUp") }}>
                  <AddLocationAltIcon sx={{ color: "#dd2126" }} fontSize="medium" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>}

          {!switchValue2 && <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid item xs={10} md={11} >
                <CustomAutocomplete
                  freeSolo={true}
                  label="Drop off Location"
                  placeholder='Select location'
                  onFocus={() => props.handleInputFocus('dropOff', 'PACKAGE_DETAILS_FORM')}
                  options={props.frequentLocations && props.frequentLocations.map((l: LocationBriefDto) => { return { label: l.location, value: l.id } })}
                  value={{ label: _dropOff.value.location, value: _dropOff.value.id }}
                  error={!!_dropOff.error}
                  disabled={_dropOff.disable}
                  readonly={_dropOff.readonly}
                  required={_dropOff.isRequired}
                  helperText={props.helperText && _dropOff.error}
                  onChange={(event: any, value: any) => props.onInputHandleChangePackageDetails('dropOff', value)}
                />
              </Grid>
              <Grid item xs={2} md={1}>
                <IconButton aria-label="delete" size="medium" onClick={() => { handleAddLocationClick("dropLocation") }}>
                  <AddLocationAltIcon sx={{ color: "#ffffff" }} fontSize="medium" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>}

          {switchValue2 && <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid item xs={10} md={11} >
                <PlaceAutoComplete
                  value={value}
                  ready={ready}
                  status={status}
                  data={data}
                  setValue={setValue}
                  required={_pickup.isRequired}
                  clearSuggestions={clearSuggestions}
                  handleInput={handleInput}
                  handleSelect={handleSelect}
                  placeHolder={"Drop off Location"}
                  type={2}
                />

              </Grid>
              <Grid item xs={2} md={1}>
                <IconButton aria-label="delete" size="medium" onClick={() => { handleAddLocationClick("dropLocation") }}>
                  <AddLocationAltIcon sx={{ color: "#dd2126" }} fontSize="medium" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>}
          <Grid item md={6}>
            <CustomAutocomplete
              label="Recipient"
              placeholder='Select recipient'
              onFocus={() => props.handleInputFocus('recipient', 'PACKAGE_DETAILS_FORM')}
              options={_allPassengers.map((p: PassengerBriefDto) => { return { label: p.name, value: p.id } })}
              value={_recipient.value}
              error={!!_recipient.error}
              disabled={_recipient.disable}
              readonly={_recipient.readonly}
              required={_recipient.isRequired}
              helperText={props.helperText && _recipient.error}
              onChange={(event: any, value: any) => props.onInputHandleChangePackageDetails('recipient', value)}
              onInputChange={(event: any, value: any) => props.onSearchValueChange('recipient', value)}
            />
          </Grid>
          <Grid item md={6}>
            <StyledTextField
              fullWidth
              label="Recipient contact Number"
              placeholder='Enter recipient contact number'
              size='small'
              InputProps={{
                readOnly: _contact.readonly
              }}
              value={_contact.value}
              error={!!_contact.error}
              disabled={_contact.disable}
              required={_contact.isRequired}
              helperText={props.helperText && _contact.error}
              onFocus={() => props.handleInputFocus('contactNumber', 'PACKAGE_DETAILS_FORM')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChangePackageDetails('contactNumber', event.target.value)}
            />
          </Grid>
          <Grid item md={6}>
            <StyledTextField
              fullWidth
              label="Instructions"
              placeholder='Enter Instructions'
              size='small'
              InputProps={{
                readOnly: _instructions.readonly
              }}
              value={_instructions.value}
              error={!!_instructions.error}
              disabled={_instructions.disable}
              required={_instructions.isRequired}
              helperText={props.helperText && _instructions.error}
              onFocus={() => props.handleInputFocus('instructions', 'PACKAGE_DETAILS_FORM')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChangePackageDetails('instructions', event.target.value)}
            />
          </Grid>
        </Grid>
        <section className={styles.items}>
          {props.screenMode !== REQUEST_SCREEN_MODES.VIEW &&
            <><Grid container spacing={3}>
              <Grid item md={6}>
                <StyledTextField
                  fullWidth
                  label="Serial Number"
                  placeholder='Enter Serial Number'
                  size='small'
                  InputProps={{
                    readOnly: _serialNumber.readonly
                  }}
                  value={_serialNumber.value}
                  error={!!_serialNumber.error}
                  disabled={_serialNumber.disable}
                  required={_serialNumber.isRequired}
                  helperText={props.helperText && _serialNumber.error}
                  onFocus={() => props.handleInputFocus('serialNumber', 'PACKAGE_DETAIL_ITEMS_FORM')}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChangePackageDetailItems('serialNumber', event.target.value)}
                />
              </Grid>
              <Grid item md={6}>
                <StyledTextField
                  fullWidth
                  label="Item Description"
                  placeholder='Enter item description'
                  size='small'
                  InputProps={{
                    readOnly: _description.readonly
                  }}
                  value={_description.value}
                  error={!!_description.error}
                  disabled={_description.disable}
                  required={_description.isRequired}
                  helperText={props.helperText && _description.error}
                  onFocus={() => props.handleInputFocus('description', 'PACKAGE_DETAIL_ITEMS_FORM')}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChangePackageDetailItems('description', event.target.value)}
                />
              </Grid>
              <Grid item md={6}>
                <StyledTextField
                  fullWidth
                  label="CBM (m³)"
                  placeholder='Enter CBM (m³)'
                  size='small'
                  InputProps={{
                    readOnly: _cbm.readonly
                  }}
                  value={_cbm.value}
                  error={!!_cbm.error}
                  disabled={_cbm.disable}
                  required={_cbm.isRequired}
                  helperText={props.helperText && _cbm.error}
                  onFocus={() => props.handleInputFocus('cbm', 'PACKAGE_DETAIL_ITEMS_FORM')}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChangePackageDetailItems('cbm', event.target.value)}
                />
              </Grid>
              <Grid item md={6}>
                <StyledTextField
                  fullWidth
                  label="Weight (kg)"
                  placeholder='Enter weight'
                  size='small'
                  InputProps={{
                    readOnly: _weight.readonly
                  }}
                  value={_weight.value}
                  error={!!_weight.error}
                  disabled={_weight.disable}
                  required={_weight.isRequired}
                  helperText={props.helperText && _weight.error}
                  onFocus={() => props.handleInputFocus('weight', 'PACKAGE_DETAIL_ITEMS_FORM')}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChangePackageDetailItems('weight', event.target.value)}
                />
              </Grid>
            </Grid>

              <div className={styles.itemsAction}>
                <CustomButton text='Clear' textColor='black' bgColor='#bfbfbf' onClick={props.onClearPackageItems} />
                <CustomButton disabled={!_serialNumber.value} text={props.isEditItem ? "Edit Item" : "Add Item"} border='1px solid #6e6e6e' bgColor='#282828' onClick={props.isEditItem ? props.onEditPackageItem : props.onAddPackageItem} />
              </div>
            </>
          }

          <section >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <CustomHeaderCell id='serialNo' >Serial Number</CustomHeaderCell>
                    <CustomHeaderCell id='description' sortable onSort={props.onSortHandle} >Item description</CustomHeaderCell>
                    <CustomHeaderCell id='cbm' sortable onSort={props.onSortHandle} >CBM (m³)</CustomHeaderCell>
                    <CustomHeaderCell id='weight' sortable onSort={props.onSortHandle} >Weight(kg)</CustomHeaderCell>
                    {props.screenMode !== REQUEST_SCREEN_MODES.VIEW && <CustomHeaderCell id='action' >Action</CustomHeaderCell>}
                  </TableRow>
                </TableHead>
                {props.packageDetailItemsList && props.packageDetailItemsList.length > 0 &&
                  <TableBody>
                    {props.packageDetailItemsList.map((p: NewRequestPackageDetailsItemsDto) => (
                      <TableRow>
                        <StyledTableCell >{p.serialNumber}</StyledTableCell>
                        <StyledTableCell >{p.description}</StyledTableCell>
                        <StyledTableCell >{p.cbm}</StyledTableCell>
                        <StyledTableCell >{p.weight} Kg</StyledTableCell>
                        {props.screenMode !== REQUEST_SCREEN_MODES.VIEW &&
                          <StyledTableCell style={{ backgroundColor: '#282828' }}>
                            <Box className='layout-row'>
                              <Box>
                                <IconButton size='small' onClick={() => props.setSelectedPackageItem(p.id)}>
                                  <Tooltip title="Delete">
                                    <DeleteOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                  </Tooltip>
                                </IconButton>
                              </Box>
                              {props.screenMode === REQUEST_SCREEN_MODES.EDIT &&
                                <Box>
                                  <IconButton size='small' onClick={() => props.onSelectPackageItemForEdit(p.id)}>
                                    <Tooltip title="Edit">
                                      <EditOutlined sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                    </Tooltip>
                                  </IconButton>
                                </Box>
                              }
                            </Box>
                          </StyledTableCell>
                        }
                      </TableRow>
                    ))}
                  </TableBody>
                }
                {props.packageDetailItemsList.length === 0 &&
                  <TableBody>
                    <TableRow>
                      <StyledTableCell align="center" colSpan={7}>No data to preview</StyledTableCell>
                    </TableRow>
                  </TableBody>
                }
              </Table>
            </TableContainer>
          </section>

        </section>
      </BootstrapDialogContent>
      <BootstrapDialogActions>
        <CustomButton text='Cancel' border='1px solid #6e6e6e' bgColor='#282828' onClick={() => props.showAddPackagePopup(false)} />
        {props.screenMode !== REQUEST_SCREEN_MODES.VIEW &&
          <CustomButton
            disabled={props.packageDetailItemsList.length === 0}
            text={props.isEdit ? "Edit Package" : "Add Package"} onClick={!props.isEdit ? props.onAddPackage : props.onEditPackage}
          />
        }
      </BootstrapDialogActions>
    </BootstrapDialog>
  )
}

export default AddPackagePopup
