/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { AppLayout } from '../../templates'
import { ConfirmationDialog, VehicleManagementForm } from '../../components'
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AddNewIncidentDto, AddVehicleIncidentParamDto, AlertDto, ApplicationStateDto, DocPayloadDto, DocumentListDto,
  DownloadParamLinkDto,
  EditVehicleParamDto, FileUploadLinkDto, IncidentListDto, NewVehiclePayloadDto, OptionsDto, SortMetaDto,
  VehicleDetailsFormDto, VehicleDocumentListDto, VehicleDocumentsDto, VehicleDto, VehicleIncidentDto, VehicleIncidentFormDto,
  VehicleInsuranceDto, VehicleLicenseDto, VehicleOtherDocDto, VehiclePurposeTypesDto
} from '../../utilities/models';
import { validateFormData } from '../../utilities/helpers';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { transportActions } from '../../redux/actions/transport.action';
import { alertActions, linkActions, vehicleActions } from '../../redux/actions';
import { ALERT_ACTION_TYPES, APP_ACTION_STATUS, APP_ROUTES, COMMON_ACTION_TYPES, DOCUMENT_TYPE, DRIVERDOCUMENT, ORGANIZATION_UNIT_TYPES, VEHICLEDOCUMENT, VEHICLE_DOCUMENT_NAME, VEHICLE_PURPOSE_TYPES, VEHICLE_SCREEN_MODES } from '../../utilities/constants';

const ManageVehicleScreen = () => {

  const VEHICLE_DETAIL_FORM_INITIAL_STATE: VehicleDetailsFormDto = {
    companyName: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: 'object', error: '' },
    vehicleNumber: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '', max: 8 },
    vehicleType: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: 'object', error: '' },
    purpose: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: 'object', error: '' },
    ceatCount: { value: '', isRequired: true, disable: false, readonly: false, validator: 'number', error: '', max: 2 },
    cbm: { value: '', isRequired: true, disable: false, readonly: false, validator: 'number', error: '' },
    plant: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: true, validator: 'object', error: '' },
    licenceNumber: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '', },
    licenseFileName: { value: '', isRequired: false, disable: false, readonly: false, validator: 'text', error: '' },
    lnVersion:{ value: 0, isRequired: false, disable: false, readonly: false, validator: 'number', error: '' },
    lnIssuedDate: { value: '', isRequired: true, disable: true, readonly: false, validator: 'date', error: '' },
    lnExpiryDate: { value: '', isRequired: true, disable: true, readonly: false, validator: 'date', error: '' },
    insuranceNumber: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '', },
    insuranceDocumentName: { value: '', isRequired: false, disable: false, readonly: false, validator: 'text', error: '', },
    inVersion:{ value: 0, isRequired: false, disable: false, readonly: false, validator: 'number', error: '' },
    insuranceIssuedDate: { value: '', isRequired: true, disable: true, readonly: false, validator: 'date', error: '' },
    insuranceExpiryDate: { value: '', isRequired: true, disable: true, readonly: false, validator: 'date', error: '' },
    gpsDeviceNumber: { value: '', isRequired: false, disable: false, readonly: false, validator: 'text', error: '' }
  }

  const VEHICLE_DOCUMENTS_DETAILS_INITIAL_STATE: VehicleDocumentsDto = {
    revenueLicenseNo: { value: '', isRequired: true, disable: false, readonly: false, validator: 'number', error: '' },
    revenueLicenseIssue: { value: null, isRequired: true, disable: false, readonly: false, validator: 'date', error: '' },
    revenueLicenseExpiry: { value: null, isRequired: true, disable: false, readonly: false, validator: 'date', error: '' },
    insurancePolicyNo: { value: '', isRequired: true, disable: false, readonly: false, validator: 'number', error: '' },
    insurancePolicyIssue: { value: null, isRequired: true, disable: false, readonly: false, validator: 'date', error: '' },
    insurancePolicyExpiry: { value: null, isRequired: true, disable: false, readonly: false, validator: 'date', error: '' }
  }

  const VEHICLE_INCIDENT_FORM_INITIAL_STATE: VehicleIncidentFormDto = {
    incident: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '', max: 100 },
    date: { value: null, isRequired: true, disable: false, readonly: false, validator: 'date', error: '' },
    outcome: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '', max: 100 },
  }

  const VEHICLE_LICENSE_DETAILS_INITIAL_STATE: VehicleLicenseDto = {
    vehicleLicenseNo: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '', max: 7 },
    licenseFileName: { value: '', isRequired: false, disable: true, readonly: true, validator: 'text', error: '' },
    vehicleLicenseIssue: { value: '', isRequired: true, disable: false, readonly: false, validator: 'date', error: '' },
    vehicleLicenseExpiry: { value: '', isRequired: true, disable: false, readonly: false, validator: 'date', error: '' },
  }

  const VEHICLE_INSURANCE_DETAILS_INITIAL_STATE: VehicleInsuranceDto = {
    insuranceNo: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '', max: 16 },
    insuranceFileName: { value: '', isRequired: false, disable: true, readonly: true, validator: 'text', error: '' },
    insuranceIssue: { value: '', isRequired: true, disable: false, readonly: false, validator: 'date', error: '' },
    insuranceExpiry: { value: '', isRequired: true, disable: false, readonly: false, validator: 'date', error: '' },
  }

  const VEHICLE_OTHER_DETAILS_INITIAL_STATE: VehicleOtherDocDto = {
    vehcileOtherDoc: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '', max: 16 },
    otherFileName: { value: '', isRequired: false, disable: true, readonly: true, validator: 'text', error: '' },
    otherVersion:{ value: 0, isRequired: false, disable: false, readonly: false, validator: 'number', error: '' },
    vehicleOtherIssue: { value: '', isRequired: false, disable: false, readonly: false, validator: 'date', error: '' },
    vehicleOtherExpiry: { value: '', isRequired: false, disable: false, readonly: false, validator: 'date', error: '' },
  }

  const INITIAL_SORT_META: SortMetaDto = {
    field: "",
    asc: false,
  }

  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [screenMode, setScreenMode] = useState("")
  const [vehicleId, setVehicleId] = useState(-1)
  const [helperText, setHelperText] = useState(true)
  const [sortMeta, setSortMeta] = useState<SortMetaDto>(INITIAL_SORT_META);
  const [incidentList, setIncidentList] = useState<IncidentListDto[]>([]);

  const [vehicleDetailsForm, setVehicleDetailsForm] = useState(VEHICLE_DETAIL_FORM_INITIAL_STATE)
  const [vehicleIncidentForm, setVehicleIncidentForm] = useState(VEHICLE_INCIDENT_FORM_INITIAL_STATE)
  const [vehicleDocumentForm, setVehicleDocumentForm] = useState(VEHICLE_DOCUMENTS_DETAILS_INITIAL_STATE)

  const [licenseForm, setLicenseForm] = useState(VEHICLE_LICENSE_DETAILS_INITIAL_STATE);
  const [insuranceForm, setInsuranceForm] = useState(VEHICLE_INSURANCE_DETAILS_INITIAL_STATE);
  const [otherForm, setOtherForm] = useState(VEHICLE_OTHER_DETAILS_INITIAL_STATE);
  const [documentList, setDocumentList] = useState<VehicleDocumentListDto[]>([]);
  const [selectedDoc, setSelectedDoc] = useState(-1)
  const [isOpenDocumentRemovePopup, setIsOpenDocumentRemovePopup] = useState(false)

  const getAllCompaniesResponse = useSelector((state: ApplicationStateDto) => state.transport.getAllCompanies)
  const getVehicleTypesResponse = useSelector((state: ApplicationStateDto) => state.vehicle.getVehicleTypes)
  const createVehicleResponse = useSelector((state: ApplicationStateDto) => state.vehicle.addVehicle)
  const createVehicleIncidentResponse = useSelector((state: ApplicationStateDto) => state.vehicle.addVehicleIncident)
  const editVehicleResponse = useSelector((state: ApplicationStateDto) => state.vehicle.editVehicle)
  const getIncidentsResponse = useSelector((state: ApplicationStateDto) => state.vehicle.getIncidents)
  const authorizedUser = useSelector((state: ApplicationStateDto) => state.authUser.authorizedUser);

  const licenseDocumentResponse = useSelector((state: ApplicationStateDto) => state.link.uploadMainFile);
  const insuranceDocumentResponse = useSelector((state: ApplicationStateDto) => state.link.uploadSecondaryFile);
  const otherDocResponse = useSelector((state: ApplicationStateDto) => state.link.uploadOtherFile);
  
  const [selectedLicenseFile, setSelectedLicenseFile] = useState<File | any>(null);
  const [selectedInsuranceFile, setSelectedInsuranceFile] = useState<File | any>(null);
  const [selectedOtherFile, setSelectedOtherFile] = useState<File | any>(null);

  const setPDFAlert: AlertDto = {
    message: "Please select a PDF file",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const setFileAlert: AlertDto = {
    message: "Document is mandatory for Other Documents!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const vehicleRegError4: AlertDto = {
    message: "Mandatory fields not filled!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  
  const alreadyExistsAlert: AlertDto = {
    message: "Document already exists!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: 'error'
    }
  }

  useEffect(() => {
    getInitialData()
    return () => {
      dispatch(vehicleActions.createVehicleResponseClear())
      dispatch(vehicleActions.editVehicleResponseClear())
      dispatch(vehicleActions.getVehicleIncidentsClear())
      setAsInitialState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialData = () => {
    const { mode, vehicle } = location.state;
    setScreenMode(mode)
    if (mode !== VEHICLE_SCREEN_MODES.CREATE) {
      dispatch(vehicleActions.getVehicleIncidents(vehicle.id))
      setInitialValue(mode, vehicle)
      setVehicleId(vehicle.id)
    } else {
      if (authorizedUser.data && authorizedUser.data !== undefined) {
        const _data = authorizedUser.data.hierarchy.find((d) => d.typeId === ORGANIZATION_UNIT_TYPES.PLANT)
        if (_data)
          setVehicleDetailsForm((vehicleDetailsForm) => ({
            ...vehicleDetailsForm,
            plant: {
              ...vehicleDetailsForm.plant,
              value: { label: _data?.name, value: _data?.id }
            }
          }))
      }
    }
    dispatch(transportActions.getAllTransportCompanies())
    dispatch(vehicleActions.getVehicleTypes())
  }

  const setInitialValue = (screenMode: string, vehicle: VehicleDto) => {
    const _isReadonly = screenMode === VEHICLE_SCREEN_MODES.VIEW
    setVehicleDetailsForm((vehicleDetailsForm) => ({
      ...vehicleDetailsForm,
      companyName: {
        ...vehicleDetailsForm.companyName,
        value: { label: vehicle.companyName, value: vehicle.companyId },
        readonly: true
      },
      plant: {
        ...vehicleDetailsForm.plant,
        value: { label: vehicle.plant, value: vehicle.plantId },
        readonly: true
      },
      vehicleType: {
        ...vehicleDetailsForm.vehicleType,
        value: { label: vehicle.vehicleType, value: vehicle.vehicleTypeId },
        readonly: true
      },
      vehicleNumber: {
        ...vehicleDetailsForm.vehicleNumber,
        value: vehicle.vehicleNumber,
        readonly: true
      },
      ceatCount: {
        ...vehicleDetailsForm.ceatCount,
        value: vehicle.seatCount.toString(),
        readonly: _isReadonly
      },
      cbm: {
        ...vehicleDetailsForm.cbm,
        value: vehicle.cbm.toString(),
        readonly: _isReadonly
      },
      purpose: {
        ...vehicleDetailsForm.purpose,
        value: {
          label: VEHICLE_PURPOSE_TYPES.find((type) => type.value === vehicle.purpose)?.label || "",
          value: vehicle.purpose
        },
        readonly: _isReadonly
      }
    }))
  }

  useEffect(() => {
    if (createVehicleResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (createVehicleResponse.data === "Vehicle already exists!") {
        const setAlert: AlertDto = {
          message: createVehicleResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));

      }else if (createVehicleResponse.data === "Revenue license already exists!") {
        const setAlert: AlertDto = {
          message: createVehicleResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
      }else if (createVehicleResponse.data === "Insurance policy already exists!") {
        const setAlert: AlertDto = {
          message: createVehicleResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
      }else if(createVehicleResponse.data === "GPSDevice already issued!"){
        const setAlert: AlertDto = {
          message: createVehicleResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
      } else {
        const setAlert: AlertDto = {
          message: createVehicleResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'success'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
        navigate(APP_ROUTES.VEHICLE_MANAGEMENT)
        setAsInitialState()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createVehicleResponse.status])

  useEffect(() => {
    if (editVehicleResponse.status === APP_ACTION_STATUS.SUCCESS && createVehicleIncidentResponse.status === APP_ACTION_STATUS.SUCCESS) {

      if (editVehicleResponse.data === "Already existing") {
        const setAlert: AlertDto = {
          message: "Vehicle already exists!",
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
      }else if(editVehicleResponse.data === "GPSDevice already issued!"){
        const setAlert: AlertDto = {
          message: editVehicleResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
      } else {
        const setAlert: AlertDto = {
          message: editVehicleResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'success'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert))
        navigate(APP_ROUTES.VEHICLE_MANAGEMENT)
        setAsInitialState()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editVehicleResponse.status, createVehicleIncidentResponse.status])

  useEffect(() => {
    if (getIncidentsResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setVehicleDetailsForm((vehicleDetailsForm) => ({
        ...vehicleDetailsForm,
        licenceNumber: {
          ...vehicleDetailsForm.licenceNumber,
          value: getIncidentsResponse.data.licenseNumber,
        },
        licenseFileName: {
          ...vehicleDetailsForm.licenseFileName,
          value: getIncidentsResponse.data.lnDocumentName,
        },
        lnVersion:{
          ...vehicleDetailsForm.lnVersion,
          value:getIncidentsResponse.data.lnVersion,
        },
        lnIssuedDate: {
          ...vehicleDetailsForm.lnIssuedDate,
          value: getIncidentsResponse.data.lnIssuedDate,
        },
        lnExpiryDate: {
          ...vehicleDetailsForm.lnExpiryDate,
          value: getIncidentsResponse.data.lnExpiryDate,
        },
        insuranceNumber: {
          ...vehicleDetailsForm.insuranceNumber,
          value: getIncidentsResponse.data.insuranceNumber,
        },
        insuranceDocumentName: {
          ...vehicleDetailsForm.insuranceDocumentName,
          value: getIncidentsResponse.data.inDocumentName,
        },
        inVersion:{
          ...vehicleDetailsForm.inVersion,
          value: getIncidentsResponse.data.inVersion,
        },
        insuranceIssuedDate: {
          ...vehicleDetailsForm.insuranceIssuedDate,
          value: getIncidentsResponse.data.inIssuedDate,
        },
        insuranceExpiryDate: {
          ...vehicleDetailsForm.insuranceExpiryDate,
          value: getIncidentsResponse.data.inExpiryDate,
        },
        gpsDeviceNumber:{
          ...vehicleDetailsForm.gpsDeviceNumber,
          value:getIncidentsResponse.data.gpsDeviceNumber,
        }
      }))
      if (getIncidentsResponse.data && getIncidentsResponse.data.vehicleIncident.length > 0) {
        setIncidentList(getIncidentsResponse.data.vehicleIncident.map((i, index) => {
          return {
            id: index,
            isNew: false,
            date: dayjs(i.date).format('YYYY-MM-DD'),
            incident: i.incident,
            outCome: i.outCome
          }
        }))
      } else {
        setIncidentList([])
      }

      if (getIncidentsResponse.data) {
        const _id = documentList.length === 0 ? 1 : documentList[documentList.length - 1].id + 1;

        const mainDoc: VehicleDocumentListDto = {
          id: _id,
          documentId: VEHICLEDOCUMENT.REVENUE_DOCUMENT,
          category: VEHICLE_DOCUMENT_NAME.REVENUE_DOCUMENT_NAME,
          documentName: getIncidentsResponse.data?.lnDocumentName || "",
          version: getIncidentsResponse.data.lnVersion,
          issuedDate: getIncidentsResponse.data.lnIssuedDate ? getIncidentsResponse.data.lnIssuedDate.split('T')[0] : '',
          expiryDate: getIncidentsResponse.data.lnExpiryDate ? getIncidentsResponse.data.lnExpiryDate.split('T')[0] : '',
        };

        const secondaryDoc: VehicleDocumentListDto = {
          id: _id + 1,
          documentId: VEHICLEDOCUMENT.INSURANCE_DOCUMENT,
          category: VEHICLE_DOCUMENT_NAME.INSURANCE_DOCUMENT_NAME,
          documentName: getIncidentsResponse.data?.inDocumentName || "",
          version: getIncidentsResponse.data.inVersion,
          issuedDate: getIncidentsResponse.data.inIssuedDate ? getIncidentsResponse.data.inIssuedDate.split('T')[0] : '',
          expiryDate: getIncidentsResponse.data.inExpiryDate ? getIncidentsResponse.data.inExpiryDate.split('T')[0] : '',
        };

        const otherDocuments = getIncidentsResponse.data.documents.map((i, index) => {
          const otherDocId = _id + 2 + index;
          return {
            id: otherDocId,
            documentId: VEHICLEDOCUMENT.OTHER_DOCUMENTS,
            category: (i.documentName.match(/\/([^/]+)\/\d+/) || [])[1] || "",
            documentName: i.documentName || "",
            version: i.version,
            issuedDate: i.issuedDate ? i.issuedDate.split('T')[0] : '',
            expiryDate: i.expiryDate ? i.expiryDate.split('T')[0] : '',
          };
        });

        const updatedDocumentList = [...documentList, mainDoc, secondaryDoc, ...otherDocuments];
        setDocumentList(updatedDocumentList);
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getIncidentsResponse.status])

  const onNavigateTo = () => {
    navigate(APP_ROUTES.VEHICLE_MANAGEMENT)
    setAsInitialState()
    dispatch(linkActions.uploadMainFileClear())
    dispatch(linkActions.uploadSecondaryFileClear())
    dispatch(linkActions.uploadOtherFileClear())
  }

  const handleInputFocus = (property: string, section: string) => {
    if (section === 'vehicle')
      setVehicleDetailsForm({
        ...vehicleDetailsForm,
        [property]: {
          ...vehicleDetailsForm[property as keyof typeof vehicleDetailsForm],
          error: null
        }
      })
    if (section === 'incident')
      setVehicleIncidentForm({
        ...vehicleIncidentForm,
        [property]: {
          ...vehicleIncidentForm[property as keyof typeof vehicleIncidentForm],
          error: null
        }
      })
    if (section === 'license')
      setLicenseForm({
        ...licenseForm,
        [property]: {
          ...licenseForm[property as keyof typeof licenseForm],
          error: null
        }
      })
    if (section === 'insurance')
      setInsuranceForm({
        ...insuranceForm,
        [property]: {
          ...insuranceForm[property as keyof typeof insuranceForm],
          error: null
        }
      })
    if (section === 'other')
      setOtherForm({
        ...otherForm,
        [property]: {
          ...otherForm[property as keyof typeof otherForm],
          error: null
        }
      })
  }

  const onInputHandleChange = (property: string, value: any) => {
    setHelperText(true)
    if (property === 'vehicleNumber') {
      setVehicleDetailsForm(() => ({
        ...vehicleDetailsForm,
        vehicleNumber: {
          ...vehicleDetailsForm.vehicleNumber,
          value: value
        }
      }))
    }
    if (property === 'ceatCount') {
      setVehicleDetailsForm(() => ({
        ...vehicleDetailsForm,
        ceatCount: {
          ...vehicleDetailsForm.ceatCount,
          value: value
        }
      }))
    }
    if (property === 'cbm') {
      setVehicleDetailsForm(() => ({
        ...vehicleDetailsForm,
        cbm: {
          ...vehicleDetailsForm.cbm,
          value: value
        }
      }))
    }
    if (property === 'companyName' || property === 'vehicleType') {
      setVehicleDetailsForm({
        ...vehicleDetailsForm,
        [property]: {
          ...vehicleDetailsForm[property as keyof typeof vehicleDetailsForm],
          value: value
        }
      })
    }

    if (property === "purpose") {
      setVehicleDetailsForm({
        ...vehicleDetailsForm,
        purpose: {
          ...vehicleDetailsForm.purpose,
          value: { value: value.value, label: value.label } as VehiclePurposeTypesDto,
        }
      })
    }

    if (property === "vehicleLicenseNo") {
      setLicenseForm(({
        ...licenseForm,
        vehicleLicenseNo: {
          ...licenseForm.vehicleLicenseNo,
          value: value
        }
      }))

      setVehicleDetailsForm(({
        ...vehicleDetailsForm,
        licenceNumber: {
          ...vehicleDetailsForm.licenceNumber,
          value: value
        }
      }))
    }

    if (property === "vehicleLicenseIssue") {
      setLicenseForm(({
        ...licenseForm,
        vehicleLicenseIssue: {
          ...licenseForm.vehicleLicenseIssue,
          value: value,
          error:null
        }
      }))
      setVehicleDetailsForm(({
        ...vehicleDetailsForm,
        lnIssuedDate: {
          ...vehicleDetailsForm.lnIssuedDate,
          value: value
        }
      }))
    }
    if (property === "vehicleLicenseExpiry") {
      setLicenseForm(({
        ...licenseForm,
        vehicleLicenseExpiry: {
          ...licenseForm.vehicleLicenseExpiry,
          value: value,
          error:null
        }
      }))
      setVehicleDetailsForm(({
        ...vehicleDetailsForm,
        lnExpiryDate: {
          ...vehicleDetailsForm.lnExpiryDate,
          value: value
        }
      }))
    }

    if (property === "insuranceNo") {
      setInsuranceForm(({
        ...insuranceForm,
        insuranceNo: {
          ...insuranceForm.insuranceNo,
          value: value
        }
      }))

      setVehicleDetailsForm(({
        ...vehicleDetailsForm,
        insuranceNumber: {
          ...vehicleDetailsForm.insuranceNumber,
          value: value
        }
      }))
    }

    if (property === "insuranceIssue") {
      setInsuranceForm(({
        ...insuranceForm,
        insuranceIssue: {
          ...insuranceForm.insuranceIssue,
          value: value,
          error:null
        }
      }))

      setVehicleDetailsForm(({
        ...vehicleDetailsForm,
        insuranceIssuedDate: {
          ...vehicleDetailsForm.insuranceIssuedDate,
          value: value
        }
      }))
    }
    if (property === "insuranceExpiry") {
      setInsuranceForm(({
        ...insuranceForm,
        insuranceExpiry: {
          ...insuranceForm.insuranceExpiry,
          value: value,
          error:null
        }
      }))

      setVehicleDetailsForm(({
        ...vehicleDetailsForm,
        insuranceExpiryDate: {
          ...vehicleDetailsForm.insuranceExpiryDate,
          value: value
        }
      }))
    }

    if (property === "vehcileOtherDoc") {
      setOtherForm(({
        ...otherForm,
        vehcileOtherDoc: {
          ...otherForm.vehcileOtherDoc,
          value: value
        }
      }))
    }
    if (property === "otherIssue") {
      setOtherForm(({
        ...otherForm,
        vehicleOtherIssue: {
          ...otherForm.vehicleOtherIssue,
          value: value
        }
      }))
    }
    if (property === "otherExpiry") {
      setOtherForm(({
        ...otherForm,
        vehicleOtherExpiry: {
          ...otherForm.vehicleOtherExpiry,
          value: value
        }
      }))
    }
    if(property === "gpsDeviceNumber"){
      setVehicleDetailsForm(({
        ...vehicleDetailsForm,
        gpsDeviceNumber: {
          ...vehicleDetailsForm.gpsDeviceNumber,
          value: value
        }
      }))
    }
  }

  const onInputHandleChangeIncident = (property: string, value: any) => {
    setHelperText(true)
    if (property === 'date') {
      setVehicleIncidentForm(() => ({
        ...vehicleIncidentForm,
        date: {
          ...vehicleIncidentForm.date,
          value: value,
          error: null
        }
      }))
    }
    if (property === 'outcome') {
      setVehicleIncidentForm(() => ({
        ...vehicleIncidentForm,
        outcome: {
          ...vehicleIncidentForm.outcome,
          value: value
        }
      }))
    }
    if (property === 'incident') {
      setVehicleIncidentForm(() => ({
        ...vehicleIncidentForm,
        incident: {
          ...vehicleIncidentForm.incident,
          value: value
        }
      }))
    }
  }

  const onInputHandleChangeDocument = (property: string, value: any) => {
    setHelperText(true)
    if (property === "licenseNo") {
      setVehicleDocumentForm(() => ({
        ...vehicleDocumentForm,
        revenueLicenseNo: {
          ...vehicleDocumentForm.revenueLicenseNo,
          value: value
        }
      }))
    }
    if (property === "licenceIssueDate") {
      setVehicleDocumentForm(() => ({
        ...vehicleDocumentForm,
        revenueLicenseIssue: {
          ...vehicleDocumentForm.revenueLicenseIssue,
          value: value
        }
      }))
    }
    if (property === "licenceExpiryDate") {
      setVehicleDocumentForm(() => ({
        ...vehicleDocumentForm,
        revenueLicenseExpiry: {
          ...vehicleDocumentForm.revenueLicenseExpiry,
          value: value
        }
      }))
    }
    if (property === "insuranceNo") {
      setVehicleDocumentForm(() => ({
        ...vehicleDocumentForm,
        insurancePolicyNo: {
          ...vehicleDocumentForm.insurancePolicyNo,
          value: value
        }
      }))
    }
    if (property === "insuranceIssueDate") {
      setVehicleDocumentForm(() => ({
        ...vehicleDocumentForm,
        insurancePolicyIssue: {
          ...vehicleDocumentForm.insurancePolicyIssue,
          value: value
        }
      }))
    }
    if (property === "insuranceExpiryDate") {
      setVehicleDocumentForm(() => ({
        ...vehicleDocumentForm,
        insurancePolicyExpiry: {
          ...vehicleDocumentForm.insurancePolicyExpiry,
          value: value
        }
      }))
    }
  }

  const onClearDocumentForm = () => {
    setVehicleDocumentForm(VEHICLE_DOCUMENTS_DETAILS_INITIAL_STATE)
  }

  const onAddIncident = async () => {
    const [validateData, isValid] = await validateFormData(vehicleIncidentForm)
    setVehicleIncidentForm(validateData)
    if (isValid) {
      const _id = incidentList.length === 0 ? 1 : incidentList[incidentList.length - 1].id + 1
      const _incident: IncidentListDto = {
        id: _id,
        isNew: true,
        incident: vehicleIncidentForm.incident.value,
        date: dayjs(vehicleIncidentForm.date.value as Dayjs).format('YYYY-MM-DD'),
        outCome: vehicleIncidentForm.outcome.value
      }
      const _list = [...incidentList, _incident]
      setIncidentList(_list)
      setVehicleIncidentForm(VEHICLE_INCIDENT_FORM_INITIAL_STATE)
    }
  }

  const onClearIncidentForm = () => {
    setVehicleIncidentForm(VEHICLE_INCIDENT_FORM_INITIAL_STATE)
  }

  //CREATE
  const onCreateVehicle = async () => {
    const [validateData, isValid] = await validateFormData(vehicleDetailsForm)
    setVehicleDetailsForm(validateData)

    console.log("CHECKING", validateData);
    if (isValid) {
      const conditionToRemove = (item: any) => {
        return (
          item.category === VEHICLE_DOCUMENT_NAME.REVENUE_DOCUMENT_NAME ||
          item.category === VEHICLE_DOCUMENT_NAME.INSURANCE_DOCUMENT_NAME
        );
      };

      const _docList = documentList
        .filter((d) => !conditionToRemove(d))
        .map((d) => {
          const _doc = {
            documentId: d.documentId,
            documentName: d.documentName,
            version:d.version,
            issuedDate: d.issuedDate ? dayjs(d.issuedDate).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ") : null,
            expiryDate: d.expiryDate ? dayjs(d.expiryDate).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ") : null,
          };
          return _doc;
        });

      const _incidents = incidentList.map((i) => {
        return {
          incident: i.incident,
          date: dayjs(i.date).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ"),
          outCome: i.outCome
        }
      })

      const _payload: NewVehiclePayloadDto = {
        transportCompanyId: Number(vehicleDetailsForm.companyName.value.value),
        vehicleTypeId: Number(vehicleDetailsForm.vehicleType.value.value),
        vehicleNo: vehicleDetailsForm.vehicleNumber.value,
        licenseNumber: vehicleDetailsForm.licenceNumber.value,
        lnDocumentName: vehicleDetailsForm.licenseFileName.value,
        lnVersion: vehicleDetailsForm.lnVersion.value,
        lnIssuedDate: dayjs(vehicleDetailsForm.lnIssuedDate.value).format("YYYY-MM-DDTHH:mmZ"),
        lnExpiryDate: dayjs(vehicleDetailsForm.lnExpiryDate.value).format("YYYY-MM-DDTHH:mmZ"),
        insuranceNumber: vehicleDetailsForm.insuranceNumber.value,
        inDocumentName: vehicleDetailsForm.insuranceDocumentName.value,
        inVersion: vehicleDetailsForm.inVersion.value,
        inIssuedDate: dayjs(vehicleDetailsForm.insuranceIssuedDate.value).format("YYYY-MM-DDTHH:mmZ"),
        inExpiryDate: dayjs(vehicleDetailsForm.insuranceExpiryDate.value).format("YYYY-MM-DDTHH:mmZ"),
        seatCount: Number(vehicleDetailsForm.ceatCount.value),
        organizationUnitId: Number(vehicleDetailsForm.plant.value.value),
        gpsDeviceNumber:vehicleDetailsForm.gpsDeviceNumber.value,
        cbm: Number(vehicleDetailsForm.cbm.value),
        purpose: Number(vehicleDetailsForm.purpose.value.value),
        vehicleIncident: _incidents,
        documents: _docList
      }
      dispatch(vehicleActions.createVehicle(_payload))

    } else {
      dispatch(alertActions.triggerAlert(vehicleRegError4))
    }
  }

  //EDIT
  const onEditVehicle = async () => {
    const [validateData, isValid] = await validateFormData(vehicleDetailsForm)
    setVehicleDetailsForm(validateData)

    console.log("CHECKING", validateData);
    if (isValid) {
      const _incidents: AddNewIncidentDto[] = []
      incidentList.forEach((i) => {
        if (i.isNew) {
          const _new: AddNewIncidentDto = {
            date: dayjs(i.date).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ"),
            incident: i.incident,
            outCome: i.outCome
          }
          _incidents.push(_new)
        }
      })

      const conditionToRemove = (item: any) => {
        return (
          item.category === VEHICLE_DOCUMENT_NAME.REVENUE_DOCUMENT_NAME ||
          item.category === VEHICLE_DOCUMENT_NAME.INSURANCE_DOCUMENT_NAME
        );
      };

      const _docList = documentList
        .filter((d) => !conditionToRemove(d))
        .map((d) => {
          const _doc = {
            documentId: d.documentId,
            documentName: d.documentName,
            issuedDate: d.issuedDate ? dayjs(d.issuedDate).format("YYYY-MM-DDTHH:mmZ") : null,
            expiryDate: d.expiryDate ? dayjs(d.expiryDate).format("YYYY-MM-DDTHH:mmZ") : null,
          };
          return _doc;
        });

      const _addIncidentPayload: AddVehicleIncidentParamDto = {
        vehicleId: vehicleId,
        incidents: _incidents,
      }


      const _payload: EditVehicleParamDto = {
        vehicleId: vehicleId,
        transportCompanyId: Number(vehicleDetailsForm.companyName.value.value),
        vehicleTypeId: Number(vehicleDetailsForm.vehicleType.value.value),
        vehicleNo: vehicleDetailsForm.vehicleNumber.value,
        licenseNumber: vehicleDetailsForm.licenceNumber.value,
        lnDocumentName: vehicleDetailsForm.licenseFileName.value,
        lnVersion: vehicleDetailsForm.lnVersion.value,
        lnIssuedDate: dayjs(vehicleDetailsForm.lnIssuedDate.value).format("YYYY-MM-DDTHH:mmZ"),
        lnExpiryDate: dayjs(vehicleDetailsForm.lnExpiryDate.value).format("YYYY-MM-DDTHH:mmZ"),
        insuranceNumber: vehicleDetailsForm.insuranceNumber.value,
        inDocumentName: vehicleDetailsForm.insuranceDocumentName.value,
        inVersion:vehicleDetailsForm.inVersion.value,
        gpsDeviceNumber:vehicleDetailsForm.gpsDeviceNumber.value,
        inIssuedDate: dayjs(vehicleDetailsForm.insuranceIssuedDate.value).format("YYYY-MM-DDTHH:mmZ"),
        inExpiryDate: dayjs(vehicleDetailsForm.insuranceExpiryDate.value).format("YYYY-MM-DDTHH:mmZ"),
        seatCount: Number(vehicleDetailsForm.ceatCount.value),
        organizationUnitId: Number(vehicleDetailsForm.plant.value.value),
        cbm: Number(vehicleDetailsForm.cbm.value),
        purpose: Number(vehicleDetailsForm.purpose.value.value),
        document: _docList,
      }

      dispatch(vehicleActions.editVehicle(_payload))
      dispatch(vehicleActions.addVehicleIncident(_addIncidentPayload))


    } else {
      dispatch(alertActions.triggerAlert(vehicleRegError4))
    }
  }

  const setAsInitialState = () => {
    setVehicleDetailsForm({
      ...VEHICLE_DETAIL_FORM_INITIAL_STATE,
      plant: vehicleDetailsForm.plant,
    })
    dispatch(linkActions.uploadMainFileClear())
    dispatch(linkActions.uploadSecondaryFileClear())
    dispatch(linkActions.uploadOtherFileClear())

    setVehicleIncidentForm(VEHICLE_INCIDENT_FORM_INITIAL_STATE)
    setVehicleDocumentForm(VEHICLE_DOCUMENTS_DETAILS_INITIAL_STATE)
    setIncidentList([])
    setDocumentList([])
    setLicenseForm(VEHICLE_LICENSE_DETAILS_INITIAL_STATE)
    setInsuranceForm(VEHICLE_INSURANCE_DETAILS_INITIAL_STATE)
    setOtherForm(VEHICLE_OTHER_DETAILS_INITIAL_STATE)
    //TESTING TO CLEAR INITIAL DOC NAME
    setSelectedLicenseFile(null)
  }

  const onSelectDocument = (id: number, action: string) => {
    setSelectedDoc(id)
    if (action === 'delete') {
      setIsOpenDocumentRemovePopup(true)
    }
    if (action === "download" || action === 'view') {
      const foundItem = documentList.find((item) => item.id === id);

      if (foundItem) {
        if (action === 'download') {
          const _downloadPayload: DownloadParamLinkDto = {
            fileName: foundItem.documentName,
            mainDocumentType: DOCUMENT_TYPE.VEHICLE_DOCUMENTS,
          }
          dispatch(linkActions.downloadRequiredFile(_downloadPayload))
        }
        if (action === 'view') {
          const _downloadPayload: DownloadParamLinkDto = {
            fileName: foundItem.documentName,
            mainDocumentType: DOCUMENT_TYPE.VEHICLE_DOCUMENTS,
          }
          dispatch(linkActions.downloadOpenFile(_downloadPayload))
        }
      }
    }
  }

  const onRemoveVehicleDocument = (con: boolean) => {
    if (con) {

      const documentId = documentList.filter((d) => d.id === selectedDoc)

      if (documentId[0].documentId === VEHICLEDOCUMENT.REVENUE_DOCUMENT) {
        setVehicleDetailsForm({
          ...vehicleDetailsForm,
          licenceNumber: {
            ...vehicleDetailsForm.licenceNumber,
            value: ''
          },
          licenseFileName: {
            ...vehicleDetailsForm.licenseFileName,
            value: ''
          },
          lnIssuedDate: {
            ...vehicleDetailsForm.lnIssuedDate,
            value: ''
          },
          lnExpiryDate: {
            ...vehicleDetailsForm.lnExpiryDate,
            value: ''
          },
        })
      }

      if (documentId[0].documentId === VEHICLEDOCUMENT.INSURANCE_DOCUMENT) {
        setVehicleDetailsForm({
          ...vehicleDetailsForm,
          insuranceNumber: {
            ...vehicleDetailsForm.insuranceNumber,
            value: ''
          },
          insuranceDocumentName: {
            ...vehicleDetailsForm.insuranceDocumentName,
            value: ''
          },
          insuranceIssuedDate: {
            ...vehicleDetailsForm.insuranceIssuedDate,
            value: ''
          },
          insuranceExpiryDate: {
            ...vehicleDetailsForm.insuranceExpiryDate,
            value: ''
          },
        })
      }
      setDocumentList(documentList.filter((d) => d.id !== selectedDoc))
      setSelectedDoc(-1)
    }
    setIsOpenDocumentRemovePopup(false)
  }

  const onSortHandle = (col: string) => {
    const sorted = incidentList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setIncidentList(sorted)
  };


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (id === VEHICLEDOCUMENT.REVENUE_DOCUMENT) {
      const file = event.target.files?.[0];
      setSelectedLicenseFile(file);
    }

    if (id === VEHICLEDOCUMENT.INSURANCE_DOCUMENT) {
      const file = event.target.files?.[0];
      setSelectedInsuranceFile(file)
    }

    if (id === VEHICLEDOCUMENT.OTHER_DOCUMENTS) {
      console.log("other calling", event.target.files?.[0])
      const file = event.target.files?.[0];
      setSelectedOtherFile(file)
    }
  };

  const onAddDocuments = async (documentId: number) => {
    const _id = documentList.length === 0 ? 1 : documentList[documentList.length - 1].id + 1

    if (documentId === VEHICLEDOCUMENT.REVENUE_DOCUMENT) {
      const [validateData, isValid] = await validateFormData(licenseForm)
      setLicenseForm(validateData)

      const _isTHere = documentList.find((v) => v.documentId === documentId);
      if (isValid && _isTHere === undefined) {
        const _document: VehicleDocumentListDto = {
          id: _id,
          documentId: documentId,
          category: VEHICLE_DOCUMENT_NAME.REVENUE_DOCUMENT_NAME,
          documentName: licenseForm.licenseFileName.value,
          version: vehicleDetailsForm.lnVersion.value,
          issuedDate: dayjs(licenseForm.vehicleLicenseIssue.value as Dayjs).format('YYYY-MM-DD'),
          expiryDate: dayjs(licenseForm.vehicleLicenseExpiry.value as Dayjs).format('YYYY-MM-DD')
        }
        const _list = [...documentList, _document]
        setDocumentList(_list)
        setLicenseForm(VEHICLE_LICENSE_DETAILS_INITIAL_STATE)
      }

      if(_isTHere !== undefined){
        dispatch(alertActions.triggerAlert(alreadyExistsAlert));
      }
    }

    if (documentId === VEHICLEDOCUMENT.INSURANCE_DOCUMENT) {
      const [validateData, isValid] = await validateFormData(insuranceForm)
      setInsuranceForm(validateData)

      const _isTHere = documentList.find((v) => v.documentId === documentId);
      if (isValid && _isTHere === undefined) {
        const _document: VehicleDocumentListDto = {
          id: _id,
          documentId: documentId,
          category: VEHICLE_DOCUMENT_NAME.INSURANCE_DOCUMENT_NAME,
          documentName: insuranceForm.insuranceFileName.value,
          version: vehicleDetailsForm.inVersion.value,
          issuedDate: dayjs(insuranceForm.insuranceIssue.value as Dayjs).format('YYYY-MM-DD'),
          expiryDate: dayjs(insuranceForm.insuranceExpiry.value as Dayjs).format('YYYY-MM-DD'),
        }

        const _list = [...documentList, _document]
        setDocumentList(_list)
        setInsuranceForm(VEHICLE_INSURANCE_DETAILS_INITIAL_STATE)
      }

      if(_isTHere !== undefined){
        dispatch(alertActions.triggerAlert(alreadyExistsAlert));
      }
    }

    if (documentId === VEHICLEDOCUMENT.OTHER_DOCUMENTS) {
      const [validateData, isValid] = await validateFormData(otherForm)
      setOtherForm(validateData)

      const filePath = otherForm.otherFileName.value.split('/');
      const docFileName = filePath[filePath.length - 2];
      const _isTHere = documentList.find((v) => v.category === otherForm.vehcileOtherDoc.value);


      if (isValid && _isTHere === undefined) {
        if(otherForm.otherFileName.value === ''){
          dispatch(alertActions.triggerAlert(setFileAlert))
        }else{
          const _document: VehicleDocumentListDto = {
            id: _id,
            documentId: documentId,
            category: otherForm.vehcileOtherDoc.value,
            documentName: otherForm.otherFileName.value,
            version:otherForm.otherVersion.value,
            issuedDate: otherForm.vehicleOtherIssue.value ? dayjs(otherForm.vehicleOtherIssue.value as Dayjs).format('YYYY-MM-DD') : null,
            expiryDate: otherForm.vehicleOtherExpiry.value ? dayjs(otherForm.vehicleOtherExpiry.value as Dayjs).format('YYYY-MM-DD') : null,
          }
          const _list = [...documentList, _document]
          setDocumentList(_list)
          setOtherForm(VEHICLE_OTHER_DETAILS_INITIAL_STATE)
        }
      }

      if(_isTHere !== undefined){
        dispatch(alertActions.triggerAlert(alreadyExistsAlert));
      }
    }

  }

  useEffect(() => {
    if (selectedLicenseFile) {
      if (selectedLicenseFile.type === 'application/pdf') {
        const fileUploadaPyload: FileUploadLinkDto = {
          attachment: selectedLicenseFile,
          mainDocumentType: DOCUMENT_TYPE.VEHICLE_DOCUMENTS,
          documentType: VEHICLEDOCUMENT.REVENUE_DOCUMENT,
          folderName: "",
        };
        dispatch(linkActions.uploadMainFile(fileUploadaPyload));
      } else {
        dispatch(alertActions.triggerAlert(setPDFAlert))
      }
    }
  }, [selectedLicenseFile]);

  useEffect(() => {
    if (licenseDocumentResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setLicenseForm(({
        ...licenseForm,
        licenseFileName: {
          ...licenseForm.licenseFileName,
          value: licenseDocumentResponse.data[0]
        }
      }))

      setVehicleDetailsForm(({
        ...vehicleDetailsForm,
        licenseFileName: {
          ...vehicleDetailsForm.licenseFileName,
          value: licenseDocumentResponse.data[0]
        },
        lnVersion:{
          ...vehicleDetailsForm.lnVersion,
          value:vehicleDetailsForm.lnVersion.value + 1
        }
      }))
    }
  }, [licenseDocumentResponse.status])

  useEffect(() => {
    if (selectedInsuranceFile) {
      if (selectedInsuranceFile.type === 'application/pdf') {
        const fileUploadaPyload: FileUploadLinkDto = {
          attachment: selectedInsuranceFile,
          mainDocumentType: DOCUMENT_TYPE.VEHICLE_DOCUMENTS,
          documentType: VEHICLEDOCUMENT.INSURANCE_DOCUMENT,
          folderName: "",
        };
        dispatch(linkActions.uploadSecondaryFile(fileUploadaPyload));
      } else {
        dispatch(alertActions.triggerAlert(setPDFAlert))
      }
    }
  }, [selectedInsuranceFile]);

  useEffect(() => {
    if (insuranceDocumentResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setInsuranceForm(({
        ...insuranceForm,
        insuranceFileName: {
          ...insuranceForm.insuranceFileName,
          value: insuranceDocumentResponse.data[0]
        }
      }))

      setVehicleDetailsForm(({
        ...vehicleDetailsForm,
        insuranceDocumentName: {
          ...vehicleDetailsForm.insuranceDocumentName,
          value: insuranceDocumentResponse.data[0]
        },
        inVersion:{
          ...vehicleDetailsForm.inVersion,
          value:vehicleDetailsForm.inVersion.value + 1,
        }        
      }))
    }
  }, [insuranceDocumentResponse.status])

  useEffect(() => {
    if (selectedOtherFile) {
      if (selectedOtherFile.type === 'application/pdf') {
        const fileUploadaPyload: FileUploadLinkDto = {
          attachment: selectedOtherFile,
          mainDocumentType: DOCUMENT_TYPE.VEHICLE_DOCUMENTS,
          documentType: VEHICLEDOCUMENT.OTHER_DOCUMENTS,
          folderName: otherForm.vehcileOtherDoc.value,
        };
        dispatch(linkActions.uploadOtherFile(fileUploadaPyload));

      } else {
        dispatch(alertActions.triggerAlert(setPDFAlert))
      }
    }
  }, [selectedOtherFile]);


  useEffect(() => {
    if (otherDocResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setOtherForm(({
        ...otherForm,
        otherFileName: {
          ...otherForm.otherFileName,
          value: otherDocResponse.data[0]
        },
        otherVersion:{
          ...otherForm.otherVersion,
          value:otherForm.otherVersion.value+1,
        }
      }))
    }
  }, [otherDocResponse.status])

  const handleDownload = (mainId: number, docId: number) => {
    if (docId === VEHICLEDOCUMENT.REVENUE_DOCUMENT) {
      const _downloadPayload: DownloadParamLinkDto = {
        fileName: licenseForm.licenseFileName.value,
        mainDocumentType: mainId,
      }
      dispatch(linkActions.downloadRequiredFile(_downloadPayload))
    }

    if (docId === VEHICLEDOCUMENT.INSURANCE_DOCUMENT) {
      const _downloadPayload: DownloadParamLinkDto = {
        fileName: insuranceForm.insuranceFileName.value,
        mainDocumentType: mainId,
      }
      dispatch(linkActions.downloadRequiredFile(_downloadPayload))
    }

    if (docId === VEHICLEDOCUMENT.OTHER_DOCUMENTS) {
      const _downloadPayload: DownloadParamLinkDto = {
        fileName: otherForm.otherFileName.value,
        mainDocumentType: mainId,
      }
      dispatch(linkActions.downloadRequiredFile(_downloadPayload))
    }
  }

  const handleDeleteFile = (mainId: number, docId: number) => {
    if (docId === VEHICLEDOCUMENT.REVENUE_DOCUMENT) {
      if (licenseForm.licenseFileName.value !== "") {
        const _downloadPayload: DownloadParamLinkDto = {
          fileName: licenseForm.licenseFileName.value,
          mainDocumentType: mainId,
        }
        dispatch(linkActions.removeUploadedFile(_downloadPayload))
      }

      setLicenseForm(VEHICLE_LICENSE_DETAILS_INITIAL_STATE)
      // setVehicleDetailsForm({
      //   ...vehicleDetailsForm,
      //   licenceNumber: {
      //     ...vehicleDetailsForm.licenceNumber,
      //     value: ''
      //   },
      //   licenseFileName: {
      //     ...vehicleDetailsForm.licenseFileName,
      //     value: ''
      //   },
      //   lnIssuedDate: {
      //     ...vehicleDetailsForm.lnIssuedDate,
      //     value: ''
      //   },
      //   lnExpiryDate: {
      //     ...vehicleDetailsForm.lnExpiryDate,
      //     value: ''
      //   }
      // })
    }

    if (docId === VEHICLEDOCUMENT.INSURANCE_DOCUMENT) {
      if (insuranceForm.insuranceFileName.value !== "") {
        const _downloadPayload: DownloadParamLinkDto = {
          fileName: insuranceForm.insuranceFileName.value,
          mainDocumentType: mainId,
        }
        dispatch(linkActions.removeUploadedFile(_downloadPayload))
      }

      setInsuranceForm(VEHICLE_INSURANCE_DETAILS_INITIAL_STATE)
      // setInsuranceForm({
      //   ...insuranceForm,
      //   insuranceNo: {
      //     ...insuranceForm.insuranceNo,
      //     value: ''
      //   },
      //   insuranceFileName: {
      //     ...insuranceForm.insuranceFileName,
      //     value: ''
      //   },
      //   insuranceIssue: {
      //     ...insuranceForm.insuranceIssue,
      //     value: ''
      //   },
      //   insuranceExpiry: {
      //     ...insuranceForm.insuranceExpiry,
      //     value: ''
      //   }
      // })
    }

    if (docId === VEHICLEDOCUMENT.OTHER_DOCUMENTS) {
      if(otherForm.otherFileName.value !== ""){
        const _downloadPayload: DownloadParamLinkDto = {
          fileName: otherForm.otherFileName.value,
          mainDocumentType: mainId,
        }
        dispatch(linkActions.removeUploadedFile(_downloadPayload))
      }
      setOtherForm(VEHICLE_OTHER_DETAILS_INITIAL_STATE)
    }
  }

  return (
    <React.Fragment>
      <AppLayout componentTitle="Manage Vehicle">
        <section className='page-root'>
          <VehicleManagementForm
            helperText={helperText}
            onSortHandle={onSortHandle}
            vehicleDetailsForm={vehicleDetailsForm}
            vehicleIncidentForm={vehicleIncidentForm}
            vehicleDocumentForm={vehicleDocumentForm}
            onInputHandleChange={onInputHandleChange}
            onInputHandleChangeIncident={onInputHandleChangeIncident}
            onInputHandleChangeDocument={onInputHandleChangeDocument}
            handleInputFocus={handleInputFocus}
            onAddIncident={onAddIncident}
            onAddDocuments={onAddDocuments}
            incidentList={incidentList}
            documentList={documentList}
            onClearIncidentForm={onClearIncidentForm}
            onClearDocumentForm={onClearDocumentForm}
            getAllCompaniesResponse={getAllCompaniesResponse}
            getVehicleTypesResponse={getVehicleTypesResponse}
            createVehicleResponse={createVehicleResponse}
            editVehicleResponse={editVehicleResponse}
            onCreateVehicle={onCreateVehicle}
            onEditVehicle={onEditVehicle}
            onClearCreateVehicleForm={setAsInitialState}
            onNavigateTo={onNavigateTo}
            licenseForm={licenseForm}
            insuranceForm={insuranceForm}
            otherForm={otherForm}
            screenMode={screenMode}
            getIncidentsResponse={getIncidentsResponse}
            handleFileChange={handleFileChange}
            handleDownload={handleDownload}
            handleDeleteFile={handleDeleteFile}
            onSelectDocument={onSelectDocument}
            licenseDocumentResponse={licenseDocumentResponse}
            insuranceDocumentResponse={insuranceDocumentResponse}
            otherDocResponse={otherDocResponse}
          />
        </section>

        <ConfirmationDialog
          isOpenConfirmationDialog={isOpenDocumentRemovePopup}
          onCallback={onRemoveVehicleDocument}
          title="Remove Vehicle Document Information"
          content="Do you want to remove this document?"
        />
      </AppLayout>
    </React.Fragment>
  )
}

export default ManageVehicleScreen
