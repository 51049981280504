import React from 'react'
import style from './CompanyDetails.module.scss'
import Stepper from '../../Shared/Stepper/Stepper'
import { CompanyDetailsFormDto, GetCompanyDetailsById, StateObjectDto } from '../../../utilities/models'
import { Grid } from '@mui/material'
import { REQUEST_TYPES } from '../../../utilities/constants'
import { AppSkeleton, CustomAutocomplete } from '../../Shared'
import { StyledTextField } from '../../../assets/theme/theme'

const CompanyDetails:React.FC<{
    companyDetailsForm:CompanyDetailsFormDto;
    handleInputFocus(property: string, section: string): void;
    onInputHandleChange(property: string, value: any): void;
    helperText: boolean
    getTransportCompanyByIDResponse:StateObjectDto<GetCompanyDetailsById|null>
}> = (props) => {
    const _companyName = props.companyDetailsForm.companyName
    const _attachedSbu=props.companyDetailsForm.attachedSbu
    const _email=props.companyDetailsForm.email
    const _phoneNumber=props.companyDetailsForm.phoneNumber


    console.log("_companyName",_companyName);
  return (
    <Stepper stepNumber={1} stepTitle={"Company Details"}>
       {props.getTransportCompanyByIDResponse && !props.getTransportCompanyByIDResponse.isLoading && (
<>
        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
            <StyledTextField
                fullWidth
                label="Company Name"
                placeholder='Enter Company Name'
                size='small'
                InputProps={{
                  readOnly: _companyName.readonly
                }}
                value={_companyName.value}
                error={!!_companyName.error}
                disabled={_companyName.disable}
                required={_companyName.isRequired}
                helperText={props.helperText && _companyName.error}
                onFocus={() => props.handleInputFocus('companyName', 'CD')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('CompanyName', event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <StyledTextField
                fullWidth
                label="Assigned SBUs"
                placeholder='Enter Assigned SBUs'
                size='small'
                InputProps={{
                  readOnly: _attachedSbu.readonly
                }}
                value={_attachedSbu.value}
                error={!!_attachedSbu.error}
                disabled={_attachedSbu.disable}
                required={_attachedSbu.isRequired}
                helperText={props.helperText && _attachedSbu.error}
                onFocus={() => props.handleInputFocus('AssignedSBUs', 'CD')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('AssignedSBUs', event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <StyledTextField
                fullWidth
                label="Telephone Number "
                placeholder='Enter Telephone Number '
                size='small'
                InputProps={{
                  readOnly: _phoneNumber.readonly
                }}
                value={_phoneNumber.value}
                error={!!_phoneNumber.error}
                disabled={_phoneNumber.disable}
                required={_phoneNumber.isRequired}
                helperText={props.helperText && _phoneNumber.error}
                onFocus={() => props.handleInputFocus('phoneNumber', 'CD')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('phoneNumber', event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <StyledTextField
                fullWidth
                label="Contact email"
                placeholder='Enter Contact email'
                size='small'
                InputProps={{
                  readOnly: _email.readonly
                }}
                value={_email.value}
                error={!!_email.error}
                disabled={_email.disable}
                required={_email.isRequired}
                helperText={props.helperText && _email.error}
                onFocus={() => props.handleInputFocus('email', 'CD')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('email', event.target.value)}
              />
            </Grid>
        </Grid>
</>)}
{props.getTransportCompanyByIDResponse.isLoading &&
        <AppSkeleton numOfRows={2} numOfColumns={2} columnWidth={50} height={40} tag="stepper" />
      }
    </Stepper>
  )
}

export default CompanyDetails