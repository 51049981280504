import React, { useState } from "react";
import { logoImage } from "../../assets/images";
import { APP_ROUTES, USER_ROLES } from "../../utilities/constants";
import { AppLayoutHeader } from "../index";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationStateDto, AuthorizedUserDto } from "../../utilities/models";
import Drawer from "@mui/material/Drawer/Drawer";
import tmsLogo from '../../assets/images/tms logo.png'
import tmsLogoLight from "../../assets/images/tms logoyellow.png"
import tmsWhite from "../../assets/images/tms logoWhite.png"

import DashboardIcon from '@mui/icons-material/Dashboard';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { AppRegistration, ViewComfy } from "@mui/icons-material";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BusinessIcon from '@mui/icons-material/Business';
import FilterTiltShiftIcon from '@mui/icons-material/FilterTiltShift';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';

const AppLayout: React.FC<{
  children: React.ReactNode;
  componentTitle: string;
}> = (props) => {
  const authorizedUser: AuthorizedUserDto = useSelector((state: ApplicationStateDto) => state.authUser.authorizedUser.data);

  return (
    <React.Fragment>
      <div className={"layout-row authorizedContainer"}>
        <aside className={`layout-row sideNavigation `}>
          <aside className="navBar">
            <aside className={"layout-row"}>
              {/* <div className={`menuBox`}>
                <a className="menuIcon" onClick={() => setIsDrawerOpen(true)}>
                  <span></span>
                </a>
              </div> */}
              <div className="contentGroup ">
                <img className="logo" src={logoImage} alt="Logo of TMR" />
              </div>
            </aside>
            
            {authorizedUser.roleId === USER_ROLES.LINE_MANAGER && (
              <>
                <aside className={"links"}>
                  <NavLink
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) =>
                      isActive ? "layout-row is-active" : "layout-row"
                    }
                    to={APP_ROUTES.LM_DASHBOARD}
                  >
                    <div className={`navBarContent navLink layout-row`}>
                     <DashboardIcon sx={{ fontSize: '20px' }} /><span>Dashboard</span>
                    </div>
                  </NavLink>
                </aside>

                <aside className={"links"}>
                  <NavLink
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) =>
                      isActive ? "layout-row is-active" : "layout-row"
                    }
                    to={APP_ROUTES.LM_REQUEST_APPROVAL}
                  >
                    <div className={`navBarContent navLink layout-row`}>
                     <AssignmentTurnedInIcon sx={{ fontSize: '20px' }} /><span>Request Approval</span>
                    </div>
                  </NavLink>
                </aside>
              </>
            )}

            {(authorizedUser.roleId === USER_ROLES.SBU_MANAGER ||
              authorizedUser.roleId === USER_ROLES.TRANSPORT_MANAGER ||
              authorizedUser.roleId === USER_ROLES.DIVISION_USER ||
              authorizedUser.roleId === USER_ROLES.SUPER_ADMIN) && (
                <aside className={"links"}>
                  <NavLink
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) =>
                      isActive ? "layout-row is-active" : "layout-row"
                    }
                    to={APP_ROUTES.TM_DASHBOARD}
                  >
                    <div className={`navBarContent navLink layout-row`}>
                     <DashboardIcon sx={{ fontSize: '20px' }} /><span>Dashboard</span>
                    </div>
                  </NavLink>
                </aside>
              )}


            {(authorizedUser.roleId === USER_ROLES.TRANSPORT_MANAGER ||
              authorizedUser.roleId === USER_ROLES.SUPER_ADMIN ||
              authorizedUser.roleId === USER_ROLES.DIVISION_USER ||
              authorizedUser.roleId === USER_ROLES.SBU_MANAGER) && (
                <aside className={"links"}>
                  <NavLink
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) =>
                      isActive ? "layout-row is-active" : "layout-row"
                    }
                    to={APP_ROUTES.TM_REQUEST_APPROVAL}
                  >
                    <div className={`navBarContent navLink layout-row`}>
                     <AssignmentTurnedInIcon sx={{ fontSize: '20px' }} /> <span>Request Approval</span>
                    </div>
                  </NavLink>
                </aside>
              )}


            {(authorizedUser.roleId === USER_ROLES.TRANSPORT_MANAGER ||
              authorizedUser.roleId === USER_ROLES.SUPER_ADMIN ||
              authorizedUser.roleId === USER_ROLES.DIVISION_USER ||
              authorizedUser.roleId === USER_ROLES.SBU_MANAGER) && (
                <aside className={"links"}>
                  <NavLink
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) =>
                      isActive ? "layout-row is-active" : "layout-row"
                    }
                    to={APP_ROUTES.TM_VEHICLE_ALLOCATION}
                  >
                    <div className={`navBarContent navLink layout-row`}>
                    <LocalShippingIcon sx={{ fontSize: '20px' }} /> <span>Vehicle Allocation</span>
                    </div>
                  </NavLink>
                </aside>
              )}

            {(authorizedUser.roleId === USER_ROLES.REGULAR_USER) && (
              <aside className={"links"}>
                <NavLink
                  style={{ textDecoration: "none" }}
                  className={({ isActive }) =>
                    isActive ? "layout-row is-active" : "layout-row"
                  }
                  to={APP_ROUTES.GU_DASHBOARD}
                >
                  <div className={`navBarContent navLink layout-row`}>
                   <DashboardIcon sx={{ fontSize: '20px' }} /><span>Completed Trip</span>
                  </div>
                </NavLink>
              </aside>
            )}
            <aside className={"links"}>
              <NavLink
                style={{ textDecoration: "none" }}
                className={({ isActive }) =>
                  isActive ? "layout-row is-active" : "layout-row"
                }
                to={APP_ROUTES.REQUEST_CREATION}
              >
                <div className={`navBarContent navLink layout-row`}>
                  <AppRegistration sx={{ fontSize: '20px' }} /><span>Request Creation</span>
                </div>
              </NavLink>
            </aside>

            <aside className={"links"}>
              <NavLink
                style={{ textDecoration: "none" }}
                className={({ isActive }) =>
                  isActive ? "layout-row is-active" : "layout-row"
                }
                to={APP_ROUTES.GU_MANAGE_TEMPLATES}
              >
                <div className={`navBarContent navLink layout-row`}>
                  <ViewComfy sx={{ fontSize: '20px' }}/> <span>Manage Templates</span>
                </div>
              </NavLink>
            </aside>

            {(authorizedUser.roleId === USER_ROLES.TRANSPORT_MANAGER || authorizedUser.roleId === USER_ROLES.SUPER_ADMIN ||
              authorizedUser.roleId === USER_ROLES.SBU_MANAGER || authorizedUser.roleId === USER_ROLES.DIVISION_USER) && (
                <aside className={"links"}>
                  <NavLink
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) =>
                      isActive ? "layout-row is-active" : "layout-row"
                    }
                    to={APP_ROUTES.DS_TRANSPORTATION}
                  >
                    <div className={`navBarContent navLink layout-row`}>
                      <FilterTiltShiftIcon sx={{ fontSize: '20px' }}/> <span>Daily Transportation</span>
                    </div>
                  </NavLink>
                </aside>
              )}

            {(authorizedUser.roleId === USER_ROLES.TRANSPORT_MANAGER) && (
              <aside className={"links"}>
                <NavLink
                  style={{ textDecoration: "none" }}
                  className={({ isActive }) =>
                    isActive ? "layout-row is-active" : "layout-row"
                  }
                  to={APP_ROUTES.VEHICLE_MANAGEMENT}
                >
                  <div className={`navBarContent navLink layout-row`}>
                   <DirectionsCarIcon sx={{ fontSize: '20px' }} /><span>Vehicle Management</span>
                  </div>
                </NavLink>
              </aside>
            )}

            {(authorizedUser.roleId === USER_ROLES.TRANSPORT_MANAGER) && (
              <aside className={"links"}>
                <NavLink
                  style={{ textDecoration: "none" }}
                  className={({ isActive }) =>
                    isActive ? "layout-row is-active" : "layout-row"
                  }
                  to={APP_ROUTES.DRIVER_MANAGEMENT}
                >
                  <div className={`navBarContent navLink layout-row`}>
                    <DirectionsBikeIcon sx={{fontSize: '20px'}} /> <span>Driver Management</span>
                  </div>
                </NavLink>
              </aside>
            )}
            
            {/* {(authorizedUser.roleId === USER_ROLES.TRANSPORT_MANAGER) && (
              <aside className={"links"}>
                <NavLink
                  style={{ textDecoration: "none" }}
                  className={({ isActive }) =>
                    isActive ? "layout-row is-active" : "layout-row"
                  }
                  to={APP_ROUTES.ADDFUELTYPES}
                >
                  <div className={`navBarContent navLink layout-row`}>
                    <LocalGasStationIcon sx={{fontSize: '20px'}} /> <span>Fuel Management</span>
                  </div>
                </NavLink>
              </aside>
            )} */}
            {(authorizedUser.roleId === USER_ROLES.TRANSPORT_MANAGER) && (
              <aside className={"links"}>
                <NavLink
                  style={{ textDecoration: "none" }}
                  className={({ isActive }) =>
                    isActive ? "layout-row is-active" : "layout-row"
                  }
                  to={APP_ROUTES.FUEL_REPORT}
                >
                  <div className={`navBarContent navLink layout-row`}>
                    <LocalGasStationIcon sx={{fontSize: '20px'}} /> <span>Fuel Management</span>
                  </div>
                </NavLink>
              </aside>
            )}
            {authorizedUser.roleId === USER_ROLES.SBU_MANAGER && (
              <aside className={"links"}>
                <NavLink
                  style={{ textDecoration: "none" }}
                  className={({ isActive }) =>
                    isActive ? "layout-row is-active" : "layout-row"
                  }
                  to={APP_ROUTES.MANAGE_TRANSPORT_COMPANY}
                >
                  <div className={`navBarContent navLink layout-row`}>
                   <BusinessIcon sx={{ fontSize: '20px' }} /> <span>Manage Transport Companies</span>
                  </div>
                </NavLink>
              </aside>
            )}



            {(authorizedUser.roleId === USER_ROLES.TRANSPORT_MANAGER ||
              authorizedUser.roleId === USER_ROLES.SUPER_ADMIN ||
              authorizedUser.roleId === USER_ROLES.SBU_MANAGER) && (
                <aside className={"links"}>
                  <NavLink
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) =>
                      isActive ? "layout-row is-active" : "layout-row"
                    }
                    to={APP_ROUTES.ENTITLEMET_CREATION}
                  >
                    <div className={`navBarContent navLink layout-row`}>
                     <AssignmentIndIcon sx={{ fontSize: '20px' }}/> <span>Manage Entitlements</span>
                    </div>
                  </NavLink>
                </aside>
              )}
          </aside>
        </aside>

        <aside className={"content"}>
          <aside className="content2">
            <AppLayoutHeader authorizedUser={authorizedUser} />
          </aside>
          <aside className={"content3"}>{props.children}</aside>
        </aside>
      </div>
    </React.Fragment>
  );
};

export default AppLayout;
