export const ALERT_CONFIGS = {
  TIMEOUT: 4000,
  AUTO_CLEAR: true,
};
export const APP_TABLE_CONFIGS = {
  DEFAULT_ROWS_PER_PAGE_OPTIONS: [5, 10, 15, 25, 50, 100],
  DEFAULT_ROWS_PER_PAGE: 5,
  DATE_FORMAT: "YYYY-MM-DD HH:mm",
  DATE_TIME_FILTERATION_KEYS: [
    "Last 30 minute",
    "Last hour",
    "Last 6 hours",
    "Last 12 hours",
    "Last 24 hours",
    "Last week",
  ],
};

export const PAGINATIONS_LIMIT = {
  DEFAULT_PAGINATION_LIMIT: 50,
};


export const REQUEST_TYPES = [
  { id: 1, name: "AdHoc",},
  { id: 2, name: "Recurrent",},
];

export const NEW_REQUEST_TYPES = [
  { id: 1, name: "AdHoc",},
  { id: 2, name: "Recurrent",},
  { id: 4, name: "Express",},
  { id:5, name:"Express AdHoc"}
]

export const SPLIT_TYPES = [
  { id: 1, name: "Split By Passengers",},
  { id: 2, name: "Split By Return Trip",},
];

export const SHIFT_GROUP = [
  { id: 1, name: "A",},
  { id: 2, name: "B",},
  { id: 3, name: "C",},
  { id: 4, name: "D",},
  { id: 5, name: "E",},
];

export const SHIFT_TYPES = [
  { id: 1, name: "Day",},
  { id: 2, name: "Night",},
];

export const EMPLOYEE_CATEGORY = [
  {value:1, label:"Shift Based"},
  {value:2, label:"General"}
];

export const FUEL_CATEGORY = [
  {value:1, label:"Petrol 92(octane)"},
  {value:2, label:"Petrol 95(octane)"},
  {value:3, label:"Diesel (Auto Diesel)"},
  {value:4, label:"Diesel (Super diesel)"},
  {value:5, label:"Electric (car unit)"}
];

export const ONE_WAY_SHIFT = [
  {value:1, label:"Start Location"},
  {value:2, label:"End Location"}
]

export const VEHICLE_PURPOSE_TYPES = [
  { value:1, label:"Business Trips"},
  { value:2, label:"Daily Transportation"},
]

export const APPROVER_DEPARTMENT = [
  {
    value: "same department",
    label: "same department",
  },
  {
    value: "all department",
    label: "all department",
  },
];

export const ORGANIZATION_UNIT_TYPES = {
  SBU: 2,
  PLANT: 3,
  DEPARTMENT: 4,
};

export const REQUEST_SCREEN_MODES = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  VIEW: "VIEW",
  USE_TEMPLATE: "USE_TEMPLATE",
  PENDING_VEHICLE_VIEW:"PENDING_VEHICLE_VIEW",
  LM_APPROVAL_VIEW:"LM_APPROVAL_VIEW",
  TM_APPROVAL_VIEW:"TM_APPROVAL_VIEW"
};

export const REQUEST_SCREEN_EXPRESS_MODES = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  VIEW: "VIEW",
  USE_TEMPLATE: "USE_TEMPLATE",
  PENDING_VEHICLE_VIEW:"PENDING_VEHICLE_VIEW",
  LM_APPROVAL_VIEW:"LM_APPROVAL_VIEW",
  TM_APPROVAL_VIEW:"TM_APPROVAL_VIEW"
};

export const GENERAL_SCREEN_MODES = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  VIEW: "VIEW",
};

export const VEHICLE_SCREEN_MODES = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  VIEW: "VIEW",
};

export const DRIVER_SCREEN_MODES = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  VIEW: "VIEW",
};
export const TRANSPORT_COMPANY_MODE = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  VIEW: "VIEW",
};
export const DAILY_TRANSPORT_MODES = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  VIEW: "VIEW",
  EXECUTE:"EXECUTE",
  DUPLICATE:"DUPLICATE",
  VIEW_EXECUTED:"VIEW_EXECUTED"
}

export const USER_ROLES = {
  REGULAR_USER: 1,
  LINE_MANAGER: 2,
  DIVISION_USER: 3,
  TRANSPORT_MANAGER: 4,
  SBU_MANAGER: 5,
  SUPER_ADMIN: 6,
};

export const TM_APPROVAL_ACTION = {
  SPLIT: "SPLIT",
  REDIRECT: "REDIRECT",
  REJECT: "REJECT",
  APPROVE: "APPROVE",
  APPROVE_ALLOCATE: "APPROVE_ALLOCATE",
};

export const DOCUMENT_TYPE = {
  DTIVER_DOCUMENTS :  1,
  VEHICLE_DOCUMENTS : 2,
  TRANSPORT_COMPANY_DOCUMENTS : 3,
};

export const DOCUMENT_TYPE_NAME = {
  DRIVER_DOCUMENTS_NAME :  "Driver Document",
  VEHICLE_DOCUMENTS_NAME : "Vehicle Document",
  TRANSPORTCOMPNAY_DOCUMENTS_NAME : "Transport Document",
};

export const DRIVERDOCUMENT = {
  LICENSE_DOCUMENT : 1,
  NIC_DOCUMENT : 2,
  OTHER_DOCUMENT : 3
};

export const DRIVER_DOCUMENT_NAME = {
  LICENSE_DOCUMENT_NAME : "License Document",
  NIC_DOCUMENT : "NIC Document",
  OTHER_DOCUMENT : "Other Document",
};

export const VEHICLEDOCUMENT = {
  INSURANCE_DOCUMENT : 1,
  REVENUE_DOCUMENT : 2,
  OTHER_DOCUMENTS : 3,
};

export const VEHICLE_DOCUMENT_NAME = {
  INSURANCE_DOCUMENT_NAME : "Insurance Document",
  REVENUE_DOCUMENT_NAME : "Revenue Document",
  OTHER_DOCUMENTS_NAME : "Other Document",
};

export const TRANSPORTCOMPNAYDOCUMENT = {
  REGISTRATION_DOCUMENT : 1,
  BANK_DOCUMENT : 2,
  OTHER_DOCUMENTS : 3
};

export const TRANSPORTCOMPNAY_DOCUMENT_NAME = {
  REGISTRTAION_DOCUMENT_NAME : "Registration Document",
  BANK_DOCUMENT_NAME : "Bank Document",
  OTHER_DOCUMENTS_NAME : "Other Document"
};

export const TRANSPORT_COMPANY_DOCUMENT = {
  REGISTRATION_DOCUMENT : 1,
  BANK_DOCUMENT : 2,
  OTHER_DOCUMENTS : 3
};
export const TRANSPORT_COMPANY_DOCUMENT_NAME = {
  REGISTRATION_DOCUMENT_NAME : "Registration Document",
  BANK_DOCUMENT_NAME : "Bank Document",
  OTHER_DOCUMENTS_NAME : "Other Document"
};

export const FUEL_TYPE_NAME = {
  PETROL_92 : "Petrol 92(octane)",
  PETROL_95 : "Petrol 95(octane)",
  DIESEL_AUTO : "Diesel (Auto Diesel)",
  DIESEL_SUPER : "Diesel (Super diesel)",
  ELECTRIC : "Electric (car unit)",
};

export const FUEL_TYPE_MODE = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  VIEW: "VIEW",
};

export const ALL_ENTITLEMENTS = [
  "View Dashboard",
  "View Event History",
  "Receive Notifications",
  "Request Creation",
  "Manage Templates",
  "Request Modification",
  "Request Cancellation",
  "View Pending Approvals",
  "Request Approval/Rejection",
  "Request Approval and Allocation",
  "Request Splitting",
  "Request Redirection",
  "Receive Redirected Requests",
  "View Pending Allocations",
  "Manage Trip",
  "View Allocation History",
  "Edit Allocation",
  "Cancel Allocation",
  "Transport Company Management",
  "Driver Management",
  "Vehicle Management",
  "Daily Transport Template Creation",
  "Manage Daily Transport Templates",
  "Assign Roles and Entitlements(Plant)",
  "Assign Roles and Entitlements(SBU)",
  "Assign Roles and Entitlements(Organization)",
];

export const ApiRolesResponse = [
  {
    id: 1,
    permissions: [
      { type: "View Dashboard" },
      { type: "View Event History" },
      { type: "Receive Notifications" },
      { type: "Request Creation" },
      { type: "Manage Templates" },
      { type: "Request Modification" },
      { type: "Request Cancellation" },
    ],
  },
  {
    id: 2,
    permissions: [
      { type: "View Dashboard" },
      { type: "View Event History" },
      { type: "Receive Notifications" },
      { type: "Request Creation" },
      { type: "Manage Templates" },
      { type: "Request Modification" },
      { type: "Request Cancellation" },
      { type: "View Pending Approvals" },
      { type: "Request Approval/Rejection" },
      { type: "View Approval History" },
    ],
  },
  {
    id: 3,
    permissions: [
      { type: "View Dashboard" },
      { type: "View Event History" },
      { type: "Receive Notifications" },
      { type: "Request Creation" },
      { type: "Manage Templates" },
      { type: "Request Modification" },
      { type: "Request Cancellation" },
      { type: "View Pending Approvals" },
      { type: "Request Approval/Rejection" },
      { type: "Request Approval and Allocation" },
      { type: "Request Splitting" },
      { type: "Request Redirection" },
      { type: "Receive Redirected Requests" },
      { type: "View Pending Allocations" },
      { type: "Manage Trip" },
      { type: "View Allocation History" },
      { type: "Edit Allocation" },
      { type: "Cancel Allocation" },
      { type : "Daily Transport Template Creation"},
      { type : "Manage Daily Transport Templates"}
    ],
  },
  {
    id: 4,
    permissions: [
      { type: "View Dashboard" },
      { type: "View Event History" },
      { type: "Receive Notifications" },
      { type: "Request Creation" },
      { type: "Manage Templates" },
      { type: "Request Modification" },
      { type: "Request Cancellation" },
      { type: "View Pending Approvals" },
      { type: "Request Approval/Rejection" },
      { type: "Request Approval and Allocation" },
      { type: "Request Splitting" },
      { type: "Request Redirection" },
      { type: "Receive Redirected Requests" },
      { type: "View Pending Allocations" },
      { type: "Manage Trip" },
      { type: "View Allocation History" },
      { type: "Edit Allocation" },
      { type: "Cancel Allocation" },
      { type: "Assign Roles and Entitlements(Plant)" },
      { type: "Driver Management" },
      { type: "Vehicle Management" },
      { type : "Daily Transport Template Creation"},
      { type : "Manage Daily Transport Templates"}
    ],
  },
  {
    id: 5,
    permissions: [
      { type: "View Dashboard" },
      { type: "View Event History" },
      { type: "Receive Notifications" },
      { type: "Request Creation" },
      { type: "Manage Templates" },
      { type: "Request Modification" },
      { type: "Request Cancellation" },
      { type: "View Pending Approvals" },
      { type: "Request Approval/Rejection" },
      { type: "Request Approval and Allocation" },
      { type: "Request Splitting" },
      { type: "Request Redirection" },
      { type: "Receive Redirected Requests" },
      { type: "View Pending Allocations" },
      { type: "Manage Trip" },
      { type: "View Allocation History" },
      { type: "Edit Allocation" },
      { type: "Cancel Allocation" },
      { type: "Assign Roles and Entitlements(SBU)" },
      { type: "Transport Company Management" },
      { type : "Daily Transport Template Creation"},
      { type : "Manage Daily Transport Templates"}
    ],
  },
  {
    id: 6,
    permissions: [
      { type: "View Dashboard" },
      { type: "View Event History" },
      { type: "Receive Notifications" },
      { type: "Request Creation" },
      { type: "Manage Templates" },
      { type: "Request Modification" },
      { type: "Request Cancellation" },
      { type: "View Pending Approvals" },
      { type: "Request Approval/Rejection" },
      { type: "Request Approval and Allocation" },
      { type: "Request Splitting" },
      { type: "Request Redirection" },
      { type: "Receive Redirected Requests" },
      { type: "View Pending Allocations" },
      { type: "Manage Trip" },
      { type: "View Allocation History" },
      { type: "Edit Allocation" },
      { type: "Cancel Allocation" },
      { type: "Assign Roles and Entitlements(Organization)" },
      { type : "Daily Transport Template Creation"},
      { type : "Manage Daily Transport Templates"}
    ],
  },
];
