import { APP_ACTION_STATUS, COMMON_ACTION_TYPES, LINK_ACTION_TYPES } from "../../utilities/constants/action.constant";
import { LinkStateDto } from "../../utilities/models";

const INITIAL_STATE: LinkStateDto = {
    uploadMainFile:{
        data: "",
        error: null,
        isLoading: false,
        status: null,
    },
    uploadSecondaryFile:{
        data: "",
        error: null,
        isLoading: false,
        status: null,
    },
    uploadOtherFile:{
        data: "",
        error: null,
        isLoading: false,
        status: null,
    },
    downloadRequiredFile:{
        data: null,
        error: null,
        isLoading: false,
        status: null,
    },
    downloadOpenFile:{
        data: null,
        error: null,
        isLoading: false,
        status: null,
    },
    removeUploadedFile:{
        data: null,
        error: null,
        isLoading: false,
        status: null,
    }
}

const linkReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case LINK_ACTION_TYPES.UPLOAD_MAIN_FILE + COMMON_ACTION_TYPES.REQUEST:
            return {
              ...state,
              uploadMainFile: {
                ...state.uploadMainFile,
                isLoading: true,
                status: APP_ACTION_STATUS.LOADING,
                error: null,
                data: "",
              },
            };
          case LINK_ACTION_TYPES.UPLOAD_MAIN_FILE + COMMON_ACTION_TYPES.SUCCESS:
            return {
              ...state,
              uploadMainFile: {
                ...state.uploadMainFile,
                isLoading: false,
                status: APP_ACTION_STATUS.SUCCESS,
                error: null,
                data: action.data,
              },
            };
          case LINK_ACTION_TYPES.UPLOAD_MAIN_FILE + COMMON_ACTION_TYPES.ERROR:
            return {
              ...state,
              uploadMainFile: {
                ...state.uploadMainFile,
                isLoading: false,
                status: APP_ACTION_STATUS.ERROR,
                error: action.error,
                data: "",
              },
            };
          case LINK_ACTION_TYPES.UPLOAD_MAIN_FILE + COMMON_ACTION_TYPES.CLEAR:
            return {
              ...state,
              uploadMainFile: {
                ...state.uploadMainFile,
                isLoading: false,
                status: APP_ACTION_STATUS.INITIAL,
                error: null,
                data: "",
              },
            };
          case LINK_ACTION_TYPES.UPLOAD_SECONDARY_FILE + COMMON_ACTION_TYPES.REQUEST:
            return {
              ...state,
              uploadSecondaryFile: {
                ...state.uploadSecondaryFile,
                isLoading: true,
                status: APP_ACTION_STATUS.LOADING,
                error: null,
                data: "",
              },
            };
          case LINK_ACTION_TYPES.UPLOAD_SECONDARY_FILE + COMMON_ACTION_TYPES.SUCCESS:
            return {
              ...state,
              uploadSecondaryFile: {
                ...state.uploadSecondaryFile,
                isLoading: false,
                status: APP_ACTION_STATUS.SUCCESS,
                error: null,
                data: action.data,
              },
            };
          case LINK_ACTION_TYPES.UPLOAD_SECONDARY_FILE + COMMON_ACTION_TYPES.ERROR:
            return {
              ...state,
              uploadSecondaryFile: {
                ...state.uploadSecondaryFile,
                isLoading: false,
                status: APP_ACTION_STATUS.ERROR,
                error: action.error,
                data: "",
              },
            };
          case LINK_ACTION_TYPES.UPLOAD_SECONDARY_FILE + COMMON_ACTION_TYPES.CLEAR:
            return {
              ...state,
              uploadSecondaryFile: {
                ...state.uploadSecondaryFile,
                isLoading: false,
                status: APP_ACTION_STATUS.INITIAL,
                error: null,
                data: "",
              },
            };
          case LINK_ACTION_TYPES.UPLOAD_OTHER_FILE + COMMON_ACTION_TYPES.REQUEST:
            return {
              ...state,
              uploadOtherFile: {
                ...state.uploadOtherFile,
                isLoading: true,
                status: APP_ACTION_STATUS.LOADING,
                error: null,
                data: "",
              },
            };
          case LINK_ACTION_TYPES.UPLOAD_OTHER_FILE + COMMON_ACTION_TYPES.SUCCESS:
            return {
              ...state,
              uploadOtherFile: {
                ...state.uploadOtherFile,
                isLoading: false,
                status: APP_ACTION_STATUS.SUCCESS,
                error: null,
                data: action.data,
              },
            };
          case LINK_ACTION_TYPES.UPLOAD_OTHER_FILE + COMMON_ACTION_TYPES.ERROR:
            return {
              ...state,
              uploadOtherFile: {
                ...state.uploadOtherFile,
                isLoading: false,
                status: APP_ACTION_STATUS.ERROR,
                error: action.error,
                data: "",
              },
            };
          case LINK_ACTION_TYPES.UPLOAD_OTHER_FILE + COMMON_ACTION_TYPES.CLEAR:
            return {
              ...state,
              uploadOtherFile: {
                ...state.uploadOtherFile,
                isLoading: false,
                status: APP_ACTION_STATUS.INITIAL,
                error: null,
                data: "",
              },
            };
          case LINK_ACTION_TYPES.DOWNLOAD_REQUIRED_FILE + COMMON_ACTION_TYPES.REQUEST:
            return {
              ...state,
              downloadRequiredFile: {
                ...state.downloadRequiredFile,
                isLoading: true,
                status: APP_ACTION_STATUS.LOADING,
                error: null,
                data: null,
              },
            };
          case LINK_ACTION_TYPES.DOWNLOAD_REQUIRED_FILE + COMMON_ACTION_TYPES.SUCCESS:
            return {
              ...state,
              downloadRequiredFile: {
                ...state.downloadRequiredFile,
                isLoading: false,
                status: APP_ACTION_STATUS.SUCCESS,
                error: null,
                data: action.data,
              },
            };
          case LINK_ACTION_TYPES.DOWNLOAD_REQUIRED_FILE + COMMON_ACTION_TYPES.ERROR:
            return {
              ...state,
              downloadRequiredFile: {
                ...state.downloadRequiredFile,
                isLoading: false,
                status: APP_ACTION_STATUS.ERROR,
                error: action.error,
                data: null,
              },
            };
          case LINK_ACTION_TYPES.DOWNLOAD_REQUIRED_FILE + COMMON_ACTION_TYPES.CLEAR:
            return {
              ...state,
              downloadRequiredFile: {
                ...state.downloadRequiredFile,
                isLoading: false,
                status: APP_ACTION_STATUS.INITIAL,
                error: null,
                data: null,
              },
            };
          
          case LINK_ACTION_TYPES.DOWNLOAD_OPEN_FILE + COMMON_ACTION_TYPES.REQUEST:
            return {
              ...state,
              downloadOpenFile: {
                ...state.downloadOpenFile,
                isLoading: true,
                status: APP_ACTION_STATUS.LOADING,
                error: null,
                data: null,
              },
            };
          case LINK_ACTION_TYPES.DOWNLOAD_OPEN_FILE + COMMON_ACTION_TYPES.SUCCESS:
            return {
              ...state,
              downloadOpenFile: {
                ...state.downloadOpenFile,
                isLoading: false,
                status: APP_ACTION_STATUS.SUCCESS,
                error: null,
                data: action.data,
              },
            };
          case LINK_ACTION_TYPES.DOWNLOAD_OPEN_FILE + COMMON_ACTION_TYPES.ERROR:
            return {
              ...state,
              downloadOpenFile: {
                ...state.downloadOpenFile,
                isLoading: false,
                status: APP_ACTION_STATUS.ERROR,
                error: action.error,
                data: null,
              },
            };
          case LINK_ACTION_TYPES.DOWNLOAD_OPEN_FILE + COMMON_ACTION_TYPES.CLEAR:
            return{
              ...state,
              downloadOpenFile: {
                ...state.downloadOpenFile,
                isLoading: false,
                status: APP_ACTION_STATUS.INITIAL,
                error: null,
                data: null,
              },
            };

          case LINK_ACTION_TYPES.REMOVE_UPLOADED_FILE + COMMON_ACTION_TYPES.REQUEST:
            return {
              ...state,
              removeUploadedFile: {
                ...state.removeUploadedFile,
                isLoading: true,
                status: APP_ACTION_STATUS.LOADING,
                error: null,
                data: null,
              },
            };
          case LINK_ACTION_TYPES.REMOVE_UPLOADED_FILE + COMMON_ACTION_TYPES.SUCCESS:
            return {
              ...state,
              removeUploadedFile: {
                ...state.removeUploadedFile,
                isLoading: false,
                status: APP_ACTION_STATUS.SUCCESS,
                error: null,
                data: action.data,
              },
            };
          case LINK_ACTION_TYPES.REMOVE_UPLOADED_FILE + COMMON_ACTION_TYPES.ERROR:
            return {
              ...state,
              removeUploadedFile: {
                ...state.removeUploadedFile,
                isLoading: false,
                status: APP_ACTION_STATUS.ERROR,
                error: action.error,
                data: null,
              },
            };
          case LINK_ACTION_TYPES.REMOVE_UPLOADED_FILE + COMMON_ACTION_TYPES.CLEAR:
            return {
              ...state,
              removeUploadedFile: {
                ...state.removeUploadedFile,
                isLoading: false,
                status: APP_ACTION_STATUS.INITIAL,
                error: null,
                data: null,
              },
            };
            
          default:
            return state;
    }
}

export default linkReducer;