import React from "react";
import Stepper from "../../Shared/Stepper/Stepper";
import {
  Grid,
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { StyledTableCell, StyledTextField } from "../../../assets/theme/theme";
import style from "./SelectVehicle.module.scss";
import { CustomButton, CustomHeaderCell } from "../../Shared";
import {
  GeneralInformationDto,
  PassengerDetailsDto,
  TravelDetailsDto,
  vehicleDetailsListDto,
} from "../../../utilities/models/trip.model";
import { VehicleTypesDto } from "../../../utilities/models";
import RadioButtonUncheckedTwoToneIcon from "@mui/icons-material/RadioButtonUncheckedTwoTone";
import RadioButtonCheckedTwoToneIcon from "@mui/icons-material/RadioButtonCheckedTwoTone";

const SelectVehicle: React.FC<{
  vehicleDetails: vehicleDetailsListDto[];
  GeneralInformation: GeneralInformationDto[];
  createdTripDetails: vehicleDetailsListDto[];
  PassengerDetails: PassengerDetailsDto[];
  VehicleTypeList: VehicleTypesDto[];
  selectedVehicleType: string;
  VehicleTypeError: boolean;
  isFiltered: boolean;
  onSortHandle(col: string): void;
  onShowVehicle(): void;
  onClearFilter(): void;
  IsView:boolean;
  onFilterHandle(col: string, value: string): void;
  getFilterList: (col: string) => string[];
  onHandleInputChange(property: string, value: string): void;
  handleOnSelectVehicle(
    property: string,
    vehicleID: number,
    existingTripId: number,
  ): void;
  travelDetails: TravelDetailsDto[],
}> = (props) => {

  let count = props.GeneralInformation.length;
  
 const totalPassengerCount = props.travelDetails.reduce((acc, travelDetail) => {
    return acc + (Number(travelDetail?.passengerCount)|| 0);
  }, 0);
  

  let passengerCount = props.PassengerDetails.length;

  // const updatedTripDetails = props.createdTripDetails?.map((item) => ({
  //   ...item,
  //   seatCount: Number(item.vehicleSeatCount) - Number(item.passengerCount),
  // }));

  return (
    <Stepper stepNumber={3} stepTitle={"Select Vehicle"}>
      {count === 1 && props.createdTripDetails.length > 0 && (
        <section className={style.gridSection}>
          <TableContainer component={Paper} className={style.grid}>
            {props.isFiltered && (
              <CustomButton
                text="Clear filter"
                textColor="black"
                bgColor="#bfbfbf"
                onClick={props.onClearFilter}
              />
            )}
            <Table className={style.table}>
              <TableHead>
                <TableRow>
                  <CustomHeaderCell
                    id="selectedTripId"
                    sortable
                    onSort={props.onSortHandle}
                  >
                    SelectedTripId{" "}
                  </CustomHeaderCell>
                  <CustomHeaderCell
                    id="licensePlateNo"
                    sortable
                    onSort={props.onSortHandle}
                  >
                    License plate Number{" "}
                  </CustomHeaderCell>
                  <CustomHeaderCell
                    id="driverName"
                    onSort={props.onSortHandle}
                    sortable
                  >
                    Driver Name
                  </CustomHeaderCell>
                  <CustomHeaderCell
                    id="transportCompany"
                    sortable
                    onSort={props.onSortHandle}
                    filtered
                    getFilterList={props.getFilterList}
                    onFilter={props.onFilterHandle}
                  >
                    Transport Company
                  </CustomHeaderCell>
                  <CustomHeaderCell
                    id="vehicleSeatCount"
                    sortable
                    onSort={props.onSortHandle}
                  >
                    Maximum seating Capacity
                  </CustomHeaderCell>
                  <CustomHeaderCell
                    id="seatCount"
                    sortable
                    onSort={props.onSortHandle}
                    filtered
                    getFilterList={props.getFilterList}
                    onFilter={props.onFilterHandle}
                  >
                    Available number Seats
                  </CustomHeaderCell>
                  <CustomHeaderCell
                    id="capacity"
                    sortable
                    onSort={props.onSortHandle}
                  >
                    Maximum CBM Capacity
                  </CustomHeaderCell>
                  <CustomHeaderCell id="pickup">Select</CustomHeaderCell>
                </TableRow>
              </TableHead>
              {props.createdTripDetails &&
                props.createdTripDetails.length > 0 && (
                  <TableBody>
                    {props.createdTripDetails.map((p) => (
                      <TableRow key={p.licensePlateNo}>
                        <StyledTableCell>{p.selectedTripId}</StyledTableCell>
                        <StyledTableCell>{p.licensePlateNo}</StyledTableCell>
                        <StyledTableCell>{p.driverName}</StyledTableCell>
                        <StyledTableCell>{p.transportCompany}</StyledTableCell>
                        <StyledTableCell>{p.maxSeatCount}</StyledTableCell>
                        <StyledTableCell>{p.availableSeatCount}</StyledTableCell>
                       
                        <StyledTableCell>{p.capacity}</StyledTableCell>
                        <StyledTableCell>
                          <Box>
                            <IconButton
                              size="small"
                              onClick={() =>
                                props.handleOnSelectVehicle(
                                  "selectedTable",
                                  p.vehicleId,
                                  Number(p.selectedTripId)
                                )
                              }
                            >
                              <Tooltip title="Select">
                                {p.isSelected ? (
                                  <RadioButtonCheckedTwoToneIcon
                                    sx={{ fontSize: "20px", color: "white" }}
                                  />
                                ) : (
                                  <RadioButtonUncheckedTwoToneIcon
                                    sx={{ fontSize: "20px", color: "white" }}
                                  />
                                )}
                              </Tooltip>
                            </IconButton>
                          </Box>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              {props.createdTripDetails.length === 0 && (
                <TableBody>
                  <TableRow>
                    <StyledTableCell align="center" colSpan={9}>
                      No data to preview
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </section>
      )}

      <Grid container spacing={4}>
        <Grid item xs={12} md={6} className={style.formBox}>
          <StyledTextField
            placeholder="Required Number of seats"
            size="small"
            value={totalPassengerCount}
            label="Required Number of seats"
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} md={6} className={style.formBox}>
          <StyledTextField
            placeholder="Select Vehicle Type"
            size="small"
            select
            defaultValue="EUR"
            label="Vehicle Type"
            //  id={props.VehicleTypeList.map(p => p.id)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.onHandleInputChange("vehicleType", event.target.value)
            }
            value={props.selectedVehicleType}
            error={props.VehicleTypeError}
            required={true}
            disabled={props.IsView}
            helperText={
              props.VehicleTypeError ? "Vehicle Type  is Required" : ""
            }
          >
            {props.VehicleTypeList.map((vehicleType) => (
              <MenuItem key={vehicleType.id} value={vehicleType.id}>
                {vehicleType.description}
              </MenuItem>
            ))}
          </StyledTextField>
        </Grid>
      </Grid>
  
      <Box sx={{ display: "flex", justifyContent: "end", margin: "1rem" }}>
        <CustomButton
        disabled=  {props.IsView}
          text="Show Vehicle"
          border="1px solid #6e6e6e"
          bgColor="#282828"
          onClick={() => {
            props.onShowVehicle();
          }}
        />
      </Box>

      <section className={style.gridSection}>
        <TableContainer component={Paper} className={style.grid}>
          <Table className={style.table}>
            <TableHead>
              <TableRow>
                <CustomHeaderCell
                  id="licensePlateNo"
                  sortable
                  onSort={props.onSortHandle}
                >
                  License plate Number{" "}
                </CustomHeaderCell>
                <CustomHeaderCell
                  id="driverName"
                  onSort={props.onSortHandle}
                  sortable
                >
                  Driver Name
                </CustomHeaderCell>
                <CustomHeaderCell
                  id="transportCompany"
                  sortable
                  onSort={props.onSortHandle}
                  filtered
                  getFilterList={props.getFilterList}
                  onFilter={props.onFilterHandle}
                >
                  Transport Company
                </CustomHeaderCell>
                <CustomHeaderCell
                  id="seatCount"
                  sortable
                  onSort={props.onSortHandle}
                  filtered
                  getFilterList={props.getFilterList}
                  onFilter={props.onFilterHandle}
                >
                  Maximum Seating Capacity
                </CustomHeaderCell>
                <CustomHeaderCell
                  id="avSeatCount"
                  sortable
                  onSort={props.onSortHandle}
                >
                  Available Number of Seats
                </CustomHeaderCell>

                <CustomHeaderCell
                  id="capacity"
                  sortable
                  onSort={props.onSortHandle}
                >
                  Maximum CBM Capacity
                </CustomHeaderCell>
                <CustomHeaderCell id="Plant">Plant</CustomHeaderCell>
                <CustomHeaderCell id="SBU">SBU</CustomHeaderCell>
                <CustomHeaderCell id="select">Select</CustomHeaderCell>
              </TableRow>
            </TableHead>
            {props.vehicleDetails && props.vehicleDetails.length > 0 && (
              <TableBody>
                {props.vehicleDetails.map((p) => (
                  <TableRow key={p.licensePlateNo}>
                    <StyledTableCell>{p.licensePlateNo}</StyledTableCell>
                    <StyledTableCell>{p.driverName}</StyledTableCell>
                    <StyledTableCell>{p.transportCompany}</StyledTableCell>
                    <StyledTableCell>{p.seatCount}</StyledTableCell>
                    <StyledTableCell>
                      {Number(p.seatCount)}
                    </StyledTableCell>
                    <StyledTableCell>{p.capacity}</StyledTableCell>
                    <StyledTableCell>{p.plant}</StyledTableCell>
                    <StyledTableCell>{p.sbu}</StyledTableCell>
                    <StyledTableCell>
                      <Box>
                        <IconButton
                          size="small"
                          onClick={() =>
                            props.handleOnSelectVehicle("Table", p.vehicleId, 0)
                          }
                        >
                          <Tooltip title="Select">
                            {p.isSelected ? (
                              <RadioButtonCheckedTwoToneIcon
                                sx={{ fontSize: "20px", color: "white" }}
                              />
                            ) : (
                              <RadioButtonUncheckedTwoToneIcon
                                sx={{ fontSize: "20px", color: "white" }}
                              />
                            )}
                          </Tooltip>
                        </IconButton>
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
            {props.vehicleDetails.length === 0 && (
              <TableBody>
                <TableRow>
                  <StyledTableCell align="center" colSpan={7}>
                    No data to preview
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </section>
    </Stepper>
  );
};

export default SelectVehicle;
