import React from "react";
import Stepper from "../../Shared/Stepper/Stepper";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Grid,
  MenuItem,
} from "@mui/material";
import { StyledTableCell, StyledTextField } from "../../../assets/theme/theme";
import { CustomHeaderCell } from "../../Shared";
import style from "./GeneralInformation.module.scss";
import { GeneralInformationDto } from "../../../utilities/models/trip.model";
import moment from "moment";

const GeneralInformation: React.FC<{
  GeneralInformation: GeneralInformationDto[];
  onSortHandle(col: string): void;
  isEdit: boolean;
  allocationId: string;
  endPoints: string[];
  StartingPoints: string[];
  selectedEndPoint: string;
  selectedStartingPoint: string;
  onHandleInputChange(property: string, value: string): void;
  selectedStartingPointError: boolean;
  selectedEndPointError: boolean;
  reason: string;
}> = (props) => {
  return (
    <Stepper stepNumber={1} stepTitle={"General Information"}>
      <Grid container spacing={4}>
    
        <Grid item xs={12} md={6} className={style.formBox}>
          {props.isEdit && (
            <StyledTextField
              placeholder="Allocation ID"
              size="small"
              value={"A" + props.allocationId}
              inputProps={{ readOnly: true }}
              defaultValue="EUR"
              label="Allocation ID"
            />
          )}
        </Grid>
      </Grid>

      <section className={style.gridSection}>
        <TableContainer component={Paper} className={style.grid}>
          <Table className={style.table}>
            <TableHead>
              <TableRow>
                <CustomHeaderCell
                  id="passenger"
                  sortable
                  onSort={props.onSortHandle}
                >
                  Requested ID
                </CustomHeaderCell>
                <CustomHeaderCell
                  id="department"
                  onSort={props.onSortHandle}
                  sortable
                >
                  Created By
                </CustomHeaderCell>
                <CustomHeaderCell
                  id="pickup"
                  sortable
                  onSort={props.onSortHandle}
                >
                  Created On
                </CustomHeaderCell>
                <CustomHeaderCell
                  id="pVehicle"
                  sortable
                  onSort={props.onSortHandle}
                >
                  Preferred Vehicle
                </CustomHeaderCell>
                <CustomHeaderCell
                  id="sLocation"
                  sortable
                  onSort={props.onSortHandle}
                >
                  Starting Location
                </CustomHeaderCell>
                <CustomHeaderCell
                  id="mDestination"
                  sortable
                  onSort={props.onSortHandle}
                >
                  Main Destination
                </CustomHeaderCell>
              </TableRow>
            </TableHead>
            {props.GeneralInformation &&
              props.GeneralInformation.length > 0 && (
                <TableBody>
                  {props.GeneralInformation.map((p) => (
                    <TableRow key={p.requestId}>
                      <StyledTableCell>R{p.requestId}</StyledTableCell>
                      <StyledTableCell>{p.createdBy}</StyledTableCell>
                      <StyledTableCell>
                        {moment(p.createdOn).add(330, 'minute').format("DD-MM-YYYY h:mm A")}
                      </StyledTableCell>
                      <StyledTableCell>{p.preferredVehicle}</StyledTableCell>
                      <StyledTableCell>{p.fromLocation}</StyledTableCell>
                      <StyledTableCell>{p.toLocation}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            {props.GeneralInformation.length === 0 && (
              <TableBody>
                <TableRow>
                  <StyledTableCell align="center" colSpan={7}>
                    No data to preview
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </section>

      {props.GeneralInformation.length > 1 && (
        <Grid container spacing={4} sx={{ marginTop: "1.5rem" }}>
          <Grid item xs={12} md={6} className={style.formBox}>
            <StyledTextField
              placeholder="Select Start Location"
              size="small"
              select
              defaultValue="EUR"
              label="Start Location"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.onHandleInputChange("StartPoint", event.target.value)
              }
              value={props.selectedStartingPoint||''}
           
              error={props.selectedStartingPointError}
              required={true}
              helperText={
                props.selectedStartingPointError
                  ? " Start Location  is Required"
                  : ""
              }
            >
              {props.StartingPoints.map((StartingPoint, index) => (
                <MenuItem key={index} value={StartingPoint}>
                  {StartingPoint}
                </MenuItem>
              ))}
            </StyledTextField>
          </Grid>
          <Grid item xs={12} md={6} className={style.formBox}>
            <StyledTextField
              placeholder="Select End Location"
              size="small"
              select
              defaultValue="EUR"
              label="End Location"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.onHandleInputChange("EndPoint", event.target.value)
              }
              value={props.selectedEndPoint || ""}
              error={props.selectedEndPointError}
              required={true}
              helperText={
                props.selectedEndPointError ? " End Location  is Required" : ""
              }
            >
              {props.endPoints.map((endPoints, index) => (
                <MenuItem key={index} value={endPoints}>
                  {endPoints}
                </MenuItem>
              ))}
            </StyledTextField>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={4} sx={{ marginTop: ".2rem" }}>

        <Grid item xs={12} md={12} className={style.reason}>
          {props.isEdit && (
            <StyledTextField
              fullWidth
              placeholder="Reason"
              size="small"
              value={props.reason || ""}
              required={false}
              defaultValue="EUR"
              label="Reason-(optional)"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.onHandleInputChange("reason", event.target.value)
              }
            />
          )}
        </Grid>
      </Grid>
    </Stepper>
  );
};

export default GeneralInformation;
