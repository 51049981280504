import { AxiosResponse } from "axios"
import { driverService } from "../../services/driver.service"
import { call, put, takeEvery } from "redux-saga/effects"
import { AlertDto, AllPlantBriefDto, DriverListDto, EditDriverDto, FileDriverResponseDto, FileResponseDto, FileUploadDrivertDto, GetRequiredDocumentParmDto, 
  GetVehicleNumberParamDto, ModifyDriverDto, NewDriverPayloadDto, VehicleNumberDto, VehicleTypesDto, ViewDriverDetailsDto, getDriversSummaryParam } from "../../utilities/models"
import { ALERT_ACTION_TYPES, COMMON_ACTION_TYPES, DRIVER_ACTION_TYPE } from "../../utilities/constants"

function* viewDriver(action: { type: string, id: number }) {
  try {
    const driverResponse: AxiosResponse<ViewDriverDetailsDto> = yield call(driverService.viewDriver, action.id)
    yield put({
      type: DRIVER_ACTION_TYPE.GET_DRIVER + COMMON_ACTION_TYPES.SUCCESS,
      data: driverResponse.data
    })
  } catch (error) {
    yield put({
      type: DRIVER_ACTION_TYPE.GET_DRIVER + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* createDriver(action: { type: string, payload: NewDriverPayloadDto }) {
  try {
    const createNewDriverResponse: AxiosResponse<string> = yield call(driverService.createDriver, action.payload)

    yield put({
      type: DRIVER_ACTION_TYPE.POST_DRIVER + COMMON_ACTION_TYPES.SUCCESS,
      data: createNewDriverResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: DRIVER_ACTION_TYPE.POST_DRIVER + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* editDriver(action: { type: string, payload: EditDriverDto }) {
  try {
    const editDriverResponse: AxiosResponse<string> = yield call(driverService.editDriver, action.payload)
    
    yield put({
      type: DRIVER_ACTION_TYPE.EDIT_DRIVER + COMMON_ACTION_TYPES.SUCCESS,
      data: editDriverResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: DRIVER_ACTION_TYPE.EDIT_DRIVER + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* modifyDriverVehicle(action: { type: string, payload: ModifyDriverDto }) {
  try {
    const editDriverVehicleResponse: AxiosResponse<string> = yield call(driverService.modifyDriverVehicle, action.payload)
    
    yield put({
      type: DRIVER_ACTION_TYPE.MODIFY_DRIVER_VEHICLE + COMMON_ACTION_TYPES.SUCCESS,
      data: editDriverVehicleResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: DRIVER_ACTION_TYPE.MODIFY_DRIVER_VEHICLE + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getDrivers(action: { type: string, payload:getDriversSummaryParam }) {
  try {
    const driverResponse: AxiosResponse<DriverListDto[]> = yield call(driverService.getDrivers,  action.payload)
    yield put({
      type: DRIVER_ACTION_TYPE.GET_DRIVER_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: driverResponse.data
    })
  } catch (error) {
    yield put({
      type: DRIVER_ACTION_TYPE.GET_DRIVER_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* deleteDriver(action: { type: string, id: number }) {
  try {
    const deleteVehicleResponse: AxiosResponse<string> = yield call(driverService.deleteDriver, action.id)
    const setAlert: AlertDto = {
      message: deleteVehicleResponse.data,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: DRIVER_ACTION_TYPE.DELETE_DRIVER + COMMON_ACTION_TYPES.SUCCESS,
      data: deleteVehicleResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: DRIVER_ACTION_TYPE.DELETE_DRIVER + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function * getPlantList (action: {type: string}) {
  try {
       const plantList: AxiosResponse<AllPlantBriefDto[]> = yield call(driverService.getPlantList)
       yield put({
           type: DRIVER_ACTION_TYPE.GET_PLANT_LIST + COMMON_ACTION_TYPES.SUCCESS,
           data: plantList.data
       })
  } catch (error) {
      yield put({
          type: DRIVER_ACTION_TYPE.GET_PLANT_LIST + COMMON_ACTION_TYPES.ERROR,
          error: error
      })
  }
}

// TODO: CHANGE DTO BY LOOKING RESPONSE BODY
function * getVehicleTypesByPlant (action: {type: string, id: number}) {
  try {
       const vehicleTypes: AxiosResponse<VehicleTypesDto[]> = yield call(driverService.getVehicleTypesByPlant, action.id)
       yield put({
           type: DRIVER_ACTION_TYPE.GET_VEHICLE_TYPES_BY_PLANT + COMMON_ACTION_TYPES.SUCCESS,
           data: vehicleTypes.data
       })
  } catch (error) {
      yield put({
          type: DRIVER_ACTION_TYPE.GET_VEHICLE_TYPES_BY_PLANT + COMMON_ACTION_TYPES.ERROR,
          error: error
      })
  }
}

function * getVehicleNumbersByType (action: {type: string, param: GetVehicleNumberParamDto}) {
  try {
       const vehicleNumbers: AxiosResponse<VehicleNumberDto[]> = yield call(driverService.getVehicleNumbersByType, action.param)
       yield put({
           type: DRIVER_ACTION_TYPE.GET_VEHICLE_NUMBERS_BY_TYPE + COMMON_ACTION_TYPES.SUCCESS,
           data: vehicleNumbers.data
       })
  } catch (error) {
      yield put({
          type: DRIVER_ACTION_TYPE.GET_VEHICLE_NUMBERS_BY_TYPE + COMMON_ACTION_TYPES.ERROR,
          error: error
      })
  }
}

function * getVehicleNumbersByDriver (action: {type: string, nic: string}) {
  try {
       const vehicleNumbers: AxiosResponse<VehicleNumberDto[]> = yield call(driverService.getVehicleNumbersByDriver, action.nic)
       yield put({
           type: DRIVER_ACTION_TYPE.GET_VEHICLE_NUMBERS_BY_DRIVER + COMMON_ACTION_TYPES.SUCCESS,
           data: vehicleNumbers.data
       })
  } catch (error) {
      yield put({
          type: DRIVER_ACTION_TYPE.GET_VEHICLE_NUMBERS_BY_DRIVER + COMMON_ACTION_TYPES.ERROR,
          error: error
      })
  }
}



function* driverSaga() {
  yield takeEvery(DRIVER_ACTION_TYPE.GET_DRIVER + COMMON_ACTION_TYPES.REQUEST, viewDriver)
  yield takeEvery(DRIVER_ACTION_TYPE.POST_DRIVER + COMMON_ACTION_TYPES.REQUEST, createDriver)
  yield takeEvery(DRIVER_ACTION_TYPE.EDIT_DRIVER + COMMON_ACTION_TYPES.REQUEST, editDriver)
  yield takeEvery(DRIVER_ACTION_TYPE.MODIFY_DRIVER_VEHICLE + COMMON_ACTION_TYPES.REQUEST, modifyDriverVehicle)
  yield takeEvery(DRIVER_ACTION_TYPE.DELETE_DRIVER + COMMON_ACTION_TYPES.REQUEST, deleteDriver)
  yield takeEvery(DRIVER_ACTION_TYPE.GET_DRIVER_LIST + COMMON_ACTION_TYPES.REQUEST, getDrivers)
  yield takeEvery(DRIVER_ACTION_TYPE.GET_PLANT_LIST + COMMON_ACTION_TYPES.REQUEST, getPlantList)
  yield takeEvery(DRIVER_ACTION_TYPE.GET_VEHICLE_TYPES_BY_PLANT + COMMON_ACTION_TYPES.REQUEST, getVehicleTypesByPlant)
  yield takeEvery(DRIVER_ACTION_TYPE.GET_VEHICLE_NUMBERS_BY_TYPE + COMMON_ACTION_TYPES.REQUEST, getVehicleNumbersByType)
  yield takeEvery(DRIVER_ACTION_TYPE.GET_VEHICLE_NUMBERS_BY_DRIVER + COMMON_ACTION_TYPES.REQUEST, getVehicleNumbersByDriver)
}

export default driverSaga