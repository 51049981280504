import { AUTH_ACTION_TYPE, COMMON_ACTION_TYPES } from "../../utilities/constants"

const authorizedUser = (email:string) => {
    return {
        type: AUTH_ACTION_TYPE.AUTHORIZE_USER + COMMON_ACTION_TYPES.REQUEST,
        email: email
    }
}

const fetchAuthorizedUser = () => {
    return { type: AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER + COMMON_ACTION_TYPES.REQUEST }
}

export const authActions = {
    authorizedUser,
    fetchAuthorizedUser
}