import { COMMON_ACTION_TYPES, FUEL_ACTION_TYPES } from "../../utilities/constants"
import { GetFuelCostDetailsDto, NewFuelTypesFormDto, fuelBillDetailsDto } from "../../utilities/models/fuel.model"


const postFuelRequest = (payload: NewFuelTypesFormDto) => {
    return {
        type: FUEL_ACTION_TYPES.POST_FUEL_TYPES + COMMON_ACTION_TYPES.REQUEST,
        payload: payload
    }
}

const postFuelRequestClear = () => {
    return {
        type: FUEL_ACTION_TYPES.POST_FUEL_TYPES + COMMON_ACTION_TYPES.CLEAR,
    }
}

const getFuelTypes = () => {
    return { type: FUEL_ACTION_TYPES.GET_FUEL_TYPES + COMMON_ACTION_TYPES.REQUEST}
}

const getAllVehicleNumbers = (number: string) => {
    return { 
        type: FUEL_ACTION_TYPES.GET_VEHICLE_NUMBER  + COMMON_ACTION_TYPES.REQUEST, 
        number:number 
    };
}

const uploadFuelBill = (payload:any) => {
    return { 
        type: FUEL_ACTION_TYPES.UPLOAD_FUEL_BILL + COMMON_ACTION_TYPES.REQUEST,
        payload:payload
    }
}

const uploadFuelBillClear = () => {
    return { 
        type: FUEL_ACTION_TYPES.UPLOAD_FUEL_BILL + COMMON_ACTION_TYPES.CLEAR,
    }
}


const getFuelPrice = (typeId:number) => {
    return{
        type: FUEL_ACTION_TYPES.GET_FUEL_PRICE + COMMON_ACTION_TYPES.REQUEST,
        typeId:typeId
    }
}

const addFuelBillDetails = (payload:fuelBillDetailsDto) => {
    return {
        type: FUEL_ACTION_TYPES.ADD_FUEL_DETAILS + COMMON_ACTION_TYPES.REQUEST,
        payload:payload
    }
}

const addFuelBillDetailsClear = () => {
    return {
        type: FUEL_ACTION_TYPES.ADD_FUEL_DETAILS + COMMON_ACTION_TYPES.CLEAR,
    }
}


const getFuelCostBillDetailsList = (param: GetFuelCostDetailsDto) => {
    return {
      type: FUEL_ACTION_TYPES.GET_FUEL_COST_DETAILS + COMMON_ACTION_TYPES.REQUEST,
      param: param,
    };
  };
 
const getFuelCostBillDetailsListClear = () => {
    return {
      type: FUEL_ACTION_TYPES.GET_FUEL_COST_DETAILS + COMMON_ACTION_TYPES.CLEAR,
    };
  };

export const fuelActions = {
    postFuelRequest,
    getFuelTypes,
    getAllVehicleNumbers,
    uploadFuelBill,
    getFuelPrice,
    addFuelBillDetails,
    postFuelRequestClear,
    uploadFuelBillClear,
    addFuelBillDetailsClear,
    getFuelCostBillDetailsList,
    getFuelCostBillDetailsListClear
}
