import React, { useEffect, useState } from "react";
import { AppLayout } from "../../templates";
import ViewSummary from "../../components/RequestCreation/ViewSummary/ViewSummary";
import ViewRequests from "../../components/RequestCreation/ViewRequests/ViewRequests";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationStateDto, RequestSummaryParamDto, SelfRequestSummaryListDto, SortMetaDto,} from "../../utilities/models";
import { chartActions, mapActions, requestActions } from "../../redux/actions";
import { APP_ACTION_STATUS, APP_ROUTES, APP_TABLE_CONFIGS, REQUEST_SCREEN_EXPRESS_MODES, REQUEST_SCREEN_MODES,} from "../../utilities/constants";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { ConfirmationDialog, StatusPopup } from "../../components";
import dayjs from "dayjs";
import RequestCreationPopup from "../../components/RequestCreation/RequestCreationPopup/RequestCreationPopup";

const RequestCreation = () => {
  const INITIAL_SORT_META: SortMetaDto = {
    field: "",
    asc: false,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE);
  const [filteredList, setFilteredList] = useState<SelfRequestSummaryListDto[]>([]);
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
  const [sortMeta, setSortMeta] = useState<SortMetaDto>(INITIAL_SORT_META);
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState("");
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

  const getRequestDataResponse = useSelector((state: ApplicationStateDto) => state.request.getSelfRequestList);
  const getRequestStatusProcessResponse = useSelector((state: ApplicationStateDto) => state.request.getRequestStatusProcess);
  const deleteRequestResponse = useSelector((state: ApplicationStateDto) => state.request.deleteRequest);
  const getGUSummaryResponse = useSelector((state: ApplicationStateDto) => state.chart.getGUSummary);

  useEffect(() => {
    getRequestList(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE, 1);

    return () => {
      dispatch(requestActions.deleteRequestClear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getRequestDataResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (getRequestDataResponse.data.length > 0) {
        setFilteredList(getRequestDataResponse.data);
      } else setFilteredList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequestDataResponse.status]);

  useEffect(() => {
    if (
      getRequestStatusProcessResponse.status === APP_ACTION_STATUS.SUCCESS &&
      getRequestStatusProcessResponse.data
    ) {
      if (getRequestStatusProcessResponse.data.length > 0)
        setIsStatusDialogOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequestStatusProcessResponse.status]);

  useEffect(() => {
    if (deleteRequestResponse.status === APP_ACTION_STATUS.SUCCESS) {
      getRequestList(rowsPerPage, 1);
      setSelectedRequestId("");
      dispatch(requestActions.deleteRequestClear());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRequestResponse.status]);

  const getRequestList = async (r: number, p: number) => {
    const _param: RequestSummaryParamDto = {
      pagination: r,
      pageNumber: p,
    };
    dispatch(requestActions.getSelfRequestSummaryList(_param));
    dispatch(chartActions.getGUSummary());
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    getRequestList(rowsPerPage, newPage + 1);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    getRequestList(+event.target.value, 1);
    setPage(0);
  };

  const showStatusPopup = (con: boolean) => {
    setIsStatusDialogOpen(con);
    if (!con) {
      setSelectedRequestId("");
      dispatch(requestActions.getRequestStatusProcessClear());
    }
  };

  const onShowStatus = (id: string) => {
    setSelectedRequestId(id);
    dispatch(requestActions.getRequestStatusProcess(id));
  };

  const onSelectRequestForRemove = (id: string) => {
    setSelectedRequestId(id);
    setIsOpenConfirmation(true);
  };

  const onRemoveRequest = (con: boolean) => {
    setIsOpenConfirmation(false);
    if (con) {
      dispatch(requestActions.deleteRequest(Number(selectedRequestId)));
    }
  };

  const navigteTORequestScreen = (mode: string, id: string) => {
    sessionStorage.setItem("Mode", mode);
    sessionStorage.setItem("id", id);
    navigate(APP_ROUTES.MANAGE_REQUEST);
  };

  const onSortHandle = (col: string) => {
    const sorted = filteredList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setFilteredList(sorted);
  };

  const onClearFilter = () => {
    setIsFiltered(false);
    setFilteredList(getRequestDataResponse.data);
  };

  const onFilterHandle = async (col: string, value: any) => {
    setIsFiltered(true);
    const filtered = getRequestDataResponse.data.filter((item) => {
      const _value = (item as any)[col];
      if (typeof _value === "boolean") {
        return _value ? value === "Yes" : value === "No";
      }
      if(col === "createdDateandTime"){
        const _selectedMin = dayjs(value[0]).format('YYYY-MM-DD HH:mm')
        const _selectedMax = dayjs(value[1]).format('YYYY-MM-DD HH:mm')
        const _targetDate = dayjs(_value).add(330, 'minute').format('YYYY-MM-DD HH:mm')

        return moment(_targetDate).isBetween(_selectedMin, _selectedMax)
      }
      if (col === "departureDateandTime" || col === "returnDateandTime") {
        const _selectedMin = dayjs(value[0]).format('YYYY-MM-DD HH:mm')
        const _selectedMax = dayjs(value[1]).format('YYYY-MM-DD HH:mm')
        const _targetDate = dayjs(_value).format('YYYY-MM-DD HH:mm')

        return moment(_targetDate).isBetween(_selectedMin, _selectedMax)
      }
      if(value === 'N/A') return !_value
      return _value === value;
    });

    setFilteredList(filtered);
  };

  const getFilterList = (col: string): string[] => {
    if (
      getRequestDataResponse.status === APP_ACTION_STATUS.SUCCESS &&
      !getRequestDataResponse.isLoading
    )
      return getRequestDataResponse.data
        .map((item) => {
          const value = (item as any)[col];
          if (typeof value === "boolean") {
            return value ? "Yes" : "No";
          }
          return value ? value : 'N/A'
        })
        .filter((value, index, array) => array.indexOf(value) === index);
    else return [];
  };



const [isOpenRequestCreationPopup, setisOpenRequestCreationPopup] = useState(false);

const HandleRequestCreationPopup=()=>{
  setisOpenRequestCreationPopup(false)
}
const navigateTo=(value:string)=>{

  if(value==="ExpressRequest"){
    navigateTOExpressRequest(REQUEST_SCREEN_EXPRESS_MODES.CREATE,"")
   
  }else{
    navigteTORequestScreen(REQUEST_SCREEN_MODES.CREATE, "")
  }

}

const navigateTOExpressRequest=(mode: string,id : string)=>{
  sessionStorage.setItem("Mode",mode);
  sessionStorage.setItem("id", id);
  navigate(APP_ROUTES.MANAGE_REQUEST_EXPRESS);
}


useEffect(() => {
   
  dispatch(mapActions.AddStartMapPointClear());
  dispatch(mapActions.ADDPickUpPointsClear());
  dispatch(mapActions.AddEndMapPointClear());
  dispatch(mapActions.addDropOffPointsClear());
}, [])


  return (
    <React.Fragment>
      <AppLayout componentTitle="Request Creation">
        <section className="page-root">
          <ViewSummary getGUSummaryResponse={getGUSummaryResponse } />
          <ViewRequests
            page={page}
            rowsPerPage={rowsPerPage}
            onHandleChangePage={handleChangePage}
            onHandleChangeRowsPerPage={handleChangeRowsPerPage}
            requestDataIsLoading={getRequestDataResponse.isLoading}
            filteredList={filteredList || []}
            sortMeta={sortMeta}
            onSortHandle={onSortHandle}
            setSelectedStatus={onShowStatus}
            onFilterHandle={onFilterHandle}
            getFilterList={getFilterList}
            navigateTo={navigteTORequestScreen}
            onClearFilter={onClearFilter}
            isFiltered={isFiltered}
            onSelectRequestForRemove={onSelectRequestForRemove}
            setisOpenRequestCreationPopup={setisOpenRequestCreationPopup}
          />
          <StatusPopup
            showStatusPopup={showStatusPopup}
            isStatusDialogOpen={isStatusDialogOpen}
            selectedRequestStatusId={selectedRequestId}
            getRequestStatusProcessResponse={getRequestStatusProcessResponse}
          />
          <ConfirmationDialog
            isOpenConfirmationDialog={isOpenConfirmation}
            onCallback={onRemoveRequest}
            title="Remove Request"
            content="Do you want to remove this request ?"
          />
             <RequestCreationPopup
           OnPopUPClose={HandleRequestCreationPopup} 
          isOpenCancelAllocationPopup={isOpenRequestCreationPopup}
          navigateTo={navigateTo}
         
         />
        </section>
      </AppLayout>
    </React.Fragment>
  );
};

export default RequestCreation;
