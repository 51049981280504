import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import usePlacesAutocomplete, { Suggestion } from "use-places-autocomplete";
import { StyledTextField } from "../../../../assets/theme/theme";

const PlaceAutoComplete: React.FC<{
    ready: boolean;
    value: string;
    status: string;
    data: Suggestion[];
    setValue(val: string, shouldFetchData?: boolean): void;
    clearSuggestions(): void;
    handleInput(event: React.ChangeEvent<HTMLInputElement>): void;
    handleSelect(value: string,typeID:number): void;
    placeHolder:string;
    type:number
    required:boolean

}> = (props) => {

 


  return (
    <Autocomplete
     size="small"
     fullWidth
      freeSolo={true}
      disableClearable
      options={props.data.map((option) => option.description)}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          
          label={props.placeHolder}
          variant="outlined"
          onChange={props.handleInput}
          disabled={!props.ready}
          size="small"
          required={props.required}
        />
      )}
      renderOption={(prop, option) => (
        <li {...prop} onClick={() => props.handleSelect(option,props.type)}>
          {option}
        </li>
      )}
    />
  );
};

export default PlaceAutoComplete;
