export enum APP_ROUTES {
  ROOT = "/",
  DRIVER = "/driver-screen",

  REQUEST_CREATION = "/request-creation",
  MANAGE_REQUEST = "/request-creation/manage-request",
  MANAGE_REQUEST_EXPRESS = "/request-creation/manage-request/express",

  // GENERAL USER
  GU_MANAGE_TEMPLATES = "/manage-templates",
  GU_DASHBOARD="/gu-dashboard",

  // LINE MANAGER
  // TODO: should be change this dashboard route once finish login part
  LM_DASHBOARD = "/lm-dashboard",
  LM_REQUEST_APPROVAL = "/lm-request-approval",

  // TRANSPORT MANAGER
  // TODO: should be change this dashboard route once finish login part
  TM_DASHBOARD = '/tm-dashboard',
  TM_REQUEST_APPROVAL = '/tm-request-approval',
  TM_VEHICLE_ALLOCATION = '/vehicle-allocation',
  TM_ACCEPT_VEHICLE_ALLOCATION = '/vehicle-allocation/accept-allocation',

  VEHICLE_MANAGEMENT = '/vehicle-management',
  CREATE_VEHICLE = '/vehicle-management/create',
  VIEW_VEHICLE = '/vehicle-management/view',
  EDIT_VEHICLE = '/vehicle-management/edit',

  DRIVER_MANAGEMENT = '/driver-management',
  CREATE_DRIVER = '/driver-management/create',
  VIEW_DRIVER = '/driver-management/view',
  EDIT_DRIVER = '/driver-management/edit',

  DS_TRANSPORTATION="/daily-transport",
  DS_EXECUTION_HISTORY="/ds-execution-history",
  CREATE_DS_TRANSPORTATION="/daily-transport/create",
  EDIT_DS_TRANSPORTATION ="/daily-transport/edit",
  VIEW_DS_TRANSPORTATION ="/daily-transport/view",
  EXECUTE_DS_TRANSPORTATION = "/daily-transport/execute",
  DUPLICATE_DS_TRANSPORTATION = "/daily-transport/duplicate",

  TM_ALLOCATION_SUMMARY = "/vehicle-allocation/summary",

  MANAGE_TRANSPORT_COMPANIES = '/Manage-transport-compnaies',

  MANAGE_TRANSPORT_COMPANY='/Manage-transport-companies',
  ADD_NEW_TRANSPORT_COMPANY='/Manage-transport-companies/add-new_company',
  
  TRANSPORT_COMPANY = "/transport-company",
  ENTITLEMET_CREATION = "/entitlement-creation",
  MANAGE_ENTITLEMENT = "/entitlement-creation/manage-entitlement",
  EDIT_ENTITLEMENT = "/entitlement-creation/edit-entitlement",
  PROFILE_VIEW = '/profile-view',
  CREATE_COMPANY = '/Manage-transport-compnaies/create',
  VIEW_COMPANY = '/Manage-transport-compnaies/view',
  EDIT_COMPANY = '/Manage-transport-compnaies/edit',

  FUEL_REPORT = "/fuel-report",
  ADD_FUEL_PRICE = "/fuel-report/fuel-price",
  ADD_FUEL_PRICE_BILL = "/fuel-report/fuel-price/fuel-bill",
}
