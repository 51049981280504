import { call, put, takeEvery } from "redux-saga/effects"
import { APPROVER_ACTION_TYPE, COMMON_ACTION_TYPES } from "../../utilities/constants"
import { ApproverBriefDto, DefaultApproverDto } from "../../utilities/models"
import { approverService } from "../../services/approver.service"
import { AxiosResponse } from "axios"

function* getApprovers(action: { type: string, param: string }) {
  try {
    const approvers: AxiosResponse<ApproverBriefDto[]> = yield call(approverService.getApprovers, action.param)
    yield put({
      type: APPROVER_ACTION_TYPE.GET_ALL_APPROVERS_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: approvers.data
    })
  } catch (error) {
    yield put({
      type: APPROVER_ACTION_TYPE.GET_ALL_APPROVERS_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}
function* getDefaultApprover(action: { type: string, depId: number }) {
  try {
    const approver: AxiosResponse<DefaultApproverDto> = yield call(approverService.getDefaultApprover, action.depId)
    yield put({
      type: APPROVER_ACTION_TYPE.GET_DEFAULT_APPROVER + COMMON_ACTION_TYPES.SUCCESS,
      data: approver.data
    })
  } catch (error) {
    yield put({
      type: APPROVER_ACTION_TYPE.GET_DEFAULT_APPROVER + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* approverSaga() {
  yield takeEvery(APPROVER_ACTION_TYPE.GET_ALL_APPROVERS_LIST + COMMON_ACTION_TYPES.REQUEST, getApprovers)
  yield takeEvery(APPROVER_ACTION_TYPE.GET_DEFAULT_APPROVER + COMMON_ACTION_TYPES.REQUEST, getDefaultApprover)
}

export default approverSaga