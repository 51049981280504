import { call, put, takeEvery } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  CHART_ACTION_TYPES,
  COMMON_ACTION_TYPES,
} from "../../utilities/constants";
import { chartService } from "../../services/chart.service";
import {
  GuSummaryBoxDetails,
  LmChartDto,
  LmSummaryBudgetDto,
  TmChartDto,
} from "../../utilities/models";

function* getLMSummary(action: { type: string }) {
  try {
    const LMSummary: AxiosResponse<LmChartDto> = yield call(
      chartService.getLMSummary
    );
    yield put({
      type: CHART_ACTION_TYPES.GET_LM_SUMMARY + COMMON_ACTION_TYPES.SUCCESS,
      data: LMSummary.data,
    });
  } catch (error) {
    yield put({
      type: CHART_ACTION_TYPES.GET_LM_SUMMARY + COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* getGUSummary(action: { type: string }) {
  try {
    const GUSummary: AxiosResponse<GuSummaryBoxDetails[]> = yield call(
      chartService.getGUSummary
    );
    yield put({
      type: CHART_ACTION_TYPES.GET_GU_SUMMARY + COMMON_ACTION_TYPES.SUCCESS,
      data: GUSummary.data,
    });
  } catch (error) {
    yield put({
      type: CHART_ACTION_TYPES.GET_GU_SUMMARY + COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* getTMSummary(action: { type: string }) {
  try {
    const TMSummary: AxiosResponse<TmChartDto> = yield call(
      chartService.getTMSummary
    );
    yield put({
      type: CHART_ACTION_TYPES.GET_TM_SUMMARY + COMMON_ACTION_TYPES.SUCCESS,
      data: TMSummary.data,
    });
  } catch (error) {
    yield put({
      type: CHART_ACTION_TYPES.GET_TM_SUMMARY + COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* getLMBudgetSummary(action: { type: string }) {
  try {
    const lmBudgetSummary : AxiosResponse<LmSummaryBudgetDto[]> = yield call(chartService.getLMBudgetSummary);
    yield put({
      type: CHART_ACTION_TYPES.GET_LM_BUDGET_SUMMARY + COMMON_ACTION_TYPES.SUCCESS,
      data: lmBudgetSummary.data,
    });
  } catch (error) {
    yield put({
      type:
      CHART_ACTION_TYPES.GET_LM_BUDGET_SUMMARY + COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* chartSaga() {
  yield takeEvery(
    CHART_ACTION_TYPES.GET_LM_SUMMARY + COMMON_ACTION_TYPES.REQUEST,
    getLMSummary
  );
  yield takeEvery(
    CHART_ACTION_TYPES.GET_GU_SUMMARY + COMMON_ACTION_TYPES.REQUEST,
    getGUSummary
  );
  yield takeEvery(
    CHART_ACTION_TYPES.GET_TM_SUMMARY + COMMON_ACTION_TYPES.REQUEST,
    getTMSummary
  );
  yield takeEvery(
    CHART_ACTION_TYPES.GET_LM_BUDGET_SUMMARY + COMMON_ACTION_TYPES.REQUEST,
    getLMBudgetSummary
  );
}

export default chartSaga;
