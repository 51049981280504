import { AlertDto, DateRangeDto, DateRangeParmDto, DepartmentBriefDto, LMRequestApprovalParamDto, LMRequestSummaryListDto, PlantBriefDto, RequestByIdDto, RequestStatusProcessDto, RequestSummaryParamDto, SbuBriefDto, SelfRequestSummaryListDto, TMRequestApprovalParamDto, TmRequestSummaryParamDto, filterationParam, requestHistory } from '../../utilities/models';
import { call, put, takeEvery } from 'redux-saga/effects'
import { ALERT_ACTION_TYPES, COMMON_ACTION_TYPES, REQUEST_ACTION_TYPE } from '../../utilities/constants';
import { AxiosResponse } from 'axios';
import { requestService } from '../../services/request.service';

function* createNewReques(action: { type: string, payload: any }) {
  try {
    const createNewRequestResponse: AxiosResponse<string> = yield call(requestService.createNewRequest, action.payload)
    const setAlert: AlertDto = {
      message: createNewRequestResponse.data,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: REQUEST_ACTION_TYPE.POST_REQUEST + COMMON_ACTION_TYPES.SUCCESS,
      data: createNewRequestResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: REQUEST_ACTION_TYPE.POST_REQUEST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* createNewReccurentRequest(action: { type: string, payload: any }) {
  try {
    const createNewRequestResponse: AxiosResponse<string> = yield call(requestService.createNewReccurentRequest, action.payload)
    const setAlert: AlertDto = {
      message: createNewRequestResponse.data,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: REQUEST_ACTION_TYPE.POST_RECCURRENT_REQUEST + COMMON_ACTION_TYPES.SUCCESS,
      data: createNewRequestResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: REQUEST_ACTION_TYPE.POST_RECCURRENT_REQUEST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* editReques(action: { type: string, payload: any }) {
  try {
    const editRequestResponse: AxiosResponse<string> = yield call(requestService.editRequest, action.payload)
    const setAlert: AlertDto = {
      message: "Request updated successfully",
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: REQUEST_ACTION_TYPE.EDIT_REQUEST + COMMON_ACTION_TYPES.SUCCESS,
      data: editRequestResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: REQUEST_ACTION_TYPE.EDIT_REQUEST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* deleteRequest(action: { type: string, id: number }) {
  try {
    const deleteRequestResponse: AxiosResponse<string> = yield call(requestService.deleteRequest, action.id)
    const setAlert: AlertDto = {
      message: "Request deleted successfully",
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: REQUEST_ACTION_TYPE.DELETE_REQUEST + COMMON_ACTION_TYPES.SUCCESS,
      data: deleteRequestResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: REQUEST_ACTION_TYPE.DELETE_REQUEST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getRequestBYId(action: {type: string, requestId: string}) {
  try {
    const request: AxiosResponse<RequestByIdDto> = yield call(requestService.getRequestById, action.requestId)
    yield put({
      type: REQUEST_ACTION_TYPE.GET_REQUEST + COMMON_ACTION_TYPES.SUCCESS,
      data: request.data
    })
  } catch (error) {
    yield put({
      type: REQUEST_ACTION_TYPE.GET_REQUEST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getSelfRequestSummaryList(action: { type: string, param: RequestSummaryParamDto }) {
  try {
    const requestList: AxiosResponse<SelfRequestSummaryListDto[]> = yield call(requestService.getSelfRequestSummaryList, action.param)
    yield put({
      type: REQUEST_ACTION_TYPE.GET_SELF_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: requestList.data
    })
  } catch (error) {
    yield put({
      type: REQUEST_ACTION_TYPE.GET_SELF_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getLMRequestSummaryList(action: { type: string, param: RequestSummaryParamDto }) {
  try {
    const requestList: AxiosResponse<LMRequestSummaryListDto[]> = yield call(requestService.getLMRequestSummaryList, action.param)
    yield put({
      type: REQUEST_ACTION_TYPE.GET_LM_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: requestList.data
    })
  } catch (error) {
    yield put({
      type: REQUEST_ACTION_TYPE.GET_LM_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getLMRequestSummaryFilters(action: { type: string, param:filterationParam}) {
  try {
    const filterList: AxiosResponse<any[]> = yield call(requestService.getLMRequestSummaryFilters, action.param)
    yield put({
      type: REQUEST_ACTION_TYPE.GET_LM_REQUEST_SUMMARY_FILTERS + COMMON_ACTION_TYPES.SUCCESS,
      data: filterList.data
    })
  } catch (error) {
    yield put({
      type: REQUEST_ACTION_TYPE.GET_LM_REQUEST_SUMMARY_FILTERS + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getTMRequestSummaryList(action: { type: string, param: TmRequestSummaryParamDto}) {
  try {
    const requestList: AxiosResponse<LMRequestSummaryListDto[]> = yield call(requestService.getTMRequestSummaryList, action.param)
    yield put({
      type: REQUEST_ACTION_TYPE.GET_TM_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: requestList.data
    })
  } catch (error) {
    yield put({
      type: REQUEST_ACTION_TYPE.GET_TM_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getTMRequestSummaryFilters(action: { type: string, param:filterationParam}) {
  try {
    const filterList: AxiosResponse<any[]> = yield call(requestService.getTMRequestSummaryFilters, action.param)
    yield put({
      type: REQUEST_ACTION_TYPE.GET_TM_REQUEST_SUMMARY_FILTERS + COMMON_ACTION_TYPES.SUCCESS,
      data: filterList.data
    })
  } catch (error) {
    yield put({
      type: REQUEST_ACTION_TYPE.GET_TM_REQUEST_SUMMARY_FILTERS + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getSbuList(action: {type: string}) {
  try {
    const sbuList: AxiosResponse<SbuBriefDto[]> = yield call(requestService.getSbuList)
    yield put({
      type: REQUEST_ACTION_TYPE.GET_SBU_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: sbuList.data
    })
  } catch (error) {
    yield put({
      type: REQUEST_ACTION_TYPE.GET_SBU_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}
function* getPlantListBySbuId(action: {type: string, id: number}) {
  try {
    const list: AxiosResponse<PlantBriefDto[]> = yield call(requestService.getPlantListBySbuId, action.id)
    yield put({
      type: REQUEST_ACTION_TYPE.GET_PLANT_LIST_BY_SBU_ID + COMMON_ACTION_TYPES.SUCCESS,
      data: list.data
    })
  } catch (error) {
    yield put({
      type: REQUEST_ACTION_TYPE.GET_PLANT_LIST_BY_SBU_ID + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}
function* getDepartmentListByPlantId(action: {type: string, id: number}) {
  try {
    const list: AxiosResponse<DepartmentBriefDto[]> = yield call(requestService.getDepartmentListByPlantId, action.id)
    yield put({
      type: REQUEST_ACTION_TYPE.GET_DEPARTMENT_LIST_BY_PLANT_ID + COMMON_ACTION_TYPES.SUCCESS,
      data: list.data
    })
  } catch (error) {
    yield put({
      type: REQUEST_ACTION_TYPE.GET_DEPARTMENT_LIST_BY_PLANT_ID + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}
function* getRequestStatusProcess(action: {type: string, id: string}) {
  try {
    const list: AxiosResponse<RequestStatusProcessDto[]> = yield call(requestService.getRequestStatusProcess, action.id)
    yield put({
      type: REQUEST_ACTION_TYPE.GET_REQUEST_STATUS_PROCESS + COMMON_ACTION_TYPES.SUCCESS,
      data: list.data
    })
  } catch (error) {
    yield put({
      type: REQUEST_ACTION_TYPE.GET_REQUEST_STATUS_PROCESS + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* lineManagerApproval(action: { type: string, payload: LMRequestApprovalParamDto }) {
  try {
    const lineManagerApprovalResponse: AxiosResponse<string> = yield call(requestService.lineManagerApproval, action.payload)
    const setAlert: AlertDto = {
      message: lineManagerApprovalResponse.data,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: REQUEST_ACTION_TYPE.LM_APPROVAL + COMMON_ACTION_TYPES.SUCCESS,
      data: lineManagerApprovalResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: REQUEST_ACTION_TYPE.LM_APPROVAL + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* transportManagerApproval(action: { type: string, payload: TMRequestApprovalParamDto }) {
  try {
    const transportManagerApprovalResponse: AxiosResponse<string> = yield call(requestService.transportManagerApproval, action.payload)
    const setAlert: AlertDto = {
      message: transportManagerApprovalResponse.data,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: REQUEST_ACTION_TYPE.TM_APPROVAL + COMMON_ACTION_TYPES.SUCCESS,
      data: transportManagerApprovalResponse.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: REQUEST_ACTION_TYPE.TM_APPROVAL + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* getRequestHistory(action: { type: string, payload:DateRangeParmDto}) {
  try {
    const RequestHistory: AxiosResponse<requestHistory[]> = yield call(requestService.getRequestHistory, action.payload)
    yield put({
      type: REQUEST_ACTION_TYPE.GET_REQUEST_HISTORY + COMMON_ACTION_TYPES.SUCCESS,
      data: RequestHistory.data
    })
  } catch (error) {
    yield put({
      type: REQUEST_ACTION_TYPE.GET_REQUEST_HISTORY + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}
function* requestSaga() {
  yield takeEvery(REQUEST_ACTION_TYPE.POST_REQUEST + COMMON_ACTION_TYPES.REQUEST, createNewReques)
  yield takeEvery(REQUEST_ACTION_TYPE.POST_RECCURRENT_REQUEST + COMMON_ACTION_TYPES.REQUEST, createNewReccurentRequest)
  yield takeEvery(REQUEST_ACTION_TYPE.EDIT_REQUEST + COMMON_ACTION_TYPES.REQUEST, editReques)
  yield takeEvery(REQUEST_ACTION_TYPE.DELETE_REQUEST + COMMON_ACTION_TYPES.REQUEST, deleteRequest)
  yield takeEvery(REQUEST_ACTION_TYPE.GET_REQUEST + COMMON_ACTION_TYPES.REQUEST, getRequestBYId)
  yield takeEvery(REQUEST_ACTION_TYPE.GET_SELF_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.REQUEST, getSelfRequestSummaryList)
  yield takeEvery(REQUEST_ACTION_TYPE.GET_LM_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.REQUEST, getLMRequestSummaryList)
  yield takeEvery(REQUEST_ACTION_TYPE.GET_LM_REQUEST_SUMMARY_FILTERS + COMMON_ACTION_TYPES.REQUEST, getLMRequestSummaryFilters)
  yield takeEvery(REQUEST_ACTION_TYPE.GET_TM_REQUEST_SUMMARY_LIST + COMMON_ACTION_TYPES.REQUEST, getTMRequestSummaryList)
  yield takeEvery(REQUEST_ACTION_TYPE.GET_TM_REQUEST_SUMMARY_FILTERS + COMMON_ACTION_TYPES.REQUEST, getTMRequestSummaryFilters)
  yield takeEvery(REQUEST_ACTION_TYPE.GET_SBU_LIST + COMMON_ACTION_TYPES.REQUEST, getSbuList)
  yield takeEvery(REQUEST_ACTION_TYPE.GET_PLANT_LIST_BY_SBU_ID + COMMON_ACTION_TYPES.REQUEST, getPlantListBySbuId)
  yield takeEvery(REQUEST_ACTION_TYPE.GET_DEPARTMENT_LIST_BY_PLANT_ID + COMMON_ACTION_TYPES.REQUEST, getDepartmentListByPlantId)
  yield takeEvery(REQUEST_ACTION_TYPE.GET_REQUEST_STATUS_PROCESS + COMMON_ACTION_TYPES.REQUEST, getRequestStatusProcess)
  yield takeEvery(REQUEST_ACTION_TYPE.LM_APPROVAL + COMMON_ACTION_TYPES.REQUEST, lineManagerApproval)
  yield takeEvery(REQUEST_ACTION_TYPE.TM_APPROVAL + COMMON_ACTION_TYPES.REQUEST, transportManagerApproval)
  yield takeEvery(REQUEST_ACTION_TYPE.GET_REQUEST_HISTORY + COMMON_ACTION_TYPES.REQUEST, getRequestHistory)
}

export default requestSaga