import {
  CHART_ACTION_TYPES,
  COMMON_ACTION_TYPES,
} from "../../utilities/constants";

const getLMSummary = () => {
  return {
    type: CHART_ACTION_TYPES.GET_LM_SUMMARY + COMMON_ACTION_TYPES.REQUEST,
  };
};

const getGUSummary = () => {
  return {
    type: CHART_ACTION_TYPES.GET_GU_SUMMARY + COMMON_ACTION_TYPES.REQUEST,
  };
};

const getTMSummary = () => {
  return {
    type: CHART_ACTION_TYPES.GET_TM_SUMMARY + COMMON_ACTION_TYPES.REQUEST,
  };
};

const getLMBudgetSummary = () => {
  return {
    type:
      CHART_ACTION_TYPES.GET_LM_BUDGET_SUMMARY + COMMON_ACTION_TYPES.REQUEST,
  };
};

export const chartActions = {
  getLMSummary,
  getGUSummary,
  getTMSummary,
  getLMBudgetSummary,
};
