import React from 'react'
import Stepper from '../../Shared/Stepper/Stepper'
import style from "./DocumentInformation.module.scss"
import { Box, Button, Grid, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@mui/material";
import { DateObject } from 'react-multi-date-picker'
import { AppSkeleton, CustomDatePicker } from '../../Shared'
import CloudDownloadIcon from '@mui/icons-material/CloudDownloadRounded';
import CloudUploadIcon from '@mui/icons-material/CloudUploadRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { CustomAutocomplete, CustomButton, CustomHeaderCell } from '../../Shared'
import { StyledSwitch, StyledTableCell, StyledTextField } from '../../../assets/theme/theme'
import { EditOutlined } from '@mui/icons-material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PublishIcon from '@mui/icons-material/Publish';
import { CompanyRegistrationFormDto, OtherDocumentFormDto, ConfirmationOfAccountInformationFormDto, newDocumentDetailsDto, StateObjectDto, GetCompanyDetailsById } from '../../../utilities/models'
import { DOCUMENT_TYPE, DRIVERDOCUMENT, TRANSPORT_COMPANY_DOCUMENT, TRANSPORT_COMPANY_MODE } from '../../../utilities/constants';
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import CustomCircularProgress from '../../Shared/CustomCircularProgress/CustomCircularProgress';
const DocumentInformation: React.FC<{
  DocumentDetails: newDocumentDetailsDto[]
  handleInputFocus(property: string, section: string): void;
  onInputHandleChange(property: string, value: any): void;
  helperText: boolean
  CompanyRegistrationForm: CompanyRegistrationFormDto
  otherdocumentForm: OtherDocumentFormDto
  COAIformationForm: ConfirmationOfAccountInformationFormDto
  onAddDocuments(documentId: number): void;
  onSelectDocument(id: number, action: string): void
  handnleFileChangeFromActions(property: string, value: number): void
  screenMode: string,
  getTransportCompanyByIDResponse: StateObjectDto<GetCompanyDetailsById | null>
  RegistrationFIleResponse:StateObjectDto<any>
  BankFilefileRespose:StateObjectDto<any>
  OtherDockResponse:StateObjectDto<any>
}> = (props) => {
  const registeredDate = props.CompanyRegistrationForm.registeredDate
  const companyRegisterNumber = props.CompanyRegistrationForm.companyRegisterNumber
  const companyRegisteredFileUploadUrl = props.CompanyRegistrationForm.companyRegisteredFileUploadUrl

  const AccountNumber = props.COAIformationForm.AccountNumber
  const COAInformationFileUploadUrl = props.COAIformationForm.COAInformationFileUploadUrl
  const issuedDate = props.COAIformationForm.issuedDate

  const documentCategory = props.otherdocumentForm.documentCategory
  const OtherDocumentFileUploadUrl = props.otherdocumentForm.OtherDocumentFileUploadUrl
  const issuedDateOtherDoc = props.otherdocumentForm.issuedDateOtherDoc
  const expiryDateOtherDoc = props.otherdocumentForm.expiryDateOtherDoc
  return (
    <Stepper stepNumber={4} stepTitle={"Document Information"}>
      {props.getTransportCompanyByIDResponse && !props.getTransportCompanyByIDResponse.isLoading && (
        <>
          <Grid container spacing={2.5}>

            <Grid item xs={12} md={12}>
              Company Registration Form 1*
            </Grid>
            <Grid item xs={4} md={3}>
              <StyledTextField
                fullWidth
                label="Company Registration Number"
                placeholder='Enter Company Registration Number'
                size='small'
                InputProps={{
                  readOnly: companyRegisterNumber.readonly
                }}
                value={companyRegisterNumber.value}
                error={!!companyRegisterNumber.error}
                disabled={companyRegisterNumber.disable}
                required={companyRegisterNumber.isRequired}
                helperText={props.helperText && companyRegisterNumber.error}
                onFocus={() => props.handleInputFocus('companyRegisterNumber', 'CD')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('companyRegisterNumber', event.target.value)}
              />
            </Grid>   <Grid item xs={4} md={3}>
              <CustomDatePicker
                label="Registered date"
                placeholder="Select Registered date"
                multiple={false}
                value={registeredDate.value}
                maxDate={new Date()}
                // minDate={new Date()}
                error={!!registeredDate.error}
                disabled={registeredDate.disable}
                readOnly={registeredDate.readonly}
                required={registeredDate.isRequired}
                helperText={props.helperText && registeredDate.error}
                onFocus={() => props.handleInputFocus("registeredDate", "CD")}
                onChange={(value: any) =>
                  props.onInputHandleChange("registeredDate", value)
                }
              />
            </Grid>
            {props.screenMode !== TRANSPORT_COMPANY_MODE.VIEW &&
              <Grid item xs={1} sx={{ display: "none" }}>
                <input
                  type="file"
                  required
                  accept=".pdf"
                  id="RegistrationFile"
                  style={{ display: 'none' }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('RegistrationFile', event.target.files?.[0])}
                  onClick={(event: any) => { 
                    event.target.value = null
                  }}
                /></Grid>}
            <Grid item xs={3} md={6}>
              <Box sx={{ display: "flex" }}>
                <Grid item xs={12} md={4}>
                  <label htmlFor="RegistrationFile">
                    <LoadingButton style={{ color: "white" }} loading={props.RegistrationFIleResponse.isLoading} loadingIndicator={<CustomCircularProgress />} component="span">
                        Select File
                      </LoadingButton>
                  </label>
                </Grid>
                <Grid item xs={12} md={6}>
                  {props.CompanyRegistrationForm.companyRegisteredFileUploadUrl.value !== "" && <Box sx={{ display: "flex" }}>
                    <StyledTextField
                      fullWidth
                      label="File Attached"
                      placeholder="Company Registration File"
                      size="small"
                      InputProps={{
                        readOnly: companyRegisteredFileUploadUrl.readonly,
                      }}
                      value={companyRegisteredFileUploadUrl.value}
                      error={!!companyRegisteredFileUploadUrl.error}
                      disabled={false}
                      required={companyRegisteredFileUploadUrl.isRequired}
                      helperText={props.helperText && companyRegisteredFileUploadUrl.error}
                      onFocus={() => props.handleInputFocus("nicFileName", "nic")}

                    // onChange={(event: React.ChangeEvent<HTMLInputElement>) =>

                    //     props.onInputHandleChange("nicFileName", event.target.value)

                    // }

                    />
                  </Box>}
                </Grid>
                {props.screenMode !== TRANSPORT_COMPANY_MODE.VIEW &&
                  <Grid item xs={12} md={6}>
                    <div className='layout-row'>
                      <CustomButton text='Clear' textColor='black' bgColor='#bfbfbf' onClick={() => { props.handnleFileChangeFromActions("delete", TRANSPORT_COMPANY_DOCUMENT.REGISTRATION_DOCUMENT)  }} />
                      <CustomButton text='Submit' textColor='black' bgColor='#bfbfbf' onClick={() => { props.onAddDocuments(TRANSPORT_COMPANY_DOCUMENT.REGISTRATION_DOCUMENT)}} />
                    </div>
                  </Grid>
                }
              </Box>

            </Grid>


            <Grid item xs={12} md={12}>
              Confirmation of Account Information*
            </Grid>
            <Grid item xs={4} md={3}>
              <StyledTextField
                fullWidth
                label="Account number(s)"
                placeholder='Enter Account number(s)'
                size='small'
                InputProps={{
                  readOnly: AccountNumber.readonly
                }}
                value={AccountNumber.value}
                error={!!AccountNumber.error}
                disabled={AccountNumber.disable}
                required={AccountNumber.isRequired}
                helperText={props.helperText && AccountNumber.error}
                onFocus={() => props.handleInputFocus('AccountNumber', 'CD')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('AccountNumber', event.target.value)}
              />
            </Grid>   <Grid item xs={4} md={3}>
              <CustomDatePicker
                label=" Issued Date"
                placeholder="Select  Issued Date"
                multiple={false}
                value={issuedDate.value}
                maxDate={new Date()}
                error={!!issuedDate.error}
                disabled={issuedDate.disable}
                readOnly={issuedDate.readonly}
                required={issuedDate.isRequired}
                helperText={props.helperText && issuedDate.error}
                onFocus={() => props.handleInputFocus("issuedDate", "CD")}
                onChange={(value: DateObject) =>
                  props.onInputHandleChange("issuedDate", value)
                }
              />
            </Grid>
            {props.screenMode !== TRANSPORT_COMPANY_MODE.VIEW &&
              <Grid item xs={1} sx={{ display: "none" }}>
                <input
                  type="file"
                  required
                  accept='.pdf'
                  id="COAInformationFile"
                  style={{ display: 'none' }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('COAInformationFile', event.target.files?.[0])}
                  onClick={(event: any) => { 
                    event.target.value = null
                  }}
                /></Grid>}
            <Grid item xs={3} md={6}>
              <Box sx={{ display: "flex" }}>
                <Grid item xs={12} md={4}>
                  <label htmlFor="COAInformationFile">
                     <LoadingButton style={{ color: "white" }} loading={props.BankFilefileRespose.isLoading} loadingIndicator={<CustomCircularProgress />} component="span">
                        Select File
                      </LoadingButton>
                  </label>
                </Grid>
                <Grid item xs={12} md={6}>
                  {COAInformationFileUploadUrl.value !== "" && <Box sx={{ display: "flex" }}>
                    <StyledTextField
                      fullWidth
                      label="File Attached"
                      placeholder="Confirmation of Account Information File"
                      size="small"
                      InputProps={{
                        readOnly: COAInformationFileUploadUrl.readonly,
                      }}
                      value={COAInformationFileUploadUrl.value}
                      error={!!COAInformationFileUploadUrl.error}
                      disabled={false}
                      required={COAInformationFileUploadUrl.isRequired}
                      helperText={props.helperText && COAInformationFileUploadUrl.error}
                      onFocus={() => props.handleInputFocus("ConfirmationAccountFile", "CD")}

                    // onChange={(event: React.ChangeEvent<HTMLInputElement>) =>

                    //     props.onInputHandleChange("nicFileName", event.target.value)

                    // }

                    />
                  </Box>}
                </Grid>
                {props.screenMode !== TRANSPORT_COMPANY_MODE.VIEW &&
                  <Grid item xs={12} md={6}>
                    <div className='layout-row'>
                      <CustomButton text='Clear' textColor='black' bgColor='#bfbfbf' onClick={() => { props.handnleFileChangeFromActions("delete", TRANSPORT_COMPANY_DOCUMENT.BANK_DOCUMENT)  }} />
                      <CustomButton text='Submit' textColor='black' bgColor='#bfbfbf' onClick={() => { props.onAddDocuments(TRANSPORT_COMPANY_DOCUMENT.BANK_DOCUMENT) }} />
                    </div>
                  </Grid>}
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              Other documents
            </Grid>
            <Grid item xs={4} md={2}>
              <StyledTextField
                fullWidth
                label="Document category*"
                placeholder='Enter Document category '
                size='small'
                InputProps={{
                  readOnly: documentCategory.readonly
                }}
                value={documentCategory.value}
                error={!!documentCategory.error}
                disabled={documentCategory.disable}
                required={documentCategory.isRequired}
                helperText={props.helperText && documentCategory.error}
                onFocus={() => props.handleInputFocus('documentCategory', 'CD')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('documentCategory', event.target.value)}
              />
            </Grid>   <Grid item xs={4} md={2}>
              <CustomDatePicker
                label="Issued Date"
                placeholder="Select Issued Date"
                multiple={false}
                value={issuedDateOtherDoc.value}
                maxDate={new Date()}
                error={!!issuedDateOtherDoc.error}
                disabled={issuedDateOtherDoc.disable}
                readOnly={issuedDateOtherDoc.readonly}
                required={issuedDateOtherDoc.isRequired}
                helperText={props.helperText && issuedDateOtherDoc.error}
                onFocus={() => props.handleInputFocus("issuedDateOtherDoc", "CD")}
                onChange={(value: DateObject) =>
                  props.onInputHandleChange("issuedDateOtherDoc", value)
                }
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <CustomDatePicker
                label="Expiry Date"
                placeholder="Select Expiry Date"
                multiple={false}
                value={expiryDateOtherDoc.value}
                minDate={issuedDateOtherDoc.value}
                error={!!expiryDateOtherDoc.error}
                disabled={expiryDateOtherDoc.disable}
                readOnly={expiryDateOtherDoc.readonly}
                required={expiryDateOtherDoc.isRequired}
                helperText={props.helperText && expiryDateOtherDoc.error}
                onFocus={() => props.handleInputFocus("expiryDateOtherDoc", "CD")}
                onChange={(value: DateObject) =>
                  props.onInputHandleChange("expiryDateOtherDoc", value)
                }
              />
            </Grid>
            {props.screenMode !== TRANSPORT_COMPANY_MODE.VIEW &&
              <Grid item xs={1} sx={{ display: "none" }}>
                <input
                  type="file"
                  required
                  accept='.pdf'
                  id="OtherDoc"
                  style={{ display: 'none' }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
                    props.onInputHandleChange('OtherDocumentFile', event.target.files?.[0])
                  }
                  onClick={(event: any) => { 
                    event.target.value = null
                  }}
                /></Grid>}
            <Grid item xs={3} md={6}>
              <Box sx={{ display: "flex" }}>

                <Grid item xs={12} md={4}>
                  <label htmlFor="OtherDoc">
                  <LoadingButton style={{ color: "white" }} loading={props.OtherDockResponse.isLoading} loadingIndicator={<CustomCircularProgress />} component="span">
                        Select File
                      </LoadingButton>
                  </label>
                </Grid>
                <Grid item xs={12} md={6}>
                  {OtherDocumentFileUploadUrl.value !== "" && <Box sx={{ display: "flex" }}>
                    <StyledTextField
                      fullWidth
                      label="File Attached"
                      placeholder="Enter driver NIC"
                      size="small"
                      InputProps={{
                        readOnly: OtherDocumentFileUploadUrl.readonly,
                      }}
                      value={OtherDocumentFileUploadUrl.value}
                      error={!!OtherDocumentFileUploadUrl.error}
                      disabled={false}
                      required={OtherDocumentFileUploadUrl.isRequired}
                      helperText={props.helperText && OtherDocumentFileUploadUrl.error}
                      onFocus={() => props.handleInputFocus("OtherDocumentFile", "CD")}

                    // onChange={(event: React.ChangeEvent<HTMLInputElement>) =>

                    //     props.onInputHandleChange("nicFileName", event.target.value)

                    // }

                    />
                  </Box>}
                </Grid>
                {props.screenMode !== TRANSPORT_COMPANY_MODE.VIEW &&
                  <Grid item xs={12} md={6}>
                    <div className='layout-row'>
                      <CustomButton text='Clear' textColor='black' bgColor='#bfbfbf' onClick={() => { props.handnleFileChangeFromActions("delete", TRANSPORT_COMPANY_DOCUMENT.OTHER_DOCUMENTS)}} />
                      <CustomButton text='Submit' textColor='black' bgColor='#bfbfbf' onClick={() => { props.onAddDocuments(TRANSPORT_COMPANY_DOCUMENT.OTHER_DOCUMENTS) }} />
                    </div>
                  </Grid>}
              </Box>

            </Grid>

            <Grid item xs={12} md={12}>


              <section className={style.gridSection}>
                <div className={style.gridHeader}>

                  <Box sx={{ flexGrow: 1, alignItems: "end", right: 0 }}>
                    <div className={style.layoutrow}>
                      {/* <CustomButton text='CLEAR PAYMENT DETAILS  'textColor='black' bgColor='#bfbfbf' onClick={() => props.PaymentDetailsFormClear()} />
        <CustomButton text='ADD PAYMENT DETAILS' onClick={() => props.onpush()} /> */}
                    </div>
                  </Box>
                </div>
                <TableContainer component={Paper} className={style.grid}>
                  <Table className={style.table}>
                    <TableHead>
                      <TableRow>
                        <CustomHeaderCell id='requestID' width={240}  >Document Category</CustomHeaderCell>
                        <CustomHeaderCell id='sbu' width={180}  > Document Version </CustomHeaderCell>
                        {/* <CustomHeaderCell id='plant' width={180} > Document Number </CustomHeaderCell> */}
                        <CustomHeaderCell id='pickup' width={250}  >Issued Date </CustomHeaderCell>
                        <CustomHeaderCell id='pickup' width={250}  >Expiry Date </CustomHeaderCell>
                        <CustomHeaderCell id='passenger' width={330} >File name </CustomHeaderCell>
                        {props.screenMode !== TRANSPORT_COMPANY_MODE.VIEW && <CustomHeaderCell id='Destination' width={180}  >Action</CustomHeaderCell>}
                      </TableRow>
                    </TableHead>
                    {true &&
                      <TableBody>
                        {props.DocumentDetails.map((p: newDocumentDetailsDto, index) => (
                          <TableRow key={index}>
                            <StyledTableCell >{p.documentCategory}</StyledTableCell>
                            <StyledTableCell >{p.version}</StyledTableCell>
                            {/* <StyledTableCell >{p.DocumentNumber}</StyledTableCell> */}
                            <StyledTableCell >{p.IssuedDate}</StyledTableCell>
                            <StyledTableCell >{p.expireDate}</StyledTableCell>
                            <StyledTableCell >{p.fileName}</StyledTableCell>
                            {props.screenMode !== TRANSPORT_COMPANY_MODE.VIEW &&
                              <StyledTableCell style={{ backgroundColor: '#282828' }}>
                                <Box className='layout-row'>
                                  <Box>
                                    <IconButton size='small' onClick={() => props.onSelectDocument(index, "view")}>
                                      <Tooltip title="View">
                                        <RemoveRedEyeOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                      </Tooltip>
                                    </IconButton>
                                  </Box>        
                                  <Box>
                                    <IconButton size='small' onClick={() => props.onSelectDocument(index, "download")}>
                                      <Tooltip title="Download">
                                        <CloudDownloadIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                      </Tooltip>
                                    </IconButton>
                                  </Box>
                                  <Box>
                                    <IconButton size='small' onClick={() => props.onSelectDocument(index, "delete")}>
                                      <Tooltip title="Delete">
                                        <DeleteOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                      </Tooltip>
                                    </IconButton>
                                  </Box>
                                </Box>
                              </StyledTableCell>
                            }
                          </TableRow>
                        ))}
                      </TableBody>
                    }
                    {props.DocumentDetails.length === 0 &&
                      <TableBody>
                        <TableRow>
                          <StyledTableCell align="center" colSpan={10}>No data to preview</StyledTableCell>
                        </TableRow>
                      </TableBody>
                    }
                  </Table>
                </TableContainer>
              </section>
            </Grid>

          </Grid>


        </>
      )}
      {props.getTransportCompanyByIDResponse.isLoading &&
        <AppSkeleton numOfRows={3} numOfColumns={4} columnWidth={50} height={40} tag="stepper" />
      }

    </Stepper>
  )
}

export default DocumentInformation