export const AUTH_ACTION_TYPE = {
  AUTHORIZE_USER: "AUTHORIZE_USER",
  FETCH_AUTHORIZE_USER: "FETCH_AUTHORIZE_USER",
};

export const REQUEST_ACTION_TYPE = {
  GET_REQUEST: "GET_REQUEST",
  POST_REQUEST: "POST_REQUEST",
  POST_RECCURRENT_REQUEST: "POST_RECCURRENT_REQUEST",
  EDIT_REQUEST: "EDIT_REQUEST",
  DELETE_REQUEST: "DELETE_REQUEST",
  GET_SELF_REQUEST_SUMMARY_LIST: "GET_SELF_REQUEST_SUMMARY_LIST",
  GET_LM_REQUEST_SUMMARY_LIST: "GET_LM_REQUEST_SUMMARY_LIST",
  GET_LM_REQUEST_SUMMARY_FILTERS:"GET_LM_REQUEST_SUMMARY_FILTERS",
  GET_TM_REQUEST_SUMMARY_LIST: "GET_TM_REQUEST_SUMMARY_LIST",
  GET_TM_REQUEST_SUMMARY_FILTERS:"GET_TM_REQUEST_SUMMARY_FILTERS",
  GET_SBU_LIST: "GET_SBU_LIST",
  GET_PLANT_LIST_BY_SBU_ID: "GET_PLANT_LIST_BY_SBU_ID",
  GET_DEPARTMENT_LIST_BY_PLANT_ID: "GET_DEPARTMENT_LIST_BY_PLANT_ID",
  GET_REQUEST_STATUS_PROCESS: "GET_REQUEST_STATUS_PROCESS",
  LM_APPROVAL: "LM_APPROVAL",
  TM_APPROVAL: "TM_APPROVAL",
  GET_REQUEST_HISTORY: "GET_REQUEST_HISTORY"
};

export const VEHICLE_ACTION_TYPE = {
  POST_VEHICLE: "POST_VEHICLE",
  EDIT_VEHICLE: "EDIT_VEHICLE",
  POST_VEHICLE_INCIDENT: "POST_VEHICLE_INCIDENT",
  DELETE_VEHICLE: "DELETE_VEHICLE",
  GET_VEHICLE_TYPES: "GET_VEHICLE_TYPES",
  GET_VEHICLE_LIST: "GET_VEHICLE_LIST",
  GET_VEHICLE_INCIDENTS: "GET_VEHICLE_INCIDENTS",
  GET_PENDING_VEHICLE_ALLOCATIONS: "GET_PENDING_VEHICLE_ALLOCATIONS",
  GET_PENDING_VEHICLE_ALLOC_FILTERS:"GET_PENDING_VEHICLE_ALLOC_FILTERS",
  GET_VEHICLE_ALLOCATION_HISTORY: "GET_VEHICLE_ALLOCATION_HISTORY",
  GET_VEHICLE_ALLOCATION_REQUISITIONS: "GET_VEHICLE_ALLOCATION_REQUISITIONS",
  PUT_CANCEL_ALLOCATION: "PUT_CANCEL_ALLOCATION",
};

export const DRIVER_ACTION_TYPE = {
  GET_DRIVER: 'GET_DRIVER',
  POST_DRIVER: 'POST_DRIVER',
  EDIT_DRIVER: 'EDIT_DRIVER',
  MODIFY_DRIVER_VEHICLE: 'MODIFY_DRIVER_VEHICLE',
  DELETE_DRIVER: 'DELETE_DRIVER',
  GET_DRIVER_LIST: 'GET_DRIVER_LIST',
  GET_PLANT_LIST: 'GET_PLANT_LIST',
  GET_VEHICLE_TYPES_BY_PLANT: 'GET_VEHICLE_TYPES_BY_PLANT',
  GET_VEHICLE_NUMBERS_BY_TYPE: 'GET_VEHICLE_NUMBERS_BY_TYPE',
  GET_VEHICLE_NUMBERS_BY_DRIVER: 'GET_VEHICLE_NUMBERS_BY_DRIVER',
  DRIVER_LICENSE_UPLOAD_FILE: 'DRIVER_LICENSE_UPLOAD_FILE',
  DRIVER_NIC_UPLOAD_FILE: 'DRIVER_NIC_UPLOAD_FILE',
  DRIVER_OTHER_UPLOAD_FILE: 'DRIVER_OTHER_UPLOAD_FILE',
  DRIVER_GET_REQUIRED_DOCUMENT: 'DRIVER_GET_REQUIRED_DOCUMENT',
  DRIVER_REMOVE_DOCUMENT: 'DRIVER_REMOVE_DOCUMENT'
}

export const LINK_ACTION_TYPES = {
  UPLOAD_MAIN_FILE: "UPLOAD_MAIN_FILE",
  UPLOAD_SECONDARY_FILE: "UPLOAD_SECONDARY_FILE",
  UPLOAD_OTHER_FILE: "UPLOAD_OTHER_FILE",
  DOWNLOAD_REQUIRED_FILE: "DOWNLOAD_REQUIRED_FILE",
  DOWNLOAD_OPEN_FILE: "DOWNLOAD_OPEN_FILE",
  REMOVE_UPLOADED_FILE: "REMOVE_UPLOADED_FILE"
}

export const TEMPLATE_ACTION_TYPES = {
  GET_TEMPLATE_LIST: "GET_TEMPLATE_LIST",
  DELETE_TEMPLATE: "DELETE_TEMPLATE",
  GET_MATCHING_TEMPLATE_LIST: "GET_MATCHING_TEMPLATE_LIST",
};

export const CHART_ACTION_TYPES = {
  GET_LM_SUMMARY: "GET_BUDGET_SUMMARY",
  GET_GU_SUMMARY: "GET_GU_SUMMARY",
  GET_TM_SUMMARY: "GET_TM_SUMMARY",
  GET_LM_BUDGET_SUMMARY: "GET_LM_BUDGET_SUMMARY"
};

export const SPLIT_ACTION_TYPE = {
  GET_SPLIT_TYPES: "GET_SPLIT_TYPES",
  GET_PASSENGERS_PACKAGES: "GET_PASSENGERS_PACKAGES",
  POST_SPLIT_REQUEST: "POST_SPLIT_REQUEST",
};

export const REDIRECT_ACTION_TYPE = {
  GET_TRANSPORT_MANAGER: "GET_TRANSPORT_MANAGER",
  POST_REDIRECT_REQUEST: "POST_REDIRECT_REQUEST",
};

export const PACKAGE_ACTION_TYPE = {
  GET_PACKAGE_TYPES: "GET_PACKAGE_TYPES",
};

export const LOCATION_ACTION_TYPE = {
  GET_FREQUENT_LOCATION_LIST: "GET_FREQUENT_LOCATION_LIST",
};

export const USER_ACTION_TYPE = {
  GET_ALL_PASSENGERS_LIST: "GET_ALL_PASSENGERS_LIST",
  GET_PASSENGER_DETAILS: "GET_PASSENGER_DETAILS",
  GET_ALL_USERS_BY_DEPARTMENT: "GET_ALL_USERS_BY_DEPARTMENT",
  GET_ALL_RECIPIENTS: "GET_ALL_RECIPIENTS"
};

export const APPROVER_ACTION_TYPE = {
  GET_ALL_APPROVERS_LIST: "GET_ALL_APPROVERS_LIST",
  GET_DEFAULT_APPROVER: "GET_DEFAULT_APPROVER",
};

export const TRANSPORT_ACTION_TYPE = {
  GET_ALL_TRANSPORT_COMPANIES: "GET_ALL_TRANSPORT_COMPANIES",
  GET_ALL_TRANSPORT_COMPANY_DETAILS: "GET_ALL_TRANSPORT_COMPANY_DETAILS",
  ADD_TRANSPORT_COMPANY: "ADD_TRANSPORT_COMPANY",
  VIEW_TRANSPORT_COMPANY: "VIEW_TRANSPORT_COMPANY",
  DELETE_TRANSPORT_COMPANY: "DELETE_TRANSPORT_COMPANY",
  EDIT_TRANSPORT_COMPANY: "EDIT_TRANSPORT_COMPANY"
};



export const TRANSPORT_ACTION_TYPE_COMPANYADD = {
  GET_ALL_TRANSPORT_COMPANIES_COMPANYADD: "GET_ALL_TRANSPORT_COMPANIES_COMPANYADD",

};

export const ALERT_ACTION_TYPES = {
  TRIGGER_ALERT: "TRIGGER_ALERT",
};

export const SHIFT_ACTION_TYPES = {
  SHIFT_UPLOAD_FILE: "SHIFT_UPLOAD_FILE",
  SHIFT_DOWNLOAD_FILE: "SHIFT_DOWNLOAD_FILE",
  SHIFT_DELETE_FILE: "SHIFT_DELETE_FILE",
  GET_SHIFT_TYPES: "GET_SHIFT_TYPES",
  GET_SHIFT_GROUPS: "GET_SHIFT_GROUPS",
  GET_AVAILABLE_SHIFT_VEHICLES: "GET_AVAILABLE_SHIFT_VEHICLES",
  POST_SHIFT_REQUEST: "POST_SHIFT_REQUEST",
  POST_SHIFT_DUPLICATE: "POST_SHIFT_DUPLICATE",
  GET_SHIFT_REQUEST_SUMMARY_LIST: "GET_SHIFT_REQUEST_SUMMARY_LIST",
  GET_SHIFT_REQUEST: "GET_SHIFT_REQUEST",
  DELETE_SHIFT_REQUEST: "DELETE_SHIFT_REQUEST",
  EDIT_SHIFT_REQUEST: "EDIT_SHIFT_REQUEST",
  EXECUTE_SHIFT_REQUEST: "EXECUTE_SHIFT_REQUEST",
  GET_EXECUTION_TEMPLATES: "GET_EXECUTION_TEMPLATES",
  GET_EXECUTED_TEMPLATE: "GET_EXECUTED_TEMPLATE"
}

export const TRANSPORT_ACTION_TYPES = {
  TRANSPORT_UPLOAD_FILE: "TRANSPORT_UPLOAD_FILE",
  TRANSPORT_DELETE_FILE: "TRANSPORT_DELETE_FILE",
  TRANSPORT_GET_REQUIRED_DOCUMENT: "TRANSPORT_GET_REQUIRED_DOCUMENT",
  TRANSPORT_REMOVE_DOCUMENT: "TRANSPORT_REMOVE_DOCUMENT",
  GET_ALL_TRANSPORT_COMPANY_DETAILS: "GET_ALL_TRANSPORT_COMPANY_DETAILS",
  GET_PAYMENT_METHODS: "GET_PAYMENT_METHODS",
  GTE_BANK_LIST: "GTE_BANK_LIST",
  GTE_BRANCH_LIST: "GTE_BRANCH_LIST",
  ADD_TRANSPORT_COMPANY: "ADD_TRANSPORT_COMPANY",
  Get_TRANSPORT_COMPANY_BY_ID: "Get_TRANSPORT_COMPANY_BY_ID",
  UPDATE_TRANSPORT_COMPANY_BY_ID: "UPDATE_TRANSPORT_COMPANY_BY_ID"
}

export const TRANSPORT_ACTION_TYPES_BANKACC = {
  TRANSPORT_BANKACC_UPLOAD_FILE: "TRANSPORT_BANKACC_UPLOAD_FILE",
  TRANSPORT_BANKACC_DELETE_FILE: "TRANSPORT_BANKACC_DELETE_FILE"
}

export const TRANSPORT_ACTION_TYPES_OTHERDOC = {
  TRANSPORT_OTHERDOC_UPLOAD_FILE: "TRANSPORT_OTHERDOC_UPLOAD_FILE",
  TRANSPORT_OTHERDOC_DELETE_FILE: "TRANSPORT_OTHERDOC_DELETE_FILE"
}

export const TRIP_ACTION_TYPES = {
  GET_TRIP_DETAILS: "GET_TRIP_DETAILS",
  POST_TRIP_DETAILS: "POST_TRIP_DETAILS",
  GET_VEHICLE_LIST_BY_PASSENGER_COUNT: "GET_VEHICLE_LIST_BY_PASSENGER_COUNT",
  GET_VEHICLE_DETAILS_LIST: "GET_VEHICLE_DETAILS_LIST",
  POST_TRIP_ALLOCATION: "POST_TRIP_ALLOCATION",
  POST_REJECT_TRIP_ALLOCATION: "POST_REJECT_TRIP_ALLOCATION",
  PUT_UPDATE_TRIP_DETAILS: "PUT_UPDATE_TRIP_DETAILS",
};

export const COMMON_ACTION_TYPES = {
  REQUEST: "_REQUEST",
  SUCCESS: "_SUCCESS",
  ERROR: "_ERROR",
  CLEAR: "_CLEAR",
  SET_ALERT_REQ: "_SET_ALERT_REQ",
  SET_ALERT: "_SET_ALERT",
  CLEAR_ALERT: "_CLEAR_ALERT",
};

export const APP_ACTION_STATUS = {
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
  INITIAL: "intial",
};

export const PERMISSION_ACTION_TYPES = {
  GET_ALL_USER_WITH_ENTITLEMENT: "GET_ALL_USER_WITH_ENTITLEMENT",
  GET_USERS_LIST_BY_DEPT_ID: "GET_USERS_LIST_BY_DEPT_ID",
  GET_ROLES_PERMISSIONS: "GET_ROLES_PERMISSIONS",
  POST_ASSIGN_ROLES_FOR_USERS: "POST_ASSIGN_ROLES_FOR_USERS",
  EDIT_ASSIGN_ROLES_FOR_USERS: "EDIT_ASSIGN_ROLES_FOR_USERS",
  GET_SELECTED_USER_DETAILS: "GET_SELECTED_USER_DETAILS",
  GET_ROLES_AND_ENTITLEMENT_BY_USER: "GET_ROLES_AND_ENTITLEMENT_BY_USER",
};


export const NOTIFICATION_ACTION_TYPES = {
  GET_NOTIFICATION: "GET_NOTIFICATION",
  UPDATE_SEEN_NOTIFICATION: "UPDATE_SEEN_NOTIFICATION",
};

export const COMPANY_ACTION_TYPE = {
  GET_REQUEST_LIST: "GET_REQUEST_LIST",
  GET_TOBE_APPROVAL_REQUEST_LIST: "GET_TOBE_APPROVAL_REQUEST_LIST"
}

export const MAP_ACTION_TYPE = {
  ADD_MAP_START_POINT: "ADD_MAP_START_POINT",
  ADD_MAP_END_POINT: "ADD_MAP_END_POINT",
  ADD_MAP_PICKUP_POINT: "ADD_MAP_PICKUP_POINT",
  ADD_MAP_DROPOFF_POINT: "ADD_MAP_DROPOFF_POINT",
  SET_TOTAL_DISTANCE: "SET_TOTAL_DISTANCE",
  SET_TOTAL_DURATION: "SET_TOTAL_DURATION"
}

export const RESPONSE_KEYS = [
  {
    value: 1,
    label: "Active",
  },
  {
    value: "0",
    label: "Inactive",
  },
]

export const GPS_ACTION_TYPES = {
  POST_REAL_TRIP:"POST_REAL_TRIP",
  GET_CURRENT_TRIP_SITUATION:"GET_CURRENT_TRIP_SITUATION",
  RESET_REAL_TRIP:" RESET_REAL_TRIP"
}

export const FUEL_ACTION_TYPES = {
  POST_FUEL_TYPES:"POST_FUEL_TYPES",
  GET_FUEL_TYPES:"GET_FUEL_TYPES",
  GET_VEHICLE_NUMBER:"GET_VEHICLE_NUMBER",
  UPLOAD_FUEL_BILL:"UPLOAD_FUEL_BILL",
  ADD_FUEL_DETAILS:"ADD_FUEL_DETAILS",
  GET_FUEL_PRICE:"GET_FUEL_PRICE",
  GET_FUEL_COST_DETAILS:"GET_FUEL_COST_DETAILS"
}