import {
  APP_ACTION_STATUS,
  COMMON_ACTION_TYPES,
  VEHICLE_ACTION_TYPE,
} from "../../utilities/constants";
import { VehicleStateDto } from "../../utilities/models";

const INITIAL_STATE: VehicleStateDto = {
  addVehicle: {
    data: "",
    error: null,
    isLoading: false,
    status: null,
  },
  addVehicleIncident: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  editVehicle: {
    data: "",
    error: null,
    isLoading: false,
    status: null,
  },
  deleteVehicle: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  getVehicleTypes: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getVehicles: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getIncidents: {
    data: {
      purpose: 0,
      licenseNumber: "",
      lnIssuedDate: "",
      lnExpiryDate: "",
      insuranceNumber: "",
      inIssuedDate: "",
      inExpiryDate: "",
      vehicleIncident: [],
      documents: [],
      lnDocumentName: "",
      lnVersion:0,
      inDocumentName: "",
      inVersion:0,
      gpsDeviceNumber:""
    },
    error: null,
    isLoading: false,
    status: null,
  },
  getPendingAllocations: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getPendingAllocationsFilters:{
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getAllocationsHistory: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getAllocationsRequisitions: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  putAllocationCancel: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
};

const vehicleReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case VEHICLE_ACTION_TYPE.POST_VEHICLE + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        addVehicle: {
          ...state.addVehicle,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: "",
        },
      };
    case VEHICLE_ACTION_TYPE.POST_VEHICLE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        addVehicle: {
          ...state.addVehicle,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case VEHICLE_ACTION_TYPE.POST_VEHICLE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        addVehicle: {
          ...state.addVehicle,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: "",
        },
      };
    case VEHICLE_ACTION_TYPE.POST_VEHICLE + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        addVehicle: {
          ...state.addVehicle,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: "",
        },
      };
    
    case VEHICLE_ACTION_TYPE.POST_VEHICLE_INCIDENT + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        addVehicleIncident: {
          ...state.addVehicleIncident,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case VEHICLE_ACTION_TYPE.POST_VEHICLE_INCIDENT + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        addVehicleIncident: {
          ...state.addVehicleIncident,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case VEHICLE_ACTION_TYPE.POST_VEHICLE_INCIDENT + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        addVehicleIncident: {
          ...state.addVehicleIncident,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case VEHICLE_ACTION_TYPE.POST_VEHICLE_INCIDENT + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        addVehicleIncident: {
          ...state.addVehicleIncident,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    
      case VEHICLE_ACTION_TYPE.EDIT_VEHICLE + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        editVehicle: {
          ...state.editVehicle,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: "",
        },
      };
    case VEHICLE_ACTION_TYPE.EDIT_VEHICLE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        editVehicle: {
          ...state.editVehicle,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case VEHICLE_ACTION_TYPE.EDIT_VEHICLE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        editVehicle: {
          ...state.editVehicle,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: "",
        },
      };
    case VEHICLE_ACTION_TYPE.EDIT_VEHICLE + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        editVehicle: {
          ...state.editVehicle,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: "",
        },
      };
    case VEHICLE_ACTION_TYPE.DELETE_VEHICLE + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        deleteVehicle: {
          ...state.deleteVehicle,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case VEHICLE_ACTION_TYPE.DELETE_VEHICLE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        deleteVehicle: {
          ...state.deleteVehicle,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case VEHICLE_ACTION_TYPE.DELETE_VEHICLE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        deleteVehicle: {
          ...state.deleteVehicle,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case VEHICLE_ACTION_TYPE.DELETE_VEHICLE + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        deleteVehicle: {
          ...state.deleteVehicle,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_TYPES + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getVehicleTypes: {
          ...state.getVehicleTypes,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_TYPES + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getVehicleTypes: {
          ...state.getVehicleTypes,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_TYPES + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getVehicleTypes: {
          ...state.getVehicleTypes,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_TYPES + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getVehicleTypes: {
          ...state.getVehicleTypes,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getVehicles: {
          ...state.getVehicles,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getVehicles: {
          ...state.getVehicles,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getVehicles: {
          ...state.getVehicles,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_LIST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getVehicles: {
          ...state.getVehicles,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_INCIDENTS + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getIncidents: {
          ...state.getIncidents,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_INCIDENTS + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getIncidents: {
          ...state.getIncidents,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_INCIDENTS + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getIncidents: {
          ...state.getIncidents,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_INCIDENTS + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getIncidents: {
          ...state.getIncidents,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case VEHICLE_ACTION_TYPE.GET_PENDING_VEHICLE_ALLOCATIONS + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getPendingAllocations: {
          ...state.getPendingAllocations,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case VEHICLE_ACTION_TYPE.GET_PENDING_VEHICLE_ALLOCATIONS + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getPendingAllocations: {
          ...state.getPendingAllocations,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case VEHICLE_ACTION_TYPE.GET_PENDING_VEHICLE_ALLOCATIONS + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getPendingAllocations: {
          ...state.getPendingAllocations,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case VEHICLE_ACTION_TYPE.GET_PENDING_VEHICLE_ALLOCATIONS + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getPendingAllocations: {
          ...state.getPendingAllocations,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case VEHICLE_ACTION_TYPE.GET_PENDING_VEHICLE_ALLOC_FILTERS + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getPendingAllocationsFilters: {
          ...state.getPendingAllocationsFilters,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: [],
        },
      };
    case VEHICLE_ACTION_TYPE.GET_PENDING_VEHICLE_ALLOC_FILTERS + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getPendingAllocationsFilters: {
          ...state.getPendingAllocationsFilters,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case VEHICLE_ACTION_TYPE.GET_PENDING_VEHICLE_ALLOC_FILTERS + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getPendingAllocationsFilters: {
          ...state.getPendingAllocationsFilters,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case VEHICLE_ACTION_TYPE.GET_PENDING_VEHICLE_ALLOC_FILTERS + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getPendingAllocationsFilters: {
          ...state.getPendingAllocationsFilters,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case VEHICLE_ACTION_TYPE.GET_VEHICLE_ALLOCATION_HISTORY + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getAllocationsHistory: {
          ...state.getAllocationsHistory,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_ALLOCATION_HISTORY + COMMON_ACTION_TYPES.SUCCESS:

      return {
        ...state,
        getAllocationsHistory: {
          ...state.getAllocationsHistory,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_ALLOCATION_HISTORY + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getAllocationsHistory: {
          ...state.getAllocationsHistory,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_ALLOCATION_HISTORY + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getAllocationsHistory: {
          ...state.getAllocationsHistory,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_ALLOCATION_REQUISITIONS + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getAllocationsRequisitions: {
          ...state.getAllocationsRequisitions,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_ALLOCATION_REQUISITIONS + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getAllocationsRequisitions: {
          ...state.getAllocationsRequisitions,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_ALLOCATION_REQUISITIONS + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getAllocationsRequisitions: {
          ...state.getAllocationsRequisitions,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case VEHICLE_ACTION_TYPE.GET_VEHICLE_ALLOCATION_REQUISITIONS + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getAllocationsRequisitions: {
          ...state.getAllocationsRequisitions,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case VEHICLE_ACTION_TYPE.PUT_CANCEL_ALLOCATION + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        putAllocationCancel: {
          ...state.putAllocationCancel,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case VEHICLE_ACTION_TYPE.PUT_CANCEL_ALLOCATION + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        putAllocationCancel: {
          ...state.putAllocationCancel,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case VEHICLE_ACTION_TYPE.PUT_CANCEL_ALLOCATION + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        putAllocationCancel: {
          ...state.putAllocationCancel,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case VEHICLE_ACTION_TYPE.PUT_CANCEL_ALLOCATION + COMMON_ACTION_TYPES.CLEAR:

      return {
        ...state,
        putAllocationCancel: {
          ...state.putAllocationCancel,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    default:
      return state;
  }
};

export default vehicleReducer;
