import Grid from "@mui/material/Grid/Grid";
import React from 'react';
import Stepper from "../../Shared/Stepper/Stepper";
import { StyledSwitch, StyledTableCell, StyledTextField } from "../../../assets/theme/theme";

import CloudDownloadIcon from '@mui/icons-material/CloudDownloadRounded';
import CloudUploadIcon from '@mui/icons-material/CloudUploadRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { Box, Button, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import RadioButtonUncheckedTwoToneIcon from "@mui/icons-material/RadioButtonUncheckedTwoTone";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import RadioButtonCheckedTwoToneIcon from "@mui/icons-material/RadioButtonCheckedTwoTone";
import PublishIcon from '@mui/icons-material/Publish';

import { DAILY_TRANSPORT_MODES, ONE_WAY_SHIFT } from "../../../utilities/constants";
import { AssignRouteListDto, DSPassengerInformationDto, shiftOneWayLocationDto, shiftVehicleDetailsListDto } from "../../../utilities/models/shift.model";
import style from "./PassengerDetails.module.scss"
import CustomAutocomplete from "../../Shared/CustomAutocomplete/CustomAutocomplete";
import { LocationBriefDto } from "../../../utilities/models/location.model";
import CustomTimePicker from "../../Shared/CustomTimePicker/CustomTimePicker";
import { VehicleTypesDto } from "../../../utilities/models/vehicle.model";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import CustomHeaderCell from "../../Shared/CustomHeaderCell/CustomHeaderCell";
import { EditOutlined } from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";
import AppSkeleton from "../../Shared/AppSkeleton/AppSkeleton";
import { StateObjectDto } from "../../../utilities/models/common.model";

const PassengerDetails: React.FC<{
  dsPassengerInformationForm: DSPassengerInformationDto;
  helperText: boolean;
  handleInputFocus(property: string, section: string): void;
  onInputHandleChangePassengerInformation(property: string, value: any): void;
  passangerCount: string,
  frequentLocationsResponse: LocationBriefDto[];
  handleDownload(): void;
  handleRemove(): void;
  screenMode: string;
  shiftDocumentResponse: StateObjectDto<any>;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectRoute(id: number, action: string): void;
  vehicleDetails: shiftVehicleDetailsListDto[];
  vehicleDetailsListResponse: StateObjectDto<any>;
  VehicleTypeList: VehicleTypesDto[];
  onHandleInputChange(property: string, value: string): void;
  onShowVehicle(): void;
  onUploadFile(): void;
  onAssignRoute(): void;
  isEditRoute: boolean;
  onEditRoute(): void;
  assignRouteList: any[];
  fileString: string;
  selectedVehicleType: string;
  onClearPassengerInfoForm(): void;
  selectedFile: File | any;
  selectedVehicleID: number | undefined;
  handleOnSelectVehicle(vehicleID: number): void;
}> = (props) => {

  console.log("xxx", props.selectedFile)

  const _passengerCount = props.dsPassengerInformationForm.passengerCount;
  const _isRoundTrip = props.dsPassengerInformationForm.isRoundTrip;
  const _toEndLocation = props.dsPassengerInformationForm.routeEndLocation;
  const _tripStartTime = props.dsPassengerInformationForm.tripStartTime;
  const _tripEndTime = props.dsPassengerInformationForm.tripEndTime;
  const _returnTripStartTime = props.dsPassengerInformationForm.returnTripStartTime;
  const _returnTripEndTime = props.dsPassengerInformationForm.returnTripEndTime;
  const _shiftOneWayLocation = props.dsPassengerInformationForm.isOneWay;
  const _vehicleType = props.dsPassengerInformationForm.vehicleType;
  const _excelDocument = props.dsPassengerInformationForm.passengerDocument;

  return (
    <Stepper stepNumber={2} stepTitle={"Route-wise Information and Vehicle Allocation"}>
      <>
        {props.screenMode !== DAILY_TRANSPORT_MODES.VIEW && props.screenMode !== DAILY_TRANSPORT_MODES.VIEW_EXECUTED && (
          <>
            <section className={style.instructionSection}>
              <Typography className={style.instruction}><b>Instruction :-</b> Please complete the General Information section before entering Passenger Information.</Typography>
            </section>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <div className={style.switchField}>
                  <Typography className={style.label}>Round Trip</Typography>
                  <Box style={{ marginTop: '-1rem' }}>
                    <StyledSwitch
                      checked={_isRoundTrip.value}
                      disabled={_isRoundTrip.disable || props.screenMode === DAILY_TRANSPORT_MODES.VIEW}
                      onChange={() => props.onInputHandleChangePassengerInformation("isRoundTrip", !_isRoundTrip.value)}
                    />
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomAutocomplete
                  label="Departure trip/Return trip"
                  placeholder="Select Trip"
                  options={ONE_WAY_SHIFT.map((t: shiftOneWayLocationDto) => {
                    return { label: t.label, value: t.value };
                  })}
                  value={_shiftOneWayLocation.value}
                  error={!!_shiftOneWayLocation.error}
                  disabled={_shiftOneWayLocation.disable || props.screenMode === DAILY_TRANSPORT_MODES.VIEW}
                  readonly={_shiftOneWayLocation.readonly}
                  required={_shiftOneWayLocation.isRequired}
                  helperText={props.helperText && _shiftOneWayLocation.error}
                  onFocus={() => props.handleInputFocus("shiftOneWayLocation", "PI")}
                  onChange={(event: any, value: any) => props.onInputHandleChangePassengerInformation("shiftOneWayLocation", value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomAutocomplete
                  label="Route Start/Endpoint Location"
                  placeholder="Select Location"
                  options={props.frequentLocationsResponse && props.frequentLocationsResponse.map((l: LocationBriefDto) => { return { label: l.location, value: l.id } })}
                  value={{ label: _toEndLocation.value.location, value: _toEndLocation.value.id }}
                  error={!!_toEndLocation.error}
                  disabled={_toEndLocation.disable}
                  readonly={_toEndLocation.readonly || props.screenMode === DAILY_TRANSPORT_MODES.VIEW}
                  required={_toEndLocation.isRequired}
                  helperText={props.helperText && _toEndLocation.error}
                  onFocus={() => props.handleInputFocus("endLocation", "PI")}
                  onChange={(event: any, value: any) => props.onInputHandleChangePassengerInformation("endLocation", value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTimePicker
                  label="Trip Start Time"
                  placeholder="Select starting time for trip"
                  value={_tripStartTime.value}
                  error={!!_tripStartTime.error}
                  disabled={_tripStartTime.disable}
                  required={_tripStartTime.isRequired}
                  readOnly={_tripStartTime.readonly || props.screenMode === DAILY_TRANSPORT_MODES.VIEW}
                  helperText={props.helperText && _tripStartTime.error}
                  onFocus={() => props.handleInputFocus("tripStartTime", "PI")}
                  onChange={(value: any) => props.onInputHandleChangePassengerInformation("tripStartTime", value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTimePicker
                  label="Estimated Trip End Time"
                  placeholder="Select arriving time (to plant)"
                  value={_tripEndTime.value}
                  error={!!_tripEndTime.error}
                  disabled={_tripEndTime.disable}
                  required={_tripEndTime.isRequired}
                  readOnly={_tripEndTime.readonly || props.screenMode === DAILY_TRANSPORT_MODES.VIEW}
                  helperText={props.helperText && _tripEndTime.error}
                  onFocus={() => props.handleInputFocus("tripEndTime", "PI")}
                  onChange={(value: any) => props.onInputHandleChangePassengerInformation("tripEndTime", value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTimePicker
                  label="Return Trip Start Time"
                  placeholder="Select return time (from plant)"
                  value={_returnTripStartTime.value}
                  error={!!_returnTripStartTime.error}
                  disabled={_returnTripStartTime.disable}
                  required={_returnTripStartTime.isRequired}
                  readOnly={_returnTripStartTime.readonly || props.screenMode === DAILY_TRANSPORT_MODES.VIEW}
                  helperText={props.helperText && _returnTripStartTime.error}
                  onFocus={() => props.handleInputFocus("returnTripStartTime", "PI")}
                  onChange={(value: any) => props.onInputHandleChangePassengerInformation("returnTripStartTime", value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTimePicker
                  label="Estimated Return Trip End Time"
                  placeholder="Select return time (from plant)"
                  value={_returnTripEndTime.value}
                  error={!!_returnTripEndTime.error}
                  disabled={_returnTripEndTime.disable}
                  required={_returnTripEndTime.isRequired}
                  readOnly={_returnTripEndTime.readonly || props.screenMode === DAILY_TRANSPORT_MODES.VIEW}
                  helperText={props.helperText && _returnTripEndTime.error}
                  onFocus={() => props.handleInputFocus("returnTripEndTime", "PI")}
                  onChange={(value: any) => props.onInputHandleChangePassengerInformation("returnTripEndTime", value)}
                />
              </Grid>
              <Grid item xs={12} md={6} />
              <Grid item xs={12} md={6}>
                <StyledTextField
                  fullWidth
                  label="Passenger Count*"
                  placeholder="Enter Passenger Count"
                  size="small"
                  type="number"
                  InputProps={{
                    readOnly: _passengerCount.readonly || props.screenMode === DAILY_TRANSPORT_MODES.VIEW
                  }}
                  value={_passengerCount.value}
                  error={!!_passengerCount.error}
                  disabled={_passengerCount.disable}
                  required={_passengerCount.disable}
                  helperText={props.helperText && _passengerCount.error}
                  onFocus={() => props.handleInputFocus("passengerCount", "PI")}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const inputValue = event.target.value;
                    const newValue = Math.max(0, parseInt(inputValue, 10)) || '';
                    props.onInputHandleChangePassengerInformation("passengerCount", newValue)
                  }
                  }
                />
              </Grid>
              <Grid item xs={1} sx={{ display: "none" }}>
                <input
                  type="file"
                  required
                  accept=".xlsx"
                  id="outlined-required"
                  style={{ display: 'none' }}
                  onChange={props.handleFileChange}
                />
              </Grid>
              <Grid item xs={3} md={4}>
                <Box sx={{ display: "flex" }}>
                  <Grid item xs={12} md={4}>
                    <label htmlFor="outlined-required">
                      <Button style={{ color: "white", border:"white 1px solid", fontSize:"0.6rem"}} component="span">
                        Passenger File
                      </Button>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {(props.selectedFile !== null) ? (
                      <Box sx={{ display: "flex", marginInline: "0.2rem" }}>
                        <Typography>Selected File: {props.selectedFile?.name}</Typography>
                      </Box>
                    ) : (_excelDocument.value !== '') ? (
                      <Box sx={{ display: "flex", marginInline: "0.2rem" }}>
                        <Typography>Uploaded File: {_excelDocument.value}</Typography>
                      </Box>
                    ) : (
                      <Box sx={{ display: "flex", marginInline: "0.2rem" }}>
                        <Typography></Typography>
                      </Box>
                    )}
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12} md={2}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CustomButton isLoading={props.shiftDocumentResponse.isLoading} onClick={props.onUploadFile} text="Upload" bgColor="#bfbfbf" />
                  {/* <Button style={{ color: "white" }} startIcon={<CloudDownloadIcon style={{ color: "white" }} />} onClick={props.handleDownload} /> */}
                  <Button style={{ color: "white" }} startIcon={<DeleteForeverRoundedIcon />} onClick={props.handleRemove} />
                </div>
              </Grid>

              <Grid item xs={12} md={6} className={style.formBox}>
                <StyledTextField placeholder="Required Number of seats" size="small" value={props.passangerCount} label="Required Number of seats" inputProps={{ readOnly: true }} />
              </Grid>
              <Grid item xs={6}>
                <CustomAutocomplete
                  label="Preferred vehicle type"
                  placeholder='Select preferred vehicle type'
                  onFocus={() => props.handleInputFocus('vehicleType', 'PI')}
                  options={props.VehicleTypeList.map((v: VehicleTypesDto) => { return { label: v.description, value: v.id } })}
                  value={_vehicleType.value}
                  error={!!_vehicleType.error}
                  disabled={_vehicleType.disable}
                  required={_vehicleType.isRequired}
                  readonly={_vehicleType.readonly}
                  helperText={props.helperText && _vehicleType.error}
                  onChange={(event: any, value: any) => props.onInputHandleChangePassengerInformation('vehicleType', value)}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "end", margin: "1rem" }}>
              <CustomButton text="Show Vehicle" border="1px solid #6e6e6e" bgColor="#282828"
                onClick={() => { props.onShowVehicle() }}
              />
            </Box>
            <section className={style.gridSection}>
              <TableContainer component={Paper} className={style.grid}>
                <Table className={style.table}>
                  <TableHead>
                    <TableRow>
                      <CustomHeaderCell id="licensePlateNo"> License plate Number</CustomHeaderCell>
                      <CustomHeaderCell id="driverName">  Driver Name </CustomHeaderCell>
                      <CustomHeaderCell id="transportCompany"> Transport Company </CustomHeaderCell>
                      <CustomHeaderCell id="seatCount"> Maximum Seating Capacity</CustomHeaderCell>
                      <CustomHeaderCell id="select">Select</CustomHeaderCell>
                    </TableRow>
                  </TableHead>
                  {props.vehicleDetailsListResponse.isLoading && (
                    <AppSkeleton numOfRows={1} numOfColumns={5} columnWidth={150} tag="table" />
                  )}
                  {props.vehicleDetails && props.vehicleDetails.length > 0 && (
                    <TableBody>
                      {props.vehicleDetails.map((p, index) => (
                        <TableRow key={index}>
                          <StyledTableCell>{p.licenseNumber}</StyledTableCell>
                          <StyledTableCell>{p.driverName}</StyledTableCell>
                          <StyledTableCell>{p.transportCompanyName}</StyledTableCell>
                          <StyledTableCell>{p.maximumSeatCapacity}</StyledTableCell>
                          <StyledTableCell>
                            <Box>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  props.handleOnSelectVehicle(p.vehicleID)
                                }
                              >
                                <Tooltip title="Select">
                                  {props.selectedVehicleID === p.vehicleID ? (
                                    <RadioButtonCheckedTwoToneIcon
                                      sx={{ fontSize: "20px", color: "white" }}
                                    />
                                  ) : (
                                    <RadioButtonUncheckedTwoToneIcon
                                      sx={{ fontSize: "20px", color: "white" }}
                                    />
                                  )}
                                </Tooltip>
                              </IconButton>
                            </Box>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                  {!props.vehicleDetailsListResponse.isLoading && props.vehicleDetails.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell align="center" colSpan={7}>
                          No data to preview
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </section>
            <section className={style.actions}>
              <CustomButton text='Clear' textColor='black' bgColor='#bfbfbf' onClick={props.onClearPassengerInfoForm} />
              <CustomButton text={props.isEditRoute ? 'Edit Route' : 'Add Route'} border='1px solid #6e6e6e' bgColor='#282828' 
               onClick={() => {
                if (!props.isEditRoute && props.screenMode === DAILY_TRANSPORT_MODES.EXECUTE) {
                  return;
                }
                props.isEditRoute ? props.onEditRoute() : props.onAssignRoute();
              }} />
            </section>
          </>
        )}
        <section className={style.gridSection}>
          <TableContainer component={Paper} className={style.grid}>
            <Table className={style.table}>
              <TableHead>
                <TableRow>
                  <CustomHeaderCell width={20} id="licensePlateNo"> # </CustomHeaderCell>
                  <CustomHeaderCell width={120} id="driverName">  Start Location </CustomHeaderCell>
                  <CustomHeaderCell width={120} id="driverName">  End Location</CustomHeaderCell>
                  <CustomHeaderCell width={80} id="transportCompany"> Trip Start Time </CustomHeaderCell>
                  <CustomHeaderCell width={80} id="seatCount">Trip End Time</CustomHeaderCell>
                  <CustomHeaderCell width={50} id="select">Passenger Count</CustomHeaderCell>
                  <CustomHeaderCell width={100} id="select">Vehicle Number</CustomHeaderCell>
                  <CustomHeaderCell width={100} id="select">Action</CustomHeaderCell>
                </TableRow>
              </TableHead>
              {props.assignRouteList.length > 0 && (
                <TableBody>
                  {props.assignRouteList.map((p: AssignRouteListDto, index) => (
                    <TableRow key={index}>
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>{p.startLocation}</StyledTableCell>
                      <StyledTableCell>{p.toLocation}</StyledTableCell>
                      <StyledTableCell>
                        {p.startTime ? `${dayjs(p.startTime).format("HH:mm:ss")}` : `${dayjs(p.returnStartTime).format("HH:mm:ss")}`}
                      </StyledTableCell>
                      <StyledTableCell>
                        {p.endTime ? `${dayjs(p.endTime).format("HH:mm:ss")}` : `${dayjs(p.returnEndTime).format("HH:mm:ss")}`}
                      </StyledTableCell>
                      <StyledTableCell>{p.passengerCount}</StyledTableCell>
                      <StyledTableCell>{p.vehicleLicenseNo}</StyledTableCell>
                      <StyledTableCell>
                        <Box className='layout-row'>
                          {p.excelDocument !== '' && (
                            <Box>
                              <IconButton size='small' onClick={() => props.onSelectRoute(p.id, "download")}>
                                <Tooltip title="download">
                                  <CloudDownloadIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                </Tooltip>
                              </IconButton>
                            </Box>
                          )}
                          {(props.screenMode !== DAILY_TRANSPORT_MODES.VIEW && props.screenMode !== DAILY_TRANSPORT_MODES.VIEW_EXECUTED) && (
                            <>
                              <Box>
                                <IconButton size='small' onClick={() => props.onSelectRoute(p.id, "edit")}>
                                  <Tooltip title="Edit">
                                    <EditOutlined sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                  </Tooltip>
                                </IconButton>
                              </Box>
                              <Box>
                                <IconButton size='small' onClick={() => props.onSelectRoute(p.id, "delete")}>
                                  <Tooltip title="Delete">
                                    <DeleteOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                  </Tooltip>
                                </IconButton>
                              </Box>
                            </>
                          )}
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
              {props.assignRouteList.length === 0 && (
                <TableBody>
                  <TableRow>
                    <StyledTableCell align="center" colSpan={8}>
                      No data to preview
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </section>
      </>
    </Stepper>
  );
};

export default PassengerDetails;
