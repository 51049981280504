import { APP_ACTION_STATUS, COMMON_ACTION_TYPES, PERMISSION_ACTION_TYPES,} from "../../utilities/constants";
import { PermissionStateDto } from "../../utilities/models/state.model";

const INITIAL_STATE: PermissionStateDto = {
  getUsersByDeptId: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getRolesPermissions: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  postRolesPermissions: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  editRolesPermissions: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  getALLusersWithEntitlements: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getSelecteUserDetailsById: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  getRolesAndEntitlementByUser: {
    data:{
      userId: "",
      firstName: "",
      lastName: "",
      roleId: "",
      roleName: "",
      permissions: [],
      hierarchy: [],
      nic: "",
      plant: "",
      sbu: "",
      department: "",
      contactNumber: "",
      homeContactNumber: "",
      emailId: "",
      address: "",
      isActive: false
    },
    error: null,
    isLoading: false,
    status: null,
  },
};

const permissonReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PERMISSION_ACTION_TYPES.GET_USERS_LIST_BY_DEPT_ID +
      COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getUsersByDeptId: {
          ...state.getUsersByDeptId,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case PERMISSION_ACTION_TYPES.GET_USERS_LIST_BY_DEPT_ID +
      COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getUsersByDeptId: {
          ...state.getUsersByDeptId,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case PERMISSION_ACTION_TYPES.GET_USERS_LIST_BY_DEPT_ID +
      COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getUsersByDeptId: {
          ...state.getUsersByDeptId,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case PERMISSION_ACTION_TYPES.GET_USERS_LIST_BY_DEPT_ID +
      COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getUsersByDeptId: {
          ...state.getUsersByDeptId,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case PERMISSION_ACTION_TYPES.GET_ROLES_PERMISSIONS +
      COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getRolesPermissions: {
          ...state.getRolesPermissions,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };

    case PERMISSION_ACTION_TYPES.GET_ROLES_PERMISSIONS +
      COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getRolesPermissions: {
          ...state.getRolesPermissions,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };

    case PERMISSION_ACTION_TYPES.GET_ROLES_PERMISSIONS +
      COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getRolesPermissions: {
          ...state.getRolesPermissions,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };

    case PERMISSION_ACTION_TYPES.GET_ROLES_PERMISSIONS +
      COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getRolesPermissions: {
          ...state.getRolesPermissions,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case PERMISSION_ACTION_TYPES.POST_ASSIGN_ROLES_FOR_USERS +
      COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        postRolesPermissions: {
          ...state.postRolesPermissions,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case PERMISSION_ACTION_TYPES.POST_ASSIGN_ROLES_FOR_USERS +
      COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        postRolesPermissions: {
          ...state.postRolesPermissions,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case PERMISSION_ACTION_TYPES.POST_ASSIGN_ROLES_FOR_USERS +
      COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        postRolesPermissions: {
          ...state.postRolesPermissions,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case PERMISSION_ACTION_TYPES.POST_ASSIGN_ROLES_FOR_USERS +
      COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        postRolesPermissions: {
          ...state.postRolesPermissions,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case PERMISSION_ACTION_TYPES.EDIT_ASSIGN_ROLES_FOR_USERS +
      COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        editRolesPermissions: {
          ...state.editRolesPermissions,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case PERMISSION_ACTION_TYPES.EDIT_ASSIGN_ROLES_FOR_USERS +
      COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        editRolesPermissions: {
          ...state.editRolesPermissions,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case PERMISSION_ACTION_TYPES.EDIT_ASSIGN_ROLES_FOR_USERS +
      COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        editRolesPermissions: {
          ...state.editRolesPermissions,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case PERMISSION_ACTION_TYPES.EDIT_ASSIGN_ROLES_FOR_USERS +
      COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        editRolesPermissions: {
          ...state.editRolesPermissions,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case PERMISSION_ACTION_TYPES.GET_ALL_USER_WITH_ENTITLEMENT +
      COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getALLusersWithEntitlements: {
          ...state.getALLusersWithEntitlements,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case PERMISSION_ACTION_TYPES.GET_ALL_USER_WITH_ENTITLEMENT +
      COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getALLusersWithEntitlements: {
          ...state.getALLusersWithEntitlements,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case PERMISSION_ACTION_TYPES.GET_ALL_USER_WITH_ENTITLEMENT +
      COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getALLusersWithEntitlements: {
          ...state.getALLusersWithEntitlements,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case PERMISSION_ACTION_TYPES.GET_ALL_USER_WITH_ENTITLEMENT +
      COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getALLusersWithEntitlements: {
          ...state.getALLusersWithEntitlements,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

    case PERMISSION_ACTION_TYPES.GET_SELECTED_USER_DETAILS +
      COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getSelecteUserDetailsById: {
          ...state.getSelecteUserDetailsById,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case PERMISSION_ACTION_TYPES.GET_SELECTED_USER_DETAILS +
      COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getSelecteUserDetailsById: {
          ...state.getSelecteUserDetailsById,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case PERMISSION_ACTION_TYPES.GET_SELECTED_USER_DETAILS +
      COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getSelecteUserDetailsById: {
          ...state.getSelecteUserDetailsById,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case PERMISSION_ACTION_TYPES.GET_SELECTED_USER_DETAILS +
      COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getSelecteUserDetailsById: {
          ...state.getSelecteUserDetailsById,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
      case PERMISSION_ACTION_TYPES.GET_ROLES_AND_ENTITLEMENT_BY_USER + COMMON_ACTION_TYPES.REQUEST:
        return {
          ...state,
          getRolesAndEntitlementByUser: {
            ...state.getRolesAndEntitlementByUser,
            isLoading: true,
            status: APP_ACTION_STATUS.LOADING,
            error: null,
            data: null,
          }
        };
      case PERMISSION_ACTION_TYPES.GET_ROLES_AND_ENTITLEMENT_BY_USER + COMMON_ACTION_TYPES.SUCCESS:
        return {
          ...state,
          getRolesAndEntitlementByUser: {
            ...state.getRolesAndEntitlementByUser,
            isLoading: false,
            status: APP_ACTION_STATUS.SUCCESS,
            error: null,
            data: action.data,
          }
        };
      case PERMISSION_ACTION_TYPES.GET_ROLES_AND_ENTITLEMENT_BY_USER + COMMON_ACTION_TYPES.ERROR:
        return {
          ...state,
          getRolesAndEntitlementByUser: {
            ...state.getRolesAndEntitlementByUser,
            isLoading: false,
            status: APP_ACTION_STATUS.ERROR,
            error: action.error,
            data: null,
          }
        };
      case PERMISSION_ACTION_TYPES.GET_ROLES_AND_ENTITLEMENT_BY_USER + COMMON_ACTION_TYPES.CLEAR:
        return {
          ...state,
          getRolesAndEntitlementByUser: {
            ...state.getRolesAndEntitlementByUser,
            isLoading: false,
            status: APP_ACTION_STATUS.INITIAL,
            error: null,
            data: null,
          }
        };

    default:
      return state;
  }
};

export default permissonReducer;
