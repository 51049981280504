import React, { useEffect, useState } from 'react'
import { AppLayout } from '../../templates'
import { DownloadFuelCostBill, FuelBillPopup, FuelManagementGrid } from '../../components/FuelManagement'
import { AlertDto, ApplicationStateDto, FuelCostDetailsFormDto, FuelTypeBriefDto, GetFuelCostDetailsDto, OptionsDto, SortMetaDto, getDriversSummaryParam } from '../../utilities/models'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { driverActions } from '../../redux/actions/driver.action'
import { ALERT_ACTION_TYPES, APP_ACTION_STATUS, APP_ROUTES, APP_TABLE_CONFIGS, COMMON_ACTION_TYPES, DRIVER_SCREEN_MODES, FUEL_CATEGORY, FUEL_TYPE_MODE } from '../../utilities/constants'
import { ConfirmationDialog } from '../../components'
import { alertActions, fuelActions } from '../../redux/actions'
import Grid from '@mui/material/Grid'
import TabList from '@mui/lab/TabList/TabList'
import { TabContext, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import { validateFormData } from '../../utilities/helpers/FormValidator'
import dayjs from 'dayjs'
import * as XLSX from 'xlsx'

const FuelReportManagement = () => {
  const INITIAL_SORT_META: SortMetaDto = {
    field: "",
    asc: false,
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
  const [sortMeta, setSortMeta] = useState<SortMetaDto>(INITIAL_SORT_META);
  const [filteredList, setFilteredList] = useState<FuelTypeBriefDto[]>([])
  const [isFiltered, setIsFiltered] = useState(false)
  const [selectedDriverId, setSelectedDriverId] = useState(-1)
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false)
  const [isOpenFuelBillPopup, setIsOpenFuelBillPopup] = useState(false)

  const getFuelListResponse = useSelector((state: ApplicationStateDto) => state.fuel.getFuelTypesList)
  const deleteDriverResponse = useSelector((state: ApplicationStateDto) => state.driver.deleteDriver)
  const createFuelCostDetailResponse = useSelector((state: ApplicationStateDto) => state.fuel.getFuelCostDetailsList);

  const FUEL_COST_INFORMATION_FORM_INITIAL_STATE: FuelCostDetailsFormDto = {
    vehicleNo: { value: {} as OptionsDto, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    fromDate: { value: null, isRequired: false, disable: false, readonly: false, validator: 'date', error: '' },
    toDate: { value: null, isRequired: false, disable: false, readonly: false, validator: 'date', error: '' },
  }

  const setFileAlert: AlertDto = {
    message: "Please upload bill!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const fieldsErrorAlert: AlertDto = {
    message: "Mandatory fields not filled!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };


  const [helperText, setHelperText] = useState(true)
  const [inputValue, setInputValue] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | any>(null);
  const [vehicleCostBillDetailsForm, setVehicleCostBillDetailsForm] = useState(FUEL_COST_INFORMATION_FORM_INITIAL_STATE);
  const vehicleNumberRes = useSelector((state: ApplicationStateDto) => state.fuel.getVehicleNumber);
  const viewDriverResponse = useSelector((state: ApplicationStateDto) => state.driver.viewDriver)

  useEffect(() => {
    dispatch(fuelActions.getFuelTypes())
  }, [])

  useEffect(() => {
    if (getFuelListResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (getFuelListResponse.data.length > 0)
        setFilteredList(getFuelListResponse.data)
      else setFilteredList([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFuelListResponse.status])

  // useEffect(() => {
  //   dispatch(driverActions.getDriversClear())
  //   getDriversList(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE, 1)
  // },[])

  useEffect(() => {
    if (deleteDriverResponse.status === APP_ACTION_STATUS.SUCCESS) {
      getDriversList(rowsPerPage, 1)
      dispatch(driverActions.deleteDriverClear())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDriverResponse.status])

  useEffect(() => {
    if (createFuelCostDetailResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setVehicleCostBillDetailsForm(FUEL_COST_INFORMATION_FORM_INITIAL_STATE);
      console.log(createFuelCostDetailResponse.data, "check the response")
      handleOnExport();

    }
  }, [createFuelCostDetailResponse.status])

  const handleOnExport = () => {

    var wb = XLSX.utils.book_new();
    const data = DataSet[0].data.map(dataRow => dataRow.map(cell => cell.value));
    const columns = DataSet[0].columns.map(column => column.title);
    const ws = XLSX.utils.aoa_to_sheet([columns, ...data]);

    // Define the worksheet
    //  const ws = utils.json_to_sheet(data, { header: columns });
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, "Fuel Bill Details.xlsx");
  };
  const DataSet = [
    {
      columns: [
        { title: "Report ID", style: { font: { sz: "18", bold: true } }, width: { wpx: 2000 } }, // width in pixels
        { title: "Bill Amount(LKR)", style: { font: { sz: "18", bold: true } }, width: { wch: 200 } }, // width in characters
        { title: "Billing Date", style: { font: { sz: "18", bold: true } }, width: { wpx: 200 } }, // width in pixels
        { title: "Liter Count(L)", style: { font: { sz: "18", bold: true } }, width: { wpx: 200 } }, // width in pixels
        { title: "Number Of KM(KM)", style: { font: { sz: "18", bold: true } }, width: { wpx: 200 } }, // width in pixels
        { title: "Vehicle Number", style: { font: { sz: "18", bold: true } }, width: { wpx: 200 } }, // width in pixels
        { title: "Fuel Type", style: { font: { sz: "18", bold: true } }, width: { wch: 30 } }, // width in characters
        { title: "Transport Company", style: { font: { sz: "18", bold: true } }, width: { wpx: 200 } }, // width in pixels

      ],
      data: createFuelCostDetailResponse?.data?.map((data, index) => [
        { value: index + 1, style: { font: { sz: "14" } } },
        { value: `${data.billAmount}.00`, style: { font: { sz: "14" } } },
        { value: new Date(data.billingDate).toLocaleString('en-US'), style: { font: { color: { rgb: "ffffff" } }, fill: { patternType: "solid" } } },
        { value: data.literCount, style: { font: { color: { rgb: "ffffff" } }, fill: { patternType: "solid" } } },
        { value: data.numberOfKM, style: { font: { color: { rgb: "ffffff" } }, fill: { patternType: "solid" } } },
        { value: data.vehicleNumber, style: { font: { color: { rgb: "ffffff" } }, fill: { patternType: "solid" } } },
        { value: FUEL_CATEGORY.find((f: OptionsDto) => f.value === data.fuelTypeId)?.label, style: { font: { color: { rgb: "ffffff" } }, fill: { patternType: "solid" } } },
        { value: data.transportCompany, style: { font: { color: { rgb: "ffffff" } }, fill: { patternType: "solid" } } },

      ])
    }
  ]

  const getDriversList = async (r: number, p: number) => {
    const _param: getDriversSummaryParam = { pagination: r, pageNumber: p, };
    // dispatch(driverActions.getDrivers(_param))
    dispatch(fuelActions.getFuelTypes())
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    getDriversList(rowsPerPage, newPage + 1)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    getDriversList(+event.target.value, 1)
    setPage(0)
  }

  const onClearFilter = () => {
    setIsFiltered(false)
    setFilteredList(getFuelListResponse.data)
  }

  const onSelectDriverForRemove = (id: number) => {
    setSelectedDriverId(id)
    setIsOpenConfirmation(true)
  }

  const onRemoveDriver = (con: boolean) => {
    setIsOpenConfirmation(false)
    if (con) {
      dispatch(driverActions.deleteDriver(selectedDriverId))
    }
  }

  const HandleFuelBillPopup = () => {
    setIsOpenFuelBillPopup(false)
  }

  const handleInputFocus = (property: string, section: string) => {
    if (section === 'bill-information')
      setVehicleCostBillDetailsForm({
        ...vehicleCostBillDetailsForm,
        [property]: {
          ...vehicleCostBillDetailsForm[property as keyof typeof vehicleCostBillDetailsForm],
          error: null
        }
      })
  }

  const onSearchValueChange = (property: string, value: any) => {
    if (property === "vehicleNo") {
      if (value && value !== inputValue) {
        dispatch(fuelActions.getAllVehicleNumbers(value));
      }
    }
    if (value) setInputValue(value);
  };

  const handleRemove = () => {
    setSelectedFile(null)
  };

  const onSetVehicle = async () => {
    const [validateData, isValid] = await validateFormData(vehicleCostBillDetailsForm)
    setVehicleCostBillDetailsForm(validateData)

    if (isValid) {

      const { vehicleNo, fromDate, toDate } = vehicleCostBillDetailsForm;

      if (vehicleNo.value === null || fromDate.value === null || toDate.value === null) {
        // Ensure that none of the three values are null at once
        dispatch(alertActions.triggerAlert(fieldsErrorAlert));

      } else if ((fromDate.value !== null && toDate.value === null) ||
        (fromDate.value === null && toDate.value !== null)) {
        // Either both startDate and endDate should be not null or both should be null
        dispatch(alertActions.triggerAlert(fieldsErrorAlert));

      } else {
        const param: GetFuelCostDetailsDto = {
          vehicleNumber: vehicleCostBillDetailsForm.vehicleNo.value.label,
          startDate: vehicleCostBillDetailsForm.fromDate.value === null ? "" : dayjs(vehicleCostBillDetailsForm.fromDate.value).format("YYYY-MM-DD"),
          endDate: vehicleCostBillDetailsForm.toDate.value === null ? "" : dayjs(vehicleCostBillDetailsForm.toDate.value).format("YYYY-MM-DDTHH:mm")
        }
        dispatch(fuelActions.getFuelCostBillDetailsList(param))
      }
    } else {
      dispatch(alertActions.triggerAlert(fieldsErrorAlert))
    }
  }

  const onInputHandleChangeFuelBillCostInformation = (property: string, value: any) => {
    setHelperText(true)
    if (property === "vehicleNo") {
      dispatch(fuelActions.getAllVehicleNumbers(value));
      setVehicleCostBillDetailsForm({
        ...vehicleCostBillDetailsForm,
        vehicleNo: {
          ...vehicleCostBillDetailsForm.vehicleNo,
          value: value
            ? ({ label: value.label, value: value.value } as OptionsDto)
            : ({} as OptionsDto),
        },
      });
    }
    if (property === "fromDate") {
      setVehicleCostBillDetailsForm(({
        ...vehicleCostBillDetailsForm,
        fromDate: {
          ...vehicleCostBillDetailsForm.fromDate,
          value: value,
          error: null
        }
      }))
    }
    if (property === "endDate") {
      setVehicleCostBillDetailsForm(({
        ...vehicleCostBillDetailsForm,
        toDate: {
          ...vehicleCostBillDetailsForm.toDate,
          value: value,
          error: null
        }
      }))

    }
  }


  const onSortHandle = (col: string) => {
    const sorted = filteredList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setFilteredList(sorted);
  };

  const onNavigate = (mode: string, id: number) => {
    if (mode === FUEL_TYPE_MODE.CREATE)
      navigate(APP_ROUTES.ADD_FUEL_PRICE_BILL, { state: { mode: mode } })
    if (mode === DRIVER_SCREEN_MODES.EDIT)
      navigate(APP_ROUTES.EDIT_DRIVER, { state: { mode: mode, id: id } })
    if (mode === DRIVER_SCREEN_MODES.VIEW)
      navigate(APP_ROUTES.VIEW_DRIVER, { state: { mode: mode, id: id } })
  }

  const navigateTo = (value: string) => {
    if (value === "addFueltypePrice") {
      navigate(APP_ROUTES.ADD_FUEL_PRICE);
    } else {
      navigate(APP_ROUTES.ADD_FUEL_PRICE_BILL)
    }
  }

  const onFilterHandle = (col: string, value: string) => {
    setIsFiltered(true)
    setPage(0)
    const _list = getFuelListResponse.data
    const filtered = _list.filter((item) => {
      const _value = (item as any)[col];

      if (typeof _value === "boolean") {
        return _value ? value === "Yes" : value === "No";
      }
      return _value === value;
    });

    setFilteredList(filtered);
  };

  const getFilterList = (col: string): string[] => {
    if (!getFuelListResponse.isLoading) {
      const _list = getFuelListResponse.data
      return _list
        .map((item) => {
          const value = (item as any)[col];
          if (typeof value === "boolean") {
            return value ? "Yes" : "No";
          }
          return value;
        })
        .filter((value, index, array) => array.indexOf(value) === index);
    }
    else return []
  };

  const [value, setValue] = React.useState(1);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <AppLayout componentTitle="Manage Fuel Report">
        <section className="page-root">

          <TabContext value={value.toString()}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} >
                <TabList
                  sx={{
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                  onChange={handleChange}
                  orientation="horizontal"
                >
                  <Tab sx={{
                    marginBlock: "0.5rem",
                    marginLeft: "1rem",
                    "&.Mui-selected": {
                      marginLeft: "1.5rem",
                      backgroundColor: "rgba(255, 0, 0, 0.1)",
                      color: "white",
                    },
                    borderRadius: "0.8rem",
                    color: "white",
                  }}
                    label="Fuel Management"
                    value="1"
                  />
                  <Tab sx={{

                    marginBlock: "0.5rem",
                    marginLeft: "1rem",
                    "&.Mui-selected": {
                      marginInline: "1rem",
                      backgroundColor: "rgba(255, 0, 0, 0.1)",
                      color: "white",
                    },
                    borderRadius: "0.8rem",
                    color: "white",
                  }}
                    label="Download"
                    value="2"
                  />
                </TabList>
              </Grid>

              <Grid item xs={12} md={12}>
                <TabPanel value="1">
                  <FuelManagementGrid
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onHandleChangePage={handleChangePage}
                    onHandleChangeRowsPerPage={handleChangeRowsPerPage}
                    isLoading={getFuelListResponse.isLoading}
                    filteredList={filteredList || []}
                    onSortHandle={onSortHandle}
                    onFilterHandle={onFilterHandle}
                    getFilterList={getFilterList}
                    onClearFilter={onClearFilter}
                    isFiltered={isFiltered}
                    onSelectDriverForRemove={onSelectDriverForRemove}
                    setIsOpenFuelBillPopup={setIsOpenFuelBillPopup}
                    navigateTo={onNavigate}
                  />
                </TabPanel>

                <TabPanel value="2">
                  {/* DOWNLOAD COMPONENT */}
                  <DownloadFuelCostBill
                    helperText={helperText}
                    handleInputFocus={handleInputFocus}
                    billInformationForm={vehicleCostBillDetailsForm}
                    vehicleNumberRes={vehicleNumberRes}
                    isLoading={viewDriverResponse.isLoading}
                    onSearchValueChange={onSearchValueChange}
                    selectedFile={selectedFile}
                    handleRemove={handleRemove}
                    excelDownload={onSetVehicle}
                    FuelBillCostDetailResponse={createFuelCostDetailResponse}
                    onInputHandleChangeFuelBillCostInformation={onInputHandleChangeFuelBillCostInformation}
                  />
                </TabPanel>
              </Grid>

            </Grid>
          </TabContext>

          <ConfirmationDialog
            isOpenConfirmationDialog={isOpenConfirmation}
            onCallback={onRemoveDriver}
            title="Delete Driver"
            content="Do you want to delete this driver ?"
            confirmButtonTitle="Yes"
            cancelButtonTitle="No"
          />
          <FuelBillPopup
            OnPopUPClose={HandleFuelBillPopup}
            isOpenCancelAllocationPopup={isOpenFuelBillPopup}
            navigateTo={navigateTo}
          />
        </section>
      </AppLayout>
    </React.Fragment>
  )
}

export default FuelReportManagement
