import React from 'react'
import styles from './SuggestionCard.module.scss'
import { Typography } from '@mui/material'
import { GetMatchingTemplateListdto } from '../../../utilities/models'

const SuggestionCard: React.FC<{
  onClick(): void
  data: GetMatchingTemplateListdto
}> = (props) => {
  return (
    <div className={props.data.isActive ? styles.suggestionsCardActive : styles.suggestionsCard} onClick={props.onClick}>
      <div className={styles.content} >
        <Typography className={styles.rout}>{props.data.startLocation} - {props.data.toLocation}</Typography>
        <Typography className={styles.time}>{props.data.startDate}</Typography>
        <Typography className={styles.time}>{props.data.endDate}</Typography>
        <Typography className={styles.seats}>Number of available seats: {props.data.seatCount}</Typography>
      </div>
    </div>
  )
}

export default SuggestionCard
