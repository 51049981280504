import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./ViewSummary.module.scss";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { ApplicationStateDto, GuSummaryBoxDetails, StateObjectDto,} from "../../../utilities/models";
import { StyledStatusApproved, StyledStatusPending, StyledStatusRejected,} from "../../../assets/theme/theme";
import { CustomButton } from "../../Shared";

const ViewSummary: React.FC<{
  getGUSummaryResponse: | any | GuSummaryBoxDetails[] | StateObjectDto<GuSummaryBoxDetails[]>;
}> = (props) => {
  const { getGUSummaryResponse } = props;
  const [total, setTotal] = useState("");
  const [pending, setPending] = useState("");
  const [pendingPercentage, setPendingPercentage] = useState("");
  const [approved, setApproved] = useState("");
  const [approvedPercentage, setApprovedPercentage] = useState("");
  const [rejected, setRejected] = useState("");
  const [rejectedPercentage, setRejectedPercentage] = useState("");

  useEffect(() => {
    if (getGUSummaryResponse && getGUSummaryResponse.data && getGUSummaryResponse.data.length > 0) {
      const {
        total,
        pending,
        pendingPercentage,
        approved,
        approvedPercentage,
        rejected,
        rejectedPercentage,
      } = getGUSummaryResponse.data[0];

      setTotal(total);
      setPending(pending);
      setPendingPercentage(pendingPercentage);
      setApproved(approved);
      setApprovedPercentage(approvedPercentage);
      setRejected(rejected);
      setRejectedPercentage(rejectedPercentage);
    }
  }, [getGUSummaryResponse]);

  return (
    <section>
      <Grid container spacing={4}>
        <Grid item md={4}>
          <Card className={style.viewSummaryCard}>
            <CardContent>
              <Box className={style.viewSummaryCardDataRow}>
                <Box>
                  <Typography className={style.data}>{pending}</Typography>
                  <StyledStatusPending>Pending Requests</StyledStatusPending>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Box>
                  <CustomButton
                    text="Total"
                    fontSize="10px"
                    bgColor="#1e1e1e"
                    onClick={() => {}}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4}>
          <Card className={style.viewSummaryCard}>
            <CardContent>
              <Box className={style.viewSummaryCardDataRow}>
                <Box>
                  <Typography className={style.data}>{approved}</Typography>
                  <StyledStatusApproved>Approved Requests</StyledStatusApproved>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Box>
                  <CustomButton
                    text="Total"
                    fontSize="10px"
                    bgColor="#1e1e1e"
                    onClick={() => {}}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4}>
          <Card className={style.viewSummaryCard}>
            <CardContent>
              <Box className={style.viewSummaryCardDataRow}>
                <Box>
                  <Typography className={style.data}>{rejected}</Typography>
                  <StyledStatusRejected>Rejected Requests</StyledStatusRejected>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Box>
                  <CustomButton
                    text="Total"
                    fontSize="10px"
                    bgColor="#1e1e1e"
                    onClick={() => {}}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default ViewSummary;
