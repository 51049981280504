/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AppLayout from "../../templates/AppLayout/AppLayout";
import style from "./ManageDailyShiftScreen.module.scss";
import { Grid, Typography } from "@mui/material";
import { GeneralInformation, PassengerDetails } from "../../components/DailyShiftTransportation";
import CustomButton from "../../components/Shared/CustomButton/CustomButton";
import { DAILY_TRANSPORT_MODES, EMPLOYEE_CATEGORY, ONE_WAY_SHIFT, ORGANIZATION_UNIT_TYPES, USER_ROLES, } from "../../utilities/constants/app.constants";
import { OptionsDto } from "../../utilities/models/common.model";
import { LocationBriefDto } from "../../utilities/models/location.model";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationStateDto } from "../../utilities/models/state.model";
import { alertActions, locationActions, mapActions, requestActions, shiftActions, tripActions } from "../../redux/actions";
import { getVehicleListParamDto } from "../../utilities/models/trip.model";
import { VehicleTypesDto } from "../../utilities/models/vehicle.model";
import { StyledTextField } from "../../assets/theme/theme";
import { useLocation, useNavigate } from "react-router-dom";
import { ALERT_ACTION_TYPES, APP_ACTION_STATUS, APP_ROUTES, COMMON_ACTION_TYPES } from "../../utilities/constants";
import dayjs, { Dayjs } from "dayjs";
import {
  AssignRouteListDto, DSGeneralInformationDto, DSPassengerInformationDto, DsExecutionInformationDto, duplicateShiftRequestDto, editShiftRequestDto, executeShiftRequestDto, getExecuteTemplateByIdDto, newAssignRouteListDto,
  newShiftRequestDto, shiftEmployeeCategoriesDto, shiftOneWayLocationDto, shiftRequestByIdDto, shiftVehicleDetailsListDto, shiftVehicleDetailsParamDto
} from "../../utilities/models/shift.model";
import { selectedType } from "../../utilities/models/request.model";
import { validateFormData } from "../../utilities/helpers/FormValidator";
import { AlertDto } from "../../utilities/models/alert.model";
import ConfirmationDialog from "../../components/Shared/ConfirmationDialog/ConfirmationDialog";
import ExecutionInformation from "../../components/DailyShiftTransportation/ExecutionInformation/ExecutionInformation";

const ManageDailyShiftScreen = () => {

  const DS_EXECUTION_INFORMATION_INITIAL_STATE: DsExecutionInformationDto = {
    executeDate: { value: null, isRequired: true, disable: false, readonly: false, validator: "date", error: "", },
  };

  const DS_GENERAL_INFORMATION_FORM_INITIAL_STATE: DSGeneralInformationDto = {
    sbu: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    plant: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: 'object', error: '' },
    shiftGroup: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    shiftType: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    employeeCategory: { value: {} as shiftEmployeeCategoriesDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    applicableDate: { value: null, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    toLocation: { value: {} as LocationBriefDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    toLocationLatAndLng: { value: {} as selectedType, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    templateTitle: { value: "", isRequired: true, disable: false, readonly: false, validator: "text", error: "", max: 15 },
  };

  const DS_PASSENGER_INFORMATION_FORM_INITIAL_STATE: DSPassengerInformationDto = {
    isRoundTrip: { value: true, isRequired: true, disable: false, readonly: false, validator: null, error: "", },
    isOneWay: { value: {} as shiftOneWayLocationDto, isRequired: false, disable: true, readonly: false, validator: null, error: "" },
    routeEndLocation: { value: {} as LocationBriefDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    toRouteEndLocationLatAndLng: { value: {} as selectedType, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    passengerCount: { value: "", isRequired: true, disable: false, readonly: false, validator: "number", error: "", max: 5 },
    tripStartTime: { value: null, isRequired: true, disable: false, readonly: false, validator: "text", error: "", },
    tripEndTime: { value: null, isRequired: true, disable: false, readonly: false, validator: "text", error: "", },
    returnTripStartTime: { value: null, isRequired: true, disable: false, readonly: false, validator: "text", error: "", },
    returnTripEndTime: { value: null, isRequired: true, disable: false, readonly: false, validator: "text", error: "", },
    vehicleType: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: 'object', error: '' },
    passengerDocument: { value: '', isRequired: false, disable: false, readonly: false, validator: 'text', error: '' },
  }

  const location = useLocation()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [helperText, setHelperText] = useState(true)
  const [isDraft, setIsDraft] = useState(false);
  const initialValue = -1;
  const [screenMode, setScreenMode] = useState("")
  const [templateId, setTemplateId] = useState("")
  const [requestId, setRequestId] = useState(-1)
  const [vehicleId, setVehicleId] = useState(initialValue);
  const [vehicleIdEdit, setVehicleIdEdit] = useState(initialValue);
  const [vehicleLicenseNo, setVehicleLicenseNo] = useState("");
  const [isEditRoute, setIsEditRoute] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(-1)
  const [isOpenRemoveRoute, setIsOpenRemoveRoute] = useState(false)
  const [isOpenCancel, setIsOpenCancel] = useState(false)

  const [dsgenaralInformationForm, setDsGenaralInformationForm] = useState(DS_GENERAL_INFORMATION_FORM_INITIAL_STATE);
  const [dsPassengerInformationForm, setDsPassengerInformationForm] = useState(DS_PASSENGER_INFORMATION_FORM_INITIAL_STATE);
  const [dsExecutionInformationForm, setDsExecutionInformationForm] = useState(DS_EXECUTION_INFORMATION_INITIAL_STATE);

  const authorizedUser = useSelector((state: ApplicationStateDto) => state.authUser.authorizedUser);
  const shiftRequestByIdResponse = useSelector((state: ApplicationStateDto) => state.shift.getShiftRequest);
  const executionTemplateByIdRes = useSelector((state: ApplicationStateDto) => state.shift.getExecutedTemplateById)
  const shiftType = useSelector((state: ApplicationStateDto) => state.shift.getShiftTypes.data)
  const shiftTypes = useSelector((state: ApplicationStateDto) => state.shift.getShiftTypes)
  const shiftGroup = useSelector((state: ApplicationStateDto) => state.shift.getShiftGroups.data)
  const shiftGroups = useSelector((state: ApplicationStateDto) => state.shift.getShiftGroups)
  const frequentLocationsResponse = useSelector((state: ApplicationStateDto) => state.location.getFrequentLocations);
  const createShiftReqResponse = useSelector((state: ApplicationStateDto) => state.shift.postShiftRequest);
  const createDupShiftReqResponse = useSelector((state: ApplicationStateDto) => state.shift.postShiftRequestDuplicate);
  const editRequestResponse = useSelector((state: ApplicationStateDto) => state.shift.editShiftRequest);
  const executeRequestResponse = useSelector((state: ApplicationStateDto) => state.shift.executeShiftRequest);
  const [vehicleDetails, setVehicleDetails] = useState<shiftVehicleDetailsListDto[]>();

  const allSbuListByResponse = useSelector((state: ApplicationStateDto) => state.request.getSbuList);
  const allPlantsBySbuIdResponse = useSelector((state: ApplicationStateDto) => state.request.getPlantListBySbuList);
  const [VehicleTypeList, setVehicleTypeList] = useState<VehicleTypesDto[]>();
  const [passengerCount, setSpassengerCount] = useState('');
  const [startLocation, setStartLocation] = useState<selectedType | null>(null);
  const [endLocation, setEndLocation] = useState<selectedType | null>(null);
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const vehicleTypeListResponse = useSelector((state: ApplicationStateDto) => state.trip.getVehicleList);
  const vehicleDetailsListResponse = useSelector((state: ApplicationStateDto) => state.shift.getAvailableShiftVehicles);
  const [assignRouteList, setAssignRouteList] = useState<AssignRouteListDto[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | any>(null);
  const [fileString, setFileString] = useState("");
  const shiftDocumentResponse = useSelector((state: ApplicationStateDto) => state.shift.uploadFile);
  const [isDraftButtonClick, setIsDraftButtonClick] = useState(false);
  const [formValuesInitialized, setFormValuesInitialized] = useState(false);
  const [selectedVehicleID, setSelectedVehicleID] = useState<number>();
  const [duplicateMinDate, setDuplicateMinDate] = useState("");

  const setGeneralAlert: AlertDto = {
    message: "Mandatory fields not filled!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const setExcelAlert: AlertDto = {
    message: "Invalid file format! Only CSV and MS Excel files must be uploaded.",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const setNoFileAlert: AlertDto = {
    message: "File attachment is mandatory for General shift!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const setAlertTime: AlertDto = {
    message: "Incorrect time entries! Please check the validity!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const sameVehicleAlert: AlertDto = {
    message: "Vehicle already assigned on same time!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const NoVehicleSelectAlert: AlertDto = {
    message: "Please select a vehicle to add route.",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const setAlert: AlertDto = {
    message: "Please fill required field/s",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const sameLocationAlert: AlertDto = {
    message: "Please select different locations.!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const setNoFileAlert1: AlertDto = {
    message: "Please upload a file to download",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const noPassengerUpdateAlert: AlertDto = {
    message: "No passenger route to update a request.!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  useEffect(() => {
    getInitialData()

    return () => {
      dispatch(shiftActions.clearAvailableShiftVehicles())
      dispatch(shiftActions.postShiftRequestClear())
      dispatch(shiftActions.postShiftRequestDuplicateClear())
      dispatch(shiftActions.editShiftRequestClear())
      dispatch(shiftActions.executeShiftRequestClear())
      setVehicleLicenseNo("");
      setSelectedVehicleType("");
      setSpassengerCount('');
      setVehicleDetails([]);
      dispatch(shiftActions.uploadFileClear())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAsInitialState = () => {
    // dispatch(locationActions.getFrequentLocationList())
    // dispatch(shiftActions.getShiftTypes())
    // dispatch(shiftActions.getShiftGroups())
    // dispatch(requestActions.getSbuList())
    setFileString("");
  }

  useEffect(() => {
    if (createShiftReqResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (createShiftReqResponse.data === "The provided template name already exists.") {
        const setAlert: AlertDto = {
          message: createShiftReqResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
      } else {
        const setAlert: AlertDto = {
          message: createShiftReqResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'success'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
        navigate(APP_ROUTES.DS_TRANSPORTATION);
        setAsInitialState()
      }
    }
  }, [createShiftReqResponse.status])

  useEffect(() => {
    if (editRequestResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (editRequestResponse.data.startsWith("Vehicle ID")) {
        const setAlert: AlertDto = {
          message: editRequestResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        };
        dispatch(alertActions.triggerAlert(setAlert));
      } else {
        const setAlert: AlertDto = {
          message: editRequestResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'success'
          }
        };
        dispatch(alertActions.triggerAlert(setAlert));
        navigate(APP_ROUTES.DS_TRANSPORTATION);
      }
    }
  }, [editRequestResponse.status])

  useEffect(() => {
    if (executeRequestResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (executeRequestResponse.data === "Execution can be done only in the date range.") {
        const setAlert: AlertDto = {
          message: executeRequestResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
      } else if (executeRequestResponse.data === "Can't be executed on the same execution Date.") {
        const setAlert: AlertDto = {
          message: executeRequestResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
      } else {
        const setAlert: AlertDto = {
          message: executeRequestResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'success'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
        navigate(APP_ROUTES.DS_TRANSPORTATION);
        setAsInitialState()
      }
    }
  }, [executeRequestResponse.status])

  useEffect(() => {
    if (createDupShiftReqResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (createDupShiftReqResponse.data.startsWith("Vehicle ID")) {
        const setAlert: AlertDto = {
          message: createDupShiftReqResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        };
        dispatch(alertActions.triggerAlert(setAlert));
      } else if (createDupShiftReqResponse.data === "The provided template name already exists.") {
        const setAlert: AlertDto = {
          message: createDupShiftReqResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
      } else {
        const setAlert: AlertDto = {
          message: createDupShiftReqResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'success'
          }
        };
        dispatch(alertActions.triggerAlert(setAlert));
        navigate(APP_ROUTES.DS_TRANSPORTATION);
      }
    }
  }, [createDupShiftReqResponse.status])

  const onClear = () => {
    setAsInitialState()
  }

  const onNavigate = () => {
    navigate(APP_ROUTES.DS_TRANSPORTATION);
    setAsInitialState();
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedFile(file);
    event.target.value = '';
  };

  useEffect(() => {
    if (shiftDocumentResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setFileString(shiftDocumentResponse.data)
      setDsPassengerInformationForm(({
        ...dsPassengerInformationForm,
        passengerDocument: {
          ...dsPassengerInformationForm.passengerDocument,
          value: shiftDocumentResponse.data
        }
      }))
    }
  }, [shiftDocumentResponse.status])


  const handleRemove = () => {
    if (dsPassengerInformationForm.passengerDocument.value !== "") {
      dispatch(shiftActions.deleteFile(dsPassengerInformationForm.passengerDocument.value))
      setDsPassengerInformationForm(({
        ...dsPassengerInformationForm,
        passengerDocument: {
          ...dsPassengerInformationForm.passengerDocument,
          value: ''
        }
      }))
      dispatch(shiftActions.uploadFileClear())
      setSelectedFile(null);
    } else {
      dispatch(shiftActions.uploadFileClear())
      setSelectedFile(null);
    }
  };

  const handleDownload = () => {
    if (dsPassengerInformationForm.passengerDocument.value !== "") {
      dispatch(shiftActions.downloadShiftFile(dsPassengerInformationForm.passengerDocument.value));
    } else {
      dispatch(alertActions.triggerAlert(setNoFileAlert1));
    }
  };

  const getInitialData = async () => {
    setAsInitialState();
    const { mode, id } = location.state;
    setScreenMode(mode);
    setTemplateId(id);

    dispatch(requestActions.getSbuList())
    dispatch(locationActions.getFrequentLocationList())
    dispatch(shiftActions.getShiftGroups())
    dispatch(shiftActions.getShiftTypes())

    if (mode !== DAILY_TRANSPORT_MODES.CREATE) {
      if (mode === DAILY_TRANSPORT_MODES.VIEW_EXECUTED) {
        setRequestId(id);
        dispatch(shiftActions.getExecutedTemplateById(id))
      } else {
        setRequestId(id);
        dispatch(shiftActions.getShiftRequestById(id))
      }
    }

    if (mode === DAILY_TRANSPORT_MODES.CREATE) {
      if (authorizedUser.data && authorizedUser.data !== undefined) {
        const _dataSbu = authorizedUser.data.hierarchy.find((d) => d.typeId === ORGANIZATION_UNIT_TYPES.SBU)
        const _dataPlant = authorizedUser.data.hierarchy.find((d) => d.typeId === ORGANIZATION_UNIT_TYPES.PLANT)

        if (_dataPlant && _dataSbu) {
          if (authorizedUser.data.roleId === USER_ROLES.TRANSPORT_MANAGER || authorizedUser.data.roleId === USER_ROLES.DIVISION_USER) {
            setDsGenaralInformationForm((dsgenaralInformationForm) => ({
              ...dsgenaralInformationForm,
              sbu: {
                ...dsgenaralInformationForm.sbu,
                value: { label: _dataSbu?.name, value: _dataSbu?.id },
                disable: true,
              }
            }))

            setDsGenaralInformationForm((dsgenaralInformationForm) => ({
              ...dsgenaralInformationForm,
              plant: {
                ...dsgenaralInformationForm.plant,
                value: { label: _dataPlant?.name, value: _dataPlant?.id },
                disable: true,
              }
            }))
          }

          if (authorizedUser.data.roleId === USER_ROLES.SBU_MANAGER) {
            setDsGenaralInformationForm((dsgenaralInformationForm) => ({
              ...dsgenaralInformationForm,
              sbu: {
                ...dsgenaralInformationForm.sbu,
                value: { label: authorizedUser.data.hierarchy[0].name, value: authorizedUser.data.hierarchy[0].typeId },
                disable: true
              }
            }))
            dispatch(requestActions.getPlantListBySbuId(authorizedUser.data.hierarchy[0].id));

            setDsGenaralInformationForm((dsgenaralInformationForm) => ({
              ...dsgenaralInformationForm,
              plant: {
                ...dsgenaralInformationForm.plant,
                value: { label: _dataPlant?.name, value: _dataPlant?.id },
              }
            }))
          }

          if (authorizedUser.data.roleId === USER_ROLES.SUPER_ADMIN) {
            setDsGenaralInformationForm((dsgenaralInformationForm) => ({
              ...dsgenaralInformationForm,
              sbu: {
                ...dsgenaralInformationForm.sbu,
                value: { label: _dataSbu?.name, value: _dataSbu?.id }
              }
            }))

            setDsGenaralInformationForm((dsgenaralInformationForm) => ({
              ...dsgenaralInformationForm,
              plant: {
                ...dsgenaralInformationForm.plant,
                value: { label: _dataPlant?.name, value: _dataPlant?.id }
              }
            }))
          }
        }
      }
    }
    setIsDraft(false);
  };

  const convertTimeStringToFormattedDate = (timeString: any) => {
    if (typeof timeString !== 'string' || !timeString.includes(':')) {
      return "Invalid time format";
    }

    const currentDate = new Date();
    const [hours, minutes, seconds] = timeString?.split(':')?.map(Number);

    currentDate.setHours(hours);
    currentDate.setMinutes(minutes);
    currentDate.setSeconds(seconds);

    const options: any = {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      timeZone: 'GMT'
    };

    const formattedDate = currentDate.toLocaleString('en-US', options);
    return formattedDate;
  }

  useEffect(() => {
    if (shiftRequestByIdResponse.status === APP_ACTION_STATUS.SUCCESS && shiftRequestByIdResponse.data !== null &&
      (screenMode === DAILY_TRANSPORT_MODES.VIEW || screenMode === DAILY_TRANSPORT_MODES.EDIT || screenMode === DAILY_TRANSPORT_MODES.EXECUTE ||
        screenMode === DAILY_TRANSPORT_MODES.DUPLICATE)) {

      const _data: shiftRequestByIdDto = shiftRequestByIdResponse.data;
      const _isDisable = screenMode === DAILY_TRANSPORT_MODES.VIEW || screenMode === DAILY_TRANSPORT_MODES.EXECUTE;
      const _isEdit = screenMode === DAILY_TRANSPORT_MODES.EDIT;
      const _isDuplicate = screenMode === DAILY_TRANSPORT_MODES.DUPLICATE;

      if (_data && frequentLocationsResponse.data && shiftTypes.data && shiftGroups.data) {
        const _toLocation = frequentLocationsResponse.data.find((l) => l.location === _data.mainDestination);
        const _empCategory = EMPLOYEE_CATEGORY.find((e) => e.value === _data.employeeCategory);

        let _shiftType;
        let _shiftGroup;

        if (_data.shiftType === null) {
          _shiftType = null
        } else {
          _shiftType = shiftTypes.data.find((s) => s.shiftType === _data.shiftType);
        }

        if (_shiftGroup) {
          _shiftGroup = shiftGroups.data.find((s) => s.name === _data.shiftGroup);
        } else {
          _shiftGroup = shiftGroups.data.find((s) => s.name === _data.shiftGroup);
        }

        const apiShiftDateRanges = _data.dateRanges;
        const timestampArrays = apiShiftDateRanges.map(dateRange => [
          dayjs(dateRange.dateRangeFrom).unix() * 1000,
          dayjs(dateRange.dateRangeTo).unix() * 1000
        ]);
        setDateEditRange(timestampArrays);
        setDuplicateMinDate(_data.dateRanges[0].dateRangeTo)

        setDsGenaralInformationForm({
          ...dsgenaralInformationForm,
          sbu: {
            ...dsgenaralInformationForm.sbu,
            value: { label: _data.sbuName, value: _data.sbuId } as OptionsDto,
            readonly: _isDisable,
            disable: _isDisable || _isEdit || _isDuplicate,
          },
          plant: {
            ...dsgenaralInformationForm.plant,
            value: { label: _data.plantName, value: _data.plantId } as OptionsDto,
            readonly: _isDisable,
            disable: _isDisable || _isEdit || _isDuplicate,
          },
          toLocation: {
            ...dsgenaralInformationForm.toLocation,
            value: { location: _toLocation?.location, id: _toLocation?.id, } as LocationBriefDto,
            readonly: _isDisable,
          },
          shiftGroup: {
            ...dsgenaralInformationForm.shiftGroup,
            value: { label: _shiftGroup?.name, value: _shiftGroup?.id } as OptionsDto,
            readonly: _isDisable,
            disable: _isDisable || _isEdit || _isDuplicate,
          },
          shiftType: {
            ...dsgenaralInformationForm.shiftType,
            value: { label: _shiftType?.shiftType, value: _shiftType?.id } as OptionsDto,
            readonly: _isDisable,
            disable: _isDisable || !_shiftType || _isDuplicate,
          },
          applicableDate: {
            ...dsgenaralInformationForm.applicableDate,
            value: _isDuplicate ? null : timestampArrays as unknown as Dayjs[],
            readonly: _isDisable,
            disable: _isDisable || _isEdit,
          },
          employeeCategory: {
            ...dsgenaralInformationForm.employeeCategory,
            value: { label: _empCategory?.label, value: _empCategory?.value, } as shiftEmployeeCategoriesDto,
            readonly: _isDisable || _isEdit,
            disable: _isDisable || _isEdit || _isDuplicate,
          },
          templateTitle: {
            ...dsgenaralInformationForm.templateTitle,
            value: _data.name,
            readonly: _isDisable || _isEdit,
            disable: _isDisable || _isEdit
          }
        })

        const _passengerRouteList = _data.routePassengers.map((p, index) => {
          let formattedStartTime = null;
          let formattedEndTime = null;;
          let formattedReturnStartTime = null;
          let formattedReturnEndTime = null;

          if (p.startTime !== null && p.endTime !== null) {
            formattedStartTime = convertTimeStringToFormattedDate(p.startTime);
            formattedEndTime = convertTimeStringToFormattedDate(p.endTime);
          }

          if (p.returnStartTime !== null && p.returnEndTime !== null) {
            formattedReturnStartTime = convertTimeStringToFormattedDate(p.returnStartTime);
            formattedReturnEndTime = convertTimeStringToFormattedDate(p.returnEndTime);;
          }

          const _vehicleId = p.vehicleId;
          const _isRoundTrip = p.isRoundTrip;
          const _excelDoc = p.excelDocument;
          const _passengerCount = p.passengerCount;
          const _vehicleTypeName = p.vehicleTypeName;
          const _vehicleTypeId = p.vehicleTypeId;
          const _routeStartLocation = p.startLocation;
          const _routeStartLocationId = p.startLocationId;
          const _routeStartLat = p.startLocationLatitude;
          const _routeStartLon = p.startLocationLongitude;
          const _routeEndLocation = p.toLocation;
          const _routeEndLat = p.toLocationLatitude;
          const _routeEndLon = p.toLocationLongitude;
          const _startTime = formattedStartTime;
          const _endTime = formattedEndTime;
          const _returnStartTime = formattedReturnStartTime;
          const _returnEndTime = formattedReturnEndTime;
          const _projectedMileage = p.projectedMileage;
          const _vehicleLicenseNo = p.licenseNumber;

          const _passengerRoute: AssignRouteListDto = {
            id: index + 1,
            oneWay: "",
            vehicleId: _vehicleId,
            vehicleLicenseNo: _vehicleLicenseNo,
            vehicleType: _vehicleTypeName,
            vehicleTypeId: _vehicleTypeId,
            excelDocument: _excelDoc,
            isRoundTrip: _isRoundTrip,
            passengerCount: _passengerCount.toString(),
            startLocation: _routeStartLocation ?? ({} as LocationBriefDto),
            startLocationId: _routeStartLocationId,
            startLocationLatitude: _routeStartLat ?? "",
            startLocationLongitude: _routeStartLon ?? "",
            toLocation: _routeEndLocation ?? ({} as LocationBriefDto),
            toLocationLatitude: _routeEndLat ?? "",
            toLocationLongitude: _routeEndLon ?? "",
            startTime: _startTime,
            endTime: _endTime,
            returnStartTime: _returnStartTime,
            returnEndTime: _returnEndTime,
            projectedMileage: _projectedMileage,
          };
          return _passengerRoute;
        });
        setAssignRouteList(_passengerRouteList);
      }
    }
  }, [shiftRequestByIdResponse.status, frequentLocationsResponse.status, shiftGroups.status, shiftTypes.status])

  useEffect(() => {
    if (executionTemplateByIdRes.status === APP_ACTION_STATUS.SUCCESS && executionTemplateByIdRes.data !== null &&
      screenMode === DAILY_TRANSPORT_MODES.VIEW_EXECUTED) {
      const _data: getExecuteTemplateByIdDto = executionTemplateByIdRes.data;
      const _isDisable = screenMode === DAILY_TRANSPORT_MODES.VIEW_EXECUTED;
      const _isEdit = screenMode === DAILY_TRANSPORT_MODES.VIEW_EXECUTED;

      if (_data && frequentLocationsResponse.data && shiftTypes.data && shiftGroups.data) {

        const _toLocation = frequentLocationsResponse.data.find((l) => l.location === _data.mainDestination);
        const _empCategory = EMPLOYEE_CATEGORY.find((e) => e.value === _data.employeeCategory);

        let _shiftType;
        let _shiftGroup;

        if (_data.shiftType === null) {
          _shiftType = null
        } else {
          _shiftType = shiftTypes.data.find((s) => s.shiftType === _data.shiftType);
        }

        if (_shiftGroup) {
          _shiftGroup = shiftGroups.data.find((s) => s.name === _data.shiftGroup);
        } else {
          _shiftGroup = shiftGroups.data.find((s) => s.name === _data.shiftGroup);
        }

        const apiShiftDateRanges = _data.dateRanges;
        const timestampArrays = apiShiftDateRanges.map(dateRange => [
          dayjs(dateRange.dateRangeFrom).unix() * 1000,
          dayjs(dateRange.dateRangeTo).unix() * 1000
        ]);

        setDateEditRange(timestampArrays);

        setDsGenaralInformationForm({
          ...dsgenaralInformationForm,
          sbu: {
            ...dsgenaralInformationForm.sbu,
            value: { label: _data.sbuName, value: _data.sbuId } as OptionsDto,
            readonly: _isDisable,
            disable: _isDisable || _isEdit,
          },
          plant: {
            ...dsgenaralInformationForm.plant,
            value: { label: _data.plantName, value: _data.plantId } as OptionsDto,
            readonly: _isDisable,
            disable: _isDisable || _isEdit,
          },
          toLocation: {
            ...dsgenaralInformationForm.toLocation,
            value: { location: _toLocation?.location, id: _toLocation?.id, } as LocationBriefDto,
            readonly: _isDisable,
          },
          shiftGroup: {
            ...dsgenaralInformationForm.shiftGroup,
            value: { label: _shiftGroup?.name, value: _shiftGroup?.id } as OptionsDto,
            readonly: _isDisable,
            disable: _isDisable || _isEdit,
          },
          shiftType: {
            ...dsgenaralInformationForm.shiftType,
            value: { label: _shiftType?.shiftType, value: _shiftType?.id } as OptionsDto,
            readonly: _isDisable,
            disable: _isDisable || !_shiftType,
          },
          applicableDate: {
            ...dsgenaralInformationForm.applicableDate,
            value: timestampArrays as unknown as Dayjs[],
            readonly: _isDisable,
            disable: _isDisable || _isEdit,
          },
          employeeCategory: {
            ...dsgenaralInformationForm.employeeCategory,
            value: { label: _empCategory?.label, value: _empCategory?.value, } as shiftEmployeeCategoriesDto,
            readonly: _isDisable || _isEdit,
            disable: _isDisable || _isEdit,
          },
          templateTitle: {
            ...dsgenaralInformationForm.templateTitle,
            value: _data.name,
            readonly: _isDisable || _isEdit,
          }
        })

        const _passengerRouteList = _data.routePassengers.map((p, index) => {
          let formattedStartTime = null;
          let formattedEndTime = null;;
          let formattedReturnStartTime = null;
          let formattedReturnEndTime = null;

          if (p.startTime !== null && p.endTime !== null) {
            formattedStartTime = convertTimeStringToFormattedDate(p.startTime);
            formattedEndTime = convertTimeStringToFormattedDate(p.endTime);
          }

          if (p.returnStartTime !== null && p.returnEndTime !== null) {
            formattedReturnStartTime = convertTimeStringToFormattedDate(p.returnStartTime);
            formattedReturnEndTime = convertTimeStringToFormattedDate(p.returnEndTime);;
          }

          const _vehicleId = p.vehicleId;
          const _isRoundTrip = p.isRoundTrip;
          const _excelDoc = p.excelDocument;
          const _passengerCount = p.passengerCount;
          const _vehicleTypeName = p.vehicleTypeName;
          const _vehicleTypeId = p.vehicleTypeId;
          const _routeStartLocation = p.startLocation;
          const _routeStartLocationId = p.startLocationId;
          const _routeStartLat = p.startLocationLatitude;
          const _routeStartLon = p.startLocationLongitude;
          const _routeEndLocation = p.toLocation;
          const _routeEndLat = p.toLocationLatitude;
          const _routeEndLon = p.toLocationLongitude;
          const _startTime = formattedStartTime;
          const _endTime = formattedEndTime;
          const _returnStartTime = formattedReturnStartTime;
          const _returnEndTime = formattedReturnEndTime;
          const _projectedMileage = p.projectedMileage;
          const _vehicleLicenseNo = p.licenseNumber;

          const _passengerRoute: AssignRouteListDto = {
            id: index + 1,
            oneWay: "",
            vehicleId: _vehicleId,
            vehicleLicenseNo: _vehicleLicenseNo,
            vehicleType: _vehicleTypeName,
            vehicleTypeId: _vehicleTypeId,
            excelDocument: _excelDoc,
            isRoundTrip: _isRoundTrip,
            passengerCount: _passengerCount.toString(),
            startLocation: _routeStartLocation ?? ({} as LocationBriefDto),
            startLocationId: _routeStartLocationId,
            startLocationLatitude: _routeStartLat ?? "",
            startLocationLongitude: _routeStartLon ?? "",
            toLocation: _routeEndLocation ?? ({} as LocationBriefDto),
            toLocationLatitude: _routeEndLat ?? "",
            toLocationLongitude: _routeEndLon ?? "",
            startTime: _startTime,
            endTime: _endTime,
            returnStartTime: _returnStartTime,
            returnEndTime: _returnEndTime,
            projectedMileage: _projectedMileage,
          };
          return _passengerRoute;
        });

        setAssignRouteList(_passengerRouteList);
      }
    }
  }, [executionTemplateByIdRes.status, frequentLocationsResponse.status, shiftGroups.status, shiftTypes.status])

  const onInputHandleChangeGeneralInformation = (property: string, value: any) => {
    setHelperText(true)
    if (property === "sbu") {
      setDsGenaralInformationForm({
        ...dsgenaralInformationForm,
        sbu: {
          ...dsgenaralInformationForm.sbu,
          value: value ? value : ({} as OptionsDto),
        },
        plant: {
          ...dsgenaralInformationForm.plant,
          value: {} as OptionsDto,
          disable: false,
        },
      });
      dispatch(requestActions.getPlantListBySbuId(value.value));
    }

    if (property === "plant") {
      setDsGenaralInformationForm({
        ...dsgenaralInformationForm,
        plant: {
          ...dsgenaralInformationForm.plant,
          value: value ? value : ({} as OptionsDto),
        },
      });
    }

    if (property === "empCategory" && value.value === EMPLOYEE_CATEGORY[0].value) {
      setDsGenaralInformationForm({
        ...dsgenaralInformationForm,
        employeeCategory: {
          ...dsgenaralInformationForm.employeeCategory,
          value: { value: value.value, label: value.label } as shiftEmployeeCategoriesDto,
          error: null,
        },
        shiftGroup: {
          ...dsgenaralInformationForm.shiftGroup,
          disable: false,
        },
        shiftType: {
          ...dsgenaralInformationForm.shiftType,
          disable: false,
        }
      });
    }

    if (property === "empCategory" && value.value === EMPLOYEE_CATEGORY[1].value) {
      setDsGenaralInformationForm({
        ...dsgenaralInformationForm,
        employeeCategory: {

          ...dsgenaralInformationForm.employeeCategory,
          value: { label: value.label, value: value.value, } as shiftEmployeeCategoriesDto,
          error: null,
        },
        shiftGroup: {
          ...dsgenaralInformationForm.shiftGroup,
          value: {} as OptionsDto,
          disable: true,
          isRequired: false,
          error: null,
        },
        shiftType: {
          ...dsgenaralInformationForm.shiftType,
          value: {} as OptionsDto,
          disable: true,
          isRequired: false,
          error: null,
        }

      });
    }

    if (property === "shiftGroup") {
      setDsGenaralInformationForm({
        ...dsgenaralInformationForm,
        shiftGroup: {
          ...dsgenaralInformationForm.shiftGroup,
          value: { label: value.label, value: value.value } as OptionsDto,
          error: null,
        },
      });
    }

    if (property === "shiftType") {
      setDsGenaralInformationForm({
        ...dsgenaralInformationForm,
        shiftType: {
          ...dsgenaralInformationForm.shiftType,
          value: { label: value.label, value: value.value } as OptionsDto,
          error: null,
        },
      });
    }

    if (property === "applicableDate") {
      setDsGenaralInformationForm({
        ...dsgenaralInformationForm,
        applicableDate: {
          ...dsgenaralInformationForm.applicableDate,
          value: value,
          error: null
        },
      })
    }

    if (property === "toLocation") {
      const specificItem = frequentLocationsResponse.data.find((item) => item.id === value.value);
      if (specificItem) {
        setStartLocation({ lat: Number(specificItem.latitude), lng: Number(specificItem.longitude), });
      }

      setDsGenaralInformationForm({
        ...dsgenaralInformationForm,
        toLocation: {
          ...dsgenaralInformationForm.toLocation,
          value: { location: value.label, id: value.value, } as LocationBriefDto,
          error: null,
        },
        toLocationLatAndLng: {
          ...dsgenaralInformationForm.toLocationLatAndLng,
          value: { lat: Number(specificItem?.latitude) || null, lng: Number(specificItem?.longitude) || null, } as selectedType,
        }
      });
    }

    if (property === "templateTitle") {
      setDsGenaralInformationForm({
        ...dsgenaralInformationForm,
        templateTitle: {
          ...dsgenaralInformationForm.templateTitle,
          value: value,
          error: null,
        },
      });
    }
  }

  const onInputHandleChangePassengerInformation = (property: string, value: any) => {
    setHelperText(true);
    if (property === "isRoundTrip" && !value) {
      setDsPassengerInformationForm({
        ...dsPassengerInformationForm,
        isRoundTrip: {
          ...dsPassengerInformationForm.isRoundTrip,
          value: value,
        },
        returnTripStartTime: {
          ...dsPassengerInformationForm.returnTripStartTime,
          value: null,
          isRequired: false,
          disable: true,
          error: null,
        },
        returnTripEndTime: {
          ...dsPassengerInformationForm.returnTripEndTime,
          value: null,
          isRequired: false,
          disable: true,
          error: null,
        },
        isOneWay: {
          ...dsPassengerInformationForm.isOneWay,
          disable: false,
          value: { value: 1, label: "Start Location" } as shiftOneWayLocationDto
        }
      });
    }

    if (property === "isRoundTrip" && value) {
      setDsPassengerInformationForm(DS_PASSENGER_INFORMATION_FORM_INITIAL_STATE);
    }

    if (property === "shiftOneWayLocation") {
      if (value.value === 1) {
        setDsPassengerInformationForm({
          ...dsPassengerInformationForm,
          isOneWay: {
            ...dsPassengerInformationForm.isOneWay,
            value: value ? value : ({} as shiftOneWayLocationDto)
          },
          returnTripStartTime: {
            ...dsPassengerInformationForm.returnTripStartTime,
            disable: true,
            isRequired: false,
          },
          returnTripEndTime: {
            ...dsPassengerInformationForm.returnTripEndTime,
            disable: true,
            isRequired: false,
          },
          tripStartTime: {
            ...dsPassengerInformationForm.tripStartTime,
            disable: false,
            isRequired: false,
          },
          tripEndTime: {
            ...dsPassengerInformationForm.tripEndTime,
            disable: false,

          },
        })
      } else {
        setDsPassengerInformationForm({
          ...dsPassengerInformationForm,
          isOneWay: {
            ...dsPassengerInformationForm.isOneWay,
            value: value ? value : ({} as shiftOneWayLocationDto)
          },
          tripStartTime: {
            ...dsPassengerInformationForm.tripStartTime,
            disable: true,
            isRequired: false,
          },
          tripEndTime: {
            ...dsPassengerInformationForm.tripEndTime,
            disable: true,
            isRequired: false,
          },
          returnTripStartTime: {
            ...dsPassengerInformationForm.returnTripStartTime,
            disable: false,
            isRequired: true,
            error: ""
          },
          returnTripEndTime: {
            ...dsPassengerInformationForm.returnTripEndTime,
            disable: false,
            isRequired: true,
            error: ""
          }
        })
      }
    }

    if (property === "tripStartTime") {
      setDsPassengerInformationForm({
        ...dsPassengerInformationForm,
        tripStartTime: {
          ...dsPassengerInformationForm.tripStartTime,
          value: value,
        },
      });
    }

    if (property === "tripEndTime") {
      setDsPassengerInformationForm({
        ...dsPassengerInformationForm,
        tripEndTime: {
          ...dsPassengerInformationForm.tripEndTime,
          value: value,
        },
      });
    }

    if (property === "returnTripStartTime") {
      setDsPassengerInformationForm({
        ...dsPassengerInformationForm,
        returnTripStartTime: {
          ...dsPassengerInformationForm.returnTripStartTime,
          value: value,
        },
      });
    }

    if (property === "returnTripEndTime") {
      setDsPassengerInformationForm({
        ...dsPassengerInformationForm,
        returnTripEndTime: {
          ...dsPassengerInformationForm.returnTripEndTime,
          value: value,
        },
      });
    }

    if (property === "passengerCount") {
      setSpassengerCount(value)
      setDsPassengerInformationForm({
        ...dsPassengerInformationForm,
        passengerCount: {
          ...dsPassengerInformationForm.passengerCount,
          value: value,
          error: null,
        },
      });
    }

    if (property === "endLocation") {
      let _isError = false
      if (dsgenaralInformationForm.toLocation.value.id !== undefined) {
        if (dsgenaralInformationForm.toLocation.value.id === value.value) {
          _isError = true
          dispatch(alertActions.triggerAlert(sameLocationAlert));
        }
      }
      if (!_isError) {
        const specificItem = frequentLocationsResponse.data.find((item) => item.id === value.value);
        if (specificItem) {
          setEndLocation({ lat: Number(specificItem.latitude), lng: Number(specificItem.longitude), });
        }

        setDsPassengerInformationForm({
          ...dsPassengerInformationForm,
          routeEndLocation: {
            ...dsPassengerInformationForm.routeEndLocation,
            value: { location: value.label, id: value.value, } as LocationBriefDto,
            error: null,
          },
          toRouteEndLocationLatAndLng: {
            ...dsPassengerInformationForm.toRouteEndLocationLatAndLng,
            value: { lat: Number(specificItem?.latitude) || null, lng: Number(specificItem?.longitude) || null, } as selectedType,
          }
        });
      }
    }

    if (property === 'vehicleType') {
      setDsPassengerInformationForm(() => ({
        ...dsPassengerInformationForm,
        vehicleType: {
          ...dsPassengerInformationForm.vehicleType,
          value: value,
        }
      }))
    }
  }

  const onClearPassengerInfoForm = () => {
    setDsPassengerInformationForm(DS_PASSENGER_INFORMATION_FORM_INITIAL_STATE);
    setSpassengerCount('');
    setVehicleTypeList([]);
    setVehicleDetails([]);
    setSelectedVehicleType("");
    setVehicleId(initialValue);
    setVehicleLicenseNo("");

    if (fileString !== "") {
      // dispatch(shiftActions.deleteFile(fileString))
      dispatch(shiftActions.uploadFileClear())
      setFileString("");
    }
    setIsEditRoute(false);
  }

  const handleInputFocus = (property: string, section: string) => {
    if (section === 'GI') {
      setDsGenaralInformationForm({
        ...dsgenaralInformationForm,
        [property]: {
          ...dsgenaralInformationForm[property as keyof typeof dsgenaralInformationForm],
          error: null
        }
      })
    }
    if (section === 'PI') {
      setDsPassengerInformationForm({
        ...dsPassengerInformationForm,
        [property]: {
          ...dsPassengerInformationForm[property as keyof typeof dsPassengerInformationForm],
          error: null
        }
      })
    }

    if (section === 'EI') {
      setDsExecutionInformationForm({
        ...dsExecutionInformationForm,
        [property]: {
          ...dsExecutionInformationForm[property as keyof typeof dsExecutionInformationForm],
          error: null
        }
      })
    }
  }

  const onInputHandleChangeExecInformation = (property: string, value: any) => {
    if (property === "executionDate") {
      setDsExecutionInformationForm({
        ...dsExecutionInformationForm,
        executeDate: {
          ...dsExecutionInformationForm.executeDate,
          value: value
        }
      })
    }
  }

  const [dateEditRange, setDateEditRange] = useState<number[][]>([]);

  //CREATE DS
  const onCreateDailyShiftTemplate = async (isDraft: boolean) => {
    if (isDraft) setIsDraftButtonClick(isDraft);

    const [validateData, isValid] = await validateFormData(dsgenaralInformationForm);
    setDsGenaralInformationForm(validateData);

    if (isValid) {
      const applicableDates = dsgenaralInformationForm.applicableDate?.value as Dayjs[];
      const dateStrings = applicableDates.map(date => date.toString());

      // const shiftDateRanges = []
      // for (const dateRange of dateStrings) {
      //   const [dateRangeFrom, dateRangeTo] = dateRange.split(',');
      //   if (dateRangeTo) {
      //     shiftDateRanges.push({
      //       "dateRangeFrom": dayjs(dateRangeFrom).add(330, 'minute').format("YYYY-MM-DDTHH:mm"),
      //       "dateRangeTo": dayjs(dateRangeTo).add(330, 'minute').format("YYYY-MM-DDTHH:mm")
      //     });
      //   } 
      //   else {
      //     shiftDateRanges.push({
      //       "dateRangeFrom": dayjs(dateRange).add(330, 'minute').format("YYYY-MM-DDTHH:mm"),
      //       "dateRangeTo": dayjs(dateRange).add(330, 'minute').format("YYYY-MM-DDTHH:mm")
      //     });
      //   }
      // }

      const shiftDateRanges = [
        {
          "dateRangeFrom": dayjs(dateStrings[0]).format("YYYY-MM-DDTHH:mm"),
          "dateRangeTo": dayjs(dateStrings[dateStrings.length - 1]).format("YYYY-MM-DDTHH:mm")
        }
      ];

      // const templateDateRange = shiftDateRanges.map((range) => {
      //   const formattedFrom = dayjs(range.dateRangeFrom).format("YYYY-MM-DD");
      //   const formattedTo = dayjs(range.dateRangeTo).format("YYYY-MM-DD");
      //   return `${formattedFrom} - ${formattedTo}`;
      // });

      const _assignRouteList = assignRouteList.map((a) => {

        let formattedStartTime = null;
        let formattedEndTime = null;
        let formattedReturnStartTime = null;
        let formattedReturnEndTime = null;

        if (a.startTime !== null && a.endTime !== null) {
          formattedStartTime = dayjs(a.startTime as unknown as Dayjs).format("HH:mm:ss");;
          formattedEndTime = dayjs(a.endTime as unknown as Dayjs).format("HH:mm:ss");;
        }
        if (a.returnStartTime !== null && a.returnEndTime !== null) {
          formattedReturnStartTime = dayjs(a.returnStartTime as unknown as Dayjs).format("HH:mm:ss");
          formattedReturnEndTime = dayjs(a.returnEndTime as unknown as Dayjs).format("HH:mm:ss");
        }

        const _item: newAssignRouteListDto = {
          vehicleId: a.vehicleId,
          excelDocument: a.excelDocument,
          startLocation: a.startLocation,
          startLocationLatitude: a.startLocationLatitude,
          startLocationLongitude: a.startLocationLongitude,
          toLocation: a.toLocation,
          toLocationLatitude: a.toLocationLatitude,
          toLocationLongitude: a.toLocationLongitude,
          startTime: formattedStartTime,
          endTime: formattedEndTime,
          returnStartTime: formattedReturnStartTime,
          returnEndTime: formattedReturnEndTime,
          passengerCount: a.passengerCount,
          projectedMileage: a.projectedMileage,
        }
        return _item
      })

      if (_assignRouteList.length > 0) {
        if (screenMode === DAILY_TRANSPORT_MODES.CREATE) {
          const _payload: newShiftRequestDto = {
            employeeCategory: dsgenaralInformationForm.employeeCategory.value.value,
            templateName: dsgenaralInformationForm.templateTitle.value,
            shiftId: Number(dsgenaralInformationForm.shiftType.value.value),
            shiftGroupId: Number(dsgenaralInformationForm.shiftGroup.value.value),
            sbu: Number(dsgenaralInformationForm.sbu.value.value),
            plant: Number(dsgenaralInformationForm.plant.value.value),
            mainDestination: dsgenaralInformationForm.toLocation.value.location,
            mainLocationLatitude: dsgenaralInformationForm.toLocationLatAndLng?.value?.lat?.toString() || "",
            mainLocationLongitude: dsgenaralInformationForm.toLocationLatAndLng?.value?.lng?.toString() || "",
            shiftDateRanges: shiftDateRanges,
            routePassengers: _assignRouteList,
            isDraft: isDraft,
          }
          dispatch(shiftActions.postShiftRequest(_payload));
        } else {
          const _payload: duplicateShiftRequestDto = {
            id: requestId,
            employeeCategory: dsgenaralInformationForm.employeeCategory.value.value,
            templateName: dsgenaralInformationForm.templateTitle.value,
            shiftId: Number(dsgenaralInformationForm.shiftType.value.value),
            shiftGroupId: Number(dsgenaralInformationForm.shiftGroup.value.value),
            sbu: Number(dsgenaralInformationForm.sbu.value.value),
            plant: Number(dsgenaralInformationForm.plant.value.value),
            mainDestination: dsgenaralInformationForm.toLocation.value.location,
            mainLocationLatitude: dsgenaralInformationForm.toLocationLatAndLng?.value?.lat?.toString() || "",
            mainLocationLongitude: dsgenaralInformationForm.toLocationLatAndLng?.value?.lng?.toString() || "",
            shiftDateRanges: shiftDateRanges,
            routePassengers: _assignRouteList,
            isDraft: isDraft,
          }
          dispatch(shiftActions.postShiftRequestDuplicate(_payload));
        }
      } else {
        dispatch(alertActions.triggerAlert(setAlert));
      }
    } else {
      dispatch(alertActions.triggerAlert(setAlert));
    }
  }

  //EDIT DS
  const editRequest = async () => {
    const [validateData, isValid] = await validateFormData(dsgenaralInformationForm);
    setDsGenaralInformationForm(validateData);
    if (isValid) {
      let _isError = false;

      const unixConvertedSetValue = dateEditRange as unknown as Dayjs[];
      const formUpdatedValue = dsgenaralInformationForm.applicableDate.value;

      const shiftDateRanges = []
      const applicableDates = dsgenaralInformationForm.applicableDate?.value as Dayjs[];
      const dateStrings = applicableDates.map(date => date.toString());

      if (unixConvertedSetValue === formUpdatedValue) {
        for (const dateRange of dateStrings) {
          const [dateRangeFromUnix, dateRangeToUnix] = dateRange.split(',');

          const dateRangeFrom = dayjs.unix(Number(dateRangeFromUnix) / 1000).format("YYYY-MM-DDTHH:mm");
          const dateRangeTo = dayjs.unix(Number(dateRangeToUnix) / 1000).format("YYYY-MM-DDTHH:mm");

          shiftDateRanges.push({
            "dateRangeFrom": dateRangeFrom,
            "dateRangeTo": dateRangeTo
          });
        }
      } else {
        for (const dateRange of dateStrings) {
          const [dateRangeFrom, dateRangeTo] = dateRange.split(',');

          if (dateRangeTo) {
            shiftDateRanges.push({
              "dateRangeFrom": dayjs(dateRangeFrom).format("YYYY-MM-DDTHH:mm"),
              "dateRangeTo": dayjs(dateRangeTo).format("YYYY-MM-DDTHH:mm")
            });
          } else {
            shiftDateRanges.push({
              "dateRangeFrom": dayjs(dateRange).format("YYYY-MM-DDTHH:mm"),
              "dateRangeTo": dayjs(dateRange).format("YYYY-MM-DDTHH:mm")
            });
          }
        }
      }


      if (assignRouteList.length === 0) {
        _isError = true;
        dispatch(alertActions.triggerAlert(noPassengerUpdateAlert));
      }

      if (!_isError) {
        const _assignRouteList = assignRouteList.map((a) => {

          let formattedStartTime = null;
          let formattedEndTime = null;
          let formattedReturnStartTime = null;
          let formattedReturnEndTime = null;

          if (a.startTime !== null && a.endTime !== null) {
            formattedStartTime = dayjs(a.startTime as unknown as Dayjs).format("HH:mm:ss");
            formattedEndTime = dayjs(a.endTime as unknown as Dayjs).format("HH:mm:ss");
          }

          if (a.returnStartTime !== null && a.returnEndTime !== null) {
            formattedReturnStartTime = dayjs(a.returnStartTime as unknown as Dayjs).format("HH:mm:ss");
            formattedReturnEndTime = dayjs(a.returnEndTime as unknown as Dayjs).format("HH:mm:ss");
          }

          const _item: newAssignRouteListDto = {
            vehicleId: a.vehicleId,
            excelDocument: a.excelDocument,
            startLocation: a.startLocation,
            startLocationLatitude: a.startLocationLatitude,
            startLocationLongitude: a.startLocationLongitude,
            toLocation: a.toLocation,
            toLocationLatitude: a.toLocationLatitude,
            toLocationLongitude: a.toLocationLongitude,
            startTime: formattedStartTime,
            endTime: formattedEndTime,
            returnStartTime: formattedReturnStartTime,
            returnEndTime: formattedReturnEndTime,
            passengerCount: a.passengerCount,
            projectedMileage: a.projectedMileage,
          }
          return _item
        })

        const _payload: editShiftRequestDto = {
          templateId: templateId,
          employeeCategory: dsgenaralInformationForm.employeeCategory.value.value,
          templateName: dsgenaralInformationForm.templateTitle.value,
          shiftId: Number(dsgenaralInformationForm.shiftType.value.value),
          shiftGroupId: Number(dsgenaralInformationForm.shiftGroup.value.value),
          sbu: Number(dsgenaralInformationForm.sbu.value.value),
          plant: Number(dsgenaralInformationForm.plant.value.value),
          mainDestination: dsgenaralInformationForm.toLocation.value.location,
          mainLocationLatitude: dsgenaralInformationForm.toLocationLatAndLng?.value?.lat?.toString() || "",
          mainLocationLongitude: dsgenaralInformationForm.toLocationLatAndLng?.value?.lng?.toString() || "",
          shiftDateRanges: shiftDateRanges,
          routePassengers: _assignRouteList,
          isNewTemplate: false,
        }
        dispatch(shiftActions.editShiftRequest(_payload));
      }
    } else {
      dispatch(alertActions.triggerAlert(setAlert));
    }
  };

  //EXECUTE
  const executeRequest = async () => {
    const [validateData, isValid] = await validateFormData(dsExecutionInformationForm);

    setDsExecutionInformationForm(validateData);
    if (isValid) {
      let _isError = false;

      if (assignRouteList.length === 0) {
        _isError = true;
        dispatch(alertActions.triggerAlert(noPassengerUpdateAlert));
      }

      if (!_isError) {
        const _assignRouteList = assignRouteList.map((a) => {

          let formattedStartTime = null;
          let formattedEndTime = null;
          let formattedReturnStartTime = null;
          let formattedReturnEndTime = null;

          if (a.startTime !== null && a.endTime !== null) {
            formattedStartTime = dayjs(a.startTime as unknown as Dayjs).format("HH:mm:ss");
            formattedEndTime = dayjs(a.endTime as unknown as Dayjs).format("HH:mm:ss");
          }

          if (a.returnStartTime !== null && a.returnEndTime !== null) {
            formattedReturnStartTime = dayjs(a.returnStartTime as unknown as Dayjs).format("HH:mm:ss");
            formattedReturnEndTime = dayjs(a.returnEndTime as unknown as Dayjs).format("HH:mm:ss");
          }

          const _item: newAssignRouteListDto = {
            vehicleId: a.vehicleId,
            excelDocument: a.excelDocument,
            startLocation: a.startLocation,
            startLocationLatitude: a.startLocationLatitude,
            startLocationLongitude: a.startLocationLongitude,
            toLocation: a.toLocation,
            toLocationLatitude: a.toLocationLatitude,
            toLocationLongitude: a.toLocationLongitude,
            startTime: formattedStartTime,
            endTime: formattedEndTime,
            returnStartTime: formattedReturnStartTime,
            returnEndTime: formattedReturnEndTime,
            passengerCount: a.passengerCount,
            projectedMileage: a.projectedMileage,
          }
          return _item
        })

        const _ExecuteDate = dayjs(dsExecutionInformationForm.executeDate.value as Dayjs).format("YYYY-MM-DDTHH:mm:ss");

        const _payload: executeShiftRequestDto = {
          templateId: templateId,
          employeeCategory: dsgenaralInformationForm.employeeCategory.value.value,
          templateName: dsgenaralInformationForm.templateTitle.value,
          shiftId: Number(dsgenaralInformationForm.shiftType.value.value),
          shiftGroupId: Number(dsgenaralInformationForm.shiftGroup.value.value),
          sbu: Number(dsgenaralInformationForm.sbu.value.value),
          plant: Number(dsgenaralInformationForm.plant.value.value),
          isNewTemplate: true,
          mainDestination: dsgenaralInformationForm.toLocation.value.location,
          mainLocationLatitude: dsgenaralInformationForm.toLocationLatAndLng?.value?.lat?.toString() || "",
          mainLocationLongitude: dsgenaralInformationForm.toLocationLatAndLng?.value?.lng?.toString() || "",
          dateOfExecution: _ExecuteDate,
          executionShiftPassengers: _assignRouteList,
        }
        dispatch(shiftActions.executeShiftRequest(_payload));
      }

    } else {
      dispatch(alertActions.triggerAlert(setAlert));
    }
  };

  // useEffect(() => {
  //   const Count: getVehicleListParamDto = {
  //     NOSeats:passengerCount
  //   };
  //   dispatch(tripActions.getVehicleLitsBYPassengerCount(Count));
  // }, [passengerCount])

  useEffect(() => {
    if (dsPassengerInformationForm.passengerCount.value !== '') {
      const Count: getVehicleListParamDto = {
        NOSeats: passengerCount,
        purpose: 2
      };

      const delay = 1500;
      const timerId = setTimeout(() => {
        dispatch(tripActions.getVehicleLitsBYPassengerCount(Count));
      }, delay);

      return () => clearTimeout(timerId);
    }
  }, [dsPassengerInformationForm.passengerCount.value]);


  useEffect(() => {
    if (vehicleDetailsListResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setVehicleDetails(vehicleDetailsListResponse.data.data)
    }
  }, [vehicleDetailsListResponse.status])


  useEffect(() => {
    if (vehicleTypeListResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setVehicleTypeList(vehicleTypeListResponse.data)
    }
  }, [vehicleTypeListResponse.status])


  useEffect(() => {
    if (startLocation) {
      dispatch(mapActions.AddStartMapPoint(startLocation));
    }

    if (endLocation) {
      dispatch(mapActions.AddEndMapPoint(endLocation));
    }
  }, [startLocation, endLocation])

  const onUploadFile = () => {
    if (selectedFile) {
      if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        dispatch(shiftActions.uploadFile(selectedFile));
        setSelectedFile(null);
      } else {
        dispatch(alertActions.triggerAlert(setExcelAlert));
      }
    }
  }

  const onShowVehicle = () => {
    if (dsPassengerInformationForm.vehicleType.value.value !== null) {
      const applicableDates = dsgenaralInformationForm.applicableDate?.value as Dayjs[];

      if (applicableDates) { // Check if applicableDates is not null or undefined
        const dateStrings = applicableDates.map(date => date.toString());

        const shiftDateRanges = [];
        let startDate;
        let endDate;
        
        if(screenMode === DAILY_TRANSPORT_MODES.EDIT || screenMode === DAILY_TRANSPORT_MODES.EXECUTE){
          const unixTimeArray = dateStrings[0];
          const dates = unixTimeArray.split(',')
          startDate = (Number(dates[0]))
          endDate = (Number(dates[1]))
        }else{
          startDate = dateStrings[0];
          endDate = dateStrings[1];
        }


        let startTime = dayjs(dsPassengerInformationForm.tripStartTime.value).format("HH:mm:ss")
        let endTime = dayjs(dsPassengerInformationForm.tripEndTime.value).format("HH:mm:ss")
        let returnStartTime = dayjs(dsPassengerInformationForm.returnTripStartTime.value).format("HH:mm:ss")
        let returnEndTime = dayjs(dsPassengerInformationForm.returnTripEndTime.value).format("HH:mm:ss")
        // if (dateRangeTo) {
        //   if(dsPassengerInformationForm.isRoundTrip.value === true){
        //     shiftDateRanges.push({
        //       "dateRangeFrom": dayjs(dateRangeFrom).add(330, 'minute').format("YYYY-MM-DDTHH:mm"),
        //       "dateRangeTo": dayjs(dateRangeTo).add(330, 'minute').format("YYYY-MM-DDTHH:mm")
        //     });
        //   }

        // } else {
        //   shiftDateRanges.push({
        //     "dateRangeFrom": dayjs(dateRange).add(330, 'minute').format("YYYY-MM-DDTHH:mm"),
        //     "dateRangeTo": dayjs(dateRange).add(330, 'minute').format("YYYY-MM-DDTHH:mm")
        //   });
        // }

        if (dsPassengerInformationForm.isRoundTrip.value === true) {
          shiftDateRanges.push({
            "dateRangeFrom": dayjs(startDate).format("YYYY-MM-DDT") + startTime,
            "dateRangeTo": dayjs(endDate).format("YYYY-MM-DDT") + returnEndTime
          })
        }else if(dsPassengerInformationForm.isOneWay.value.value ===  ONE_WAY_SHIFT[0].value){
          shiftDateRanges.push({
            "dateRangeFrom": dayjs(startDate).format("YYYY-MM-DDT") + startTime,
            "dateRangeTo": dayjs(endDate).format("YYYY-MM-DDT") + endTime
          })
        }else if(dsPassengerInformationForm.isOneWay.value.value ===  ONE_WAY_SHIFT[1].value){
          shiftDateRanges.push({
            "dateRangeFrom": dayjs(startDate).format("YYYY-MM-DDT") + returnStartTime,
            "dateRangeTo": dayjs(endDate).format("YYYY-MM-DDT") + returnEndTime
          })
        }

        const vehicleDetailsParm: shiftVehicleDetailsParamDto = {
          vehicleTypeId: Number(dsPassengerInformationForm.vehicleType.value.value),
          passengerCount: Number(dsPassengerInformationForm.passengerCount.value),
          shiftDateRange: shiftDateRanges
        };
        dispatch(shiftActions.getAvailableShiftVehicles(vehicleDetailsParm))
      } //else {
      //dispatch(alertActions.triggerAlert(setDetailsAlert))
      //}
    }
    // else {
    //   dispatch(alertActions.triggerAlert(setDetailsAlert))
    // }
  }


  const onHandleInputChange = (property: string, value: string) => {
    if (property === "vehicleType") {
      setSelectedVehicleType(value);
    }
  };

  const handleOnSelectVehicle = (vehicleID: number) => {
    setSelectedVehicleID(vehicleID);

    vehicleDetails?.forEach((vehicle: shiftVehicleDetailsListDto) => {
      if (vehicle.vehicleID === vehicleID) {
        vehicle.isSelected = true;
        setVehicleId(vehicleID);
        setVehicleLicenseNo(vehicle.licenseNumber);
      } else {
        vehicle.isSelected = false;
      }
    });
  }

  const onAssignRoute = async () => {
    const [validateData, isValid] = await validateFormData(dsPassengerInformationForm)
    setDsPassengerInformationForm(validateData)
    if (isValid) {
      const _id = assignRouteList.length === 0 ? 1 : assignRouteList[assignRouteList.length - 1].id + 1

      const _obj: AssignRouteListDto = {
        id: _id,
        vehicleLicenseNo: vehicleLicenseNo,
        vehicleId: vehicleId,
        vehicleType: dsPassengerInformationForm.vehicleType.value.label,
        vehicleTypeId: Number(dsPassengerInformationForm.vehicleType.value.value),
        excelDocument: dsPassengerInformationForm.passengerDocument.value,
        isRoundTrip: dsPassengerInformationForm.isRoundTrip.value,
        oneWay: dsPassengerInformationForm?.isOneWay?.value.label,
        passengerCount: dsPassengerInformationForm.passengerCount.value,
        startLocation: dsPassengerInformationForm.isRoundTrip.value
          ? dsPassengerInformationForm.routeEndLocation.value.location
          : (
            dsPassengerInformationForm.isOneWay.value.value === 1
              ? dsPassengerInformationForm.routeEndLocation.value.location
              : dsgenaralInformationForm.toLocation.value.location
          ),
        startLocationId: dsPassengerInformationForm.routeEndLocation.value.id,
        startLocationLatitude: dsPassengerInformationForm.toRouteEndLocationLatAndLng?.value.lat?.toString() || "",
        startLocationLongitude: dsPassengerInformationForm.toRouteEndLocationLatAndLng?.value.lng?.toString() || "",
        toLocation: dsPassengerInformationForm.isRoundTrip.value
          ? dsgenaralInformationForm.toLocation.value.location
          : (
            dsPassengerInformationForm.isOneWay.value.value === 2
              ? dsPassengerInformationForm.routeEndLocation.value.location
              : dsgenaralInformationForm.toLocation.value.location
          ),
        toLocationLatitude: dsgenaralInformationForm.toLocationLatAndLng.value.lat?.toString() || "",
        toLocationLongitude: dsgenaralInformationForm.toLocationLatAndLng.value.lng?.toString() || "",
        startTime: dsPassengerInformationForm.tripStartTime.value === null ? null : dsPassengerInformationForm.tripStartTime.value?.toString(),
        endTime: dsPassengerInformationForm.tripEndTime.value === null ? null : dsPassengerInformationForm.tripEndTime.value?.toString(),
        returnStartTime: dsPassengerInformationForm.returnTripStartTime.value === null ? null : dsPassengerInformationForm.returnTripStartTime.value?.toString(),
        returnEndTime: dsPassengerInformationForm.returnTripEndTime.value === null ? null : dsPassengerInformationForm.returnTripEndTime.value?.toString(),
        projectedMileage: 0,
      }

      // const _isTHere = assignRouteList.find((v) => v.vehicleId === _obj.vehicleId);
      //WORKS
      // const _isTHere = assignRouteList.find((v) => (v.vehicleId === vehicleId && v.startTime === dsPassengerInformationForm.tripStartTime.value?.toString()) || (v.vehicleId === vehicleId && v.returnStartTime === dsPassengerInformationForm.tripStartTime.value?.toString()));
      const _isTHere = assignRouteList.find((v) => {
        // console.log("MATRIX", dsPassengerInformationForm.tripStartTime.value?.isAfter(v.startTime));
        // console.log("MATRIX", dsPassengerInformationForm.tripStartTime.value?.isBefore(v.endTime));
        const isStartTimeMatching =
          v.vehicleId === vehicleId &&
          v.startTime !== null &&
          dsPassengerInformationForm.tripStartTime.value !== null &&
          ((dsPassengerInformationForm.tripStartTime.value?.isAfter(v.startTime)) ||
          (dsPassengerInformationForm.tripStartTime.value?.isBefore(v.endTime)));
      
        const isReturnStartTimeMatching =
          v.vehicleId === vehicleId &&
          v.returnStartTime !== null &&
          dsPassengerInformationForm.tripStartTime.value !== null &&
          ((dsPassengerInformationForm.tripStartTime.value?.isAfter(v.returnStartTime)) ||
          (dsPassengerInformationForm.tripStartTime.value?.isBefore(v.returnEndTime)));
      
        return isStartTimeMatching || isReturnStartTimeMatching;
      });

      if (vehicleId > initialValue) {
        if (_isTHere !== undefined) {
          dispatch(alertActions.triggerAlert(sameVehicleAlert));
        } else if (dayjs(_obj.startTime).isAfter(dayjs(_obj.endTime)) || dayjs(_obj.startTime).isAfter(dayjs(_obj.returnStartTime)) ||
          dayjs(_obj.returnStartTime).isAfter(dayjs(_obj.returnEndTime))) {
          dispatch(alertActions.triggerAlert(setAlertTime));
        } else if (dsgenaralInformationForm.shiftGroup.value.value === undefined && _obj.excelDocument === '') {
          dispatch(alertActions.triggerAlert(setNoFileAlert))
        } else {

          let _list: any = [];

          if (_obj.isRoundTrip) {
            const _roundTripObj = {
              ..._obj,
              returnStartTime: null,
              returnEndTime: null,
            }
            _list = [...assignRouteList, _roundTripObj]
          } else {
            _list = [...assignRouteList, _obj]
          }

          if (dsPassengerInformationForm.isRoundTrip.value) {
            const incrementedObj = {
              ..._obj,
              id: _obj.id + 1,
              startLocation: _obj.toLocation,
              toLocation: _obj.startLocation,
              startTime: null,
              endTime: null,
              returnStartTime: dsPassengerInformationForm.returnTripStartTime.value === null ? null : dsPassengerInformationForm.returnTripStartTime.value?.toString(),
              returnEndTime: dsPassengerInformationForm.returnTripEndTime.value === null ? null : dsPassengerInformationForm.returnTripEndTime.value?.toString(),
            };
            _list.push(incrementedObj);
          }

          setAssignRouteList(_list)
          setVehicleId(initialValue);
          setFileString("");
          setVehicleLicenseNo("");
          setSelectedVehicleType("");
          setSpassengerCount('');
          setVehicleDetails([]);
          setDsPassengerInformationForm(DS_PASSENGER_INFORMATION_FORM_INITIAL_STATE)
          dispatch(shiftActions.clearAvailableShiftVehicles())
        }
      } else {
        dispatch(alertActions.triggerAlert(NoVehicleSelectAlert));
      }
    } else {
      dispatch(alertActions.triggerAlert(setGeneralAlert))
    }
  }

  const onSelectRoute = (id: number, action: string) => {
    setSelectedRoute(id)

    if (action === 'edit') {
      setIsEditRoute(true);
      onSelectAssignRouteForEdit(id)
    }

    if (action === 'delete') {
      setIsOpenRemoveRoute(true)
    }

    if (action === 'download') {
      const findDownloadFile = assignRouteList.find((r) => r.id === id);
      if (findDownloadFile?.excelDocument !== undefined) {
        dispatch(shiftActions.downloadShiftFile(findDownloadFile?.excelDocument));
      } else {
        dispatch(alertActions.triggerAlert(setNoFileAlert1));
      }
    }
  }

  useEffect(() => {
    if (formValuesInitialized) {
      onShowVehicle();
    }
  }, [formValuesInitialized])

  useEffect(() => {
    // Ensure that the vehicleId is not null or undefined before calling handleOnSelectVehicle
    if (vehicleId !== null && vehicleId !== undefined) {
      handleOnSelectVehicle(vehicleId);
    }
  }, [vehicleId]);


  const onSelectAssignRouteForEdit = (id: number) => {
    const _route = assignRouteList.find((r) => r.id === id);

    if (_route?.vehicleId !== undefined && _route?.vehicleLicenseNo !== undefined) {
      setVehicleId(_route?.vehicleId)
      setVehicleIdEdit(_route?.vehicleId)
      setVehicleLicenseNo(_route?.vehicleLicenseNo)
    }

    if (_route) {
      setSpassengerCount(_route.passengerCount);
      setDsPassengerInformationForm({
        ...dsPassengerInformationForm,
        isOneWay: {
          ...dsPassengerInformationForm.isOneWay,
          value: _route.startTime ? { value: 1, label: "Start Location" } : { value: 2, label: "End Location" },
          error: null,
        },
        passengerCount: {
          ...dsPassengerInformationForm.passengerCount,
          value: _route.passengerCount,
          error: null,
        },
        isRoundTrip: {
          ...dsPassengerInformationForm.isRoundTrip,
          value: false,
          disable: true,
        },
        routeEndLocation: {
          ...dsPassengerInformationForm.routeEndLocation,
          value: { location: _route.startLocation, id: _route.startLocationId, } as LocationBriefDto,
        },
        toRouteEndLocationLatAndLng: {
          ...dsPassengerInformationForm.toRouteEndLocationLatAndLng,
          value: { lat: Number(_route?.toLocationLatitude) || null, lng: Number(_route?.toLocationLongitude) || null, } as selectedType,
        },
        tripStartTime: {
          ...dsPassengerInformationForm.tripStartTime,
          value: _route?.startTime ? dayjs(_route?.startTime) : null,
          disable: _route?.startTime === null,
          isRequired: _route?.startTime !== null,
        },
        tripEndTime: {
          ...dsPassengerInformationForm.tripEndTime,
          value: _route?.endTime ? dayjs(_route?.endTime) : null,
          disable: _route?.endTime === null,
          isRequired: _route?.endTime !== null,
        },
        returnTripStartTime: {
          ...dsPassengerInformationForm.returnTripStartTime,
          value: _route?.returnStartTime ? dayjs(_route?.returnStartTime) : null,
          disable: _route?.returnStartTime === null,
          isRequired: _route?.returnStartTime !== null,
        },
        returnTripEndTime: {
          ...dsPassengerInformationForm.returnTripEndTime,
          value: _route?.returnEndTime ? dayjs(_route.returnEndTime) : null,
          disable: _route?.returnEndTime === null,
          isRequired: _route?.returnEndTime !== null,
        },
        vehicleType: {
          ...dsPassengerInformationForm.vehicleType,
          value: { label: _route.vehicleType, value: _route.vehicleTypeId },
          disable: _route?.vehicleType === null,
          isRequired: _route?.vehicleType !== null,
        },
        passengerDocument: {
          ...dsPassengerInformationForm.passengerDocument,
          value: _route.excelDocument,
          disable: _route?.excelDocument === '',
          isRequired: _route?.excelDocument !== '',
        }
      })
      setFileString(_route.excelDocument);
      setFormValuesInitialized(true)
      handleOnSelectVehicle(_route.vehicleId);
    }
  }

  const onEditRoute = async () => {
    const [validateData, isValid] = await validateFormData(dsPassengerInformationForm)
    setDsPassengerInformationForm(validateData)

    let isRouteEdited = false;

    if (isValid) {
      setAssignRouteList(assignRouteList.map((p) => {
        // const _isTHere = assignRouteList.find((v) => vehicleId === v.vehicleId);
        //Works
        //const _isTHere = assignRouteList.find((v) => (vehicleId === v.vehicleId && v.startTime === startTime) || (vehicleId === v.vehicleId && v.returnStartTime === returnStartTime));

        const _isTHere = assignRouteList.find((v) => {
          const isStartTimeMatching =
            v.vehicleId === vehicleId &&
            v.startTime !== null &&
            dsPassengerInformationForm.tripStartTime.value !== null &&
            ((dsPassengerInformationForm.tripStartTime.value?.isAfter(v.startTime)) ||
            (dsPassengerInformationForm.tripStartTime.value?.isBefore(v.endTime)));;
        
          const isReturnStartTimeMatching =
            v.vehicleId === vehicleId &&
            v.returnStartTime !== null &&
            dsPassengerInformationForm.tripStartTime.value !== null &&
            ((dsPassengerInformationForm.tripStartTime.value?.isAfter(v.returnStartTime)) ||
              (dsPassengerInformationForm.tripStartTime.value?.isBefore(v.returnEndTime)));;
        
          return isStartTimeMatching || isReturnStartTimeMatching;
        });

        if (vehicleLicenseNo === '') {
          dispatch(alertActions.triggerAlert(setGeneralAlert))
          return p;
        } else if (dayjs(dsPassengerInformationForm.tripStartTime.value).isAfter(dayjs(dsPassengerInformationForm.tripEndTime.value)) ||
          dayjs(dsPassengerInformationForm.returnTripStartTime.value).isAfter(dayjs(dsPassengerInformationForm.returnTripEndTime.value))) {
          dispatch(alertActions.triggerAlert(setAlertTime));
          return p;
        } else if (p.excelDocument !== '' && dsPassengerInformationForm.passengerDocument.value === '') {
          dispatch(alertActions.triggerAlert(setNoFileAlert));
          return p;
        } 
        // else if (_isTHere !== undefined && vehicleIdEdit !== vehicleId) {
        //   dispatch(alertActions.triggerAlert(sameVehicleAlert));
        //   return p;
        // }
        else if (_isTHere !== undefined) {
          dispatch(alertActions.triggerAlert(sameVehicleAlert));
          return p;
        }
        else if (p.id === selectedRoute && vehicleLicenseNo !== '') {
          isRouteEdited = true;

          return {
            ...p,
            isRoundTrip: p.isRoundTrip,
            vehicleLicenseNo: vehicleLicenseNo,
            vehicleId: vehicleId,
            excelDocument: dsPassengerInformationForm.passengerDocument.value,
            passengerCount: dsPassengerInformationForm.passengerCount.value,
            startLocation: dsPassengerInformationForm.routeEndLocation.value.location,
            toLocation: p.toLocation,
            toLocationLatitude: p.toLocationLatitude,
            toLocationLongitude: p.toLocationLongitude,
            startTime: dsPassengerInformationForm.tripStartTime.value === null ? null : dsPassengerInformationForm.tripStartTime.value?.toString(),
            endTime: dsPassengerInformationForm.tripEndTime.value === null ? null : dsPassengerInformationForm.tripEndTime.value?.toString(),
            returnStartTime: dsPassengerInformationForm.returnTripStartTime.value === null ? null : dsPassengerInformationForm.returnTripStartTime.value?.toString(),
            returnEndTime: dsPassengerInformationForm.returnTripEndTime.value === null ? null : dsPassengerInformationForm.returnTripEndTime.value?.toString(),
          }
        } else {
          return p
        }

      }))

    } else {
      dispatch(alertActions.triggerAlert(setGeneralAlert))
    }

    if (isRouteEdited) {
      // setIsEditRoute(false)
      // setSpassengerCount('')
      // setFormValuesInitialized(false)
      // setDsPassengerInformationForm(DS_PASSENGER_INFORMATION_FORM_INITIAL_STATE);
      setIsEditRoute(false)
      setVehicleId(initialValue);
      setFormValuesInitialized(false);
      setFileString("");
      setVehicleLicenseNo("");
      setSelectedVehicleType("");
      setSpassengerCount('');
      setVehicleDetails([]);
      setDsPassengerInformationForm(DS_PASSENGER_INFORMATION_FORM_INITIAL_STATE)
      dispatch(shiftActions.clearAvailableShiftVehicles())
    }
  }

  const onRemoveRoute = (con: boolean) => {
    if (con) {
      setAssignRouteList(assignRouteList.filter((p) => p.id !== selectedRoute).map((v, index) => {
        return {
          ...v,
          id: index + 1
        }
      }))
      setSelectedRoute(-1)
    }
    setIsOpenRemoveRoute(false)
  }

  const onTemplateClose = (con: boolean) => {
    setIsOpenCancel(false)
    if (con) {
      navigate(APP_ROUTES.DS_TRANSPORTATION)
      setAsInitialState()
    }
  }

  return (
    <React.Fragment>
      <AppLayout componentTitle="New Request Creation">
        <section className="page-root">
          <section className={style.sectionCard}>
            <section className={style.sectionCardHeader}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={10}>
                  <Typography noWrap component="div" className={style.title}>
                    {screenMode === DAILY_TRANSPORT_MODES.EXECUTE && (
                      <h3>Daily Transport Template Execution</h3>
                    )}

                    {screenMode === DAILY_TRANSPORT_MODES.VIEW_EXECUTED && (
                      <h3>Daily Transport Template Execution {requestId}</h3>
                    )}

                    {(screenMode === DAILY_TRANSPORT_MODES.CREATE || screenMode === DAILY_TRANSPORT_MODES.VIEW
                      || screenMode === DAILY_TRANSPORT_MODES.EDIT || screenMode === DAILY_TRANSPORT_MODES.DUPLICATE) && (
                        <h3>Daily Transport Template</h3>
                      )}
                  </Typography>
                </Grid>
              </Grid>
            </section>
            <section className={style.sectionCardBody}>
              <section className={style.stepperRoot}>
                {screenMode === DAILY_TRANSPORT_MODES.EXECUTE && (
                  <ExecutionInformation
                    handleInputFocus={handleInputFocus}
                    dsExecutionInformationForm={dsExecutionInformationForm}
                    helperText={helperText}
                    onInputHandleChangeExecInformation={onInputHandleChangeExecInformation}
                  />
                )}

                <GeneralInformation
                  dsgenaralInformationForm={dsgenaralInformationForm}
                  helperText={helperText}
                  allSbuListByResponse={allSbuListByResponse}
                  allPlantsBySbuIdResponse={allPlantsBySbuIdResponse}
                  shiftType={shiftType || []}
                  shiftGroup={shiftGroup || []}
                  screenMode={screenMode}
                  duplicateMinDate={duplicateMinDate}
                  assignRouteList={assignRouteList}
                  handleInputFocus={handleInputFocus}
                  getShiftRequestByIdResponse={shiftRequestByIdResponse}
                  getExecutionIdResponse={executionTemplateByIdRes}
                  onInputHandleChangeGeneralInformation={onInputHandleChangeGeneralInformation}
                  frequentLocationsResponse={frequentLocationsResponse.data || []} />

                <PassengerDetails
                  dsPassengerInformationForm={dsPassengerInformationForm}
                  helperText={helperText}
                  shiftDocumentResponse={shiftDocumentResponse}
                  handleFileChange={handleFileChange}
                  handleDownload={handleDownload}
                  handleRemove={handleRemove}
                  screenMode={screenMode}
                  handleInputFocus={handleInputFocus}
                  passangerCount={passengerCount}
                  onInputHandleChangePassengerInformation={onInputHandleChangePassengerInformation}
                  frequentLocationsResponse={frequentLocationsResponse.data || []}
                  vehicleDetails={vehicleDetails || []}
                  vehicleDetailsListResponse={vehicleDetailsListResponse}
                  onAssignRoute={onAssignRoute}
                  isEditRoute={isEditRoute}
                  fileString={fileString}
                  onEditRoute={onEditRoute}
                  onSelectRoute={onSelectRoute}
                  VehicleTypeList={VehicleTypeList || []}
                  onHandleInputChange={onHandleInputChange}
                  selectedVehicleType={selectedVehicleType}
                  onShowVehicle={onShowVehicle}
                  onUploadFile={onUploadFile}
                  assignRouteList={assignRouteList}
                  handleOnSelectVehicle={handleOnSelectVehicle}
                  selectedVehicleID={selectedVehicleID}
                  selectedFile={selectedFile}
                  onClearPassengerInfoForm={onClearPassengerInfoForm} />
                {/* <Suggestions /> */}

                <section className={style.saveAs}>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <StyledTextField
                        fullWidth
                        label="Template title"
                        placeholder="Enter title"
                        size="small"
                        InputProps={{
                          readOnly: dsgenaralInformationForm.templateTitle.readonly
                        }}
                        value={dsgenaralInformationForm.templateTitle.value}
                        error={!!dsgenaralInformationForm.templateTitle.error}
                        disabled={dsgenaralInformationForm.templateTitle.disable}
                        required={dsgenaralInformationForm.templateTitle.isRequired}
                        helperText={helperText && dsgenaralInformationForm.templateTitle.error}
                        onFocus={() => handleInputFocus('templateTitle', 'GI')}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => onInputHandleChangeGeneralInformation('templateTitle', event.target.value)}
                      />
                    </Grid>
                  </Grid>
                </section>
              </section>
              <section className={style.sectionCardFooter}>

                {(screenMode === DAILY_TRANSPORT_MODES.VIEW || screenMode === DAILY_TRANSPORT_MODES.VIEW_EXECUTED) && (
                  <CustomButton
                    text="Close"
                    textColor="black"
                    bgColor="#bfbfbf"
                    onClick={onNavigate}
                  />
                )}

                {(screenMode === DAILY_TRANSPORT_MODES.EDIT || screenMode === DAILY_TRANSPORT_MODES.CREATE ||
                  screenMode === DAILY_TRANSPORT_MODES.DUPLICATE) && (
                    <CustomButton
                      text="Close"
                      textColor="black"
                      bgColor="#bfbfbf"
                      onClick={() => setIsOpenCancel(true)}
                    />
                  )}

                {(screenMode === DAILY_TRANSPORT_MODES.CREATE) && (
                  <CustomButton
                    text="Clear"
                    border="1px solid #6e6e6e"
                    bgColor="#282828"
                    onClick={onClear}
                  />
                )}

                {(screenMode === DAILY_TRANSPORT_MODES.CREATE || screenMode === DAILY_TRANSPORT_MODES.DUPLICATE) && (
                  <>
                    {screenMode === DAILY_TRANSPORT_MODES.CREATE && (
                      <CustomButton
                        text="Save draft"
                        border="1px solid #6e6e6e"
                        bgColor="#282828"
                        disabled={createShiftReqResponse.isLoading}
                        isLoading={
                          isDraftButtonClick &&
                          createShiftReqResponse.isLoading
                        }
                        onClick={() => onCreateDailyShiftTemplate(true)}
                      />
                    )}

                    <CustomButton text={screenMode === DAILY_TRANSPORT_MODES.CREATE ? "Send Request" : "Duplicate Request"}
                      disabled={createShiftReqResponse.isLoading}
                      isLoading={
                        !isDraftButtonClick &&
                        (createShiftReqResponse.isLoading || createDupShiftReqResponse.isLoading)
                      }
                      onClick={() => onCreateDailyShiftTemplate(false)} />
                  </>
                )}

                {screenMode === DAILY_TRANSPORT_MODES.EDIT && (
                  <CustomButton
                    text="Edit Request"
                    disabled={false}
                    isLoading={editRequestResponse.isLoading}
                    onClick={editRequest}
                  />
                )}

                {screenMode === DAILY_TRANSPORT_MODES.EXECUTE && (
                  <CustomButton
                    text="Execute Template"
                    isLoading={executeRequestResponse.isLoading}
                    disabled={false}
                    onClick={executeRequest}
                  />
                )}
              </section>
            </section>
          </section>
        </section>
        <ConfirmationDialog
          isOpenConfirmationDialog={isOpenRemoveRoute}
          onCallback={onRemoveRoute}
          title="Remove Added Route"
          content="Do you want to remove this assigned route?"
        />

        <ConfirmationDialog
          isOpenConfirmationDialog={isOpenCancel}
          onCallback={onTemplateClose}
          title="DISCARD TEMPLATE"
          content="Do you want to discard this template?"
          confirmButtonTitle="Yes"
          cancelButtonTitle="No"
        />

      </AppLayout>
    </React.Fragment>
  );
};

export default ManageDailyShiftScreen;
