import React from 'react'
import style from './PaymentDetails.module.scss'
import Stepper from '../../Shared/Stepper/Stepper'
import { Box, Grid, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@mui/material";
import { GetBankListDto, GetPaymentMethodsDto, PaymentDetailsFormDTo , GetBranchListDto, NewPaymentDetailsDTo, GetCompanyDetailsById, StateObjectDto} from '../../../utilities/models'
import { CurrencyTypes, TRANSPORT_COMPANY_MODE } from '../../../utilities/constants'
import { AppSkeleton, CustomAutocomplete, CustomButton, CustomHeaderCell } from '../../Shared'
import { StyledSwitch, StyledTableCell, StyledTextField } from '../../../assets/theme/theme'
import { EditOutlined } from '@mui/icons-material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
const PaymentDetails:React.FC<{
  paymentDetailsForm:PaymentDetailsFormDTo
  handleInputFocus(property: string, section: string): void;
  onInputHandleChange(property: string, value: any): void;
  helperText: boolean
  paymentmethods:GetPaymentMethodsDto[]
  bankList:GetBankListDto[]
  branchList:GetBranchListDto[]
  onSearchValueChange(property: string, value: any): void;
  onPushPaymentDetails(property:string):void
  paymentDetailsList:NewPaymentDetailsDTo[]
  setActiveStates(index:number):void
  PaymentDetailsFormClear():void
  handleTableAction(index:number, action:string):void
  ispaymetDetailsEdite:boolean
   screenMode:string
   getTransportCompanyByIDResponse:StateObjectDto<GetCompanyDetailsById|null>
  GetBankListResponse: StateObjectDto<GetBankListDto[]>;

}> = (props) => {

const  _currencyType =props.paymentDetailsForm.currencyType
const  _PaymentMethod =props.paymentDetailsForm.paymentMethod
const  _bankName =props.paymentDetailsForm.bank
const  _bankCode =props.paymentDetailsForm.bankCode
const _BranchName=props.paymentDetailsForm.Branch
const _BranchCode=props.paymentDetailsForm.branchCode
const _beneficiaryName=props.paymentDetailsForm.beneficiaryName
const _AccountNumber=props.paymentDetailsForm.AccountNumber
  return (
    <Stepper stepNumber={3} stepTitle={"Payment Details"}>
       {props.getTransportCompanyByIDResponse && !props.getTransportCompanyByIDResponse.isLoading && !props.GetBankListResponse.isLoading&& ( 
 <Grid container spacing={4}>
 <Grid item xs={12} md={6}>
            <CustomAutocomplete
                label="Currency  "
                placeholder='Select Currency  '
                onFocus={() => props.handleInputFocus('currencyType', 'PM')}
                options={CurrencyTypes.map((method) => ({
                  label: method.name,
                  value:method.id,
                }))}
                value={{ label: _currencyType.value.label, value: _currencyType.value.value }}
                error={!!_currencyType.error}
                disabled={_currencyType.disable}
                readonly={_currencyType.readonly}
                required={_currencyType.isRequired}
                helperText={props.helperText && _currencyType.error}
                onChange={(event: any, value: any) => props.onInputHandleChange('currencyType', value)}
              /></Grid>
               <Grid item xs={12} md={6}>
            <CustomAutocomplete
                label="Payment Method "
                placeholder='Select  Payment Method '
                onFocus={() => props.handleInputFocus('paymentMethod', 'PM')}
                options={props.paymentmethods.map((method:GetPaymentMethodsDto) => ({
                  label: method.method,
                  value:method.id,
                }))}
                value={{ label: _PaymentMethod.value.label, value: _PaymentMethod.value.value }}
                error={!!_PaymentMethod.error}
                disabled={_PaymentMethod.disable}
                readonly={_PaymentMethod.readonly}
                required={_PaymentMethod.isRequired}
                helperText={props.helperText && _PaymentMethod.error}
                onChange={(event: any, value: any) => props.onInputHandleChange('paymentMethod', value)}
              />
              </Grid>
              <Grid item xs={12} md={4}>
            <CustomAutocomplete
                label="Bank Name"
                placeholder='Select  Bank Name'
                onFocus={() => props.handleInputFocus('bank', 'PM')}
                options={props.bankList.map((bank:GetBankListDto) => ({
                  label: bank.bankName,
                  value:bank.id,
                }))}
                value={{ label: _bankName.value.label, value: _bankName.value.value }}
                error={!!_bankName.error}
                disabled={_bankName.disable}
                readonly={_bankName.readonly}
                required={_bankName.isRequired}
                helperText={props.helperText && _bankName.error}
                onChange={(event: any, value: any) => props.onInputHandleChange('bank', value)}
              />
              
              </Grid>
              <Grid item xs={12} md={2}>
            <StyledTextField
                fullWidth
                label="Bank Code"
                placeholder='Enter Bank Code'
                size='small'
                InputProps={{
                  readOnly: _bankCode.readonly
                }}
                value={_bankCode.value}
                error={!!_bankCode.error}
                disabled={_bankCode.disable}
                required={_bankCode.isRequired}
                helperText={props.helperText && _bankCode.error}
                onFocus={() => props.handleInputFocus(' bankCode', 'PM')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('bankCode', event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
            <CustomAutocomplete
              
                label="Branch Name "
                placeholder='Select  Branch Name'
                onFocus={() => props.handleInputFocus('Branch', 'PM')}
                options={props.branchList.map((p: GetBranchListDto) => { return { label: p.name, value: p.id } })}
                onInputChange={(event: any, value: any) => props.onSearchValueChange('Branch', value)}
                value={_BranchName.value}
                error={!!_BranchName.error}
                disabled={_BranchName.disable}
                readonly={_BranchName.readonly}
                required={_BranchName.isRequired}
                helperText={props.helperText && _BranchName.error}
                onChange={(event: any, value: any) => props.onInputHandleChange('Branch', value)}
              />
              
              </Grid>
              <Grid item xs={12} md={2}>
            <StyledTextField
                fullWidth
                label="Branch  Code"
                placeholder='Enter Branch  Code'
                size='small'
                InputProps={{
                  readOnly: _BranchCode.readonly
                }}
                value={_BranchCode.value}
                error={!!_BranchCode.error}
                disabled={_BranchCode.disable}
                required={_BranchCode.isRequired}
                helperText={props.helperText && _BranchCode.error}
                onFocus={() => props.handleInputFocus('branchCode', 'PM')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('branchCode', event.target.value)}
              />
              
            </Grid>
            <Grid item xs={12} md={6}>
            <StyledTextField
                fullWidth
                label="Beneficiary Name"
                placeholder='Enter Beneficiary Name'
                size='small'
                InputProps={{
                  readOnly: _beneficiaryName.readonly
                }}
                value={_beneficiaryName.value}
                error={!!_beneficiaryName.error}
                disabled={_beneficiaryName.disable}
                required={_beneficiaryName.isRequired}
                helperText={props.helperText && _beneficiaryName.error}
                onFocus={() => props.handleInputFocus('beneficiaryName', 'PM')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('beneficiaryName', event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <StyledTextField
                fullWidth
                label="Beneficiary Account Number"
                placeholder='Enter Beneficiary Account Number'
                size='small'
                InputProps={{
                  readOnly: _AccountNumber.readonly
                }}
                value={_AccountNumber.value}
                error={!!_AccountNumber.error}
                disabled={_AccountNumber.disable}
                required={_AccountNumber.isRequired}
                helperText={props.helperText && _AccountNumber.error}
                onFocus={() => props.handleInputFocus('_AccountNumber', 'PM')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('_AccountNumber', event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
          
            

            <section className={style.gridSection}>
            <div className={style.gridHeader}>
            {props.screenMode !== TRANSPORT_COMPANY_MODE.VIEW &&  
        <Box sx={{ flexGrow: 1, alignItems:"end",right:0}}>
        <div className={style.layoutrow}>
          <CustomButton text='CLEAR PAYMENT DETAILS  'textColor='black' bgColor='#bfbfbf' onClick={() => props.PaymentDetailsFormClear()} />
          {!props.ispaymetDetailsEdite&&<CustomButton text='ADD PAYMENT DETAILS' onClick={() => props.onPushPaymentDetails("add")} />}
          {props.ispaymetDetailsEdite&&<CustomButton text='Edit PAYMENT DETAILS' onClick={() => props.onPushPaymentDetails("edit")} />}
        </div>
        </Box>}
      </div>
        <TableContainer component={Paper} className={style.grid}>
          <Table className={style.table}>
            <TableHead>
              <TableRow>
                <CustomHeaderCell id='requestID'width={180}  >Payment Method</CustomHeaderCell>
                <CustomHeaderCell id='passenger'  width={180}  >Currency</CustomHeaderCell>
                <CustomHeaderCell id='sbu' width={180}  >Account Number</CustomHeaderCell>
                <CustomHeaderCell id='plant' width={180} >Beneficiary Name</CustomHeaderCell>
                <CustomHeaderCell id='pickup' width={180} >Bank Name </CustomHeaderCell>
                <CustomHeaderCell id='pickup' width={180}>Bank Code</CustomHeaderCell>
                <CustomHeaderCell id='Drop-off' width={180} >Branch Name</CustomHeaderCell>
                <CustomHeaderCell id='Drop-off' width={180} >Branch Code</CustomHeaderCell>
                <CustomHeaderCell id='Drop-off'  width={180} >Status </CustomHeaderCell>
                {props.screenMode !== TRANSPORT_COMPANY_MODE.VIEW &&    <CustomHeaderCell id='Destination'  width={180}>Action</CustomHeaderCell>}
              </TableRow>
            </TableHead>
            {true &&
              <TableBody>
                {props.paymentDetailsList.map((p:NewPaymentDetailsDTo, index) => (
                  <TableRow key={index}>
                    <StyledTableCell >{p.paymentMethod.label}</StyledTableCell>
                    <StyledTableCell >{p.currencyType.label}</StyledTableCell>
                    <StyledTableCell >{p.AccountNumber}</StyledTableCell>
                    <StyledTableCell >{p.beneficiaryName}</StyledTableCell>
                    <StyledTableCell >{p.bank.label}</StyledTableCell>
                    <StyledTableCell >{p.bankCode}</StyledTableCell>
                    <StyledTableCell >{p.Branch.label}</StyledTableCell>
                    <StyledTableCell >{p.branchCode}</StyledTableCell>

                    <StyledTableCell >      <StyledSwitch
                      checked={p.isActive}
                      onChange={() =>{if(props.screenMode !== TRANSPORT_COMPANY_MODE.VIEW ){props.setActiveStates(index)}}}
                    /></StyledTableCell>
                   {props.screenMode !== TRANSPORT_COMPANY_MODE.VIEW &&
                      <StyledTableCell style={{ backgroundColor: '#282828' }}>
                        <Box className='layout-row'>
                          <Box>
                            <IconButton size='small' onClick={() => props.handleTableAction(index,"delete")}>
                              <Tooltip title="Delete">
                                <DeleteOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                              </Tooltip>
                            </IconButton>
                          </Box>
                          <Box>
                            <IconButton size='small' onClick={() =>props.handleTableAction(index,"edit")}>
                              <Tooltip title="Edit">
                                <EditOutlined sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                              </Tooltip>
                            </IconButton>
                          </Box>
                        </Box>
                      </StyledTableCell>
                    } 
                  </TableRow>
                ))}
              </TableBody>
            }
            {props.paymentDetailsList.length===0 &&
              <TableBody>
                <TableRow>
                  <StyledTableCell align="center" colSpan={12}>No data to preview</StyledTableCell>
                </TableRow>
              </TableBody>
            }
          </Table>
        </TableContainer>
      </section>
      </Grid>
              
 </Grid>

 )}
{props.getTransportCompanyByIDResponse.isLoading &&
        <AppSkeleton numOfRows={7} numOfColumns={2} columnWidth={50} height={40} tag="stepper" />
      }
    </Stepper>
  )
}

export default PaymentDetails