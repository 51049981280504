import { COMMON_ACTION_TYPES, LINK_ACTION_TYPES } from "../../utilities/constants"
import { DownloadParamLinkDto, FileUploadLinkDto } from "../../utilities/models/link.model"

const uploadMainFile = (payload:FileUploadLinkDto) => {
    return { 
        type: LINK_ACTION_TYPES.UPLOAD_MAIN_FILE + COMMON_ACTION_TYPES.REQUEST,
        payload:payload,
    }
  }
  
  const uploadSecondaryFile = (payload:FileUploadLinkDto) => {
    return { 
        type: LINK_ACTION_TYPES.UPLOAD_SECONDARY_FILE + COMMON_ACTION_TYPES.REQUEST,
        payload:payload,
    }
  }
  
  const uploadOtherFile = (payload:FileUploadLinkDto) => {
    return { 
        type: LINK_ACTION_TYPES.UPLOAD_OTHER_FILE + COMMON_ACTION_TYPES.REQUEST,
        payload:payload
    }
  }
  
  const downloadRequiredFile = (payload:DownloadParamLinkDto)=>{
    return { 
        type: LINK_ACTION_TYPES.DOWNLOAD_REQUIRED_FILE + COMMON_ACTION_TYPES.REQUEST,
        payload:payload
    }
  }

  const downloadOpenFile = (payload:DownloadParamLinkDto)=>{
    return { 
        type: LINK_ACTION_TYPES.DOWNLOAD_OPEN_FILE + COMMON_ACTION_TYPES.REQUEST,
        payload:payload
    }
  }
  
  const removeUploadedFile =(payload:DownloadParamLinkDto)=>{
    return { 
        type: LINK_ACTION_TYPES.REMOVE_UPLOADED_FILE + COMMON_ACTION_TYPES.REQUEST,
        payload:payload
    }
  }
  const uploadMainFileClear = () => {
    return { 
        type: LINK_ACTION_TYPES.UPLOAD_MAIN_FILE + COMMON_ACTION_TYPES.CLEAR,
  
    }
  }
  const uploadSecondaryFileClear = () => {
    return { 
        type: LINK_ACTION_TYPES.UPLOAD_SECONDARY_FILE + COMMON_ACTION_TYPES.CLEAR,
   
    }
  }
  const uploadOtherFileClear = () => {
    return { 
        type: LINK_ACTION_TYPES.UPLOAD_OTHER_FILE + COMMON_ACTION_TYPES.CLEAR,
       
    }
  }
  export const linkActions = {
    uploadMainFile,
    uploadSecondaryFile,
    uploadOtherFile,
    downloadRequiredFile,
    removeUploadedFile,
    downloadOpenFile,
    uploadMainFileClear,
    uploadSecondaryFileClear,
    uploadOtherFileClear,
  }