import { all } from "redux-saga/effects";
import requestSaga from "./request.saga";
import vehicleSaga from "./vehicle.saga";
import locationSaga from "./location.saga";
import userSaga from "./user.saga";
import approverSaga from "./approver.saga";
import packageSaga from "./package.saga";
import templateSaga from "./template.saga";
import alertSaga from "./alert.saga";
import authSaga from "./auth.saga";
import splitSaga from "./split.saga";
import tripSaga from "./trip.saga";
import permissionSaga from "./permission.saga";
import chartSaga from "./chart.saga";
import transportSaga from "./transport.saga";
import driverSaga from "./driver.saga";
import notificationSaga from "./notification.saga";
import shiftSaga from "./shift.saga";
import linkSaga from "./link.saga";
import gpsSaga from "./gps.saga";
import fuelSaga from "./fuel.saga"

export default function* rootSaga() {
  yield all([
    alertSaga(),
    requestSaga(),
    vehicleSaga(),
    locationSaga(),
    userSaga(),
    approverSaga(),
    packageSaga(),
    templateSaga(),
    authSaga(),
    tripSaga(),
    permissionSaga(),
    chartSaga(),
    transportSaga(),
    splitSaga(),
    driverSaga(),
    notificationSaga(),
    shiftSaga(),
    linkSaga(),
    gpsSaga(),
    fuelSaga(),
  ]);
}
