import { AxiosResponse } from "axios";
import { AddVehicleIncidentParamDto, EditVehicleParamDto, NewVehiclePayloadDto, PendingAllocationParamDto, PendingVehicleAllocationsDto, VehicleDto, VehicleIncidentListDto, VehicleTypesDto, allocationHistoryDto, allocationRequisitionsDto, cancelAllocationParm, getDetailsSummaryParam, getSummaryGridParam } from "../utilities/models";
import { axiosPrivateInstance } from ".";

const createVehicle = (payload: NewVehiclePayloadDto):  Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.post('/api/Vehicle/AddVehiclesDetails', payload)
}

const editVehicle = (payload: EditVehicleParamDto):  Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.put('/api/Vehicle/UpdateVehicleDetails', payload)
}

const addVehicleIncident = (payload: AddVehicleIncidentParamDto):  Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.post('/api/Vehicle/AddVehicleIncident', payload)
}

const deleteVehicle = (id: number):  Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.put(`/api/Vehicle/DeleteVehicleDetails?vehicleId=${id}`)
}

const getVehicleTypes = (): Promise<AxiosResponse<VehicleTypesDto[]>> => {
    return axiosPrivateInstance.get('/api/Vehicle/VehicleTypes')
}

const getVehicles = (payload:getDetailsSummaryParam): Promise<AxiosResponse<VehicleDto[]>> => {
    return axiosPrivateInstance.get(`/api/Vehicle/GetVehicleDetails?pageNumber=${payload.pageNumber}&pagination=${payload.pagination}`)
}

const getVehicleIncidents = (id: number): Promise<AxiosResponse<VehicleIncidentListDto[]>> => {
    return axiosPrivateInstance.get(`/api/Vehicle/GetVehicleIncidents?vehicleId=${id}`)
}

const getPendingAllocationsFilters = ():Promise<AxiosResponse<any[]>> => {
    return axiosPrivateInstance.get('/api/Vehicle/GetPendingAllocationsFiltrationOptions')
} 

const getPendingAllocations = (param:PendingAllocationParamDto): Promise<AxiosResponse<PendingVehicleAllocationsDto[]>> => {
    return axiosPrivateInstance.post(`/api/Vehicle/PendingAllocations?pagination=${param.pagination}&pageNumber=${param.pageNumber}${param.pending ? '&pending=' + param.pending : ''}`, param.filter)
}
const getAllocationHistory=():Promise<AxiosResponse<allocationHistoryDto[]>>=>{
    return axiosPrivateInstance.get('/api/Vehicle/AllocationHistory')
}
const getAllocationRequisitions=(payload:string):Promise<AxiosResponse<allocationRequisitionsDto[]>>=>{
    return  axiosPrivateInstance.get(`/api/Vehicle/AllocatedRequisitions?allocationId=${payload}`)
}
const cancelAllocation=(payload:cancelAllocationParm):Promise<AxiosResponse<string>>=>{
    return  axiosPrivateInstance.put(`/api/Vehicle/CancelAllocationHistory?allocationId=${payload.AllocationId}&reason=${payload.reason}`)
}

export const vehicleService = {
    createVehicle,
    editVehicle,
    addVehicleIncident,
    deleteVehicle,
    getVehicleTypes,
    getVehicles,
    getVehicleIncidents,
    getPendingAllocations,
    getAllocationHistory,
    getAllocationRequisitions,
    cancelAllocation,
    getPendingAllocationsFilters
}