import React, { useEffect, useState } from 'react'
import { AppLayout } from '../../templates'
import { ManageTransportCompanyGrid } from '../../components/ManageTransportCompanies'
import { ApplicationStateDto, SortMetaDto, getCompanyDetailsDTO, getSummaryGridParam } from '../../utilities/models'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { APP_ACTION_STATUS, APP_ROUTES, APP_TABLE_CONFIGS, RESPONSE_KEYS, TRANSPORT_COMPANY_MODE } from '../../utilities/constants'
import { transportActions } from '../../redux/actions/transport.action'
import { ConfirmationDialog } from '../../components/Shared'

const ManageTransportCompanies = () => {
  
  const INITIAL_SORT_META: SortMetaDto = {
    field: "",
    asc: false,
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
  const [sortMeta, setSortMeta] = useState<SortMetaDto>(INITIAL_SORT_META);
  const [filteredList, setFilteredList] = useState<getCompanyDetailsDTO[]>([])
  const [isFiltered, setIsFiltered] = useState(false);
  const [screenMode, setScreenMode] = useState("");
  
  const GetAllCompanyDetailsResponse = useSelector((state: ApplicationStateDto) => state.transport.getAllCompanyDetails)
  const DeleteRespose = useSelector((state: ApplicationStateDto) => state.transport.deletecompany)
  const [isOpenDocumentRemovePopup, setIsOpenDocumentRemovePopup] = useState(false)
  const [SelectedCompany, setSelectedCompany] = useState(0)

  const handleChangePage = (event: unknown, newPage: number) => {
    GetTableData(rowsPerPage, newPage + 1)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    GetTableData(+event.target.value, 1)
    setPage(0)
  }

  useEffect(() => {
    GetTableData(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE, 1)
  }, [])

  useEffect(() => {
    if (DeleteRespose.status === APP_ACTION_STATUS.SUCCESS) {
      GetTableData(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE, 1)
    }
  }, [DeleteRespose.status])

  const GetTableData = async (r: number, p: number)=> {
    const _param: getSummaryGridParam = { pagination: r, pageNumber: p, };
    dispatch(transportActions.getAllTransportCompanyDetails(_param))
    dispatch(transportActions.UpdateTransportCompanyByIDClear())
    dispatch(transportActions.RegisterTransportCompanyClear())
    dispatch(transportActions.getTransportCompanyByIdClear())
  }

  useEffect(() => {
    if (GetAllCompanyDetailsResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setFilteredList(GetAllCompanyDetailsResponse.data)
      console.log("GetAllCompanyDetailsResponse.data", GetAllCompanyDetailsResponse.data);
    }
  }, [GetAllCompanyDetailsResponse.data])

  const onSortHandle = (col: string) => {
    console.log(col)
    const sorted = filteredList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];
      const prev = typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next = typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }
      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setFilteredList(sorted);
  };

  const onFilterHandle = (col: string, value: string) => {
    setIsFiltered(true);
    const filtered = filteredList.filter((item: any) => {
      const _value = (item as any)[col];
      if (_value === "sbu") {
        console.log("_value", _value);
      }

      if (typeof _value === "boolean") {
        return _value ? value === "Active" : value === "Inactive";
      }

      return _value === value;
    });

    setFilteredList(filtered);
  };



  const onClearFilter = () => {
    setIsFiltered(false);
    setFilteredList(GetAllCompanyDetailsResponse.data)
  };

  const getFilterList = (col: string): string[] => {
    if (!GetAllCompanyDetailsResponse.isLoading) {
      const _list = GetAllCompanyDetailsResponse.data || [];
      const keys = _list.map((item) => {
          const value = (item as any)[col];

          if (typeof value === "boolean") {
            return value ? "Active" : "Inactive";
          }
          return value;
        })
        .filter((value, index, array) => array.indexOf(value) === index);

      return keys;
    } else return [];
  };

  const navigateTo = (mode: string, id: string) => {
    setScreenMode(mode)
    sessionStorage.setItem("Mode", mode);
    sessionStorage.setItem("id", id);

    if (TRANSPORT_COMPANY_MODE.CREATE === mode) {
      navigate(APP_ROUTES.ADD_NEW_TRANSPORT_COMPANY);
    }

    if (TRANSPORT_COMPANY_MODE.EDIT === mode || TRANSPORT_COMPANY_MODE.VIEW === mode) {
      navigate(APP_ROUTES.ADD_NEW_TRANSPORT_COMPANY);
    }

    if (mode === "delete") {
      setSelectedCompany(Number(id))
      setIsOpenDocumentRemovePopup(true)
    }
  }

  const oncallBack = (value: boolean) => {
    if (value) {
      dispatch(transportActions.deleteCompany(SelectedCompany))
    }

    setSelectedCompany(0)
    setIsOpenDocumentRemovePopup(false)
  }

  return (
    <React.Fragment>
      <AppLayout componentTitle="Transport manager dashboard">
        <section className="page-root">
          <ManageTransportCompanyGrid
            page={page}
            rowsPerPage={rowsPerPage}
            onHandleChangePage={handleChangePage}
            onHandleChangeRowsPerPage={handleChangeRowsPerPage}
            // approvalRequestDataIsLoading={false}
            filteredList={filteredList}
            sortMeta={sortMeta}
            onSortHandle={onSortHandle}
            onFilterHandle={onFilterHandle}
            navigateTo={navigateTo}
            getFilterList={getFilterList}
            // onSelectCompanyForRemove={onSelectCompanyForRemove}
            onClearFilter={onClearFilter}
            isFiltered={isFiltered}
            requestDataIsLoading={GetAllCompanyDetailsResponse.isLoading}
          />

          <ConfirmationDialog
            isOpenConfirmationDialog={isOpenDocumentRemovePopup}
            onCallback={oncallBack}
            title="Remove Transport Company"
            content="Do you want to remove this Company?"
          />
        </section>
      </AppLayout>
    </React.Fragment>
  )
}

export default ManageTransportCompanies