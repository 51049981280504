import { Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CustomDatePicker, CustomTimePicker, CustomAutocomplete, AppSkeleton } from '../../Shared/index'
import Stepper from '../../Shared/Stepper/Stepper'
import { StyledSwitch, StyledTextField } from '../../../assets/theme/theme'
import style from './GeneralInformation.module.scss'
import { ApproverBriefDto, GeneralInformationFormDto, LocationBriefDto, VehicleTypesDto, DefaultApproverDto, RequestByIdDto, StateObjectDto, SbuBriefDto, PlantBriefDto, DepartmentBriefDto, selectedType } from '../../../utilities/models'
import { APPROVER_DEPARTMENT, REQUEST_SCREEN_MODES, REQUEST_TYPES } from '../../../utilities/constants'
import dayjs, { Dayjs } from 'dayjs'
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import PlaceAutoComplete from '../../Shared/GoogleMap/PlaceAutoComplete/PlaceAutoComplete'

const GeneralInformation: React.FC<{
  helperText: boolean
  screenMode: string
  genaralInformationForm: GeneralInformationFormDto
  onInputHandleChangeGenaralInformation(property: string, value: any): void;
  onSearchValueChange(property: string, value: any): void;
  handleInputFocus(property: string, section: string): void;
  vehicleTypesResponse: StateObjectDto<VehicleTypesDto[]>
  frequentLocations: LocationBriefDto[]
  allSbuList: SbuBriefDto[];
  allApproverResponse: StateObjectDto<ApproverBriefDto[] | []>
  allPlantsBySbuIdResponse: StateObjectDto<PlantBriefDto[] | []>
  allDepartmentByPlantIdResponse: StateObjectDto<DepartmentBriefDto[] | []>
  defaultApproverResponse: StateObjectDto<DefaultApproverDto | null>
  requestByIdResponse: StateObjectDto<RequestByIdDto | null>
  setStartLocation(value: selectedType): void
  setEndLocation(value: selectedType): void
  onInputHandleChangeGoogleInput(property: string, value: any, section: string): void;
  switchValue:boolean;
  switchValue2:boolean;
}> = (props) => {
  const _requestType = props.genaralInformationForm.requestType
  const _depatureDate = props.genaralInformationForm.depatureDate
  const _departureTime = props.genaralInformationForm.departureTime
  const _fromLocation = props.genaralInformationForm.fromLocation
  const _toLocation = props.genaralInformationForm.toLocation
  const _returnDate = props.genaralInformationForm.returnDate
  const _returnTime = props.genaralInformationForm.returnTime
  const _isSameDay = props.genaralInformationForm.isSameDay
  const _isVIP = props.genaralInformationForm.isVIP
  const _approverDep = props.genaralInformationForm.approverDep
  const _approver = props.genaralInformationForm.approver
  const _vehicleType = props.genaralInformationForm.vehicleType
  const _purpose = props.genaralInformationForm.purpose
  const _sbu = props.genaralInformationForm.sbu
  const _plant = props.genaralInformationForm.plant
  const _department = props.genaralInformationForm.department

  const _allApprovers = props.allApproverResponse !== undefined && props.allApproverResponse?.data?.length > 0 ? props.allApproverResponse.data : []
  const _allPlants = props.allPlantsBySbuIdResponse !== undefined && props.allPlantsBySbuIdResponse?.data?.length > 0 ? props.allPlantsBySbuIdResponse.data : []
  const _allDepartment = props.allDepartmentByPlantIdResponse !== undefined && props.allDepartmentByPlantIdResponse?.data?.length > 0 ? props.allDepartmentByPlantIdResponse.data : []
  const _allVehicleTypes = props.vehicleTypesResponse !== undefined && props.vehicleTypesResponse?.data?.length > 0 ? props.vehicleTypesResponse.data : []

  const { ready, value, setValue, suggestions: { status, data }, clearSuggestions } = usePlacesAutocomplete();

  const [switchValue, setswitchValue] = useState(false)
  const [switchValue2, setswitchValue2] = useState(false)

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {

    setValue(event.target.value);
  };

  useEffect(() => {
    if(props.switchValue === false){
      setswitchValue(true)
    }
    if(props.switchValue2 === false){
      setswitchValue2(true)
    }
  }, [props.switchValue, props.switchValue2])

  useEffect(() => {
    if(props.screenMode === REQUEST_SCREEN_MODES.EDIT || props.screenMode === REQUEST_SCREEN_MODES.VIEW ||
      props.screenMode === REQUEST_SCREEN_MODES.PENDING_VEHICLE_VIEW || REQUEST_SCREEN_MODES.USE_TEMPLATE){
      if(switchValue === true){
        handleSelect(props.genaralInformationForm.fromLocation.value.location, 1)
      }
      if(switchValue2 === true){
        handleSelect(props.genaralInformationForm.toLocation.value.location, 2)
      }
    }
  },[switchValue, switchValue2])


  const handleSelect = async (address: string, typeID: number) => {
    console.log("usePlacesAutocomplete", address)
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);

    console.log("usePlacesAutocomplete", results)
    console.log("usePlacesAutocomplete", { lat, lng })
    if (typeID === 1) {
      props.setStartLocation({ lat, lng });
      setswitchValue(false)
      let data = { address: address, latAndLang: { lat: lat, lng: lng } }
      props.onInputHandleChangeGoogleInput("fromLocation", data, "GI")
    } else if (typeID === 2) {
      setswitchValue2(false)
      props.setEndLocation({ lat, lng });
      let data = { address: address, latAndLang: { lat: lat, lng: lng } }
      props.onInputHandleChangeGoogleInput("toLocation", data, "GI")
    } else if (typeID === 3) {
      // setMiddleLocations([...middleLocations, { lat, lng }]);
    }
  };


  const handleAddLocationClick = (value: string) => {
    if (value === "stLocatoon") {
      if (switchValue) {
        setswitchValue(false)
      } else {
        setswitchValue(true)
      }
    } else if (value === "endLocatoon") {
      if (switchValue2) {
        setswitchValue2(false)
      } else {
        setswitchValue2(true)

      }
    }
  }

  return (
    <Stepper stepNumber={1} stepTitle={"General Information"}>
      {props.requestByIdResponse && !props.requestByIdResponse.isLoading && (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <CustomAutocomplete
                label="Request Type"
                placeholder='Select request type'
                onFocus={() => props.handleInputFocus('requestType', 'GI')}
                options={REQUEST_TYPES.map((t) => { return { label: t.name, value: t.id } })}
                value={{ label: _requestType.value.name, value: _requestType.value.id }}
                error={!!_requestType.error}
                disabled={_requestType.disable}
                readonly={_requestType.readonly}
                required={_requestType.isRequired}
                helperText={props.helperText && _requestType.error}
                onChange={(event: any, value: any) => props.onInputHandleChangeGenaralInformation('requestType', value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomDatePicker
                    label="Departure date"
                    placeholder='Select Date'
                    multiple={_requestType.value.id === 2}
                    value={_depatureDate.value}
                    minDate={new Date()}
                    maxDate={_returnDate.value}
                    error={!!_depatureDate.error}
                    disabled={_depatureDate.disable}
                    readOnly={_depatureDate.readonly}
                    required={_depatureDate.isRequired}
                    helperText={props.helperText && _depatureDate.error}
                    onFocus={() => props.handleInputFocus('depatureDate', 'GI')}
                    onChange={(value: any) => props.onInputHandleChangeGenaralInformation('depatureDate', value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTimePicker
                    label="Departure at"
                    placeholder='Select Time'
                    value={_departureTime.value}
                    error={!!_departureTime.error}
                    disabled={_departureTime.disable}
                    readOnly={_departureTime.readonly}
                    required={_departureTime.isRequired}
                    helperText={props.helperText && _departureTime.error}
                    onFocus={() => props.handleInputFocus('departureTime', 'GI')}
                    onChange={(value: any) => props.onInputHandleChangeGenaralInformation("departureTime", value)}
                  />
                </Grid>
              </Grid>
            </Grid>

            {!switchValue &&
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={10} md={11} >
                    <CustomAutocomplete
                      freeSolo={true}
                      label="I want to travel from"
                      placeholder='Select location'
                      onFocus={() => props.handleInputFocus('fromLocation', 'GI')}
                      options={props.frequentLocations && props.frequentLocations.map((l: LocationBriefDto) => { return { label: l.location, value: l.id, } })}
                      value={{ label: _fromLocation.value.location, value: _fromLocation.value.id, }}
                      error={!!_fromLocation.error}
                      disabled={_fromLocation.disable}
                      readonly={_fromLocation.readonly}
                      required={_fromLocation.isRequired}
                      helperText={props.helperText && _fromLocation.error}
                      onChange={(event: any, value: any) => props.onInputHandleChangeGenaralInformation('fromLocation', value)}
                    />
                  </Grid>
                  <Grid item xs={2} md={1}>
                    <IconButton aria-label="delete" size="medium" onClick={() => { handleAddLocationClick("stLocatoon") }}>
                      <AddLocationAltIcon sx={{ color: "#ffffff" }} fontSize="medium" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            }

            {switchValue && <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={10} md={11} >
                  <PlaceAutoComplete
                    value={value}
                    ready={ready}
                    status={status}
                    data={data}
                    setValue={setValue}
                    required={_fromLocation.isRequired}
                    clearSuggestions={clearSuggestions}
                    handleInput={handleInput}
                    handleSelect={handleSelect}
                    placeHolder={"Start Location"}
                    type={1}
                  />
                </Grid>
                <Grid item xs={2} md={1}>
                  <IconButton aria-label="delete" size="medium" onClick={() => { handleAddLocationClick("stLocatoon") }}>
                    <AddLocationAltIcon sx={{ color: "#dd2126" }} fontSize="medium" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>}



            {!switchValue2 && <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={10} md={11} >
                  <CustomAutocomplete
                    freeSolo={true}
                    label="I want to travel to"
                    placeholder='Select location'
                    onFocus={() => props.handleInputFocus('', 'GI')}
                    options={props.frequentLocations && props.frequentLocations.map((l: LocationBriefDto) => { return { label: l.location, value: l.id } })}
                    value={{ label: _toLocation.value.location, value: _toLocation.value.id }}
                    error={!!_toLocation.error}
                    disabled={_toLocation.disable}
                    readonly={_toLocation.readonly}
                    required={_toLocation.isRequired}
                    helperText={props.helperText && _toLocation.error}
                    onChange={(event: any, value: any) => props.onInputHandleChangeGenaralInformation('toLocation', value)}
                  />
                </Grid>

                <Grid item xs={2} md={1}>
                  <IconButton aria-label="delete" size="medium" onClick={() => { handleAddLocationClick("endLocatoon") }}>
                    <AddLocationAltIcon sx={{ color: "#ffffff" }} fontSize="medium" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>}
            {switchValue2 && <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={10} md={11} >
                  <PlaceAutoComplete
                    value={value}
                    ready={ready}
                    status={status}
                    data={data}
                    setValue={setValue}
                    required={_fromLocation.isRequired}
                    clearSuggestions={clearSuggestions}
                    handleInput={handleInput}
                    handleSelect={handleSelect}
                    placeHolder={"End Location"}
                    type={2}
                  />
                </Grid>
                <Grid item xs={2} md={1}>
                  <IconButton aria-label="delete" size="medium" onClick={() => { handleAddLocationClick("endLocatoon") }}>
                    <AddLocationAltIcon sx={{ color: "#dd2126" }} fontSize="medium" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>}
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <div className={style.switchField}>
                    <Typography className={style.label}>Return same day</Typography>
                    <StyledSwitch
                      checked={_isSameDay.value}
                      disabled={_isSameDay.disable}
                      onChange={() => props.onInputHandleChangeGenaralInformation('isSameDay', !_isSameDay.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className={style.switchField}>
                    <Typography className={style.label}>VIP Trip</Typography>
                    <StyledSwitch
                      checked={_isVIP.value}
                      disabled={_isVIP.disable}
                      onChange={() => props.onInputHandleChangeGenaralInformation('isVIP', !_isVIP.value)}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomDatePicker
                    label="Return date"
                    placeholder='Select Date'
                    multiple={_requestType.value.id === 2}
                    value={_returnDate.value}
                    minDate={dayjs(_depatureDate.value as Dayjs).add(1, 'day').format('YYYY/MM/DD')}
                    error={!!_returnDate.error}
                    disabled={_returnDate.disable}
                    readOnly={_returnDate.readonly}
                    required={_returnDate.isRequired}
                    helperText={props.helperText && _returnDate.error}
                    onFocus={() => props.handleInputFocus('returnDate', 'GI')}
                    onChange={(value: any) => props.onInputHandleChangeGenaralInformation('returnDate', value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTimePicker
                    label="Return at"
                    placeholder='Select Time'
                    value={_returnTime.value}
                    error={!!_returnTime.error}
                    disabled={_returnTime.disable}
                    readOnly={_returnTime.readonly}
                    required={_returnTime.isRequired}
                    helperText={props.helperText && _returnTime.error}
                    onFocus={() => props.handleInputFocus('returnTime', 'GI')}
                    onChange={(value: any) => props.onInputHandleChangeGenaralInformation("returnTime", value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomAutocomplete
                label="Preferred vehicle type"
                placeholder='Select preferred vehicle type'
                onFocus={() => props.handleInputFocus('vehicleType', 'GI')}
                options={_allVehicleTypes.map((v: VehicleTypesDto) => { return { label: v.description, value: v.id } })}
                value={{ label: _vehicleType.value.description, value: _vehicleType.value.id }}
                error={!!_vehicleType.error}
                disabled={_vehicleType.disable}
                required={_vehicleType.isRequired}
                readonly={_vehicleType.readonly}
                helperText={props.helperText && _vehicleType.error}
                onChange={(event: any, value: any) => props.onInputHandleChangeGenaralInformation('vehicleType', value)}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                fullWidth
                label="Purpose"
                placeholder='Enter purpose'
                size='small'
                InputProps={{
                  readOnly: _purpose.readonly
                }}
                value={_purpose.value}
                error={!!_purpose.error}
                disabled={_purpose.disable}
                required={_purpose.isRequired}
                helperText={props.helperText && _purpose.error}
                onFocus={() => props.handleInputFocus('purpose', 'GI')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChangeGenaralInformation('purpose', event.target.value)}
              />
            </Grid>
          </Grid>

          <section style={{ marginTop: '20px' }}>
            <Typography sx={{ fontSize: '10px' }}>Select Billable Department*</Typography><br />
          </section>
          <section style={{}}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <CustomAutocomplete
                  label="SBU"
                  placeholder='Select sbu'
                  onFocus={() => props.handleInputFocus('sbu', 'GI')}
                  options={props.allSbuList.map((t) => { return { label: t.name, value: t.id } })}
                  value={_sbu.value}
                  error={!!_sbu.error}
                  disabled={_sbu.disable}
                  readonly={_sbu.readonly}
                  required={_sbu.isRequired}
                  helperText={props.helperText && _sbu.error}
                  onChange={(event: any, value: any) => props.onInputHandleChangeGenaralInformation('sbu', value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomAutocomplete
                  label="Plant"
                  placeholder='Select Plant'
                  onFocus={() => props.handleInputFocus('plant', 'GI')}
                  options={_allPlants.map((p) => { return { label: p.plantName, value: p.id } })}
                  value={_plant.value}
                  error={!!_plant.error}
                  disabled={_plant.disable}
                  readonly={_plant.readonly}
                  required={_plant.isRequired}
                  helperText={props.helperText && _plant.error}
                  onChange={(event: any, value: any) => props.onInputHandleChangeGenaralInformation('plant', value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomAutocomplete
                  label="Department"
                  placeholder='Select department'
                  onFocus={() => props.handleInputFocus('department', 'GI')}
                  options={_allDepartment.map((t) => { return { label: t.plantName, value: t.id } })}
                  value={_department.value}
                  error={!!_department.error}
                  disabled={_department.disable}
                  readonly={_department.readonly}
                  required={_department.isRequired}
                  helperText={props.helperText && _department.error}
                  onChange={(event: any, value: any) => props.onInputHandleChangeGenaralInformation('department', value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {props.defaultApproverResponse.isLoading ?
                  <AppSkeleton numOfRows={1} numOfColumns={1} columnWidth={50} height={37} tag="formField" /> :
                  <CustomAutocomplete
                    label="Approver's department"
                    placeholder='Select department'
                    onFocus={() => props.handleInputFocus('approverDep', 'GI')}
                    options={APPROVER_DEPARTMENT.map((d) => { return { label: d.label, value: d.value } })}
                    value={_approverDep.value}
                    error={!!_approverDep.error}
                    disabled={_approverDep.disable}
                    readonly={_approverDep.readonly}
                    required={_approverDep.isRequired}
                    helperText={props.helperText && _approverDep.error}
                    onChange={(event: any, value: any) => props.onInputHandleChangeGenaralInformation('approverDep', value)}
                  />
                }
              </Grid>
              <Grid item xs={12} md={6}>
                {props.defaultApproverResponse.isLoading ?
                  <AppSkeleton numOfRows={1} numOfColumns={1} columnWidth={50} height={37} tag="formField" /> :
                  <CustomAutocomplete
                    label="Approver"
                    placeholder='Select approver'
                    onFocus={() => props.handleInputFocus('approver', 'GI')}
                    options={_approverDep.value && _approverDep.value.value === "same department" ?
                      [{ label: props.defaultApproverResponse.data?.defaultApproverName, value: props.defaultApproverResponse.data?.defaultApproverId }] :
                      _allApprovers.map((a) => { return { label: a.name, value: a.approver } })
                    }
                    value={_approver.value}
                    error={!!_approver.error}
                    disabled={_approver.disable}
                    required={_approver.isRequired}
                    readonly={_approver.readonly}
                    helperText={props.helperText && _approver.error}
                    onChange={(event: any, value: any) => props.onInputHandleChangeGenaralInformation('approver', value)}
                    onInputChange={(event: any, value: any) => props.onSearchValueChange('approver', value)}
                  />
                }
              </Grid>
            </Grid>
          </section>
        </>
      )}
      {props.requestByIdResponse.isLoading &&
        <AppSkeleton numOfRows={7} numOfColumns={2} columnWidth={50} height={40} tag="stepper" />
      }
    </Stepper>
  )
}

export default GeneralInformation
