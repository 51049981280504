import React from 'react'
import { CustomAutocomplete, CustomButton, CustomDatePicker, CustomHeaderCell, CustomStepper } from '../../Shared'
import { DriverIncidentFormDto, DriverIncidentListDto, VehicleNumberDto } from '../../../utilities/models'
import { Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { StyledTableCell, StyledTextField } from '../../../assets/theme/theme'
import style from './IncidentInformations.module.scss'
import { APP_TABLE_CONFIGS, DRIVER_SCREEN_MODES } from '../../../utilities/constants'

const IncidentInformations: React.FC<{
  page: number,
  rowsPerPage: number,
  onHandleChangePage(event: unknown, newPage: number): void,
  onHandleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void,
  helperText: boolean
  screenMode: string
  driverIncidentForm: DriverIncidentFormDto
  driverIncidentList: DriverIncidentListDto[]
  vehicleNumberByDriver: VehicleNumberDto[]
  onAddIncident(): void
  onClearAddIncidentForm(): void
  onSortHandle(col: string): void
  handleInputFocus(property: string, section: string): void
  onInputHandleChangeDriverIncident(property: string, section: string): void
}> = (props) => {
  const _incident = props.driverIncidentForm.incident
  const _date = props.driverIncidentForm.date
  const _outcome = props.driverIncidentForm.outcome
  const _vehicleNumber = props.driverIncidentForm.vehicleNumber

  return (
    <CustomStepper stepNumber={5} stepTitle="Incidents Details">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <StyledTextField
                fullWidth
                label="Incident"
                placeholder='Enter Incident'
                size='small'
                InputProps={{
                  readOnly: _incident.readonly
                }}
                value={_incident.value}
                error={!!_incident.error}
                disabled={_incident.disable}
                required={_incident.isRequired}
                helperText={props.helperText && _incident.error}
                onFocus={() => props.handleInputFocus('incident', 'incident-information')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChangeDriverIncident('incident', event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                label="Date"
                placeholder='Select Date'
                multiple={false}
                maxDate={new Date()}
                value={_date.value}
                error={!!_date.error}
                disabled={_date.disable}
                readOnly={_date.readonly}
                required={_date.isRequired}
                helperText={props.helperText && _date.error}
                onFocus={() => props.handleInputFocus('date', 'incident-information')}
                onChange={(value: any) => props.onInputHandleChangeDriverIncident('date', value)}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                fullWidth
                label="Outcome"
                placeholder='Enter Outcome'
                size='small'
                InputProps={{
                  readOnly: _outcome.readonly
                }}
                value={_outcome.value}
                error={!!_outcome.error}
                disabled={_outcome.disable}
                required={_outcome.isRequired}
                helperText={props.helperText && _outcome.error}
                onFocus={() => props.handleInputFocus('outcome', 'incident-information')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChangeDriverIncident('outcome', event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomAutocomplete
                label="Vehicle Number"
                placeholder='Select Vehicle Number'
                options={props.vehicleNumberByDriver.map((v) => { return { label: v.name, value: v.id } })}
                value={_vehicleNumber.value}
                error={!!_vehicleNumber.error}
                disabled={_vehicleNumber.disable}
                required={_vehicleNumber.isRequired}
                readonly={_vehicleNumber.readonly}
                helperText={props.helperText && _vehicleNumber.error}
                onFocus={() => props.handleInputFocus('vehicleNumber', 'incident-information')}
                onChange={(event: any, value: any) => props.onInputHandleChangeDriverIncident('vehicleNumber', value)}
              />
            </Grid>
          </Grid>

          <section className={style.actions}>
            <CustomButton text='Clear' textColor='black' bgColor='#bfbfbf' onClick={props.onClearAddIncidentForm} />
            <CustomButton text='Add Incident' border='1px solid #6e6e6e' bgColor='#282828' onClick={props.onAddIncident} />
          </section>

      <section>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <CustomHeaderCell id='id' >#</CustomHeaderCell>
                <CustomHeaderCell width={400} id='incident' sortable onSort={props.onSortHandle} >Incident</CustomHeaderCell>
                <CustomHeaderCell id='vehicleNumber' sortable onSort={props.onSortHandle} >Vehicle Number</CustomHeaderCell>
                <CustomHeaderCell id='date' sortable onSort={props.onSortHandle} >Date</CustomHeaderCell>
                <CustomHeaderCell id='outcome' sortable onSort={props.onSortHandle} >Outcome</CustomHeaderCell>
              </TableRow>
            </TableHead>
            {props.driverIncidentList && props.driverIncidentList.length > 0 &&
              <TableBody>
                {props.driverIncidentList.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage).map((p) => (
                  <TableRow key={p.id}>
                    <StyledTableCell >{p.id}</StyledTableCell>
                    <StyledTableCell >{p.incident}</StyledTableCell>
                    <StyledTableCell >{p.vehicleNumber}</StyledTableCell>
                    <StyledTableCell >{p.date}</StyledTableCell>
                    <StyledTableCell >{p.outcome}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            }
            {props.driverIncidentList.length === 0 &&
              <TableBody>
                <TableRow>
                  <StyledTableCell align="left" colSpan={7}>No data to preview</StyledTableCell>
                </TableRow>
              </TableBody>
            }
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_OPTIONS}
          component="div"
          labelRowsPerPage={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                color: 'white',
              }}
            >
              Items per page
            </div>
          }
          count={props.driverIncidentList.length}
          page={props.page}
          onPageChange={props.onHandleChangePage}
          onRowsPerPageChange={props.onHandleChangeRowsPerPage}
          rowsPerPage={props.rowsPerPage}
          showFirstButton={true}
          showLastButton={true}
          sx={{ backgroundColor: "#282828", color: "white" }}
        />
      </section>
    </CustomStepper>
  )
}

export default IncidentInformations
