
import CryptoJS from 'crypto-js'
import Cookies from 'universal-cookie'
import { APP_CONFIGS, SECRET_KEY } from '../utilities/constants'
import { AuthorizedUserDto } from '../utilities/models'

export const authorizeUser = async (userInfo: AuthorizedUserDto) => {
  const cookies = new Cookies()
  // Encrypt
  const cipherAuthorizedUserData = CryptoJS.AES.encrypt(JSON.stringify(userInfo), SECRET_KEY).toString()

  // generate cookie expire
  const expireDate = new Date()
  expireDate.setDate(expireDate.getDate() + 1)
  cookies.set(APP_CONFIGS.USER_DATA_COOKIE, cipherAuthorizedUserData, {
    path: '/',
    expires: expireDate
  })
}

export const getAuthorizedUser = (): AuthorizedUserDto => {
  // auth data
  const cookies = new Cookies()
  const userDataString = cookies.get(APP_CONFIGS.USER_DATA_COOKIE)
  if (!userDataString) {
    const AuthorizedUserDto: AuthorizedUserDto = {} as AuthorizedUserDto
    return AuthorizedUserDto
  } else {
    const bytes = CryptoJS.AES.decrypt(userDataString, SECRET_KEY)
    const userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return userData
  }
}

export const authService = {
  authorizeUser,
  getAuthorizedUser
}
