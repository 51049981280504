import React from 'react'
import { AppSkeleton, CustomAutocomplete, CustomButton, CustomHeaderCell, CustomStepper } from '../../Shared'
import { AllPlantBriefDto, AssignVehicleFormDto, AssignVehicleListDto, VehicleNumberDto, VehicleTypesDto } from '../../../utilities/models'
import { Box, Grid, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material'
import { StyledTableCell } from '../../../assets/theme/theme'
import { EditOutlined } from '@mui/icons-material'
import style from './AssignVehicles.module.scss'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import NoCrashOutlinedIcon from '@mui/icons-material/NoCrashOutlined';
import { APP_TABLE_CONFIGS, DRIVER_SCREEN_MODES } from '../../../utilities/constants'

const AssignVehicles: React.FC<{
  page: number,
  rowsPerPage: number,
  onHandleChangePage(event: unknown, newPage: number): void,
  onHandleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void,
  helperText: boolean
  isEditVehicle: boolean
  screenMode: string
  isLoading: boolean
  assignVehicleForm: AssignVehicleFormDto
  assignVehicleList: AssignVehicleListDto[]
  allPlantList: AllPlantBriefDto[]
  vehicleTypesByPlant: VehicleTypesDto[]
  vehicleNumberByVehicleType: VehicleNumberDto[]
  onAssignVehicle(): void
  onEditVehicle(): void
  onClearAssignVehicleForm(): void
  onSelectVehicle(id: number, action: string): void
  onSetActiveVehicle(id: number): void
  onSortHandle(col: string): void
  handleInputFocus(property: string, section: string): void
  onInputHandleChangeAssignDriver(property: string, section: string): void
}> = (props) => {
  const _plant = props.assignVehicleForm.plant
  const _vehicleType = props.assignVehicleForm.vehicleType
  const _vehicleNumber = props.assignVehicleForm.vehicleNumber

  return (
    <CustomStepper stepNumber={3} stepTitle="Assign Vehicles">
      {!props.isLoading &&
        <>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <CustomAutocomplete
                label="Plant"
                placeholder='Select Plant'
                options={props.allPlantList.map((p) => { return { label: p.name, value: p.id } })}
                value={_plant.value}
                error={!!_plant.error}
                disabled={_plant.disable}
                required={_plant.isRequired}
                readonly={_plant.readonly || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                helperText={props.helperText && _plant.error}
                onFocus={() => props.handleInputFocus('plant', 'assign-vehicle')}
                onChange={(event: any, value: any) => props.onInputHandleChangeAssignDriver('plant', value)}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomAutocomplete
                label="Vehicle Type"
                placeholder='Select Vehicle Type'
                options={props.vehicleTypesByPlant.map((t) => { return { label: t.description, value: t.id } })}
                value={_vehicleType.value}
                error={!!_vehicleType.error}
                disabled={_vehicleType.disable}
                required={_vehicleType.isRequired}
                readonly={_vehicleType.readonly || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                helperText={props.helperText && _vehicleType.error}
                onFocus={() => props.handleInputFocus('vehicleType', 'assign-vehicle')}
                onChange={(event: any, value: any) => props.onInputHandleChangeAssignDriver('vehicleType', value)}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomAutocomplete
                label="Vehicle License Plate Number"
                placeholder='Select Vehicle License Plate Number'
                options={props.vehicleNumberByVehicleType.map((v) => { return { label: v.name, value: v.id } })}
                value={_vehicleNumber.value}
                error={!!_vehicleNumber.error}
                disabled={_vehicleNumber.disable}
                required={_vehicleNumber.isRequired}
                readonly={_vehicleNumber.readonly || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                helperText={props.helperText && _vehicleNumber.error}
                onFocus={() => props.handleInputFocus('vehicleNumber', 'assign-vehicle')}
                onChange={(event: any, value: any) => props.onInputHandleChangeAssignDriver('vehicleNumber', value)}
              />
            </Grid>
          </Grid>
            <section className={style.actions}>
              <CustomButton text='Clear' textColor='black' bgColor='#bfbfbf' disabled={props.screenMode === DRIVER_SCREEN_MODES.VIEW} onClick={() => props.onClearAssignVehicleForm()} />
              <CustomButton text={props.isEditVehicle ? 'Edit Vehicle' : 'Add Vehicle'} disabled={props.screenMode === DRIVER_SCREEN_MODES.VIEW} border='1px solid #6e6e6e' bgColor='#282828' onClick={props.isEditVehicle ? props.onEditVehicle : props.onAssignVehicle} />
            </section>
        </>
      }
      {props.isLoading &&
        <section style={{ marginBottom: '20px' }}>
          <AppSkeleton numOfRows={2} numOfColumns={2} columnWidth={50} height={40} tag="stepper" />
        </section>
      }

      <section>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <CustomHeaderCell id='id' >#</CustomHeaderCell>
                <CustomHeaderCell id='vehicleType' sortable onSort={props.onSortHandle} >Vehicle Type</CustomHeaderCell>
                <CustomHeaderCell id='vehicleNumber' sortable onSort={props.onSortHandle} >Vehicle Number</CustomHeaderCell>
                <CustomHeaderCell id='plant' sortable onSort={props.onSortHandle} >Plant</CustomHeaderCell>
                {props.screenMode !== DRIVER_SCREEN_MODES.VIEW && <CustomHeaderCell width={150} id='actions' >Actions</CustomHeaderCell>}
              </TableRow>
            </TableHead>
            {!props.isLoading && props.assignVehicleList  &&
              <TableBody>
                {props.assignVehicleList.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage).map((p) => (
                  <TableRow key={p.id}>
                    <StyledTableCell >{p.id}</StyledTableCell>
                    <StyledTableCell >{p.vehicleType}</StyledTableCell>
                    <StyledTableCell ><Typography className={p.isActive ? style.active : style.inactive}>{p.vehicleNumber}</Typography></StyledTableCell>
                    <StyledTableCell >{p.plant}</StyledTableCell>
                    {props.screenMode !== DRIVER_SCREEN_MODES.VIEW &&
                      <StyledTableCell style={{ backgroundColor: '#282828' }}>
                        <Box className='layout-row'>
                          {!p.isActive && p.isNew &&
                            <Box>
                              <IconButton size='small' onClick={() => props.onSelectVehicle(p.id, "delete")}>
                                <Tooltip title="Delete">
                                  <DeleteOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                </Tooltip>
                              </IconButton>
                            </Box>
                          }
                          {p.isNew &&
                            <Box>
                              <IconButton size='small' onClick={() => props.onSelectVehicle(p.id, "edit")}>
                                <Tooltip title="Edit">
                                  <EditOutlined sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                </Tooltip>
                              </IconButton>
                            </Box>
                          }
                          {!p.isActive &&
                            <Box>
                              <IconButton size='small' onClick={() => props.onSetActiveVehicle(p.id)}>
                                <Tooltip title="Active vehicle">
                                  <NoCrashOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                </Tooltip>
                              </IconButton>
                            </Box>
                          }
                        </Box>
                      </StyledTableCell>
                    }
                  </TableRow>
                ))}
              </TableBody>
            }
            {!props.isLoading && props.assignVehicleList.length === 0 &&
              <TableBody>
                <TableRow>
                  <StyledTableCell align="left" colSpan={7}>No data to preview</StyledTableCell>
                </TableRow>
              </TableBody>
            }
            {props.isLoading && (
              <AppSkeleton numOfRows={5} numOfColumns={props.screenMode === DRIVER_SCREEN_MODES.VIEW ? 4 : 5} columnWidth={100} tag="table" />
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_OPTIONS}
          component="div"
          labelRowsPerPage={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                color: 'white',
              }}
            >
              Items per page
            </div>
          }
          count={props.assignVehicleList.length}
          page={props.page}
          onPageChange={props.onHandleChangePage}
          onRowsPerPageChange={props.onHandleChangeRowsPerPage}
          rowsPerPage={props.rowsPerPage}
          showFirstButton={true}
          showLastButton={true}
          sx={{ backgroundColor: "#282828", color: "white" }}
        />
      </section>
    </CustomStepper>
  )
}

export default AssignVehicles
