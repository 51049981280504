import React from 'react'
import styles from './AddMultipleLocationPopup.module.scss'
import { BootstrapDialog, BootstrapDialogActions, BootstrapDialogContent, BootstrapDialogTitle } from '../../../assets/theme/theme'
import { Grid } from '@mui/material'
import { CustomAutocomplete, CustomButton } from '../../Shared'
import { LocationBriefDto, PassengerDetailsFormDto } from '../../../utilities/models'

const AddMultipleLocationPopup: React.FC<{
  helperText: boolean
  passengerDetailsForm: PassengerDetailsFormDto
  onInputHandleChangePassengerDetails(property: string, value: any): void;
  handleInputFocus(property: string, section: string): void;
  isOpenAddMultipleLocationPopup: boolean
  showAddMultipleLocationPopup(con: boolean): void
  frequentLocations: LocationBriefDto[]
}> = (props) => {
  const _stopOne = props.passengerDetailsForm.intermediateLocation
  const _stopTwo = props.passengerDetailsForm.dropLocation
  return (
    <BootstrapDialog
    width={"800px"}
      className={styles.dialogCard}
      aria-labelledby="customized-dialog-title"
      open={props.isOpenAddMultipleLocationPopup}
    >
      <BootstrapDialogTitle id="customized-dialog-title"
        onClose={() => props.showAddMultipleLocationPopup(false)}
      >
        Add multiple locations
      </BootstrapDialogTitle>
      <BootstrapDialogContent>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <CustomAutocomplete
              label="Stop one"
              placeholder='Select location'
              onFocus={() => props.handleInputFocus('stopOne', 'PD')}
              options={props.frequentLocations && props.frequentLocations.map((l: LocationBriefDto) => { return {label: l.location, value: l.id} })}
              value={{ label: _stopOne.value.location, value: _stopOne.value.id }}
              error={!!_stopOne.error}
              disabled={_stopOne.disable}
              readonly={_stopOne.readonly}
              required={_stopOne.isRequired}
              helperText={props.helperText && _stopOne.error}
              onChange={(event: any, value: any) => props.onInputHandleChangePassengerDetails('stopOne', value)}
            />
          </Grid>
          <Grid item md={12}>
            <CustomAutocomplete
              label="Stop Two"
              placeholder='Select location'
              onFocus={() => props.handleInputFocus('stopTwo', 'PD')}
              options={props.frequentLocations && props.frequentLocations.map((l: LocationBriefDto) => { return {label: l.location, value: l.id} })}
              value={{ label: _stopTwo.value.location, value: _stopTwo.value.id }}
              error={!!_stopTwo.error}
              disabled={_stopTwo.disable}
              readonly={_stopTwo.readonly}
              required={_stopTwo.isRequired}
              helperText={props.helperText && _stopTwo.error}
              onChange={(event: any, value: any) => props.onInputHandleChangePassengerDetails('stopTwo', value)}
            />
          </Grid>
        </Grid>
      </BootstrapDialogContent>
      <BootstrapDialogActions>
        <CustomButton text='Cancel' border='1px solid #6e6e6e' bgColor='#282828' onClick={() => props.showAddMultipleLocationPopup(false)} />
        <CustomButton text='Send' 
        disabled={
          (!_stopOne.value || (!!_stopOne.value && Object.keys(_stopOne.value).length === 0 && _stopOne.value.constructor === Object))
        } 
        onClick={() => props.showAddMultipleLocationPopup(false)} 
        />
      </BootstrapDialogActions>
    </BootstrapDialog>
  )
}

export default AddMultipleLocationPopup
