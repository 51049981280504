import React, { useEffect, useState } from "react";
import { AppLayout } from "../../templates";
import { ApplicationStateDto, LMRequestApprovalParamDto, LMRequestSummaryListDto, LmRequestSummaryBodyDto, RequestSummaryParamDto, SortMetaDto, filterationParam,} from "../../utilities/models";
import { useDispatch, useSelector } from "react-redux";
import { chartActions, requestActions } from "../../redux/actions";
import { APP_ACTION_STATUS, APP_ROUTES, APP_TABLE_CONFIGS,} from "../../utilities/constants";
import { LmRequestApprovalGrid } from "../../components/LmRequestApproval";
import LmRequestApprovalSummary from "../../components/LmRequestApproval/LmRequestApprovalSummary/LmRequestApprovalSummary";
import { ConfirmationDialog } from "../../components";
import { useNavigate } from "react-router";
import moment from "moment";
import dayjs from "dayjs";
import TmRequestRejectPopupView from "../../components/TmRequestApproval/TmRequestRejectPopupView/TmRequestRejectPopupView";

const LmRequestApproval = () => {
  const INITIAL_SORT_META: SortMetaDto = {
    field: "",
    asc: false,
  };

  const filterParam:filterationParam ={
    pending:true
  }

  const INITIAL_FILTER_OPTIONS : LmRequestSummaryBodyDto = {
    parentRequestId: null,
    recurrentParentId: null,
    requestType: null,
    userName: null,
    sbuName: null,
    plantName: null,
    departmentName: null,
    travelMode: null,
    fromLocation: null,
    toLocation: null,
    passengerCount: null,
    preferredVehicle: null,
    package: null,
    createdBy: null,
    status: null,
    createdFromDateandTime: null,
    createdToDateandTime: null,
    departureFromDateandTime: null,
    departureToDateandTime: null,
    returnFromDateandTime: null,
    returnToDateandTime: null
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE);
  const [sortMeta, setSortMeta] = useState<SortMetaDto>(INITIAL_SORT_META);
  const [filterBody, setFilterBody] = useState<LmRequestSummaryBodyDto>(INITIAL_FILTER_OPTIONS);
  const [filteredList, setFilteredList] = useState<LMRequestSummaryListDto[]>([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [isOpenReject, setIsOpenReject] = useState(false);
  const [filters, setFilters] = useState<any[]>([]);

  const getRequestDataResponse = useSelector((state: ApplicationStateDto) => state.request.getLMRequestList);
  const linemanageApprovalResponse = useSelector((state: ApplicationStateDto) => state.request.lmApproval);
  const lmSummaryCardResponse = useSelector((state: ApplicationStateDto) => state.chart.getLMBudgetSummary);
  const getLmSummaryFiltersRes = useSelector((state:ApplicationStateDto) => state.request.getLMRequestSummaryFilters);


  useEffect(() => {
    // getRequestList(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE, 1);
    dispatch(chartActions.getLMBudgetSummary());
    dispatch(requestActions.getLMRequestSummaryFilters(filterParam));
    return () => {
      dispatch(requestActions.lineManagerApprovalClear())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(getLmSummaryFiltersRes.status === APP_ACTION_STATUS.SUCCESS){
      setFilters(getLmSummaryFiltersRes.data)
    }
  }, [getLmSummaryFiltersRes.status])

  useEffect(() => {
    if (getRequestDataResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (getRequestDataResponse?.data.length > 0) {
        setFilteredList(
          getRequestDataResponse?.data?.map((item) => {
            return {
              ...item,
              isSelect: false,
            };
          })
        );
      } else setFilteredList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequestDataResponse.status]);

  useEffect(() => {
    if (linemanageApprovalResponse.status === APP_ACTION_STATUS.SUCCESS) {
      navigate(APP_ROUTES.LM_DASHBOARD)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linemanageApprovalResponse.status]);

  const getRequestList = async (r: number, p: number) => {
    const _param: RequestSummaryParamDto = {
      pagination: r,
      pageNumber: p,
      pending: true,
      filter: filterBody
    };
    dispatch(requestActions.getLMRequestSummaryList(_param));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    getRequestList(rowsPerPage, newPage + 1);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    getRequestList(+event.target.value, 1);
    setPage(0);
  };

  const onSelectRequestIds = (id: string, value: boolean) => {
    if (id === "ALL") {
      setFilteredList(
        filteredList.map((item) => {
          return {
            ...item,
            isSelect: value,
          };
        })
      );
    } else {
      setFilteredList(
        filteredList.map((item) => {
          return {
            ...item,
            isSelect: id === item.requestId ? value : item.isSelect,
          };
        })
      );
    }
  };
  const navigteTORequestScreen = (mode: string, id: string) => {
    sessionStorage.setItem("Mode", mode);
    sessionStorage.setItem("id", id);
    navigate(APP_ROUTES.MANAGE_REQUEST);
  };

  const onApproveRequest = (con: boolean) => {
    setIsOpenApprove(false);
    if (con) {
      let _list: Array<string> = [];
      filteredList.forEach((req) => {
        if (req.isSelect) {
          _list = [..._list, req.requestId];
        }
      });
      const param: LMRequestApprovalParamDto = {
        requestId: _list,
        approve: true,
        note: "",
      };
      dispatch(requestActions.lineManagerApproval(param));
    }
  };

  const onRejectRequest = (con: boolean,reason: string) => {
    setIsOpenReject(false);
    if (con) {
      let _list: Array<string> = [];
      filteredList.forEach((req) => {
        if (req.isSelect) {
          _list = [..._list, req.requestId];
        }
      });
      const param: LMRequestApprovalParamDto = {
        requestId: _list,
        approve: false,
        note: reason,
      };
      dispatch(requestActions.lineManagerApproval(param));
    }
  };

  const onActionButtonClick = (action: string) => {
    let _list: Array<string> = [];
    filteredList.forEach((req) => {
      if (req.isSelect) {
        _list = [..._list, req.requestId];
      }
    });
    if (_list.length > 0) {
      if (action === "approve") setIsOpenApprove(true);
      else setIsOpenReject(true);
    }
  };

  const onSortHandle = (col: string) => {
    const sorted = filteredList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setFilteredList(sorted);
  };

  const onClearFilter = () => {
    setIsFiltered(false)
    setFilterBody(INITIAL_FILTER_OPTIONS)
    setPage(0)
  };

  // const onFilterHandle = (col: string, value: any) => {
  //   setIsFiltered(true);
  //   const _list = getRequestDataResponse.data;
  //   const filtered = _list.filter((item) => {
  //     const _value = (item as any)[col];
  //     if (typeof _value === "boolean") {
  //       return _value ? value === "Yes" : value === "No";
  //     }
  //     if(col === "createdDateandTime"){
  //       const _selectedMin = dayjs(value[0]).format('YYYY-MM-DD HH:mm')
  //       const _selectedMax = dayjs(value[1]).format('YYYY-MM-DD HH:mm')
  //       const _targetDate = dayjs(_value).add(330, 'minute').format('YYYY-MM-DD HH:mm')

  //       return moment(_targetDate).isBetween(_selectedMin, _selectedMax)
  //     }
  //     if (col === "departureDateandTime" || col === "returnDateandTime") {
  //       const _selectedMin = dayjs(value[0]).format("YYYY-MM-DD HH:mm");
  //       const _selectedMax = dayjs(value[1]).format("YYYY-MM-DD HH:mm");
  //       const _targetDate = dayjs(_value).format("YYYY-MM-DD HH:mm");

  //       return moment(_targetDate).isBetween(_selectedMin, _selectedMax);
  //     }
  //     if(value === 'N/A') return !_value
  //     return _value === value;
  //   });

  //   setFilteredList(filtered);
  // };

  const onFilterHandle = (col: string, value: any) => {
    console.log("CHECKING CALLED onFilterHandle", col, value)
    setIsFiltered(true)

    if (filterBody.hasOwnProperty(col)) {
      setFilterBody(prevFilterBody => ({
        ...prevFilterBody,
        [col]: value,
      }));
    } else {
      if (col === "createdDateandTime") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DDTHH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DDTHH:mm");

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          createdFromDateandTime: _selectedMin,
          createdToDateandTime: _selectedMax
        }));
      } else if (col === "departureDateandTime") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DDTHH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DDTHH:mm");

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          departureFromDateandTime: _selectedMin,
          departureToDateandTime: _selectedMax
        }));
      } else if (col === "returnDateandTime") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DDTHH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DDTHH:mm");

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          returnFromDateandTime: _selectedMin,
          returnToDateandTime: _selectedMax
        }));
      }else if(col === "packageX"){
        const _valueCon = value === "Yes" ? true : false

        setFilterBody(prevFilterBody => ({
          ...prevFilterBody,
          package:_valueCon
        }));
      }
    }
  }

  useEffect(() => {
      getRequestList(rowsPerPage, 1)
      setPage(0)
  },[isFiltered, filterBody])

  useEffect(() => {
    setFilteredList(getRequestDataResponse.data);
  },[filterBody])

  const getFilterList = (col: string): string[] => {
    if (!getRequestDataResponse?.isLoading) {
      return getRequestDataResponse?.data
        ?.map((item) => {
          const value = (item as any)[col];
          if (typeof value === "boolean") {
            return value ? "Yes" : "No";
          }
          return value ? value : 'N/A';
        })
        ?.filter((value, index, array) => array.indexOf(value) === index);
    } else return [];
  };
  return (
    <React.Fragment>
      <AppLayout componentTitle="Request Approval">
        <section className="page-root">
          <LmRequestApprovalSummary 
            lmSummaryCardResponse={lmSummaryCardResponse}
            filteredList={filteredList || []}
            onSelectRequestIds={onSelectRequestIds}
          />
          <LmRequestApprovalGrid
            page={page}
            rowsPerPage={rowsPerPage}
            onHandleChangePage={handleChangePage}
            onHandleChangeRowsPerPage={handleChangeRowsPerPage}
            requestDataIsLoading={getRequestDataResponse.isLoading}
            filteredList={filteredList || []}
            sortMeta={sortMeta}
            onSortHandle={onSortHandle}
            onFilterHandle={onFilterHandle}
            onActionButtonClick={onActionButtonClick}
            onSelectRequestIds={onSelectRequestIds}
            navigateTo={navigteTORequestScreen}
            onClearFilter={onClearFilter}
            isFiltered={isFiltered}
            filters={filters}
          />
          <ConfirmationDialog
            isOpenConfirmationDialog={isOpenApprove}
            onCallback={onApproveRequest}
            title="Are you sure ?"
            content="Are you sure you want to approve ?"
            confirmButtonTitle="Yes"
            cancelButtonTitle="No"
          />
          {/* <ConfirmationDialog
            isOpenConfirmationDialog={isOpenReject}
            onCallback={onRejectRequest}
            title="Are you sure ?"
            content="Are you sure you want to reject ?"
            confirmButtonTitle="Yes"
            cancelButtonTitle="No"
          /> */}
           <TmRequestRejectPopupView
            isOpenConfirmationDialog={isOpenReject}
            onCallback={(confirmed: boolean, reason: string) =>
              onRejectRequest(confirmed, reason)
            }
            title="Are you sure?"
            content="Are you sure you want to reject?"
            confirmButtonTitle="Yes"
            cancelButtonTitle="No"
          />
        </section>
      </AppLayout>
    </React.Fragment>
  );
};

export default LmRequestApproval;
