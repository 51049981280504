import { vehicleService } from "../../services/vehicle.service"
import { call, put, takeEvery } from 'redux-saga/effects'
import { AxiosResponse } from 'axios';
import { AddVehicleIncidentParamDto, AlertDto, EditVehicleParamDto, NewVehiclePayloadDto, PendingAllocationParamDto, PendingVehicleAllocationsDto, VehicleDto, VehicleIncidentListDto, VehicleTypesDto, allocationHistoryDto, allocationRequisitionsDto, cancelAllocationParm, getDetailsSummaryParam } from "../../utilities/models";
import { ALERT_ACTION_TYPES, COMMON_ACTION_TYPES, VEHICLE_ACTION_TYPE } from "../../utilities/constants";

function* createVehicle(action: { type: string, payload: NewVehiclePayloadDto }) {
    try {
      const createNewVehicleResponse: AxiosResponse<string> = yield call(vehicleService.createVehicle, action.payload)
      
      yield put({
        type: VEHICLE_ACTION_TYPE.POST_VEHICLE + COMMON_ACTION_TYPES.SUCCESS,
        data: createNewVehicleResponse.data
      })
    } catch (error) {
      const setAlert: AlertDto = {
        message: error as string,
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'error'
        }
      }
      yield put(setAlert)
      yield put({
        type: VEHICLE_ACTION_TYPE.POST_VEHICLE + COMMON_ACTION_TYPES.ERROR,
        error: error
      })
    }
  }

function* editVehicle(action: { type: string, payload: EditVehicleParamDto }) {
    try {
      const editVehicleResponse: AxiosResponse<string> = yield call(vehicleService.editVehicle, action.payload)
      
      yield put({
        type: VEHICLE_ACTION_TYPE.EDIT_VEHICLE + COMMON_ACTION_TYPES.SUCCESS,
        data: editVehicleResponse.data
      })
    } catch (error) {
      const setAlert: AlertDto = {
        message: error as string,
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'error'
        }
      }
      yield put(setAlert)
      yield put({
        type: VEHICLE_ACTION_TYPE.EDIT_VEHICLE + COMMON_ACTION_TYPES.ERROR,
        error: error
      })
    }
  }

  function* addVehicleIncident(action: { type: string, payload: AddVehicleIncidentParamDto }) {
      try {
        const createNewVehicleIncidentResponse: AxiosResponse<string> = yield call(vehicleService.addVehicleIncident, action.payload)
        
        yield put({
          type: VEHICLE_ACTION_TYPE.POST_VEHICLE_INCIDENT + COMMON_ACTION_TYPES.SUCCESS,
          data: createNewVehicleIncidentResponse.data
        })
      } catch (error) {
        const setAlert: AlertDto = {
          message: error as string,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        yield put(setAlert)
        yield put({
          type: VEHICLE_ACTION_TYPE.POST_VEHICLE_INCIDENT + COMMON_ACTION_TYPES.ERROR,
          error: error
        })
      }
    }

function* deleteVehicle(action: { type: string, id: number }) {
    try {
      const deleteVehicleResponse: AxiosResponse<string> = yield call(vehicleService.deleteVehicle, action.id)
      const setAlert: AlertDto = {
        message: deleteVehicleResponse.data,
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'success'
        }
      }
      yield put(setAlert)
      yield put({
        type: VEHICLE_ACTION_TYPE.DELETE_VEHICLE + COMMON_ACTION_TYPES.SUCCESS,
        data: deleteVehicleResponse.data
      })
    } catch (error) {
      const setAlert: AlertDto = {
        message: error as string,
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: 'error'
        }
      }
      yield put(setAlert)
      yield put({
        type: VEHICLE_ACTION_TYPE.DELETE_VEHICLE + COMMON_ACTION_TYPES.ERROR,
        error: error
      })
    }
  }

function * getVehicleTypes (action: {type: string}) {
    try {
         const vehicleTypes: AxiosResponse<VehicleTypesDto[]> = yield call(vehicleService.getVehicleTypes)
         yield put({
             type: VEHICLE_ACTION_TYPE.GET_VEHICLE_TYPES + COMMON_ACTION_TYPES.SUCCESS,
             data: vehicleTypes.data
         })
    } catch (error) {
        yield put({
            type: VEHICLE_ACTION_TYPE.GET_VEHICLE_TYPES + COMMON_ACTION_TYPES.ERROR,
            error: error
        })
    }
}

function * getVehicles (action: {type: string, payload:getDetailsSummaryParam}) {
    try {
         const vehiclesResponse: AxiosResponse<VehicleDto[]> = yield call(vehicleService.getVehicles, action.payload)
         yield put({
             type: VEHICLE_ACTION_TYPE.GET_VEHICLE_LIST + COMMON_ACTION_TYPES.SUCCESS,
             data: vehiclesResponse.data
         })
    } catch (error) {
        yield put({
            type: VEHICLE_ACTION_TYPE.GET_VEHICLE_LIST + COMMON_ACTION_TYPES.ERROR,
            error: error
        })
    }
}

function * getVehicleIncidents (action: {type: string, id: number}) {
    try {
         const incidentsResponse: AxiosResponse<VehicleIncidentListDto[]> = yield call(vehicleService.getVehicleIncidents, action.id)
         yield put({
             type: VEHICLE_ACTION_TYPE.GET_VEHICLE_INCIDENTS + COMMON_ACTION_TYPES.SUCCESS,
             data: incidentsResponse.data
         })
    } catch (error) {
        yield put({
            type: VEHICLE_ACTION_TYPE.GET_VEHICLE_INCIDENTS + COMMON_ACTION_TYPES.ERROR,
            error: error
        })
    }
}

function * getPendingAllocationsFilters(action:{type:string}){
  try {
    const filterList: AxiosResponse<any[]> = yield call(vehicleService.getPendingAllocationsFilters)
    yield put({
      type: VEHICLE_ACTION_TYPE.GET_PENDING_VEHICLE_ALLOC_FILTERS + COMMON_ACTION_TYPES.SUCCESS,
      data: filterList.data
    })
  } catch (error) {
    yield put({
      type: VEHICLE_ACTION_TYPE.GET_PENDING_VEHICLE_ALLOC_FILTERS + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
} 

function * getPendingAllocations (action: {type: string, param: PendingAllocationParamDto}) {
    try {
         const vehicleAllocations: AxiosResponse<PendingVehicleAllocationsDto[]> = yield call(vehicleService.getPendingAllocations, action.param)
         yield put({
             type: VEHICLE_ACTION_TYPE.GET_PENDING_VEHICLE_ALLOCATIONS + COMMON_ACTION_TYPES.SUCCESS,
             data: vehicleAllocations.data
         })
    } catch (error) {
        yield put({
            type: VEHICLE_ACTION_TYPE.GET_PENDING_VEHICLE_ALLOCATIONS + COMMON_ACTION_TYPES.ERROR,
            error: error
        })
    }
}

function * getAllocationHistory(action:{ type: string, payload: any }){
  try {
      const vehicleAllocationHistoryResponse: AxiosResponse<allocationHistoryDto[]>= yield call(vehicleService.getAllocationHistory)
      yield put({
          type: VEHICLE_ACTION_TYPE.GET_VEHICLE_ALLOCATION_HISTORY + COMMON_ACTION_TYPES.SUCCESS,
          data: vehicleAllocationHistoryResponse.data
      })
  } catch (error) {
      yield put({
          type: VEHICLE_ACTION_TYPE.GET_VEHICLE_ALLOCATION_HISTORY + COMMON_ACTION_TYPES.ERROR,
          error: error
      })
      
  }
}

function * getAllocationRequisitions(action:{ type: string, payload: any }){
  try {
      const vehicleAllocationRequisitionsResponse: AxiosResponse<allocationRequisitionsDto[]>= yield call(vehicleService.getAllocationRequisitions,action.payload)
     
     
      yield put({
          type: VEHICLE_ACTION_TYPE.GET_VEHICLE_ALLOCATION_REQUISITIONS + COMMON_ACTION_TYPES.SUCCESS,
          data: vehicleAllocationRequisitionsResponse.data
      })
  } catch (error) {
      yield put({
          type: VEHICLE_ACTION_TYPE.GET_VEHICLE_ALLOCATION_REQUISITIONS + COMMON_ACTION_TYPES.ERROR,
          error: error
      })
      
  }
}

function *  cancelAllocation(action:{type:string,payload:cancelAllocationParm}){

  try {
      const cancelAllocationResponse: AxiosResponse<string>= yield call(vehicleService.cancelAllocation,action.payload)
      const setAlert: AlertDto = {
          message: cancelAllocationResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'success'
          }
        }
        yield put(setAlert)
      yield put({
          type: VEHICLE_ACTION_TYPE.PUT_CANCEL_ALLOCATION + COMMON_ACTION_TYPES.SUCCESS,
          data: cancelAllocationResponse.data
      })
      
  } catch (error) {
      const setAlert: AlertDto = {
          message: error as string,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
       yield put(setAlert)
       yield put({
          type: VEHICLE_ACTION_TYPE.PUT_CANCEL_ALLOCATION + COMMON_ACTION_TYPES.ERROR,
          error: error
      })
  }
}
function* vehicleSaga() {
    yield takeEvery(VEHICLE_ACTION_TYPE.POST_VEHICLE + COMMON_ACTION_TYPES.REQUEST, createVehicle)
    yield takeEvery(VEHICLE_ACTION_TYPE.EDIT_VEHICLE + COMMON_ACTION_TYPES.REQUEST, editVehicle)
    yield takeEvery(VEHICLE_ACTION_TYPE.POST_VEHICLE_INCIDENT + COMMON_ACTION_TYPES.REQUEST, addVehicleIncident)
    yield takeEvery(VEHICLE_ACTION_TYPE.DELETE_VEHICLE + COMMON_ACTION_TYPES.REQUEST, deleteVehicle)
    yield takeEvery(VEHICLE_ACTION_TYPE.GET_VEHICLE_TYPES + COMMON_ACTION_TYPES.REQUEST, getVehicleTypes)
    yield takeEvery(VEHICLE_ACTION_TYPE.GET_VEHICLE_LIST + COMMON_ACTION_TYPES.REQUEST, getVehicles)
    yield takeEvery(VEHICLE_ACTION_TYPE.GET_VEHICLE_INCIDENTS + COMMON_ACTION_TYPES.REQUEST, getVehicleIncidents)
    yield takeEvery(VEHICLE_ACTION_TYPE.GET_PENDING_VEHICLE_ALLOC_FILTERS + COMMON_ACTION_TYPES.REQUEST, getPendingAllocationsFilters)
    yield takeEvery(VEHICLE_ACTION_TYPE.GET_PENDING_VEHICLE_ALLOCATIONS + COMMON_ACTION_TYPES.REQUEST, getPendingAllocations)
    yield takeEvery(VEHICLE_ACTION_TYPE.GET_VEHICLE_ALLOCATION_HISTORY + COMMON_ACTION_TYPES.REQUEST, getAllocationHistory)
    yield takeEvery(VEHICLE_ACTION_TYPE.GET_VEHICLE_ALLOCATION_REQUISITIONS + COMMON_ACTION_TYPES.REQUEST, getAllocationRequisitions)
    yield takeEvery(VEHICLE_ACTION_TYPE.PUT_CANCEL_ALLOCATION + COMMON_ACTION_TYPES.REQUEST, cancelAllocation)
}

export default vehicleSaga