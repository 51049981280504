/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import style from './ManageDriverScreen.module.scss'
import { AppLayout } from '../../templates'
import { AssignVehicles, DriverInformations, IncidentInformations, WorkHistory, DriverDocuments } from '../../components/DriverManagement'
import { AlertDto, ApplicationStateDto, AssignVehicleFormDto, AssignVehicleListDto, DriverDetailsFormDt, DriverDocumentListDto, DriverIncidentFormDto, DriverIncidentListDto, DriverLicenseDto, DriverWorkHistoryFormDto, DriverWorkhistoryListDto, EditDriverDto, EditDriverVehicleDto, GetVehicleNumberParamDto, ModifyDriverDto, ModifyDriverIncidentDto, NewDriverAssignVehiclDto, NewDriverIncidentDto, NewDriverPayloadDto, NewDriverWorkHistoryDto, OptionsDto, SortMetaDto, FileUploadDrivertDto, DriverDocumentListDto1, DriverNICDto, DriverOtherDto, NewDocPayloadDto, GetRequiredDocumentParmDto, EditDriverDtoBody, EditDriverDtoParm } from '../../utilities/models'
import { ConfirmationDialog, CustomButton } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { transportActions } from '../../redux/actions/transport.action'
import { driverActions } from '../../redux/actions/driver.action'
import { validateFormData } from '../../utilities/helpers'
import dayjs, { Dayjs } from 'dayjs'
import { ALERT_ACTION_TYPES, APP_ACTION_STATUS, APP_ROUTES, APP_TABLE_CONFIGS, COMMON_ACTION_TYPES, DOCUMENT_TYPE, DRIVERDOCUMENT, DRIVER_DOCUMENT_NAME, DRIVER_SCREEN_MODES, ORGANIZATION_UNIT_TYPES, REQUEST_SCREEN_MODES } from '../../utilities/constants'
import { alertActions, linkActions, requestActions } from '../../redux/actions'

const ManageDriverScreen = () => {
  const DRIVER_INFORMATION_FORM_INITIAL_STATE: DriverDetailsFormDt = {
    driverName: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '', max: 30 },
    companyName: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: 'object', error: '' },
    plant: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: true, validator: 'object', error: '' },
    nic: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '', },
    nicDocument: { value: '', isRequired: false, disable: false, readonly: false, validator: 'text', error: '' },
    nicVersion:{ value: 0, isRequired: false, disable: false, readonly: false, validator: 'number', error: '' },
    nicIssuedDate: { value: '', isRequired: true, disable: true, readonly: false, validator: 'date', error: '' },
    licenceNumber: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '', },
    licenseDocument: { value: '', isRequired: false, disable: false, readonly: false, validator: 'text', error: '' },
    lnVersion:{ value: 0, isRequired: false, disable: false, readonly: false, validator: 'number', error: '' },
    lnIssuedDate: { value: '', isRequired: true, disable: true, readonly: false, validator: 'date', error: '' },
    lnExpiryDate: { value: '', isRequired: true, disable: true, readonly: false, validator: 'date', error: '' },
    contactNumber: { value: '', isRequired: true, disable: false, readonly: false, validator: 'number', error: '', charLength: [10] },
  }

  const ASSIGN_VEHICLE_FORM_INITIAL_STATE: AssignVehicleFormDto = {
    plant: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: true, validator: 'object', error: '' },
    vehicleType: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: 'object', error: '' },
    vehicleNumber: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: 'object', error: '' },
  }

  const DRIVER_INCIDENT_FORM_INITIAL_STATE: DriverIncidentFormDto = {
    incident: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '', max: 100 },
    date: { value: null, isRequired: true, disable: false, readonly: false, validator: 'date', error: '' },
    outcome: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '', max: 100 },
    vehicleNumber: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: 'object', error: '' },
  }

  const DRIVER_WORK_HISTORY_FORM_INITIAL_STATE: DriverWorkHistoryFormDto = {
    sbu: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: 'object', error: '' },
    plant: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: 'object', error: '' },
    startDate: { value: null, isRequired: true, disable: false, readonly: false, validator: 'date', error: '' },
    endDate: { value: null, isRequired: true, disable: false, readonly: false, validator: 'date', error: '' },
  }

  const DRIVER_LICENSE_DETAILS_INITIAL_STATE: DriverLicenseDto = {
    driverLicenseNo: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '', max: 9 },
    licenseFileName: { value: '', isRequired: false, disable: true, readonly: true, validator: 'text', error: '' },
    driverLicenseIssue: { value: '', isRequired: true, disable: false, readonly: false, validator: 'date', error: '' },
    driverLicenseExpiry: { value: '', isRequired: true, disable: false, readonly: false, validator: 'date', error: '' },
  }

  const DRIVER_NIC_DETAILS_INITIAL_STATE: DriverNICDto = {
    driverNICNo: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '', max: 15 },
    NICFileName: { value: '', isRequired: false, disable: true, readonly: true, validator: 'text', error: '' },
    driverNICIssue: { value: '', isRequired: true, disable: false, readonly: false, validator: 'date', error: '' },
    driverNICExpiry: { value: '', isRequired: false, disable: true, readonly: false, validator: 'date', error: '' },
  }

  const DRIVER_OTHER_DETAILS_INITIAL_STATE: DriverOtherDto = {
    driverOtherDoc: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '' },
    otherFileName: { value: '', isRequired: false, disable: true, readonly: true, validator: 'text', error: '' },
    otherVersion:{ value: 0, isRequired: false, disable: false, readonly: false, validator: 'number', error: '' },
    driverOtherIssue: { value: '', isRequired: false, disable: false, readonly: false, validator: 'date', error: '' },
    driverOtherExpiry: { value: '', isRequired: false, disable: false, readonly: false, validator: 'date', error: '' },
  }

  const INITIAL_SORT_META: SortMetaDto = {
    field: "",
    asc: false,
  }

  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [screenMode, setScreenMode] = useState("")
  const [driverId, setDriverId] = useState(-1)
  const [helperText, setHelperText] = useState(true)
  const [sortMeta, setSortMeta] = useState<SortMetaDto>(INITIAL_SORT_META);

  const [driverInformationForm, setDriverInformationForm] = useState(DRIVER_INFORMATION_FORM_INITIAL_STATE)

  const [historyPage, setHistoryPage] = useState(0)
  const [historyRowsPerPage, setHistoryRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)

  const [licenseForm, setLicenseForm] = useState(DRIVER_LICENSE_DETAILS_INITIAL_STATE);
  const [nicForm, setNICForm] = useState(DRIVER_NIC_DETAILS_INITIAL_STATE);
  const [otherForm, setOtherForm] = useState(DRIVER_OTHER_DETAILS_INITIAL_STATE);

  const [driverWorkHistoryForm, setDriverWorkHistoryForm] = useState(DRIVER_WORK_HISTORY_FORM_INITIAL_STATE)
  const [driverWorkHistoryList, setDriverWorkhistoryList] = useState<DriverWorkhistoryListDto[]>([])

  const [vehiclePage, setVehiclePage] = useState(0)
  const [vehicleRowsPerPage, setVehicleRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
  const [isEditVehicle, setIsEditVehicle] = useState(false)
  const [selectedVehicle, setSelectedVehicle] = useState(-1)
  const [selectedDoc, setSelectedDoc] = useState(-1)
  const [selectedHistory, setSelectedHistory] = useState(-1)
  const [isOpenVehicleRemovePopup, setIsOpenVehicleRemovePopup] = useState(false)
  const [isOpenDocumentRemovePopup, setIsOpenDocumentRemovePopup] = useState(false)
  const [isOpenHistoryRemovePopup, setIsOpenHistoryRemovePopup] = useState(false)
  const [assignVehicleForm, setAssignVehicleForm] = useState(ASSIGN_VEHICLE_FORM_INITIAL_STATE)
  const [assignVehicleList, setAssignVehicleList] = useState<AssignVehicleListDto[]>([])

  const [documentList, setDocumentList] = useState<DriverDocumentListDto1[]>([]);
  const [incidentPage, setIncidentPage] = useState(0)
  const [incidentRowsPerPage, setincidentRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
  const [driverIncidentForm, SerDriverIncidentForm] = useState(DRIVER_INCIDENT_FORM_INITIAL_STATE)
  const [driverIncidentList, setDriverIncidentList] = useState<DriverIncidentListDto[]>([])

  const allCompanies = useSelector((state: ApplicationStateDto) => state.transport.getAllCompanies.data)
  const allPlantList = useSelector((state: ApplicationStateDto) => state.driver.getPlants.data)
  const vehicleNumberByDriver = useSelector((state: ApplicationStateDto) => state.driver.getVehicleNumbersByDriver.data)
  const vehicleNumberByVehicleType = useSelector((state: ApplicationStateDto) => state.driver.getVehicleNumbersByType.data)
  const allSBUList = useSelector((state: ApplicationStateDto) => state.request.getSbuList.data)
  const plantListBysbu = useSelector((state: ApplicationStateDto) => state.request.getPlantListBySbuList.data)
  const vehicleTypesByPlant = useSelector((state: ApplicationStateDto) => state.driver.getVehicleTypesByPlant.data)
  const viewDriverResponse = useSelector((state: ApplicationStateDto) => state.driver.viewDriver)

  const createDriverResponse = useSelector((state: ApplicationStateDto) => state.driver.addDriver)
  const editDriverResponse = useSelector((state: ApplicationStateDto) => state.driver.editDriver)
  const modifyDriverVehicleResponse = useSelector((state: ApplicationStateDto) => state.driver.editDriverVehicle)
  const authorizedUser = useSelector((state: ApplicationStateDto) => state.authUser.authorizedUser);

  const [selectedLicenseFile, setSelectedLicenseFile] = useState<File | any>(null);
  const [selectedNICFile, setSelectedNICFile] = useState<File | any>(null);
  const [selectedOtherFile, setSelectedOtherFile] = useState<File | any>(null);

  const licenseDocumentResponse = useSelector((state: ApplicationStateDto) => state.link.uploadMainFile);
  const nicDocumentResponse = useSelector((state: ApplicationStateDto) => state.link.uploadSecondaryFile);
  const otherDocResponse = useSelector((state: ApplicationStateDto) => state.link.uploadOtherFile);

  const [nicVersion, setNicVersion] = useState<number>(0);
  const [lnVersion, setLnVersion] = useState<number>(0);
  const [otherVerion, setOtherVersion] = useState<number>(0);

  const setPDFAlert: AlertDto = {
    message: "Please select a PDF file",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const setFileAlert: AlertDto = {
    message: "Document is mandatory for Other Documents!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const setFolderAlert: AlertDto = {
    message: "Please enter the document name",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const setVehicleAlert: AlertDto = {
    message: "Please assign atleast one vehicle!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const driverRegError5: AlertDto = {
    message: "Mandatory fields not filled!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const alreadyExistsAlert: AlertDto = {
    message: "Document already exists!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: 'error'
    }
  }

  useEffect(() => {
    getInitialData()

    return () => {
      dispatch(driverActions.createDriverResponseClear())
      dispatch(driverActions.editDriverResponseClear())
      dispatch(driverActions.modifyDriverVehicleResponseClear())
      dispatch(driverActions.viewDriverClear())
      dispatch(linkActions.uploadMainFileClear())
      dispatch(linkActions.uploadSecondaryFileClear())
      dispatch(linkActions.uploadOtherFileClear())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (createDriverResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (createDriverResponse.data === "Driver's license already exists!") {
        const setAlert: AlertDto = {
          message: createDriverResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
      } else if (createDriverResponse.data === "NIC already exists!") {
        const setAlert: AlertDto = {
          message: createDriverResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
      } else if (createDriverResponse.data === "Vehicle already assigned for another Driver!") {
        const setAlert: AlertDto = {
          message: createDriverResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
      } else {
        const setAlert: AlertDto = {
          message: createDriverResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'success'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
        navigate(APP_ROUTES.DRIVER_MANAGEMENT)
        setAsInitialState()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDriverResponse.status])

  useEffect(() => {
    if (editDriverResponse.status === APP_ACTION_STATUS.SUCCESS && modifyDriverVehicleResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (editDriverResponse.data === "Already existing") {
        const setAlert: AlertDto = {
          message: editDriverResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
      } else if (editDriverResponse.data === "Already assigned") {
        const setAlert: AlertDto = {
          message: editDriverResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert));
      }
      else {
        const setAlert: AlertDto = {
          message: editDriverResponse.data,
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'success'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert))
        navigate(APP_ROUTES.DRIVER_MANAGEMENT)
        setAsInitialState()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDriverResponse.status, modifyDriverVehicleResponse.status])

  const getInitialData = async () => {
    const { mode, id } = location.state;
    setScreenMode(mode)
    if (mode !== DRIVER_SCREEN_MODES.CREATE) {
      setDriverId(id)
      dispatch(driverActions.viewDriver(id))
    }
    if (mode !== DRIVER_SCREEN_MODES.VIEW) {
      if (authorizedUser.data && authorizedUser.data !== undefined) {
        const _data = authorizedUser.data.hierarchy.find((d) => d.typeId === ORGANIZATION_UNIT_TYPES.PLANT)
        if (_data) {
          setDriverInformationForm((driverInformationForm) => ({
            ...driverInformationForm,
            plant: {
              ...driverInformationForm.plant,
              value: { label: _data?.name, value: _data?.id }
            }
          }))

          setAssignVehicleForm({
            ...assignVehicleForm,
            plant: {
              ...assignVehicleForm.plant,
              value: { label: _data?.name, value: _data?.id }
            }
          })
          dispatch(driverActions.getVehicleTypesByPlant(_data?.id))
        }
      }
    }
    dispatch(requestActions.getSbuList())
    dispatch(transportActions.getAllTransportCompanies())
    dispatch(driverActions.getPlantList())
  }

  useEffect(() => {
    if (viewDriverResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (viewDriverResponse.data) {
        const _data = viewDriverResponse.data
       
        // setOtherVersion(_data.driverDocument[0].version)

        dispatch(driverActions.getVehicleNumbersByDriver(_data.nic))
        if (_data) {
          setDriverInformationForm({
            ...driverInformationForm,
            driverName: {
              ...driverInformationForm.driverName,
              value: _data.diverName,
              readonly: screenMode === DRIVER_SCREEN_MODES.VIEW
            },
            companyName: {
              ...driverInformationForm.companyName,
              value: { label: _data.transCompany, value: _data.companyId } as OptionsDto,
              readonly: screenMode === DRIVER_SCREEN_MODES.VIEW
            },
            plant: {
              ...driverInformationForm.plant,
              value: { label: _data.organizationName, value: _data?.organizationUnitId }
            },
            contactNumber: {
              ...driverInformationForm.contactNumber,
              value: _data.phone,
              readonly: screenMode === DRIVER_SCREEN_MODES.VIEW
            },
            licenceNumber: {
              ...driverInformationForm.licenceNumber,
              value: _data.licenseNo
            },
            licenseDocument:{
              ...driverInformationForm.licenseDocument,
              value:_data.licenseDocument
            },
            lnVersion:{
              ...driverInformationForm.lnVersion,
              value:_data.lnVersion
            },
            lnIssuedDate: {
              ...driverInformationForm.lnIssuedDate,
              value: _data.lnIssuedDate
            },
            lnExpiryDate: {
              ...driverInformationForm.lnExpiryDate,
              value: _data.lnExpiryDate
            },
            nic: {
              ...driverInformationForm.nic,
              value: _data.nic
            },
            nicIssuedDate: {
              ...driverInformationForm.nicIssuedDate,
              value: _data.nicIssuedDate
            },
            nicDocument:{
              ...driverInformationForm.nicDocument,
              value:_data.nicDocument
            },
            nicVersion:{
              ...driverInformationForm.nicVersion,
              value:_data.nicVersion
            }
          })

          setAssignVehicleList(_data.viewVehicleNo.map((v, index) => {
            const _vehicle: AssignVehicleListDto = {
              id: index + 1,
              isActive: v.isActive,
              plant: v.plant,
              plantId: -1,
              vehicleNumber: v.vehicleNumber,
              vehicleNumberId: v.id,
              vehicleType: v.vehicleType,
              vehicleTypeId: -1,
              isNew: false
            }

            return _vehicle
          }))

          setDriverIncidentList(_data.viewDriverIncidents.map((i, index) => {
            const _incident: DriverIncidentListDto = {
              id: index + 1,
              isNew: false,
              incident: i.incident,
              outcome: i.outCome,
              vehicleNumber: i.vehicleNo,
              date: dayjs(i.dateTime).format('YYYY-MM-DD'),
              vehicleNumberId: -1
            }

            return _incident
          }))

          if (_data.driverDocument) {
            const _id = documentList.length === 0 ? 1 : documentList[documentList.length - 1].id + 1;

            const mainDoc: DriverDocumentListDto1 = {
              id: _id,
              documentId: DRIVERDOCUMENT.LICENSE_DOCUMENT,
              category: DRIVER_DOCUMENT_NAME.LICENSE_DOCUMENT_NAME,
              documentName: _data.licenseDocument || "",
              version: _data.lnVersion,
              issuedDate: _data.lnIssuedDate ? _data.lnIssuedDate.split('T')[0] : '',
              expiryDate: _data.lnExpiryDate ? _data.lnExpiryDate.split('T')[0] : '',
            };

            const secondaryDoc: DriverDocumentListDto1 = {
              id: _id + 1,
              documentId: DRIVERDOCUMENT.NIC_DOCUMENT,
              category: DRIVER_DOCUMENT_NAME.NIC_DOCUMENT,
              documentName: _data.nicDocument || "",
              version: _data.nicVersion,
              issuedDate: _data.nicIssuedDate ? _data.nicIssuedDate.split('T')[0] : '',
              expiryDate: '',
            };

            const otherDocuments = _data.driverDocument.map((i, index) => {
              const otherDocId = _id + 2 + index;
              return {
                id: otherDocId,
                documentId: DRIVERDOCUMENT.OTHER_DOCUMENT,
                category: (i.documentName.match(/\/([^/]+)\/\d+/) || [])[1] || "",
                documentName: i.documentName || "",
                version: i.version,
                issuedDate: i.issuedDate ? i.issuedDate.split('T')[0] : '',
                expiryDate: i.expiryDate ? i.expiryDate.split('T')[0] : '',
              };
            });

            const updatedDocumentList = [...documentList, mainDoc, secondaryDoc, ...otherDocuments];
            setDocumentList(updatedDocumentList);
          }

          setDriverWorkhistoryList(_data.viewDriverHistory.map((w, index) => {
            const _history: DriverWorkhistoryListDto = {
              id: index + 1,
              plant: w.plant,
              plantId: -1,
              sbu: w.sbu,
              sbuId: -1,
              from: dayjs(w.fromDate).format('YYYY-MM-DD'),
              to: dayjs(w.toDate).format('YYYY-MM-DD'),
            }

            return _history
          }))
        }

      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewDriverResponse.status])

  const handleInputFocus = (property: string, section: string) => {
    if (section === 'driver-information')
      setDriverInformationForm({
        ...driverInformationForm,
        [property]: {
          ...driverInformationForm[property as keyof typeof driverInformationForm],
          error: null
        }
      })
    if (section === 'assign-vehicle')
      setAssignVehicleForm({
        ...assignVehicleForm,
        [property]: {
          ...assignVehicleForm[property as keyof typeof assignVehicleForm],
          error: null
        }
      })
    if (section === 'incident-information')
      SerDriverIncidentForm({
        ...driverIncidentForm,
        [property]: {
          ...driverIncidentForm[property as keyof typeof driverIncidentForm],
          error: null
        }
      })
    if (section === 'work-history')
      setDriverWorkHistoryForm({
        ...driverWorkHistoryForm,
        [property]: {
          ...driverWorkHistoryForm[property as keyof typeof driverWorkHistoryForm],
          error: null
        }
      })
    if (section === 'license')
      setLicenseForm({
        ...licenseForm,
        [property]: {
          ...licenseForm[property as keyof typeof licenseForm],
          error: null
        }
      })
    if (section === 'nic')
      setNICForm({
        ...nicForm,
        [property]: {
          ...nicForm[property as keyof typeof nicForm],
          error: null
        }
      })
    if (section === 'other')
      setOtherForm({
        ...otherForm,
        [property]: {
          ...otherForm[property as keyof typeof otherForm],
          error: null
        }
      })
  }

  const onInputHandleChangeDriverInformation = (property: string, value: any) => {
    setHelperText(true)
    // if (property === 'nic') {
    //   setDriverInformationForm({
    //     ...driverInformationForm,
    //     nic: {
    //       ...driverInformationForm.nic,
    //       value: value
    //     }
    //   })
    //   dispatch(driverActions.getVehicleNumbersByDriver(value))
    // }
    if (property === 'driverName') {
      setDriverInformationForm({
        ...driverInformationForm,
        driverName: {
          ...driverInformationForm.driverName,
          value: value
        }
      })
    }
    if (property === 'companyName') {
      setDriverInformationForm({
        ...driverInformationForm,
        companyName: {
          ...driverInformationForm.companyName,
          value: value
        }
      })
    }

    if (property === 'contactNumber') {
      setDriverInformationForm({
        ...driverInformationForm,
        contactNumber: {
          ...driverInformationForm.contactNumber,
          value: value
        }
      })
    }
  }

  // ASSIGN VEHICLE SECTION HANDLE FUNCTIONS
  const onInputHandleChangeAssignDriver = (property: string, value: any) => {
    setHelperText(true)
    if (property === 'plant') {
      setAssignVehicleForm({
        ...assignVehicleForm,
        plant: {
          ...assignVehicleForm.plant,
          value: value
        },
        vehicleType: {
          ...assignVehicleForm.vehicleType,
          value: {} as OptionsDto
        },
        vehicleNumber: {
          ...assignVehicleForm.vehicleNumber,
          value: {} as OptionsDto
        }
      })
      dispatch(driverActions.getVehicleTypesByPlant(value.value))
    }
    if (property === 'vehicleType') {
      setAssignVehicleForm({
        ...assignVehicleForm,
        vehicleType: {
          ...assignVehicleForm.vehicleType,
          value: value
        },
        vehicleNumber: {
          ...assignVehicleForm.vehicleNumber,
          value: {} as OptionsDto
        }
      })
      const _param: GetVehicleNumberParamDto = {
        plantId: Number(assignVehicleForm.plant.value.value),
        vehicleTypeId: value.value
      }
      dispatch(driverActions.getVehicleNumbersByType(_param))
    }
    if (property === 'vehicleNumber') {
      setAssignVehicleForm({
        ...assignVehicleForm,
        vehicleNumber: {
          ...assignVehicleForm.vehicleNumber,
          value: value
        }
      })
    }
  }

  const onAssignVehicle = async () => {
    const [validateData, isValid] = await validateFormData(assignVehicleForm)
    setAssignVehicleForm(validateData)
    if (isValid) {
      const _isTHere = assignVehicleList.find((v) => v.vehicleNumber === assignVehicleForm.vehicleNumber.value.label)
      if (!_isTHere) {
        const _isActive = assignVehicleList.length === 0
        const _obj: AssignVehicleListDto = {
          id: 1,
          isActive: _isActive,
          plant: assignVehicleForm.plant.value.label,
          plantId: Number(assignVehicleForm.plant.value.value),
          vehicleNumber: assignVehicleForm.vehicleNumber.value.label,
          vehicleNumberId: Number(assignVehicleForm.vehicleNumber.value.value),
          vehicleType: assignVehicleForm.vehicleType.value.label,
          vehicleTypeId: Number(assignVehicleForm.vehicleType.value.value),
          isNew: true
        }
        const _old = assignVehicleList.map((v) => {
          return {
            ...v,
            id: v.id + 1,
          }
        })
        const _list = [_obj, ..._old]
        setAssignVehicleList(_list)
        setAssignVehicleForm({
          ...ASSIGN_VEHICLE_FORM_INITIAL_STATE,
          plant: {
            ...ASSIGN_VEHICLE_FORM_INITIAL_STATE.plant,
            value: assignVehicleForm.plant.value
          }
        })
      } else {
        const setAlert: AlertDto = {
          message: "Vehicle already assigned.!",
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: 'error'
          }
        }
        dispatch(alertActions.triggerAlert(setAlert))
      }
    }
  }

  const onEditVehicle = async () => {
    const [validateData, isValid] = await validateFormData(assignVehicleForm)
    setAssignVehicleForm(validateData)
    if (isValid) {
      setAssignVehicleList(assignVehicleList.map((v) => {
        if (v.id === selectedVehicle) {
          return {
            ...v,
            plant: assignVehicleForm.plant.value.label,
            plantId: Number(assignVehicleForm.plant.value.value),
            vehicleNumber: assignVehicleForm.vehicleNumber.value.label,
            vehicleNumberId: Number(assignVehicleForm.vehicleNumber.value.value),
            vehicleType: assignVehicleForm.vehicleType.value.label,
            vehicleTypeId: Number(assignVehicleForm.vehicleType.value.value)
          }
        }
        return v
      }))
      setAssignVehicleForm({
        ...ASSIGN_VEHICLE_FORM_INITIAL_STATE,
        plant: {
          ...ASSIGN_VEHICLE_FORM_INITIAL_STATE.plant,
          value: assignVehicleForm.plant.value
        }
      })
      setIsEditVehicle(false)
    }
  }

  const onSelectVehicle = (id: number, action: string) => {
    setSelectedVehicle(id)
    if (action === 'edit') {
      setIsEditVehicle(true)
      onSelectAssignVehicleForEdit(id)
    }
    else setIsOpenVehicleRemovePopup(true)
  }

  const onSelectAssignVehicleForEdit = (id: number) => {
    const _vehicle = assignVehicleList.find((v) => v.id === id)
    if (_vehicle)
      setAssignVehicleForm({
        ...assignVehicleForm,
        plant: {
          ...assignVehicleForm.plant,
          value: { label: _vehicle.plant, value: _vehicle.plantId }
        },
        vehicleType: {
          ...assignVehicleForm.vehicleType,
          value: { label: _vehicle.vehicleType, value: _vehicle.vehicleTypeId }
        },
        vehicleNumber: {
          ...assignVehicleForm.vehicleNumber,
          value: { label: _vehicle.vehicleNumber, value: _vehicle.vehicleNumberId }
        }
      })
  }

  const onRemoveAssignVehicle = (con: boolean) => {
    if (con) {
      setAssignVehicleList(assignVehicleList.filter((p) => p.id !== selectedVehicle).map((v, index) => {
        return {
          ...v,
          id: index + 1
        }
      }))
      setSelectedVehicle(-1)
    }
    setIsOpenVehicleRemovePopup(false)
  }

  const onSetActiveVehicle = (id: number) => {
    setAssignVehicleList(assignVehicleList.map((v) => {
      return {
        ...v,
        isActive: v.id === id ? true : v.isActive ? false : v.isActive
      }
    }))
  }

  const onSelectDocument = (id: number, action: string) => {
    setSelectedDoc(id)
    if (action === 'delete') {
      setIsOpenDocumentRemovePopup(true)
    }
    if (action === "download" || action === 'view') {
      const foundItem = documentList.find((item) => item.id === id);

      if (foundItem) {
        if (action === 'download') {
          const _downloadPayload: GetRequiredDocumentParmDto = {
            fileName: foundItem.documentName,
            mainDocumentType: DOCUMENT_TYPE.DTIVER_DOCUMENTS,
          }
          dispatch(linkActions.downloadRequiredFile(_downloadPayload))
        }
        if (action === 'view') {
          const _downloadPayload: GetRequiredDocumentParmDto = {
            fileName: foundItem.documentName,
            mainDocumentType: DOCUMENT_TYPE.DTIVER_DOCUMENTS,
          }
          dispatch(linkActions.downloadOpenFile(_downloadPayload))
        }
      }
    }
  }

  const onRemoveDriverDocument = (con: boolean) => {
    if (con) {
      const documentId = documentList.filter((d) => d.id === selectedDoc)
      if (documentId[0].documentId === DRIVERDOCUMENT.LICENSE_DOCUMENT) {
        setDriverInformationForm({
          ...driverInformationForm,
          licenceNumber: {
            ...driverInformationForm.licenceNumber,
            value: ""
          },
          licenseDocument: {
            ...driverInformationForm.licenseDocument,
            value: ""
          },
          lnExpiryDate: {
            ...driverInformationForm.lnExpiryDate,
            value: ""
          },
          lnIssuedDate: {
            ...driverInformationForm.lnIssuedDate,
            value: ""
          },
        })
      }

      if (documentId[0].documentId === DRIVERDOCUMENT.NIC_DOCUMENT) {
        setDriverInformationForm({
          ...driverInformationForm,
          nic: {
            ...driverInformationForm.nic,
            value: ""
          },
          nicIssuedDate: {
            ...driverInformationForm.nicIssuedDate,
            value: ""
          },
          nicDocument: {
            ...driverInformationForm.nicDocument,
            value: ""
          }
        })
      }

      setDocumentList(documentList.filter((d) => d.id !== selectedDoc))
      setSelectedDoc(-1)
    }
    setIsOpenDocumentRemovePopup(false)
  }

  const onSelectWorkHistory = (id: number, action: string) => {
    setSelectedHistory(id)
    if (action === "delete") {
      setIsOpenHistoryRemovePopup(true)
    }
  }

  const onRemoveDriverhistory = (con: boolean) => {
    if (con) {
      setDriverWorkhistoryList(driverWorkHistoryList.filter((d) => d.id !== selectedHistory).map((v, index) => {
        return {
          ...v,
          id: index + 1
        }
      }))
      setSelectedHistory(-1)
    }
    setIsOpenHistoryRemovePopup(false)
  }

  const onClearAssignVehicleForm = () => {
    setAssignVehicleForm({
      ...assignVehicleForm,
      ...ASSIGN_VEHICLE_FORM_INITIAL_STATE,
      plant: assignVehicleForm.plant,
      vehicleNumber: ASSIGN_VEHICLE_FORM_INITIAL_STATE.vehicleNumber,
      vehicleType: ASSIGN_VEHICLE_FORM_INITIAL_STATE.vehicleType
    })
    // setAssignVehicleForm({
    //   ...assignVehicleForm,
    // plant:assignVehicleForm.plant,
    // })
    setIsEditVehicle(false)
  }

  // ADD INCIDENT INFORMATION SECTION FUNCTIONALITIES
  const onInputHandleChangeDriverIncident = (property: string, value: any) => {
    setHelperText(true)
    if (property === 'incident') {
      SerDriverIncidentForm({
        ...driverIncidentForm,
        incident: {
          ...driverIncidentForm.incident,
          value: value
        }
      })
    }
    if (property === 'date') {
      SerDriverIncidentForm({
        ...driverIncidentForm,
        date: {
          ...driverIncidentForm.date,
          value: value
        }
      })
    }
    if (property === 'outcome') {
      SerDriverIncidentForm({
        ...driverIncidentForm,
        outcome: {
          ...driverIncidentForm.outcome,
          value: value
        }
      })
    }
    if (property === 'vehicleNumber') {
      SerDriverIncidentForm({
        ...driverIncidentForm,
        vehicleNumber: {
          ...driverIncidentForm.vehicleNumber,
          value: value
        }
      })
    }
  }

  const onAddIncident = async () => {
    const [validateData, isValid] = await validateFormData(driverIncidentForm)
    SerDriverIncidentForm(validateData)
    if (isValid) {
      const _obj: DriverIncidentListDto = {
        id: 1,
        isNew: true,
        incident: driverIncidentForm.incident.value,
        date: dayjs(driverIncidentForm.date.value as Dayjs).format('YYYY-MM-DD'),
        outcome: driverIncidentForm.outcome.value,
        vehicleNumber: driverIncidentForm.vehicleNumber.value.label,
        vehicleNumberId: Number(driverIncidentForm.vehicleNumber.value.value)
      }
      const _old = driverIncidentList.map((v) => {
        return {
          ...v,
          id: v.id + 1
        }
      })
      const _list = [_obj, ..._old]
      setDriverIncidentList(_list)
      SerDriverIncidentForm(DRIVER_INCIDENT_FORM_INITIAL_STATE)
    }
  }

  const onClearAddIncidentForm = () => {
    SerDriverIncidentForm(DRIVER_INCIDENT_FORM_INITIAL_STATE)
  }

  // ADD WORK HISTORY SECTION FUNCTIONALITIES
  const onInputHandleChangeDriverhistory = (property: string, value: any) => {
    setHelperText(true)
    setDriverWorkHistoryForm({
      ...driverWorkHistoryForm,
      [property]: {
        ...driverWorkHistoryForm[property as keyof typeof driverWorkHistoryForm],
        value: value
      }
    })
    if (property === 'sbu') {
      dispatch(requestActions.getPlantListBySbuId(value.value))
    }
  }

  const onAddWorkHistory = async () => {
    const [validateData, isValid] = await validateFormData(driverWorkHistoryForm)
    setDriverWorkHistoryForm(validateData)
    if (isValid) {
      const _obj: DriverWorkhistoryListDto = {
        id: 1,
        plant: driverWorkHistoryForm.plant.value.label,
        plantId: Number(driverWorkHistoryForm.plant.value.value),
        sbu: driverWorkHistoryForm.sbu.value.label,
        sbuId: Number(driverWorkHistoryForm.sbu.value.value),
        from: dayjs(driverWorkHistoryForm.startDate.value as Dayjs).format('YYYY-MM-DD'),
        to: dayjs(driverWorkHistoryForm.endDate.value as Dayjs).format('YYYY-MM-DD'),
      }
      const _old = driverWorkHistoryList.map((v) => {
        return {
          ...v,
          id: v.id + 1
        }
      })
      const _list = [_obj, ..._old]
      setDriverWorkhistoryList(_list)
      setDriverWorkHistoryForm(DRIVER_WORK_HISTORY_FORM_INITIAL_STATE)
    }
  }

  const onClearWorkHistoryForm = () => {
    setDriverWorkHistoryForm(DRIVER_WORK_HISTORY_FORM_INITIAL_STATE)
  }

  // CREATE DRIVER
  const onCreateDriver = async () => {
    const [validateData, isValid] = await validateFormData(driverInformationForm)
    setDriverInformationForm(validateData)

    console.log("CHECKING", validateData)
    if (isValid) {
      const conditionToRemove = (item: any) => {
        return (
          item.category === DRIVER_DOCUMENT_NAME.LICENSE_DOCUMENT_NAME ||
          item.category === DRIVER_DOCUMENT_NAME.NIC_DOCUMENT
        );
      };

      const _docList = documentList
        .filter((d) => !conditionToRemove(d))
        .map((d) => {
          const _doc: NewDocPayloadDto = {
            documentId: d.documentId,
            documentName: d.documentName,
            version: d.version,
            issuedDate: d.issuedDate ? dayjs(d.issuedDate).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ") : null,
            expiryDate: d.expiryDate ? dayjs(d.expiryDate).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ") : null,
          };
          return _doc;
        });

      const _historyList = driverWorkHistoryList.map((h) => {
        const _item: NewDriverWorkHistoryDto = {
          sbu: h.sbu,
          plant: h.plant,
          fromDate: dayjs(h.from).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ"),
          toDate: dayjs(h.to).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ"),
        }
        return _item
      })

      const _incidentList = driverIncidentList.map((i) => {
        const _item: NewDriverIncidentDto = {
          incident: i.incident,
          outCome: i.outcome,
          dateTime: dayjs(i.date).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ"),
          vehicleNo: i.vehicleNumber
        }
        return _item
      })

      const _vehicleList = assignVehicleList.map((v) => {
        const _item: NewDriverAssignVehiclDto = {
          id: v.vehicleNumberId,
          isActive: v.isActive,
          vehicleNumber: v.vehicleNumber
        }
        return _item
      })

      if (_vehicleList.length < 1) {
        dispatch(alertActions.triggerAlert(setVehicleAlert))
      } else {
        const _payload: NewDriverPayloadDto = {
          diverName: driverInformationForm.driverName.value,
          phone: driverInformationForm.contactNumber.value,
          nic: driverInformationForm.nic.value,
          nicDocument: driverInformationForm.nicDocument.value,
          nicVersion:driverInformationForm.nicVersion.value,
          nicIssuedDate: dayjs(driverInformationForm.nicIssuedDate.value).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ"),
          licenseNo: driverInformationForm.licenceNumber.value,
          licenseDocument: driverInformationForm.licenseDocument.value,
          lnVersion:driverInformationForm.lnVersion.value,
          lnIssuedDate: dayjs(driverInformationForm.lnIssuedDate.value).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ"),
          lnExpiryDate: dayjs(driverInformationForm.lnExpiryDate.value).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ"),
          transCompanyId: Number(driverInformationForm.companyName.value.value),
          organizationUnitId: Number(driverInformationForm.plant.value.value),
          vehicleNo: _vehicleList,
          driverIncidents: _incidentList,
          driverHistory: _historyList,
          document: _docList,
        }
        dispatch(driverActions.createDriver(_payload))
      }
    } else {
      dispatch(alertActions.triggerAlert(driverRegError5))
    }
  }

  // EDIT DRIVER
  const onEditDriver = async () => {
    const [validateData, isValid] = await validateFormData(driverInformationForm)
    setDriverInformationForm(validateData)

    console.log("CHECKING", validateData);
    if (isValid) {
      const _incidentList: ModifyDriverIncidentDto[] = []
      driverIncidentList.forEach((i) => {
        const _item: ModifyDriverIncidentDto = {
          incident: i.incident,
          outCome: i.outcome,
          dateTime: dayjs(i.date).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ"),
          vehicleNo: i.vehicleNumber,
          vehicleId: i.vehicleNumberId
        }
        if (i.isNew) {
          _incidentList.push(_item)
        }
      })

      let _newVehicleList: EditDriverVehicleDto[] = []
      let _oldVehicleList: EditDriverVehicleDto[] = []
      
      assignVehicleList.forEach((v) => {
        const _item: EditDriverVehicleDto = {
          id: v.vehicleNumberId,
          isActive: v.isActive,
          vehicleNumber: v.vehicleNumber
        }
        if (v.isNew) {
          _newVehicleList.push(_item)
        } else {
          _oldVehicleList.push(_item)
        }
      })

      const _postPayload: ModifyDriverDto = {
        driverId: driverId,
        vehiclesNos: _newVehicleList,
        driversIncident: _incidentList
      }

      const conditionToRemove = (item: any) => {
        return (
          item.category === DRIVER_DOCUMENT_NAME.LICENSE_DOCUMENT_NAME ||
          item.category === DRIVER_DOCUMENT_NAME.NIC_DOCUMENT
        );
      };

      const _docList = documentList
        .filter((d) => !conditionToRemove(d))
        .map((d) => {
          const _doc = {
            documentId: d.documentId,
            documentName: d.documentName,
            version:d.version,
            issuedDate: d.issuedDate ? dayjs(d.issuedDate).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ") : null,
            expiryDate: d.expiryDate ? dayjs(d.expiryDate).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ") : null,
          };
          return _doc;
        });

      const EditDriverDtoBodys: EditDriverDtoBody = {
        diverName: driverInformationForm.driverName.value,
        transCompanyId: Number(driverInformationForm.companyName.value.value),
        phone: driverInformationForm.contactNumber.value,
        licenseNo: driverInformationForm.licenceNumber.value,
        licenseDocument: driverInformationForm.licenseDocument.value,
        lnVersion: driverInformationForm.lnVersion.value,
        lnIssuedDate: dayjs(driverInformationForm.lnIssuedDate.value).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ"),
        lnExpiryDate: dayjs(driverInformationForm.lnExpiryDate.value).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ"),
        nic: driverInformationForm.nic.value,
        nicDocument: driverInformationForm.nicDocument.value,
        nicVersion: driverInformationForm.nicVersion.value,
        nicIssuedDate: dayjs(driverInformationForm.nicIssuedDate.value).add(330, 'minute').format("YYYY-MM-DDTHH:mmZ"),
        vehicleNo: _oldVehicleList,
        driverDoc: _docList,
      }
      const EditDriverDtoParms: EditDriverDtoParm = {
        driverId: driverId,
      }
      const _putPayload: EditDriverDto = {
        EditDriverDtoBody: EditDriverDtoBodys,
        EditDriverDtoParm: EditDriverDtoParms
      }

      dispatch(driverActions.editDriver(_putPayload))
      dispatch(driverActions.modifyDriverVehicle(_postPayload))

    } else {
      dispatch(alertActions.triggerAlert(driverRegError5))
    }
  }

  const setAsInitialState = () => {
    setDriverWorkhistoryList([])
    setDriverIncidentList([])
    setAssignVehicleList([])

    setAssignVehicleForm({
      ...ASSIGN_VEHICLE_FORM_INITIAL_STATE,
      plant: assignVehicleForm.plant,
    })
    SerDriverIncidentForm(DRIVER_INCIDENT_FORM_INITIAL_STATE)
    setDriverWorkHistoryForm(DRIVER_WORK_HISTORY_FORM_INITIAL_STATE)
    setDriverInformationForm({
      ...DRIVER_INFORMATION_FORM_INITIAL_STATE,
      plant: driverInformationForm.plant,
    })
    setSelectedLicenseFile(null);
    setSelectedNICFile(null);
    setSelectedOtherFile(null);
    setDocumentList([])
    setLicenseForm(DRIVER_LICENSE_DETAILS_INITIAL_STATE)
    setNICForm(DRIVER_NIC_DETAILS_INITIAL_STATE)
    setOtherForm(DRIVER_OTHER_DETAILS_INITIAL_STATE)
  }

  const onNavigate = () => {
    navigate(APP_ROUTES.DRIVER_MANAGEMENT)
    setAsInitialState()
  }

  const onWorkHistoryListSortHandle = (col: string) => {
    const sorted = driverWorkHistoryList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setDriverWorkhistoryList(sorted)
  };

  const onIncidentListSortHandle = (col: string) => {
    const sorted = driverIncidentList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setDriverIncidentList(sorted)
  };

  const onAssignVehicleListSortHandle = (col: string) => {
    const sorted = assignVehicleList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setAssignVehicleList(sorted)
  };

  // HANDLE TABLE PAGINATIONS
  const handleChangeVehiclePage = (event: unknown, newPage: number) => {
    setVehiclePage(newPage)
  }

  const handleChangeVehicleRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVehicleRowsPerPage(+event.target.value)
    setVehiclePage(0)
  }

  const handleChangeIncidentPage = (event: unknown, newPage: number) => {
    setIncidentPage(newPage)
  }

  const handleChangeIncidentRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setincidentRowsPerPage(+event.target.value)
    setIncidentPage(0)
  }

  const handleChangeHistoryPage = (event: unknown, newPage: number) => {
    setHistoryPage(newPage)
  }

  const handleChangeHistoryRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHistoryRowsPerPage(+event.target.value)
    setHistoryPage(0)
  }

  const onInputHandleChange = (property: string, value: any) => {
    setHelperText(true)
    if (property === "driverLicenseNo") {
      setLicenseForm(({
        ...licenseForm,
        driverLicenseNo: {
          ...licenseForm.driverLicenseNo,
          value: value
        }
      }))

      setDriverInformationForm(({
        ...driverInformationForm,
        licenceNumber: {
          ...driverInformationForm.licenceNumber,
          value: value
        }
      }))
    }

    if (property === "licenseIssue") {
      setLicenseForm(({
        ...licenseForm,
        driverLicenseIssue: {
          ...licenseForm.driverLicenseIssue,
          value: value,
          error:null
        }
      }))
      setDriverInformationForm(({
        ...driverInformationForm,
        lnIssuedDate: {
          ...driverInformationForm.lnIssuedDate,
          value: value
        }
      }))
    }
    if (property === "licenseExpiry") {
      setLicenseForm(({
        ...licenseForm,
        driverLicenseExpiry: {
          ...licenseForm.driverLicenseExpiry,
          value: value,
          error:null
        }
      }))
      setDriverInformationForm(({
        ...driverInformationForm,
        lnExpiryDate: {
          ...driverInformationForm.lnExpiryDate,
          value: value
        }
      }))
    }
    if (property === "driverNICNo") {
      setNICForm(({
        ...nicForm,
        driverNICNo: {
          ...nicForm.driverNICNo,
          value: value
        }
      }))
      setDriverInformationForm(({
        ...driverInformationForm,
        nic: {
          ...driverInformationForm.nic,
          value: value
        }
      }))
      dispatch(driverActions.getVehicleNumbersByDriver(value))
    }
    if (property === "nicIssue") {
      setNICForm(({
        ...nicForm,
        driverNICIssue: {
          ...nicForm.driverNICIssue,
          value: value,
          error:null
        }
      }))
      setDriverInformationForm(({
        ...driverInformationForm,
        nicIssuedDate: {
          ...driverInformationForm.nicIssuedDate,
          value: value
        }
      }))
    }
    if (property === "driverOtherDoc") {
      setOtherForm(({
        ...otherForm,
        driverOtherDoc: {
          ...otherForm.driverOtherDoc,
          value: value
        }
      }))
    }
    if (property === "otherIssue") {
      setOtherForm(({
        ...otherForm,
        driverOtherIssue: {
          ...otherForm.driverOtherIssue,
          value: value
        }
      }))
    }
    if (property === "otherExpiry") {
      setOtherForm(({
        ...otherForm,
        driverOtherExpiry: {
          ...otherForm.driverOtherExpiry,
          value: value
        }
      }))
    }
  }

  const onAddDocuments = async (documentId: number) => {
    const _id = documentList.length === 0 ? 1 : documentList[documentList.length - 1].id + 1

    if (documentId === DRIVERDOCUMENT.LICENSE_DOCUMENT) {
      const [validateData, isValid] = await validateFormData(licenseForm)
      setLicenseForm(validateData)

      const _isTHere = documentList.find((v) => v.documentId === documentId);
      if (isValid && _isTHere === undefined) {
        const _document: DriverDocumentListDto1 = {
          id: _id,
          documentId: documentId,
          category: DRIVER_DOCUMENT_NAME.LICENSE_DOCUMENT_NAME,
          documentName: licenseForm.licenseFileName.value,
          version: driverInformationForm.lnVersion.value,
          issuedDate: dayjs(licenseForm.driverLicenseIssue.value as Dayjs).format('YYYY-MM-DD'),
          expiryDate: dayjs(licenseForm.driverLicenseExpiry.value as Dayjs).format('YYYY-MM-DD')
        }
        const _list = [...documentList, _document]
        setDocumentList(_list)
        setLicenseForm(DRIVER_LICENSE_DETAILS_INITIAL_STATE)
      } 
      
      if(_isTHere !== undefined){
        dispatch(alertActions.triggerAlert(alreadyExistsAlert));
      }
    }

    if (documentId === DRIVERDOCUMENT.NIC_DOCUMENT) {
      const [validateData, isValid] = await validateFormData(nicForm)
      setNICForm(validateData)

      const _isTHere = documentList.find((v) => v.documentId === documentId);
      if (isValid && _isTHere === undefined) {
        const _document: DriverDocumentListDto1 = {
          id: _id,
          documentId: documentId,
          category: DRIVER_DOCUMENT_NAME.NIC_DOCUMENT,
          documentName: nicForm.NICFileName.value,
          version: driverInformationForm.nicVersion.value,
          issuedDate: dayjs(nicForm.driverNICIssue.value as Dayjs).format('YYYY-MM-DD'),
          expiryDate: nicForm.driverNICExpiry.value ? dayjs(nicForm.driverNICExpiry.value).format('YYYY-MM-DD') : null,
        }

        const _list = [...documentList, _document]
        setDocumentList(_list)
        setNICForm(DRIVER_NIC_DETAILS_INITIAL_STATE)
      }

      if(_isTHere !== undefined){
        dispatch(alertActions.triggerAlert(alreadyExistsAlert));
      }
    }

    if (documentId === DRIVERDOCUMENT.OTHER_DOCUMENT) {
      const [validateData, isValid] = await validateFormData(otherForm)
      setOtherForm(validateData)

      const filePath = otherForm.otherFileName.value.split('/');
      const docFileName = filePath[filePath.length - 2];

      const _isTHere = documentList.find((v) => v.category === otherForm.driverOtherDoc.value);

      console.log("MATRIX", docFileName);
      
      if (isValid && _isTHere === undefined) {
        if (otherForm.otherFileName.value === '') {
          dispatch(alertActions.triggerAlert(setFileAlert))
        } else {
          const _document: DriverDocumentListDto1 = {
            id: _id,
            documentId: documentId,
            category: otherForm.driverOtherDoc.value,
            documentName: otherForm.otherFileName.value,
            version: otherForm.otherVersion.value,
            issuedDate: otherForm.driverOtherIssue.value ? dayjs(otherForm.driverOtherIssue.value as Dayjs).format('YYYY-MM-DD') : null,
            expiryDate: otherForm.driverOtherExpiry.value ?  dayjs(otherForm.driverOtherExpiry.value as Dayjs).format('YYYY-MM-DD') : null,
          }
          const _list = [...documentList, _document]
          setDocumentList(_list)
          setOtherForm(DRIVER_OTHER_DETAILS_INITIAL_STATE)
        }
      }

      if(_isTHere !== undefined){
        dispatch(alertActions.triggerAlert(alreadyExistsAlert));
      }
    }

  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (id === DRIVERDOCUMENT.LICENSE_DOCUMENT) {
      const file = event.target.files?.[0];
      setSelectedLicenseFile(file);
      event.target.value = '';
    }

    if (id === DRIVERDOCUMENT.NIC_DOCUMENT) {
      const file = event.target.files?.[0];
      setSelectedNICFile(file)
      event.target.value = '';
    }

    if (id === DRIVERDOCUMENT.OTHER_DOCUMENT) {
      const file = event.target.files?.[0];
      setSelectedOtherFile(file)
      event.target.value = '';
    }
  };

  useEffect(() => {
    if (selectedLicenseFile) {
      if (selectedLicenseFile.type === 'application/pdf') {
        const fileUploadaPyload: FileUploadDrivertDto = {
          attachment: selectedLicenseFile,
          mainDocumentType: DOCUMENT_TYPE.DTIVER_DOCUMENTS,
          documentType: DRIVERDOCUMENT.LICENSE_DOCUMENT,
          folderName: "",
        };
        dispatch(linkActions.uploadMainFile(fileUploadaPyload));
      } else {
        dispatch(alertActions.triggerAlert(setPDFAlert));
      }
    }
  }, [selectedLicenseFile]);

  useEffect(() => {
    if (licenseDocumentResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setLicenseForm(({
        ...licenseForm,
        licenseFileName: {
          ...licenseForm.licenseFileName,
          value: licenseDocumentResponse.data[0]
        }
      }))

      setDriverInformationForm(({
        ...driverInformationForm,
        licenseDocument: {
          ...driverInformationForm.licenseDocument,
          value: licenseDocumentResponse.data[0]
        },
        lnVersion:{
          ...driverInformationForm.lnVersion,
          value:driverInformationForm.lnVersion.value + 1,
        }
      }))

    }
  }, [licenseDocumentResponse.status])

  useEffect(() => {
    if (selectedNICFile) {
      if (selectedNICFile.type === 'application/pdf') {
        const fileUploadaPyload: FileUploadDrivertDto = {
          attachment: selectedNICFile,
          mainDocumentType: DOCUMENT_TYPE.DTIVER_DOCUMENTS,
          documentType: DRIVERDOCUMENT.NIC_DOCUMENT,
          folderName: "",
        };
        dispatch(linkActions.uploadSecondaryFile(fileUploadaPyload));
      } else {
        dispatch(alertActions.triggerAlert(setPDFAlert));
      }

    }
  }, [selectedNICFile]);

  useEffect(() => {
    if (nicDocumentResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setNICForm(({
        ...nicForm,
        NICFileName: {
          ...nicForm.NICFileName,
          value: nicDocumentResponse.data[0]
        }
      }))

      setDriverInformationForm(({
        ...driverInformationForm,
        nicDocument: {
          ...driverInformationForm.nicDocument,
          value: nicDocumentResponse.data[0]
        },
        nicVersion:{
          ...driverInformationForm.nicVersion,
          value:driverInformationForm.nicVersion.value + 1,
        }
      }))
    }
  }, [nicDocumentResponse.status])

  useEffect(() => {
    if (selectedOtherFile) {
      if (selectedOtherFile.type === 'application/pdf') {
        if (otherForm.driverOtherDoc.value !== '') {
          const fileUploadaPyload: FileUploadDrivertDto = {
            attachment: selectedOtherFile,
            mainDocumentType: DOCUMENT_TYPE.DTIVER_DOCUMENTS,
            documentType: DRIVERDOCUMENT.OTHER_DOCUMENT,
            folderName: otherForm.driverOtherDoc.value,
          };
          dispatch(linkActions.uploadOtherFile(fileUploadaPyload));
        } else {
          dispatch(alertActions.triggerAlert(setFolderAlert))
        }
      } else {
        dispatch(alertActions.triggerAlert(setPDFAlert));
      }
    }
  }, [selectedOtherFile]);


  useEffect(() => {
    if (otherDocResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setOtherForm(({
        ...otherForm,
        otherFileName: {
          ...otherForm.otherFileName,
          value: otherDocResponse.data[0]
        },
        otherVersion:{
          ...otherForm.otherVersion,
          value:otherForm.otherVersion.value+1,
        }
      }))
    }
  }, [otherDocResponse.status])

  const handleDownload = (mainId: number, docId: number) => {
    if (docId === DRIVERDOCUMENT.LICENSE_DOCUMENT) {
      const _downloadPayload: GetRequiredDocumentParmDto = {
        fileName: licenseForm.licenseFileName.value,
        mainDocumentType: mainId,
      }
      dispatch(linkActions.downloadRequiredFile(_downloadPayload))
    }

    if (docId === DRIVERDOCUMENT.NIC_DOCUMENT) {
      const _downloadPayload: GetRequiredDocumentParmDto = {
        fileName: nicForm.NICFileName.value,
        mainDocumentType: mainId,
      }
      dispatch(linkActions.downloadRequiredFile(_downloadPayload))
    }

    if (docId === DRIVERDOCUMENT.OTHER_DOCUMENT) {
      const _downloadPayload: GetRequiredDocumentParmDto = {
        fileName: otherForm.driverOtherDoc.value,
        mainDocumentType: mainId,
      }
      dispatch(linkActions.downloadRequiredFile(_downloadPayload))
    }
  }

  const handleDeleteFile = (mainId: number, docId: number) => {
    if (docId === DRIVERDOCUMENT.LICENSE_DOCUMENT) {
      if(licenseForm.licenseFileName.value !== ""){
        const _downloadPayload: GetRequiredDocumentParmDto = {
          fileName: licenseForm.licenseFileName.value,
          mainDocumentType: mainId,
        }
        dispatch(linkActions.removeUploadedFile(_downloadPayload))
      }
        setLicenseForm(DRIVER_LICENSE_DETAILS_INITIAL_STATE)
        // setDriverInformationForm({
        //   ...driverInformationForm,
        //   nic: {
        //     ...driverInformationForm.nic,
        //     value: ""
        //   },
        //   nicIssuedDate: {
        //     ...driverInformationForm.nicIssuedDate,
        //     value: ""
        //   },
        //   nicDocument: {
        //     ...driverInformationForm.nicDocument,
        //     value: ""
        //   }
        // })
    }

    if (docId === DRIVERDOCUMENT.NIC_DOCUMENT) {
      if(nicForm.NICFileName.value !== ""){
        const _downloadPayload: GetRequiredDocumentParmDto = {
          fileName: nicForm.NICFileName.value,
          mainDocumentType: mainId,
        }
        dispatch(linkActions.removeUploadedFile(_downloadPayload))
      }
      
        setNICForm(DRIVER_NIC_DETAILS_INITIAL_STATE)
        // setDriverInformationForm({
        //   ...driverInformationForm,
        //   licenceNumber: {
        //     ...driverInformationForm.licenceNumber,
        //     value: ""
        //   },
        //   licenseDocument: {
        //     ...driverInformationForm.licenseDocument,
        //     value: ""
        //   },
        //   lnExpiryDate: {
        //     ...driverInformationForm.lnExpiryDate,
        //     value: ""
        //   },
        //   lnIssuedDate: {
        //     ...driverInformationForm.lnIssuedDate,
        //     value: ""
        //   },
        // })
    }

    if (docId === DRIVERDOCUMENT.OTHER_DOCUMENT) {
      if(otherForm.otherFileName.value !== ""){
        const _downloadPayload: GetRequiredDocumentParmDto = {
          fileName: otherForm.otherFileName.value,
          mainDocumentType: mainId,
        }
        dispatch(linkActions.removeUploadedFile(_downloadPayload))
      }
      
        setOtherForm(DRIVER_OTHER_DETAILS_INITIAL_STATE)
    }
  }

  return (
    <React.Fragment>
      <AppLayout componentTitle="New Request Creation">
        <section className='page-root'>
          <section className={style.sectionCard}>

            <section className={style.sectionCardHeader}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={10}>
                  <Typography noWrap component="div" className={style.title}>
                    <h3>Manage Drivers</h3>
                  </Typography>
                </Grid>
              </Grid>
            </section>

            <section className={style.sectionCardBody}>
              <section className={style.stepperRoot}>
                <DriverInformations
                  helperText={helperText}
                  handleInputFocus={handleInputFocus}
                  driverInformationForm={driverInformationForm}
                  onInputHandleChangeDriverInformation={onInputHandleChangeDriverInformation}
                  allCompanies={allCompanies || []}
                  isLoading={viewDriverResponse.isLoading}
                />
                <DriverDocuments
                  isLoading={viewDriverResponse.isLoading}
                  onInputHandleChange={onInputHandleChange}
                  handleInputFocus={handleInputFocus}
                  handleFileChange={handleFileChange}
                  licenseForm={licenseForm}
                  nicForm={nicForm}
                  otherForm={otherForm}
                  documentList={documentList}
                  screenMode={screenMode}
                  onAddDocuments={onAddDocuments}
                  onSelectDocument={onSelectDocument}
                  handleDownload={handleDownload}
                  handleDeleteFile={handleDeleteFile}
                  helperText={helperText}
                  licenseDocumentResponse={licenseDocumentResponse}
                  nicDocumentResponse={nicDocumentResponse}
                  otherDocResponse={otherDocResponse}
                />
                <AssignVehicles
                  page={vehiclePage}
                  rowsPerPage={vehicleRowsPerPage}
                  onHandleChangePage={handleChangeVehiclePage}
                  onHandleChangeRowsPerPage={handleChangeVehicleRowsPerPage}
                  helperText={helperText}
                  screenMode={screenMode}
                  isLoading={viewDriverResponse.isLoading}
                  isEditVehicle={isEditVehicle}
                  handleInputFocus={handleInputFocus}
                  onAssignVehicle={onAssignVehicle}
                  onEditVehicle={onEditVehicle}
                  onSelectVehicle={onSelectVehicle}
                  onSetActiveVehicle={onSetActiveVehicle}
                  onClearAssignVehicleForm={onClearAssignVehicleForm}
                  assignVehicleForm={assignVehicleForm}
                  assignVehicleList={assignVehicleList}
                  onSortHandle={onAssignVehicleListSortHandle}
                  onInputHandleChangeAssignDriver={onInputHandleChangeAssignDriver}
                  allPlantList={allPlantList || []}
                  vehicleTypesByPlant={vehicleTypesByPlant || []}
                  vehicleNumberByVehicleType={vehicleNumberByVehicleType || []}
                />

                <WorkHistory
                  page={historyPage}
                  rowsPerPage={historyRowsPerPage}
                  onHandleChangePage={handleChangeHistoryPage}
                  onHandleChangeRowsPerPage={handleChangeHistoryRowsPerPage}
                  helperText={helperText}
                  screenMode={screenMode}
                  handleInputFocus={handleInputFocus}
                  onAddWorkHistory={onAddWorkHistory}
                  onClearWorkHistoryForm={onClearWorkHistoryForm}
                  onSortHandle={onWorkHistoryListSortHandle}
                  onSelectWorkHistory={onSelectWorkHistory}
                  driverWorkHistoryForm={driverWorkHistoryForm}
                  driverWorkHistoryList={driverWorkHistoryList}
                  onInputHandleChangeDriverhistory={onInputHandleChangeDriverhistory}
                  allSBUList={allSBUList || []}
                  plantListBysbu={plantListBysbu || []}
                />

                {(screenMode === REQUEST_SCREEN_MODES.EDIT || screenMode === REQUEST_SCREEN_MODES.VIEW) && (
                  <IncidentInformations
                    page={incidentPage}
                    rowsPerPage={incidentRowsPerPage}
                    onHandleChangePage={handleChangeIncidentPage}
                    onHandleChangeRowsPerPage={handleChangeIncidentRowsPerPage}
                    helperText={helperText}
                    screenMode={screenMode}
                    handleInputFocus={handleInputFocus}
                    onAddIncident={onAddIncident}
                    onClearAddIncidentForm={onClearAddIncidentForm}
                    driverIncidentForm={driverIncidentForm}
                    driverIncidentList={driverIncidentList}
                    onSortHandle={onIncidentListSortHandle}
                    onInputHandleChangeDriverIncident={onInputHandleChangeDriverIncident}
                    vehicleNumberByDriver={vehicleNumberByDriver || []}
                  />
                )}


              </section>

              <section className={style.sectionCardFooter}>
                <CustomButton text='Close' textColor='black' bgColor='#bfbfbf' onClick={onNavigate} />
                {screenMode === DRIVER_SCREEN_MODES.CREATE && <CustomButton text='Clear' border='1px solid #6e6e6e' bgColor='#282828' onClick={setAsInitialState} />}
                {screenMode === DRIVER_SCREEN_MODES.CREATE &&
                  <CustomButton
                    text="Add Driver"
                    disabled={createDriverResponse.isLoading}
                    isLoading={createDriverResponse.isLoading}
                    onClick={onCreateDriver}
                  />
                }
                {screenMode === DRIVER_SCREEN_MODES.EDIT &&
                  <CustomButton
                    text="Edit Driver"
                    disabled={editDriverResponse.isLoading || modifyDriverVehicleResponse.isLoading}
                    isLoading={editDriverResponse.isLoading || modifyDriverVehicleResponse.isLoading}
                    onClick={onEditDriver}
                  />
                }
              </section>
            </section>
          </section>
        </section>

        <ConfirmationDialog
          isOpenConfirmationDialog={isOpenVehicleRemovePopup}
          onCallback={onRemoveAssignVehicle}
          title="Remove Assign Vehicle"
          content="Do you want to remove this assigned vehicle?"
        />

        <ConfirmationDialog
          isOpenConfirmationDialog={isOpenDocumentRemovePopup}
          onCallback={onRemoveDriverDocument}
          title="Remove Driver Document Information"
          content="Do you want to remove this document?"
        />

        <ConfirmationDialog
          isOpenConfirmationDialog={isOpenHistoryRemovePopup}
          onCallback={onRemoveDriverhistory}
          title="Remove Work History Information"
          content="Do you want to remove this work history?"
        />


      </AppLayout>
    </React.Fragment>
  )
}

export default ManageDriverScreen
