import React from 'react'
import style from './LmRequestApprovalGrid.module.scss'
import { LMRequestSummaryListDto, SortMetaDto } from '../../../utilities/models';
import { Box, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material';
import { StickyTableCell, StyledCheckBox, StyledTableCell } from '../../../assets/theme/theme';
import { APP_ROUTES, APP_TABLE_CONFIGS, REQUEST_SCREEN_EXPRESS_MODES, REQUEST_SCREEN_MODES } from '../../../utilities/constants';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import moment from 'moment';
import { AppSkeleton, CustomButton, CustomHeaderCell } from '../../Shared';
import { useNavigate } from 'react-router-dom';

const LmRequestApprovalGrid: React.FC<{
  page: number,
  rowsPerPage: number,
  isFiltered: boolean,
  onHandleChangePage(event: unknown, newPage: number): void,
  onHandleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void,
  requestDataIsLoading: boolean,
  filteredList: LMRequestSummaryListDto[],
  sortMeta: SortMetaDto,
  onActionButtonClick(action: string): void
  onSortHandle(col: string): void
  onFilterHandle(col: string, value: any): void;
  onSelectRequestIds(id: string, value: boolean): void;
  navigateTo(mode: string, id: string): void;
  onClearFilter(): void;
  filters:any[];
}> = (props) => {

  const navigate = useNavigate();
  const _recurrentParentId = props.filters[0]?.recurrentParentId || [];
  // const _parentRequestId = props.filters[0]?.parentRequestId || [];
  const _reqFilters = props.filters[0]?.requestType || [];
  const _userNameFilters = props.filters[0]?.userName || [];
  const _createdByFilters = props.filters[0]?.createdBy || [];
  const _deptFilters = props.filters[0]?.departmentName || [];
  const _sbuFilters = props.filters[0]?.sbuName || [];
  const _plantFilters = props.filters[0]?.plantName || [];
  const _travelModeFilters = props.filters[0]?.travelMode || [];
  const _fromLocFilters = props.filters[0]?.fromLocation || [];
  const _toLocFilters = props.filters[0]?.toLocation || [];
  const _passengerFilters = props.filters[0]?.passengerCount || [];
  const _vehicleFilters = props.filters[0]?.preferredVehicle || [];
  const _packageFilters = ["Yes", "No"] || [];
  // const _statusFilters = props.filters[0]?.status || [];

  return (
    <section className={style.gridContainer}>
      <div className={style.gridHeader}>
        <Typography
          noWrap
          component="div"
          className={style.gridTitle}
        >
          <h3>LM Request Approval</h3>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <div className='layout-row'>
          {props.isFiltered && 
          <CustomButton text='Clear filter' textColor='black' bgColor='#bfbfbf' onClick={props.onClearFilter} />
          }
          <CustomButton text='Reject' border='1px solid #6e6e6e' bgColor='#282828' onClick={() => props.onActionButtonClick('reject')} />
          <CustomButton text='Approve' onClick={() => props.onActionButtonClick('approve')} />
        </div>
      </div>

      <TableContainer component={Paper} className={style.grid}>
        <Table className={style.table}>
          <TableHead>
            <TableRow>
              <StickyTableCell width={60} ><StyledCheckBox size="small" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onSelectRequestIds('ALL', event.target.checked) } /></StickyTableCell>
              <CustomHeaderCell width={180} id='recurrentParentId' sortable onSort={props.onSortHandle} filtered filterStringList={_recurrentParentId} onFilter={props.onFilterHandle}>Parent ID</CustomHeaderCell>
              <CustomHeaderCell width={180} id='requestId' sortable onSort={props.onSortHandle} >Request ID</CustomHeaderCell>
              <CustomHeaderCell width={180} id='requestType' sortable onSort={props.onSortHandle} filtered filterStringList={_reqFilters} onFilter={props.onFilterHandle}>Request Type</CustomHeaderCell>
              <CustomHeaderCell width={300} id='purpose'  sortable onSort={props.onSortHandle} >Purpose</CustomHeaderCell>
              <CustomHeaderCell width={200} id='projectedCost' sortable onSort={props.onSortHandle} >Projected Cost (LKR)</CustomHeaderCell>
              <CustomHeaderCell width={180} id='userName' sortable onSort={props.onSortHandle} filtered filterStringList={_userNameFilters} onFilter={props.onFilterHandle} >User Name</CustomHeaderCell>
              <CustomHeaderCell width={200} id='sbuName' sortable onSort={props.onSortHandle} filtered filterStringList={_sbuFilters} onFilter={props.onFilterHandle}>SBU</CustomHeaderCell>
              <CustomHeaderCell width={200} id='plantName' sortable onSort={props.onSortHandle} filtered filterStringList={_plantFilters} onFilter={props.onFilterHandle} >Plant</CustomHeaderCell>
              <CustomHeaderCell width={220} id='departmentName' sortable onSort={props.onSortHandle} filtered filterStringList={_deptFilters} onFilter={props.onFilterHandle} >Department</CustomHeaderCell>
              <CustomHeaderCell width={270} id='departureDateandTime' sortable onSort={props.onSortHandle} filtered onFilter={props.onFilterHandle}>Departure date & time</CustomHeaderCell>
              <CustomHeaderCell width={250} id='returnDateandTime' sortable onSort={props.onSortHandle} filtered onFilter={props.onFilterHandle}>Return date & time</CustomHeaderCell>
              <CustomHeaderCell width={150} id='fromLocation' sortable onSort={props.onSortHandle} filtered filterStringList={_fromLocFilters} onFilter={props.onFilterHandle}>From</CustomHeaderCell>
              <CustomHeaderCell width={220} id='toLocation' sortable onSort={props.onSortHandle} filtered filterStringList={_toLocFilters} onFilter={props.onFilterHandle} >To (Main Destination)</CustomHeaderCell>
              <CustomHeaderCell width={220} id='travelMode' sortable onSort={props.onSortHandle} filtered filterStringList={_travelModeFilters} onFilter={props.onFilterHandle}>Travel Mode</CustomHeaderCell>
              <CustomHeaderCell width={240} id='passengerCount' sortable onSort={props.onSortHandle} filtered filterStringList={_passengerFilters} onFilter={props.onFilterHandle}>Passenger count</CustomHeaderCell>
              <CustomHeaderCell width={240} id='preferredVehicle' sortable onSort={props.onSortHandle} filtered filterStringList={_vehicleFilters} onFilter={props.onFilterHandle}>Preferred Vehicle</CustomHeaderCell>
              <CustomHeaderCell width={150} id='packageX' sortable onSort={props.onSortHandle} filtered filterStringList={_packageFilters} onFilter={props.onFilterHandle}>Package</CustomHeaderCell>
              <CustomHeaderCell width={240} id='cbm' sortable onSort={props.onSortHandle} >Total CBM of package</CustomHeaderCell>
              <CustomHeaderCell width={240} id='createdDateandTime' sortable onSort={props.onSortHandle} filtered onFilter={props.onFilterHandle}>Create date and time</CustomHeaderCell>
              <CustomHeaderCell width={150} id='createdBy' sortable onSort={props.onSortHandle} filtered filterStringList={_createdByFilters} onFilter={props.onFilterHandle} >Created by</CustomHeaderCell>
              <CustomHeaderCell width={100} id='actions' >Actions</CustomHeaderCell>
            </TableRow>
          </TableHead>
          {props.requestDataIsLoading && (
            <AppSkeleton numOfRows={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE} numOfColumns={20} columnWidth={80} height={35} stickyFirst tag="table" />
          )}
          {!props.requestDataIsLoading && props.filteredList.length > 0 &&
            <TableBody>
              {props.filteredList.map((req: LMRequestSummaryListDto) => (
                <TableRow key={req.requestId}>
                  <StickyTableCell ><StyledCheckBox size="small" checked={req.isSelect} onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onSelectRequestIds(req.requestId, event.target.checked) }/></StickyTableCell>
                  <StyledTableCell >{!req.recurrentParentId ? 'N/A' : 'R' + req.recurrentParentId}</StyledTableCell>
                  <StyledTableCell >R{req.requestId}</StyledTableCell>
                  <StyledTableCell >{req.requestType}</StyledTableCell>
                  <StyledTableCell >{req.purpose}</StyledTableCell>
                  <StyledTableCell >{req.projectedCost}</StyledTableCell>
                  <StyledTableCell >{req.userName}</StyledTableCell>
                  <StyledTableCell >{req.sbuName ?? '-'}</StyledTableCell>
                  <StyledTableCell >{req.plantName ?? '-'}</StyledTableCell>
                  <StyledTableCell >{req.departmentName ?? '-'}</StyledTableCell>
                  <StyledTableCell >{moment(req.departureDateandTime).format(APP_TABLE_CONFIGS.DATE_FORMAT)}</StyledTableCell>
                  <StyledTableCell >{moment(req.returnDateandTime).format(APP_TABLE_CONFIGS.DATE_FORMAT)}</StyledTableCell>
                  <StyledTableCell >{req.from}</StyledTableCell>
                  <StyledTableCell >{req.to}</StyledTableCell>
                  <StyledTableCell >{req.travelMode}</StyledTableCell>
                  <StyledTableCell >{req.passengerCount}</StyledTableCell>
                  <StyledTableCell >{req.preferredVehicle}</StyledTableCell>
                  <StyledTableCell >{req.package ? "Yes" : "No"}</StyledTableCell>
                  <StyledTableCell >{req.cbm}</StyledTableCell>
                  <StyledTableCell >{moment(req.createdDateandTime).add(330, 'minute').format(APP_TABLE_CONFIGS.DATE_FORMAT)}</StyledTableCell>
                  <StyledTableCell >{req.createdBy}</StyledTableCell>
                  <StyledTableCell style={{ backgroundColor: '#282828' }}>
                    <Box className='layout-row'>
                      <Box>
                        <IconButton size='small' onClick={() => { 
                          
                          if(req.requestType==="Express AdHoc"){
                            sessionStorage.setItem("Mode",REQUEST_SCREEN_EXPRESS_MODES.LM_APPROVAL_VIEW);
                            sessionStorage.setItem("id", req.requestId.toString());
                            navigate(APP_ROUTES.MANAGE_REQUEST_EXPRESS);
                           }else{
                            props.navigateTo(REQUEST_SCREEN_MODES.LM_APPROVAL_VIEW, req.requestId) }
                           }
                          }>
                          <Tooltip title="View">
                            <RemoveRedEyeOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          }
          {!props.requestDataIsLoading && props.filteredList.length === 0 &&
            <TableBody>
              <TableRow>
                <StyledTableCell align="left" colSpan={19}>No data to preview</StyledTableCell>
              </TableRow>
            </TableBody>
          }
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_OPTIONS}
        component="div"
        labelRowsPerPage={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: 'white',
            }}
          >
            Items per page
          </div>
        }
        count={-1}
        page={props.page}
        onPageChange={props.onHandleChangePage}
        onRowsPerPageChange={props.onHandleChangeRowsPerPage}
        rowsPerPage={props.rowsPerPage}
        nextIconButtonProps={
          { disabled: -1 < props.filteredList.length && props.filteredList.length < props.rowsPerPage }
        }
        sx={{ backgroundColor: "#282828", color: "white" }}
      />
    </section>
  )
}

export default LmRequestApprovalGrid
