import React, { useEffect, useState } from "react";
import style from "./TmRequestSummaryGraphCard.module.scss";
import { Card, CardContent, Box, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CustomButton from "../CustomButton/CustomButton";
import ApexCharts from "react-apexcharts";
import { useSelector } from "react-redux";
import { ApplicationStateDto } from "../../../utilities/models/state.model";
import { TMRequestSummaryBarChartDto } from "../../../utilities/models/chart.model";
import { USER_ROLES } from "../../../utilities/constants/app.constants";

const TmRequestSummaryGraphCard = (props: any) => {
  const endpointData = props.tmDeptChartData || [];
  const autthID = useSelector((state: ApplicationStateDto) => state.authUser.authorizedUser.data.roleId);
    
  const labels = Array.from(new Set(endpointData.map((data: any) => data.department)));

  const seriesData = [
    {
      name: "Approved",
      data: endpointData.map((data: TMRequestSummaryBarChartDto) => parseInt(data.approved)),
    },
    {
      name: "Pending",
      data: endpointData.map((data: TMRequestSummaryBarChartDto) => parseInt(data.pending)),
    },
    {
      name: "Rejected",
      data: endpointData.map((data: TMRequestSummaryBarChartDto) => parseInt(data.rejected)),
    },
  ];

  const data = {
    labels: labels,
    series: seriesData,
  };

  const options: any = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        colors: {
          hover: "#000000",
        },
        dataLabels: {
          style: {
            borderColor: ["#3fffff"],
            colors: ["#000000"], // Set data label color to white
          },

          borderRadiusWhenStacked: "all",
        },
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: data.labels,
      labels: {
        show: false, // Hide x-axis labels
      },
      min: 0,  // Set the minimum value
      max: 100, // Set the maximum value
    },
    yaxis: {
      labels: {
        style: {
          colors: ["#ffffff"], // Replace with the desired text color
        },
      },    
    },
    grid: {
      show: false,
    },
    colors: [
      "rgb(105,211,153)", // Green for Accepted
      "rgb(254,206,37)",
      "rgb(242,92,129)", // Pink for Rejected
    ],
    tooltip: {
      fillSeriesColor: true,
    },
    dataLabels: {
      style: {
        borderColor: "#222312",
        colors: ["#282828"], // Set data label color to white
      },
    },
    
  };

  // Add a CSS rule to hide the classes
  const hideClasses = `
    .apexcharts-xaxis,
    .apexcharts-yaxis-inversed,
    .apexcharts-xaxis-tick {
      display: none;
    }
  `;

  
  
  return (
    <Card className={style.summaryCard}>
      <CardContent>
        <Box className={style.summaryCardTitle}>
          <Box>
            <Typography className={style.main}>Request Summary</Typography>
            {  !(autthID===USER_ROLES.SBU_MANAGER) && !(autthID === USER_ROLES.SUPER_ADMIN) && <Typography className={style.sub}>By Department</Typography>}
            {  (autthID===USER_ROLES.SBU_MANAGER) && <Typography className={style.sub}>By Plant</Typography>}
            {  (autthID===USER_ROLES.SUPER_ADMIN)&& <Typography className={style.sub}> Organization super admin {`>`} By SBU</Typography>}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box></Box>
        </Box>
        <Box
          sx={{
            // background: "$$bgColorSidebar",
            maxHeight: { xs: "none", md: "23rem", marginTop: ".1rem" },
            width: "100%",
          }}
        >
          <ApexCharts
            options={options}
            series={data.series}
            type="bar"
            height={150}
          />
        </Box>
      </CardContent>
      <style>{hideClasses}</style>
    </Card>
  );
};

export default TmRequestSummaryGraphCard;
