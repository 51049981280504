import React from 'react'
import { BootstrapDialog, BootstrapDialogContent, BootstrapDialogTitle } from '../../../assets/theme/theme'
import styles from "./MapSummaryPopup.module.scss"
import GoogleMap from '../GoogleMap/GoogleMap';

const MapSummaryPopup: React.FC<{
    isOpenMapPopup: boolean;
    onMapPopupClose():void;
  }> = (props) => {
  return (
    <BootstrapDialog
      width={"2400px"}
      className={styles.dialogCard}
      aria-labelledby="customized-dialog-title"
      open={props.isOpenMapPopup}
    >
      <BootstrapDialogTitle id="customized-dialog-title"
        onClose={() => { props.onMapPopupClose() }}>
        Trip Summary
      </BootstrapDialogTitle>
     
      <BootstrapDialogContent>
        <section className={styles.gridSection}>
            <GoogleMap/>
        </section>
      </BootstrapDialogContent>
    </BootstrapDialog>
  )
}

export default MapSummaryPopup