import { COMMON_ACTION_TYPES, MAP_ACTION_TYPE } from "../../utilities/constants"
import { selectedType } from "../../utilities/models"


const AddStartMapPoint = (payload: selectedType) => {
    return {
        type: MAP_ACTION_TYPE.ADD_MAP_START_POINT + COMMON_ACTION_TYPES.SUCCESS,
        payload: payload
    }
}

const AddEndMapPoint = (payload: selectedType) => {
    return {
        type: MAP_ACTION_TYPE.ADD_MAP_END_POINT + COMMON_ACTION_TYPES.SUCCESS,
        payload: payload
    }
}

const ADDPickUpPoints = (payload: selectedType[]) => {
    return {
        type: MAP_ACTION_TYPE.ADD_MAP_PICKUP_POINT + COMMON_ACTION_TYPES.SUCCESS,
        payload: payload
    }
}

const addDropOffPoints = (payload: selectedType[]) => {
    return {
        type: MAP_ACTION_TYPE.ADD_MAP_DROPOFF_POINT + COMMON_ACTION_TYPES.SUCCESS,
        payload: payload
    }
}

const AddStartMapPointClear = () => {
    return {
        type: MAP_ACTION_TYPE.ADD_MAP_START_POINT + COMMON_ACTION_TYPES.CLEAR,
    }
}

const AddEndMapPointClear = () => {
    return {
        type: MAP_ACTION_TYPE.ADD_MAP_END_POINT + COMMON_ACTION_TYPES.CLEAR,
    }
}

const ADDPickUpPointsClear = () => {


    return {
        type: MAP_ACTION_TYPE.ADD_MAP_PICKUP_POINT + COMMON_ACTION_TYPES.CLEAR,

    }
}
const addDropOffPointsClear = () => {


    return {
        type: MAP_ACTION_TYPE.ADD_MAP_DROPOFF_POINT + COMMON_ACTION_TYPES.CLEAR,

    }
}
const SetTotalDistance = (payload: string) => {


    return {
        type: MAP_ACTION_TYPE.SET_TOTAL_DISTANCE + COMMON_ACTION_TYPES.SUCCESS,
        payload: payload
    }
}

const setTotalDuration = (payload:number) => {
    return {
        type: MAP_ACTION_TYPE.SET_TOTAL_DURATION + COMMON_ACTION_TYPES.SUCCESS,
        payload: payload
    }
}

export const mapActions = {
    AddStartMapPoint,
    AddEndMapPoint,
    ADDPickUpPoints,
    addDropOffPoints,
    AddStartMapPointClear,
    AddEndMapPointClear,
    ADDPickUpPointsClear,
    addDropOffPointsClear,
    SetTotalDistance,
    setTotalDuration
}