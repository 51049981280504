import { COMMON_ACTION_TYPES, APP_ACTION_STATUS, AUTH_ACTION_TYPE } from '../../utilities/constants'
import { AuthUserStateDto, AuthorizedUserDto } from '../../utilities/models'
const INITIAL_STATE: AuthUserStateDto = {
  userAuthorizing: {
    isAuthorized: false,
    isLoading: false,
    status: null,
    error: null
  },
  authorizedUser: {
    data: {} as AuthorizedUserDto,
    isLoading: false,
    status: null,
    error: null
  }
}
const authReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    // user authorizing
    case AUTH_ACTION_TYPE.AUTHORIZE_USER + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        userAuthorizing: {
          ...state.userAuthorizing,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case AUTH_ACTION_TYPE.AUTHORIZE_USER + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        userAuthorizing: {
          isAuthorized: true,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null
        }
      }
    case AUTH_ACTION_TYPE.AUTHORIZE_USER + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        userAuthorizing: {
          isAuthorized: false,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error
        }
      }
    case AUTH_ACTION_TYPE.AUTHORIZE_USER + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        userAuthorizing: {
          isAuthorized: false,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null
        }
      }
    // fetch authorized user
    case AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        authorizedUser: {
          ...state.authorizedUser,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        authorizedUser: {
          data: action.data,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null
        }
      }
    case AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        authorizedUser: {
          data: {},
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error
        }
      }
    case AUTH_ACTION_TYPE.FETCH_AUTHORIZE_USER + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        authorizedUser: {
          data: {},
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null
        }
      }
    default:
      return state
  }
}
export default authReducer
