import { AxiosResponse } from "axios";
import { UsrInfoDto } from "../utilities/models";
import { axiosPrivateInstance } from ".";
import { AssignRolesforUsersParamDto, EditRolesforusersParamDto, GetAllUsersWithEntitlementsDTO, GetRolesAndEntitlementDto, UserByDeptDto, UserRolesEntitlementsDto,
  emailId,} from "../utilities/models/permission.model";

  
const getUserInfo = (email: string): Promise<AxiosResponse<UsrInfoDto>> => {
  return axiosPrivateInstance.get(
    `/api/Permission/GetRoles&EntitlementsByUser?emailId=${email}`
  );
};

const getUserByDept = (deptId: number): Promise<AxiosResponse<UserByDeptDto[]>> => {
  return axiosPrivateInstance.get(
    `/api/User/GetUsersByDepartment?departmentId=${deptId}`
  );
};

const getRolesEntitlements = (): Promise<AxiosResponse<UserRolesEntitlementsDto[]>> => {
  return axiosPrivateInstance.get(`/api/Permission/GetRoles&Entitlements`);
};

const postAssignRolesUsers = (payload: AssignRolesforUsersParamDto): Promise<AxiosResponse<string>> => {
  return axiosPrivateInstance.post(`/api/Permission/AssignRoleForUsers`, payload);
};

const editAssignRolesUsers = (payload: EditRolesforusersParamDto): Promise<AxiosResponse<string>> => {
  return axiosPrivateInstance.put(`/api/Permission/UpdateUsersRole`, payload);
};

const getAllUsersWithEntitlements = (payload: any): Promise<AxiosResponse<GetAllUsersWithEntitlementsDTO>> => {
  return axiosPrivateInstance.get(`/api/Permission/GetAllUsersWithEntitlements?pageNumber=${payload.pageNumber}&pagination=${payload.pagination}`);
};

const getSelecteUserDetailsById = (id: number): Promise<AxiosResponse<void>> => {
  return axiosPrivateInstance.get(`/api/Permission/GetSelectedUserWithDetails?userId=${id}`);
};

const getRolesAndEntitlementByUser = (payload: emailId): Promise<AxiosResponse<GetRolesAndEntitlementDto>> => {
  return axiosPrivateInstance.get(`/api/Permission/GetRoles&EntitlementsByUser?emailId=${payload.emailId}`)
}

export const permissionService = {
  getUserInfo,
  getUserByDept,
  getRolesEntitlements,
  postAssignRolesUsers,
  editAssignRolesUsers,
  getAllUsersWithEntitlements,
  getSelecteUserDetailsById,
  getRolesAndEntitlementByUser,
};
