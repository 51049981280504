import React from 'react'
import { GetExecutionTemplateDto } from '../../../utilities/models'
import style from './ExecutionHistoryTable.module.scss'
import { EditOutlined } from "@mui/icons-material";
import { StyledTableCell } from '../../../assets/theme/theme'
import { DAILY_TRANSPORT_MODES, APP_TABLE_CONFIGS, EMPLOYEE_CATEGORY } from '../../../utilities/constants'
import { CustomButton, CustomHeaderCell, AppSkeleton } from '../../Shared'
import { Box, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import moment from 'moment';

const ExecutionHistoryTable: React.FC<{
  page: number,
  rowsPerPage: number,
  isFiltered: boolean,
  onHandleChangePage(event: unknown, newPage: number): void,
  onHandleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void,
  filteredList: GetExecutionTemplateDto[],
  navigateTo(mode: string, id: number): void;
  isLoading: boolean,
  onSortHandle(col: string): void
  getFilterList: (col: string) => string[];
  onFilterHandle(col: string, value: string): void;
  onClearFilter(): void;

}> = (props) => {
  return (
    <section className={style.gridContainer}>
      <div className={style.gridHeader}>
        <Typography noWrap component="div" className={style.gridTitle}>
          <h3>Daily Transport Execution Templates Table</h3>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <div className="layout-row">
          {props.isFiltered && (
            <CustomButton text="Clear filter" textColor="black" bgColor="#bfbfbf" onClick={props.onClearFilter} />
          )}
          {/* <CustomButton text="+ New Template" onClick={() => props.navigateTo(DAILY_TRANSPORT_MODES.CREATE, -1)} /> */}
        </div>
      </div>
      <TableContainer component={Paper} className={style.grid}>
        <Table className={style.table}>
          <TableHead>
            <TableRow>
              <CustomHeaderCell width={100} id="name" sortable onSort={props.onSortHandle}>Execution Template Name</CustomHeaderCell>
              <CustomHeaderCell width={180} id="dateOfExecution" filtered sortable getFilterList={props.getFilterList} onFilter={props.onFilterHandle} onSort={props.onSortHandle}>Date of Execution  </CustomHeaderCell>
              <CustomHeaderCell width={120} id="sbu" filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >SBU</CustomHeaderCell>
              <CustomHeaderCell width={120} id="plant" filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Plant</CustomHeaderCell>
              <CustomHeaderCell width={140} id="empCategory" filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Employee Category</CustomHeaderCell>
              <CustomHeaderCell width={100} id="shiftGroup" filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Shift Group</CustomHeaderCell>
              <CustomHeaderCell width={100} id="shiftType" filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Shift</CustomHeaderCell>
              <CustomHeaderCell width={150} id="mainDestination" filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Plant Location</CustomHeaderCell>
              <CustomHeaderCell width={150} id="noOfRoutes" filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Number of route records included in the template</CustomHeaderCell>
              <CustomHeaderCell width={150} id="createDateTime" filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Template created date/time</CustomHeaderCell>
              <CustomHeaderCell getFilterList={props.getFilterList} onFilter={props.onFilterHandle} id="actions">Actions</CustomHeaderCell>
            </TableRow>
          </TableHead>
          {props.isLoading && (
            <AppSkeleton numOfRows={props.rowsPerPage} numOfColumns={12} columnWidth={150} tag="table" />
          )}
          {!props.isLoading && props.filteredList.length > 0 && (
            <TableBody>
              {props.filteredList.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage).map((item: GetExecutionTemplateDto, index) => (
                <TableRow key={index}>
                  <StyledTableCell > {item.templateName.replace(/(\d{4}-\d{2}-\d{2}).*/, '$1')}</StyledTableCell>
                  <StyledTableCell >{moment(item.dateOfExecution).format(APP_TABLE_CONFIGS.DATE_FORMAT)}</StyledTableCell>
                  <StyledTableCell >{item.sbu}</StyledTableCell>
                  <StyledTableCell >{item.plant}</StyledTableCell>
                  <StyledTableCell >{item.employeeCategory}</StyledTableCell>
                  <StyledTableCell >{item.shiftGroup ? item.shiftGroup : 'N/A'}</StyledTableCell>
                  <StyledTableCell >{item.shift ? item.shift : 'N/A'}</StyledTableCell>
                  <StyledTableCell >{item.mainDestination}</StyledTableCell>
                  <StyledTableCell >{item.noOfRoutes}</StyledTableCell>
                  <StyledTableCell >{moment(item.createDate).add(330, 'minute').format(APP_TABLE_CONFIGS.DATE_FORMAT)}</StyledTableCell>
                  <StyledTableCell style={{ backgroundColor: '#282828' }}>
                    <Box className='layout-row'>
                      <Box>
                        <IconButton size='small' onClick={() => props.navigateTo(DAILY_TRANSPORT_MODES.VIEW_EXECUTED, item.id)}>
                          <Tooltip title="View">
                            <VisibilityOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>

                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}

            </TableBody>
          )}
          {!props.isLoading && props.filteredList.length === 0 && (
            <TableBody>
              <TableRow>
                <StyledTableCell align="center" colSpan={12}>
                  No data to preview
                </StyledTableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_OPTIONS}
        component="div"
        labelRowsPerPage={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: 'white',
            }}
          >
            Items per page
          </div>
        }
        count={props.filteredList.length}
        page={props.page}
        onPageChange={props.onHandleChangePage}
        onRowsPerPageChange={props.onHandleChangeRowsPerPage}
        rowsPerPage={props.rowsPerPage}
        showFirstButton={true}
        showLastButton={true}
        sx={{ backgroundColor: "#282828", color: "white" }}
      />
    </section>
  )
}

export default ExecutionHistoryTable