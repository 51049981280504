import { AxiosResponse } from "axios";
import { LocationBriefDto } from "../utilities/models";
import { axiosPrivateInstance } from ".";

const getFrequentLocations = (): Promise<AxiosResponse<LocationBriefDto[]>> => {
    return axiosPrivateInstance.get('/api/Location/GetFrequentLocations')
}

export const locationService = {
    getFrequentLocations
}