import { AxiosResponse } from "axios";
import { call, put, takeEvery } from 'redux-saga/effects'
import { LocationBriefDto } from "../../utilities/models";
import { locationService } from "../../services/location.service";
import { COMMON_ACTION_TYPES, LOCATION_ACTION_TYPE } from "../../utilities/constants";

function * getFrequentLocations (action: {type: string}) {
    try {
        const frequentLocations: AxiosResponse<LocationBriefDto[]> = yield call(locationService.getFrequentLocations)
        yield put({
            type: LOCATION_ACTION_TYPE.GET_FREQUENT_LOCATION_LIST + COMMON_ACTION_TYPES.SUCCESS,
            data: frequentLocations.data
        })
    } catch (error) {
        
        yield put({
            type: LOCATION_ACTION_TYPE.GET_FREQUENT_LOCATION_LIST + COMMON_ACTION_TYPES.ERROR,
            error: error
        })
    }
}
function* locationSaga() {
    yield takeEvery(LOCATION_ACTION_TYPE.GET_FREQUENT_LOCATION_LIST + COMMON_ACTION_TYPES.REQUEST, getFrequentLocations)
}

export default locationSaga