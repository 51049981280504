import React, { useEffect, useState } from "react";
import { AppLayout } from "../../templates";
import { AllocatedRequisitionsPopUp, TMAllocationSummaryDataGrid,} from "../../components";
import { APP_ACTION_STATUS, APP_ROUTES, APP_TABLE_CONFIGS,} from "../../utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApplicationStateDto, SortMetaDto, allocationHistoryDto, allocationRequisitionsDto, cancelAllocationParm, selectedType, vehicleDetailsListDto,} from "../../utilities/models";
import { mapActions, tripActions, vehicleActions } from "../../redux/actions";
import moment from "moment";
import CancelAllocationPopup from "../../components/TMAllocationSummary/CancleAllocationPopUp/CancleAllocationPopup";
import dayjs from "dayjs";
import MapSummaryPopup from "../../components/Shared/MapSummaryPopup/MapSummaryPopup";

const TMAllocationSummary = () => {
  const INITIAL_SORT_META: SortMetaDto = {
    field: "",
    asc: false,
  };
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const navigate = useNavigate();
  const [sortMeta, setSortMeta] = useState<SortMetaDto>(INITIAL_SORT_META);
  const [sortMeta2, setSortMeta2] = useState<SortMetaDto>(INITIAL_SORT_META);
  const [Navigete, setNavigete] = useState(false);
  const [allocationID, setAllocationID] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE);
  const [rowsPerPage2, setRowsPerPage2] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE);
  const [filteredList, setFilteredList] = useState<allocationHistoryDto[]>([]);
  const [allocationId, setallocationId] = useState("")

  const [allocatedRequisitions, setallocatedRequisitions] = useState<allocationRequisitionsDto[]>([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isFiltered2, setIsFiltered2] = useState(false);
  const [isOpenAddPackagePopup, setIsOpenAddPackagePopup] = useState(false);
  const [isOpenCancelAllocationPopup, setIsOpenCancelAllocationPopup] = useState(false);
  const [isOpenMapPopup, setIsOpenMapPopup] = useState(false);
  const [reason, setReason] = useState("");
  const [selectedStartePointLatANdLang, setselectedStartePointLatANdLang] = useState<selectedType>()
  const [selectedEndPointLatANdLang, setselectedEndPointtLatANdLang] = useState<selectedType>()

  const allocatedHistoryResponse = useSelector((state: ApplicationStateDto) => state.vehicle.getAllocationsHistory);
  const allocatedRequisitionsResponse = useSelector((state: ApplicationStateDto) => state.vehicle.getAllocationsRequisitions);
  const allocationCancelResponse = useSelector((state: ApplicationStateDto) => state.vehicle.putAllocationCancel);

  const onSelectRequestIds = (id: string, value: boolean) => {
    if (id === "ALL") {
      setFilteredList(
        filteredList.map((item) => {
          return {
            ...item,
            isSelect: value,
          };
        })
      );
    } else {
      setFilteredList(
        filteredList.map((item) => {
          return {
            ...item,
            isSelect: id === item.allocationId ? value : item.isSelect,
          };
        })
      );
    }
  };

  const onSortHandle = (col: string) => {
    const sorted = filteredList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setFilteredList(sorted);
  };

  const onSeeAllocationHistory = () => {};

  const onclickOnView = (allocationId: string) => {
    dispatch(vehicleActions.getAllocationRequisitions(allocationId));
    setIsOpenAddPackagePopup(true);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangePage2 = (event: unknown, newPage: number) => {
    setPage2(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeRowsPerPage2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage2(+event.target.value);
    setPage2(0);
  };

  useEffect(() => {
    dispatch(vehicleActions.getAllocationHistory());
    dispatch(tripActions.updateTripAllocationDetailsClear());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allocatedHistoryResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (allocatedHistoryResponse.data.length > 0)
        setFilteredList(
          allocatedHistoryResponse.data.map((item) => {
            return {
              ...item,
              isSelect: false,
            };
          })
        );
      else setFilteredList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocatedHistoryResponse.status]);

  useEffect(() => {
    if (allocationCancelResponse.status === APP_ACTION_STATUS.SUCCESS) {
      dispatch(vehicleActions.getAllocationHistory());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocationCancelResponse.status]);

  useEffect(() => {
    if (allocatedRequisitionsResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setallocatedRequisitions(allocatedRequisitionsResponse.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocatedRequisitionsResponse.status]);

  const OnclickPopupClose = () => {
    setIsOpenAddPackagePopup(false);
    dispatch(vehicleActions.getAllocationRequisitionsClear());
    setallocatedRequisitions(allocatedRequisitionsResponse.data);
  };
  const OnCancelAllocationPopUPClose = () => {
    setIsOpenCancelAllocationPopup(false);
  };

  const onMapPopupClose = () => {
    dispatch(mapActions.AddStartMapPointClear());
    dispatch(mapActions.ADDPickUpPointsClear());
    dispatch(mapActions.AddEndMapPointClear());
    dispatch(mapActions.addDropOffPointsClear());
    setIsOpenMapPopup(false)
  }

  const onClearFilter = () => {
    setIsFiltered(false);
    setFilteredList(allocatedHistoryResponse.data);
  };
  const onClearFilter2 = () => {
    setIsFiltered2(false);
    setallocatedRequisitions(allocatedRequisitionsResponse.data);
  };

  const onFilterHandle = (col: string, value: string) => {
    setIsFiltered(true);
    const _list = allocatedHistoryResponse.data;
    const filtered = _list.filter((item) => {
      const _value = (item as any)[col];

      if (typeof _value === "boolean") {
        return _value ? value === "Yes" : value === "No";
      }
      if (col === "createdDateandTime" || col === "allocatedOn") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DD HH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DD HH:mm");
        const _targetDate = dayjs(_value).add(330, "minute").format("YYYY-MM-DD HH:mm");

        return moment(_targetDate).isBetween(_selectedMin, _selectedMax);
      }

      if (col === "departureDateTime" || col === "returnDateTime") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DD HH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DD HH:mm");
        const _targetDate = dayjs(_value).format("YYYY-MM-DD HH:mm");

        return moment(_targetDate).isBetween(_selectedMin, _selectedMax);
      }
      return _value === value;
    });

    setFilteredList(filtered);
  };

  const getFilterList = (col: string): string[] => {
    if (!allocatedHistoryResponse.isLoading) {
      const _list = allocatedHistoryResponse.data || [];

      const keys = _list.map((item) => {
          const value = (item as any)[col];

          if (typeof value === "boolean") {
            return value ? "Yes" : "No";
          }
          return value;
        }).filter((value, index, array) => array.indexOf(value) === index);
      return keys;
    } else return [];
  };

  const onFilterHandle2 = (col: string, value: string) => {
      setIsFiltered2(true);

      const _list = allocatedRequisitionsResponse.data || [];
      const filtered = _list.filter((item) => {
      const _value = (item as any)[col];

      if (typeof _value === "boolean") {
        return _value ? value === "Yes" : value === "No";
      }

      if (col === "createdDateandTime" || col === "allocatedOn") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DD HH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DD HH:mm");
        const _targetDate = dayjs(_value).add(330, "minute").format("YYYY-MM-DD HH:mm");

        return moment(_targetDate).isBetween(_selectedMin, _selectedMax);
      }

      if (col === "departureDateTime" || col === "returnDateTime") {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DD HH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DD HH:mm");
        const _targetDate = dayjs(_value).format("YYYY-MM-DD HH:mm");

        return moment(_targetDate).isBetween(_selectedMin, _selectedMax);
      }
      return _value === value;
    });

    setallocatedRequisitions(filtered);
  };

  const onSortHandle2 = (col: string) => {
    const sorted = allocatedRequisitions.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta2.asc) {
      sorted.reverse();
    }

    setSortMeta2((_sort) => ({ field: col, asc: !_sort.asc }));
    setallocatedRequisitions(sorted);
  };

  const getFilterList2 = (col: string): string[] => {
    if (!allocatedRequisitionsResponse.isLoading) {
      const _list = allocatedRequisitionsResponse.data || [];

      const keys = _list
        .map((item) => {
          const value = (item as any)[col];

          if (typeof value === "boolean") {
            return value ? "Yes" : "No";
          }
          return value;
        })
        .filter((value, index, array) => array.indexOf(value) === index);
      return keys;
    } else return [];
  };

  const onCallback = (value: boolean, reason: string) => {
    if (value) {
        const param: cancelAllocationParm = {
          AllocationId: allocationId,
          reason: reason,
        };
        dispatch(vehicleActions.cancelAllocation(param));
      
    } else {
      setIsOpenCancelAllocationPopup(false);
      setReason("");
    }
  };

  const onclickOnCancelAllocation = (allocationId: string) => {
    setallocationId(allocationId)
    setIsOpenCancelAllocationPopup(true);
  };

  const onClickOnMap = (fLat:string, fLon:string, toLat:string, toLon:string) => {
    console.log("CHECKS", fLat, fLon, toLat, toLon);
    const selectedStartPoint = { lat: Number(fLat), lng: Number(fLon) };
    const selectedEndPoint = { lat: Number(toLat), lng: Number(toLon) };
    setselectedStartePointLatANdLang(selectedStartPoint);
    setselectedEndPointtLatANdLang(selectedEndPoint);
    navToMap(selectedStartPoint, selectedEndPoint);
  };
  
  const navToMap = (selectedStartePointLatANdLang:selectedType, selectedEndPointtLatANdLang: selectedType) => {
    if (
      selectedStartePointLatANdLang !== undefined &&
      selectedEndPointtLatANdLang !== undefined
    ) {
      dispatch(mapActions.AddStartMapPoint(selectedStartePointLatANdLang));
      dispatch(mapActions.AddEndMapPoint(selectedEndPointtLatANdLang));
    }
    setIsOpenMapPopup(true);
  };
  
  
  useEffect(() => {
    if (allocationCancelResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setIsOpenCancelAllocationPopup(false);
      setReason("");
    }
  }, [allocationCancelResponse.status]);

  const handleEditClick = (allocationId: string) => {
    dispatch(vehicleActions.getAllocationRequisitions(allocationId));
    setNavigete(true);
    setAllocationID(allocationId);
  };

  const onNavigateBack = () => {
    navigate(APP_ROUTES.TM_VEHICLE_ALLOCATION)
  }

  useEffect(() => {
    if (allocatedRequisitionsResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setallocatedRequisitions(allocatedRequisitionsResponse.data);
      if (Navigete && allocatedRequisitionsResponse.data.length > 0) {
        let _list: Array<string> = [];

        allocatedRequisitions.forEach((req) => {
          _list = [..._list, req.requestId];
        });

        const allocationData = filteredList.find(
          (item) => item.allocationId === allocationID
        );

        const allocationDetails: vehicleDetailsListDto = 
          {
            allocationId: allocationID,
            editIsTrue: true,
            selectedTripId: "",
            licensePlateNo: allocationData?.vehiclePlateNo || "",
            driverName: allocationData?.driverName || "",
            transportCompany: allocationData?.transportCompany || "",
            vehicleSeatCount: "",
            passengerCount: "",
            seatCount: allocationData?.seatCount.toString() || "",
            capacity: allocationData?.cbm.toString() || "",
            vehicleId: allocationData?.vehicleId || 0,
            isSelected: false,
            fromLocation: allocationData?.fromLocation || "",
            toLocation: allocationData?.toLocation || "",
            availableSeatCount: "",
            maxSeatCount: "",
            fromLocationLatitude: allocationData?.fromLocationLatitude || "",
            fromLocationLongitude: allocationData?.fromLocationLongitude || "",
            toLocationLatitude: allocationData?.toLocationLatitude || "",
            toLocationLongitude: allocationData?.toLocationLongitude || "",
            sbu: "",
            plant: "",
            actualMileage: "",
            actualCost: "",
            isView:false
          }
      
        if (_list.length > 0)
          navigate(APP_ROUTES.TM_ACCEPT_VEHICLE_ALLOCATION, {
            state: { reqList: _list, allocationDetails: allocationDetails },
          });
      }
    }
  }, [
    Navigete,
    allocatedRequisitionsResponse.status,
    allocationID,
    filteredList,
    navigate,
  ]);

  return (
    <React.Fragment>
      <AppLayout componentTitle="Allocation Summary">
        <section className="page-root">
          <TMAllocationSummaryDataGrid
            onclickOnCancelAllocation={onclickOnCancelAllocation}
            onClickOnMap={onClickOnMap}
            onSortHandle={onSortHandle}
            onSeeAllocationHistory={onSeeAllocationHistory}
            getFilterList={getFilterList}
            onFilterHandle={onFilterHandle}
            filteredList={filteredList}
            isFiltered={isFiltered}
            onNavigateBack={onNavigateBack}
            onHandleChangeRowsPerPage={handleChangeRowsPerPage}
            onHandleChangePage={handleChangePage}
            page={page}
            rowsPerPage={rowsPerPage}
            onclickOnView={onclickOnView}
            isOpenAddPackagePopup={isOpenAddPackagePopup}
            setIsOpenAddPackagePopup={setIsOpenAddPackagePopup}
            isLoading={allocatedHistoryResponse.isLoading}
            onSelectRequestIds={onSelectRequestIds}
            onClearFilter={onClearFilter}
           
            handleEditClick={handleEditClick}
          />

          <AllocatedRequisitionsPopUp
            onClearFilter2={onClearFilter2}
            getFilterList={getFilterList2}
            onFilterHandle={onFilterHandle2}
            isOpenAddPackagePopup={isOpenAddPackagePopup}
            page={page2}
            isFiltered={isFiltered2}
            rowsPerPage={rowsPerPage2}
            onSortHandle={onSortHandle2}
            OnclickPopupClose={OnclickPopupClose}
            allocatedRequisitions={allocatedRequisitions}
            onHandleChangeRowsPerPage={handleChangeRowsPerPage2}
            onHandleChangePage={handleChangePage2}
            isLoading={allocatedRequisitionsResponse.isLoading}
          />

          <CancelAllocationPopup
            onCallback={onCallback}
            OnCancelAllocationPopUPClose={OnCancelAllocationPopUPClose}
            isOpenCancelAllocationPopup={isOpenCancelAllocationPopup}
            setReason={setReason}
            reason={reason}
          />

          <MapSummaryPopup 
            isOpenMapPopup={isOpenMapPopup}
            onMapPopupClose={onMapPopupClose}
          />
        </section>
      </AppLayout>
    </React.Fragment>
  );
};

export default TMAllocationSummary;
