import React from 'react'
import { BootstrapDialog, BootstrapDialogContent, BootstrapDialogTitle, StyledTableCell, StyledTextField } from '../../../assets/theme/theme'
import { Box, Grid, IconButton, MenuItem, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import style from'./TmRequestApprovalViewAvaliBilityPopUp.module.scss'
import { VehicleTypesDto, vehicleDetailsListDto } from '../../../utilities/models'
import { CustomButton, CustomHeaderCell } from '../../Shared'
const TmRequestApprovalViewAvaliBilityPopUp: React.FC<{
   passengerCount:number,
   isOpenViewVehicleDetailsPopUp:boolean,
   handleClosePopUp():void;
   VehicleTypeError:boolean;
   onHandleInputChange(property: string, value: string): void;
   VehicleTypeList: VehicleTypesDto[];
   selectedVehicleType: string;
   onShowVehicle():void;
   vehicleDetails: vehicleDetailsListDto[];
  }> = (props)   => {

  return (
    <BootstrapDialog 

    open={props.isOpenViewVehicleDetailsPopUp}    // className={styles.dialogCard}
    // aria-labelledby="customized-dialog-title"
    // open={props.isOpenConfirmationDialog}
  >
    <BootstrapDialogTitle  
    id={''} 
        onClose={() => props.handleClosePopUp()}    >
       View Available Vehicle details
 </BootstrapDialogTitle>
 <BootstrapDialogContent>


 <Grid container spacing={4}>
        <Grid item xs={12} md={6} className={style.formBox}>
          <StyledTextField
          fullWidth
            placeholder="Required Number of seats"
            size="small"
            value={props.passengerCount}
            label="Required Number of seats"
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} md={6} className={style.formBox}>
          <StyledTextField
          fullWidth
            placeholder="Select Vehicle Type"
            size="small"
            select
            defaultValue="EUR"
            label="Vehicle Type"
            //  id={props.VehicleTypeList.map(p => p.id)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.onHandleInputChange("vehicleType", event.target.value)
            }
            value={props.selectedVehicleType}
            error={props.VehicleTypeError}
            required={true}
            helperText={
              props.VehicleTypeError ? "Vehicle Type  is Required" : ""
            }
          >
            {props.VehicleTypeList.map((vehicleType) => (
              <MenuItem key={vehicleType.id} value={vehicleType.id}>
                {vehicleType.description}
              </MenuItem>
            ))}
          </StyledTextField>
        </Grid>
 
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", marginBlock: "1.5rem" }}>
        <CustomButton
          text="Show Vehicle"
          border="1px solid #6e6e6e"
          bgColor="#282828"
          onClick={() => {
            props.onShowVehicle();
          }}
        />


      </Box>



      <section className={style.gridSection}>
        <TableContainer component={Paper} className={style.grid}>
          <Table className={style.table}>
            <TableHead>
              <TableRow>
                <CustomHeaderCell
                  id="licensePlateNo"
                  
              
                >
                  License plate Number{" "}
                </CustomHeaderCell>
                <CustomHeaderCell
                  id="driverName" 
                >
                  Driver Name
                </CustomHeaderCell>
                <CustomHeaderCell
                  id="transportCompany"
                >
                  Transport Company
                </CustomHeaderCell>
                <CustomHeaderCell
                  id="seatCount"
                > 
               Maximum Seat Capacity
                </CustomHeaderCell>
                <CustomHeaderCell
                  id="avSeatCount"
                  
              
                >
                  Available Number of Seats 
                </CustomHeaderCell>
              
                <CustomHeaderCell
                  id="capacity"
                  
              
                >
                  Maximum CBM Capacity
                </CustomHeaderCell>
              </TableRow>
            </TableHead>
            {props.vehicleDetails && props.vehicleDetails.length > 0 && (
              <TableBody>
                {props.vehicleDetails.map((p) => (
                  <TableRow key={p.licensePlateNo}>
                    <StyledTableCell>{p.licensePlateNo}</StyledTableCell>
                    <StyledTableCell>{p.driverName}</StyledTableCell>
                    <StyledTableCell>{p.transportCompany}</StyledTableCell>
                    <StyledTableCell>{p.seatCount}</StyledTableCell>
                    <StyledTableCell>{Number(p.seatCount)-props.passengerCount}</StyledTableCell>
                    <StyledTableCell>{p.capacity}</StyledTableCell>
                   
                  </TableRow>
                ))}
              </TableBody>
            )}
            {props.vehicleDetails.length === 0 && (
              <TableBody>
                <TableRow>
                  <StyledTableCell align="center" colSpan={7}>
                    No data to preview
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </section>
 </BootstrapDialogContent>
 </BootstrapDialog>
  )
}

export default TmRequestApprovalViewAvaliBilityPopUp