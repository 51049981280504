import { axiosPrivateInstance } from ".";
import { RequestByIdDto, SelfRequestSummaryListDto, RequestSummaryParamDto, LMRequestSummaryListDto, TMRequestSummaryListDto, SbuBriefDto, PlantBriefDto, DepartmentBriefDto, RequestStatusProcessDto, LMRequestApprovalParamDto, TMRequestApprovalParamDto, DateRangeDto, requestHistory, DateRangeParmDto, LmRequestSummaryParamDto, TmRequestSummaryParamDto, filterationParam } from "../utilities/models";
import { AxiosResponse } from "axios";

const createNewRequest = (payload: any): Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.post('/api/Request/CreateRequest', payload)
}

const createNewReccurentRequest = (payload: any): Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.post('/api/Request/CreateReccurrentRequest', payload)
}

const editRequest = (payload: any): Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.put(`/api/Request/ModifyRejectedRequest?id=${payload.id}`, payload)
}

const deleteRequest = (id: number): Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.put(`/api/Request/DeleteRequest?requestId=${id}`)
}

const getRequestById = (requestId: string): Promise<AxiosResponse<RequestByIdDto>> => {
    return axiosPrivateInstance.get(`/api/Request/GetRequestById?requestId=${requestId}`)
}

const getSelfRequestSummaryList = (param: RequestSummaryParamDto): Promise<AxiosResponse<SelfRequestSummaryListDto[]>> => {
    return axiosPrivateInstance.get(`/api/Request/GetSelfRequestSummery?pagination=${param.pagination}&pageNumber=${param.pageNumber}`)
}

const getLMRequestSummaryList = (param: LmRequestSummaryParamDto ): Promise<AxiosResponse<LMRequestSummaryListDto[]>> => {
    return axiosPrivateInstance.post(`/api/Request/GetLMRequestSummery?pagination=${param.pagination}&pageNumber=${param.pageNumber}${param.pending ? '&pending=' + param.pending : ''}`, param.filter)
}

const getLMRequestSummaryFilters = (param:filterationParam):Promise<AxiosResponse<any[]>> => {
    return axiosPrivateInstance.get(`/api/Request/GetLMRequestSummaryFiltration?pending=${param.pending}`);
}

const getTMRequestSummaryList = (param: TmRequestSummaryParamDto): Promise<AxiosResponse<TMRequestSummaryListDto[]>> => {
    return axiosPrivateInstance.post(`/api/Request/GetTMRequestSummery?pagination=${param.pagination}&pageNumber=${param.pageNumber}${param.pending ? '&pending=' + param.pending : ''}`, param.filter)
}

const getTMRequestSummaryFilters = (param:filterationParam):Promise<AxiosResponse<any[]>> => {
    return axiosPrivateInstance.get(`/api/Request/GetTMRequestSummaryFiltration?pending=${param.pending}`);
}

const getSbuList = (): Promise<AxiosResponse<SbuBriefDto[]>> => {
    return axiosPrivateInstance.get(`/api/Request/GetSBUList`)
}

const getPlantListBySbuId = (id: number): Promise<AxiosResponse<PlantBriefDto[]>> => {
    return axiosPrivateInstance.get(`/api/Request/GetPlantList/Id?SBUId=${id}`)
}

const getDepartmentListByPlantId = (id: number): Promise<AxiosResponse<DepartmentBriefDto[]>> => {
    return axiosPrivateInstance.get(`/api/Request/GetPlantList/Id?SBUId=${id}`)
}

const getRequestStatusProcess = (id: string): Promise<AxiosResponse<RequestStatusProcessDto[]>> => {
    return axiosPrivateInstance.get(`/api/Request/GetRequestStatusProcess?requestId=${id}`)
}

const lineManagerApproval = (payload: LMRequestApprovalParamDto): Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.post(`/api/Request/LineManagerApproval`, payload)
}

const transportManagerApproval = (payload: TMRequestApprovalParamDto): Promise<AxiosResponse<string>> => {
    return axiosPrivateInstance.post(`/api/Request/TransportManagerApproval`, payload)
}

const getRequestHistory = (payload: DateRangeParmDto): Promise<AxiosResponse<requestHistory[]>> => {
    return axiosPrivateInstance.get(`/api/Request/GetRequestHistory?fromDate=${payload.fromDate}&toDate=${payload.toDate}&isAll=${payload.isAll}`)
}


export const requestService = {
    createNewRequest,
    createNewReccurentRequest,
    editRequest,
    deleteRequest,
    getRequestById,
    getSelfRequestSummaryList,
    getLMRequestSummaryList,
    getLMRequestSummaryFilters,
    getTMRequestSummaryFilters,
    getTMRequestSummaryList,
    getSbuList,
    getPlantListBySbuId,
    getDepartmentListByPlantId,
    getRequestStatusProcess,
    lineManagerApproval,
    transportManagerApproval,
    getRequestHistory,
}