import React from 'react'
import { BootstrapDialog, BootstrapDialogActions, BootstrapDialogContent, BootstrapDialogTitle, StyledTextField } from '../../../assets/theme/theme'
import styles from './CancleAllocationPopup.module.scss'
import { Grid, Typography } from '@mui/material'
import { CustomButton } from '../../Shared'

const CancelAllocationPopup: React.FC<{
  OnCancelAllocationPopUPClose(): void;
  isOpenCancelAllocationPopup: boolean;
  reason: string;
  setReason(value: string): void;
  onCallback(value: boolean, reason: string): void
}> = (props) => {
  return (
    <BootstrapDialog
      width={"600px"}
      className={styles.dialogCard}
      aria-labelledby="customized-dialog-title"
      open={props.isOpenCancelAllocationPopup}
    >
      <BootstrapDialogTitle id="customized-dialog-title"
        onClose={() => { props.OnCancelAllocationPopUPClose() }}>
        Cancel Allocation
      </BootstrapDialogTitle>
      <BootstrapDialogContent>
        <Typography sx={{ marginBottom: "1rem" }}>
          <h3>Are you sure you want to cancel?</h3>
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} className={styles.formBox}>
            <StyledTextField
              fullWidth
              placeholder="If Yes Please Enter A Reason"
              size="medium"
              
              value={props.reason}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.setReason(event.target.value)}
            />
          </Grid>
        </Grid>
      </BootstrapDialogContent>
      <BootstrapDialogActions>
        <CustomButton
          text={"Cancel"}
          border="1px solid #6e6e6e"
          bgColor="#282828"
          onClick={() => { props.onCallback(false, props.reason) }}
        />
        <CustomButton
          text={"Confirm"}
          disabled={props.reason === ""}
          onClick={() => { props.onCallback(true, props.reason) }}
        />
      </BootstrapDialogActions>
    </BootstrapDialog>
  )
}

export default CancelAllocationPopup