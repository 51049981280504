import React, { useEffect, useState } from 'react'
import { AppLayout } from '../../templates'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ApplicationStateDto, SortMetaDto, VehicleDto, getDetailsSummaryParam } from '../../utilities/models'
import { APP_ACTION_STATUS, APP_ROUTES, APP_TABLE_CONFIGS, VEHICLE_SCREEN_MODES } from '../../utilities/constants'
import { vehicleActions } from '../../redux/actions'
import moment from 'moment'
import { ConfirmationDialog, VehicleManagementGrid } from '../../components'
import dayjs from 'dayjs'

const VehicleManagement = () => {
  const INITIAL_SORT_META: SortMetaDto = {
    field: "",
    asc: false,
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
  const [sortMeta, setSortMeta] = useState<SortMetaDto>(INITIAL_SORT_META);
  const [filteredList, setFilteredList] = useState<VehicleDto[]>([])
  const [isFiltered, setIsFiltered] = useState(false)
  const [selectedVehicleId, setSelectedVehicleId] = useState(-1)
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false)

  const getVehiclesListResponse = useSelector((state: ApplicationStateDto) => state.vehicle.getVehicles)
  const deleteVehicleResponse = useSelector((state: ApplicationStateDto) => state.vehicle.deleteVehicle)
  
  // useEffect(() => {
  //   getVehiclesList
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    if (getVehiclesListResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if (getVehiclesListResponse.data.length > 0)
        setFilteredList(getVehiclesListResponse.data)
      else setFilteredList([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getVehiclesListResponse.status])

  useEffect(() => {
    dispatch(vehicleActions.getVehicleIncidentsClear())
    getVehiclesList(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE, 1);
  }, []);

  useEffect(() => {
    if (deleteVehicleResponse.status === APP_ACTION_STATUS.SUCCESS) {
      getVehiclesList(rowsPerPage, 1);
      dispatch(vehicleActions.deleteVehicleClear())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteVehicleResponse.status])

  const getVehiclesList = async (r: number, p: number) => {
    const _param: getDetailsSummaryParam = { pagination: r, pageNumber: p, };
    dispatch(vehicleActions.getVehicles(_param))
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    getVehiclesList(rowsPerPage, newPage + 1)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    getVehiclesList(+event.target.value, 1)
    setPage(0)
  }
  
  const onClearFilter = () => {
    setIsFiltered(false)
    setFilteredList(getVehiclesListResponse.data)
  }

  const onSelectVehicleForRemove = (id: number) => {
    setSelectedVehicleId(id)
    setIsOpenConfirmation(true)
  }

  const onRemoveVehicle = (con: boolean) => {
    setIsOpenConfirmation(false)
    if(con) {
      dispatch(vehicleActions.deleteVehicle(selectedVehicleId))
    }
  }

  const onNavigate = (mode: string, id: number) => {
    if(mode === VEHICLE_SCREEN_MODES.CREATE)
    navigate(APP_ROUTES.CREATE_VEHICLE, { state: { mode: mode } })
    if(mode === VEHICLE_SCREEN_MODES.EDIT){
      const _vehicle = filteredList.find((v) => v.id === id)
      navigate(APP_ROUTES.EDIT_VEHICLE, { state: { mode: mode, vehicle: _vehicle } })
    }
    if(mode === VEHICLE_SCREEN_MODES.VIEW){
      const _vehicle = filteredList.find((v) => v.id === id)
      navigate(APP_ROUTES.VIEW_VEHICLE, { state: { mode: mode, vehicle: _vehicle } })
    }
  }

  const onSortHandle = (col: string) => {
    const sorted = filteredList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setFilteredList(sorted);
  };

  const onFilterHandle = (col: string, value: string) => {
    setIsFiltered(true)
    setPage(0)
    const _list = getVehiclesListResponse.data
    const filtered = _list.filter((item) => {
      const _value = (item as any)[col];
      if (typeof _value === "boolean") {
        return _value ? value === "Yes" : value === "No";
      }
      if (col === "createdDateandTime" || col === "departureDateandTime" || col === "returnDateandTime") {
        const _selectedMin = dayjs(value[0]).format('YYYY-MM-DD HH:mm')
        const _selectedMax = dayjs(value[1]).format('YYYY-MM-DD HH:mm')
        const _targetDate = dayjs(_value).format('YYYY-MM-DD HH:mm')

        return moment(_targetDate).isBetween(_selectedMin, _selectedMax)
      }
      return _value === value;
    });

    setFilteredList(filtered);
  };

  const getFilterList = (col: string): string[] => {
    if (!getVehiclesListResponse.isLoading) {
      const _list = getVehiclesListResponse.data
      return _list
        .map((item) => {
          const value = (item as any)[col];
          if (typeof value === "boolean") {
            return value ? "Yes" : "No";
          }
          return value;
        })
        .filter((value, index, array) => array.indexOf(value) === index);
    }
    else return []
  };

  return (
    <React.Fragment>
      <AppLayout componentTitle="Vehicle Management">
        <section className="page-root">
          <VehicleManagementGrid
            page={page}
            rowsPerPage={rowsPerPage}
            onHandleChangePage={handleChangePage}
            onHandleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={getVehiclesListResponse.isLoading}
            filteredList={filteredList || []}
            onSortHandle={onSortHandle}
            onFilterHandle={onFilterHandle}
            getFilterList={getFilterList}
            onClearFilter={onClearFilter}
            isFiltered={isFiltered}
            navigateTo={onNavigate}
            onSelectVehicleForRemove={onSelectVehicleForRemove}
          />
          <ConfirmationDialog
            isOpenConfirmationDialog={isOpenConfirmation}
            onCallback={onRemoveVehicle}
            title="Delete Vehicle"
            content="Do you want to delete this vehicle ?"
            confirmButtonTitle="Yes"
            cancelButtonTitle="No"
          />
        </section>
      </AppLayout>
    </React.Fragment>
  )
}

export default VehicleManagement
