import React from 'react'
import Stepper from '../../Shared/Stepper/Stepper'
import style from './ProjectedRout.module.scss'
import { Grid, Box } from '@mui/material'
import { StyledTextField } from '../../../assets/theme/theme'
import { CustomButton } from '../../Shared'
import GoogleMap from '../../Shared/GoogleMap/GoogleMap'
import { vehicleDetailsListDto } from '../../../utilities/models'
import GoogleMapV2 from '../../Shared/GoogleMapV2/GoogleMapV2'

const ProjectedRout: React.FC<{
  // handleAllocateClick(): void,
  // handleCancelClick(): void,
  // handleModificationClick():void,
  // isEdit:boolean;
  vehicleDetails: vehicleDetailsListDto[];
  IsView:boolean,
  totalDistance:number
}> = (props) => {
  let actualCost;
  if( props.IsView){
    actualCost =props.vehicleDetails[0]?.actualMileage||0
  }


  return (
    <Stepper stepNumber={4} stepTitle={"Projected Route & Mileage"}>
      <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
          <StyledTextField
             label="Projected Mileage(km)"
            fullWidth
            placeholder="Projected Mileage(km)"
            size="small"
            value={props.totalDistance !== null ? `${props.totalDistance.toFixed(2)} ` : ""}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        {props.IsView &&
        <Grid item xs={12} md={6}>
          <StyledTextField
             label="Actual Mileage(km)"
            fullWidth
            placeholder="Actual Mileage(km)"
            size="small"
            value={actualCost}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        }
      </Grid>
      <Grid sx={{marginTop:"2rem"}}>
      {props.IsView ? (
        // Render one component when isView is true
        <GoogleMapV2/>
      ) : (
        // Render another component when isView is false
        <GoogleMap/>
      )}
                  

                
             </Grid>
    </Stepper>
  )
}

export default ProjectedRout