import React from 'react'
import Stepper from '../../Shared/Stepper/Stepper'
import CustomDatePicker from '../../Shared/CustomDatePicker/CustomDatePicker'
import Grid from '@mui/material/Grid/Grid'
import { DsExecutionInformationDto } from '../../../utilities/models/shift.model'

const ExecutionInformation:React.FC<{
    dsExecutionInformationForm:DsExecutionInformationDto;
    handleInputFocus(property: string, section: string): void
    onInputHandleChangeExecInformation(property: string, section: string): void
    helperText: boolean
}> = (props) => {
    const _executeDate = props.dsExecutionInformationForm.executeDate; 

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

  return (
    <Stepper stepNumber={0} stepTitle={"Execution Information"}>
        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <CustomDatePicker
                    label="Execution date"
                    placeholder='Select Date'
                    multiple={false}
                    minDate={yesterday} 
                    maxDate={tomorrow} 
                    value={_executeDate.value}       
                    error={!!_executeDate.error}
                    disabled={_executeDate.disable}
                    readOnly={_executeDate.readonly}
                    required={_executeDate.isRequired}
                    helperText={props.helperText && _executeDate.error}
                    onFocus={() => props.handleInputFocus('executionDate', 'EI')}
                    onChange={(value: any) => props.onInputHandleChangeExecInformation('executionDate', value)} 
                />
            </Grid>
        </Grid>
    </Stepper>
  )
}

export default ExecutionInformation