import { APP_ACTION_STATUS, COMMON_ACTION_TYPES, TRANSPORT_ACTION_TYPE, TRANSPORT_ACTION_TYPES, TRANSPORT_ACTION_TYPES_BANKACC, TRANSPORT_ACTION_TYPES_OTHERDOC } from "../../utilities/constants";
import { TransportStateDto } from "../../utilities/models";
import { ADD_TO_TABLE  } from "../actions/transport.action";
import dayjs, { Dayjs } from "dayjs";
const INITIAL_STATE: TransportStateDto = {
  UpdateTransportCompanyById:{
    data: "",
    isLoading: false,
    error: null,
    status: null
  },
  getTransportCompanyByID:{
    data: null,
    isLoading: false,
    error: null,
    status: null
  },
  RegisterTransportCompany:{
    data: null,
    isLoading: false,
    error: null,
    status: null
  },
  getBranchList:{
    data: [],
    isLoading: false,
    error: null,
    status: null
  },
  getBankList:{
    data: [],
    isLoading: false,
    error: null,
    status: null
      },
  GetPaymentMethods:{
    data: [],
    isLoading: false,
    error: null,
    status: null
  },
  getAllCompanyDetails:{
    data: [],
    isLoading: false,
    error: null,
    status: null
  },
    RemoveDocument:{
      data:null,
      error: null,
      isLoading: false,
      status: null,

    },
    uploadFileTransportOtherDoc:{
      data: null,
      error: null,
      isLoading: false,
      status: null,

    },
    GetRequiredDocument:{
      data: null,
      error: null,
      isLoading: false,
      status: null,

    },
    uploadFileTransportBankAcc: {
      data: null,
      error: null,
      isLoading: false,
      status: null,
    },
    uploadFileTransport: {
      data: null,
      error: null,
      isLoading: false,
      status: null,
    },
    deleteFileTransport: {
      data: null,
      error: null,
      isLoading: false,
      status: null,
    },
   
    getAllCompanies: {
      data: [],
      error: null,
      isLoading: false,
      status: null,
    },
    getAllCompaniesForm: {
      data: [],
      error: null,
      isLoading: false,
      status: null,
    },
    // ViewAllTransportCompanies: {
    //   data: [],
    //   error: null,
    //   isLoading: false,
    //   status: null,
    // },
    deletecompany: {
      data: {
        
        transCompany: "",
        registeredNumber: "",
        attachedSBU: "",
        telephoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        district: "",
        province: "",
        postalCode: "",
        email: "",
        paymentMethodDetails: [],
        companyDocuments: [],
        registeredDate: "",
     

    },
      error: null,
      isLoading: false,
      status: null,
    },

    editCompany: {
      data: {
        transCompany: "",
        registeredNumber: "",
        registeredDate: "",
        telephoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        district: "",
        province: "",
        postalCode: "",
        email: "",
        paymentMethodDetails: [],
        companyDocuments:[],

    },
      error: null,
      isLoading: false,
      status: null,
    },
    
   
    AddTransportCompany: {
      data: {
          transCompany: "",
          registeredNumber: "",
          attachedSBU: 0,
          telephoneNumber: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          district: "",
          province: "",
          postalCode: "",
          email: "",
          paymentMethodDetails: [],
          companyDocuments:[],
          registeredDate: "",
        
  
      },
      error: null,
      isLoading: false,
      status: null,
    },

    getRequestBYIdCompany:{
      data: {
        transCompany: "",
        registeredNumber: "",
        registeredDate: "",
        
        attachedSBU: [
          {
            id:0,
            name:"",
          }
        ],
        telephoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        district: "",
        province: "",
        postalCode: "",
        email: "",
        status: true,
        paymentMethodDetails: [
          {
            currency:"",
            paymentMethod: "",
            bank: "",
            branch: "",
            beneficiaryName: "",
            accountNumber: "",
            isActive: false,
            issuedDate: "",
            
          }
         
        ],
        companyDocuments:[
          {
          
            documentId: 0,
          documentName: "",
          issuedDate: "",
          expiryDate: "",
        }
        ],
    },
    error: null,
    isLoading: false,
    status: null,
    },

   
   
  };
  
const transportReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
      case TRANSPORT_ACTION_TYPE.GET_ALL_TRANSPORT_COMPANIES + COMMON_ACTION_TYPES.REQUEST:
        return {
          ...state,
          getAllCompanies: {
            ...state.getAllCompanies,
            isLoading: true,
            status: APP_ACTION_STATUS.LOADING,
            error: null,
            data: null,
          },
        };
      case TRANSPORT_ACTION_TYPE.GET_ALL_TRANSPORT_COMPANIES + COMMON_ACTION_TYPES.SUCCESS:
        return {
          ...state,
          getAllCompanies: {
            ...state.getAllCompanies,
            isLoading: false,
            status: APP_ACTION_STATUS.SUCCESS,
            error: null,
            data: action.data,
          },
        };
      case TRANSPORT_ACTION_TYPE.GET_ALL_TRANSPORT_COMPANIES + COMMON_ACTION_TYPES.ERROR:
        return {
          ...state,
          getAllCompanies: {
            ...state.getAllCompanies,
            isLoading: false,
            status: APP_ACTION_STATUS.ERROR,
            error: action.error,
            data: [],
          },
        };
      case TRANSPORT_ACTION_TYPE.GET_ALL_TRANSPORT_COMPANIES + COMMON_ACTION_TYPES.CLEAR:
        return {
          ...state,
          getAllCompanies: {
            ...state.getAllCompanies,
            isLoading: false,
            status: APP_ACTION_STATUS.INITIAL,
            error: null,
            data: null,
          },
        };



        //add
        // eslint-disable-next-line no-duplicate-case
        case TRANSPORT_ACTION_TYPE.GET_ALL_TRANSPORT_COMPANIES + COMMON_ACTION_TYPES.REQUEST:
          return {
            ...state,
            getAllCompaniesForm: {
              ...state.getAllCompaniesForm,
              isLoading: true,
              status: APP_ACTION_STATUS.LOADING,
              error: null,
              data: null,
            },
          };
        // eslint-disable-next-line no-duplicate-case
        case TRANSPORT_ACTION_TYPE.GET_ALL_TRANSPORT_COMPANIES + COMMON_ACTION_TYPES.SUCCESS:
          return {
            ...state,
            getAllCompaniesForm: {
              ...state.getAllCompaniesForm,
              isLoading: false,
              status: APP_ACTION_STATUS.SUCCESS,
              error: null,
              data: action.data,
            },
          };
        // eslint-disable-next-line no-duplicate-case
        case TRANSPORT_ACTION_TYPE.GET_ALL_TRANSPORT_COMPANIES + COMMON_ACTION_TYPES.ERROR:
          return {
            ...state,
            getAllCompaniesForm: {
              ...state.getAllCompaniesForm,
              isLoading: false,
              status: APP_ACTION_STATUS.ERROR,
              error: action.error,
              data: null,
            },
          };
        // eslint-disable-next-line no-duplicate-case
        case TRANSPORT_ACTION_TYPE.GET_ALL_TRANSPORT_COMPANIES + COMMON_ACTION_TYPES.CLEAR:
          return {
            ...state,
            getAllCompaniesForm: {
              ...state.getAllCompaniesForm,
              isLoading: false,
              status: APP_ACTION_STATUS.INITIAL,
              error: null,
              data: null,
            },
          };
  
        



//view company

        case TRANSPORT_ACTION_TYPE.VIEW_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.REQUEST:
        return {
          ...state,
          getRequestBYIdCompany: {
            ...state.getRequestBYIdCompany,
            isLoading: true,
            status: APP_ACTION_STATUS.LOADING,
            error: null,
            data: null,
          },
        };
      case TRANSPORT_ACTION_TYPE.VIEW_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.SUCCESS:
        return {
          ...state,
          getRequestBYIdCompany: {
            ...state.getRequestBYIdCompany,
            isLoading: false,
            status: APP_ACTION_STATUS.SUCCESS,
            error: null,
            data: action.data,
          },
        };
      case TRANSPORT_ACTION_TYPE.VIEW_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.ERROR:
        return {
          ...state,
          getRequestBYIdCompany: {
            ...state.getRequestBYIdCompany,
            isLoading: false,
            status: APP_ACTION_STATUS.ERROR,
            error: action.error,
            data: null,
          },
        };
      case TRANSPORT_ACTION_TYPE.VIEW_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.CLEAR:
        return {
          ...state,
          getRequestBYIdCompany: {
            ...state.getRequestBYIdCompany,
            isLoading: false,
            status: APP_ACTION_STATUS.INITIAL,
            error: null,
            data: null,
          },
        };

//Delete vehicle

case TRANSPORT_ACTION_TYPE.DELETE_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.REQUEST:
  return {
    ...state,
    deletecompany: {
      ...state.deletecompany,
      isLoading: true,
      status: APP_ACTION_STATUS.LOADING,
      error: null,
      data: null,
    },
  };
case TRANSPORT_ACTION_TYPE.DELETE_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.SUCCESS:
  return {
    ...state,
    deletecompany: {
      ...state.deletecompany,
      isLoading: false,
      status: APP_ACTION_STATUS.SUCCESS,
      error: null,
      data: action.data,
    },
  };
case TRANSPORT_ACTION_TYPE.DELETE_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.ERROR:
  return {
    ...state,
    deletecompany: {
      ...state.deletecompany,
      isLoading: false,
      status: APP_ACTION_STATUS.ERROR,
      error: action.error,
      data: null,
    },
  };
case TRANSPORT_ACTION_TYPE.DELETE_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.CLEAR:
  return {
    ...state,
    deletecompany: {
      ...state.deletecompany,
      isLoading: false,
      status: APP_ACTION_STATUS.INITIAL,
      error: null,
      data: null,
    },
  };
//edit

  case TRANSPORT_ACTION_TYPE.EDIT_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        editCompany: {
          ...state.editCompany,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case TRANSPORT_ACTION_TYPE.EDIT_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        editCompany: {
          ...state.editCompany,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case TRANSPORT_ACTION_TYPE.EDIT_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        editCompany: {
          ...state.editCompany,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case TRANSPORT_ACTION_TYPE.EDIT_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        editCompany: {
          ...state.editCompany,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

  //file upload companyReg

  case TRANSPORT_ACTION_TYPES.TRANSPORT_UPLOAD_FILE + COMMON_ACTION_TYPES.REQUEST:
            return {
              ...state,
              uploadFileTransport: {
                ...state.uploadFileTransport,
                isLoading: true,
                status: APP_ACTION_STATUS.LOADING,
                error: null,
                data: null,
              },
            };
          case TRANSPORT_ACTION_TYPES.TRANSPORT_UPLOAD_FILE + COMMON_ACTION_TYPES.SUCCESS:
            return {
              ...state,
              uploadFileTransport: {
                ...state.uploadFileTransport,
                isLoading: false,
                status: APP_ACTION_STATUS.SUCCESS,
                error: null,
                data: action.data,
              },
            };
          case TRANSPORT_ACTION_TYPES.TRANSPORT_UPLOAD_FILE + COMMON_ACTION_TYPES.ERROR:
            return {
              ...state,
              uploadFileTransport: {
                ...state.uploadFileTransport,
                isLoading: false,
                status: APP_ACTION_STATUS.ERROR,
                error: action.error,
                data: null,
              },
            };
          case TRANSPORT_ACTION_TYPES.TRANSPORT_UPLOAD_FILE + COMMON_ACTION_TYPES.CLEAR:
            return {
              ...state,
              uploadFileTransport: {
                ...state.uploadFileTransport,
                isLoading: false,
                status: APP_ACTION_STATUS.INITIAL,
                error: null,
                data: null,
              },
            };

  //file upload company bank account

  case TRANSPORT_ACTION_TYPES_BANKACC.TRANSPORT_BANKACC_UPLOAD_FILE + COMMON_ACTION_TYPES.REQUEST:
            return {
              ...state,
              uploadFileTransportBankAcc: {
                ...state.uploadFileTransportBankAcc,
                isLoading: true,
                status: APP_ACTION_STATUS.LOADING,
                error: null,
                data: null,
              },
            };
          case TRANSPORT_ACTION_TYPES_BANKACC.TRANSPORT_BANKACC_UPLOAD_FILE + COMMON_ACTION_TYPES.SUCCESS:
            return {
              ...state,
              uploadFileTransportBankAcc: {
                ...state.uploadFileTransportBankAcc,
                isLoading: false,
                status: APP_ACTION_STATUS.SUCCESS,
                error: null,
                data: action.data,
              },
            };
          case TRANSPORT_ACTION_TYPES_BANKACC.TRANSPORT_BANKACC_UPLOAD_FILE + COMMON_ACTION_TYPES.ERROR:
            return {
              ...state,
              uploadFileTransportBankAcc: {
                ...state.uploadFileTransportBankAcc,
                isLoading: false,
                status: APP_ACTION_STATUS.ERROR,
                error: action.error,
                data: null,
              },
            };
          case TRANSPORT_ACTION_TYPES_BANKACC.TRANSPORT_BANKACC_UPLOAD_FILE + COMMON_ACTION_TYPES.CLEAR:
            return {
              ...state,
              uploadFileTransportBankAcc: {
                ...state.uploadFileTransportBankAcc,
                isLoading: false,
                status: APP_ACTION_STATUS.INITIAL,
                error: null,
                data: null,
              },
            };

  //file upload in other doc

  case TRANSPORT_ACTION_TYPES_OTHERDOC.TRANSPORT_OTHERDOC_UPLOAD_FILE + COMMON_ACTION_TYPES.REQUEST:
            return {
              ...state,
              uploadFileTransportOtherDoc: {
                ...state.uploadFileTransportOtherDoc,
                isLoading: true,
                status: APP_ACTION_STATUS.LOADING,
                error: null,
                data: null,
              },
            };
          case TRANSPORT_ACTION_TYPES_OTHERDOC.TRANSPORT_OTHERDOC_UPLOAD_FILE + COMMON_ACTION_TYPES.SUCCESS:
            return {
              ...state,
              uploadFileTransportOtherDoc: {
                ...state.uploadFileTransportOtherDoc,
                isLoading: false,
                status: APP_ACTION_STATUS.SUCCESS,
                error: null,
                data: action.data,
              },
            };
          case TRANSPORT_ACTION_TYPES_OTHERDOC.TRANSPORT_OTHERDOC_UPLOAD_FILE + COMMON_ACTION_TYPES.ERROR:
            return {
              ...state,
              uploadFileTransportOtherDoc: {
                ...state.uploadFileTransportOtherDoc,
                isLoading: false,
                status: APP_ACTION_STATUS.ERROR,
                error: action.error,
                data: null,
              },
            };
          case TRANSPORT_ACTION_TYPES_OTHERDOC.TRANSPORT_OTHERDOC_UPLOAD_FILE + COMMON_ACTION_TYPES.CLEAR:
            return {
              ...state,
              uploadFileTransportOtherDoc: {
                ...state.uploadFileTransportOtherDoc,
                isLoading: false,
                status: APP_ACTION_STATUS.INITIAL,
                error: null,
                data: null,
              },
            };
            
  //end

  case TRANSPORT_ACTION_TYPES.TRANSPORT_GET_REQUIRED_DOCUMENT + COMMON_ACTION_TYPES.REQUEST:
    return {
      ...state,
      GetRequiredDocument: {
        ...state.GetRequiredDocument,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: null,
        data: null,
      },
    };
  case TRANSPORT_ACTION_TYPES.TRANSPORT_GET_REQUIRED_DOCUMENT + COMMON_ACTION_TYPES.SUCCESS:
    return {
      ...state,
      GetRequiredDocument: {
        ...state.GetRequiredDocument,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        error: null,
        data: action.data,
      },
    };
  case TRANSPORT_ACTION_TYPES.TRANSPORT_GET_REQUIRED_DOCUMENT + COMMON_ACTION_TYPES.ERROR:
    return {
      ...state,
      GetRequiredDocument: {
        ...state.GetRequiredDocument,
        isLoading: false,
        status: APP_ACTION_STATUS.ERROR,
        error: action.error,
        data: null,
      },
    };
  case TRANSPORT_ACTION_TYPES.TRANSPORT_GET_REQUIRED_DOCUMENT + COMMON_ACTION_TYPES.CLEAR:
    return {
      ...state,
      GetRequiredDocument: {
        ...state.GetRequiredDocument,
        isLoading: false,
        status: APP_ACTION_STATUS.INITIAL,
        error: null,
        data: null,
      },
    };





    case TRANSPORT_ACTION_TYPES.TRANSPORT_REMOVE_DOCUMENT + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        RemoveDocument: {
          ...state.RemoveDocument,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case TRANSPORT_ACTION_TYPES.TRANSPORT_REMOVE_DOCUMENT + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        RemoveDocument: {
          ...state.RemoveDocument,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case TRANSPORT_ACTION_TYPES.TRANSPORT_REMOVE_DOCUMENT + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        RemoveDocument: {
          ...state.RemoveDocument,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case TRANSPORT_ACTION_TYPES.TRANSPORT_REMOVE_DOCUMENT + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        RemoveDocument: {
          ...state.RemoveDocument,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };

/////added new

case TRANSPORT_ACTION_TYPES.GET_ALL_TRANSPORT_COMPANY_DETAILS + COMMON_ACTION_TYPES.REQUEST:
  return {
    ...state,
    getAllCompanyDetails: {
      ...state.getAllCompanyDetails,
      isLoading: true,
      status: APP_ACTION_STATUS.LOADING,
      error: null,
      data: null,
    },
  };
case TRANSPORT_ACTION_TYPES.GET_ALL_TRANSPORT_COMPANY_DETAILS + COMMON_ACTION_TYPES.SUCCESS:
  return {
    ...state,
    getAllCompanyDetails: {
      ...state.getAllCompanyDetails,
      isLoading: false,
      status: APP_ACTION_STATUS.SUCCESS,
      error: null,
      data: action.data,
    },
  };
case TRANSPORT_ACTION_TYPES.GET_ALL_TRANSPORT_COMPANY_DETAILS + COMMON_ACTION_TYPES.ERROR:
  return {
    ...state,
    getAllCompanyDetails: {
      ...state.getAllCompanyDetails,
      isLoading: false,
      status: APP_ACTION_STATUS.ERROR,
      error: action.error,
      data: [],
    },
  };
case TRANSPORT_ACTION_TYPES.GET_ALL_TRANSPORT_COMPANY_DETAILS + COMMON_ACTION_TYPES.CLEAR:
  return {
    ...state,
    getAllCompanyDetails: {
      ...state.getAllCompanyDetails,
      isLoading: false,
      status: APP_ACTION_STATUS.INITIAL,
      error: [],
      data: null,
    },
  };
  case TRANSPORT_ACTION_TYPES.GET_PAYMENT_METHODS + COMMON_ACTION_TYPES.REQUEST:
    return {
      ...state,
      GetPaymentMethods: {
        ...state.GetPaymentMethods,
        isLoading: true,
        status: APP_ACTION_STATUS.LOADING,
        error: null,
        data: null,
      },
    };
  case TRANSPORT_ACTION_TYPES.GET_PAYMENT_METHODS + COMMON_ACTION_TYPES.SUCCESS:
    return {
      ...state,
      GetPaymentMethods: {
        ...state.GetPaymentMethods,
        isLoading: false,
        status: APP_ACTION_STATUS.SUCCESS,
        error: null,
        data: action.data,
      },
    };
  case TRANSPORT_ACTION_TYPES.GET_PAYMENT_METHODS + COMMON_ACTION_TYPES.ERROR:
    return {
      ...state,
      GetPaymentMethods: {
        ...state.GetPaymentMethods,
        isLoading: false,
        status: APP_ACTION_STATUS.ERROR,
        error: action.error,
        data: [],
      },
    };
  case TRANSPORT_ACTION_TYPES.GET_PAYMENT_METHODS + COMMON_ACTION_TYPES.CLEAR:
    return {
      ...state,
      GetPaymentMethods: {
        ...state.GetPaymentMethods,
        isLoading: false,
        status: APP_ACTION_STATUS.INITIAL,
        error: [],
        data: null,
      },
    };
    case TRANSPORT_ACTION_TYPES.GTE_BANK_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getBankList: {
          ...state.getBankList,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case TRANSPORT_ACTION_TYPES.GTE_BANK_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getBankList: {
          ...state.getBankList,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case TRANSPORT_ACTION_TYPES.GTE_BANK_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getBankList: {
          ...state.getBankList,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case TRANSPORT_ACTION_TYPES.GTE_BANK_LIST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getBankList: {
          ...state.getBankList,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: [],
          data: null,
        },
      };
      case TRANSPORT_ACTION_TYPES.GTE_BRANCH_LIST + COMMON_ACTION_TYPES.REQUEST:
        return {
          ...state,
          getBranchList: {
            ...state.getBranchList,
            isLoading: true,
            status: APP_ACTION_STATUS.LOADING,
            error: null,
            data: null,
          },
        };
      case TRANSPORT_ACTION_TYPES.GTE_BRANCH_LIST + COMMON_ACTION_TYPES.SUCCESS:
        return {
          ...state,
          getBranchList: {
            ...state.getBranchList,
            isLoading: false,
            status: APP_ACTION_STATUS.SUCCESS,
            error: null,
            data: action.data,
          },
        };
      case TRANSPORT_ACTION_TYPES.GTE_BRANCH_LIST + COMMON_ACTION_TYPES.ERROR:
        return {
          ...state,
          getBranchList: {
            ...state.getBranchList,
            isLoading: false,
            status: APP_ACTION_STATUS.ERROR,
            error: action.error,
            data: [],
          },
        };
      case TRANSPORT_ACTION_TYPES.GTE_BRANCH_LIST + COMMON_ACTION_TYPES.CLEAR:
        return {
          ...state,
          getBranchList: {
            ...state.getBranchList,
            isLoading: false,
            status: APP_ACTION_STATUS.INITIAL,
            error: [],
            data: null,
          },
        };
      
      case TRANSPORT_ACTION_TYPES.ADD_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.REQUEST:
        return {
          ...state,
          RegisterTransportCompany: {
            ...state.RegisterTransportCompany,
            isLoading: true,
            status: APP_ACTION_STATUS.LOADING,
            error: null,
            data: null,
          },
        };
      case TRANSPORT_ACTION_TYPES.ADD_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.SUCCESS:
        return {
          ...state,
          RegisterTransportCompany: {
            ...state.RegisterTransportCompany,
            isLoading: false,
            status: APP_ACTION_STATUS.SUCCESS,
            error: null,
            data: action.data,
          },
        };
      case TRANSPORT_ACTION_TYPES.ADD_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.ERROR:
        return {
          ...state,
          RegisterTransportCompany: {
            ...state.RegisterTransportCompany,
            isLoading: false,
            status: APP_ACTION_STATUS.ERROR,
            error: action.error,
            data: null,
          },
        };
      case TRANSPORT_ACTION_TYPES.ADD_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.CLEAR:
        return {
          ...state,
          RegisterTransportCompany: {
            ...state.RegisterTransportCompany,
            isLoading: false,
            status: APP_ACTION_STATUS.INITIAL,
            error: null,
            data: null,
          },
        };
        case TRANSPORT_ACTION_TYPES.Get_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.REQUEST:
          return {
            ...state,
            getTransportCompanyByID: {
              ...state.getTransportCompanyByID,
              isLoading: true,
              status: APP_ACTION_STATUS.LOADING,
              error: null,
              data: null,
            },
          };
        case TRANSPORT_ACTION_TYPES.Get_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.SUCCESS:
          return {
            ...state,
            getTransportCompanyByID: {
              ...state.getTransportCompanyByID,
              isLoading: false,
              status: APP_ACTION_STATUS.SUCCESS,
              error: null,
              data: action.data,
            },
          };
        case TRANSPORT_ACTION_TYPES.Get_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.ERROR:
          return {
            ...state,
            getTransportCompanyByID: {
              ...state.getTransportCompanyByID,
              isLoading: false,
              status: APP_ACTION_STATUS.ERROR,
              error: action.error,
              data: null,
            },
          };
        case TRANSPORT_ACTION_TYPES.Get_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.CLEAR:
          return {
            ...state,
            getTransportCompanyByID: {
              ...state.getTransportCompanyByID,
              isLoading: false,
              status: APP_ACTION_STATUS.INITIAL,
              error: null,
              data: null,
            },
          };
          case TRANSPORT_ACTION_TYPES.UPDATE_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.REQUEST:
            return {
              ...state,
              UpdateTransportCompanyById: {
                ...state.UpdateTransportCompanyById,
                isLoading: true,
                status: APP_ACTION_STATUS.LOADING,
                error: null,
                data: null,
              },
            };
          case TRANSPORT_ACTION_TYPES.UPDATE_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.SUCCESS:
            return {
              ...state,
              UpdateTransportCompanyById: {
                ...state.UpdateTransportCompanyById,
                isLoading: false,
                status: APP_ACTION_STATUS.SUCCESS,
                error: null,
                data: action.data,
              },
            };
          case TRANSPORT_ACTION_TYPES.UPDATE_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.ERROR:
            return {
              ...state,
              UpdateTransportCompanyById: {
                ...state.UpdateTransportCompanyById,
                isLoading: false,
                status: APP_ACTION_STATUS.ERROR,
                error: action.error,
                data: null,
              },
            };
          case TRANSPORT_ACTION_TYPES.UPDATE_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.CLEAR:
            return {
              ...state,
              UpdateTransportCompanyById: {
                ...state.UpdateTransportCompanyById,
                isLoading: false,
                status: APP_ACTION_STATUS.INITIAL,
                error: null,
                data: null,
              },
            };
      default:
        return state;
    }
  };
  // 
 
  export default transportReducer;
  