import { call, put, takeEvery } from "redux-saga/effects"
import { ALERT_ACTION_TYPES, COMMON_ACTION_TYPES, TRIP_ACTION_TYPES } from "../../utilities/constants"
import { AxiosResponse } from "axios"
import { tripService } from "../../services/trip.service"
import { AlertDto, VehicleTypesDto, getVehicleListParamDto, rejectAllocationParam, updateTripDetailsParam, vehicleDetailsListDto } from "../../utilities/models"

function* getTripDetailsByRequestIds(action: { type: string, payload: any }) {
  try {
    const tripDetailsList: AxiosResponse<any> = yield call(tripService.getTripDetailsByRequestIds, action.payload)

    yield put({
      type: TRIP_ACTION_TYPES.GET_TRIP_DETAILS + COMMON_ACTION_TYPES.SUCCESS,
      data: tripDetailsList,
    })
  } catch (error) {
    yield put({
      type: TRIP_ACTION_TYPES.GET_TRIP_DETAILS + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }

}

function* GetVehicleLitsBYPassengerCount(action: { type: string, payload: getVehicleListParamDto }) {
  try {
    const VehicleList: AxiosResponse<VehicleTypesDto> = yield call(tripService.GetVehicleLitsBYPassengerCount, action.payload)
    yield put({
      type: TRIP_ACTION_TYPES.GET_VEHICLE_LIST_BY_PASSENGER_COUNT + COMMON_ACTION_TYPES.SUCCESS,
      data: VehicleList,
    })
  } catch (error) {
    yield put({
      type: TRIP_ACTION_TYPES.GET_VEHICLE_LIST_BY_PASSENGER_COUNT + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* GetVehicleDetailsByVehicleType(action: { type: string, payload: any }) {
  try {
    const VehicleList: AxiosResponse<vehicleDetailsListDto> = yield call(tripService.GetVehicleDetailsByVehicleType, action.payload)
    yield put({
      type: TRIP_ACTION_TYPES.GET_VEHICLE_DETAILS_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: VehicleList,
    })
  } catch (error) {
    yield put({
      type: TRIP_ACTION_TYPES.GET_VEHICLE_DETAILS_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}

function* postTripAllocationDetails(action: { type: string, payload: any }) {
  try {
    const reallocated: AxiosResponse<string> = yield call(tripService.postTripAllocationDetails, action.payload)
    const setAlert: AlertDto = {
      message: reallocated.data,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: TRIP_ACTION_TYPES.POST_TRIP_ALLOCATION + COMMON_ACTION_TYPES.SUCCESS,
      data: reallocated.data
    })
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: TRIP_ACTION_TYPES.POST_TRIP_ALLOCATION + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}
function *  updateTripAllocationDetails(action:{type:string,payload:updateTripDetailsParam}){
  try {
    const updateResponse: AxiosResponse<string> = yield call(tripService.updateTripAllocationDetails, action.payload)
    const setAlert: AlertDto = {
      message: updateResponse.data,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: TRIP_ACTION_TYPES.PUT_UPDATE_TRIP_DETAILS + COMMON_ACTION_TYPES.SUCCESS,
      data: updateResponse.data
    })
    
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: TRIP_ACTION_TYPES.PUT_UPDATE_TRIP_DETAILS + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  
  }
}
function *  rejectTripAllocation(action:{type:string,payload:rejectAllocationParam}){
  try {
    const updateResponse: AxiosResponse<string> = yield call(tripService.rejectTripAllocation, action.payload)
    const setAlert: AlertDto = {
      message: updateResponse.data,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'success'
      }
    }
    yield put(setAlert)
    yield put({
      type: TRIP_ACTION_TYPES.POST_REJECT_TRIP_ALLOCATION + COMMON_ACTION_TYPES.SUCCESS,
      data: updateResponse.data
    })
    
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: 'error'
      }
    }
    yield put(setAlert)
    yield put({
      type: TRIP_ACTION_TYPES.POST_REJECT_TRIP_ALLOCATION + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  
  }
}


function* tripSaga() {
  yield takeEvery(TRIP_ACTION_TYPES.GET_TRIP_DETAILS + COMMON_ACTION_TYPES.REQUEST, getTripDetailsByRequestIds,)
  yield takeEvery(TRIP_ACTION_TYPES.GET_VEHICLE_LIST_BY_PASSENGER_COUNT + COMMON_ACTION_TYPES.REQUEST, GetVehicleLitsBYPassengerCount,)
  yield takeEvery(TRIP_ACTION_TYPES.GET_VEHICLE_DETAILS_LIST + COMMON_ACTION_TYPES.REQUEST, GetVehicleDetailsByVehicleType,)
  yield takeEvery(TRIP_ACTION_TYPES.POST_TRIP_ALLOCATION + COMMON_ACTION_TYPES.REQUEST, postTripAllocationDetails,)
  yield takeEvery(TRIP_ACTION_TYPES.PUT_UPDATE_TRIP_DETAILS + COMMON_ACTION_TYPES.REQUEST, updateTripAllocationDetails,)
  yield takeEvery(TRIP_ACTION_TYPES.POST_REJECT_TRIP_ALLOCATION + COMMON_ACTION_TYPES.REQUEST, rejectTripAllocation,)
}

export default tripSaga
