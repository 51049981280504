import { Box, Grid, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React, { useEffect } from 'react';
import style from "./Notification.module.scss";
import { ApplicationStateDto, NotificationDto, notificationDetailsDto, updateSeenNotificationParam } from '../../../utilities/models';
import moment from 'moment';
import { AppSkeleton } from '../../Shared';
import { APP_ACTION_STATUS, APP_TABLE_CONFIGS } from '../../../utilities/constants';
import { useDispatch, useSelector } from 'react-redux';
import { notificationAction } from '../../../redux/actions';
import dayjs from 'dayjs';

const Notification :React.FC<{
  NotificationDetails?:NotificationDto
  isLoading:boolean;
  IdList:string[];
}> = (props) => {
  const GetNotificationResponse = useSelector( (state: ApplicationStateDto) => state.notification.getNotifications); 
  const UpdateResponse = useSelector( (state: ApplicationStateDto) => state.notification.updateSeenNotification); 
  const dispatch = useDispatch();


  useEffect(() => {
    
      dispatch(notificationAction.getNotification())
    
   }, [1])

   useEffect(() => {
    if(GetNotificationResponse.status===APP_ACTION_STATUS.SUCCESS ){
  let parmData:updateSeenNotificationParam={
    requestStatusIds:props.IdList
    
  }
  if(parmData.requestStatusIds.length>0){
    dispatch(notificationAction.UpdateSeenNotification(parmData))
  }

    }
   }, [GetNotificationResponse.status, dispatch, props.IdList])

   useEffect(() => {
    if(UpdateResponse.status===APP_ACTION_STATUS.SUCCESS){
      dispatch(notificationAction.getNotification())
    } 
   }, [UpdateResponse.status])
   


  return (
    <section> 
      <Box maxHeight="300px"sx={{background:"#323232"}} overflow="auto"> {/* Apply maxHeight and overflow properties */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
        
            {props.NotificationDetails?.notificationDetails.map((task:notificationDetailsDto) => (
            
                <ListItem style={style} className={style.border} key={task.id} component="div" disablePadding>
                  <ListItemButton>
                    <Box className={style.taskContainer} key={task.id}>
                      <Box sx={{display:"flex"}}>
                      <Box className={style.taskName}>Request ID - R{task.requestId}</Box>  
                      {(task.tripId!=null) &&
                      <Box className={style.taskName}>TripID -{task.tripId}</Box>
                      }
                      </Box>
                      <Box className={style.DataBox}>
                        <Box className={style.taskDescription}>{task.description}</Box>
                        <Box sx={{ position: 'absolute',  top: 30,bottom: 0, right: 20 }} className={style.taskTime}>{  dayjs(task.notificationDateTime).add(330, 'minute').format("YYYY-MM-DD HH:mm A")   }</Box>
                     
                     

                      </Box>
                    </Box>
                  </ListItemButton>
                </ListItem>
             
            ))}
          </Grid>
        </Grid>
      </Box>
    </section>
  );
}

export default Notification;
