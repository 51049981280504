import styles from "./TmSplitRequestPopupView.module.scss";
import { BootstrapDialog, BootstrapDialogActions, BootstrapDialogContent, BootstrapDialogTitle, StyledCheckBox, StyledSelect, StyledTableCell} from "../../../assets/theme/theme";
import { CustomButton, CustomHeaderCell } from "../../Shared";
import Grid from "@mui/material/Grid/Grid";
import { AlertDto, SplitPackagesDTO, SplitPassengerPackageDto, SplitPassengersDTO, SplitTypeBriefDto,} from "../../../utilities/models";
import { MenuItem, Paper, Table, TableBody, TableContainer, TableHead, TableRow,} from "@mui/material";
import { alertActions, splitActions,} from "../../../redux/actions";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import React from "react";
import { ALERT_ACTION_TYPES, COMMON_ACTION_TYPES, REQUEST_TYPES, SPLIT_TYPES,} from "../../../utilities/constants";

const TmSplitRequestPopupView: React.FC<{
  isOpenSplitViewPopup: boolean;
  showSplitViewPopup(con: boolean): void;
  splitTypes: SplitTypeBriefDto[];
  reqId: number;
  reqType: string;
  reqStartDate:string;
  reqEndDate:string;
  getPassengerPackages: SplitPassengerPackageDto[];
}> = (props) => {
  const _splitType = props.splitTypes;
  const numberOfTrips = [2, 3, 4, 5];
  const _reqId = props.reqId;
  const _reqType = props.reqType;
  const _mainVar = props.getPassengerPackages[0];
  const [passengers, setPassengers] = useState<SplitPassengersDTO[]>([]);
  const [packages, setPackages] = useState<SplitPackagesDTO[]>([]);
  const [packageidList, setpackageidList] = useState<Number[]>([]);
  const [passengersList, setpassengersList] = useState<Number[]>([]);
  const _startDate = props.reqStartDate?.substring(0, 10);
  const _endDate = props.reqEndDate?.substring(0, 10);

  const SplitEnum = {
    _initialSplitValue: 0,
    _splitByPassenger: 1,
    _splitByReturn: 2,
  };

  useEffect(() => {
    if (_mainVar && _mainVar.passengers && _mainVar.packages) {
      const { passengers, packages } = _mainVar;
      setPassengers(passengers);
      setPackages(packages);
    }
  }, [_mainVar]);

  const [selectedValue, setSelectedValue] = useState<number>(0);
  const [noTripValue, setNoTripValue] = useState<any>("");
  const [columns, setColumns] = useState([
    {
      id: "serialNo",
      name: "User",
      passengerId: [] as number[],
      packageId: [] as number[],
      columnId: 0,
    },
  ]);

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const handleDropdownChange = (event: any) => {
    const value = parseInt(event.target.value);
    setNoTripValue(value);
  };

  const setDefaultAlert: AlertDto = {
    message: "Something went wrong!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const setCheckboxValidationAlert: AlertDto = {
    message: "Check the splitting requests!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const handleButtonClick = () => {
    const newColumns = [
      {
        id: "serialNo",
        name: "User",
        passengerId: [] as number[],
        packageId: [] as number[],
        columnId: 0,
      },
    ];

    if (selectedValue === SplitEnum._splitByPassenger) {
      for (let i = 1; i <= noTripValue; i++) {
        const newColumn = {
          id: `column-${i}`,
          name: `Trip TMR ${_reqId}-${i}`,
          passengerId: [] as number[],
          packageId: [] as number[],
          columnId: i,
        };
        newColumns.push(newColumn);
      }
      setColumns(newColumns);
    }

    if (selectedValue === SplitEnum._splitByReturn) {
      const passangerIDs = passengers.map((item: SplitPassengersDTO) => item.id);
      const packageslis = packages.map((item: SplitPackagesDTO) => item.id);

      setpassengersList(passangerIDs);
      setpackageidList(packageslis);

      for (let i = 1; i <= SplitEnum._splitByReturn; i++) {
        if (i === 1) {
          const newColumn = {
            id: `column-${i}`,
            name: `Departure Trip`,
            passengerId: [] as number[],
            packageId: [] as number[],
            columnId: i,
          };
          newColumns.push(newColumn);
        }
        if (i === 2) {
          const newColumn = {
            id: `column-${i}`,
            name: `Return Trip`,
            passengerId: [] as number[],
            packageId: [] as number[],
            columnId: i,
          };
          newColumns.push(newColumn);
        }
      }
      setColumns(newColumns);
    }
  };

  const handleCheckBoxChange = (
    event: ChangeEvent<HTMLInputElement>,
    columnIndex: number,
    rowId: number,
    tableId: number
  ) => {
    setColumns((prevColumns) => {
      const updatedColumns = [...prevColumns];
      const column = updatedColumns[columnIndex];

      if (event.target.checked) {
        if (tableId === 0) {
          column.passengerId = [...column.passengerId, rowId];
        } else if (tableId === 1) {
          column.packageId = [...column.packageId, rowId];
        }
      } else {
        if (tableId === 0) {
          column.passengerId = column.passengerId.filter((id) => id !== rowId);
        } else if (tableId === 1) {
          column.packageId = column.packageId.filter((id) => id !== rowId);
        }
      }
      return updatedColumns;
    });
  };

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (selectedValue === SplitEnum._splitByPassenger) {
      const combinedArray = columns
        .map((column) => ({
          passengerId: column.passengerId || [],
          packageId: column.packageId || [],
        }))
        .filter(
          (column) =>
            (column.passengerId && column.passengerId.length > 0) ||
            (column.packageId && column.packageId.length > 0)
        );

      const payload = {
        originalRequestId: _reqId,
        splitTypeId: selectedValue,
        splitedId: combinedArray,
      };

      const hasNoDuplicates = hasRepeatPassengerIds(payload);
      if (hasNoDuplicates) {
        dispatch(splitActions.createSplitRequest(payload));
        handleCancel();
      } else {
        dispatch(alertActions.triggerAlert(setCheckboxValidationAlert));
      }
    } else if (selectedValue === SplitEnum._splitByReturn) {
      const data = {
        passengerId: passengersList,
        packageId: packageidList,
      };

      const payload = {
        originalRequestId: _reqId,
        splitTypeId: selectedValue,
        splitedId: [data],
      };
      
      //Multiple object sending
      const data1 = {
        passengerId: passengersList,
        packageId: [],
      };
      payload.splitedId.push(data1);

      dispatch(splitActions.createSplitRequest(payload));
      handleCancel();
    } else {
      dispatch(alertActions.triggerAlert(setDefaultAlert));
      props.showSplitViewPopup(false);
    }
  };

  const handleCancel = () => {
    props.showSplitViewPopup(false);
    setSelectedValue(0);
    setNoTripValue("");
    columns.length = 0;
  };

  const hasRepeatPassengerIds = (data: any) => {
    const passengerIdsSeen = new Set();
    const packageIdsSeen = new Set();

    for (const entry of data.splitedId) {
      for (const passengerId of entry.passengerId) {
        if (passengerIdsSeen.has(passengerId)) {
          return false; 
        } else {
          passengerIdsSeen.add(passengerId);
        }
      }
    }

    for (const entry of data.splitedId) {
      for (const packageId of entry.packageId) {
        if (packageIdsSeen.has(packageId)) {
          return false; 
        } else {
          packageIdsSeen.add(packageId);
        }
      }
    }

    return true;
  };

  return (
    <BootstrapDialog
      width={"800px"}
      className={styles.dialogCard}
      aria-labelledby="customized-dialog-title"
      open={props.isOpenSplitViewPopup}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCancel}>
        Split Trip - {_reqId}
      </BootstrapDialogTitle>
      <BootstrapDialogContent>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <StyledSelect
              size="small"
              defaultValue={""}
              displayEmpty
              onChange={handleChange}
              sx={{ fieldset: { borderColor: "rgb(235, 235, 235)" } }}
              style={{
                minWidth: "100%",
                color: "rgb(235, 235, 235)",
              }}
            >
              <MenuItem value="" disabled>
                Select a type to Split Trip
              </MenuItem>
              {_splitType.map((split) => {
                if (
                  (_reqType === REQUEST_TYPES[1].name &&
                  split.name === SPLIT_TYPES[1].name) || 
                  (_startDate === _endDate &&
                    split.name === SPLIT_TYPES[1].name)
                ) {
                  return null;
                } else {
                  return (
                    <MenuItem key={split.id} value={split.id}>
                      {split.name}
                    </MenuItem>
                  );
                }
              })}
            </StyledSelect>
          </Grid>
          <Grid item md={10}>
            <StyledSelect
              size="small"
              value={noTripValue}
              displayEmpty
              onChange={handleDropdownChange}
              sx={{ fieldset: { borderColor: "rgb(235, 235, 235)" } }}
              style={{
                minWidth: "100%",
                color: "rgb(235, 235, 235)",
              }}
              disabled={
                selectedValue === undefined ||
                selectedValue === SplitEnum._splitByReturn
              }
            >
              <MenuItem value="" disabled>
                Select number of trips
              </MenuItem>
              {numberOfTrips.map((num) => (
                <MenuItem key={num} value={num}>
                  {num}
                </MenuItem>
              ))}
            </StyledSelect>
          </Grid>
          <Grid item md={2}>
            <CustomButton
              text="Split"
              border="1px solid #6e6e6e"
              bgColor="#282828"
              onClick={handleButtonClick}
            />
          </Grid>
          {columns.length > SplitEnum._initialSplitValue &&
            selectedValue > SplitEnum._initialSplitValue && (
              <>
                <Grid item md={12}>
                  Assign Team
                </Grid>
                <Grid item md={12}>
                  {selectedValue === SplitEnum._splitByReturn ? (
                    <section className={styles.table}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {columns.map((column, columnIndex) => (
                                <CustomHeaderCell
                                  key={column.id}
                                  id={column.id}
                                >
                                  {column.name}
                                </CustomHeaderCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {passengers.map((row) => (
                              <TableRow key={row.id}>
                                {columns.map((column, columnIndex) => (
                                  <StyledTableCell key={column.id}>
                                    {columnIndex === 0 ? (
                                      row.name
                                    ) : (
                                      <StyledCheckBox
                                        defaultChecked={true}
                                        checked={true}
                                        readOnly
                                      />
                                    )}
                                  </StyledTableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </section>
                  ) : (
                    <section className={styles.table}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {columns.map((column, columnIndex) => (
                                <CustomHeaderCell
                                  key={column.id}
                                  id={column.id}
                                >
                                  {column.name}
                                </CustomHeaderCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {passengers.map((row) => (
                              <TableRow key={row.id}>
                                {columns.map((column, columnIndex) => (
                                  <StyledTableCell key={column.id}>
                                    {columnIndex === 0 ? (
                                      row.name
                                    ) : (
                                      <StyledCheckBox
                                        checked={column.passengerId.includes(
                                          row.id
                                        )}
                                        onChange={(event) =>
                                          handleCheckBoxChange(
                                            event,
                                            columnIndex,
                                            row.id,
                                            0
                                          )
                                        }
                                      />
                                    )}
                                  </StyledTableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </section>
                  )}
                </Grid>
                <Grid item md={12}>
                  Assign Package
                </Grid>
                <Grid item md={12}>
                  {selectedValue === SplitEnum._splitByReturn ? (
                    <section className={styles.table}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {columns.map((column, columnIndex) => (
                                <React.Fragment key={column.id}>
                                  {columnIndex === 0 ? (
                                    <CustomHeaderCell id={column.id}>
                                      Serial No
                                    </CustomHeaderCell>
                                  ) : (
                                    <CustomHeaderCell id={column.id}>
                                      {column.name}
                                    </CustomHeaderCell>
                                  )}
                                </React.Fragment>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {packages.map((row) => (
                              <TableRow key={row.id}>
                                {columns.map((column, columnIndex) => (
                                  <StyledTableCell key={column.id}>
                                    {columnIndex === 0 ? (
                                      "Package - "+row.serialNumber
                                    ) : (
                                      <StyledCheckBox
                                        defaultChecked={columnIndex !== 2}
                                        checked={columnIndex !== 2}
                                        readOnly
                                      />
                                    )}
                                  </StyledTableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </section>
                  ) : (
                    <section className={styles.table}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {columns.map((column, columnIndex) => (
                                <React.Fragment key={column.id}>
                                  {columnIndex === 0 ? (
                                    <CustomHeaderCell id={column.id}>
                                      Serial No
                                    </CustomHeaderCell>
                                  ) : (
                                    <CustomHeaderCell id={column.id}>
                                      {column.name}
                                    </CustomHeaderCell>
                                  )}
                                </React.Fragment>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {packages.map((row) => (
                              <TableRow key={row.id}>
                                {columns.map((column, columnIndex) => (
                                  <StyledTableCell key={column.id}>
                                    {columnIndex === 0 ? (
                                     "Package - "+ row.serialNumber
                                    ) : (
                                      <StyledCheckBox
                                        checked={column.packageId.includes(
                                          row.id
                                        )}
                                        onChange={(event) =>
                                          handleCheckBoxChange(
                                            event,
                                            columnIndex,
                                            row.id,
                                            1
                                          )
                                        }
                                      />
                                    )}
                                  </StyledTableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </section>
                  )}
                </Grid>
              </>
            )}
        </Grid>
      </BootstrapDialogContent>
      <BootstrapDialogActions>
        <CustomButton
          text="Cancel"
          border="1px solid #6e6e6e"
          bgColor="#282828"
          onClick={handleCancel}
        />
        <CustomButton text="Split" onClick={handleSubmit} />
      </BootstrapDialogActions>
    </BootstrapDialog>
  );
};

export default TmSplitRequestPopupView;
