import React, { useEffect, useState } from "react";
import { AppLayout } from "../../templates";
import style from "./RequestScreenExpress.module.scss";
import { CustomButton, OnBehalfOfSomeone } from "../../components";
import { ALERT_ACTION_TYPES, APP_ACTION_STATUS, APP_ROUTES, COMMON_ACTION_TYPES, ORGANIZATION_UNIT_TYPES, REQUEST_SCREEN_EXPRESS_MODES, REQUEST_SCREEN_MODES, REQUEST_TYPES, USER_ROLES, } from "../../utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertDto, ApplicationStateDto, GeneralInformationFormDto, LocationBriefDto, NewRequestPackageDetailsItemsDto, NewRequestPackagedetailsDto,
  NewRequestPassengerDetailsDto, OnBehalfOfSomeoneFormDto, OptionsDto, PackageDetailsFormDto, PackageItemsFormDto, PackageTypeBriefDto, PassengerDetailsFormDto,
  RequestByIdDto,
  RequestTypeBriefDto, VehicleTypesDto, selectedType,
} from "../../utilities/models";
import { Grid, Typography } from "@mui/material";
import { requestActions, templateActions, userActions, approverActions, vehicleActions, locationActions, packageActions, alertActions, mapActions, } from "../../redux/actions";
import RequestScreenExpressInfo from "../../components/RequestScreenExpress/RequestScreenExpressInfo";
import { LoadScript } from "@react-google-maps/api";
import { ENV_LOCAL } from "../../.env/environment.local";
import { Library } from "@googlemaps/js-api-loader";
import { StyledSwitch, StyledTextField } from "../../assets/theme/theme";
import dayjs, { Dayjs } from "dayjs";
import { validateFormData } from "../../utilities/helpers";
import { isDraft } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

const libraries: Library[] = ["places"];

const RequestScreenExpress = () => {
  const ON_BEHALF_OF_SOMEONE_FORM_INITIAL_STATE: OnBehalfOfSomeoneFormDto = {
    isForSomeone: { value: false, isRequired: true, disable: false, readonly: false, validator: null, error: "", },
    sbu: { value: {} as OptionsDto, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    plant: { value: {} as OptionsDto, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    department: { value: {} as OptionsDto, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    someone: { value: {} as OptionsDto, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
  };

  const GENERAL_INFORMATION_FORM_INITIAL_STATE: GeneralInformationFormDto = {
    requestType: { value: {} as RequestTypeBriefDto, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    depatureDate: { value: null, isRequired: true, disable: false, readonly: false, validator: "date", error: "", },
    departureTime: { value: null, isRequired: true, disable: false, readonly: false, validator: "text", error: "", },
    fromLocation: { value: {} as LocationBriefDto, isRequired: true, readonly: false, disable: false, validator: "object", error: "", },
    toLocation: { value: {} as LocationBriefDto, isRequired: true, readonly: false, disable: false, validator: "object", error: "", },
    returnDate: { value: null, isRequired: true, disable: false, readonly: false, validator: "date", error: "", },
    returnTime: { value: null, isRequired: true, disable: false, readonly: false, validator: "text", error: "", },
    isSameDay: { value: false, isRequired: true, disable: false, readonly: false, validator: null, error: "", },
    isVIP: { value: false, isRequired: true, disable: false, readonly: false, validator: null, error: "", },
    approverDep: { value: {} as { value: string; label: string; }, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    approver: { value: {} as OptionsDto, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    vehicleType: { value: {} as VehicleTypesDto, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    purpose: { value: "", isRequired: false, disable: false, readonly: false, validator: "text", error: "", },
    isTemplate: { value: false, isRequired: true, disable: false, readonly: false, validator: null, error: "", },
    sbu: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    plant: { value: {} as OptionsDto, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    department: { value: {} as OptionsDto, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    templateTitle: { value: "", isRequired: false, disable: true, readonly: false, validator: "text", error: "", max: 15, },
    startLocationLatAndLng: { value: {} as selectedType, isRequired: true, disable: true, readonly: false, validator: "object", error: "", },
    toLocationLatAndLng: { value: {} as selectedType, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    totalPassengerCount: { value: '', isRequired: true, disable: false, readonly: false, validator: "number", max: 5, error: "", },
    totalPackageCount: { value: '', isRequired: false, disable: false, readonly: false, validator: "number", max: 5, error: "", },
    totalPackageWeight: { value: '', isRequired: false, disable: false, readonly: false, validator: "number", max: 5, error: "", },
    totalPackageCBM: { value: '', isRequired: false, disable: false, readonly: false, validator: "number", max: 5, error: "", },
  };

  const PASSENGER_DETAILS_FORM_INITIAL_STATE: PassengerDetailsFormDto = {
    passenger: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    sbu: { value: "", isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    plant: { value: "", isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    department: { value: "", isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    startLocation: { value: {} as LocationBriefDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    toLocation: { value: {} as LocationBriefDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    intermediateLocation: { value: {} as LocationBriefDto, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    dropLocation: { value: {} as LocationBriefDto, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    //added
    startLocationLatAndLng: { value: {} as selectedType, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    toLocationLatAndLng: { value: {} as selectedType, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    dropLocationLatAndLng: { value: {} as selectedType, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    intermediateLocationLatAndLng: { value: {} as selectedType, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
  };

  const PACKAGE_DETAILS_FORM_INITIAL_STATE: PackageDetailsFormDto = {
    packageType: { value: {} as PackageTypeBriefDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    gatePassNo: { value: "", isRequired: true, disable: false, readonly: false, validator: "text", error: "", max: 15, },
    pickup: { value: {} as LocationBriefDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    dropOff: { value: {} as LocationBriefDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    recipient: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    contactNumber: { value: "", isRequired: true, disable: false, readonly: false, validator: "number", error: "", charLength: [10], },
    instructions: { value: "", isRequired: false, disable: false, readonly: false, validator: "text", error: "", },
    pickupLatAndLng: { value: {} as selectedType, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    dropOffLatAndLng: { value: {} as selectedType, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
  };

  const PACKAGE_DETAILS_ITEMS_FORM_INITIAL_STATE: PackageItemsFormDto = {
    serialNumber: { value: "", isRequired: false, disable: false, readonly: false, validator: "text", error: "", max: 15, },
    description: { value: "", isRequired: false, disable: false, readonly: false, validator: "text", error: "", max: 100, },
    cbm: { value: "", isRequired: false, disable: false, readonly: false, validator: "number", error: "", max: 5, },
    weight: { value: "", isRequired: false, disable: false, readonly: false, validator: "number", error: "", max: 5, },
  };

  const authorizedUser = useSelector((state: ApplicationStateDto) => state.authUser.authorizedUser);
  const vehicleTypesResponse = useSelector((state: ApplicationStateDto) => state.vehicle.getVehicleTypes);
  const frequentLocationsResponse = useSelector((state: ApplicationStateDto) => state.location.getFrequentLocations);
  const allPassengerResponse = useSelector((state: ApplicationStateDto) => state.user.getAllPassengers);
  const allApproverResponse = useSelector((state: ApplicationStateDto) => state.approver.getApprovers);
  const defaultApproverResponse = useSelector((state: ApplicationStateDto) => state.approver.getDefaultApprover);
  const packageTypes = useSelector((state: ApplicationStateDto) => state.package.getPackageTypes.data);
  const passengerByIdResponse = useSelector((state: ApplicationStateDto) => state.user.getPassenger);
  const requestByIdResponse = useSelector((state: ApplicationStateDto) => state.request.getRequest);
  const createNewRequestResponse = useSelector((state: ApplicationStateDto) => state.request.addRequest);
  const editRequestResponse = useSelector((state: ApplicationStateDto) => state.request.editRequest);
  const matchingTemplatesResponse = useSelector((state: ApplicationStateDto) => state.template.getMatchingTemplateList);
  const allSbuList = useSelector((state: ApplicationStateDto) => state.request.getSbuList.data);
  const allPlantsBySbuIdResponse = useSelector((state: ApplicationStateDto) => state.request.getPlantListBySbuList);
  const allDepartmentByPlantIdResponse = useSelector((state: ApplicationStateDto) => state.request.getDepartmentListByPlantId);
  const allTemplatesResponse = useSelector((state: ApplicationStateDto) => state.template.getTemplate);
  const userByIdResponse = useSelector((state: ApplicationStateDto) => state.user.getPassenger);
  const getUsersByDepartmentResponse = useSelector((state: ApplicationStateDto) => state.user.getAllPassengersByDepartment);

  const [isDraft, setIsDraft] = useState(false);
  const [helperText, setHelperText] = useState(true);
  const [screenMode, setScreenMode] = useState("");
  const [requestCount, setSetRequestCount] = useState(0);
  const [onBehalfOfSomeoneForm, setOnBehlafOfSomeoneForm] = useState(ON_BEHALF_OF_SOMEONE_FORM_INITIAL_STATE);
  const [passengerDetailsForm, setPassengerDetailsForm] = useState(PASSENGER_DETAILS_FORM_INITIAL_STATE);
  const [genaralInformationForm, setGenaralInformationForm] = useState(GENERAL_INFORMATION_FORM_INITIAL_STATE);
  const [packageDetailsForm, setPackageDetailsForm] = useState(PACKAGE_DETAILS_FORM_INITIAL_STATE);
  const [packageItemsForm, setPackageItemsForm] = useState(PACKAGE_DETAILS_ITEMS_FORM_INITIAL_STATE);
  const [selectedMatchingTemplate, setSelectedMatchingTemplate] = useState(-1);
  const [passengerDetailsList, setPassengerDetailsList] = useState<NewRequestPassengerDetailsDto[]>([]);
  const [packageDetailsList, setPackageDetailsList] = useState<NewRequestPackagedetailsDto[]>([]);
  const [packageDetailItemsList, setPackageDetailItemsList] = useState<NewRequestPackageDetailsItemsDto[]>([]);
  const GetTotalDistanceResponse = useSelector((state: ApplicationStateDto) => state.map.setTotalDistance);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditItem, setIsEditItem] = useState(false);
  const [selectedTemplateFreez, setSelectedTemplateFreez] = useState(false);
  const navigate = useNavigate();
  const [totalDistance, settotalDistance] = useState(0)
  const [addPackage, setaddPackage] = useState(false)
  const [inputValue, setInputValue] = useState("");
  const [switchValue, setswitchValue] = useState(true)
  const [switchValue2, setswitchValue2] = useState(true)

  const dispatch = useDispatch();
  useEffect(() => {
    getInitialData();

    return () => {

      dispatch(requestActions.CreateNewRequestClear());
      dispatch(requestActions.editRequestClear());
      dispatch(requestActions.getRequestByIdClear());
      dispatch(templateActions.getMatchingTemplateListClear());
      dispatch(userActions.getPassengerByIdClear());
      dispatch(approverActions.getDefaultApproverClear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editRequestResponse.status === APP_ACTION_STATUS.SUCCESS) {
      navigate(APP_ROUTES.REQUEST_CREATION);
      setAsInitialState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRequestResponse.status]);

  useEffect(() => {
    if (GetTotalDistanceResponse.status === APP_ACTION_STATUS.SUCCESS) {
      settotalDistance(Number(GetTotalDistanceResponse.data))
    }
  }, [GetTotalDistanceResponse.status])



  useEffect(() => {
    if (vehicleTypesResponse.status === APP_ACTION_STATUS.SUCCESS &&
      frequentLocationsResponse.status === APP_ACTION_STATUS.SUCCESS) {

      const _mode = sessionStorage.getItem("Mode");
      const _id = sessionStorage.getItem("id");



      if (_mode === REQUEST_SCREEN_MODES.VIEW || _mode === REQUEST_SCREEN_MODES.EDIT ||
        _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE || _mode === REQUEST_SCREEN_MODES.PENDING_VEHICLE_VIEW ||
        _mode === REQUEST_SCREEN_MODES.LM_APPROVAL_VIEW || _mode === REQUEST_SCREEN_MODES.TM_APPROVAL_VIEW) {

        if (_id) dispatch(requestActions.getRequestById(_id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleTypesResponse.status, frequentLocationsResponse.status]);



  useEffect(() => {
    const _mode = sessionStorage.getItem("Mode");

    if (requestByIdResponse.status === APP_ACTION_STATUS.SUCCESS && requestByIdResponse.data && _mode !== REQUEST_SCREEN_MODES.CREATE) {

      if (_mode === REQUEST_SCREEN_MODES.USE_TEMPLATE) setSelectedTemplateFreez(true);

      const _data: RequestByIdDto = requestByIdResponse.data;
      setIsDraft(_data.isDraft);
      const _isDisable = _mode === REQUEST_SCREEN_MODES.VIEW ||
        _mode === REQUEST_SCREEN_EXPRESS_MODES.LM_APPROVAL_VIEW ||
        _mode === REQUEST_SCREEN_EXPRESS_MODES.TM_APPROVAL_VIEW ||
        _mode === REQUEST_SCREEN_EXPRESS_MODES.PENDING_VEHICLE_VIEW;;

      const viewOnlyModes = REQUEST_SCREEN_MODES.VIEW || REQUEST_SCREEN_MODES.PENDING_VEHICLE_VIEW ||
                            REQUEST_SCREEN_MODES.LM_APPROVAL_VIEW || REQUEST_SCREEN_MODES.TM_APPROVAL_VIEW;

      console.log("_mode", _mode, _isDisable)
      dispatch(approverActions.getApprovers(_data.approver));
      dispatch(approverActions.getDefaultApprover(_data.billableDepartments.departmentId));
      setInputValue(_data.approver);

      if (_data && frequentLocationsResponse.data && vehicleTypesResponse.data) {
        const _requestType = REQUEST_TYPES.find((r) => r.id === _data.requestTypeId);
        const _fromLocation = frequentLocationsResponse.data.find((l) => l.location === _data.fromLocation);
        const _toLocation = frequentLocationsResponse.data.find((l) => l.location === _data.toLocation);
        const _vehicle = vehicleTypesResponse.data.find((v) => v.id === _data.vehicleTypeId);

        const specificItemStart:boolean = frequentLocationsResponse.data.some((item) => item.location === (_fromLocation?.location));
        const specificItemEnd:boolean = frequentLocationsResponse.data.some((item) => item.location === (_toLocation?.location));

        setswitchValue(specificItemStart)
        setswitchValue2(specificItemEnd)
        // Rest of your code using _requestType, _fromLocation, _toLocation, and _vehicle

        if (_mode === viewOnlyModes || _mode === REQUEST_SCREEN_MODES.EDIT)
          setOnBehlafOfSomeoneForm({
            ...onBehalfOfSomeoneForm,
            isForSomeone: {
              ...onBehalfOfSomeoneForm.isForSomeone,
              value: _data.isOnbehalfOfSomeone,
              disable: (_mode === viewOnlyModes || _mode === REQUEST_SCREEN_MODES.EDIT) ? true : false
            },
            someone: {
              ...onBehalfOfSomeoneForm.someone,
              value: { label: _data.createForName, value: _data.createFor },
              readonly: (_mode === viewOnlyModes || _mode === REQUEST_SCREEN_MODES.EDIT) ? true : false
            }
          })

        setGenaralInformationForm({
          ...genaralInformationForm,
          requestType: {
            ...genaralInformationForm.requestType,
            value: { name: _requestType?.name, id: _requestType?.id, } as RequestTypeBriefDto,
            readonly: _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE ? false : true,
          },
          depatureDate: {
            ...genaralInformationForm.depatureDate,
            value: _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE ? null : dayjs(_data?.fromDateTime).format("YYYY-MM-DD") as unknown as Dayjs,
            readonly: _isDisable ? true
              : _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE
                ? false
                : _requestType?.id === 2
                  ? true
                  : false,
            disable: _isDisable
              ? true
              : _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE
                ? false
                : _requestType?.id === 2
                  ? true
                  : false,
          },
          departureTime: {
            ...genaralInformationForm.departureTime,
            value: dayjs(_data?.fromDateTime),
            readonly: _isDisable,
          },
          returnDate: {
            ...genaralInformationForm.returnDate,
            value: _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE ? null : dayjs(_data?.toDateTime).format("YYYY-MM-DD") as unknown as Dayjs,
            readonly: _isDisable
              ? true
              : _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE
                ? false
                : _requestType?.id === 2
                  ? true
                  : false,
            disable: _isDisable
              ? true
              : _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE
                ? false
                : _requestType?.id === 2
                  ? true
                  : false,
          },
          returnTime: {
            ...genaralInformationForm.returnTime,
            value: dayjs(_data?.toDateTime),
            readonly: _isDisable,
          },

          fromLocation: {
            ...genaralInformationForm.fromLocation,
            value: specificItemStart ? { location: _fromLocation?.location, id: _fromLocation?.id, } as LocationBriefDto : 
                                        { location: _data.fromLocation, id: "0", } as LocationBriefDto,
            readonly: _isDisable,
          },
          startLocationLatAndLng: {
            value: {
              lat: Number(requestByIdResponse.data.fromLocationLatitude),
              lng: Number(requestByIdResponse.data.fromLocationLongitude)
            } as selectedType,
            validator: null,
            isRequired: false,
            error: null,
            disable: false,
            readonly: (_mode === viewOnlyModes || _mode === REQUEST_SCREEN_MODES.EDIT) ? true : false
          },
          toLocationLatAndLng: {
            value: {
              lat: Number(requestByIdResponse.data.toLocationLatitude),
              lng: Number(requestByIdResponse.data.toLocationLongitude),
            } as selectedType,
            validator: null,
            isRequired: false,
            error: null,
            disable: false,
            readonly: (_mode === viewOnlyModes || _mode === REQUEST_SCREEN_MODES.EDIT) ? true : false
          },
          toLocation: {
            ...genaralInformationForm.toLocation,
            value: specificItemEnd ? { location: _toLocation?.location, id: _toLocation?.id, } as LocationBriefDto : 
            { location: _data.toLocation, id: "0", } as LocationBriefDto,
            readonly: _isDisable,
          },
          isSameDay: {
            ...genaralInformationForm.isSameDay,
            value: _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE ? false : _data.return,
            disable: _isDisable,
          },
          isVIP: {
            ...genaralInformationForm.isVIP,
            value: _data.vip,
            disable: _isDisable,
          },
          purpose: {
            ...genaralInformationForm.purpose,
            value: _data.purpose,
            readonly: _isDisable,
          },
          isTemplate: {
            ...genaralInformationForm.isTemplate,
            value: _data.isTemplate,
            disable: true,
          },
          templateTitle: {
            ...genaralInformationForm.templateTitle,
            value: _data.templateTitle,
            disable: !_data.isTemplate,
            isRequired: _data.isTemplate
          },
          approver: {
            ...genaralInformationForm.approver,
            value: { label: _data.approver, value: _data?.approverId, } as OptionsDto,
            readonly: _isDisable,
            disable: !_isDisable,
          },
          vehicleType: {
            ...genaralInformationForm.vehicleType,
            value: { description: _vehicle?.description, id: _vehicle?.id, } as VehicleTypesDto,
            readonly: _isDisable,
          },
          sbu: {
            ...genaralInformationForm.sbu,
            value: { label: _data.billableDepartments.sbuName, value: _data?.billableDepartments.sbuId, } as OptionsDto,
            readonly: _isDisable,
          },
          plant: {
            ...genaralInformationForm.plant,
            value: { label: _data.billableDepartments.plantName, value: _data?.billableDepartments.plantId, } as OptionsDto,
            readonly: _isDisable,
            disable: !_isDisable,
          },
          department: {
            ...genaralInformationForm.department,
            value: { label: _data.billableDepartments.departmentName, value: _data?.billableDepartments.departmentId, } as OptionsDto,
            readonly: _isDisable,
            disable: !_isDisable,
          },
          totalPackageCount: {
            ...genaralInformationForm.totalPackageCount,
            value: _data.expressRequests[0].packageCount,
            readonly: _isDisable,
            disable: _isDisable,

          },
          totalPackageCBM: {
            ...genaralInformationForm.totalPackageCBM,
            value: _data.expressRequests[0].totalPackageCBM,
            readonly: _isDisable,
            disable: _isDisable,
          },
          totalPackageWeight: {
            ...genaralInformationForm.totalPackageWeight,
            value: _data.expressRequests[0].totalPackageWeight,
            readonly: _isDisable,
            disable: _isDisable,
          },
          totalPassengerCount: {
            ...genaralInformationForm.totalPassengerCount,
            value: _data.expressRequests[0].passengerCount,
            readonly: _isDisable,
            disable: _isDisable,
          },
        });

        setPassengerDetailsForm({
          ...passengerDetailsForm,
          startLocation: {
            ...passengerDetailsForm.startLocation,
            value: { location: _fromLocation?.location, id: _fromLocation?.id, } as LocationBriefDto,
          },
          startLocationLatAndLng: {
            value: { lat: Number(requestByIdResponse.data.fromLocationLatitude), lng: Number(requestByIdResponse.data.fromLocationLatitude), } as selectedType,
            validator: null,
            isRequired: false,
            error: null,
            disable: false,
            readonly: (_mode === viewOnlyModes || _mode === REQUEST_SCREEN_MODES.EDIT) ? true : false
          },
          toLocation: {
            ...passengerDetailsForm.toLocation,
            value: { location: _toLocation?.location, id: _toLocation?.id, } as LocationBriefDto,
          },
          dropLocation: {
            ...passengerDetailsForm.dropLocation,
            value: { location: _fromLocation?.location, id: _fromLocation?.id, } as LocationBriefDto,
          },
        });
        setPackageDetailsForm({
          ...packageDetailsForm,
          pickup: {
            ...packageDetailsForm.pickup,
            value: { location: _fromLocation?.location, id: _fromLocation?.id, } as LocationBriefDto,
          },
          dropOff: {
            ...packageDetailsForm.dropOff,
            value: { location: _toLocation?.location, id: _toLocation?.id, } as LocationBriefDto,
          }
        });

        const _passengerList = _data.requestPassengers.map((p, index) => {
          const _listOfLocations = p.requestPassengerLocations;
          const _sbu = p.hierarchy.find((h) => h.typeId === ORGANIZATION_UNIT_TYPES.SBU);
          const _plant = p.hierarchy.find((h) => h.typeId === ORGANIZATION_UNIT_TYPES.PLANT);
          const _dep = p.hierarchy.find((h) => h.typeId === ORGANIZATION_UNIT_TYPES.DEPARTMENT);

          const _startLocation = frequentLocationsResponse.data.find((l) => l.location === _listOfLocations[0].startLocation)
          const _toLocation = frequentLocationsResponse.data.find((l) => l.location === _listOfLocations[0].toLocation)
          const _intermediateLocation = frequentLocationsResponse.data.find((l) => l.location === _listOfLocations[0].intermediateLocation)
          const _dropLocation = frequentLocationsResponse.data.find((l) => l.location === _listOfLocations[0].dropLocation)
          const _passenger: NewRequestPassengerDetailsDto = {
            id: index,
            passenger: { id: p.passengerId, name: p.passenger },
            sbu: _sbu?.name ?? "",
            plant: _plant?.name ?? "",
            department: _dep?.name ?? "",
            startLocation: _startLocation ?? ({} as LocationBriefDto),
            toLocation: _toLocation ?? ({} as LocationBriefDto),
            intermediateLocation: _intermediateLocation ?? ({} as LocationBriefDto),
            dropLocation: _dropLocation ?? ({} as LocationBriefDto),
            requestPassengerLocations: _listOfLocations
          };

          return _passenger;
        });

        const _packageList = _data.packages.map((p, index) => {
          const _items = p.items.map((i, index) => {
            const item: NewRequestPackageDetailsItemsDto = {
              id: index,
              serialNumber: i.serialNo,
              description: i.description,
              cbm: i.cbm,
              weight: i.weight,
            };

            return item;
          });

          const _pickup = frequentLocationsResponse.data.find((l) => l.location === p.packageLocations[0].startLocation);
          const _dropOff = frequentLocationsResponse.data.find((l) => l.location === p.packageLocations[0].toLocation);

          const pickupLat = frequentLocationsResponse.data.find((l) => l.latitude === p.packageLocations[0].pickupLatAndLng?.lat);
          const pickupLang = frequentLocationsResponse.data.find((l) => l.longitude === p.packageLocations[0].pickupLatAndLng?.lng);
          const dropOffLat = frequentLocationsResponse.data.find((l) => l.latitude === p.packageLocations[0].dropOffLatAndLng?.lat);
          const dropOffLng = frequentLocationsResponse.data.find((l) => l.longitude === p.packageLocations[0].dropOffLatAndLng?.lng);

          const pickupLatAndLngs: selectedType = {
            lat: pickupLat?.latitude ?? 0,
            lng: pickupLang?.longitude ?? 0,
          }

          const dropOffLatAndLngs: selectedType = {
            lat: dropOffLat?.latitude ?? 0,
            lng: dropOffLng?.longitude ?? 0,
          }

          const _package: NewRequestPackagedetailsDto = {
            // TODO: CHANGE ID TO P.serialNo 
            id: index,
            packageType: { id: p.packageTypeId, description: p.packageType },
            gatePassNo: p.gatePassNo,
            pickup: _pickup ?? ({} as LocationBriefDto),
            dropOff: _dropOff ?? ({} as LocationBriefDto),
            recipient: { id: p.recipientId, name: p.reciepient },
            contactNumber: p.recipientContact,
            instructions: p.instructions,
            items: _items,
            pickupLatAndLng: pickupLatAndLngs ?? ({} as selectedType),
            dropOffLatAndLng: dropOffLatAndLngs ?? ({} as selectedType),
          };

          return _package;
        });

        if (screenMode === viewOnlyModes || screenMode === REQUEST_SCREEN_MODES.EDIT) {
          setPassengerDetailsList(_passengerList);
          setPackageDetailsList(_packageList);
        }

        if (screenMode === REQUEST_SCREEN_MODES.USE_TEMPLATE) setPassengerDetailsList(_passengerList);
      }
    } else {
      // Handle the case when any of the data sources (_data, frequentLocationsResponse, vehicleTypesResponse) is null or undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestByIdResponse.status]);

  useEffect(() => {
    if (createNewRequestResponse.status === APP_ACTION_STATUS.SUCCESS) {
      navigate(APP_ROUTES.REQUEST_CREATION);
      setAsInitialState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNewRequestResponse.status]);


  // const getInitialData = async () => {
  //   const _mode = sessionStorage.getItem("Mode");
  //   if (_mode !== null) setScreenMode(_mode);

  //   dispatch(vehicleActions.getVehicleTypes());
  //   dispatch(locationActions.getFrequentLocationList());
  //   dispatch(packageActions.getPackageTypes());
  //   dispatch(requestActions.getSbuList());

  //   if ( _mode === REQUEST_SCREEN_MODES.CREATE || _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE) {
  //     dispatch(templateActions.getTemplate());
  //   }

  //   if (_mode === REQUEST_SCREEN_MODES.CREATE) {
  //     fetchInitialPassengerDetailsForm();
  //   }
  // };





  useEffect(() => {
    setGenaralInformationForm(
      {
        ...genaralInformationForm,
        requestType: {
          value: { name: "express Adhoc", id: 4 },
          validator: null,
          isRequired: false,
          error: null,
          disable: false,
          readonly: false
        }
      }


    )
  }, [])

  const fetchInitialPassengerDetailsForm = () => {
    const _empName =
      authorizedUser.data.firstName + " " + authorizedUser.data.lastName;
    setInputValue(_empName);
    dispatch(userActions.getPassengerById(authorizedUser.data.userId));
    setPassengerDetailsForm({
      ...passengerDetailsForm,
      passenger: {
        ...passengerDetailsForm.passenger,
        value: { label: _empName, value: authorizedUser.data.userId, } as OptionsDto,
        readonly: true,
      },
    });
  };

  const getInitialData = async () => {
    const _mode = sessionStorage.getItem("Mode");
    if (_mode !== null) setScreenMode(_mode);
    dispatch(vehicleActions.getVehicleTypes());
    dispatch(locationActions.getFrequentLocationList());
    dispatch(packageActions.getPackageTypes());
    dispatch(requestActions.getSbuList());
    if (
      _mode === REQUEST_SCREEN_MODES.CREATE ||
      _mode === REQUEST_SCREEN_MODES.USE_TEMPLATE
    ) {
      dispatch(templateActions.getTemplate());
    }
    if (_mode === REQUEST_SCREEN_MODES.CREATE) {
      fetchInitialPassengerDetailsForm();
    }
  };

  const onInputHandleChangeRequestForSomeone = (property: string, value: any) => {
    console.log("property", property, "value", value);
    setHelperText(true);
    if (value !== null) {
      if (property === "isForSomeone") {
        if (value) {
          setInputValue("");
          setPassengerDetailsForm({
            ...passengerDetailsForm,
            passenger: {
              ...passengerDetailsForm.passenger,
              value: {} as OptionsDto,
              readonly: false,
            },
            sbu: {
              ...passengerDetailsForm.sbu,
              value: "",
              readonly: true,
            },
            plant: {
              ...passengerDetailsForm.plant,
              value: "",
              readonly: true,
            },
            department: {
              ...passengerDetailsForm.department,
              value: "",
              readonly: true,
            },
          });

          // CHECK USER ROLE
          // if (authorizedUser.data.roleId === USER_ROLES.SUPER_ADMIN || authorizedUser.data.roleId === USER_ROLES.TRANSPORT_MANAGER || 
          //   authorizedUser.data.roleId === USER_ROLES.SBU_MANAGER || authorizedUser.data.roleId === USER_ROLES.DIVISION_USER)
          //   setOnBehlafOfSomeoneForm({
          //     ...onBehalfOfSomeoneForm,
          //     isForSomeone: {
          //       ...onBehalfOfSomeoneForm.isForSomeone,
          //       value: value,
          //     },
          //     sbu: {
          //       ...onBehalfOfSomeoneForm.sbu,
          //       disable: false,
          //     },
          //   });
          // if (authorizedUser.data.roleId === USER_ROLES.SBU_MANAGER)
          //   if (userByIdResponse.data && !userByIdResponse.isLoading) {
          //     const _data = userByIdResponse.data.data;
          //     const _sbu = _data.find(
          //       (d) => d.type === ORGANIZATION_UNIT_TYPES.SBU
          //     );
          //     if (_sbu) {
          //       setOnBehlafOfSomeoneForm({
          //         ...onBehalfOfSomeoneForm,
          //         isForSomeone: {
          //           ...onBehalfOfSomeoneForm.isForSomeone,
          //           value: value,
          //         },
          //         sbu: {
          //           ...onBehalfOfSomeoneForm.sbu,
          //           value: { label: _sbu?.name, value: _sbu?.id },
          //           disable: true,
          //         },
          //         plant: {
          //           ...onBehalfOfSomeoneForm.plant,
          //           disable: false,
          //         },
          //       });
          //       dispatch(requestActions.getPlantListBySbuId(_sbu?.id));
          //     }
          //   }
          // if (authorizedUser.data.roleId === USER_ROLES.TRANSPORT_MANAGER || authorizedUser.data.roleId === USER_ROLES.DIVISION_USER)
          //   if (userByIdResponse.data && !userByIdResponse.isLoading) {
          //     const _data = userByIdResponse.data.data;
          //     const _sbu = _data.find((d) => d.type === ORGANIZATION_UNIT_TYPES.SBU);
          //     const _plant = _data.find((d) => d.type === ORGANIZATION_UNIT_TYPES.PLANT);
          //     if (_sbu && _plant) {
          //       setOnBehlafOfSomeoneForm({
          //         ...onBehalfOfSomeoneForm,
          //         isForSomeone: {
          //           ...onBehalfOfSomeoneForm.isForSomeone,
          //           value: value,
          //         },
          //         sbu: {
          //           ...onBehalfOfSomeoneForm.sbu,
          //           value: { label: _sbu.name, value: _sbu.id },
          //           disable: true,
          //         },
          //         plant: {
          //           ...onBehalfOfSomeoneForm.plant,
          //           value: { label: _plant.name, value: _plant.id },
          //           disable: true,
          //         },
          //         department: {
          //           ...onBehalfOfSomeoneForm.department,
          //           disable: false,
          //         },
          //       });
          //       dispatch(requestActions.getDepartmentListByPlantId(_plant.id));
          //     }
          //   }

          // CHECK USER ROLE BUG 406, 408
          if (authorizedUser.data && authorizedUser.data !== undefined) {
            const _dataSbu = authorizedUser.data.hierarchy.find((d) => d.typeId === ORGANIZATION_UNIT_TYPES.SBU)
            const _dataPlant = authorizedUser.data.hierarchy.find((d) => d.typeId === ORGANIZATION_UNIT_TYPES.PLANT)
            const _dataDept = authorizedUser.data.hierarchy.find((d) => d.typeId === ORGANIZATION_UNIT_TYPES.DEPARTMENT)

            if (_dataPlant !== undefined && _dataSbu !== undefined && _dataDept !== undefined) {
              if (authorizedUser.data.roleId === USER_ROLES.SUPER_ADMIN)
                setOnBehlafOfSomeoneForm({
                  ...onBehalfOfSomeoneForm,
                  isForSomeone: {
                    ...onBehalfOfSomeoneForm.isForSomeone,
                    value: value,
                  },
                  sbu: {
                    ...onBehalfOfSomeoneForm.sbu,
                    disable: false,
                  },
                });
            }

            if (authorizedUser.data.roleId === USER_ROLES.SBU_MANAGER) {
              setOnBehlafOfSomeoneForm({
                ...onBehalfOfSomeoneForm,
                isForSomeone: {
                  ...onBehalfOfSomeoneForm.isForSomeone,
                  value: value,
                },
                sbu: {
                  ...onBehalfOfSomeoneForm.sbu,
                  value: { label: authorizedUser.data.hierarchy[0].name, value: authorizedUser.data.hierarchy[0].id },
                  disable: true,
                },
                plant: {
                  ...onBehalfOfSomeoneForm.plant,
                  disable: false,
                },
              });
              dispatch(requestActions.getPlantListBySbuId(authorizedUser.data.hierarchy[0].id));
            }

            if (authorizedUser.data.roleId === USER_ROLES.TRANSPORT_MANAGER || authorizedUser.data.roleId === USER_ROLES.DIVISION_USER) {
              setOnBehlafOfSomeoneForm({
                ...onBehalfOfSomeoneForm,
                isForSomeone: {
                  ...onBehalfOfSomeoneForm.isForSomeone,
                  value: value,
                },
                sbu: {
                  ...onBehalfOfSomeoneForm.sbu,
                  value: { label: authorizedUser.data.hierarchy[0].name, value: authorizedUser.data.hierarchy[0].id },
                  disable: true,
                },
                plant: {
                  ...onBehalfOfSomeoneForm.plant,
                  value: { label: authorizedUser.data.hierarchy[1].name, value: authorizedUser.data.hierarchy[1].id },
                  disable: true,
                },
                department: {
                  ...onBehalfOfSomeoneForm.department,
                  disable: false,
                },
              });
              dispatch(requestActions.getDepartmentListByPlantId(authorizedUser.data.hierarchy[1].id));
            }
          }
        } else {
          fetchInitialPassengerDetailsForm();
          setOnBehlafOfSomeoneForm({
            ...onBehalfOfSomeoneForm,
            isForSomeone: {
              ...onBehalfOfSomeoneForm.isForSomeone,
              value: value,
            },
            sbu: {
              ...onBehalfOfSomeoneForm.sbu,
              value: {} as OptionsDto,
              disable: true,
            },
            plant: {
              ...onBehalfOfSomeoneForm.plant,
              value: {} as OptionsDto,
              disable: true,
            },
            department: {
              ...onBehalfOfSomeoneForm.department,
              value: {} as OptionsDto,
              disable: true,
            },
            someone: {
              ...onBehalfOfSomeoneForm.someone,
              value: {} as OptionsDto,
              disable: true,
            },
          });
        }
      }

      if (property === "sbu") {
        dispatch(requestActions.getPlantListBySbuId(value.value));
        setOnBehlafOfSomeoneForm({
          ...onBehalfOfSomeoneForm,
          sbu: {
            ...onBehalfOfSomeoneForm.sbu,
            value: value,
          },
          plant: {
            ...onBehalfOfSomeoneForm.plant,
            disable: false,
          },
        });
      }

      if (property === "plant") {
        dispatch(requestActions.getDepartmentListByPlantId(value.value));
        setOnBehlafOfSomeoneForm({
          ...onBehalfOfSomeoneForm,
          plant: {
            ...onBehalfOfSomeoneForm.plant,
            value: value,
          },
          department: {
            ...onBehalfOfSomeoneForm.department,
            disable: false,
          },
        });
      }

      if (property === "department") {
        dispatch(userActions.getAllPassengersByDepartment(value.value));
        setOnBehlafOfSomeoneForm({
          ...onBehalfOfSomeoneForm,
          department: {
            ...onBehalfOfSomeoneForm.department,
            value: value,
          },
          someone: {
            ...onBehalfOfSomeoneForm.someone,
            disable: false,
          },
        });
      }

      if (property === "someone") {
        setOnBehlafOfSomeoneForm({
          ...onBehalfOfSomeoneForm,
          someone: {
            ...onBehalfOfSomeoneForm.someone,
            value: value,
          },
        });

        setInputValue(value.label);
        dispatch(userActions.getPassengerById(value.value));
        setPassengerDetailsForm({
          ...passengerDetailsForm,
          passenger: {
            ...passengerDetailsForm.passenger,
            value: { label: value.label, value: value.value } as OptionsDto,
            readonly: true,
          },
        });
      }
    }
  };

  useEffect(() => {
    const _mode = sessionStorage.getItem("Mode");

    if (defaultApproverResponse.status === APP_ACTION_STATUS.SUCCESS && defaultApproverResponse.data &&
      _mode !== REQUEST_SCREEN_MODES.CREATE) {

      const _isDefaultApprover = defaultApproverResponse.data?.defaultApproverId === genaralInformationForm.approver.value.value;
      const _isDisable = _mode === REQUEST_SCREEN_MODES.VIEW;

      setGenaralInformationForm({
        ...genaralInformationForm,
        approverDep: {
          ...genaralInformationForm.approverDep,
          value: { label: _isDefaultApprover ? "same department" : "all department", value: _isDefaultApprover ? "same department" : "all department", },
          disable: false,
          readonly: _isDisable,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultApproverResponse.status]);

  useEffect(() => {
    const _mode = sessionStorage.getItem("Mode");
    console.log("_mode_mode", _mode,)

    if (defaultApproverResponse.status === APP_ACTION_STATUS.SUCCESS && defaultApproverResponse.data &&
      _mode !== REQUEST_SCREEN_MODES.VIEW && _mode !== REQUEST_SCREEN_MODES.EDIT) {


      setGenaralInformationForm({
        ...genaralInformationForm,
        approverDep: {
          ...genaralInformationForm.approverDep,
          value: { label: "same department", value: "same department" },
          error: null,
        },
        approver: {
          ...genaralInformationForm.approver,
          value: { label: defaultApproverResponse.data.defaultApproverName, value: defaultApproverResponse.data.defaultApproverId, },
          disable: false,
          readonly: true,
          error: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultApproverResponse.status]);

  const onInputHandleChangeGenaralInformation = (property: string, value: any) => {
    setHelperText(true);
    if (value !== null) {
      // // REQUEST TYPE IS RECURRENT
      // if (property === "requestType" && value.value === 2) {
      //   setGenaralInformationForm({
      //     ...genaralInformationForm,
      //     requestType: {
      //       ...genaralInformationForm.requestType,
      //       value: { name: value.label, id: value.value, } as RequestTypeBriefDto,
      //     },
      //     returnDate: {
      //       ...genaralInformationForm.returnDate,
      //       value: [],
      //       disable: true,
      //       validator: "dates",
      //     },
      //     isSameDay: {
      //       ...genaralInformationForm.isSameDay,
      //       value: true,
      //       disable: true,
      //     },
      //     depatureDate: {
      //       ...genaralInformationForm.depatureDate,
      //       value: [],
      //       disable: false,
      //       validator: "dates",
      //     },
      //   });
      // }
      // if (property === "requestType" && value.value === 1) {
      //   setGenaralInformationForm({
      //     ...genaralInformationForm,
      //     requestType: {
      //       ...genaralInformationForm.requestType,
      //       value: { name: value.label, id: value.value, } as RequestTypeBriefDto,
      //     },
      //     returnDate: {
      //       ...genaralInformationForm.returnDate,
      //       value: null,
      //       disable: false,
      //       validator: "date",
      //     },
      //     isSameDay: {
      //       ...genaralInformationForm.isSameDay,
      //       value: false,
      //       disable: false,
      //     },
      //     depatureDate: {
      //       ...genaralInformationForm.depatureDate,
      //       value: null,
      //       disable: false,
      //       validator: "date",
      //     },
      //   });
      // }
      if (property === "fromLocation") {
        let _isError = false;
        if (genaralInformationForm.toLocation.value.id !== undefined) {
          if (genaralInformationForm.toLocation.value.id === value.value) {
            _isError = true;
            const setAlert: AlertDto = {
              message: "Please select different locations.!",
              type:
                ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
              options: {
                key: new Date().getTime() + Math.random(),
                varient: "error",
              },
            };
            dispatch(alertActions.triggerAlert(setAlert));
          }
        }
        if (!_isError) {
          const specificItem = frequentLocationsResponse.data.find((item) => item.id === value.value);
          if (specificItem) {
            setStartLocation({ lat: Number(specificItem.latitude), lng: Number(specificItem.longitude), });
          }
          setGenaralInformationForm({
            ...genaralInformationForm,
            fromLocation: {
              ...genaralInformationForm.fromLocation,
              value: { location: value.label, id: value.value, } as LocationBriefDto,
            },
            startLocationLatAndLng: {
              ...genaralInformationForm.startLocationLatAndLng,
              value: { lat: Number(specificItem?.latitude) || null, lng: Number(specificItem?.longitude) || null, } as selectedType,
            },
          });

          setPassengerDetailsForm({
            ...passengerDetailsForm,
            startLocation: {
              ...passengerDetailsForm.startLocation,
              value: { location: value.label, id: value.value, } as LocationBriefDto,
            },

            startLocationLatAndLng: {
              ...passengerDetailsForm.startLocationLatAndLng,
              value: { lat: Number(specificItem?.latitude) || null, lng: Number(specificItem?.longitude) || null, } as selectedType,
            },
          });
          setPackageDetailsForm({
            ...packageDetailsForm,
            pickup: {
              ...packageDetailsForm.pickup,
              value: {
                location: value.label,
                id: value.value,
              } as LocationBriefDto,
            },
            pickupLatAndLng: {
              ...packageDetailsForm.pickupLatAndLng,
              value: { lat: Number(specificItem?.latitude) || null, lng: Number(specificItem?.longitude) || null, } as selectedType,
            },
          });
        }
      }
      if (property === "toLocation") {
        let _isError = false;
        if (genaralInformationForm.fromLocation.value.id !== undefined) {
          if (genaralInformationForm.fromLocation.value.id === value.value) {
            _isError = true;
            const setAlert: AlertDto = {
              message: "Please select different locations.!",
              type:
                ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
              options: {
                key: new Date().getTime() + Math.random(),
                varient: "error",
              },
            };
            dispatch(alertActions.triggerAlert(setAlert));
          }
        }
        if (!_isError) {
          const specificItem = frequentLocationsResponse.data.find(
            (item) => item.id === value.value
          );
          if (specificItem) {
            setEndLocation({
              lat: Number(specificItem.latitude),
              lng: Number(specificItem.longitude),
            });
          }
          setGenaralInformationForm({
            ...genaralInformationForm,
            toLocation: {
              ...genaralInformationForm.toLocation,
              value: {
                location: value.label,
                id: value.value,
              } as LocationBriefDto,
            },
            toLocationLatAndLng: {
              ...genaralInformationForm.toLocationLatAndLng,
              value: {
                lat: Number(specificItem?.latitude) || null,
                lng: Number(specificItem?.longitude) || null,
              } as selectedType,
            },
          });

          setPassengerDetailsForm({
            ...passengerDetailsForm,
            toLocation: {
              ...passengerDetailsForm.toLocation,
              value: {
                location: value.label,
                id: value.value,
              } as LocationBriefDto,
            },
            toLocationLatAndLng: {
              ...passengerDetailsForm.toLocationLatAndLng,
              value: {
                lat: Number(specificItem?.latitude) || null,
                lng: Number(specificItem?.longitude) || null,
              } as selectedType,
            },
          });
          setPackageDetailsForm({
            ...packageDetailsForm,
            dropOff: {
              ...packageDetailsForm.dropOff,
              value: {
                location: value.label,
                id: value.value,
              } as LocationBriefDto,
            },
            dropOffLatAndLng: {
              ...packageDetailsForm.dropOffLatAndLng,
              value: { lat: Number(specificItem?.latitude) || null, lng: Number(specificItem?.longitude) || null, } as selectedType,
            },
          });
        }
      }
      if (
        property === "depatureDate" &&
        genaralInformationForm.requestType.value.id === 2
      ) {
        setGenaralInformationForm({
          ...genaralInformationForm,
          depatureDate: {
            ...genaralInformationForm.depatureDate,
            value: value,
            error: null,
          },
          returnDate: {
            ...genaralInformationForm.returnDate,
            value: value,
            error: null,
          },
        });
      }
      if (
        property === "depatureDate"
      ) {
        setGenaralInformationForm({
          ...genaralInformationForm,
          depatureDate: {
            ...genaralInformationForm.depatureDate,
            value: value,
            error: null,
          },
        });
      }
      if (property === "isSameDay" && value) {
        setGenaralInformationForm({
          ...genaralInformationForm,
          isSameDay: {
            ...genaralInformationForm.isSameDay,
            value: value,
          },
          returnDate: {
            ...genaralInformationForm.returnDate,
            value: genaralInformationForm.depatureDate.value,
            disable: true,
            error: null,
          },
        });
      }
      if (property === "isSameDay" && !value) {
        setGenaralInformationForm({
          ...genaralInformationForm,
          isSameDay: {
            ...genaralInformationForm.isSameDay,
            value: value,
          },
          returnDate: {
            ...genaralInformationForm.returnDate,
            value: null,
            disable: false,
          },
        });
      }
      if (property === "vehicleType") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          vehicleType: {
            ...genaralInformationForm.vehicleType,
            value: {
              description: value.label,
              id: value.value,
            } as VehicleTypesDto,
          },
        });
      }
      if (property === "returnDate") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          returnDate: {
            ...genaralInformationForm.returnDate,
            value: value,
            error: null,
          },
        });
      }
      if (
        property === "purpose" ||
        property === "returnTime" ||
        property === "isVIP" ||
        property === "departureTime"
      ) {
        setGenaralInformationForm({
          ...genaralInformationForm,
          [property]: {
            ...genaralInformationForm[
            property as keyof typeof genaralInformationForm
            ],
            value: value,
          },
        });
      }
      if (property === "sbu") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          sbu: {
            ...genaralInformationForm.sbu,
            value: value ? value : ({} as OptionsDto),
          },
          plant: {
            ...genaralInformationForm.plant,
            value: {} as OptionsDto,
            disable: false,
          },
          department: {
            ...genaralInformationForm.department,
            value: {} as OptionsDto,
          },
        });

        dispatch(requestActions.getPlantListBySbuId(value.value));
      }
      if (property === "plant") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          plant: {
            ...genaralInformationForm.plant,
            value: value ? value : ({} as OptionsDto),
          },
          department: {
            ...genaralInformationForm.department,
            value: {} as OptionsDto,
            disable: false,
          },
        });

        dispatch(requestActions.getDepartmentListByPlantId(value.value));
      }
      if (property === "department") {
        dispatch(approverActions.getDefaultApprover(Number(value.value)));
        setGenaralInformationForm({
          ...genaralInformationForm,
          department: {
            ...genaralInformationForm.department,
            value: value ? value : ({} as OptionsDto),
          },
          approverDep: {
            ...genaralInformationForm.approverDep,
            disable: false,
          },
        });
      }
      if (property === "approverDep" && value.value === "same department") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          approverDep: {
            ...genaralInformationForm.approverDep,
            value: value,
          },
          approver: {
            ...genaralInformationForm.approver,
            value: defaultApproverResponse.data
              ? {
                label: defaultApproverResponse.data?.defaultApproverName,
                value: defaultApproverResponse.data?.defaultApproverId,
              }
              : ({} as OptionsDto),
            disable: false,
            readonly: true,
          },
        });
      }
      if (property === "approverDep" && value.value === "all department") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          approver: {
            ...genaralInformationForm.approver,
            value: {} as OptionsDto,
            disable: false,
            readonly: false,
          },
          approverDep: {
            ...genaralInformationForm.approverDep,
            value: value,
          },
        });
      }
      if (property === "approver") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          approver: {
            ...genaralInformationForm.approver,
            value: value
              ? ({ label: value.label, value: value.value } as OptionsDto)
              : ({} as OptionsDto),
          },
        });
      }
      if (property === "isTemplate") {
        if (value)
          setGenaralInformationForm({
            ...genaralInformationForm,
            isTemplate: {
              ...genaralInformationForm.isTemplate,
              value: value,
            },
            templateTitle: {
              ...genaralInformationForm.templateTitle,
              isRequired: true,
              disable: false,
            },
          });
        else
          setGenaralInformationForm({
            ...genaralInformationForm,
            isTemplate: {
              ...genaralInformationForm.isTemplate,
              value: value,
            },
            templateTitle: {
              ...genaralInformationForm.templateTitle,
              value: "",
              isRequired: false,
              disable: true,
            },
          });
      }
      if (property === "templateTitle") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          templateTitle: {
            ...genaralInformationForm.templateTitle,
            value: value,
          },
        });
      }
      if (property === "PassengerCount") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          totalPassengerCount: {
            ...genaralInformationForm.totalPassengerCount,
            value: value,
          },
        });
      }
      if (property === "totalPackageCount") {

        setGenaralInformationForm({
          ...genaralInformationForm,
          totalPackageCount: {
            ...genaralInformationForm.totalPackageCount,
            value: value,
          },
        });
      }
      if (property === "totalPackageWeight") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          totalPackageWeight: {
            ...genaralInformationForm.totalPackageWeight,
            value: value,
          },
        });
      }
      if (property === "totalPackageCBM") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          totalPackageCBM: {
            ...genaralInformationForm.totalPackageCBM,
            value: value,
          },
        });
      }
    }
  };

  const handleInputFocus = (property: string, section: string) => {
    if (section === "GI")

      setGenaralInformationForm({
        ...genaralInformationForm,
        [property]: {
          ...genaralInformationForm[
          property as keyof typeof genaralInformationForm
          ],
          error: null,
        },
      });
    if (section === "PD")
      setPassengerDetailsForm({
        ...passengerDetailsForm,
        [property]: {
          ...passengerDetailsForm[
          property as keyof typeof passengerDetailsForm
          ],
          error: null,
        },
      });
    if (section === "PACKAGE_DETAILS_FORM")
      setPackageDetailsForm({
        ...packageDetailsForm,
        [property]: {
          ...packageDetailsForm[property as keyof typeof packageDetailsForm],
          error: null,
        },
      });
    if (section === "PACKAGE_DETAIL_ITEMS_FORM")
      setPackageItemsForm({
        ...packageItemsForm,
        [property]: {
          ...packageItemsForm[property as keyof typeof packageItemsForm],
          error: null,
        },
      });
    if (section === "request-for")
      setOnBehlafOfSomeoneForm({
        ...onBehalfOfSomeoneForm,
        [property]: {
          ...onBehalfOfSomeoneForm[
          property as keyof typeof onBehalfOfSomeoneForm
          ],
          error: null,
        },
      });
  };

  const onSearchValueChange = (property: string, value: any) => {
    if (
      screenMode === REQUEST_SCREEN_MODES.CREATE ||
      screenMode === REQUEST_SCREEN_MODES.EDIT ||
      screenMode === REQUEST_SCREEN_MODES.USE_TEMPLATE
    ) {
      if (
        property === "approver" &&
        genaralInformationForm.approverDep.value.value === "all department"
      ) {
        if (value && value !== inputValue) {
          dispatch(approverActions.getApprovers(value));
        }
      }
      if (property === "passenger") {
        if (value && value !== inputValue) {
          dispatch(userActions.getAllPassengers(value));
        }
      }
      if (property === "recipient") {
        if (value && value !== inputValue) {
          dispatch(userActions.getAllPassengers(value));
        }
      }
      if (value) setInputValue(value);
    }
  };

  const getNewRequestPayload = (
    fromDate: string,
    toDate: string,
    isDraft: boolean
  ) => {
    const _packages = packageDetailsList.map(
      (p: NewRequestPackagedetailsDto) => ({
        packageTypeId: p.packageType.id,
        gatePassNo: p.gatePassNo,
        recipientContact: p.contactNumber,
        instructions: p.instructions,
        serialNo: "",
        isReturn: genaralInformationForm.isSameDay.value,
        fromDate: fromDate,
        toDate: toDate,
        reciepientId: p.recipient.id,
        packageLocations: [
          {
            startLocation: p.pickup.location,
            startLocationLatitude: p.pickupLatAndLng?.lat?.toLocaleString() || "",
            startLocationLongitude: p.pickupLatAndLng?.lng?.toLocaleString() || "",
            toLocation: p.dropOff.location,
            toLocationLatitude: p.pickupLatAndLng?.lat?.toLocaleString() || "",
            toLocationLongitude: p.pickupLatAndLng?.lng?.toLocaleString() || "",
          },
        ],
        items: p.items.map((i: NewRequestPackageDetailsItemsDto) => ({
          serialNo: i.serialNumber,
          description: i.description,
          cbm: Number(i.cbm),
          weight: Number(i.weight),
        })),
      })
    );

    const _passengers = passengerDetailsList.map(
      (p: NewRequestPassengerDetailsDto) => {
        const _obj = {
          userId: p.passenger.id,
          isReturn: genaralInformationForm.isSameDay.value,
          fromDate: fromDate,
          toDate: toDate,
          requestPassengerLocations: p.requestPassengerLocations
        };

        return _obj;
      }
    );

    const _sbu = genaralInformationForm.sbu.value.value;
    const _plant = genaralInformationForm.plant.value.value;
    const _department = genaralInformationForm.department.value.value;

    let _requestFor = null;

    if (
      authorizedUser.data.roleId === USER_ROLES.SUPER_ADMIN ||
      authorizedUser.data.roleId === USER_ROLES.DIVISION_USER ||
      authorizedUser.data.roleId === USER_ROLES.SBU_MANAGER ||
      authorizedUser.data.roleId === USER_ROLES.TRANSPORT_MANAGER
    )
      _requestFor = Number(onBehalfOfSomeoneForm.someone.value.value);

    const _payload = {
      requestTypeId: 4,
      vehicleTypeId: genaralInformationForm.vehicleType.value.id,
      fromDateTime: fromDate,
      toDateTime: toDate,
      return: genaralInformationForm.isSameDay.value,
      fromLocation: genaralInformationForm.fromLocation.value.location,
      fromLocationLatitude: genaralInformationForm.startLocationLatAndLng.value.lat.toLocaleString(),
      fromLocationLongitude: genaralInformationForm.startLocationLatAndLng.value.lng.toLocaleString(),
      toLocation: genaralInformationForm.toLocation.value.location,
      toLocationLatitude: genaralInformationForm.toLocationLatAndLng.value.lat.toLocaleString(),
      toLocationLongitude: genaralInformationForm.toLocationLatAndLng.value.lng.toLocaleString(),
      vip: genaralInformationForm.isVIP.value,
      purpose: genaralInformationForm.purpose.value,
      isDraft: isDraft,
      approver: genaralInformationForm.approver.value.value,
      sbu: _sbu,
      plant: _plant,
      department: _department,
      distance: totalDistance,
      selectedTripId:
        selectedMatchingTemplate === -1 ? 0 : selectedMatchingTemplate,
      packages: _packages,
      requestPassengers: _passengers,
      templates: genaralInformationForm.isTemplate.value,
      createFor: _requestFor,
      templateTitle: genaralInformationForm.templateTitle.value,
      expressRequests: [
        {
          passengerCount: Number(genaralInformationForm.totalPassengerCount.value),
          packageCount: Number(genaralInformationForm.totalPackageCount.value),
          totalPackageWeight: Number(genaralInformationForm.totalPackageWeight.value),
          totalPackageCBM: Number(genaralInformationForm.totalPackageCBM.value),
        },
      ],
    };

    return _payload;
  };

  const editRequest = async () => {
    const [validateData, isValid] = await validateFormData(
      genaralInformationForm
    );

    setGenaralInformationForm(validateData);
    if (isValid) {
      let _isError = false;

      const _date = dayjs(new Date()).format("YYYY-MM-DDT");
      const _depatureDate = dayjs(genaralInformationForm.depatureDate.value as Dayjs).format("YYYY-MM-DDT");
      const _depatureAt = dayjs(genaralInformationForm.departureTime.value).format("HH:mmZ");
      const _returnDate = dayjs(genaralInformationForm.returnDate.value as Dayjs).format("YYYY-MM-DDT");
      const _returnAt = dayjs(genaralInformationForm.returnTime.value).format("HH:mmZ");

      const _fromDate = _date + _depatureAt;
      const _toDate = _date + _returnAt;

      console.log("dayjs(_depatureDate).isSame(_returnDate)", dayjs(_depatureDate).isSame(_returnDate))
      console.log("dayjs(_fromDate).isAfter(_toDate)", dayjs(_fromDate).isAfter(_toDate))

      if (dayjs(_fromDate).isAfter(_toDate) && dayjs(_depatureDate).isSame(_returnDate)) {
        _isError = true
        const setAlert: AlertDto = {
          message: "Return time should be greater than depature time.!",
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: "error",
          },
        };
        dispatch(alertActions.triggerAlert(setAlert));
      }
      // if (
      //   passengerDetailsList.length === 0 &&
      //   packageDetailsList.length === 0
      // ) {
      //   _isError = true;
      //   const setAlert: AlertDto = {
      //     message: "Please select passenger/package for update a request.!",
      //     type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      //     options: {
      //       key: new Date().getTime() + Math.random(),
      //       varient: "error",
      //     },
      //   };
      //   dispatch(alertActions.triggerAlert(setAlert));
      // }
      // if (packageDetailsList.length === 0) {
      //   if (onBehalfOfSomeoneForm.isForSomeone.value) {
      //     if (
      //       !passengerDetailsList.some(
      //         (p) =>
      //           p.passenger.id === onBehalfOfSomeoneForm.someone.value.value
      //       )
      //     ) {
      //       _isError = true;
      //       const setAlert: AlertDto = {
      //         message:
      //           "Invalid request! Request pointer person must be in the passenger list.",
      //         type:
      //           ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      //         options: {
      //           key: new Date().getTime() + Math.random(),
      //           varient: "error",
      //         },
      //       };
      //       dispatch(alertActions.triggerAlert(setAlert));
      //     }
      //   } else {
      //     if (
      //       !passengerDetailsList.some(
      //         (p) => p.passenger.id === authorizedUser.data.userId
      //       )
      //     ) {
      //       _isError = true;
      //       const setAlert: AlertDto = {
      //         message:
      //           "Invalid request! Request creator must be in the passenger list.",
      //         type:
      //           ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      //         options: {
      //           key: new Date().getTime() + Math.random(),
      //           varient: "error",
      //         },
      //       };
      //       dispatch(alertActions.triggerAlert(setAlert));
      //     }
      //   }
      // }
      if (!_isError) {
        const _depatureDate = dayjs(
          genaralInformationForm.depatureDate.value as Dayjs
        ).format("YYYY-MM-DDT");
        const _depatureAt = dayjs(
          genaralInformationForm.departureTime.value
        ).format("HH:mmZ");
        const _returnDate = dayjs(
          genaralInformationForm.returnDate.value as Dayjs
        ).format("YYYY-MM-DDT");
        const _returnAt = dayjs(genaralInformationForm.returnTime.value).format(
          "HH:mmZ"
        );

        const _tempFromDate = _depatureDate + _depatureAt;
        const _tempToDate = _returnDate + _returnAt;

        const _fromDate = dayjs(_tempFromDate)
          .add(330, "minute")
          .format("YYYY-MM-DDTHH:mmZ");
        const _toDate = dayjs(_tempToDate)
          .add(330, "minute")
          .format("YYYY-MM-DDTHH:mmZ");

        const _payload = getEditRequestPayload(_fromDate, _toDate, isDraft);
        dispatch(requestActions.editRequest(_payload));
      }
    } else {
      const setAlert: AlertDto = {
        message: "Please fill required field/s",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: "error",
        },
      };
      dispatch(alertActions.triggerAlert(setAlert));
    }
  };

  const [isDraftButtonClick, setIsDraftButtonClick] = useState(false);

  const createNewRequest = async (isDraft: boolean) => {
    if (isDraft) setIsDraftButtonClick(isDraft);
    const [validateData, isValid] = await validateFormData(
      genaralInformationForm
    );
    setGenaralInformationForm(validateData);

    if (isValid) {
      let _isError = false;
      const _date = dayjs(new Date()).format("YYYY-MM-DDT");
      const _depatureDate = dayjs(genaralInformationForm.depatureDate.value as Dayjs).format("YYYY-MM-DDT");
      const _depatureAt = dayjs(genaralInformationForm.departureTime.value).format("HH:mmZ");
      const _returnDate = dayjs(genaralInformationForm.returnDate.value as Dayjs).format("YYYY-MM-DDT");
      const _returnAt = dayjs(genaralInformationForm.returnTime.value).format("HH:mmZ");

      const _fromDate = _date + _depatureAt;
      const _toDate = _date + _returnAt;

      console.log("dayjs(_depatureDate).isSame(_returnDate)", dayjs(_depatureDate).isSame(_returnDate))
      console.log("dayjs(_fromDate).isAfter(_toDate)", dayjs(_fromDate).isAfter(_toDate))

      if (dayjs(_fromDate).isAfter(_toDate) && dayjs(_depatureDate).isSame(_returnDate)) {
        _isError = true
        const setAlert: AlertDto = {
          message: "Return time should be greater than depature time.!",
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: "error",
          },
        };
        dispatch(alertActions.triggerAlert(setAlert));
      }

      if (genaralInformationForm.requestType.value.id === 2) {
        const _dateRange = genaralInformationForm.depatureDate
          .value as Array<Dayjs>;
        if (_dateRange.length === 1) {
          _isError = true;
          const setAlert: AlertDto = {
            message:
              "Please select more than one day to create reccurent request.!",
            type:
              ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
            options: {
              key: new Date().getTime() + Math.random(),
              varient: "error",
            },
          };
          dispatch(alertActions.triggerAlert(setAlert));
        }
      }
      if (!_isError)
        if (genaralInformationForm.requestType.value.id === 4) {
          const _depatureDate = dayjs(
            genaralInformationForm.depatureDate.value as Dayjs
          ).format("YYYY-MM-DDT");
          const _depatureAt = dayjs(
            genaralInformationForm.departureTime.value
          ).format("HH:mmZ");
          const _returnDate = dayjs(
            genaralInformationForm.returnDate.value as Dayjs
          ).format("YYYY-MM-DDT");
          const _returnAt = dayjs(
            genaralInformationForm.returnTime.value
          ).format("HH:mmZ");

          const _tempFromDate = _depatureDate + _depatureAt;
          const _tempToDate = _returnDate + _returnAt;

          const _fromDate = dayjs(_tempFromDate)
            .add(330, "minute")
            .format("YYYY-MM-DDTHH:mmZ");
          const _toDate = dayjs(_tempToDate)
            .add(330, "minute")
            .format("YYYY-MM-DDTHH:mmZ");

          const _payload = getNewRequestPayload(_fromDate, _toDate, isDraft);
          dispatch(requestActions.createNewRequest(_payload));
          setSetRequestCount(requestCount + 1);
        }
    } else {
      const setAlert: AlertDto = {
        message: "Please fill required field/s",
        type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        options: {
          key: new Date().getTime() + Math.random(),
          varient: "error",
        },
      };
      dispatch(alertActions.triggerAlert(setAlert));
    }
  };
  const getEditRequestPayload = (
    fromDate: string,
    toDate: string,
    isDraft: boolean
  ) => {
    const _packages = packageDetailsList.map(
      (p: NewRequestPackagedetailsDto) => ({
        packageTypeId: p.packageType.id,
        gatePassNo: p.gatePassNo,
        recipientContact: p.contactNumber,
        instructions: p.instructions,
        serialNo: "",
        isReturn: genaralInformationForm.isSameDay.value,
        fromDate: fromDate,
        toDate: toDate,
        reciepientId: p.recipient.id,
        packageLocations: [
          {
            startLocation: p.pickup.location,
            toLocation: p.dropOff.location,
          },
        ],
        items: p.items.map((i: NewRequestPackageDetailsItemsDto) => ({
          serialNo: i.serialNumber,
          description: i.description,
          cbm: Number(i.cbm),
          weight: Number(i.weight),
        })),
      })
    );

    const _passengers = passengerDetailsList.map(
      (p: NewRequestPassengerDetailsDto) => {
        const _obj = {
          userId: p.passenger.id,
          isReturn: genaralInformationForm.isSameDay.value,
          fromDate: fromDate,
          toDate: toDate,
          requestPassengerLocations: [
            {
              startLocation: p.startLocation.location,
              toLocation: p.toLocation.location,
              dropLocation: p.dropLocation?.location,
              intermediateLocation: p.intermediateLocation?.location,
            },
          ],
        };

        return _obj;
      }
    );

    const _payload = {
      id: sessionStorage.getItem("id"),
      requestTypeId: 4,
      vehicleTypeId: 0,
      fromDateTime: fromDate,
      toDateTime: toDate,
      return: genaralInformationForm.isSameDay.value,
      fromLocation: genaralInformationForm.fromLocation.value.location.toLocaleString(),
      fromLocationLatitude: genaralInformationForm.startLocationLatAndLng.value.lat.toLocaleString(),
      fromLocationLongitude: genaralInformationForm.startLocationLatAndLng.value.lng.toLocaleString(),
      toLocation: genaralInformationForm.toLocation.value.location.toLocaleString(),
      toLocationLatitude: genaralInformationForm.toLocationLatAndLng.value.lat.toLocaleString(),
      toLocationLongitude: genaralInformationForm.toLocationLatAndLng.value.lng.toLocaleString(),
      vip: genaralInformationForm.isVIP.value,
      purpose: genaralInformationForm.purpose.value,
      isDraft: isDraft,
      approver: genaralInformationForm.approver.value.value,
      distance: totalDistance,
      selectedTripId:
        selectedMatchingTemplate === -1 ? 0 : selectedMatchingTemplate,
      packages: _packages,
      requestPassengers: _passengers,
      templates: genaralInformationForm.isTemplate.value,
      deprtmentId: genaralInformationForm.department.value.value,
      plantId: genaralInformationForm.plant.value.value,
      sbuId: genaralInformationForm.sbu.value.value,
      templateTitle: genaralInformationForm.templateTitle.value,

      expressRequests: [
        {
          passengerCount: Number(genaralInformationForm.totalPassengerCount.value),
          packageCount: Number(genaralInformationForm.totalPackageCount.value),
          totalPackageWeight: Number(genaralInformationForm.totalPackageWeight.value),
          totalPackageCBM: Number(genaralInformationForm.totalPackageCBM.value),
        },
      ],

    };

    return _payload;
  };

  const SelectedType_Initial_State: selectedType = {
    lat: 0,
    lng: 0
  }

  const [startLocation, setStartLocation] = useState<selectedType>(SelectedType_Initial_State);
  const [endLocation, setEndLocation] = useState<selectedType>(SelectedType_Initial_State);

  const setAsInitialState = () => {
    setGenaralInformationForm(GENERAL_INFORMATION_FORM_INITIAL_STATE);
    setPassengerDetailsForm(PASSENGER_DETAILS_FORM_INITIAL_STATE);
    setPackageDetailsForm(PACKAGE_DETAILS_FORM_INITIAL_STATE);
    setPackageItemsForm(PACKAGE_DETAILS_ITEMS_FORM_INITIAL_STATE);
    setPassengerDetailsList([]);
    setPackageDetailsList([]);
    setPackageDetailItemsList([]);
    setIsEdit(false);
    setIsDraft(false);
    setIsEditItem(false);
    setInputValue("");
    setSelectedMatchingTemplate(-1);
    setSelectedTemplateFreez(false);
    setaddPackage(false)
    setStartLocation(SelectedType_Initial_State)
    setEndLocation(SelectedType_Initial_State)
    dispatch(mapActions.AddStartMapPointClear());
    dispatch(mapActions.ADDPickUpPointsClear());
    dispatch(mapActions.AddEndMapPointClear());
    dispatch(mapActions.addDropOffPointsClear());
  };

  const onClose = () => {
    if(screenMode === REQUEST_SCREEN_MODES.PENDING_VEHICLE_VIEW){
      navigate(APP_ROUTES.TM_VEHICLE_ALLOCATION);
    }else if(screenMode === REQUEST_SCREEN_MODES.LM_APPROVAL_VIEW){
      navigate(APP_ROUTES.LM_REQUEST_APPROVAL)
    }else if(screenMode === REQUEST_SCREEN_MODES.TM_APPROVAL_VIEW){
      navigate(APP_ROUTES.TM_REQUEST_APPROVAL)
    }else{
      navigate(APP_ROUTES.REQUEST_CREATION);
    }
  };

  useEffect(() => {

    if (requestByIdResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setStartLocation({ lat: Number(requestByIdResponse.data?.fromLocationLatitude), lng: Number(requestByIdResponse.data?.fromLocationLongitude) })
      setEndLocation({ lat: Number(requestByIdResponse.data?.toLocationLatitude), lng: Number(requestByIdResponse.data?.toLocationLongitude) })
    }

  }, [requestByIdResponse.status])



  useEffect(() => {
    dispatch(mapActions.addDropOffPointsClear());
    dispatch(mapActions.addDropOffPointsClear());
    dispatch(mapActions.AddStartMapPoint(startLocation));
    dispatch(mapActions.AddEndMapPoint(endLocation));

  }, [startLocation, endLocation])



  const onInputHandleChangeGoogleInput = (property: string, value: any, section: string) => {
    console.log("property", property, "value", value);
    if (section === "GI") {
      if (property === "fromLocation") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          fromLocation: {
            ...genaralInformationForm.fromLocation,
            value: { location: value.address, id: value.value, } as LocationBriefDto,
          },
          startLocationLatAndLng: {
            ...genaralInformationForm.startLocationLatAndLng,
            value: value.latAndLang as selectedType
          }
        });
      }
      if (property === "toLocation") {
        setGenaralInformationForm({
          ...genaralInformationForm,
          toLocation: {
            ...genaralInformationForm.toLocation,
            value: { location: value.address, id: value.value, } as LocationBriefDto,
          },
          toLocationLatAndLng: {
            ...genaralInformationForm.toLocationLatAndLng,
            value: value.latAndLang as selectedType
          }
        });
      }
    }
  }

  return (
    <React.Fragment>
      <AppLayout componentTitle="UserProfile">
        <section className="page-root">
          <section className={style.gridContainer}>
            <section className={style.sectionCard}>
              <section className={style.sectionCardBody}>
                <section className={style.sectionCardHeader}>
                  <Typography noWrap component="div" className={style.title}>
                    <h3>Express Request</h3>
                  </Typography>
                </section>
                <section className={style.stepperRoot}>
                  {(authorizedUser.data.roleId === USER_ROLES.SBU_MANAGER ||
                    authorizedUser.data.roleId === USER_ROLES.DIVISION_USER ||
                    authorizedUser.data.roleId === USER_ROLES.SUPER_ADMIN ||
                    authorizedUser.data.roleId === USER_ROLES.TRANSPORT_MANAGER ||
                    (screenMode === REQUEST_SCREEN_MODES.VIEW && authorizedUser.data.roleId === USER_ROLES.LINE_MANAGER)
                  ) && (
                      <OnBehalfOfSomeone
                        helperText={helperText}
                        screenMode={screenMode}
                        onBehalfOfSomeoneForm={onBehalfOfSomeoneForm}
                        handleInputFocus={handleInputFocus}
                        onInputHandleChangeRequestForSomeone={onInputHandleChangeRequestForSomeone}
                        allSbuList={allSbuList || []}
                        allPlantsBySbuIdResponse={allPlantsBySbuIdResponse}
                        allDepartmentByPlantIdResponse={allDepartmentByPlantIdResponse}
                        getUsersByDepartmentResponse={getUsersByDepartmentResponse} isLoading={false} />
                    )}

                  <RequestScreenExpressInfo
                    // screenMode={screenMode}
                    addPackage={addPackage}
                    setaddPackage={setaddPackage}
                    vehicleTypesResponse={vehicleTypesResponse}
                    frequentLocations={frequentLocationsResponse.data || []}
                    allSbuList={allSbuList || []}
                    allApproverResponse={allApproverResponse}
                    defaultApproverResponse={defaultApproverResponse}
                    helperText={helperText}
                    handleInputFocus={handleInputFocus}
                    genaralInformationForm={genaralInformationForm}
                    requestByIdResponse={requestByIdResponse}
                    setStartLocation={setStartLocation}
                    setEndLocation={setEndLocation}
                    onInputHandleChangeGenaralInformation={
                      onInputHandleChangeGenaralInformation
                    }
                    onSearchValueChange={onSearchValueChange}
                    allPlantsBySbuIdResponse={allPlantsBySbuIdResponse}
                    allDepartmentByPlantIdResponse={
                      allDepartmentByPlantIdResponse
                    }
                    onInputHandleChangeGoogleInput={onInputHandleChangeGoogleInput}
                    switchValue={switchValue}
                    switchValue2={switchValue2}
                    screenMode={screenMode}
                  />
                </section>
                {sessionStorage.getItem("Mode") ===
                  REQUEST_SCREEN_MODES.CREATE && (
                    <section className={style.saveAs}>
                      <div className={style.switchField}>
                        <Typography className={style.label}>
                          Save as template
                        </Typography>
                        <StyledSwitch
                          checked={genaralInformationForm.isTemplate.value}
                          disabled={genaralInformationForm.isTemplate.disable}
                          onChange={() =>
                            onInputHandleChangeGenaralInformation(
                              "isTemplate",
                              !genaralInformationForm.isTemplate.value
                            )
                          }
                        />
                      </div>
                      <Grid container spacing={4}>
                        <Grid item xs={6}>
                          <StyledTextField
                            fullWidth
                            label="Template title"
                            placeholder="Enter title"
                            size="small"
                            InputProps={{
                              readOnly:
                                genaralInformationForm.templateTitle.readonly,
                            }}
                            value={genaralInformationForm.templateTitle.value}
                            error={!!genaralInformationForm.templateTitle.error}
                            disabled={
                              genaralInformationForm.templateTitle.disable
                            }
                            required={
                              genaralInformationForm.templateTitle.isRequired
                            }
                            helperText={
                              helperText &&
                              genaralInformationForm.templateTitle.error
                            }
                            onFocus={() =>
                              handleInputFocus("templateTitle", "GI")
                            }
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              onInputHandleChangeGenaralInformation(
                                "templateTitle",
                                event.target.value
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </section>
                  )}

                <section className={style.sectionCardFooter}>
                  <CustomButton
                    text="Close"
                    textColor="black"
                    bgColor="#bfbfbf"
                    onClick={onClose}
                  />
                  {screenMode !== REQUEST_SCREEN_EXPRESS_MODES.VIEW && (
                    <>
                      {(sessionStorage.getItem("Mode") ===
                        REQUEST_SCREEN_EXPRESS_MODES.CREATE ||
                        sessionStorage.getItem("Mode") ===
                        REQUEST_SCREEN_EXPRESS_MODES.USE_TEMPLATE) && (
                          <>
                            <CustomButton
                              text="Clear"
                              border="1px solid #6e6e6e"
                              bgColor="#282828"
                              onClick={setAsInitialState}
                            />
                            <CustomButton
                              text="Save draft"
                              border="1px solid #6e6e6e"
                              bgColor="#282828"
                              disabled={createNewRequestResponse.isLoading}
                              isLoading={
                                isDraftButtonClick &&
                                createNewRequestResponse.isLoading
                              }
                              onClick={() => createNewRequest(true)}
                            />
                            <CustomButton
                              text="Send Request"
                              disabled={createNewRequestResponse.isLoading}
                              isLoading={
                                !isDraftButtonClick &&
                                createNewRequestResponse.isLoading
                              }
                              onClick={() => createNewRequest(false)}
                            />
                          </>
                        )}
                      {sessionStorage.getItem("Mode") ===
                        REQUEST_SCREEN_EXPRESS_MODES.EDIT && (
                          <CustomButton
                            text="Edit Request"
                            disabled={editRequestResponse.isLoading}
                            isLoading={editRequestResponse.isLoading}
                            onClick={editRequest}
                          />
                        )}
                    </>
                  )}
                </section>
              </section>

            </section>
          </section>
        </section>
      </AppLayout>

    </React.Fragment>
  );
};

export default RequestScreenExpress;
