import React from "react";
import style from "./TemplateCard.module.scss";
import CustomButton from "../CustomButton/CustomButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { APP_ROUTES, NEW_REQUEST_TYPES, REQUEST_SCREEN_MODES, REQUEST_TYPES } from "../../../utilities/constants";
import { useNavigate } from "react-router-dom";

type CardProps = {
  purpose: string;
  toLocation: string;
  fromLocation: string;
  passengerCount: number;
  packageCount: number;
  passengersList: Array<string>;
  id: number;
  title:string;
  requestType:number;
  onDelete: (id: number) => void;
};

const TemplateCard: React.FC<CardProps> = ({
  purpose,
  toLocation,
  fromLocation,
  passengerCount,
  packageCount,
  passengersList,
  id,
  title,
  requestType,
  onDelete,
}) => {
  const navigate = useNavigate();

  const handleDelete = () => {
    onDelete(id);
  };

  const handleUse = async () => {
    sessionStorage.setItem("Mode", REQUEST_SCREEN_MODES.USE_TEMPLATE);
    sessionStorage.setItem("id", id.toString());

    if(requestType === NEW_REQUEST_TYPES[2].id){
      navigate(APP_ROUTES.MANAGE_REQUEST_EXPRESS);
    }else{
      navigate(APP_ROUTES.MANAGE_REQUEST);
    }
  };

  return (
    <div className={style.box}>
      <div className={style.main}>
        {title !== "" ? (<h3> {title} </h3>) : (<h3> {purpose} </h3>)}
        <p className={style.subTitle}>
          {fromLocation} - {toLocation}
        </p>
      </div>
      <div className={style.design}>
        <svg
          width="47"
          height="26"
          viewBox="0 0 47 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="3" y="10" width="6" height="6" rx="3" fill="#E6E6E6" />
          <rect x="15" y="10" width="16" height="6" rx="3" fill="#A6191D" />
          <rect x="37" y="10" width="6" height="6" rx="3" fill="#E6E6E6" />
        </svg>
      </div>

      <div className={style.overview}>
        <p>
          {passengerCount} Passengers, {packageCount} Package
        </p>
      </div>

      <div className={style.passengers}>
        {passengersList && passengersList.length > 0 ? (
          <div className={style.passengerListContainer}>
            {passengersList.map((passenger, index) => (
              <div className={style.passengerItem} key={index}>
                <div className={style.check1}>
                  <KeyboardArrowRightIcon />
                </div>
                <span className={style.passengerName}>{passenger}</span>
              </div>
            ))}
          </div>
        ) : passengerCount > 0 ? (<p></p>) : (
          <p>No passengers found.</p>
        )}
      </div>

      <CustomButton
        text="Delete"
        border="1px solid #6e6e6e"
        bgColor="#282828"
        onClick={handleDelete}
      />
      <CustomButton text="Use" onClick={handleUse} />
    </div>
  );
};

export default TemplateCard;
