import React from 'react'
import { DriverListDto, FuelTypeBriefDto, OptionsDto } from '../../../utilities/models';
import style from './FuelManagementGrid.module.scss'
import { Box, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material';
import { AppSkeleton, CustomButton, CustomHeaderCell } from '../../Shared';
import AddIcon from '@mui/icons-material/Add';
import { StyledTableCell } from '../../../assets/theme/theme';
import { APP_TABLE_CONFIGS, DRIVER_SCREEN_MODES, FUEL_CATEGORY, FUEL_TYPE_MODE } from '../../../utilities/constants';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { EditOutlined } from '@mui/icons-material';

const FuelManagementGrid: React.FC<{
  page: number,
  rowsPerPage: number,
  isFiltered: boolean,
  isLoading: boolean,
  filteredList: FuelTypeBriefDto[],
  onHandleChangePage(event: unknown, newPage: number): void,
  onHandleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void,
  onSortHandle(col: string): void
  onSelectDriverForRemove(id: number): void
  onFilterHandle(col: string, value: string): void;
  getFilterList: (col: string) => string[];
  onClearFilter(): void;
  setIsOpenFuelBillPopup(value:boolean):void
  navigateTo(mode: string, id: number): void;
}> = (props) => {
  return (
    <section className={style.gridContainer}>
      <div className={style.gridHeader}>
        <Typography
          noWrap
          component="div"
          className={style.gridTitle}
        >
          <h3>Fuel Prices</h3>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <div className='layout-row'>
          {props.isFiltered &&
            <CustomButton text='Clear filter' textColor='black' bgColor='#bfbfbf' onClick={props.onClearFilter} />
          }
          <CustomButton icon={<AddIcon sx={{ fontSize: '20px' }} />} text='New' onClick={() => props.setIsOpenFuelBillPopup(true)} />
        </div>
      </div>

      <TableContainer component={Paper} className={style.grid}>
        <Table className={style.table}>
          <TableHead>
            <TableRow>
              <CustomHeaderCell width={300} id='id'>#</CustomHeaderCell>
              <CustomHeaderCell width={500} id='fuelType'>Fuel Type</CustomHeaderCell>
              <CustomHeaderCell width={400} id='price' sortable onSort={props.onSortHandle} >Price (LKR)</CustomHeaderCell>
              {/* <CustomHeaderCell width={300} id='actions' >Actions</CustomHeaderCell> */}
            </TableRow>
          </TableHead>
          {props.isLoading && (
            <AppSkeleton numOfRows={props.rowsPerPage} numOfColumns={4} columnWidth={150} tag="table" />
          )}
          {!props.isLoading && props.filteredList.length > 0 &&
            <TableBody>
              {props.filteredList.map((item: FuelTypeBriefDto, index) => (
                <TableRow key={index}>
                  <StyledTableCell >{index + 1}</StyledTableCell>
                  <StyledTableCell >{FUEL_CATEGORY.find((f:OptionsDto) => f.value === item.fuelTypeId)?.label}</StyledTableCell>
                  <StyledTableCell >{item.price}.00</StyledTableCell>
                  {/* <StyledTableCell style={{ backgroundColor: '#282828' }}>
                    <Box className='layout-row'>
                      <Box>
                        <IconButton size='small'>
                          <Tooltip title="View">
                            <VisibilityOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton size='small'>
                          <Tooltip title="Edit">
                            <EditOutlined sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton size='small'>
                          <Tooltip title="Delete">
                            <DeleteOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                    </Box>
                  </StyledTableCell> */}
                </TableRow>
              ))}
            </TableBody>
          }
          {!props.isLoading && props.filteredList.length === 0 &&
            <TableBody>
              <TableRow>
                <StyledTableCell align="left" colSpan={4}>No data to preview</StyledTableCell>
              </TableRow>
            </TableBody>
          }
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_OPTIONS}
        component="div"
        labelRowsPerPage={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: 'white',
            }}
          >
            Items per page
          </div>
        }
        count={-1}
        page={props.page}
        onPageChange={props.onHandleChangePage}
        onRowsPerPageChange={props.onHandleChangeRowsPerPage}
        rowsPerPage={props.rowsPerPage}
        nextIconButtonProps={
          { disabled: -1 < props.filteredList.length && props.filteredList.length < props.rowsPerPage }
        }
        sx={{ backgroundColor: "#282828", color: "white" }}
      />
    </section>
  )
}

export default FuelManagementGrid
