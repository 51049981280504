import { Box, Grid, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import style from './PackageDetails.module.scss'
import React from 'react'
import { CustomButton, CustomHeaderCell } from '../..'
import Stepper from '../../Shared/Stepper/Stepper'
import { StyledTableCell } from '../../../assets/theme/theme'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { NewRequestPackagedetailsDto } from '../../../utilities/models'
import { REQUEST_SCREEN_MODES } from '../../../utilities/constants'
import { EditOutlined } from '@mui/icons-material'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const PackageDetails: React.FC<{
  screenMode: string
  onSortHandle(table: string, col: string): void
  showAddPackagePopup(con: boolean): void
  setSelectedPackageFromList(id: number): void
  onSelectPackageForEdit(id: number): void
  onSelectPackageForView(id: number): void
  packageDetailsList: NewRequestPackagedetailsDto[]
}> = (props) => {
  return (
    <Stepper stepNumber={3} stepTitle={"Package Details"}>
      {props.screenMode !== REQUEST_SCREEN_MODES.VIEW &&
        <section className={style.action}>
          <CustomButton text='Add Package' border='1px solid #6e6e6e' bgColor='#282828' onClick={() => { props.showAddPackagePopup(true) }} />
        </section>
      }
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <section className={style.gridSection}>
            <TableContainer component={Paper} className={style.grid}>
              <Table className={style.table}>
                <TableHead>
                  <TableRow>
                    <CustomHeaderCell width={250} id='dept' >Package Type</CustomHeaderCell>
                    <CustomHeaderCell width={250} id='pickup' >Pickup location</CustomHeaderCell>
                    <CustomHeaderCell width={250} id='drop' >Drop off location</CustomHeaderCell>
                    <CustomHeaderCell width={200} id='drop' >Recipient</CustomHeaderCell>
                    <CustomHeaderCell width={225} id='drop' >Recipient Contact</CustomHeaderCell>
                    <CustomHeaderCell width={250} id='drop' >Instructions</CustomHeaderCell>
                      <CustomHeaderCell width={100} id='actions' >Actions</CustomHeaderCell>
                  </TableRow>
                </TableHead>
                {props.packageDetailsList && props.packageDetailsList.length > 0 &&
                  <TableBody>
                    {props.packageDetailsList.map((p: NewRequestPackagedetailsDto) => (
                      <TableRow key={p.id}>
                        <StyledTableCell >{p.packageType.description}</StyledTableCell>
                        <StyledTableCell >{p.pickup.location}</StyledTableCell>
                        <StyledTableCell >{p.dropOff.location}</StyledTableCell>
                        <StyledTableCell >{p.recipient.name}</StyledTableCell>
                        <StyledTableCell >{p.contactNumber}</StyledTableCell>
                        <StyledTableCell >{p.instructions}</StyledTableCell>
                        {props.screenMode !== REQUEST_SCREEN_MODES.VIEW &&
                          <StyledTableCell style={{ backgroundColor: '#282828' }}>
                            <Box className='layout-row'>
                              <Box>
                                <IconButton size='small' onClick={() => props.setSelectedPackageFromList(p.id)}>
                                  <Tooltip title="Delete">
                                    <DeleteOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                  </Tooltip>
                                </IconButton>
                              </Box>
                              <Box>
                                <IconButton size='small' onClick={() => props.onSelectPackageForEdit(p.id)}>
                                  <Tooltip title="Edit">
                                    <EditOutlined sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                  </Tooltip>
                                </IconButton>
                              </Box>
                            </Box>
                          </StyledTableCell>
                        }
                        {props.screenMode === REQUEST_SCREEN_MODES.VIEW &&
                          <StyledTableCell style={{ backgroundColor: '#282828' }}>
                            <Box className='layout-row'>
                              <Box>
                                <IconButton size='small' onClick={() => props.onSelectPackageForView(p.id)}>
                                  <Tooltip title="View">
                                    <RemoveRedEyeOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                                  </Tooltip>
                                </IconButton>
                              </Box>
                            </Box>
                          </StyledTableCell>
                        }
                      </TableRow>
                    ))}
                  </TableBody>
                }
                {props.packageDetailsList.length === 0 &&
                  <TableBody>
                    <TableRow>
                      <StyledTableCell align="left" colSpan={7}>No data to preview</StyledTableCell>
                    </TableRow>
                  </TableBody>
                }
              </Table>
            </TableContainer>
          </section>
        </Grid>
      </Grid>
    </Stepper>
  )
}

export default PackageDetails
