import {
  APP_ACTION_STATUS,
  COMMON_ACTION_TYPES,
  USER_ACTION_TYPE,
} from "../../utilities/constants";
import { UserStateDto } from "../../utilities/models";

const INITIAL_STATE: UserStateDto = {
  getAllPassengers: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getAllPassengersByDepartment: {
    data: [],
    error: null,
    isLoading: false,
    status: null
  },
  getPassenger: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  getAllRecipients: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
};

const userReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case USER_ACTION_TYPE.GET_ALL_PASSENGERS_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getAllPassengers: {
          ...state.getAllPassengers,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case USER_ACTION_TYPE.GET_ALL_PASSENGERS_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getAllPassengers: {
          ...state.getAllPassengers,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case USER_ACTION_TYPE.GET_ALL_PASSENGERS_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getAllPassengers: {
          ...state.getAllPassengers,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case USER_ACTION_TYPE.GET_ALL_PASSENGERS_LIST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getAllPassengers: {
          ...state.getAllPassengers,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null
        }
      }
    case USER_ACTION_TYPE.GET_ALL_USERS_BY_DEPARTMENT + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getAllPassengersByDepartment: {
          ...state.getAllPassengersByDepartment,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null
        }
      }
    case USER_ACTION_TYPE.GET_ALL_USERS_BY_DEPARTMENT + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getAllPassengersByDepartment: {
          ...state.getAllPassengersByDepartment,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data
        }
      }
    case USER_ACTION_TYPE.GET_ALL_USERS_BY_DEPARTMENT + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getAllPassengersByDepartment: {
          ...state.getAllPassengersByDepartment,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null
        }
      }
    case USER_ACTION_TYPE.GET_ALL_USERS_BY_DEPARTMENT + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getAllPassengersByDepartment: {
          ...state.getAllPassengersByDepartment,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null
        }
      }
    case USER_ACTION_TYPE.GET_PASSENGER_DETAILS + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getPassenger: {
          ...state.getPassenger,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case USER_ACTION_TYPE.GET_PASSENGER_DETAILS + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getPassenger: {
          ...state.getPassenger,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case USER_ACTION_TYPE.GET_PASSENGER_DETAILS + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getPassenger: {
          ...state.getPassenger,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case USER_ACTION_TYPE.GET_PASSENGER_DETAILS + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getPassenger: {
          ...state.getPassenger,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
      case USER_ACTION_TYPE.GET_ALL_RECIPIENTS + COMMON_ACTION_TYPES.REQUEST:
        return {
          ...state,
          getAllRecipients: {
            ...state.getAllRecipients,
            isLoading: true,
            status: APP_ACTION_STATUS.LOADING,
            error: null,
            data: null,
          },
        };
      case USER_ACTION_TYPE.GET_ALL_RECIPIENTS + COMMON_ACTION_TYPES.SUCCESS:
        return {
          ...state,
          getAllRecipients: {
            ...state.getAllRecipients,
            isLoading: false,
            status: APP_ACTION_STATUS.SUCCESS,
            error: null,
            data: action.data,
          },
        };
      case USER_ACTION_TYPE.GET_ALL_RECIPIENTS + COMMON_ACTION_TYPES.ERROR:
        return {
          ...state,
          getAllRecipients: {
            ...state.getAllRecipients,
            isLoading: false,
            status: APP_ACTION_STATUS.ERROR,
            error: action.error,
            data: null,
          },
        };
      case USER_ACTION_TYPE.GET_ALL_RECIPIENTS + COMMON_ACTION_TYPES.CLEAR:
        return {
          ...state,
          getAllRecipients: {
            ...state.getAllRecipients,
            isLoading: false,
            status: APP_ACTION_STATUS.INITIAL,
            error: null,
            data: null
          }
        }
    default:
      return state;
  }
};

export default userReducer;
