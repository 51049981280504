import { AxiosResponse } from "axios"
import { GetTripStatusDto, RealTripPayloadDto } from "../../utilities/models/gps.model"
import { gpsService } from "../../services/gps.service"
import { call, put, takeEvery } from "redux-saga/effects"
import { ALERT_ACTION_TYPES, COMMON_ACTION_TYPES, GPS_ACTION_TYPES } from "../../utilities/constants/action.constant"
import { AlertDto } from "../../utilities/models/alert.model"

function* postRealTripDetails(action: { type: string, payload: RealTripPayloadDto }) {
    try {
        const postRealTripResponse: AxiosResponse<string> = yield call(gpsService.postRealTripDetails, action.payload)
        yield put({
            type: GPS_ACTION_TYPES.POST_REAL_TRIP + COMMON_ACTION_TYPES.SUCCESS,
            data: postRealTripResponse.data
        })
        // const setAlert: AlertDto = {
        //     message: postRealTripResponse.data,
        //     type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
        //     options: {
        //         key: new Date().getTime() + Math.random(),
        //         varient: 'success'
        //     }
        // }
        // yield put(setAlert)
    } catch (error) {
        const setAlert: AlertDto = {
            message: error as string,
            type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
            options: {
                key: new Date().getTime() + Math.random(),
                varient: 'error'
            }
        }
        yield put(setAlert)
        yield put({
            type: GPS_ACTION_TYPES.POST_REAL_TRIP + COMMON_ACTION_TYPES.ERROR,
            error: error
        })
    }
}

function* getActualTripDetails(action: {type: string, existingTripId: string}) {
    try {
      const request: AxiosResponse<GetTripStatusDto> = yield call(gpsService.getActualTripDetails, action.existingTripId)
      yield put({
        type: GPS_ACTION_TYPES.GET_CURRENT_TRIP_SITUATION + COMMON_ACTION_TYPES.SUCCESS,
        data: request.data
      })
    } catch (error) {
      yield put({
        type: GPS_ACTION_TYPES.GET_CURRENT_TRIP_SITUATION + COMMON_ACTION_TYPES.ERROR,
        error: error
      })
    }
  }

  function* resetActualTripDetails(action: { type: string, tripId: string }) {
    try {
        const resetTripResponse: AxiosResponse<string> = yield call(gpsService.resetActualTripDetails, action.tripId)
        yield put({
            type: GPS_ACTION_TYPES.RESET_REAL_TRIP + COMMON_ACTION_TYPES.SUCCESS,
            data: resetTripResponse.data
        })
        const setAlert: AlertDto = {
            message: resetTripResponse.data,
            type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
            options: {
                key: new Date().getTime() + Math.random(),
                varient: 'success'
            }
        }
        yield put(setAlert)
    } catch (error) {
        const setAlert: AlertDto = {
            message: error as string,
            type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
            options: {
                key: new Date().getTime() + Math.random(),
                varient: 'error'
            }
        }
        yield put(setAlert)
        yield put({
            type: GPS_ACTION_TYPES.RESET_REAL_TRIP + COMMON_ACTION_TYPES.ERROR,
            error: error
        })
    }
}  

function* gpsSaga() {
    yield takeEvery(GPS_ACTION_TYPES.POST_REAL_TRIP + COMMON_ACTION_TYPES.REQUEST,  postRealTripDetails);
    yield takeEvery(GPS_ACTION_TYPES.GET_CURRENT_TRIP_SITUATION + COMMON_ACTION_TYPES.REQUEST, getActualTripDetails);
    yield takeEvery(GPS_ACTION_TYPES.RESET_REAL_TRIP + COMMON_ACTION_TYPES.REQUEST, resetActualTripDetails);
}

export default gpsSaga