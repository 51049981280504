import Grid from "@mui/material/Grid/Grid";
import Stepper from "../../Shared/Stepper/Stepper";
import CustomAutocomplete from "../../Shared/CustomAutocomplete/CustomAutocomplete";
import { DAILY_TRANSPORT_MODES, EMPLOYEE_CATEGORY, SHIFT_GROUP, SHIFT_TYPES, } from "../../../utilities/constants/app.constants";
import CustomDatePicker from "../../Shared/CustomDatePicker/CustomDatePicker";
import { AppSkeleton, CustomTimePicker } from "../../Shared";
import { StyledTextField } from "../../../assets/theme/theme";
import { DSGeneralInformationDto, getExecuteTemplateByIdDto, shiftEmployeeCategoriesDto, shiftGroupBriefDto, shiftRequestByIdDto, shiftTypeBriefDto } from "../../../utilities/models/shift.model";
import { LocationBriefDto } from "../../../utilities/models/location.model";
import { SbuBriefDto } from "../../../utilities/models/sbu.model";
import { StateObjectDto } from "../../../utilities/models/common.model";
import { PlantBriefDto } from "../../../utilities/models/plant.model";

const GeneralInformation: React.FC<{
  dsgenaralInformationForm: DSGeneralInformationDto;
  frequentLocationsResponse: LocationBriefDto[];
  allSbuListByResponse: StateObjectDto<SbuBriefDto[] | []>;
  helperText: boolean;
  screenMode: string;
  shiftType: shiftTypeBriefDto[];
  shiftGroup: shiftGroupBriefDto[];
  handleInputFocus(property: string, section: string): void;
  onInputHandleChangeGeneralInformation(property: string, section: string): void;
  allPlantsBySbuIdResponse: StateObjectDto<PlantBriefDto[] | []>;
  assignRouteList: any[];
  getShiftRequestByIdResponse: StateObjectDto<shiftRequestByIdDto | null>;
  getExecutionIdResponse: StateObjectDto<getExecuteTemplateByIdDto | null>;
  duplicateMinDate:string;
}> = (props) => {

  const _sbu = props.dsgenaralInformationForm.sbu;
  const _plant = props.dsgenaralInformationForm.plant;
  const _toLocation = props.dsgenaralInformationForm.toLocation;
  const _empCategory = props.dsgenaralInformationForm.employeeCategory;
  const _shiftGroup = props.dsgenaralInformationForm.shiftGroup;
  const _shiftType = props.dsgenaralInformationForm.shiftType;
  const _applicableDate = props.dsgenaralInformationForm.applicableDate;

  const _allSbu = props.allSbuListByResponse !== undefined && props.allSbuListByResponse?.data.length > 0 ? props.allSbuListByResponse.data : [];
  const _allPlants = props.allPlantsBySbuIdResponse !== undefined && props.allPlantsBySbuIdResponse?.data?.length > 0 ? props.allPlantsBySbuIdResponse.data : [];
  const dupMinDate = new Date(props.duplicateMinDate);
  dupMinDate.setDate(dupMinDate.getDate() + 1);

  return (
    <Stepper stepNumber={1} stepTitle={"General Information"}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          {props.getShiftRequestByIdResponse && props.getShiftRequestByIdResponse.isLoading
            ? (
              <AppSkeleton numOfRows={1} numOfColumns={0} tag="formField" />) : (
              <CustomAutocomplete
                label="SBU"
                placeholder="Select sbu"
                onFocus={() => props.handleInputFocus("sbu", "GI")}
                options={_allSbu.map((t) => {
                  return { label: t.name, value: t.id };
                })}
                value={_sbu.value}
                error={!!_sbu.error}
                disabled={_sbu.disable}
                readonly={_sbu.readonly}
                required={_sbu.isRequired}
                helperText={props.helperText && _sbu.error}
                onChange={(event: any, value: any) =>
                  props.onInputHandleChangeGeneralInformation("sbu", value)
                }
              />
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          {props.getShiftRequestByIdResponse && props.getShiftRequestByIdResponse.isLoading
            ? (
              <AppSkeleton numOfRows={1} numOfColumns={0} tag="formField" />) : (
              <CustomAutocomplete
                label="Plant"
                placeholder="Select Plant"
                onFocus={() => props.handleInputFocus("plant", "GI")}
                options={_allPlants.map((p) => {
                  return { label: p.plantName, value: p.id };
                })}
                value={_plant.value}
                error={!!_plant.error}
                disabled={_plant.disable}
                readonly={_plant.readonly}
                required={_plant.isRequired}
                helperText={props.helperText && _plant.error}
                onChange={(event: any, value: any) =>
                  props.onInputHandleChangeGeneralInformation("plant", value)
                }
              />
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          {props.getShiftRequestByIdResponse && props.getShiftRequestByIdResponse.isLoading
            ? (
              <AppSkeleton numOfRows={1} numOfColumns={0} tag="formField" />) : (
              <CustomAutocomplete
                label="Employee Category"
                placeholder="Select employee category"
                options={EMPLOYEE_CATEGORY.map((t: shiftEmployeeCategoriesDto) => {
                  return { label: t.label, value: t.value };
                })}
                value={_empCategory.value}
                error={!!_empCategory.error}
                disabled={_empCategory.disable}
                readonly={_empCategory.readonly}
                required={_empCategory.isRequired}
                helperText={props.helperText && _empCategory.error}
                onFocus={() => props.handleInputFocus("empCategory", "GI")}
                onChange={(event: any, value: any) => props.onInputHandleChangeGeneralInformation("empCategory", value)}
              />
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          {props.getShiftRequestByIdResponse && props.getShiftRequestByIdResponse.isLoading
            ? (
              <AppSkeleton numOfRows={1} numOfColumns={0} tag="formField" />) : (
              <CustomAutocomplete
                label="Shift Group"
                placeholder="Select shift group"
                options={props.shiftGroup.map((t: shiftGroupBriefDto) => {
                  return { label: t.name, value: t.id };
                })}
                value={_shiftGroup.value}
                error={!!_shiftGroup.error}
                disabled={_shiftGroup.disable}
                readonly={_shiftGroup.readonly}
                required={_shiftGroup.isRequired}
                helperText={props.helperText && _shiftGroup.error}
                onFocus={() => props.handleInputFocus("shiftGroup", "GI")}
                onChange={(event: any, value: any) => props.onInputHandleChangeGeneralInformation("shiftGroup", value)}
              />
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          {props.getShiftRequestByIdResponse && props.getShiftRequestByIdResponse.isLoading
            ? (
              <AppSkeleton numOfRows={1} numOfColumns={0} tag="formField" />) : (
              <CustomAutocomplete
                label="Shift"
                placeholder="Select shift types"
                options={props.shiftType.map((t: shiftTypeBriefDto) => {
                  return { label: t.shiftType, value: t.id };
                })}
                value={_shiftType.value}
                error={!!_shiftType.error}
                disabled={_shiftType.disable}
                readonly={_shiftType.readonly}
                required={_shiftType.isRequired}
                helperText={props.helperText && _shiftType.error}
                onFocus={() => props.handleInputFocus("shiftType", "GI")}
                onChange={(event: any, value: any) => props.onInputHandleChangeGeneralInformation("shiftType", value)}
              />
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          {props.getShiftRequestByIdResponse && props.getShiftRequestByIdResponse.isLoading
            ? (
              <AppSkeleton numOfRows={1} numOfColumns={0} tag="formField" />) : (
              <CustomAutocomplete
                label="Plant Location"
                placeholder="Select Location"
                options={props.frequentLocationsResponse && props.frequentLocationsResponse.map((l: LocationBriefDto) => { return { label: l.location, value: l.id } })}
                value={{ label: _toLocation.value.location, value: _toLocation.value.id }}
                error={!!_toLocation.error}
                disabled={_toLocation.disable || props.assignRouteList.length > 0}
                readonly={_toLocation.readonly}
                required={_toLocation.isRequired}
                helperText={props.helperText && _toLocation.error}
                onFocus={() => props.handleInputFocus("toLocation", "GI")}
                onChange={(event: any, value: any) => props.onInputHandleChangeGeneralInformation("toLocation", value)}
              />
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          {props.getShiftRequestByIdResponse && props.getShiftRequestByIdResponse.isLoading
            ? (
              <AppSkeleton numOfRows={1} numOfColumns={0} tag="formField" />) : (
              <CustomDatePicker
                label="Applicable Date Range"
                placeholder="Select date"
                multiple={(props.screenMode === DAILY_TRANSPORT_MODES.DUPLICATE || props.screenMode === DAILY_TRANSPORT_MODES.CREATE) ? false : true}
                range={true}
                value={_applicableDate.value}
                minDate={props.screenMode === DAILY_TRANSPORT_MODES.DUPLICATE ? dupMinDate : new Date()}
                error={!!_applicableDate.error}
                disabled={_applicableDate.disable}
                readOnly={_applicableDate.readonly}
                required={_applicableDate.isRequired}
                helperText={props.helperText && _applicableDate.error}
                onFocus={() => props.handleInputFocus("applicableDate", "GI")}
                onChange={(value: any) => props.onInputHandleChangeGeneralInformation("applicableDate", value)}
              />
            )}
        </Grid>
      </Grid>
    </Stepper>
  );
};

export default GeneralInformation;
