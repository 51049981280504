import { TRIP_ACTION_TYPES, COMMON_ACTION_TYPES } from "../../utilities/constants"
import { getVehicleListParamDto, rejectAllocationParam, tripAllocationDto, tripDetailsParamDto, vehicleDetailsParamDto } from "../../utilities/models"

const getTripDetailsByRequestIds = (payload: tripDetailsParamDto) => {
  return {
    type: TRIP_ACTION_TYPES.GET_TRIP_DETAILS + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }
}

const getVehicleLitsBYPassengerCount = (payload: getVehicleListParamDto) => {
  return {
    type: TRIP_ACTION_TYPES.GET_VEHICLE_LIST_BY_PASSENGER_COUNT + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }

}

const getVehicleDetailsByVehicleType = (payload: vehicleDetailsParamDto) => {
  return {
    type: TRIP_ACTION_TYPES.GET_VEHICLE_DETAILS_LIST + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }
}

const postTripAllocationDetails = (payload: tripAllocationDto) => {
  return {
    type: TRIP_ACTION_TYPES.POST_TRIP_ALLOCATION + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }
}

const updateTripAllocationDetails = (payload: any) => {
  return {
    type: TRIP_ACTION_TYPES.PUT_UPDATE_TRIP_DETAILS + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }
}

const postTripAllocationDetailsClear = () => {
  return {
    type: TRIP_ACTION_TYPES.POST_TRIP_ALLOCATION + COMMON_ACTION_TYPES.CLEAR
  }
}

const getVehicleDetailsByVehicleClear = () => {
  return { type: TRIP_ACTION_TYPES.GET_VEHICLE_DETAILS_LIST + COMMON_ACTION_TYPES.CLEAR }
}

const getVehicleLitsBYPassengerCountClear = () => {
  return { type: TRIP_ACTION_TYPES.GET_VEHICLE_LIST_BY_PASSENGER_COUNT + COMMON_ACTION_TYPES.CLEAR }
}

const getTripDetailsByRequestIdsClear = () => {
  return { type: TRIP_ACTION_TYPES.GET_TRIP_DETAILS + COMMON_ACTION_TYPES.CLEAR }
}

const postRejectTripAllocation = (payload: rejectAllocationParam) => {
  return {
    type: TRIP_ACTION_TYPES.POST_REJECT_TRIP_ALLOCATION + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }
}

const updateTripAllocationDetailsClear = () => {
  return { type: TRIP_ACTION_TYPES.PUT_UPDATE_TRIP_DETAILS + COMMON_ACTION_TYPES.CLEAR }
}


export const tripActions = {
  getTripDetailsByRequestIds,
  getVehicleLitsBYPassengerCount,
  getVehicleDetailsByVehicleType,
  postTripAllocationDetails,
  updateTripAllocationDetails,
  postTripAllocationDetailsClear,
  getVehicleDetailsByVehicleClear,
  getVehicleLitsBYPassengerCountClear,
  getTripDetailsByRequestIdsClear,
  postRejectTripAllocation,
  updateTripAllocationDetailsClear,

}