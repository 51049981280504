/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AppLayout from "../../templates/AppLayout/AppLayout";
import { DailyShiftGrid } from "../../components/DailyShiftTransportation";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../utilities/constants/routes.constants";
import { GetExecutionTemplateDto, shiftRequestSummaryListDto, shiftRequestSummaryParam } from "../../utilities/models/shift.model";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationStateDto, SortMetaDto } from "../../utilities/models";
import { APP_ACTION_STATUS, APP_TABLE_CONFIGS, DAILY_TRANSPORT_MODES } from "../../utilities/constants";
import { shiftActions } from "../../redux/actions";
import ConfirmationDialog from "../../components/Shared/ConfirmationDialog/ConfirmationDialog";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab } from "@mui/material";
import MyProfile from "../../components/UserProfile/MyProfile/MyProfile";
import style from './DailyShiftTransportation.module.scss'
import ExecutionHistoryTable from "../../components/DailyShiftTransportation/ExecutionHistoryTable/ExecutionHistoryTable";

const DailyShiftTransportation = () => {
  const INITIAL_SORT_META: SortMetaDto = {
    field: "",
    asc: false,
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
  const [isFiltered, setIsFiltered] = useState(false);
  const [sortMeta, setSortMeta] = useState<SortMetaDto>(INITIAL_SORT_META);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [isOpenExecute, setIsOpenExecute] = useState(false);

  const [selectedTemplateId, setSelectedTemplateId] = useState(-1)
  const [filteredList, setFilteredList] = useState<shiftRequestSummaryListDto[]>([]);

  const getRequestDataResponse = useSelector((state: ApplicationStateDto) => state.shift.getShiftRequestList);
  const deleteRequestDataResponse = useSelector((state: ApplicationStateDto) => state.shift.deleteShiftRequest);
  const GetExecutionTemplatesResponse = useSelector((state: ApplicationStateDto) => state.shift.getExecutionTemplates);

  useEffect(() => {
    if (getRequestDataResponse.status === APP_ACTION_STATUS.SUCCESS) {
      if(getRequestDataResponse.data.length > 0){
        setFilteredList(getRequestDataResponse.data);
      }else setFilteredList([]);
    }
  }, [getRequestDataResponse.status]);


  useEffect(() => {
    dispatch(shiftActions.getShiftRequestByIdClear())
    getRequestSummaryList(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE, 1);
    dispatch(shiftActions.GetExecutionTemplates());
  }, []);

  useEffect(() => {
    if (deleteRequestDataResponse.status === APP_ACTION_STATUS.SUCCESS) {
      getRequestSummaryList(rowsPerPage, 1);
      dispatch(shiftActions.deleteShiftRequestClear())
    }
  }, [deleteRequestDataResponse.status])

  const getRequestSummaryList = async (r: number, p: number) => {
    const _param: shiftRequestSummaryParam = { pagination: r, pageNumber: p, };
    dispatch(shiftActions.getShiftRequestList(_param));
  };

  const onNavigate = (mode: string, id: number) => {
    if (mode === DAILY_TRANSPORT_MODES.CREATE) {
      navigate(APP_ROUTES.CREATE_DS_TRANSPORTATION, { state: { mode: mode } })
    }
    if (mode === DAILY_TRANSPORT_MODES.EDIT) {
      navigate(APP_ROUTES.EDIT_DS_TRANSPORTATION, { state: { mode: mode, id: id } })
    }
    if (mode === DAILY_TRANSPORT_MODES.VIEW) {
      navigate(APP_ROUTES.VIEW_DS_TRANSPORTATION, { state: { mode: mode, id: id } })
    }
    if (mode === DAILY_TRANSPORT_MODES.EXECUTE) {
      navigate(APP_ROUTES.EXECUTE_DS_TRANSPORTATION, { state: { mode: mode, id: id } })
    }
    if(mode === DAILY_TRANSPORT_MODES.DUPLICATE){
      navigate(APP_ROUTES.DUPLICATE_DS_TRANSPORTATION, { state: { mode: mode, id: id } })
    }
    if (mode === DAILY_TRANSPORT_MODES.VIEW_EXECUTED) {
      navigate(APP_ROUTES.EXECUTE_DS_TRANSPORTATION, { state: { mode: mode, id: id } })
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    getRequestSummaryList(rowsPerPage, newPage + 1);
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    getRequestSummaryList(+event.target.value, 1)
    setPage(0)
  }

  const onClearFilter = () => {
    setIsFiltered(false)
    setFilteredList(getRequestDataResponse.data)
  }

  const onSortHandle = (col: string) => {
    const sorted = filteredList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setFilteredList(sorted);
  };

  const onFilterHandle = (col: string, value: string) => {
    setIsFiltered(true)
    setPage(0)
    const _list = getRequestDataResponse.data
   
    const filtered = _list.filter((item) => {
      console.log(item);
      const _value = (item as any)[col];
      if (typeof _value === "boolean") {
        return _value ? value === "Yes" : value === "None";
      }
      return _value === value;
    });

    setFilteredList(filtered);
  };

  const getFilterList = (col: string): string[] => {
    if (!getRequestDataResponse.isLoading) {
      const _list = getRequestDataResponse.data
      return _list
        .map((item) => {
          const value = (item as any)[col];
          if (typeof value === "boolean") {
            return value ? "Yes" : "None";
          }
          return value;
        })
        .filter((value, index, array) => array.indexOf(value) === index);
    }
    else return []
  };

  const onSelectTemplateForDelete = (id: number) => {
    setSelectedTemplateId(id)
    setIsOpenConfirmation(true)
  }

  const onTemplateDeletion = (con: boolean) => {
    setIsOpenConfirmation(false)
    if (con) {
      dispatch(shiftActions.deleteShiftRequest(selectedTemplateId))
    }
  }



  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  
  const viewAvailability = (value: number) => {
    setIsOpenExecute(true);
    // setPassengerCount(value);
    // setisOpenViewVehicleDetailsPopUp(true);

    // const Count: getVehicleListParamDto = {
    //   NOSeats: passengerCount.toString(),
    // };
    // dispatch(tripActions.getVehicleLitsBYPassengerCount(Count));
  };

  const getFilterList2 = (col: string): string[] => {
    if (!getRequestDataResponse.isLoading) {
      const _list = GetExecutionTemplatesResponse.data
      return _list
        .map((item) => {
          const value = (item as any)[col];
          if (typeof value === "boolean") {
            return value ? "Yes" : "None";
          }
          return value;
        })
        .filter((value, index, array) => array.indexOf(value) === index);
    }
    else return []
  };

  const onSortHandle2 = (col: string) => {
    const sorted = executionData.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setExecutionData(sorted);
  };

  const onFilterHandle2 = (col: string, value: string) => {
    setIsFiltered2(true)
    setPage2(0)
    const _list = GetExecutionTemplatesResponse.data
    const filtered = _list.filter((item) => {
      const _value = (item as any)[col];
      if (typeof _value === "boolean") {
        return _value ? value === "Yes" : value === "None";
      }
      return _value === value;
    });

    setExecutionData(filtered);
  };

  useEffect(() => {
    if (GetExecutionTemplatesResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setExecutionData(GetExecutionTemplatesResponse.data)
    }
  }, [GetExecutionTemplatesResponse.status])

  const handleChangePage2 = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage2(+event.target.value)
    setPage2(0)
  }

  const onClearFilter2 = () => {
    setIsFiltered2(false)
    setExecutionData(GetExecutionTemplatesResponse.data)
  }

  const [page2, setPage2] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10)
  const [isFiltered2, setIsFiltered2] = useState(false);
  const [executionData, setExecutionData] = useState<GetExecutionTemplateDto[]>([])
  return (
    <React.Fragment>
      <AppLayout componentTitle="DailyTransportation">
        <section className="page-root">

          <TabContext value={value.toString()}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} >
                <TabList
                  sx={{
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                  onChange={handleChange}
                  orientation="horizontal"
                >
                  <Tab sx={{
                    marginBlock: "0.5rem",
                    marginLeft: "1rem",
                    "&.Mui-selected": {
                      marginLeft: "1.5rem",
                      backgroundColor: "rgba(255, 0, 0, 0.1)",
                      color: "white",
                    },
                    borderRadius: "0.8rem",
                    color: "white",
                  }}
                    label="Templates"
                    value="1"
                  />
                  <Tab sx={{
                    
                    marginBlock: "0.5rem",
                    marginLeft: "1rem",
                    "&.Mui-selected": {
                      marginInline: "1rem",
                      backgroundColor: "rgba(255, 0, 0, 0.1)",
                      color: "white",
                    },
                    borderRadius: "0.8rem",
                    color: "white",
                  }}
                    label="Execution History"
                    value="2"
                  />
                </TabList>
              </Grid>

              <Grid item xs={12} md={12}>
                <TabPanel value="1">
                  <DailyShiftGrid
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onHandleChangePage={handleChangePage}
                    getFilterList={getFilterList}
                    isLoading={getRequestDataResponse.isLoading}
                    onFilterHandle={onFilterHandle}
                    onHandleChangeRowsPerPage={handleChangeRowsPerPage}
                    isFiltered={isFiltered}
                    filteredList={filteredList}
                    onClearFilter={onClearFilter}
                    onSortHandle={onSortHandle}
                    navigateTo={onNavigate}
                    onSelectTemplateForDelete={onSelectTemplateForDelete}
                  />
                </TabPanel>
                <TabPanel value="2">
                  <ExecutionHistoryTable
                    page={page2}
                    rowsPerPage={rowsPerPage2}
                    onHandleChangePage={handleChangePage2}
                    getFilterList={getFilterList2}
                    isLoading={GetExecutionTemplatesResponse.isLoading}
                    onFilterHandle={onFilterHandle2}
                    onHandleChangeRowsPerPage={handleChangeRowsPerPage2}
                    isFiltered={isFiltered2}
                    filteredList={executionData}
                    onClearFilter={onClearFilter2}
                    onSortHandle={onSortHandle2}
                    navigateTo={onNavigate}
                  />
                </TabPanel>
              </Grid>
            </Grid>
          </TabContext>

          <ConfirmationDialog
            isOpenConfirmationDialog={isOpenConfirmation}
            onCallback={onTemplateDeletion}
            title="Delete Template"
            content="Do you want to delete this template?"
            confirmButtonTitle="Yes"
            cancelButtonTitle="No"
          />

        </section>
      </AppLayout>
    </React.Fragment>
  );
};

export default DailyShiftTransportation;
