import React, { useEffect, useState } from "react";
import { AppLayout } from "../../templates";
import style from "./VehicleAllocationScreen.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import { AlertDto, ApplicationStateDto, AuthorizedUserDto, SortMetaDto, VehicleTypesDto, selectedType, } from "../../utilities/models";
import { ALERT_ACTION_TYPES, APP_ACTION_STATUS, APP_ROUTES, APP_TABLE_CONFIGS, COMMON_ACTION_TYPES, USER_ROLES, } from "../../utilities/constants";
import {
  GeneralInformationDto, ItemDetailsDto, PassengerDetailsDto, TravelDetailsDto, getVehicleListParamDto, tripAllocationDto,
  tripDetailsParamDto, tripLocations, updateTripDetailsParam, vehicleDetailsListDto, vehicleDetailsParamDto,
} from "../../utilities/models/trip.model";
import { useDispatch, useSelector } from "react-redux";
import { tripActions } from "../../redux/actions/trip.action";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { PassengerAndPackageDetails, ProjectedRout, SelectVehicle, VehicleAllocationGeneralInformation, } from "../../components/VehicalAllocationScreen";
import dayjs from "dayjs";
import { ConfirmationDialog, CustomButton } from "../../components";
import { alertActions, mapActions } from "../../redux/actions";

const VehicalAllocationScreen = () => {
  const INITIAL_SORT_META: SortMetaDto = {
    field: "",
    asc: false,
  };

  const [sortMeta, setSortMeta] = useState<SortMetaDto>(INITIAL_SORT_META);
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE);
  const [isOpenConfirmationDialog, setisOpenConfirmationDialog] = useState(false);
  const [isOpenConfirmationDialog2, setisOpenConfirmationDialog2] = useState(false);

  const [generalInformation, setGeneralInformation] = useState<GeneralInformationDto[]>([]);
  const [travelDetails, setTravelDetails] = useState<TravelDetailsDto[]>([]);
  const [PassengerDetails, setPassengerDetails] = useState<PassengerDetailsDto[]>([]);
  const [packageDetails, setPackageDetails] = useState<ItemDetailsDto[]>([]);
  const [vehicleDetails, setVehicleDetails] = useState<vehicleDetailsListDto[]>([]);
  const [createdTripDetails, setCreatedTripDetails] = useState<vehicleDetailsListDto[]>([]);
  const [VehicleTypeList, setVehicleTypeList] = useState<VehicleTypesDto[]>([]);
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const [VehicleTypeError, setVehicleTypeError] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [IsView, setisView] = useState(false);
  const [allocationID, setAllocationID] = useState("");
  const [TripAllocationDetails, setTripAllocationDetails] = useState<tripAllocationDto>();
  const [isFiltered, setIsFiltered] = useState(false);

  const [StartingPoints, setStartingPoints] = useState<string[]>([]);
  const [endPoints, setEndPoints] = useState<string[]>([]);
  const [selectedStartingPoint, setselectedStartingPoint] = useState("");
  const [selectedStartePointLatANdLang, setselectedStartePointLatANdLang] = useState<selectedType>()
  const [selectedEndPointLatANdLang, setselectedEndPointtLatANdLang] = useState<selectedType>()

  const [PickUpPoints, setPickUpPoints] = useState<selectedType[]>([]);
  const [PassengerPickPoints, setPassengerPickPoints] = useState<selectedType[]>([]);
  const [PackagePickPoints, setPackagePickPoints] = useState<selectedType[]>([]);

  const [DropOffPoints, setDropOffPoints] = useState<selectedType[]>([]);
  const [PassengerdropPoints, setPassengerDropOffPoints] = useState<selectedType[]>([]);
  const [PackageDropOffPoints, setPackageDropOffPoints] = useState<selectedType[]>([]);

  const [selectedEndPoint, setselectedEndPoint] = useState("");
  const [selectedStartingPointError, setselectedStartingPointError] = useState(false);
  const [selectedEndPointError, setselectedEndPointError] = useState(false);
  const [reason, setReason] = useState("");
  const dispatch = useDispatch();

  const vehicleAllocationEnum = {
    vehicleType: "vehicleType",
    StartPoint: "StartPoint",
    EndPoint: "EndPoint",
    reason: "reason",
  };

  const vehicleDetailsListResponse = useSelector((state: ApplicationStateDto) => state.trip.getVehicleDetailsList);
  const vehicleTypeListResponse = useSelector((state: ApplicationStateDto) => state.trip.getVehicleList);
  const TripDetailsResponse = useSelector((state: ApplicationStateDto) => state.trip.getTripDetails);
  const allocationstateResponse = useSelector((state: ApplicationStateDto) => state.trip.postTripAllocation);
  const tripModificationResponse = useSelector((state: ApplicationStateDto) => state.trip.updateTripAllocation);
  const GetTotalDistanceResponse = useSelector((state: ApplicationStateDto) => state.map.setTotalDistance);
  const GetTotalDurationResponse = useSelector((state: ApplicationStateDto) => state.map.setTotalDuration);

  const [totalDistance, settotalDistance] = useState(0)
  const [totalDuration, setTotalDuration] = useState<number>();

  useEffect(() => {
    if (GetTotalDistanceResponse.status === APP_ACTION_STATUS.SUCCESS) {
      settotalDistance(Number(GetTotalDistanceResponse.data))
    }
  }, [GetTotalDistanceResponse.status])

  useEffect(() => {
    if (GetTotalDurationResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setTotalDuration(Math.floor(GetTotalDurationResponse.data / 60))
    }
  }, [GetTotalDurationResponse.status])

  // const formatDuration = (durationInSeconds: number) => {
  //   const hours = Math.floor(durationInSeconds / 3600);
  //   const minutes = Math.floor((durationInSeconds % 3600) / 60);
  //   return `${hours} hrs ${minutes} mins`;
  // }

  useEffect(() => {

    if (PassengerDetails?.length > 0) {

      const startLocationLatAndLngArray = PassengerDetails.map(passenger => ({
        lat: Number(passenger.startLocationLatitude),
        lng: Number(passenger.startLocationLongitude)
      }));

      const intermediateLocationLatAndLngArray = PassengerDetails
        .filter(passenger => passenger.intermediateLocationLatitude !== "" && passenger.intermediateLocationLongitude !== "")
        .map(passenger => ({
          lat: Number(passenger.intermediateLocationLatitude),
          lng: Number(passenger.intermediateLocationLongitude)
        }));

      const combinedLocationLatAndLngArray = [...startLocationLatAndLngArray, ...intermediateLocationLatAndLngArray];
      // Convert the combined array elements to strings for Set
      const combinedLocationStrings = combinedLocationLatAndLngArray.map(location => JSON.stringify(location));
      // Create a Set of the strings to ensure uniqueness
      const uniqueLocationStringsSet = new Set(combinedLocationStrings);
      // Convert the strings back to JSON objects
      const uniqueLocationLatAndLngArray = Array.from(uniqueLocationStringsSet).map(locationString => JSON.parse(locationString));
      console.log("uniqueLocationLatAndLngArray", uniqueLocationLatAndLngArray);
      setPassengerPickPoints(uniqueLocationLatAndLngArray)
    }

    const toLocationLatAndLngarray = PassengerDetails.map(passenger => ({
      lat: Number(passenger.destinationLatitude),
      lng: Number(passenger.destinationLongitude)
    }));

    console.log("toLocationLatAndLngarray", toLocationLatAndLngarray);
    const dropLocationLatAndLngArray = PassengerDetails
      .filter(passenger => passenger.dropLocationLatitude !== "" && passenger.dropLocationLongitude !== "")
      .map(passenger => ({
        lat: Number(passenger.dropLocationLatitude),
        lng: Number(passenger.dropLocationLongitude)
      }));
    console.log("dropLocationLatAndLngArray", dropLocationLatAndLngArray);
    const combinedLocationLatAndLngArray = [...toLocationLatAndLngarray, ...dropLocationLatAndLngArray];
    console.log("combinedLocationLatAndLngArray", combinedLocationLatAndLngArray);
    const combinedLocationStrings = combinedLocationLatAndLngArray.map(location => JSON.stringify(location));
    const uniqueLocationStringsSet = new Set(combinedLocationStrings);
    const uniqueLocationLatAndLngArray = Array.from(uniqueLocationStringsSet).map(locationString => JSON.parse(locationString));


    console.log("uniqueLocationLatAndLngArray drop", uniqueLocationLatAndLngArray);
    setPassengerDropOffPoints(uniqueLocationLatAndLngArray)

  }, [PassengerDetails])



  useEffect(() => {

    const pickupLatAndLngArry: selectedType[] = packageDetails.filter(passenger => passenger.fromLocationLatitude !== "" && passenger.fromLocationLongitude !== "").map(
      (packages) => {
        return {
          lat: Number(packages.fromLocationLatitude),
          lng: Number(packages.fromLocationLongitude)
        }
      }
    )

    const dropOffLatAndLngArry: selectedType[] = packageDetails.filter(passenger => passenger.dropLocationLatitude !== "" && passenger.dropLocationLongitude !== "").map(
      (packages) => {
        return {
          lat: Number(packages.dropLocationLatitude),
          lng: Number(packages.dropLocationLongitude)
        }
      }
    )

    setPackagePickPoints(pickupLatAndLngArry)
    setPackageDropOffPoints(dropOffLatAndLngArry)

  }, [packageDetails])

  useEffect(() => {


    if (PassengerPickPoints.length > 0) {
      const combinedPickUpPoints = PassengerPickPoints.concat(PackagePickPoints);
      const uniqueLocationLatAndLngArray: selectedType[] = Array.from(new Set(combinedPickUpPoints));
      setPickUpPoints(uniqueLocationLatAndLngArray);
    }
    if (PassengerdropPoints.length > 0) {
      const combinedPickUpPoints = PassengerdropPoints.concat(PackageDropOffPoints);
      const uniqueLocationLatAndLngArray: selectedType[] = Array.from(new Set(combinedPickUpPoints));

      setDropOffPoints(uniqueLocationLatAndLngArray)
    }

  }, [PackageDropOffPoints.length, PackagePickPoints.length, PassengerPickPoints.length])

  useEffect(() => {

    if (selectedStartePointLatANdLang) {
      dispatch(mapActions.AddStartMapPoint(selectedStartePointLatANdLang));
    }
    if (selectedEndPointLatANdLang) {
      dispatch(mapActions.AddEndMapPoint(selectedEndPointLatANdLang));
    }
    if (PickUpPoints.length > 0) {
      console.log("PickUpPoints", PickUpPoints);
      dispatch(mapActions.ADDPickUpPoints(PickUpPoints));
    }
    if (DropOffPoints.length > 0) {
      console.log("DropOffPoints", DropOffPoints);
      dispatch(mapActions.addDropOffPoints(DropOffPoints));
    }
  }, [selectedStartePointLatANdLang?.lat, selectedStartePointLatANdLang?.lng, selectedEndPointLatANdLang, PickUpPoints, PassengerPickPoints, DropOffPoints])



  useEffect(() => {
    return () => {
      dispatch(tripActions.postTripAllocationDetailsClear());
      dispatch(tripActions.postTripAllocationDetailsClear());
      dispatch(tripActions.getVehicleDetailsByVehicleClear());
      dispatch(tripActions.getVehicleLitsBYPassengerCountClear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (generalInformation?.length || 1 > 0) {
      const startArray = generalInformation?.map((item) => item.fromLocation);
      const startlat = generalInformation?.map((item) => item.fromLocationLatitude);
      const startlng = generalInformation?.map((item) => item.fromLocationLongitude);
      const endArray = generalInformation?.map((item) => item.toLocation);
      const endlat = generalInformation?.map((item) => item.toLocationLatitude);
      const endlng = generalInformation?.map((item) => item.toLocationLongitude);

      // Use a Set to store unique values
      const uniqueValues = new Set(startArray);
      // Convert the Set back to an array
      const uniqueArray = Array.from(uniqueValues);
      // Use a Set to store unique values
      const uniqueValues2 = new Set(endArray);
      // Convert the Set back to an array
      const uniqueArray2 = Array.from(uniqueValues2);



      setStartingPoints(uniqueArray);
      setselectedStartePointLatANdLang({ lat: Number(startlat), lng: Number(startlng) })
      setEndPoints(uniqueArray2);
      setselectedEndPointtLatANdLang({ lat: Number(endlat), lng: Number(endlng) })
    }
    if (generalInformation?.length === 1) {
      const startArray = generalInformation?.map((item) => item.fromLocation);
      const endArray = generalInformation?.map((item) => item.toLocation);
      const startlat = generalInformation?.map((item) => item.fromLocationLatitude);
      const startlng = generalInformation?.map((item) => item.fromLocationLongitude);
      const endlat = generalInformation?.map((item) => item.toLocationLatitude);
      const endlng = generalInformation?.map((item) => item.toLocationLongitude);
      setselectedStartingPoint(startArray[0]);
      setselectedStartePointLatANdLang({ lat: Number(startlat[0]), lng: Number(startlng[0]) })
      setselectedEndPoint(endArray[0]);
      setselectedEndPointtLatANdLang({ lat: Number(endlat[0]), lng: Number(endlng[0]) })

    }
  }, [generalInformation]);


  useEffect(() => {
    dispatch(mapActions.AddStartMapPointClear());
    dispatch(mapActions.ADDPickUpPointsClear());
    dispatch(mapActions.AddEndMapPointClear());
    dispatch(mapActions.addDropOffPointsClear());
  }, [])


  useEffect(() => {
    if (vehicleTypeListResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setVehicleTypeList(vehicleTypeListResponse.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleTypeListResponse.status]);

  useEffect(() => {
    if (allocationstateResponse.status === APP_ACTION_STATUS.SUCCESS) {
      navigate(APP_ROUTES.TM_ALLOCATION_SUMMARY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocationstateResponse.status]);

  useEffect(() => {
    if (tripModificationResponse.status === APP_ACTION_STATUS.SUCCESS) {
      navigate(APP_ROUTES.TM_ALLOCATION_SUMMARY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripModificationResponse.status]);

  useEffect(() => {
    if (vehicleDetailsListResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setVehicleDetails(vehicleDetailsListResponse.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleDetailsListResponse.status]);

  useEffect(() => {
    if (TripDetailsResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setGeneralInformation(
        TripDetailsResponse.data.manageTripDetailsDTO_MTDGeneralInformationDTO
      );
      setPassengerDetails(
        TripDetailsResponse.data.manageTripDetailsDTO_MTDPassengerDetailsDTO
      );
      setTravelDetails(
        TripDetailsResponse.data
          .manageTripDetailsDTO_MTDPassengerAndPackageDetailsDTO
      );
      setPackageDetails(
        TripDetailsResponse.data.manageTripDetailsDTO_MTDPackageDetailsDTO
      );
      setCreatedTripDetails(
        TripDetailsResponse.data.manageTripDetailsDTO_MTDSelectedTripDetailsDTO
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TripDetailsResponse.status]);
  const authorizedUser: AuthorizedUserDto = useSelector(
    (state: ApplicationStateDto) => state.authUser.authorizedUser.data
  );
  const handleCancelClick = () => {

    dispatch(mapActions.AddStartMapPointClear());
    dispatch(mapActions.ADDPickUpPointsClear());
    dispatch(mapActions.AddEndMapPointClear());
    dispatch(mapActions.addDropOffPointsClear());
    dispatch(tripActions.postTripAllocationDetailsClear());
    dispatch(tripActions.getVehicleDetailsByVehicleClear());
    dispatch(tripActions.getVehicleLitsBYPassengerCountClear());
    dispatch(tripActions.postTripAllocationDetailsClear());
    dispatch(tripActions.getTripDetailsByRequestIdsClear())

    { (authorizedUser.roleId === USER_ROLES.REGULAR_USER) ? (navigate(APP_ROUTES.GU_DASHBOARD)) : navigate(APP_ROUTES.TM_VEHICLE_ALLOCATION); }

  };




  useEffect(() => {
    if (TripDetailsResponse.status === APP_ACTION_STATUS.SUCCESS) {

      if (PassengerDetails) {
        const totalPassengerCount = travelDetails.reduce((acc, travelDetail) => {
          return acc + (Number(travelDetail?.passengerCount) || 0);
        }, 0);
        const passengerCount: getVehicleListParamDto = {

          NOSeats: totalPassengerCount.toString(),
          purpose: 1
        };
        dispatch(tripActions.getVehicleLitsBYPassengerCount(passengerCount));
      }
    }
  }, [PassengerDetails]);

  //TODO
  const getTripDetailsList = async () => {
    const { reqList } = location.state;
    const { allocationDetails } = location.state;
    const data: vehicleDetailsListDto = allocationDetails || "";
    if (data) {
      setAllocationID(data.allocationId);
      setisEdit(data.editIsTrue);
      setisView(data.isView);
      setselectedEndPoint(data.toLocation);
      setselectedStartingPoint(data.fromLocation);
      setVehicleDetails([data]);
    }

    const _param: tripDetailsParamDto = { requestId: reqList };


    dispatch(tripActions.getTripDetailsByRequestIds(_param));

  };

  const handleOnSelectVehicle = (
    property: string,
    vehicleID: number,
    existingTripId: number
  ) => {
    if (property === "Table") {
      vehicleDetails?.forEach((vehicle: vehicleDetailsListDto) => {
        if (vehicle.vehicleId === vehicleID) {
          vehicle.isSelected = true;
        } else {
          vehicle.isSelected = false;
        }
      });
      createdTripDetails?.forEach((vehicle: vehicleDetailsListDto) => {
        vehicle.isSelected = false;
      });
    } else {
      createdTripDetails?.forEach((vehicle: vehicleDetailsListDto) => {
        if (vehicle.vehicleId === vehicleID) {
          vehicle.isSelected = true;
        } else {
          vehicle.isSelected = false;
        }
      });
      vehicleDetails?.forEach((vehicle: vehicleDetailsListDto) => {
        vehicle.isSelected = false;
      });
    }

    const tripLocations: tripLocations[] = [];
    const requestedIDList: any = [];

    PassengerDetails?.forEach((item) => {
      const locationObj = {
        startLocation: item.startLocation,
        endLocation: item.destination,
        startLocationLatitude: item.startLocationLatitude,
        startLocationLongitude: item.startLocationLongitude,
        endLocationLatitude: item.destinationLatitude,
        endLocationLongitude: item.destinationLongitude,
      };

      tripLocations.push(locationObj);
    });

    generalInformation?.forEach((item) => {
      const requestedID = item.requestId;
      requestedIDList.push(requestedID);
    });
    let startDate = "";
    let endDate = "";
    if (travelDetails) {
      startDate = travelDetails[0].passengerStartDate;
      endDate = travelDetails[0].passengerEndDate;
    }

    const _tripAllocationParm: tripAllocationDto = {
      vehicleId: Number(vehicleID),
      startDate: startDate,
      endDate: endDate,
      requestId: requestedIDList,
      tripLocations: tripLocations,
      existingTripId: Number(existingTripId),
      fromLocation: selectedStartingPoint,
      fromLocationLatitude: (selectedStartePointLatANdLang?.lat)?.toString() || '',
      fromLocationLongitude: (selectedStartePointLatANdLang?.lng)?.toString() || '',
      toLocation: selectedEndPoint,
      toLocationLatitude: (selectedEndPointLatANdLang?.lat)?.toString() || '',
      toLocationLongitude: (selectedEndPointLatANdLang?.lng)?.toString() || '',
      mileage: totalDistance.toString() + "|" + totalDuration?.toString(),
    };

    setTripAllocationDetails(_tripAllocationParm);
  };
  const handleAllocateClick = () => {
    setisOpenConfirmationDialog(true);
  };

  const onShowVehicle = () => {
    if (selectedStartingPoint) {
      setselectedStartingPointError(true);
    }
    if (selectedEndPoint) {
      setselectedEndPointError(true);
    }
    if (selectedVehicleType) {
      setVehicleTypeError(false);
      setselectedEndPointError(false);
      setselectedStartingPointError(false);
      let totalPassengerCount = 0;

      for (const item of travelDetails) {
        totalPassengerCount += Number(item.passengerCount);
      }
      const vehicleDetailsParm: vehicleDetailsParamDto = {
        VehicleTypeId: selectedVehicleType,
        PassengerCount: totalPassengerCount,
        fromDate: travelDetails[0].passengerStartDate,
        toDate: travelDetails[0].passengerEndDate

      };
      dispatch(tripActions.getVehicleDetailsByVehicleType(vehicleDetailsParm));
    } else {
      setVehicleTypeError(true);
    }
  };

  const onHandleInputChange = (property: string, value: string) => {

    if (property === vehicleAllocationEnum.vehicleType) {
      setSelectedVehicleType(value);
      setVehicleTypeError(false);
    }
    if (property === vehicleAllocationEnum.StartPoint) {


      setselectedStartingPoint(value);
      let selectedFromLocationLatitude = "";
      let selectedFromLocationLongitude = "";

      for (const info of generalInformation || []) {
        if (info.fromLocation === value) {
          selectedFromLocationLatitude = info.fromLocationLatitude;
          selectedFromLocationLongitude = info.fromLocationLongitude;
          break; // Exit the loop once a match is found
        }
      }
      setselectedStartePointLatANdLang({ lat: Number(selectedFromLocationLatitude), lng: Number(selectedFromLocationLongitude) })
      setselectedStartingPointError(false);
    }

    if (property === vehicleAllocationEnum.EndPoint) {
      setselectedEndPoint(value);
      setselectedEndPointError(false);
      let selectedToLocationLatitude = "";
      let selectedToLocationLongitude = "";

      for (const info of generalInformation || []) {
        if (info.toLocation === value) {
          selectedToLocationLatitude = info.toLocationLatitude;
          selectedToLocationLongitude = info.toLocationLongitude;
          break; // Exit the loop once a match is found
        }
      }
      setselectedEndPointtLatANdLang({ lat: Number(selectedToLocationLatitude), lng: Number(selectedToLocationLongitude) })
    }
    if (property === vehicleAllocationEnum.reason) {
      setReason(value);
    }
  };

  useEffect(() => {
    getTripDetailsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSortHandle = (col: string) => {
    const sorted = vehicleDetails?.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];
      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;
      if (prev < next) {
        return -1;
      }
      if (prev > next) {
        return 1;
      }
      return 0;
    });
    if (sortMeta.asc) {
      sorted?.reverse();
    }
    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setVehicleDetails(sorted);
  };

  const onClearFilter = () => {
    setIsFiltered(false);
    setVehicleDetails(vehicleDetailsListResponse.data);
  };

  const onFilterHandle = (col: string, value: string) => {
    setIsFiltered(true);
    const _list = vehicleDetailsListResponse.data;
    const filtered = _list.filter((item: any) => {
      const _value = (item as any)[col];

      if (typeof _value === "boolean") {
        return _value ? value === "Yes" : value === "No";
      }
      if (
        col === "allocatedOn" ||
        col === "departureDateandTime" ||
        col === "returnDateandTime"
      ) {
        const _selectedMin = dayjs(value[0]).format("YYYY-MM-DD HH:mm");
        const _selectedMax = dayjs(value[1]).format("YYYY-MM-DD HH:mm");
        const _targetDate = dayjs(_value).format("YYYY-MM-DD HH:mm");

        return moment(_targetDate).isBetween(_selectedMin, _selectedMax);
      }
      return _value === value;
    });

    setVehicleDetails(filtered);
  };

  const getFilterList = (col: string): string[] => {
    if (!vehicleDetailsListResponse.isLoading) {
      const _list = vehicleDetailsListResponse.data || [""];
      return _list
        .map((item: any) => {
          const value = (item as any)[col];
          if (typeof value === "boolean") {
            return value ? "Yes" : "No";
          }
          return value;
        })
        .filter(
          (value: any, index: any, array: string | any[]) =>
            array.indexOf(value) === index
        );
    } else return [];
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleModificationClick = () => {
    setisOpenConfirmationDialog2(true);
  };

  const onCallback = (con: boolean) => {
    if (con) {
      if (TripAllocationDetails) {
        setisOpenConfirmationDialog(false);
        dispatch(tripActions.postTripAllocationDetails(TripAllocationDetails));
      } else {
        const setAlert: AlertDto = {
          message: "Please Select A Vehicle",
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: "error",
          },
        };
        dispatch(alertActions.triggerAlert(setAlert));
      }
    } else {
      setisOpenConfirmationDialog(false);
    }
  };

  const onCallback2 = (con: boolean) => {
    if (con) {
      if (TripAllocationDetails) {
        const _param: updateTripDetailsParam = {
          param: allocationID,
          requestBody: TripAllocationDetails,
        };
        dispatch(tripActions.updateTripAllocationDetails(_param));
      } else {
        const setAlert: AlertDto = {
          message: "Please Select A Vehicle",
          type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
          options: {
            key: new Date().getTime() + Math.random(),
            varient: "error",
          },
        };
        dispatch(alertActions.triggerAlert(setAlert));
      }
    } else {
      setisOpenConfirmationDialog2(false);
    }
  };
  return (
    <React.Fragment>
      <AppLayout componentTitle="New Request Creation">
        <section className="page-root">
          <section className={style.sectionCard}>
            <section className={style.sectionCardHeader}>
              <Typography noWrap component="div" className={style.title}>
                <h3>Manage Trip</h3>
              </Typography>
            </section>
            <section className={style.sectionCardBody}>
              <section className={style.stepperRoot}>
                <VehicleAllocationGeneralInformation
                  GeneralInformation={generalInformation || []}
                  onSortHandle={onSortHandle}
                  isEdit={isEdit}
                  allocationId={allocationID}
                  StartingPoints={StartingPoints || []}
                  endPoints={endPoints || []}
                  selectedStartingPoint={selectedStartingPoint}
                  selectedEndPoint={selectedEndPoint}
                  onHandleInputChange={onHandleInputChange}
                  selectedStartingPointError={selectedStartingPointError}
                  selectedEndPointError={selectedEndPointError}
                  reason={reason}
                />
                <PassengerAndPackageDetails
                  GeneralInformation={generalInformation || []}
                  travelDetails={travelDetails || []}
                  PassengerDetails={PassengerDetails || []}
                  packageDetails={packageDetails || []}
                  onSortHandle={onSortHandle}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onHandleChangePage={handleChangePage}
                  onHandleChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <SelectVehicle
                  GeneralInformation={generalInformation || []}
                  vehicleDetails={vehicleDetails || []}
                  createdTripDetails={createdTripDetails || []}
                  PassengerDetails={PassengerDetails || []}
                  VehicleTypeList={VehicleTypeList || []}
                  onSortHandle={onSortHandle}
                  onShowVehicle={onShowVehicle}
                  selectedVehicleType={selectedVehicleType}
                  onHandleInputChange={onHandleInputChange}
                  VehicleTypeError={VehicleTypeError}
                  handleOnSelectVehicle={handleOnSelectVehicle}
                  onFilterHandle={onFilterHandle}
                  getFilterList={getFilterList}
                  onClearFilter={onClearFilter}
                  isFiltered={isFiltered}
                  travelDetails={travelDetails || []}
                  IsView={IsView}
                />
                <ProjectedRout
                  IsView={IsView}
                  vehicleDetails={vehicleDetails || []}
                  totalDistance={totalDistance}
                />
                <ConfirmationDialog
                  isOpenConfirmationDialog={isOpenConfirmationDialog}
                  onCallback={onCallback}
                  title={"Confirm Vehicle Allocation"}
                  content={"Are you Sure You Want To Allocate This Vehicle?"}
                  confirmButtonTitle={"Allocate & Exit"}
                  cancelButtonTitle={"Cancel"}
                />
                <ConfirmationDialog
                  isOpenConfirmationDialog={isOpenConfirmationDialog2}
                  onCallback={onCallback2}
                  title={"Modify Vehicle Allocation"}
                  content={
                    "Are you sure you want to modify this vehicle allocation?"
                  }
                  confirmButtonTitle={"Modify & Exit"}
                  cancelButtonTitle={"Cancel"}
                />

              </section>
              <Grid container spacing={4}>
                <Grid item xs={12} md={12} className={style.formBox}>
                  <Box sx={{ display: "flex", justifyContent: "end", margin: "1rem" }}>
                    <CustomButton text="Cancel" border="1px solid #6e6e6e" bgColor="#282828" onClick={handleCancelClick} />
                    {isEdit && <CustomButton text='Modify Allocation' onClick={() => { handleModificationClick() }} />}
                    {!IsView && !isEdit && <CustomButton text='Confirm Allocation' onClick={() => { handleAllocateClick() }} />}

                  </Box>
                </Grid>
              </Grid>
            </section>
          </section>
        </section>
      </AppLayout>
    </React.Fragment>
  );
};

export default VehicalAllocationScreen;
