import {
  APP_ACTION_STATUS,
  COMMON_ACTION_TYPES,
  TRIP_ACTION_TYPES,
} from "../../utilities/constants";
import { TripStateDto } from "../../utilities/models";

const INITIAL_STATE: TripStateDto = {
  getTripDetails: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getVehicleList: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  getVehicleDetailsList: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
  postTripAllocation: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  updateTripAllocation:{
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  rejectTripAllocation:{
    data: null,
    error: null,
    isLoading: false,
    status: null,
  }
};

const tripReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case TRIP_ACTION_TYPES.GET_TRIP_DETAILS + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getTripDetails: {
          ...state.getTripDetails,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case TRIP_ACTION_TYPES.GET_TRIP_DETAILS + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getTripDetails: {
          ...state.getTripDetails,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data.data,
        },
      };
    case TRIP_ACTION_TYPES.GET_TRIP_DETAILS + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getTripDetails: {
          ...state.getTripDetails,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case TRIP_ACTION_TYPES.GET_TRIP_DETAILS + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getTripDetails: {
          ...state.getTripDetails,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case TRIP_ACTION_TYPES.GET_VEHICLE_LIST_BY_PASSENGER_COUNT + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getVehicleList: {
          ...state.getVehicleList,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        }
      };
    case TRIP_ACTION_TYPES.GET_VEHICLE_LIST_BY_PASSENGER_COUNT + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getVehicleList: {
          ...state.getVehicleList,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data.data
        }
      };
    case TRIP_ACTION_TYPES.GET_VEHICLE_LIST_BY_PASSENGER_COUNT + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getVehicleList: {
          ...state.getVehicleList,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        }
      };
    case TRIP_ACTION_TYPES.GET_VEHICLE_LIST_BY_PASSENGER_COUNT + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getVehicleList: {
          ...state.getVehicleList,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        }
      };
    case TRIP_ACTION_TYPES.GET_VEHICLE_DETAILS_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getVehicleDetailsList: {
          ...state.getVehicleDetailsList,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        }
      };
    case TRIP_ACTION_TYPES.GET_VEHICLE_DETAILS_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getVehicleDetailsList: {
          ...state.getVehicleDetailsList,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data.data,
        }
      };
    case TRIP_ACTION_TYPES.GET_VEHICLE_DETAILS_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getVehicleDetailsList: {
          ...state.getVehicleDetailsList,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        }
      };
    case TRIP_ACTION_TYPES.GET_VEHICLE_DETAILS_LIST + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getVehicleDetailsList: {
          ...state.getVehicleDetailsList,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        }
      };
    case TRIP_ACTION_TYPES.POST_TRIP_ALLOCATION + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        postTripAllocation: {
          ...state.postTripAllocation,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        }
      };
    case TRIP_ACTION_TYPES.POST_TRIP_ALLOCATION + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        postTripAllocation: {
          ...state.postTripAllocation,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        }
      };
    case TRIP_ACTION_TYPES.POST_TRIP_ALLOCATION + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        postTripAllocation: {
          ...state.postTripAllocation,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        }
      };
    case TRIP_ACTION_TYPES.POST_TRIP_ALLOCATION + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        postTripAllocation: {
          ...state.postTripAllocation,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        }
      };
      case TRIP_ACTION_TYPES.PUT_UPDATE_TRIP_DETAILS + COMMON_ACTION_TYPES.REQUEST:
        return {
          ...state,
          updateTripAllocation: {
            ...state.updateTripAllocation,
            isLoading: true,
            status: APP_ACTION_STATUS.LOADING,
            error: null,
            data: null,
          }
        };
      case TRIP_ACTION_TYPES.PUT_UPDATE_TRIP_DETAILS + COMMON_ACTION_TYPES.SUCCESS:
        return {
          ...state,
          updateTripAllocation: {
            ...state.updateTripAllocation,
            isLoading: false,
            status: APP_ACTION_STATUS.SUCCESS,
            error: null,
            data: action.data,
          }
        };
      case TRIP_ACTION_TYPES.PUT_UPDATE_TRIP_DETAILS + COMMON_ACTION_TYPES.ERROR:
        return {
          ...state,
          updateTripAllocation: {
            ...state.updateTripAllocation,
            isLoading: false,
            status: APP_ACTION_STATUS.ERROR,
            error: action.error,
            data: null,
          }
        };
      case TRIP_ACTION_TYPES.PUT_UPDATE_TRIP_DETAILS + COMMON_ACTION_TYPES.CLEAR:
        return {
          ...state,
          updateTripAllocation: {
            ...state.updateTripAllocation,
            isLoading: false,
            status: APP_ACTION_STATUS.INITIAL,
            error: null,
            data: null,
          }
        };

        case TRIP_ACTION_TYPES.POST_REJECT_TRIP_ALLOCATION + COMMON_ACTION_TYPES.REQUEST:
          return {
            ...state,
            rejectTripAllocation: {
              ...state.rejectTripAllocation,
              isLoading: true,
              status: APP_ACTION_STATUS.LOADING,
              error: null,
              data: null,
            }
          };
        case TRIP_ACTION_TYPES.POST_REJECT_TRIP_ALLOCATION + COMMON_ACTION_TYPES.SUCCESS:
          return {
            ...state,
            rejectTripAllocation: {
              ...state.rejectTripAllocation,
              isLoading: false,
              status: APP_ACTION_STATUS.SUCCESS,
              error: null,
              data: action.data,
            }
          };
        case TRIP_ACTION_TYPES.POST_REJECT_TRIP_ALLOCATION + COMMON_ACTION_TYPES.ERROR:
          return {
            ...state,
            rejectTripAllocation: {
              ...state.rejectTripAllocation,
              isLoading: false,
              status: APP_ACTION_STATUS.ERROR,
              error: action.error,
              data: null,
            }
          };
        case TRIP_ACTION_TYPES.POST_REJECT_TRIP_ALLOCATION + COMMON_ACTION_TYPES.CLEAR:
          return {
            ...state,
            rejectTripAllocation: {
              ...state.rejectTripAllocation,
              isLoading: false,
              status: APP_ACTION_STATUS.INITIAL,
              error: null,
              data: null,
            }
          };
    default:
      return state;
  }
};
export default tripReducer;
