/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AppLayout } from "../../templates";
import ManageEntitlementGird from "../../components/ManageEntitlements/ManageEntitlementGird";
import { APP_ACTION_STATUS, APP_ROUTES, APP_TABLE_CONFIGS, GENERAL_SCREEN_MODES, } from "../../utilities/constants";
import { GetAllUsersWithEntitlementParam, GetAllUsersWithEntitlementsDTO, } from "../../utilities/models/permission.model";
import { permissionActions } from "../../redux/actions";
import { ApplicationStateDto, SortMetaDto } from "../../utilities/models";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ManageEntitlements = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE);
  const INITIAL_SORT_META: SortMetaDto = {
    field: "",
    asc: false,
  };

  const [sortMeta, setSortMeta] = useState<SortMetaDto>(INITIAL_SORT_META);
  const [isFiltered, setIsFiltered] = useState(false);
  const getAllUsersWithEntitlementsResponse = useSelector((state: ApplicationStateDto) => state.permission.getALLusersWithEntitlements);
  const newRolesResponse = useSelector((state: ApplicationStateDto) => state.permission.postRolesPermissions);
  const editeRolesResponse = useSelector((state: ApplicationStateDto) => state.permission.editRolesPermissions);
  const [filteredList, setFilteredList] = useState<GetAllUsersWithEntitlementsDTO[]>([]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    getAllUsersWithEntitlements(+event.target.value, 1);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    getAllUsersWithEntitlements(rowsPerPage, newPage + 1);
    setPage(newPage);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (editeRolesResponse.status === APP_ACTION_STATUS.SUCCESS) {
      getAllUsersWithEntitlements(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE, 1);
    }
  }, [editeRolesResponse.status]);

  useEffect(() => {
    if (newRolesResponse.status === APP_ACTION_STATUS.SUCCESS) {
      getAllUsersWithEntitlements(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE, 1);
    }
  }, [newRolesResponse.status])

  useEffect(() => {
    getAllUsersWithEntitlements(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE, 1);
  }, []);

  useEffect(() => {
    if (
      getAllUsersWithEntitlementsResponse.status === APP_ACTION_STATUS.SUCCESS
    ) {
      setFilteredList(getAllUsersWithEntitlementsResponse.data);
    }
  }, [getAllUsersWithEntitlementsResponse.status]);

  const getAllUsersWithEntitlements = async (r: number, p: number) => {
    const _param: GetAllUsersWithEntitlementParam = { pagination: r, pageNumber: p, };
    dispatch(permissionActions.getAllUserWithEntitlements(_param));
  };

  const getEntitlementId = (id: any) => {
    console.log("Entitlement: ", id);
  };

  const navigteTORequestScreen = (mode: string, id: number) => {
    if(mode === GENERAL_SCREEN_MODES.CREATE){
      navigate(APP_ROUTES.MANAGE_ENTITLEMENT, { state: { mode: mode } })
    }
    if(mode === GENERAL_SCREEN_MODES.EDIT){
      navigate(APP_ROUTES.EDIT_ENTITLEMENT, { state: { mode: mode, id: id } });
    }
  };


  const onClearFilter = () => {
    setIsFiltered(false);
    setFilteredList(getAllUsersWithEntitlementsResponse.data);

  };

  const getFilterList = (col: string): string[] => {
    if (!getAllUsersWithEntitlementsResponse.isLoading) {
      const _list = getAllUsersWithEntitlementsResponse.data || [];


      const keys = _list.map((item) => {

        const value = (item as any)[col];
        if (typeof value === "boolean") {
          return value ? "Yes" : "No";
        }
        return value;
      })
        .filter((value, index, array) => array.indexOf(value) === index);
      return keys;
    } else return [];
  };

  const onFilterHandle = (col: string, value: string) => {
    setIsFiltered(true);
    const _list = getAllUsersWithEntitlementsResponse.data;
    const filtered = _list.filter((item) => {
      const _value = (item as any)[col];

      if (typeof _value === "boolean") {
        return _value ? value === "Yes" : value === "No";
      }

      return _value === value;
    });

    setFilteredList(filtered);
  };

  const onSortHandle = (col: string) => {
    const sorted = filteredList.sort((_prev: any, _next: any) => {
      const _prevItem = _prev[col];
      const _nextItem = _next[col];

      const prev =
        typeof _prevItem === "string" ? _prevItem.toUpperCase() : _prevItem;
      const next =
        typeof _nextItem === "string" ? _nextItem.toUpperCase() : _nextItem;

      if (prev < next) {
        return -1;
      }

      if (prev > next) {
        return 1;
      }

      return 0;
    });

    if (sortMeta.asc) {
      sorted.reverse();
    }

    setSortMeta((_sort) => ({ field: col, asc: !_sort.asc }));
    setFilteredList(sorted);
  };

  return (
    <React.Fragment>
      <AppLayout componentTitle="ManageEntitlements">
        <section className="page-root">
          <ManageEntitlementGird
            onHandleChangeRowsPerPage={handleChangeRowsPerPage}
            onHandleChangePage={handleChangePage}
            DataIsLoading={getAllUsersWithEntitlementsResponse.isLoading}
            page={page}
            rowsPerPage={rowsPerPage}
            filteredList={filteredList}
            getEntitlementId={getEntitlementId}
            navigateTo={navigteTORequestScreen}
            onClearFilter={onClearFilter}
            isFiltered={isFiltered}
            getFilterList={getFilterList}
            onFilterHandle={onFilterHandle}
            onSortHandle={onSortHandle}
          />
        </section>
      </AppLayout>
    </React.Fragment>
  );
};

export default ManageEntitlements;
