import { APP_ACTION_STATUS, COMMON_ACTION_TYPES, FUEL_ACTION_TYPES } from "../../utilities/constants";
import { FuelCostManagementStateDto } from "../../utilities/models/state.model";


const INITIAL_STATE: FuelCostManagementStateDto = {
  postFuelTypes: {
    data: "",
    error: null,
    isLoading: false,
    status: null
  },
  getFuelTypesList: {
    data: [],
    isLoading: false,
    error: null,
    status: null
  },
  getVehicleNumber: {
    data: [],
    isLoading: false,
    error: null,
    status: null
  },
  uploadFuelBill: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  addFuelBillDetails: {
    data: "",
    error: null,
    isLoading: false,
    status: null
  },
  getFuelPrice: {
    data: null,
    error: null,
    isLoading: false,
    status: null,
  },
  getFuelCostDetailsList: {
    data: [],
    error: null,
    isLoading: false,
    status: null,
  },
}

const fuelReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FUEL_ACTION_TYPES.POST_FUEL_TYPES + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        postFuelTypes: {
          ...state.postFuelTypes,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: "",
        },
      };
    case FUEL_ACTION_TYPES.POST_FUEL_TYPES + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        postFuelTypes: {
          ...state.postFuelTypes,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case FUEL_ACTION_TYPES.POST_FUEL_TYPES + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        postFuelTypes: {
          ...state.postFuelTypes,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: "",
        },
      };
    case FUEL_ACTION_TYPES.POST_FUEL_TYPES + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        postFuelTypes: {
          ...state.postFuelTypes,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: "",
        },
      };
    case FUEL_ACTION_TYPES.GET_FUEL_TYPES + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getFuelTypesList: {
          ...state.getFuelTypesList,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case FUEL_ACTION_TYPES.GET_FUEL_TYPES + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getFuelTypesList: {
          ...state.getFuelTypesList,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case FUEL_ACTION_TYPES.GET_FUEL_TYPES + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getFuelTypesList: {
          ...state.getFuelTypesList,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case FUEL_ACTION_TYPES.GET_FUEL_TYPES + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getFuelTypesList: {
          ...state.getFuelTypesList,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case FUEL_ACTION_TYPES.GET_VEHICLE_NUMBER + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getVehicleNumber: {
          ...state.getVehicleNumber,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case FUEL_ACTION_TYPES.GET_VEHICLE_NUMBER + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getVehicleNumber: {
          ...state.getVehicleNumber,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case FUEL_ACTION_TYPES.GET_VEHICLE_NUMBER + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getVehicleNumber: {
          ...state.getVehicleNumber,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: [],
        },
      };
    case FUEL_ACTION_TYPES.GET_VEHICLE_NUMBER + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getVehicleNumber: {
          ...state.getVehicleNumber,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case FUEL_ACTION_TYPES.UPLOAD_FUEL_BILL + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        uploadFuelBill: {
          ...state.uploadFuelBill,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case FUEL_ACTION_TYPES.UPLOAD_FUEL_BILL + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        uploadFuelBill: {
          ...state.uploadFuelBill,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case FUEL_ACTION_TYPES.UPLOAD_FUEL_BILL + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        uploadFuelBill: {
          ...state.uploadFuelBill,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case FUEL_ACTION_TYPES.UPLOAD_FUEL_BILL + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        uploadFuelBill: {
          ...state.uploadFuelBill,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
    case FUEL_ACTION_TYPES.ADD_FUEL_DETAILS + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        addFuelBillDetails: {
          ...state.addFuelBillDetails,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: "",
        },
      };
    case FUEL_ACTION_TYPES.ADD_FUEL_DETAILS + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        addFuelBillDetails: {
          ...state.addFuelBillDetails,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case FUEL_ACTION_TYPES.ADD_FUEL_DETAILS + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        addFuelBillDetails: {
          ...state.addFuelBillDetails,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: "",
        },
      };
    case FUEL_ACTION_TYPES.ADD_FUEL_DETAILS + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        addFuelBillDetails: {
          ...state.addFuelBillDetails,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: "",
        },
      };
    case FUEL_ACTION_TYPES.GET_FUEL_PRICE + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        getFuelPrice: {
          ...state.getFuelPrice,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING,
          error: null,
          data: null,
        },
      };
    case FUEL_ACTION_TYPES.GET_FUEL_PRICE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        getFuelPrice: {
          ...state.getFuelPrice,
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS,
          error: null,
          data: action.data,
        },
      };
    case FUEL_ACTION_TYPES.GET_FUEL_PRICE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        getFuelPrice: {
          ...state.getFuelPrice,
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR,
          error: action.error,
          data: null,
        },
      };
    case FUEL_ACTION_TYPES.GET_FUEL_PRICE + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        getFuelPrice: {
          ...state.getFuelPrice,
          isLoading: false,
          status: APP_ACTION_STATUS.INITIAL,
          error: null,
          data: null,
        },
      };
      case FUEL_ACTION_TYPES.GET_FUEL_COST_DETAILS + COMMON_ACTION_TYPES.REQUEST:
        return {
          ...state,
          getFuelCostDetailsList: {
            ...state.getFuelCostDetailsList,
            isLoading: true,
            status: APP_ACTION_STATUS.LOADING,
            error: null,
            data: null,
          },
        };
      case FUEL_ACTION_TYPES.GET_FUEL_COST_DETAILS + COMMON_ACTION_TYPES.SUCCESS:
        return {
          ...state,
          getFuelCostDetailsList: {
            ...state.getFuelCostDetailsList,
            isLoading: false,
            status: APP_ACTION_STATUS.SUCCESS,
            error: null,
            data: action.data,
          },
        };
      case FUEL_ACTION_TYPES.GET_FUEL_COST_DETAILS + COMMON_ACTION_TYPES.ERROR:
        return {
          ...state,
          getFuelCostDetailsList: {
            ...state.getFuelCostDetailsList,
            isLoading: false,
            status: APP_ACTION_STATUS.ERROR,
            error: action.error,
            data: null,
          },
        };
      case FUEL_ACTION_TYPES.GET_FUEL_COST_DETAILS + COMMON_ACTION_TYPES.CLEAR:
        return {
          ...state,
          getFuelCostDetailsList: {
            ...state.getFuelCostDetailsList,
            isLoading: false,
            status: APP_ACTION_STATUS.INITIAL,
            error: null,
            data: null,
          },
        };
    default:
      return state;
  }
}

export default fuelReducer;