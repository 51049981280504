/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import style from './ManageFuleBillingScreen.module.scss'
import { AppLayout } from '../../templates'
import { BillingDetails, DownloadFuelCostBill } from '../../components/FuelManagement'
import {  ApplicationStateDto, OptionsDto, SortMetaDto, FuelDetailsFormDto, FuelTypeFieldDto, fuelBillDetailsDto, AlertDto, FuelCostDetailsFormDto, GetFuelCostDetailsDto } from '../../utilities/models'
import { ConfirmationDialog, CustomButton } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { transportActions } from '../../redux/actions/transport.action'
import { driverActions } from '../../redux/actions/driver.action'
import { validateFormData } from '../../utilities/helpers'
import dayjs, { Dayjs } from 'dayjs'
import { ALERT_ACTION_TYPES, APP_ACTION_STATUS, APP_ROUTES, COMMON_ACTION_TYPES, DRIVER_SCREEN_MODES, FUEL_CATEGORY } from '../../utilities/constants'
import { alertActions, fuelActions, linkActions } from '../../redux/actions'
import Decimal from 'decimal.js'
//import { read,writeFileXLSX , utils, writeFile } from 'xlsx';
import * as XLSX from 'xlsx'


const ManageFuelBillScreen = () => {

  const BILLING_INFORMATION_FORM_INITIAL_STATE: FuelDetailsFormDto = {
    vehicleNo: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: "object", error: "", },
    fuelType: { value: {} as OptionsDto, isRequired: true, disable: false, readonly: false, validator: 'object', error: '' },
    fuelPrice: { value: '', isRequired: true, disable: true, readonly: false, validator: 'text', error: '', },
    billedAmount: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '' },
    billedDate: { value: null, isRequired: true, disable: false, readonly: false, validator: 'date', error: '' },
    literCount: { value: '', isRequired: true, disable: true, readonly: false, validator: 'text', error: '', },
    kmCount: { value: '', isRequired: true, disable: false, readonly: false, validator: 'text', error: '' },
    billFile: { value: '', isRequired: false, disable: false, readonly: false, validator: 'text', error: '' }
  }
  const FUEL_COST_INFORMATION_FORM_INITIAL_STATE: FuelCostDetailsFormDto = {
    vehicleNo: { value: {} as OptionsDto, isRequired: false, disable: false, readonly: false, validator: "object", error: "", },
    fromDate: { value: null, isRequired: false, disable: false, readonly: false, validator: 'date', error: '' },
    toDate: { value: null, isRequired: false, disable: false, readonly: false, validator: 'date', error: '' },
  }

  const INITIAL_SORT_META: SortMetaDto = {
    field: "",
    asc: false,
  }

  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [driverId, setDriverId] = useState(-1)
  const [helperText, setHelperText] = useState(true)
  const [sortMeta, setSortMeta] = useState<SortMetaDto>(INITIAL_SORT_META);
  const [billInformationForm, setBillnformationForm] = useState(BILLING_INFORMATION_FORM_INITIAL_STATE)
  const [vehicleCostBillDetailsForm, setVehicleCostBillDetailsForm] = useState(FUEL_COST_INFORMATION_FORM_INITIAL_STATE);

  const viewDriverResponse = useSelector((state: ApplicationStateDto) => state.driver.viewDriver)
  const editDriverResponse = useSelector((state: ApplicationStateDto) => state.driver.editDriver)
  const modifyDriverVehicleResponse = useSelector((state: ApplicationStateDto) => state.driver.editDriverVehicle)
  const authorizedUser = useSelector((state: ApplicationStateDto) => state.authUser.authorizedUser);
  const vehicleNumberRes = useSelector((state:ApplicationStateDto) => state.fuel.getVehicleNumber);
  
  const billUploadResponse = useSelector((state:ApplicationStateDto) => state.fuel.uploadFuelBill);
  const createBillDetailResponse = useSelector((state: ApplicationStateDto) => state.fuel.addFuelBillDetails);
  const getFuelPriceResponse = useSelector((state: ApplicationStateDto) => state.fuel.getFuelPrice);
  const createFuelCostDetailResponse = useSelector((state: ApplicationStateDto) => state.fuel.getFuelCostDetailsList);

  const [selectedFile, setSelectedFile] = useState<File | any>(null);
  const [fileString, setFileString] = useState("");
  const [inputValue, setInputValue] = useState("");

  const setFileAlert: AlertDto = {
    message: "Please upload bill!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };

  const fieldsErrorAlert: AlertDto = {
    message: "Mandatory fields not filled!",
    type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
    options: {
      key: new Date().getTime() + Math.random(),
      varient: "error",
    },
  };


  useEffect(() => {
    getInitialData()
    // dispatch(fuelActions.getAllVehicleNumbers('y'));

    return () => {
      dispatch(fuelActions.uploadFuelBillClear())
      dispatch(fuelActions.addFuelBillDetailsClear())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getInitialData = async () => {
    setAsInitialState()
  }


  useEffect(() => {
    if(createBillDetailResponse.status === APP_ACTION_STATUS.SUCCESS){
      navigate(APP_ROUTES.FUEL_REPORT)
      setAsInitialState()
    }
  },[createBillDetailResponse.status])
  
  const handleInputFocus = (property: string, section: string) => {
    if (section === 'bill-information')
      setBillnformationForm({
        ...billInformationForm,
        [property]: {
          ...billInformationForm[property as keyof typeof billInformationForm],
          error: null
        }
      })
  }

  const onInputHandleChangeBillInformation = (property: string, value: any) => {
    setHelperText(true)
    if(property === "vehicleNo"){
      dispatch(fuelActions.getAllVehicleNumbers(value));
          setBillnformationForm({
            ...billInformationForm,
            vehicleNo: {
              ...billInformationForm.vehicleNo,
              value: value
                ? ({ label: value.label, value: value.value } as OptionsDto)
                : ({} as OptionsDto),
            },
          });
    }

    if(property === "fuelType"){
      setBillnformationForm({
        ...billInformationForm,
        fuelType: {
          ...billInformationForm.fuelType,
          value: value
        },

        billedAmount: {
          ...billInformationForm.billedAmount,
          value: ''
        }, 

        literCount:{
          ...billInformationForm.fuelPrice,
          value:''
        }          
      });

      dispatch(fuelActions.getFuelPrice(value.value))
    }

    if(property === "billedAmount"){
      
      setBillnformationForm({
        
        ...billInformationForm,
        billedAmount: {
          ...billInformationForm.billedAmount,
          value: value
        },

        literCount:{
          ...billInformationForm.literCount,
          value:  value === "" ? '' : new Decimal(value).dividedBy(billInformationForm.fuelPrice.value).toFixed(2).toString(),
          error:null
        }
      });
    }
    
    if (property === "billedDate") {
      setBillnformationForm(({
        ...billInformationForm,
        billedDate: {
          ...billInformationForm.billedDate,
          value: value,
          error:null
        }
      }))
    }

    if(property === "kmCount"){
      setBillnformationForm({
        ...billInformationForm,
        kmCount: {
          ...billInformationForm.kmCount,
          value: value
        },
      });
    }
  }


  const onSearchValueChange = (property: string, value: any) => {
      if (property === "vehicleNo") {
        if (value && value !== inputValue) {
          dispatch(fuelActions.getAllVehicleNumbers(value));
        }
      }
      if (value) setInputValue(value);    
  };

  useEffect(() => {
    if(getFuelPriceResponse.status === APP_ACTION_STATUS.SUCCESS){
      setBillnformationForm({
        ...billInformationForm,
        fuelPrice: {
          ...billInformationForm.fuelPrice,
          value: getFuelPriceResponse.data?.price.toString() || '',
          error:null
        },
    })
    }
  },[getFuelPriceResponse.status])


  // ADD DETAILS
  const onAddDetails = async () => {
    const [validateData, isValid] = await validateFormData(billInformationForm)
    setBillnformationForm(validateData)

    if (isValid) {
      if(billInformationForm.billFile.value === ""){
        dispatch(alertActions.triggerAlert(setFileAlert))
      }else{
        const _payload: fuelBillDetailsDto = {
          billAmount: Number(billInformationForm.billedAmount.value),
          billingDate: dayjs(billInformationForm.billedDate.value).add(330, 'minute').format("YYYY-MM-DDTHH:mm:ssZ"),
          literCount: Number(billInformationForm.literCount.value),
          numberOfKM: Number(billInformationForm.kmCount.value),
          vehicleNumber: billInformationForm.vehicleNo.value.label,
          fuelTypeId: Number(billInformationForm.fuelType.value.value),
          billDocument:billInformationForm.billFile.value
        }
        dispatch(fuelActions.addFuelBillDetails(_payload))
      } 
    }else{
      dispatch(alertActions.triggerAlert(fieldsErrorAlert))
    }
  }

  // EDIT DRIVER
  const onEditDriver = async () => {
  }

  const setAsInitialState = () => {
    setFileString("");
    setSelectedFile(null)
    setBillnformationForm({
      ...BILLING_INFORMATION_FORM_INITIAL_STATE,
    })
  }
  const setFuelCostAsInitialState = () => {
    setVehicleCostBillDetailsForm(
      FUEL_COST_INFORMATION_FORM_INITIAL_STATE,
    )
    dispatch(fuelActions.getFuelCostBillDetailsListClear())
  }

  const onNavigate = () => {
    navigate(APP_ROUTES.FUEL_REPORT)
    setAsInitialState()
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedFile(file);
    event.target.value = '';
  };

  const onUploadFile = () => {
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      dispatch(fuelActions.uploadFuelBill(selectedFile));
      setSelectedFile(null);
    } else {
      console.log('Please select an image file.');
    }
  }

  useEffect(() => {
    if (billUploadResponse.status === APP_ACTION_STATUS.SUCCESS) {
      setFileString(billUploadResponse.data)
      setBillnformationForm(({
        ...billInformationForm,
        billFile: {
          ...billInformationForm.billFile,
          value: billUploadResponse.data
        }
      }))
    }
  }, [billUploadResponse.status])

  const handleRemove = () => {
    setSelectedFile(null)
  };

  const onInputHandleChangeFuelBillCostInformation = (property: string, value: any) => {
    setHelperText(true)
    if(property === "vehicleNo"){
      dispatch(fuelActions.getAllVehicleNumbers(value));
      setVehicleCostBillDetailsForm({
            ...vehicleCostBillDetailsForm,
            vehicleNo: {
              ...vehicleCostBillDetailsForm.vehicleNo,
              value: value
                ? ({ label: value.label, value: value.value } as OptionsDto)
                : ({} as OptionsDto),
            },
          });
    }   
    if (property === "fromDate") {
      setVehicleCostBillDetailsForm(({
        ...vehicleCostBillDetailsForm,
        fromDate: {
          ...vehicleCostBillDetailsForm.fromDate,
          value: value,
          error:null
        }
      }))
    }
  if (property === "endDate") {
    setVehicleCostBillDetailsForm(({
        ...vehicleCostBillDetailsForm,
        toDate: {
          ...vehicleCostBillDetailsForm.toDate,
          value: value,
          error:null
        }
      }))
  
    }
  }

  useEffect(() => {
    if(createFuelCostDetailResponse.status === APP_ACTION_STATUS.SUCCESS){
      setVehicleCostBillDetailsForm(FUEL_COST_INFORMATION_FORM_INITIAL_STATE);
      console.log(createFuelCostDetailResponse.data,"check the response")
      handleOnExport();     
     
    }
  },[createFuelCostDetailResponse.status])

  
  const onSetVehicle = async () => {
    const [validateData, isValid] = await validateFormData(vehicleCostBillDetailsForm)
    setVehicleCostBillDetailsForm(validateData)

    if (isValid) {

      const { vehicleNo, fromDate, toDate } = vehicleCostBillDetailsForm;

    if (vehicleNo.value === null || fromDate.value === null || toDate.value === null) {
      // Ensure that none of the three values are null at once
      dispatch(alertActions.triggerAlert(fieldsErrorAlert));

    } else if ((fromDate.value !== null && toDate.value === null) || 
    (fromDate.value === null && toDate.value !== null)) {
        // Either both startDate and endDate should be not null or both should be null
        dispatch(alertActions.triggerAlert(fieldsErrorAlert));
        
      } else {
        const param: GetFuelCostDetailsDto = {
          vehicleNumber: vehicleCostBillDetailsForm.vehicleNo.value.label,
          startDate: vehicleCostBillDetailsForm.fromDate.value ===null ? "":dayjs(vehicleCostBillDetailsForm.fromDate.value).format("YYYY-MM-DD"),
          endDate: vehicleCostBillDetailsForm.toDate.value ===null ? "": dayjs(vehicleCostBillDetailsForm.toDate.value).format("YYYY-MM-DDTHH:mm")
        }
        dispatch(fuelActions.getFuelCostBillDetailsList(param))
      }
    }else{
      dispatch(alertActions.triggerAlert(fieldsErrorAlert))
    }
  }

  const handleOnExport =()=>
  {

    var wb=XLSX.utils.book_new();
    const data = DataSet[0].data.map(dataRow => dataRow.map(cell => cell.value));
    const columns = DataSet[0].columns.map(column => column.title);
    const ws = XLSX.utils.aoa_to_sheet([columns, ...data]);
    
    // Define the worksheet
  //  const ws = utils.json_to_sheet(data, { header: columns });
  XLSX.utils.book_append_sheet(wb,ws,"Sheet 1");
  XLSX.writeFile(wb,"Fuel Bill Details.xlsx");
  };
  const DataSet = [
    {
        columns: [
            {title: "Report ID", style: {font: {sz: "18", bold: true}}, width: {wpx: 2000}}, // width in pixels
            {title: "Bill Amount(LKR)", style: {font: {sz: "18", bold: true}}, width: {wch: 200}}, // width in characters
            {title: "Billing Date", style: {font: {sz: "18", bold: true}}, width: {wpx: 200}}, // width in pixels
            {title: "Liter Count(L)", style: {font: {sz: "18", bold: true}}, width: {wpx: 200}}, // width in pixels
            {title: "Number Of KM(KM)", style: {font: {sz: "18", bold: true}}, width: {wpx: 200}}, // width in pixels
            {title: "Vehicle Number", style: {font: {sz: "18", bold: true}}, width: {wpx: 200}}, // width in pixels
            {title: "Fuel Type", style: {font: {sz: "18", bold: true}}, width: {wch: 30}}, // width in characters
            {title: "Transport Company", style: {font: {sz: "18", bold: true}}, width: {wpx: 200}}, // width in pixels
            
        ],
        data: createFuelCostDetailResponse?.data?.map((data,index) => [
            {value: index + 1, style: {font: {sz: "14"}}},
            {value: `${data.billAmount}.00`, style: {font: {sz: "14"}}},
            {value: new Date(data.billingDate).toLocaleString('en-US'), style:{font: {color: {rgb: "ffffff"}}, fill: {patternType: "solid"}}},
            {value: data.literCount, style:{font: {color: {rgb: "ffffff"}}, fill: {patternType: "solid"}}},
            {value: data.numberOfKM, style:{font: {color: {rgb: "ffffff"}}, fill: {patternType: "solid"}}},
            {value: data.vehicleNumber, style:{font: {color: {rgb: "ffffff"}}, fill: {patternType: "solid"}}},
            {value: FUEL_CATEGORY.find((f: OptionsDto) => f.value === data.fuelTypeId)?.label, style: { font: { color: { rgb: "ffffff" } }, fill: { patternType: "solid" } } },
            {value: data.transportCompany, style:{font: {color: {rgb: "ffffff"}}, fill: {patternType: "solid"}}},

        ])
    }
]


  return (
    <React.Fragment>
      <AppLayout componentTitle="New Request Creation">
        <section className='page-root'>
          <section className={style.sectionCard}>

            <section className={style.sectionCardHeader}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={10}>
                  <Typography noWrap component="div" className={style.title}>
                    <h3>Billing Management</h3>
                  </Typography>
                </Grid>
              </Grid>
            </section>

            <section className={style.sectionCardBody}>
              <section className={style.stepperRoot}>
              {/* <DownloadFuelCostBill
                  helperText={helperText}
                  handleInputFocus={handleInputFocus}
                  billInformationForm={vehicleCostBillDetailsForm}
                  vehicleNumberRes={vehicleNumberRes}
                  isLoading={viewDriverResponse.isLoading}
                  onSearchValueChange={onSearchValueChange}
                  selectedFile={selectedFile}
                  handleRemove={handleRemove}
                  excelDownload={onSetVehicle}
                  FuelBillCostDetailResponse={createFuelCostDetailResponse}
                  onInputHandleChangeFuelBillCostInformation={onInputHandleChangeFuelBillCostInformation}
                /> */}
                <BillingDetails
                  helperText={helperText}
                  handleInputFocus={handleInputFocus}
                  billInformationForm={billInformationForm}
                  vehicleNumberRes={vehicleNumberRes}
                  onInputHandleChangeBillInformation={onInputHandleChangeBillInformation}
                  isLoading={viewDriverResponse.isLoading}
                  onSearchValueChange={onSearchValueChange}
                  billUploadResponse={billUploadResponse}
                  handleFileChange={handleFileChange}
                  fileString={fileString}
                  onUploadFile={ onUploadFile}
                  selectedFile={selectedFile}
                  handleRemove={handleRemove}
                />
              
              </section>

              <section className={style.sectionCardFooter}>
                <CustomButton text='Close' textColor='black' bgColor='#bfbfbf' onClick={onNavigate} />
                {/* {screenMode === DRIVER_SCREEN_MODES.CREATE && <CustomButton text='Clear' border='1px solid #6e6e6e' bgColor='#282828' onClick={setAsInitialState} />} */}
                <CustomButton text='Clear' border='1px solid #6e6e6e' bgColor='#282828' onClick={setAsInitialState} />
                {/* {screenMode === DRIVER_SCREEN_MODES.CREATE &&
                  <CustomButton
                    text="Add Driver"
                    disabled={createDriverResponse.isLoading}
                    isLoading={createDriverResponse.isLoading}
                    onClick={onCreateDriver}
                  />
                } */}
                <CustomButton
                    text="Add Details"
                    disabled={createBillDetailResponse.isLoading}
                    isLoading={createBillDetailResponse.isLoading}
                    onClick={onAddDetails}
                  />
              </section>
            </section>
          </section>
        </section>

      </AppLayout>
    </React.Fragment>
  )
}

export default ManageFuelBillScreen
