import React from 'react'
import { DepartmentBriefDto, OnBehalfOfSomeoneFormDto, PassengerBriefDto, PlantBriefDto, SbuBriefDto, StateObjectDto } from '../../../utilities/models'
import { Grid, Typography } from '@mui/material'
import { StyledSwitch } from '../../../assets/theme/theme'
import style from './OnBehalfOfSomeone.module.scss'
import { AppSkeleton, CustomAutocomplete } from '../../Shared'
import Stepper from '../../Shared/Stepper/Stepper'
import { REQUEST_SCREEN_MODES } from '../../../utilities/constants'

const OnBehalfOfSomeone: React.FC<{
  helperText: boolean
  isLoading: boolean
  screenMode: string
  onBehalfOfSomeoneForm: OnBehalfOfSomeoneFormDto
  onInputHandleChangeRequestForSomeone(property: string, value: any): void;
  handleInputFocus(property: string, section: string): void;
  allSbuList: SbuBriefDto[]
  allPlantsBySbuIdResponse: StateObjectDto<PlantBriefDto[] | []>
  allDepartmentByPlantIdResponse: StateObjectDto<DepartmentBriefDto[] | []>
  getUsersByDepartmentResponse: StateObjectDto<PassengerBriefDto[] | []>
}> = (props) => {
  const _isForSomeone = props.onBehalfOfSomeoneForm.isForSomeone
  const _sbu = props.onBehalfOfSomeoneForm.sbu
  const _plant = props.onBehalfOfSomeoneForm.plant
  const _department = props.onBehalfOfSomeoneForm.department
  const _someone = props.onBehalfOfSomeoneForm.someone

  const _allPlants = props.allPlantsBySbuIdResponse !== undefined && props.allPlantsBySbuIdResponse?.data?.length > 0 ? props.allPlantsBySbuIdResponse.data : []
  const _allDepartment = props.allDepartmentByPlantIdResponse !== undefined && props.allDepartmentByPlantIdResponse?.data?.length > 0 ? props.allDepartmentByPlantIdResponse.data : []
  const _allUsers = props.getUsersByDepartmentResponse !== undefined && props.getUsersByDepartmentResponse?.data?.length > 0 ? props.getUsersByDepartmentResponse.data : []

  return (
    <Stepper stepNumber={0} stepTitle={"Request for Someone"}>
      {props.isLoading &&
        <AppSkeleton numOfRows={2} numOfColumns={2} columnWidth={50} height={40} tag="stepper" />
      }
      {!props.isLoading &&
      <section>
        <div className={style.switchField}>
          <Typography className={style.label}>On behalf of someone</Typography>
          <StyledSwitch
            checked={_isForSomeone.value}
            disabled={_isForSomeone.disable}
            onChange={() => props.onInputHandleChangeRequestForSomeone('isForSomeone', !_isForSomeone.value)}
          />
        </div>

      { _isForSomeone.value&& <Grid container spacing={4}>
          {(props.screenMode === REQUEST_SCREEN_MODES.CREATE || props.screenMode === REQUEST_SCREEN_MODES.USE_TEMPLATE )&&
            <><Grid item xs={12} md={6}>
              <CustomAutocomplete
                label="SBU"
                placeholder='Select sbu'
                onFocus={() => props.handleInputFocus('sbu', 'request-for')}
                options={props.allSbuList.map((t) => { return { label: t.name, value: t.id } })}
                value={_sbu.value}
                error={!!_sbu.error}
                disabled={_sbu.disable}
                readonly={_sbu.readonly}
                required={_sbu.isRequired}
                helperText={props.helperText && _sbu.error}
                onChange={(event: any, value: any) => props.onInputHandleChangeRequestForSomeone('sbu', value)}
              />
            </Grid>
              <Grid item xs={12} md={6}>
                <CustomAutocomplete
                  label="Plant"
                  placeholder='Select Plant'
                  onFocus={() => props.handleInputFocus('plant', 'request-for')}
                  options={_allPlants.map((p) => { return { label: p.plantName, value: p.id } })}
                  value={_plant.value}
                  error={!!_plant.error}
                  disabled={_plant.disable}
                  readonly={_plant.readonly}
                  required={_plant.isRequired}
                  helperText={props.helperText && _plant.error}
                  onChange={(event: any, value: any) => props.onInputHandleChangeRequestForSomeone('plant', value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomAutocomplete
                  label="Department"
                  placeholder='Select department'
                  onFocus={() => props.handleInputFocus('department', 'request-for')}
                  options={_allDepartment.map((t) => { return { label: t.plantName, value: t.id } })}
                  value={_department.value}
                  error={!!_department.error}
                  disabled={_department.disable}
                  readonly={_department.readonly}
                  required={_department.isRequired}
                  helperText={props.helperText && _department.error}
                  onChange={(event: any, value: any) => props.onInputHandleChangeRequestForSomeone('department', value)}
                />
              </Grid>
            </>
          }

          <Grid item xs={12} md={6}>
            <CustomAutocomplete
              label="Request For"
              placeholder='Select user'
              onFocus={() => props.handleInputFocus('someone', 'request-for')}
              options={_allUsers.map((u) => { return { label: u.name, value: u.id } })}
              value={_someone.value}
              error={!!_someone.error}
              disabled={_someone.disable}
              readonly={_someone.readonly}
              required={_someone.isRequired}
              helperText={props.helperText && _someone.error}
              onChange={(event: any, value: any) => props.onInputHandleChangeRequestForSomeone('someone', value)}
            />
          </Grid>
        </Grid>}
      </section>
      }
    </Stepper>
  )
}

export default OnBehalfOfSomeone
