import { COMMON_ACTION_TYPES, DRIVER_ACTION_TYPE } from "../../utilities/constants"
import { EditDriverDto, FileUploadDrivertDto, GetDriverDocumentParmDto, GetVehicleNumberParamDto, 
  ModifyDriverDto, NewDriverPayloadDto, getDriversSummaryParam } from "../../utilities/models"

const viewDriver = (id: number) => {
  return {
      type: DRIVER_ACTION_TYPE.GET_DRIVER + COMMON_ACTION_TYPES.REQUEST,
      id: id
  }
}

const viewDriverClear = () => {
  return {
      type: DRIVER_ACTION_TYPE.GET_DRIVER + COMMON_ACTION_TYPES.CLEAR
  }
}

const editDriver = (payload: EditDriverDto) => {
  return {
      type: DRIVER_ACTION_TYPE.EDIT_DRIVER + COMMON_ACTION_TYPES.REQUEST,
      payload: payload
  }
}

const editDriverResponseClear = () => {
    return { type: DRIVER_ACTION_TYPE.EDIT_DRIVER + COMMON_ACTION_TYPES.CLEAR }
}

const modifyDriverVehicle = (payload: ModifyDriverDto) => {
  return {
      type: DRIVER_ACTION_TYPE.MODIFY_DRIVER_VEHICLE + COMMON_ACTION_TYPES.REQUEST,
      payload: payload
  }
}

const modifyDriverVehicleResponseClear = () => {
    return { type: DRIVER_ACTION_TYPE.MODIFY_DRIVER_VEHICLE + COMMON_ACTION_TYPES.CLEAR }
}

const createDriver = (payload: NewDriverPayloadDto) => {
  return {
      type: DRIVER_ACTION_TYPE.POST_DRIVER + COMMON_ACTION_TYPES.REQUEST,
      payload: payload
  }
}

const createDriverResponseClear = () => {
    return { type: DRIVER_ACTION_TYPE.POST_DRIVER + COMMON_ACTION_TYPES.CLEAR }
}

const getDrivers = (payload:getDriversSummaryParam) => {
  return { 
    type: DRIVER_ACTION_TYPE.GET_DRIVER_LIST + COMMON_ACTION_TYPES.REQUEST,
    payload: payload 
  }
}

const getDriversClear = () => {
  return{
    type: DRIVER_ACTION_TYPE.GET_DRIVER_LIST + COMMON_ACTION_TYPES.CLEAR,
  }
}

const deleteDriver = (id: number) => {
  return {
    type: DRIVER_ACTION_TYPE.DELETE_DRIVER + COMMON_ACTION_TYPES.REQUEST,
    id: id
  }
}

const deleteDriverClear = () => {
  return {type:DRIVER_ACTION_TYPE.DELETE_DRIVER + COMMON_ACTION_TYPES.CLEAR}
}

const getPlantList = () => {
  return { type: DRIVER_ACTION_TYPE.GET_PLANT_LIST + COMMON_ACTION_TYPES.REQUEST }
}

const getVehicleTypesByPlant = (id: number) => {
  return {
    type: DRIVER_ACTION_TYPE.GET_VEHICLE_TYPES_BY_PLANT + COMMON_ACTION_TYPES.REQUEST,
    id: id
  }
}

const getVehicleNumbersByType = (param: GetVehicleNumberParamDto) => {
  return {
    type: DRIVER_ACTION_TYPE.GET_VEHICLE_NUMBERS_BY_TYPE + COMMON_ACTION_TYPES.REQUEST,
    param: param
  }
}

const getVehicleNumbersByDriver = (nic: string) => {
  return {
    type: DRIVER_ACTION_TYPE.GET_VEHICLE_NUMBERS_BY_DRIVER + COMMON_ACTION_TYPES.REQUEST,
    nic: nic
  }
}


export const driverActions = {
  viewDriver,
  viewDriverClear,
  createDriver,
  createDriverResponseClear,
  editDriver,
  editDriverResponseClear,
  modifyDriverVehicle,
  modifyDriverVehicleResponseClear,
  getDrivers,
  deleteDriver,
  getPlantList,
  getVehicleTypesByPlant,
  getVehicleNumbersByType,
  getVehicleNumbersByDriver,
  deleteDriverClear,
  getDriversClear
}