import { COMMON_ACTION_TYPES, TRANSPORT_ACTION_TYPE, TRANSPORT_ACTION_TYPES, TRANSPORT_ACTION_TYPES_BANKACC, TRANSPORT_ACTION_TYPES_OTHERDOC } from "../../utilities/constants"
import { GetRequiredDocumentParmDto, ITransportCompanyDTO, ITransportCompanyDTOEdit, ITransportCompanyDTOadd, RegisterTransportCompanyDto, UpdateTransportCompany, fileUploadTransportDto, getSummaryGridParam } from "../../utilities/models"

const getAllTransportCompanies = () => {
    return { type: TRANSPORT_ACTION_TYPE.GET_ALL_TRANSPORT_COMPANIES + COMMON_ACTION_TYPES.REQUEST }
}

// const ViewAllTransportCompanies = () => {
//     return { type: TRANSPORT_ACTION_TYPE.GET_ALL_TRANSPORT_COMPANIES + COMMON_ACTION_TYPES.REQUEST }
// }

const AddTransportCompany = (payload:ITransportCompanyDTOadd) => {
    console.log("data from action",payload)
     return { 
         type:TRANSPORT_ACTION_TYPE.ADD_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.REQUEST ,
         payload:payload
     }
     
 }

 const getRequestBYIdCompany = (companyId: number) => {
    console.log("getRequestBYIdCompanyACtion",companyId)
    return {
        type: TRANSPORT_ACTION_TYPE.VIEW_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.REQUEST,
        companyId: companyId
    }
}


const deleteCompany = (companyIdDel: number) => {
    return {
        type: TRANSPORT_ACTION_TYPE.DELETE_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.REQUEST,
        companyIdDel: companyIdDel
    }
}

export const ADD_TO_TABLE = 'ADD_TO_TABLE';

export const addToTable = (data:any) => ({
  type: ADD_TO_TABLE,
  payload: data,
});

const getRequestBYIdCompanyClear = () => {
    return { type: TRANSPORT_ACTION_TYPE.VIEW_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.CLEAR }
  }

  const CreateNewCompanyClear = () => {
    return {
        type: TRANSPORT_ACTION_TYPE.ADD_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.CLEAR
    }
}

const editCompany = (payload: any) => {
    return {
      type: TRANSPORT_ACTION_TYPE.EDIT_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.REQUEST,
      payload: payload
    }
  }
  

const editCompanyResponseClear = () => {
    return { type: TRANSPORT_ACTION_TYPE.EDIT_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.CLEAR }
}

const uploadFileTransport = (payload:fileUploadTransportDto) => {
    console.log("payladfromaction",payload)
    return { 
        type: TRANSPORT_ACTION_TYPES.TRANSPORT_UPLOAD_FILE + COMMON_ACTION_TYPES.REQUEST ,
        payload:payload,
    }
}

const uploadFileTransportBankAcc = (payload:fileUploadTransportDto) => {

    return { 
        type: TRANSPORT_ACTION_TYPES_BANKACC.TRANSPORT_BANKACC_UPLOAD_FILE + COMMON_ACTION_TYPES.REQUEST ,
        payload:payload,
    }
}

const uploadFileTransportOtherDoc = (payload:fileUploadTransportDto) => {

    return { 
        type: TRANSPORT_ACTION_TYPES_OTHERDOC.TRANSPORT_OTHERDOC_UPLOAD_FILE + COMMON_ACTION_TYPES.REQUEST ,
        payload:payload
    }
}

const GetRequiredDocument=(payload:GetRequiredDocumentParmDto)=>{
    return { 
        type: TRANSPORT_ACTION_TYPES.TRANSPORT_GET_REQUIRED_DOCUMENT + COMMON_ACTION_TYPES.REQUEST ,
        payload:payload
    }
}

const RemoveUploadedDocument =(payload:GetRequiredDocumentParmDto)=>{
    return { 
        type: TRANSPORT_ACTION_TYPES.TRANSPORT_REMOVE_DOCUMENT + COMMON_ACTION_TYPES.REQUEST ,
        payload:payload
    }
}


///added newly
const getAllTransportCompanyDetails =(payload:getSummaryGridParam)=>{
    return { 
        type: TRANSPORT_ACTION_TYPES.GET_ALL_TRANSPORT_COMPANY_DETAILS + COMMON_ACTION_TYPES.REQUEST,
        payload:payload
    }
}

const GetPaymentMethods=()=>{
    return { 
        type: TRANSPORT_ACTION_TYPES.GET_PAYMENT_METHODS + COMMON_ACTION_TYPES.REQUEST ,
       
    }
}
const GetBankList=()=>{
    return { 
        type: TRANSPORT_ACTION_TYPES.GTE_BANK_LIST + COMMON_ACTION_TYPES.REQUEST ,
       
    }
}
const GetBranchList=(payload:string)=>{
    return { 
        type: TRANSPORT_ACTION_TYPES.GTE_BRANCH_LIST + COMMON_ACTION_TYPES.REQUEST ,
        payload:payload
    }
}

const RegisterTransportCompany=(payload:RegisterTransportCompanyDto)=>{
    return { 
        type: TRANSPORT_ACTION_TYPES.ADD_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.REQUEST ,
        payload:payload
    }
}
const getTransportCompanyById=(payload:string)=>{
    return { 
        type: TRANSPORT_ACTION_TYPES.Get_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.REQUEST ,
        payload:payload
    }
}

const UpdateTransportCompanyByID=(payload:UpdateTransportCompany)=>{
    return { 
        type: TRANSPORT_ACTION_TYPES.UPDATE_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.REQUEST ,
        payload:payload
    }
}
const UpdateTransportCompanyByIDClear=()=>{
    return { 
        type: TRANSPORT_ACTION_TYPES.UPDATE_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.CLEAR ,
       
    }
}
const GetBranchListClear=()=>{
    return { 
        type: TRANSPORT_ACTION_TYPES.GTE_BRANCH_LIST + COMMON_ACTION_TYPES.CLEAR ,
       
    }
}
const RegisterTransportCompanyClear=()=>{
    return { 
        type: TRANSPORT_ACTION_TYPES.ADD_TRANSPORT_COMPANY + COMMON_ACTION_TYPES.CLEAR ,
     
    }
}
const getTransportCompanyByIdClear=()=>{
    return { 
        type: TRANSPORT_ACTION_TYPES.Get_TRANSPORT_COMPANY_BY_ID + COMMON_ACTION_TYPES.CLEAR ,
     
    }
}


export const transportActions = {
    getAllTransportCompanies,
    AddTransportCompany,
    getRequestBYIdCompany,
    getRequestBYIdCompanyClear,
    deleteCompany,
    CreateNewCompanyClear,
    editCompany,
    editCompanyResponseClear,
    uploadFileTransport,
    uploadFileTransportBankAcc,
    uploadFileTransportOtherDoc,
    GetRequiredDocument,
    RemoveUploadedDocument,
    // ViewAllTransportCompanies,
    getAllTransportCompanyDetails,
    GetPaymentMethods,GetBankList,
    GetBranchList,
    RegisterTransportCompany,
    getTransportCompanyById,
    UpdateTransportCompanyByID,
    UpdateTransportCompanyByIDClear,
    GetBranchListClear,
    RegisterTransportCompanyClear,
    getTransportCompanyByIdClear,
}