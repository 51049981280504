import { createSlice } from '@reduxjs/toolkit'
import { getBank, getBranch, getCompanyViewList, getSBUs, getTransportCompanies, insertCompany } from '../../services/transportcompany.service';
import { IpassengerDetails } from '../../types/TransportCompanyDto';
import { APP_ACTION_STATUS, COMMON_ACTION_TYPES } from '../../utilities/constants';


export const TransportCompany = createSlice({
  name: 'TransportCompany',
  initialState: {
    transCompany:"",
    companyDetails:[],
    loading:false,
    error: null,
    data: null,
    notificationMessage:"",
    DocumentList:[] as any[],
    paymentList :[] as any[],  
    // SBUList :[] as any[],  
    BankList:[] as any[],  
    BranchList:[] as any[], 
    passengerDetails:{},
    CompanyViewList:[] as any[],
    status: APP_ACTION_STATUS.INITIAL,
  },
  reducers: {


    deleteRecord: (state, action) => {
      state.loading = true;
      state.CompanyViewList = action.payload;
      state.loading = false;
    },
   
    getAllCompanyDetails: (state,action) => {
      
      
    },

    // setSBUList: (state, action) => {
    //   state.SBUList = action.payload;
    // },

    setBankList: (state, action) => {
      state.BankList = action.payload;
    },

    setBranchList: (state, action) => {
      state.BranchList = action.payload;
    },

    setpassengerDetails: (state, action) => {
      state.passengerDetails = action.payload;
    },

    setCompanyViewDetails: (state, action) => {
      state.CompanyViewList = action.payload;
    },


    removePaymentMethodFromList:(state,action)=>{
      const { paymentId } = action.payload;
      const index = state.paymentList.findIndex(item => item.id === paymentId);
      if (index !== -1) {
        state.paymentList.splice(index, 1);
      }

    },
    addPaymentMethodList:(state,action)=>{
      state.paymentList.push(action.payload);
    },

    addDocumentList:(state,action)=>{
      state.DocumentList.push(action.payload);
    },

    removeDocumentFromList:(state,action)=>{
      const { documentName } = action.payload;
      const index = state.DocumentList.findIndex(item => item.id === documentName);
      if (index !== -1) {
        state.DocumentList.splice(index, 1);
      }
    },
    
  },
  extraReducers: (builder) => {
      builder.addCase(getTransportCompanies.fulfilled, (state, action:any) => {
        
      })
      builder.addCase(insertCompany.fulfilled, (state, action:any) => {
        state.loading=false;
      })

      // builder.addCase(getSBUs.fulfilled, (state, action:any) => {
        
      //   state.SBUList = action.payload

      // })

       builder.addCase(getBank.fulfilled, (state, action:any) => {
        
        state.BankList = action.payload

      })

      builder.addCase(getBranch.fulfilled, (state, action:any) => {
        
        state.BranchList = action.payload

      })

      builder.addCase(getSBUs.fulfilled, (state, action:any) => {
        
        state.passengerDetails = action.payload

      })
            //getCompanyViewList
      builder.addCase(getCompanyViewList.pending, (state, action:any) => {
        
        state.loading = true
        state.status =  APP_ACTION_STATUS.LOADING
       state.data = null
      })

      builder.addCase(getCompanyViewList.fulfilled, (state, action:any) => {
        state.loading = false
        
        state.CompanyViewList = action.payload
        state.status =APP_ACTION_STATUS.SUCCESS
      })


    


      
    }
})

export const {deleteRecord, removePaymentMethodFromList, getAllCompanyDetails,addPaymentMethodList, addDocumentList, removeDocumentFromList} = TransportCompany.actions

export default TransportCompany.reducer


