import React from 'react'
import { BootstrapDialog, BootstrapDialogContent, BootstrapDialogTitle, StyledTableCell } from '../../../assets/theme/theme'
import styles from './AllocatedRequisitionsPopUp.module.scss'
import { TableContainer, Paper, Table, TableHead, TableRow, TableBody, TablePagination, Box } from '@mui/material'
import { APP_TABLE_CONFIGS } from '../../../utilities/constants'
import { AppSkeleton, CustomButton, CustomHeaderCell } from '../../Shared'
import { allocationRequisitionsDto } from '../../../utilities/models'
const AllocatedRequisitionsPopUp: React.FC<{
  page: number,
  rowsPerPage: number,
  isFiltered: boolean,
  onSortHandle(col: string): void
  onHandleChangePage(event: unknown, newPage: number): void,
  onHandleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void,
  isOpenAddPackagePopup: boolean,
  OnclickPopupClose(): void
  allocatedRequisitions: allocationRequisitionsDto[]
  onClearFilter2(): void;
  getFilterList: (col: string) => string[];
  onSortHandle(col: string): void
  onFilterHandle(col: string, value: any): void;
  isLoading: boolean;
}> = (props) => {
  return (
    <BootstrapDialog
      width={"2400px"}
      className={styles.dialogCard}
      aria-labelledby="customized-dialog-title"
      open={props.isOpenAddPackagePopup}
    >
      <BootstrapDialogTitle id="customized-dialog-title"
        onClose={() => { props.OnclickPopupClose() }}>
        Allocated Requisitions
        <Box sx={{ alignItems: "end", right: '5rem', position: "absolute" }}>
          {props.isFiltered &&
            <CustomButton text='Clear filter' textColor='black' bgColor='#bfbfbf' onClick={props.onClearFilter2} />
          }
        </Box>
      </BootstrapDialogTitle>

      <BootstrapDialogContent>
        <section className={styles.gridSection}>
          <TableContainer component={Paper} className={styles.grid}>

            <Table className={styles.table}>
              <TableHead>
                <TableRow>
                  <CustomHeaderCell id='recurrent' sortable onSort={props.onSortHandle}  filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Parent ID(Recurrent)</CustomHeaderCell>
                  <CustomHeaderCell id='parentRequestId' sortable onSort={props.onSortHandle}  filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Pre-Split Request ID</CustomHeaderCell>
                  <CustomHeaderCell id='requestId' sortable onSort={props.onSortHandle}  >Request ID</CustomHeaderCell>
                  <CustomHeaderCell id='createdBy' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >User Name (Created by)</CustomHeaderCell>
                  <CustomHeaderCell id='sbu' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}  > SBU</CustomHeaderCell>
                  <CustomHeaderCell id='plant' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} > Plant</CustomHeaderCell>
                  <CustomHeaderCell id='department' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}  > Department</CustomHeaderCell>
                  <CustomHeaderCell id='fromLocation' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}  > Travel from</CustomHeaderCell>
                  <CustomHeaderCell id='toLocation' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}  >Travel to</CustomHeaderCell>
                  <CustomHeaderCell id='departureDateTime' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Departure Date/time</CustomHeaderCell>
                  <CustomHeaderCell id='returnDateTime' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Return Date/time</CustomHeaderCell>
                  <CustomHeaderCell id='requestType' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}  >Request Type</CustomHeaderCell>
                  <CustomHeaderCell id='purpose'>Purpose</CustomHeaderCell>
                  <CustomHeaderCell id='passengerCount' sortable onSort={props.onSortHandle}filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}   >Passenger count</CustomHeaderCell>
                  <CustomHeaderCell id='isPackage' sortable onSort={props.onSortHandle}filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}  >Package </CustomHeaderCell>
                  <CustomHeaderCell id='cbm'>Package CBM (m3) </CustomHeaderCell>
                  <CustomHeaderCell id='tripDuration' sortable onSort={props.onSortHandle}  onFilter={props.onFilterHandle}  >Total trip duration</CustomHeaderCell>
                  <CustomHeaderCell id='totalStops' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}  >Total stops</CustomHeaderCell>
                </TableRow>
              </TableHead>
              {props.isLoading && (
                <AppSkeleton numOfRows={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE} numOfColumns={12} columnWidth={180} height={12} stickyFirst tag="table" />
              )}
              {props.allocatedRequisitions && props.allocatedRequisitions.length > 0 &&
                <TableBody>
                  {props.allocatedRequisitions.map((p) => (
                    <TableRow key={p.requestId}>
                      <StyledTableCell >{p.recurrentParentId}</StyledTableCell>
                      <StyledTableCell >{p.parentRequestId}</StyledTableCell>
                      <StyledTableCell >     {p.requestType === "Express AdHoc"
    ? "E" + p.requestId.toString()
    : "R" + p.requestId.toString()
  }</StyledTableCell>
                      <StyledTableCell >{p.createdBy}</StyledTableCell>
                      <StyledTableCell >{p.sbu}</StyledTableCell>
                      <StyledTableCell >{p.plant}</StyledTableCell>
                      <StyledTableCell >{p.department}</StyledTableCell>
                      <StyledTableCell >{p.fromLocation}</StyledTableCell>
                      <StyledTableCell >{p.toLocation}</StyledTableCell>
                      <StyledTableCell >{p.departureDateTime }</StyledTableCell>
                      <StyledTableCell >{p.returnDateTime}</StyledTableCell>
                      <StyledTableCell >{p.requestType}</StyledTableCell>
                      <StyledTableCell >{p.purpose}</StyledTableCell>
                      <StyledTableCell >{p.passengerCount}</StyledTableCell>
                      <StyledTableCell >{p.isPackage}</StyledTableCell>
                      <StyledTableCell >{p.cbm}</StyledTableCell>
                      <StyledTableCell >{p.tripDuration}</StyledTableCell>
                      <StyledTableCell >{p.totalStops}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              }
              {props.allocatedRequisitions.length === 0 && !props.isLoading &&
                <TableBody>
                  <TableRow>
                    <StyledTableCell align="center" colSpan={15}>No data to preview</StyledTableCell>
                  </TableRow>
                </TableBody>
              }
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_OPTIONS}
            component="div"
            labelRowsPerPage={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  color: 'white',
                }}
              >
                Items per page
              </div>
            }
            count={props.allocatedRequisitions.length}
            page={props.page}
            onPageChange={props.onHandleChangePage}
            onRowsPerPageChange={props.onHandleChangeRowsPerPage}
            rowsPerPage={props.rowsPerPage}
            showFirstButton={true}
            showLastButton={true}
            sx={{ backgroundColor: "#282828", color: "white" }}
          />
        </section>
      </BootstrapDialogContent>
    </BootstrapDialog>


  )
}

export default AllocatedRequisitionsPopUp