import { combineReducers } from "redux";
import requestReducer from "./request.reducer";
import vehicleReducer from "./vehicle.reducer";
import locationReducer from "./location.reducer";
import userReducer from "./user.reducer";
import approverReducer from "./approver.reducer";
import packageReducer from "./package.reducer";
import transportcompanyReducer from "./transportcompany.reducer";
import TemplateReducer from "./template.reducer";
import alertReducer from "./alert.reducer";
import authReducer from "./auth.reducer";
import splitReducer from "./split.reducer";
import tripReducer from "./trip.reducer";
import permissonReducer from "./permission.reducer";
import chartReducer from "./chart.reducer";
import transportReducer from "./transport.reducer";
import driverReducer from "./driver.reducer";
import notificationReducer from "./notification.reducer";
import companyReducer from "./company.reducer";
import mapReducer from "./map.reducer";
import shiftReducer from "./shift.reducer";
import linkReducer from "./link.reducer";
import gpsReducer from "./gps.reducer";
import fuelReducer from "./fuel.reducer";

const rootReducer = combineReducers({
  request: requestReducer,
  vehicle: vehicleReducer,
  location: locationReducer,
  user: userReducer,
  approver: approverReducer,
  package: packageReducer,
  transportCompany: transportcompanyReducer,
  template: TemplateReducer,
  alert: alertReducer,
  authUser: authReducer,
  split: splitReducer,
  trip: tripReducer,
  transport: transportReducer,
  driver: driverReducer,
  permission: permissonReducer,
  chart: chartReducer,
  notification:notificationReducer,
  company:companyReducer,
  map: mapReducer,
  shift:shiftReducer,
  link:linkReducer,
  gps:gpsReducer,
  fuel:fuelReducer,
});

export default rootReducer;
