import React from 'react'
import { CustomAutocomplete, CustomButton, CustomDatePicker, CustomHeaderCell, CustomStepper } from '../../Shared'
import { DriverWorkHistoryFormDto, DriverWorkhistoryListDto, PlantBriefDto, SbuBriefDto } from '../../../utilities/models'
import { Box, Grid, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import style from './WorkHistory.module.scss'
import { StyledTableCell } from '../../../assets/theme/theme'
import { APP_TABLE_CONFIGS, DRIVER_SCREEN_MODES } from '../../../utilities/constants'
import dayjs from 'dayjs'
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const WorkHistory: React.FC<{
  page: number,
  rowsPerPage: number,
  onHandleChangePage(event: unknown, newPage: number): void,
  onHandleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void,
  helperText: boolean
  screenMode: string
  driverWorkHistoryForm: DriverWorkHistoryFormDto
  driverWorkHistoryList: DriverWorkhistoryListDto[]
  allSBUList: SbuBriefDto[]
  plantListBysbu: PlantBriefDto[]
  onAddWorkHistory(): void
  onSelectWorkHistory(id: number, action: string):void
  onClearWorkHistoryForm(): void
  onSortHandle(col: string): void
  handleInputFocus(property: string, section: string): void
  onInputHandleChangeDriverhistory(property: string, section: string): void
}> = (props) => {
  const _sbu = props.driverWorkHistoryForm.sbu
  const _plant = props.driverWorkHistoryForm.plant
  const _startDate = props.driverWorkHistoryForm.startDate
  const _endDate = props.driverWorkHistoryForm.endDate

  return (
    <CustomStepper stepNumber={4} stepTitle="Previous Work History">
        <>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <CustomAutocomplete
                label="SBU"
                placeholder='Select SBU'
                options={props.allSBUList.map((t) => { return { label: t.name, value: t.id } })}
                value={_sbu.value}
                error={!!_sbu.error}
                disabled={_sbu.disable}
                required={_sbu.isRequired}
                readonly={_sbu.readonly || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                helperText={props.helperText && _sbu.error}
                onFocus={() => props.handleInputFocus('sbu', 'work-history')}
                onChange={(event: any, value: any) => props.onInputHandleChangeDriverhistory('sbu', value)}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomAutocomplete
                label="Plant"
                placeholder='Select Plant'
                options={props.plantListBysbu.map((p) => { return { label: p.plantName, value: p.id } })}
                value={_plant.value}
                error={!!_plant.error}
                disabled={_plant.disable}
                required={_plant.isRequired}
                readonly={_plant.readonly || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                helperText={props.helperText && _plant.error}
                onFocus={() => props.handleInputFocus('plant', 'work-history')}
                onChange={(event: any, value: any) => props.onInputHandleChangeDriverhistory('plant', value)}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                label="Start Date"
                placeholder='Select Date'
                multiple={false}
                maxDate={(dayjs().subtract(1, 'day').format('YYYY/MM/DD'))}
                value={_startDate.value}
                error={!!_startDate.error}
                disabled={_startDate.disable || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                readOnly={_startDate.readonly || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                required={_startDate.isRequired}
                helperText={props.helperText && _startDate.error}
                onFocus={() => props.handleInputFocus('startDate', 'work-history')}
                onChange={(value: any) => props.onInputHandleChangeDriverhistory('startDate', value)}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                label="End Date"
                placeholder='Select Date'
                multiple={false}
                maxDate={new Date()}
                minDate={_startDate.value} 
                value={_endDate.value}
                error={!!_endDate.error}
                disabled={_endDate.disable || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                readOnly={_endDate.readonly || props.screenMode === DRIVER_SCREEN_MODES.VIEW}
                required={_endDate.isRequired}
                helperText={props.helperText && _endDate.error}
                onFocus={() => props.handleInputFocus('endDate', 'work-history')}
                onChange={(value: any) => props.onInputHandleChangeDriverhistory('endDate', value)}
              />
            </Grid>
          </Grid>

          <section className={style.actions}>
            <CustomButton text='Clear' textColor='black' disabled={props.screenMode === DRIVER_SCREEN_MODES.VIEW} bgColor='#bfbfbf' onClick={props.onClearWorkHistoryForm} />
            <CustomButton text='Add Work History' border='1px solid #6e6e6e' disabled={props.screenMode === DRIVER_SCREEN_MODES.VIEW} bgColor='#282828' onClick={props.onAddWorkHistory} />
          </section>
        </>

      <section>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <CustomHeaderCell id='id' >#</CustomHeaderCell>
                <CustomHeaderCell id='sbu' sortable onSort={props.onSortHandle} >SBU</CustomHeaderCell>
                <CustomHeaderCell id='plant' sortable onSort={props.onSortHandle} >Plant</CustomHeaderCell>
                <CustomHeaderCell id='from' sortable onSort={props.onSortHandle} >Start Date</CustomHeaderCell>
                <CustomHeaderCell id='to' sortable onSort={props.onSortHandle} >End Date</CustomHeaderCell>
                {props.screenMode !== DRIVER_SCREEN_MODES.VIEW && <CustomHeaderCell width={150} id='actions' >Actions</CustomHeaderCell>}
              </TableRow>
            </TableHead>
            {props.driverWorkHistoryList && props.driverWorkHistoryList.length > 0 &&
              <TableBody>
                {props.driverWorkHistoryList.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage).map((p) => (
                  <TableRow key={p.id}>
                    <StyledTableCell >{p.id}</StyledTableCell>
                    <StyledTableCell >{p.sbu}</StyledTableCell>
                    <StyledTableCell >{p.plant}</StyledTableCell>
                    <StyledTableCell >{p.from}</StyledTableCell>
                    <StyledTableCell >{p.to}</StyledTableCell>
                    <StyledTableCell>
                    {props.screenMode !== DRIVER_SCREEN_MODES.VIEW && (
                      <Box className="layout-row">
                        <Box>
                          <IconButton size='small' onClick={() => props.onSelectWorkHistory(p.id, "delete")}>
                            <Tooltip title="Delete">
                              <DeleteOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                            </Tooltip>
                          </IconButton>
                        </Box>
                      </Box>
                    )}    
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            }
            {props.driverWorkHistoryList.length === 0 &&
              <TableBody>
                <TableRow>
                  <StyledTableCell align="left" colSpan={8}>No data to preview</StyledTableCell>
                </TableRow>
              </TableBody>
            }
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_OPTIONS}
          component="div"
          labelRowsPerPage={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                color: 'white',
              }}
            >
              Items per page
            </div>
          }
          count={props.driverWorkHistoryList.length}
          page={props.page}
          onPageChange={props.onHandleChangePage}
          onRowsPerPageChange={props.onHandleChangeRowsPerPage}
          rowsPerPage={props.rowsPerPage}
          showFirstButton={true}
          showLastButton={true}
          sx={{ backgroundColor: "#282828", color: "white" }}
        />
      </section>
    </CustomStepper>
  )
}

export default WorkHistory
