import ENV from '../../.env/index'

export const APP_CONFIGS = {
  APP_OWNER: 'MAS-TRAN-WEB-DEV',
  APP_ENV: ENV.APP_ENV,
  API_BASE: ENV.API_BASE,
  AUTHORITY: ENV.AUTHORITY,
  CLIENT_ID: ENV.CLIENT_ID,
  REDIRECT_URL: ENV.REDIRECT_URL,
  APP_SCOPES: ENV.APP_SCOPES,
  USER_DATA_COOKIE: 'MASUser',
}

export const SECRET_KEY = '1234'