import React from 'react'
import { Box, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import moment from 'moment';
import { PendingVehicleAllocationsDto } from '../../utilities/models';
import { AppSkeleton, CustomButton, CustomHeaderCell } from '../Shared';
import { StickyTableCell, StyledCheckBox, StyledStatusApproved, StyledStatusDraft, StyledStatusPending, StyledStatusRejected, StyledTableCell } from '../../assets/theme/theme';
import { APP_ROUTES, APP_TABLE_CONFIGS, REQUEST_SCREEN_EXPRESS_MODES, REQUEST_SCREEN_MODES, REQUEST_STATUS } from '../../utilities/constants';
import style from './PendingVehicleAllocationGrid.module.scss'
import { useNavigate } from 'react-router-dom';

const PendingVehicleAllocationGrid: React.FC<{
  page: number,
  rowsPerPage: number,
  isFiltered: boolean,
  onHandleChangePage(event: unknown, newPage: number): void,
  onHandleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void,
  approvalRequestDataIsLoading: boolean,
  filteredList: PendingVehicleAllocationsDto[],
  onSortHandle(col: string): void
  onFilterHandle(col: string, value: any): void;
  onSelectRequestIds(id: string, value: boolean): void;
  getFilterList: (col: string) => string[];
  navigateTo(mode: string, id: string): void;
  onClearFilter(): void;
  onAllocateVehicle(): void;
  onActionButtonClick(): void;
  onSeeAllocationHistory(): void;
  filters:any[];
}> = (props) => {

  const navigate = useNavigate()
  const _parentRequestId = props.filters[0]?.parentRequestId || [];
  const _recurrentParentId = props.filters[0]?.recurrentParentId || [];
  // const _createFor = props.filters[0]?.createFor || [];
  const _createdBy = props.filters[0]?.createdBy || [];
  const _requestType = props.filters[0]?.requestType || [];
  const _redirected = ["Yes", "No"] || [];
  const _sbu = props.filters[0]?.sbu || [];
  const _plant = props.filters[0]?.plant || [];
  const _department = props.filters[0]?.department || [];
  const _travelMode = props.filters[0]?.travelMode || [];
  const _fromLocation = props.filters[0]?.fromLocation || [];
  const _toLocation = props.filters[0]?.toLocation || [];
  const _passengerCount = props.filters[0]?.passengerCount || [];
  const _preferredVehicle = props.filters[0]?.preferredVehicle || [];
  const _status = props.filters[0]?.status || [];
  const _createBy = props.filters[0]?.createBy || [];
  const _isPackage = ["Yes", "No"] || [];
  const _isVIP = ["Yes", "No"] || [];

  return (
    <section className={style.gridContainer}>
      <div className={style.gridHeader}>
        <Typography
          noWrap
          component="div"
          className={style.gridTitle}
        >
          <h3>Pending Allocations</h3>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <div className='layout-row'>
          <CustomButton text='See Allocation History' border='0px solid #6e6e6e' bgColor='transparent' isShadow='none' textDecoration='underline' onClick={() => { props.onSeeAllocationHistory() }} />
          {props.isFiltered &&
            <CustomButton text='Clear filter' textColor='black' bgColor='#bfbfbf' onClick={props.onClearFilter} />
          }
          <CustomButton text='Reject' border='1px solid #6e6e6e' bgColor='#282828' onClick={props.onActionButtonClick} />
          <CustomButton
            text='Allocate'
            onClick={props.onAllocateVehicle}
            disabled={props.filteredList.filter((r) => r.isSelect).length > 2}
          />
        </div>
      </div>

      <TableContainer component={Paper} className={style.grid}>
        <Table className={style.table}>
          <TableHead>
            <TableRow>
              <StickyTableCell width={60} ><StyledCheckBox size="small" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onSelectRequestIds('ALL', event.target.checked)} /></StickyTableCell>
              <CustomHeaderCell width={180} id='recurrentParentId' sortable onSort={props.onSortHandle} filtered filterStringList={_recurrentParentId} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Parent ID</CustomHeaderCell>
              <CustomHeaderCell width={220} id='parentRequestId' sortable onSort={props.onSortHandle} filtered filterStringList={_parentRequestId} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Pre-split Request ID</CustomHeaderCell>
              <CustomHeaderCell width={180} id='requestId' sortable onSort={props.onSortHandle} >Request ID</CustomHeaderCell>
              <CustomHeaderCell width={180} id='requestType' sortable onSort={props.onSortHandle} filtered filterStringList={_requestType} getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Request Type</CustomHeaderCell>
              <CustomHeaderCell width={180} id='Redirected' sortable onSort={props.onSortHandle} filtered filterStringList={_redirected} getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Redirected</CustomHeaderCell>
              <CustomHeaderCell width={180} id='createdBy' sortable onSort={props.onSortHandle} filtered filterStringList={_createdBy} getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >User Name</CustomHeaderCell>
              <CustomHeaderCell width={150} id='sbu' sortable onSort={props.onSortHandle} filtered filterStringList={_sbu} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>SBU</CustomHeaderCell>
              <CustomHeaderCell width={150} id='plant' sortable onSort={props.onSortHandle} filtered filterStringList={_plant} getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Plant</CustomHeaderCell>
              <CustomHeaderCell width={220} id='department' sortable onSort={props.onSortHandle} filtered filterStringList={_department} getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >Department</CustomHeaderCell>
              <CustomHeaderCell width={200} id='travelMode' sortable onSort={props.onSortHandle} filtered filterStringList={_travelMode} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Travel mode</CustomHeaderCell>
              <CustomHeaderCell width={150} id='fromLocation' sortable onSort={props.onSortHandle} filtered filterStringList={_fromLocation} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>From</CustomHeaderCell>
              <CustomHeaderCell width={220} id='toLocation' sortable onSort={props.onSortHandle} filtered filterStringList={_toLocation} getFilterList={props.getFilterList} onFilter={props.onFilterHandle} >To (Main Destination)</CustomHeaderCell>
              <CustomHeaderCell width={270} id='departureDateTime' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Departure date & time</CustomHeaderCell>
              <CustomHeaderCell width={250} id='returnDateandTime' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Return date & time</CustomHeaderCell>
              <CustomHeaderCell width={180} id='cost' >Projected cost</CustomHeaderCell>
              <CustomHeaderCell width={240} id='passengerCount' filtered filterStringList={_passengerCount} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Passenger count</CustomHeaderCell>
              <CustomHeaderCell width={240} id='preferredVehicle' sortable onSort={props.onSortHandle} filtered filterStringList={_preferredVehicle} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Preferred Vehicle</CustomHeaderCell>
              <CustomHeaderCell width={150} id='Package' sortable onSort={props.onSortHandle} filtered filterStringList={_isPackage} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Package</CustomHeaderCell>
              <CustomHeaderCell width={150} id='VIP' sortable onSort={props.onSortHandle} filtered filterStringList={_isVIP} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>VIP</CustomHeaderCell>
              <CustomHeaderCell width={240} id='totalCBM' sortable onSort={props.onSortHandle} >Total CBM of package</CustomHeaderCell>
              <CustomHeaderCell width={250} id='purpose' >Purpose</CustomHeaderCell>
              <CustomHeaderCell width={180} id='status' sortable onSort={props.onSortHandle} filtered filterStringList={_status} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Status</CustomHeaderCell>
              <CustomHeaderCell width={240} id='createDateTime' sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Create date and time</CustomHeaderCell>
              <CustomHeaderCell width={240} id='createBy' sortable onSort={props.onSortHandle} filtered filterStringList={_createBy} getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Created by</CustomHeaderCell>
              <CustomHeaderCell width={120} id='actions' >Actions</CustomHeaderCell>
            </TableRow>
          </TableHead>
          {props.approvalRequestDataIsLoading && (
            <AppSkeleton numOfRows={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE} numOfColumns={25} columnWidth={80} height={35} stickyFirst tag="table" />
          )}
          {!props.approvalRequestDataIsLoading && props.filteredList.length > 0 &&
            <TableBody>
              {props.filteredList.map((req: PendingVehicleAllocationsDto) => (
                <TableRow key={req.requestId}>
                  <StickyTableCell ><StyledCheckBox size="small" checked={req.isSelect} onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onSelectRequestIds(req.requestId.toString(), event.target.checked)} /></StickyTableCell>
                  <StyledTableCell >{!req.recurrentParentId ? 'N/A' : 'R' + req.recurrentParentId}</StyledTableCell>
                  <StyledTableCell >{!req.parentRequestId ? 'N/A' : 'R' + req.parentRequestId}</StyledTableCell>
                  <StyledTableCell > {req.requestType === "Express AdHoc"? "E" + req.requestId.toString() : "R" + req.requestId.toString()}</StyledTableCell>
                  <StyledTableCell >{req.requestType}</StyledTableCell>
                  <StyledTableCell >{req.redirected ? "Yes" : "No"}</StyledTableCell>
                  <StyledTableCell >{req.createdBy}</StyledTableCell>
                  <StyledTableCell >{req.sbu ?? '-'}</StyledTableCell>
                  <StyledTableCell >{req.plant ?? '-'}</StyledTableCell>
                  <StyledTableCell >{req.department ?? '-'}</StyledTableCell>
                  <StyledTableCell >{req.travelMode}</StyledTableCell>
                  <StyledTableCell >{req.fromLocation}</StyledTableCell>
                  <StyledTableCell >{req.toLocation}</StyledTableCell>
                  <StyledTableCell >{moment(req.departureDateTime).format(APP_TABLE_CONFIGS.DATE_FORMAT)}</StyledTableCell>
                  <StyledTableCell >{moment(req.returnDateTime).format(APP_TABLE_CONFIGS.DATE_FORMAT)}</StyledTableCell>
                  <StyledTableCell >{req.cost}</StyledTableCell>
                  <StyledTableCell >{req.passengerCount}</StyledTableCell>
                  <StyledTableCell >{req.preferredVehicle}</StyledTableCell>
                  <StyledTableCell >{req.isPackage ? "Yes" : "No"}</StyledTableCell>
                  <StyledTableCell >{req.isVIP ? "Yes" : "No"}</StyledTableCell>
                  <StyledTableCell >{req.totalCBM}</StyledTableCell>
                  <StyledTableCell >{req.purpose}</StyledTableCell>
                  <StyledTableCell>
                    {req.status === REQUEST_STATUS.APPROVED ||
                      req.status === REQUEST_STATUS.APPROVED_LM ||
                      req.status === REQUEST_STATUS.APPROVED_TM ||
                      req.status === REQUEST_STATUS.ALLOCATED_ME ||
                      req.status === REQUEST_STATUS.ALLOCATED_TM ||
                      req.status === REQUEST_STATUS.APPROVED_ME
                      ? (
                        <StyledStatusApproved onClick={() => { }}>
                          {req.status}
                        </StyledStatusApproved>
                      ) : req.status === REQUEST_STATUS.ALLOCATION_CANCELLED_TM ||
                        req.status === REQUEST_STATUS.ALLOCATION_CANCELLED_ME ||
                        req.status === REQUEST_STATUS.REDIRECTION_REJECTED_TM ||
                        req.status === REQUEST_STATUS.REJECTED_LM ||
                        req.status === REQUEST_STATUS.REJECTED_TM ||
                        req.status === REQUEST_STATUS.REJECTED_ME
                        ? (
                          <StyledStatusRejected onClick={() => { }}>
                            {req.status}
                          </StyledStatusRejected>
                        ) : req.status === REQUEST_STATUS.PENDING ||
                          req.status === REQUEST_STATUS.REDIRECTED_ME ||
                          req.status === REQUEST_STATUS.REDIRECTED_TM ||
                          req.status === REQUEST_STATUS.ALLOCATION_EDITED_TM ||
                          req.status === REQUEST_STATUS.ALLOCATION_EDITED_ME
                          ? (
                            <StyledStatusPending>
                              {req.status}
                            </StyledStatusPending>
                          ) : (
                            <StyledStatusDraft>
                              {req.status}
                            </StyledStatusDraft>
                          )}
                  </StyledTableCell>
                  <StyledTableCell >{moment(req.createDateTime).add(330, 'minute').format(APP_TABLE_CONFIGS.DATE_FORMAT)}</StyledTableCell>
                  <StyledTableCell >{req.createBy}</StyledTableCell>
                  <StyledTableCell style={{ backgroundColor: '#282828' }}>
                    <Box className='layout-row'>
                      <Box>
                        <IconButton size='small' onClick={() => { 
                          
                          if(req.requestType==="Express AdHoc"){
                            sessionStorage.setItem("Mode",REQUEST_SCREEN_EXPRESS_MODES.PENDING_VEHICLE_VIEW);
                            sessionStorage.setItem("id", req.requestId.toString());
                            navigate(APP_ROUTES.MANAGE_REQUEST_EXPRESS);
                           }else{
                            props.navigateTo(REQUEST_SCREEN_MODES.PENDING_VEHICLE_VIEW, req.requestId.toString()) 
                          }
                           }
                          
                          }>
                          <Tooltip title="View">
                            <VisibilityOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'white' }} />
                          </Tooltip>
                        </IconButton>
                      </Box>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          }
          {!props.approvalRequestDataIsLoading && props.filteredList.length === 0 &&
            <TableBody>
              <TableRow>
                <StyledTableCell align="left" colSpan={21}>No data to preview</StyledTableCell>
              </TableRow>
            </TableBody>
          }
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_OPTIONS}
        component="div"
        labelRowsPerPage={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: 'white',
            }}
          >
            Items per page
          </div>
        }
        count={-1}
        page={props.page}
        onPageChange={props.onHandleChangePage}
        onRowsPerPageChange={props.onHandleChangeRowsPerPage}
        rowsPerPage={props.rowsPerPage}
        nextIconButtonProps={
          { disabled: -1 < props.filteredList.length && props.filteredList.length < props.rowsPerPage }
        }
        sx={{ backgroundColor: "#282828", color: "white" }}
      />
    </section>
  )
}

export default PendingVehicleAllocationGrid
