import { AxiosResponse } from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  ALERT_ACTION_TYPES,
  COMMON_ACTION_TYPES,
  REDIRECT_ACTION_TYPE,
  SPLIT_ACTION_TYPE,
} from "../../utilities/constants";
// import { PackageTypeBriefDto } from "../../utilities/models"
// import { packageService } from "../../services/package.service"
import {
  SplitTypeBriefDto,
  SplitPassengerPackageDto,
  TransportManagerDto,
} from "../../utilities/models/split.model";
import { splitService } from "../../services/split.service";
import { AlertDto } from "../../utilities/models/alert.model";

function* getSplitTypes(action: { type: string }) {
  try {
    const splitTypes: AxiosResponse<SplitTypeBriefDto[]> = yield call(
      splitService.getSplitTypes
    );
    yield put({
      type: SPLIT_ACTION_TYPE.GET_SPLIT_TYPES + COMMON_ACTION_TYPES.SUCCESS,
      data: splitTypes.data,
    });
  } catch (error) {
    yield put({
      type: SPLIT_ACTION_TYPE.GET_SPLIT_TYPES + COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* getSplitPassengerPackagers(action: { type: string; id: number }) {
  try {
    const splitPassengersPackages: AxiosResponse<SplitPassengerPackageDto[]> =
      yield call(splitService.getSplitPassengerPackages, action.id);
    yield put({
      type:
        SPLIT_ACTION_TYPE.GET_PASSENGERS_PACKAGES + COMMON_ACTION_TYPES.SUCCESS,
      data: splitPassengersPackages.data,
    });
  } catch (error) {
    yield put({
      type:
        SPLIT_ACTION_TYPE.GET_PASSENGERS_PACKAGES + COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* createSplitRequest(action: { type: string; payload: any }) {
  try {
    const createSplitRequestResponse: AxiosResponse<string> = yield call(
      splitService.createSplitRequest,
      action.payload
    );
    const setAlert: AlertDto = {
      message: createSplitRequestResponse.data,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: "success",
      },
    };
    yield put(setAlert);
    yield put({
      type: SPLIT_ACTION_TYPE.POST_SPLIT_REQUEST + COMMON_ACTION_TYPES.SUCCESS,
      data: createSplitRequestResponse.data,
    });
  } catch (error) {
    const setAlert: AlertDto = {
      message: error as string,
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: "error",
      },
    };
    yield put(setAlert);
    yield put({
      type: SPLIT_ACTION_TYPE.POST_SPLIT_REQUEST + COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* getTransportManager(action: { type: string; id: number }) {
  try {
    const transportManager: AxiosResponse<TransportManagerDto> = yield call(
      splitService.getTransportManagerByPlant,
      action.id
    );
    yield put({
      type:
        REDIRECT_ACTION_TYPE.GET_TRANSPORT_MANAGER +
        COMMON_ACTION_TYPES.SUCCESS,
      data: transportManager.data,
    });
  } catch (error) {
    yield put({
      type:
        REDIRECT_ACTION_TYPE.GET_TRANSPORT_MANAGER + COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* createRedirectRequest(action: { type: string; payload: any }) {
  try {
    const createRedirectRequestResponse: AxiosResponse<string> = yield call(
      splitService.createRedirectRequest,
      action.payload
    );
    const setAlert: AlertDto = {
      message: "Redirected Successfully",
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.SUCCESS,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: "success",
      },
    };
    yield put(setAlert);
    yield put({
      type:
        REDIRECT_ACTION_TYPE.POST_REDIRECT_REQUEST +
        COMMON_ACTION_TYPES.SUCCESS,
      data: createRedirectRequestResponse.data,
    });
  } catch (error) {
    const setAlert: AlertDto = {
      message:"Something went wrong!",
      type: ALERT_ACTION_TYPES.TRIGGER_ALERT + COMMON_ACTION_TYPES.ERROR,
      options: {
        key: new Date().getTime() + Math.random(),
        varient: "error",
      },
    };
    yield put(setAlert);
    yield put({
      type:
        REDIRECT_ACTION_TYPE.POST_REDIRECT_REQUEST + COMMON_ACTION_TYPES.ERROR,
      error: error,
    });
  }
}

function* splitSaga() {
  yield takeEvery(
    SPLIT_ACTION_TYPE.GET_SPLIT_TYPES + COMMON_ACTION_TYPES.REQUEST,
    getSplitTypes
  );
  yield takeEvery(
    SPLIT_ACTION_TYPE.GET_PASSENGERS_PACKAGES + COMMON_ACTION_TYPES.REQUEST,
    getSplitPassengerPackagers
  );
  yield takeEvery(
    SPLIT_ACTION_TYPE.POST_SPLIT_REQUEST + COMMON_ACTION_TYPES.REQUEST,
    createSplitRequest
  );
  yield takeEvery(
    REDIRECT_ACTION_TYPE.GET_TRANSPORT_MANAGER + COMMON_ACTION_TYPES.REQUEST,
    getTransportManager
  );
  yield takeEvery(
    REDIRECT_ACTION_TYPE.POST_REDIRECT_REQUEST + COMMON_ACTION_TYPES.REQUEST,
    createRedirectRequest
  );
}

export default splitSaga;
