import { TableContainer, Paper, Table, TableHead, TableRow, TableBody, TablePagination, Box, Typography, IconButton, Tooltip,} from "@mui/material";
import React from "react";
import { StyledCheckBox, StyledTableCell } from "../../assets/theme/theme";
import { APP_TABLE_CONFIGS, ALL_ENTITLEMENTS, USER_ROLES, GENERAL_SCREEN_MODES,} from "../../utilities/constants";
import { AppSkeleton, CustomButton, CustomHeaderCell } from "../Shared";
import style from "./ManageEntitlementGird.module.scss";
import { EditOutlined } from "@mui/icons-material";
import { GetAllUsersWithEntitlementsDTO } from "../../utilities/models/permission.model";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import { ApplicationStateDto } from "../../utilities/models/state.model";

const ManageEntitlementGird: React.FC<{
  filteredList: GetAllUsersWithEntitlementsDTO[];
  page: number;
  rowsPerPage: number;
  onHandleChangePage(event: unknown, newPage: number): void;
  onHandleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void;
  DataIsLoading: boolean;
  getEntitlementId(id:any):void; 
  navigateTo(mode: string, id: number): void;
  onClearFilter(): void;
  onSortHandle(col: string): void
  onFilterHandle(col: string, value: any): void;
  getFilterList: (col: string) => string[];
  isFiltered: boolean,
}> = (props) => {
  
  const authorizedUser = useSelector((state: ApplicationStateDto) => state.authUser.authorizedUser.data);

  return (
    <section className={style.gridContainer}>
      <div className={style.gridHeader}>
        <Typography noWrap component="div" className={style.gridTitle}>
          <h3>Manage Entitlements</h3>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <div className="layout-row">
          {props.isFiltered &&
              <CustomButton text='Clear filter' textColor='black' bgColor='#bfbfbf' onClick={props.onClearFilter} />
            }
            <CustomButton  icon={<AddIcon sx={{ fontSize: '20px' }} />} text="New Entitlement" onClick={() => {props.navigateTo(GENERAL_SCREEN_MODES.CREATE, -1)}} /> 
        </div>
      </div>
      <TableContainer component={Paper} className={style.grid}>
        <Table className={style.table}>
          <TableHead>
            <TableRow>
               <CustomHeaderCell id="name" sortable onSort={props.onSortHandle}>User Name</CustomHeaderCell>
              <CustomHeaderCell id="roleName" sortable onSort={props.onSortHandle} filtered getFilterList={props.getFilterList} onFilter={props.onFilterHandle}>Role Name</CustomHeaderCell>
              <CustomHeaderCell id="sbu" onSort={props.onSortHandle} >SBU</CustomHeaderCell>
              <CustomHeaderCell id="Plant" width={120}>Plant</CustomHeaderCell>
              <CustomHeaderCell id="department"  onSort={props.onSortHandle} >Department</CustomHeaderCell>
              
              {ALL_ENTITLEMENTS.map((entitlement, index) => (
                <CustomHeaderCell key={index} id="">{entitlement}</CustomHeaderCell>
              ))}
              {authorizedUser.roleId === USER_ROLES.SUPER_ADMIN && ( 
               <CustomHeaderCell id="">Actions</CustomHeaderCell>
              )}
            </TableRow>
          </TableHead>
          {props.DataIsLoading && (
            <AppSkeleton
              numOfRows={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE}
              numOfColumns={29}
              columnWidth={120}
              height={30}
              tag="table"
            />
          )}
          {props.filteredList &&
            props.filteredList.length > 0 &&
            !props.DataIsLoading && (
              <TableBody>
                {props.filteredList.map((p: any) => (
                  <TableRow key={p.id}>
                    <StyledTableCell>{p.name}</StyledTableCell>
                    <StyledTableCell>{p.roleName}</StyledTableCell>
                    <StyledTableCell> {p.hierarchy.find((h: any) => h.type === "SBU")?.name}</StyledTableCell>
                    <StyledTableCell> {p.hierarchy.find((h: any) => h.type === "Plant")?.name} </StyledTableCell>
                    <StyledTableCell> {p.hierarchy.find((h: any) => h.type === "Department")?.name}</StyledTableCell>
                    {ALL_ENTITLEMENTS.map((entitlement: any, index: number) => {
                      const hasEntitlement = p.entitlements.some(
                        (e: any) => e.entitlements === entitlement
                      );
                      return (
                        <StyledTableCell key={index}>
                          <StyledCheckBox
                            size="small"
                            checked={hasEntitlement}
                            readOnly
                          ></StyledCheckBox>
                        </StyledTableCell>
                      );
                    })}
                    <StyledTableCell>
                      <Box className="layout-row">
                        {authorizedUser.roleId === USER_ROLES.SUPER_ADMIN && (
                          <Box>
                            <IconButton
                              size="small"
                              onClick={() => {
                                props.getEntitlementId(p.id)
                                props.navigateTo(GENERAL_SCREEN_MODES.EDIT, p.id)
                              }}
                            >
                              <Tooltip title="Edit">
                                <EditOutlined sx={{ fontSize: "20px", mr: "-1", color: "white" }} />
                              </Tooltip>
                            </IconButton>
                          </Box>
                        )}
                        
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          {!props.DataIsLoading && props.filteredList.length === 0 && (
            <TableBody>
              <TableRow>
                <StyledTableCell align="center" colSpan={27}>
                  No data to preview
                </StyledTableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_OPTIONS}
        component="div"
        labelRowsPerPage={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              color: "white",
            }}
          >
            Items per page
          </div>
        }
        count={-1}
        page={props.page}
        onPageChange={props.onHandleChangePage}
        onRowsPerPageChange={props.onHandleChangeRowsPerPage}
        rowsPerPage={props.rowsPerPage}
        sx={{ backgroundColor: "#282828", color: "white" }}
        nextIconButtonProps={{
          disabled:
            -1 < props.filteredList.length &&
            props.filteredList.length < props.rowsPerPage,
        }}
      />
    </section>
  );
};

export default ManageEntitlementGird;
